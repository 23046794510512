import React, { type FC } from 'react'
import { Divider, ListItemButton } from '@mui/material'
import { type SvgIconComponent } from '@mui/icons-material'

import { type Capability, type Translation } from '../../../../domain'

import { type AbsoluteRouteOnlyPath, useHasCapabilities, useRouteDescription, useRouteTitle, useRouting } from '../../routing'

import { Icon, Text } from './Item'
import { listItemStyle } from './Styles'

type AlternativeProps =
    | Readonly<{ route: AbsoluteRouteOnlyPath }>
    | Readonly<{ title: Translation, isTitleSingular: boolean, onClick: VoidFunction, requiredCapabilities: Capability[] }>

export type NavigationLinkProps = Readonly<AlternativeProps & { icon?: SvgIconComponent, nested?: boolean, divide?: boolean }>

export const NavigationLink: FC<NavigationLinkProps> = ({ icon, nested, divide, ...props }) => {
    const routing = useRouting()
    const routeTitleConfig = useRouteTitle()
    const routeDescription = useRouteDescription()
    const hasCapabilities = useHasCapabilities()

    const withCapabilities = hasCapabilities('requiredCapabilities' in props ? props.requiredCapabilities : props.route)

    if (!withCapabilities) {
        return null
    }

    const selected = !('onClick' in props) && routing.isAtRoute(props.route)
    const titleConfig = 'onClick' in props ? props : routeTitleConfig(props.route)
    const description = 'onClick' in props ? null : routeDescription(props.route)

    return (
        <>
            {divide && <Divider variant="white" />}
            <ListItemButton
                data-testid={`navigation-item-${('route' in props ? props.route : props.title).toLowerCase().replaceAll('_', '-')}`}
                sx={listItemStyle}
                className={`navigationLink ${nested ? 'nested' : ''}`}
                component="li"
                selected={selected}
                onClick={'onClick' in props ? props.onClick : () => routing.redirect(props.route)}
            >
                {icon && <Icon icon={icon} />}
                <Text title={titleConfig.title} isTitleSingular={titleConfig.isTitleSingular} description={description || undefined} inset={!icon} />
            </ListItemButton>
        </>
    )
}
