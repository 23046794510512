import { ConnectwareError, ConnectwareErrorType, isOtpValid, MfaSettingsState } from '../../../domain'
import { initialOtp } from '../..'
import { MfaUsecase } from './Base'

export class EnableMfaUsecase extends MfaUsecase {
    /** Does **not** throw errors in expected conditions */
    async start (): Promise<void> {
        /** Asseting that is currently disabled */
        this.getMFaSettings(MfaSettingsState.DISABLED)

        /** Start enabling process */
        this.setMfa({ state: MfaSettingsState.ENABLING, activation: null, otp: initialOtp, error: null })

        /** Load activation metadata onto the state */
        await this.mfaService
            .enable()
            .catch((e: ConnectwareError) => e)
            .then((activation) => this.updateMfaSettings({ state: MfaSettingsState.ENABLING, activation }))
    }

    setOtp (otp: string[]): void {
        this.updateMfaSettings({ state: MfaSettingsState.ENABLING, otp })
    }

    /** Does **not** throw errors in expected conditions */
    async confirm (): Promise<void> {
        const { otp } = this.getMFaSettings(MfaSettingsState.ENABLING)

        try {
            if (!isOtpValid(otp)) {
                throw new ConnectwareError(ConnectwareErrorType.STATE, 'Invalid OTP', { otp })
            }

            /** Reset error */
            this.updateMfaSettings({ state: MfaSettingsState.ENABLING, error: null })

            this.setMfa({ state: MfaSettingsState.ENABLED, backupCodes: await this.mfaService.confirmEnabling(otp) })
        } catch (e: unknown) {
            this.updateMfaSettings({ state: MfaSettingsState.ENABLING, error: e as ConnectwareError })
        }
    }
}
