import type { CybusServiceMarketPlace } from '../..'

export const mapMarketplace = (
    marketplace: CybusServiceMarketPlace
): Readonly<{ directory: string, filename: string, version: string, updatedAt: Date }> | null =>
    marketplace.directory && marketplace.filename && marketplace.version && marketplace.updatedAt
        ? {
              directory: marketplace.directory,
              filename: marketplace.filename,
              version: marketplace.version,
              updatedAt: new Date(marketplace.updatedAt),
          }
        : null
