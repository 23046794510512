import React, { type FC, useEffect } from 'react'
import { Edit } from '@mui/icons-material'

import { Box, type SxProps, type Theme } from '@mui/material'

import { ConnectwareError, Translation } from '../../../../domain'

import { useAppUsecases } from '../../State'
import { FormattedTranslation } from '../../Internationalization'
import { ErrorMessage } from '../../ErrorMessage'
import { TiniestCopy } from '../../Copy'

import { useInput, useSelectedTopics } from './Hooks'
import { Code } from './Code'

const customizeButtonWrapperStyle: SxProps<Theme> = { width: 40, height: 0, ml: 'auto', mr: 2.5, position: 'relative', zIndex: 1 }
const customizeButtonStyle: SxProps<Theme> = { cursor: 'pointer', width: 40, height: 40, p: '10px' }

export const LastMessageTitle: FC = () => {
    const input = useInput()

    return (
        <FormattedTranslation
            id={Translation.TRANSFORMATION_INPUT}
            values={{
                last: true,
                hasSource: Boolean(input.loaded && input.topic),
                source: input.topic,
                copy: <TiniestCopy>{input.topic}</TiniestCopy>,
            }}
        />
    )
}

export const LastMessage: FC = () => {
    const topics = useSelectedTopics()
    const input = useInput()
    const { subscribeToMessagesSandboxUsecase, filterSandboxUsecase } = useAppUsecases()

    useEffect(() => subscribeToMessagesSandboxUsecase.subscribe(), [subscribeToMessagesSandboxUsecase])

    if (!topics?.length) {
        return (
            <Code id="rule-engine-last-message">
                <FormattedTranslation id={Translation.NO_TOPICS_SELECTED} />
            </Code>
        )
    }

    if (!input.loaded) {
        return (
            <Code id="rule-engine-last-message">
                <FormattedTranslation id={Translation.NO_MESSAGES} />
            </Code>
        )
    }

    if (ConnectwareError.is(input.value)) {
        return <ErrorMessage id="rule-engine-last-message" titleVariant="subtitle1" error={input.value} extras="popover" />
    }

    return (
        <Code
            id="rule-engine-last-message"
            buttons={
                <Box sx={customizeButtonWrapperStyle}>
                    <Edit id="rule-engine-last-message-customize" sx={customizeButtonStyle} onClick={() => filterSandboxUsecase.setJson(input.value)} />
                </Box>
            }
            json={input.value}
        />
    )
}
