import React, { type FC } from 'react'

import {
    Capability,
    type CybusDetailedServiceContainer,
    CybusLogSource,
    ResourceType,
    selectDeletableLoadedDetailedServiceContainer,
    selectRestartableLoadedDetailedServiceContainer,
    selectServiceContainer,
    selectStartableLoadedDetailedServiceContainer,
    selectStoppableLoadedDetailedServiceContainer,
    Translation,
} from '../../../domain'

import { useRouting } from '../routing'
import { ResourceDetails } from '../Resources'

import { DeleteModal, RestartModal, StartModal, StopModal } from './Bulk'
import { Content, HeaderActions, HeaderDetails } from './Details'

const Modals: FC = () => {
    const routing = useRouting()

    return (
        <>
            <StopModal
                dimension={ResourceType.SERVICE_CONTAINER}
                usecase="bulkStopServiceContainersUsecase"
                containersSelector={selectStoppableLoadedDetailedServiceContainer}
            />
            <StartModal
                dimension={ResourceType.SERVICE_CONTAINER}
                usecase="bulkStartServiceContainersUsecase"
                containersSelector={selectStartableLoadedDetailedServiceContainer}
            />
            <RestartModal
                dimension={ResourceType.SERVICE_CONTAINER}
                usecase="bulkRestartServiceContainersUsecase"
                containersSelector={selectRestartableLoadedDetailedServiceContainer}
            />
            <DeleteModal
                dimension={ResourceType.SERVICE_CONTAINER}
                usecase="bulkDeleteServiceContainersUsecase"
                containersSelector={selectDeletableLoadedDetailedServiceContainer}
                onConfirmed={() => routing.redirectToParent()}
            />
        </>
    )
}

export const Details: FC = () => (
    <ResourceDetails<CybusDetailedServiceContainer>
        subscriptionUsecase="manageServiceContainerUsecase"
        resourceSelector={selectServiceContainer}
        notFoundTitle={Translation.CONTAINER_NOT_FOUND}
        modals={Modals}
        headerResourceName={Translation.CONTAINER}
        headerName="name"
        headerActions={(props) => (
            <HeaderActions
                deleteUsecaseName="bulkDeleteServiceContainersUsecase"
                restartUsecaseName="bulkRestartServiceContainersUsecase"
                startUsecaseName="bulkStartServiceContainersUsecase"
                stopUsecaseName="bulkStopServiceContainersUsecase"
                managementCapability={Capability.SERVICE_CONTAINERS_MANAGE}
                {...props}
            />
        )}
        infoTitle={Translation.CONTAINER_DETAILS_DOCUMENTATION_TITLE}
        infoBody={Translation.CONTAINER_DETAILS_DOCUMENTATION_BODY}
        headerDetails={(props) => <HeaderDetails linkVolumes {...props} />}
        content={(props) => <Content logType={CybusLogSource.SERVICE_CONTAINER} {...props} />}
    />
)
