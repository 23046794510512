import React, { type FC, type ReactNode } from 'react'
import {
    Dialog as BaseDialog,
    type DialogProps as BaseDialogProps,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    type SxProps,
    type Theme,
    Typography,
} from '@mui/material'

import { ConnectwareError, Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'
import { ErrorMessage } from '../../ErrorMessage'

const titleStyle: SxProps<Theme> = { display: 'flex', '& > *': { margin: 'auto 0' }, '& > h2': { marginRight: 'auto' }, '& > button': { marginLeft: 1 } }
const errorStyle: SxProps<Theme> = { mr: 'auto' }

export type DialogProps = Readonly<{
    /** If the modal will be displayed as loading, null hides the progress */
    loading: boolean | null

    /** Custom title of the dialog, should be a string */
    title: ReactNode

    /** Rendered after the title and advanced mode toggle */
    headerSuffix?: ReactNode

    /** Called when user intents to close the dialog */
    easyClose?: boolean
    onClose?: VoidFunction

    /** Errors to be displayed at the bottom of the dialog */
    error?: ConnectwareError | null

    /** Displayed after the close button */
    actionsSuffix?: ReactNode
}> &
    Pick<BaseDialogProps, 'maxWidth'>

export const Dialog: FC<DialogProps> = ({ maxWidth, title, headerSuffix, loading, easyClose = true, onClose, children, error, actionsSuffix }) => {
    const close = !loading ? onClose : undefined

    return (
        <BaseDialog maxWidth={maxWidth} fullWidth open onClose={(easyClose && close) || undefined}>
            <DialogTitle sx={titleStyle} component="div">
                <Typography variant="h6" component="h2">
                    {title}
                </Typography>
                {headerSuffix}
            </DialogTitle>

            {loading !== null && <LinearProgress variant={loading ? 'indeterminate' : 'determinate'} value={0} />}

            <DialogContent>{children}</DialogContent>

            <DialogActions>
                {ConnectwareError.is(error) && <ErrorMessage data-testid="confirmation-error" sx={errorStyle} error={error} titleVariant="subtitle1" />}
                <Button data-testid="close" color="secondary" variant="outlined" disabled={!close} onClick={close}>
                    <FormattedTranslation id={Translation.CLOSE} />
                </Button>
                {actionsSuffix}
            </DialogActions>
        </BaseDialog>
    )
}
