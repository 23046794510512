import React, { type FC, useEffect } from 'react'

import { selectIsUsersManagementRegistryLoading, selectUsersManagementRegistryError } from '../../../../domain'

import { useAppState, useAppUsecase } from '../../State'
import { CircularLoader } from '../../common'
import { ErrorMessage } from '../../ErrorMessage'

import { RegistrationMessage } from './Message'
import { RequestsTable } from './Table'
import { RegistryRequestDialog } from './Modal'

export const Registry: FC = () => {
    const loadClientRegistryUsecase = useAppUsecase('loadClientRegistryUsecase')
    useEffect(() => loadClientRegistryUsecase.invoke(), [loadClientRegistryUsecase])

    const loading = useAppState(selectIsUsersManagementRegistryLoading)
    const error = useAppState(selectUsersManagementRegistryError)

    if (loading) {
        return <CircularLoader />
    }

    if (error) {
        return <ErrorMessage error={error} stack extras="section" />
    }

    return (
        <>
            <RequestsTable />
            <RegistrationMessage />
            <RegistryRequestDialog />
        </>
    )
}
