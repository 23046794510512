import type { TopicPath } from '../../domain'

export class TopicMatchingHelper {
    protected readonly topicSourcesCache: Record<string, boolean> = {}

    protected readonly prefixSourcesCache: Record<string, boolean> = {}

    /**
     * Splits the given topic & source into their parts
     * If it detect that there is multi-level wildcard at the source,
     * Then, it removes everything after that index & returns only the parts before that multi-level wildcard index
     */
    private getNonMultiLevelWildcardTopicParts (topic: string, base: string): [string[], string[]] {
        /**
         * Split everything by levels
         */
        let topicParts = topic.split('/')
        let sourceParts = base.split('/')

        /**
         * There can be only one # (@see https://upload.wikimedia.org/wikipedia/en/b/bc/Highlander_1986%2Cposter.jpg)
         * It has to be the last char in order for the sub to be valid
         * So if we find it, we remove everything after it
         */
        const wildcardPosition = sourceParts.indexOf('#')
        if (wildcardPosition >= 0) {
            sourceParts = sourceParts.slice(0, wildcardPosition)
            topicParts = topicParts.slice(0, wildcardPosition)
        }

        return [topicParts, sourceParts]
    }

    /**
     * Full-match, no need for pattern matching
     * Logically it has no purpose, but it is faster and cleaner
     */
    private isFullMatch (a: string, b: string): boolean {
        return a === b
    }

    /**
     * Match every source & topic parts by level
     * Returns true, if it detect single-level wildcard at the source part, or topic & source part are same
     */
    private doPartsMatch (a: string[], b: string[]): boolean {
        return a.every((topicPart, index) => {
            const sourcePart = b[index]

            /**
             * Now we handle one level deep wildcards (+)
             * There can be any amount
             */
            return sourcePart === '+' || sourcePart === topicPart
        })
    }

    /**
     * MQTT topics can have wildcards
     *
     * @see http://docs.oasis-open.org/mqtt/mqtt/v3.1.1/os/mqtt-v3.1.1-os.html#_Topic_wildcards
     */
    private isSourceWithoutCache (topic: string, source: string): boolean {
        if (this.isFullMatch(topic, source)) {
            return true
        }

        const [topicParts, sourceParts] = this.getNonMultiLevelWildcardTopicParts(topic, source)

        if (topicParts.length !== sourceParts.length) {
            /**
             * A missmatch in sizes is not expected from this point on
             * As any-levels wildcards have been done away with
             */
            return false
        }

        return this.doPartsMatch(topicParts, sourceParts)
    }

    private isPrefixWithoutCache (prefix: string, topic: string): boolean {
        if (this.isFullMatch(prefix, topic)) {
            return true
        }

        const [prefixParts, topicParts] = this.getNonMultiLevelWildcardTopicParts(prefix, topic)
        const endsWithSlash = !prefixParts[prefixParts.length - 1]

        return this.doPartsMatch(
            /**
             * Cuts off the prefix's last part if it ends with a slash
             * unless the prefix is shorter than the source and thus
             * needs another level becomes relevant again
             */
            prefixParts.slice(0, prefixParts.length - Number(endsWithSlash && prefixParts.length <= topicParts.length)),
            /**
             * Cut of to the level of the source without the trailing slash
             */
            topicParts.slice(0, prefixParts.length - Number(endsWithSlash))
        )
    }

    private isRecordInCache (topic: string, source: string, withoutCache: () => boolean, cacheRecord: Record<string, boolean>): boolean {
        /** Based on https://docs.oasis-open.org/mqtt/mqtt/v3.1.1/os/mqtt-v3.1.1-os.html#_Toc384800482 */
        const cacheKey = `${topic}\u0000${source}`
        let isCache = cacheRecord[cacheKey]

        if (isCache === undefined) {
            isCache = withoutCache()
            cacheRecord[cacheKey] = isCache
        }

        return isCache
    }

    private isSource (topic: string, source: string): boolean {
        return this.isRecordInCache(topic, source, () => this.isSourceWithoutCache(topic, source), this.topicSourcesCache)
    }

    findTopicSources (topic: string, sources: string[]): TopicPath[] {
        return sources.reduce<TopicPath[]>((r: TopicPath[], source: string) => (this.isSource(topic, source) ? [...r, source.split('/')] : r), [])
    }

    isSubscribeable (topic: string, sources: string[]): boolean {
        return sources.some((source: string) => this.isSource(topic, source))
    }

    isPrefix (prefix: string, topics: string[]): boolean {
        return topics.some((source: string) => this.isRecordInCache(prefix, source, () => this.isPrefixWithoutCache(prefix, source), this.prefixSourcesCache))
    }
}
