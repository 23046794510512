import React, { type FC } from 'react'

import { Capability, type CybusDetailedVolume, Translation } from '../../../../domain'

import { useAppUsecase } from '../../State'
import { DetailsActionButton } from '../../Details'

export const HeaderActions: FC<{ resource: CybusDetailedVolume }> = ({ resource: { id } }) => {
    const bulkDeleteVolumesUsecase = useAppUsecase('bulkDeleteVolumesUsecase')

    return (
        <DetailsActionButton
            id="delete-volume-button"
            translation={Translation.DELETE}
            capabilities={[Capability.VOLUMES_MANAGE]}
            onClick={() => bulkDeleteVolumesUsecase.selectVolumes([id])}
        />
    )
}
