import React, { type FC, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, type SxProps, type Theme } from '@mui/material'

import { type CybusDetailedService, type StatusType, Translation } from '../../../../domain'

import { Status, Table, useTableStatusSearcher } from '../../common'
import { FormattedTranslation } from '../../Internationalization'

const buttonStyle: SxProps<Theme> = { color: 'primary.700' }

const useToggler = (): [boolean, VoidFunction] => {
    const [open, setOpen] = useState(false)
    const toggle: VoidFunction = () => setOpen(!open)

    return [open, toggle]
}

export const DeviationModal: FC<Pick<CybusDetailedService, 'deviations'>> = ({ deviations }) => {
    const [open, toggle] = useToggler()
    const searchStrategy = useTableStatusSearcher()
    const customCellRender = (s: StatusType): JSX.Element => <Status status={s} />

    return (
        <>
            <Button id="open-service-deviations-modal" sx={buttonStyle} size="small" onClick={toggle}>
                <FormattedTranslation id={Translation.SHOW_DEVIATIONS} />
            </Button>
            {open && (
                <Dialog id="service-deviations-modal" maxWidth="md" fullWidth open onClose={toggle}>
                    <DialogTitle>
                        <FormattedTranslation id={Translation.DEVIATION_DETECTED} />
                    </DialogTitle>
                    <DialogContent>
                        <Table
                            data={deviations}
                            columns={{
                                id: { label: Translation.LOGICAL_ID, sort: true },
                                expected: { label: Translation.EXPECTED, sort: true, searchStrategy, customCellRender },
                                actual: { label: Translation.ACTUAL, sort: true, searchStrategy, customCellRender },
                                reason: { label: Translation.REASON },
                                type: {
                                    label: Translation.TYPE,
                                    sort: true,
                                    customCellRender: (type) => <FormattedTranslation id={Translation.DEVIATION_TYPE} values={{ type }} />,
                                },
                            }}
                            initialPagination={{ selected: 15, options: [15] }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button id="close-service-deviations-modal" color="primary" onClick={toggle}>
                            <FormattedTranslation id={Translation.CLOSE} />
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}
