import React, { type FC } from 'react'
import { MenuItem, Select, type SxProps, type Theme } from '@mui/material'

import { type BufferType } from '../../../../domain'

import { type BaseCommonProps } from '../Base'
import { useBufferTypeTranslator } from './Hooks'

const menuItemStyle: SxProps<Theme> = { fontSize: 'body2.fontSize' }

type Props = Readonly<{ value: BufferType, types: BufferType[], onChange: (value: BufferType) => void }> & Pick<BaseCommonProps, 'sx'>

export const BufferTypeSelect: FC<Props> = ({ value, types, onChange, sx }) => {
    const translate = useBufferTypeTranslator()

    if (types.length < 2) {
        /**
         * No real choice is possible, give up
         */
        return null
    }

    return (
        <Select sx={sx} variant="standard" value={value} onChange={({ target: { value } }) => onChange(value as BufferType)}>
            {types
                .map((type) => ({ type, label: translate(type) }))
                .sort(({ label: a }, { label: b }) => (a > b ? -1 : 1))
                .map(({ type, label }) => (
                    <MenuItem key={type} value={type} sx={menuItemStyle}>
                        {label}
                    </MenuItem>
                ))}
        </Select>
    )
}
