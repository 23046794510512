import { type AppState, ConnectwareError, selectDeviations } from '../../../domain'

import { initialState, type SubscriptionListEvent } from '../..'
import { ResourceSubscriptionUsecase } from '../Resources'

export class ManageDeviationsUsecase extends ResourceSubscriptionUsecase<never, 'deviations'> {
    protected readonly singularName: never = undefined as never

    protected readonly listName = 'deviations'

    protected mapListEvents (deviationsEvent: SubscriptionListEvent<'deviations'> | null): Partial<AppState> {
        if (Array.isArray(deviationsEvent)) {
            const newDeviations: AppState['deviations'] = []
            deviationsEvent.forEach((deviation) => {
                if (typeof deviation === 'string') {
                    newDeviations.push(deviation)
                } else {
                    this.logger.error(deviation)
                }
            })

            return { deviations: newDeviations }
        }

        if (ConnectwareError.is(deviationsEvent)) {
            this.logger.error(deviationsEvent)
        }

        return { deviations: selectDeviations(initialState) }
    }
}
