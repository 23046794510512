import React, { type FC, useCallback, useState } from 'react'
import { MobileDateTimePicker, type MobileDateTimePickerProps } from '@mui/x-date-pickers'
import { type UseDateFieldProps } from '@mui/x-date-pickers/DateField'
import { type BaseSingleInputFieldProps, type DateValidationError, type FieldSection } from '@mui/x-date-pickers/models'
import { type Dayjs } from 'dayjs'
import { Chip } from '@mui/material'

import { type Required } from 'utility-types'

import { type Translation } from '../../../../domain'
import { FormattedDateTime, FormattedTranslation } from '../../Internationalization'

type ButtonFieldProps = UseDateFieldProps<Dayjs> &
    BaseSingleInputFieldProps<Dayjs | null, Dayjs, FieldSection, DateValidationError> &
    Required<Pick<MobileDateTimePickerProps<Dayjs>, 'onAccept'>, 'onAccept'> &
    Readonly<{ onOpen: VoidFunction }>

type ButtonDatePickerProps = Required<Omit<MobileDateTimePickerProps<Dayjs>, 'open' | 'onOpen' | 'onClose' | 'label'>, 'onAccept'> &
    Readonly<{ emptyLabel: Translation }>

const ButtonField: FC<ButtonFieldProps> = ({ onOpen, id, label, disabled, value, onAccept }) => (
    <Chip
        id={id}
        disabled={disabled}
        label={label}
        onClick={onOpen}
        variant={value ? 'filled' : 'outlined'}
        color={value ? 'warning' : 'default'}
        onDelete={value ? () => onAccept(null) : undefined}
    />
)

export const ButtonDatePicker: FC<ButtonDatePickerProps> = ({ emptyLabel, ...props }) => {
    const [open, setOpen] = useState<boolean>(false)
    const onOpen = useCallback(() => setOpen(true), [])
    const onClose = useCallback(() => setOpen(false), [])

    return (
        <MobileDateTimePicker
            ampm={false}
            slots={{
                field: (p) => <ButtonField {...p} onOpen={onOpen} onAccept={props.onAccept} value={props.value} />,
            }}
            {...props}
            open={open}
            onClose={onClose}
            onOpen={onOpen}
            label={props.value ? <FormattedDateTime date={props.value.toDate()} format="datetime" /> : <FormattedTranslation id={emptyLabel} />}
        />
    )
}
