import type { CybusMapping, SortableColumn } from '../../../domain'

import { ResourcePageSubscriptionUsecase, ResourceSingleSubscriptionUsecase } from '../Resources'

export class ManageMappingsUsecase extends ResourcePageSubscriptionUsecase<'mappings'> {
    protected readonly pageName = 'mappings'

    protected readonly pageAddress = 'mappingsPage'

    protected readonly initialSortColumn: SortableColumn<CybusMapping> = 'service'
}

export class ManageMappingUsecase extends ResourceSingleSubscriptionUsecase<'mapping'> {
    protected readonly singularName = 'mapping'

    protected readonly singleAddress = 'mapping'
}
