import React, { type FC, Fragment } from 'react'
import { List } from '@mui/material'

import { type CybusDetailedService, doesServiceNeedAuthorization, ResourceType, selectEnabableLoadedDetailedService, Translation } from '../../../../domain'

import { useAppUsecase } from '../../State'
import { EnableResourceModal } from '../../Resources'

import { UserRolesPermissions } from './UserRolesPermissions'
import { HttpIngressRoutes, TcpIngressRoutes } from './Routes'
import { Containers } from './Containers'
import { Volumes } from './Volumes'
import { Connections } from './Connections'
import { Mappings } from './Mappings'
import { Servers } from './Servers'
import { Endpoints } from './Endpoints'

const Content: FC<{ resource: CybusDetailedService }> = ({
    resource: {
        configuredResources: { roles, users, tcpRoutes, httpRoutes, containers, volumes, connections, mappings, servers, endpoints },
    },
}) => {
    const list: JSX.Element[] = []

    if (roles.length + users.length) {
        list.push(<UserRolesPermissions users={users} roles={roles} />)
    }

    if (httpRoutes.length) {
        list.push(<HttpIngressRoutes httpRoutes={httpRoutes} />)
    }

    if (tcpRoutes.length) {
        list.push(<TcpIngressRoutes tcpRoutes={tcpRoutes} />)
    }

    if (containers.length) {
        list.push(<Containers containers={containers} />)
    }

    if (endpoints.length) {
        list.push(<Endpoints endpoints={endpoints} />)
    }

    if (volumes.length) {
        list.push(<Volumes volumes={volumes} />)
    }

    if (connections.length) {
        list.push(<Connections connections={connections} />)
    }

    if (mappings.length) {
        list.push(<Mappings mappings={mappings} />)
    }

    if (servers.length) {
        list.push(<Servers servers={servers} />)
    }

    return (
        <>
            {list.map((item, k) => (
                <Fragment key={k}>{item}</Fragment>
            ))}
        </>
    )
}

const Wrapper: FC = ({ children }) => (
    <List dense disablePadding>
        {children}
    </List>
)

export const EnablingAuthorizationModal: FC = () => {
    const usecase = useAppUsecase('bulkEnableServiceUsecase')

    return (
        <EnableResourceModal<CybusDetailedService>
            title={Translation.AUTHORIZATION_REQUIRED}
            subtitle={Translation.AUTHORIZE_SERVICE_REQUIREMENTS}
            infoBody={Translation.AUTHORIZATION_REQUIRED_DOCUMENTATION_BODY}
            confirm={Translation.ALLOW}
            maxWidth="lg"
            resourceSelector={selectEnabableLoadedDetailedService}
            dimension={ResourceType.SERVICE}
            onRenderLine={Wrapper}
            onRenderLineContent={Content}
            onCancel={() => usecase.selectServices(null)}
            onConfirm={() => usecase.enable()}
            showOnlyOnError={(service) => !doesServiceNeedAuthorization(service)}
        />
    )
}
