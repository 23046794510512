import React, { type FC, useLayoutEffect } from 'react'

import { HashRouter } from 'react-router-dom'
import { CssBaseline } from '@mui/material'

import {
    AbsoluteRouteOnlyPath,
    AbsoluteRoutePathWithId,
    AbsoluteRoutePathWithServiceAndResourceId,
    AbsoluteRoutePathWithServiceId,
    CybusRouter,
    Route,
    Switch,
} from '../routing'
import { useAppUsecases } from '../State'

import { Head } from '..'

import VersionMismatchNotifier from '../VersionMismatch'
import Credentials from '../Credentials'
import { AppNavigation, RouteNavigation } from '../Navigation'
import Services, { Details as ServiceDetails, ServiceLinks, ServicesCatalog } from '../Services'
import Explorer from '../Explorer'
import Servers, { Details as ServerDetails } from '../Servers'
import { CoreContainerDetails, CoreContainersTable, ServiceContainerDetails, ServiceContainersTable } from '../Containers'
import Volumes, { Details as VolumeDetails } from '../Volumes'
import Endpoints, { Details as EndpointDetails } from '../Endpoints'
import Mappings, { Details as MappingDetails } from '../Mappings'
import Connections, { Details as ConnectionDetails } from '../Connections'
import InfoPage from '../Info'
import Certificates from '../Certificates'
import Metrics from '../Metrics'
import Maintenance from '../Maintenance'
import Agents from '../Agents'
import RuleEngineSandbox from '../RuleEngine'
import { JSONEditor } from '../JsonEditor'
import { ClientRegistry, UsersManagement } from '../Users'
import DeviationNotifications from '../DeviationNotifications'
import ResourcesTabs from '../Resources'
import ServiceLogs from '../ServiceLogs'
import { LoginSettings, SettingsTabs } from '../Settings'

const RouterAppNavigation: FC = ({ children }) => (
    <>
        <CssBaseline />
        <Credentials>
            <AppNavigation>{children}</AppNavigation>
            <DeviationNotifications />
        </Credentials>
    </>
)

export const App: FC = () => {
    const { loginFromTokenUsecase, loginFromPersistenceUsecase, inspectApplicationUsecase } = useAppUsecases()

    useLayoutEffect(() => {
        /**
         * Attempt to login from either the token on the url
         * or the persisted information from local storage
         */
        loginFromTokenUsecase.loginBrowserFromToken() || loginFromPersistenceUsecase.loginWithPersistedInformation()

        /**
         * Activate debugging of the application
         */
        inspectApplicationUsecase.invoke()
    }, [loginFromTokenUsecase, loginFromPersistenceUsecase, inspectApplicationUsecase])

    return (
        <>
            <Head />
            <HashRouter>
                <CybusRouter
                    initial={AbsoluteRouteOnlyPath.SERVICES}
                    onNotFound={AbsoluteRouteOnlyPath.SERVICES}
                    appNavigation={RouterAppNavigation}
                    routeNavigation={RouteNavigation}
                >
                    <Route path={AbsoluteRouteOnlyPath.SERVICES}>
                        <Services />
                    </Route>

                    <Route paths={[AbsoluteRoutePathWithServiceAndResourceId.SERVICE_SERVER, AbsoluteRoutePathWithId.RESOURCES_SERVER]}>
                        <ServerDetails />
                    </Route>
                    <Route paths={[AbsoluteRoutePathWithServiceAndResourceId.SERVICE_CONTAINER, AbsoluteRoutePathWithId.RESOURCES_CONTAINER]}>
                        <ServiceContainerDetails />
                    </Route>
                    <Route paths={[AbsoluteRoutePathWithServiceAndResourceId.SERVICE_VOLUME, AbsoluteRoutePathWithId.RESOURCES_VOLUME]}>
                        <VolumeDetails />
                    </Route>
                    <Route paths={[AbsoluteRoutePathWithServiceAndResourceId.SERVICE_CONNECTION, AbsoluteRoutePathWithId.RESOURCES_CONNECTION]}>
                        <ConnectionDetails />
                    </Route>
                    <Route paths={[AbsoluteRoutePathWithServiceAndResourceId.SERVICE_ENDPOINT, AbsoluteRoutePathWithId.RESOURCES_ENDPOINT]}>
                        <EndpointDetails />
                    </Route>
                    <Route paths={[AbsoluteRoutePathWithServiceAndResourceId.SERVICE_MAPPING, AbsoluteRoutePathWithId.RESOURCES_MAPPING]}>
                        <MappingDetails />
                    </Route>
                    <Route paths={[AbsoluteRoutePathWithServiceId.SERVICE, AbsoluteRoutePathWithId.RESOURCES_SERVICE]}>
                        <ServiceDetails />
                    </Route>
                    <Route path={AbsoluteRouteOnlyPath.SERVICE_LOGS}>
                        <ServiceLogs />
                    </Route>

                    <Route
                        paths={[
                            AbsoluteRouteOnlyPath.RESOURCES,
                            AbsoluteRouteOnlyPath.SERVICES_LINKS,
                            AbsoluteRouteOnlyPath.RESOURCES_SERVERS,
                            AbsoluteRouteOnlyPath.RESOURCES_CONTAINERS,
                            AbsoluteRouteOnlyPath.RESOURCES_VOLUMES,
                            AbsoluteRouteOnlyPath.RESOURCES_CONNECTIONS,
                            AbsoluteRouteOnlyPath.RESOURCES_ENDPOINTS,
                            AbsoluteRouteOnlyPath.RESOURCES_MAPPINGS,
                        ]}
                    >
                        <ResourcesTabs />
                        <Switch onNotFound={AbsoluteRouteOnlyPath.SERVICES_LINKS}>
                            <Route path={AbsoluteRouteOnlyPath.SERVICES_LINKS}>
                                <ServiceLinks />
                            </Route>
                            <Route path={AbsoluteRouteOnlyPath.RESOURCES_SERVERS}>
                                <Servers />
                            </Route>
                            <Route path={AbsoluteRouteOnlyPath.RESOURCES_CONTAINERS}>
                                <ServiceContainersTable />
                            </Route>

                            <Route path={AbsoluteRouteOnlyPath.RESOURCES_VOLUMES}>
                                <Volumes />
                            </Route>

                            <Route path={AbsoluteRouteOnlyPath.RESOURCES_CONNECTIONS}>
                                <Connections />
                            </Route>

                            <Route path={AbsoluteRouteOnlyPath.RESOURCES_ENDPOINTS}>
                                <Endpoints />
                            </Route>

                            <Route path={AbsoluteRouteOnlyPath.RESOURCES_MAPPINGS}>
                                <Mappings />
                            </Route>
                        </Switch>
                    </Route>

                    <Route path={AbsoluteRouteOnlyPath.USER_MANAGEMENT}>
                        <UsersManagement />
                    </Route>
                    <Route path={AbsoluteRouteOnlyPath.CLIENT_REGISTRY}>
                        <ClientRegistry />
                    </Route>
                    <Route path={AbsoluteRouteOnlyPath.CERTIFICATES}>
                        <Certificates />
                    </Route>

                    <Route path={AbsoluteRouteOnlyPath.CATALOG}>
                        <ServicesCatalog />
                    </Route>

                    <Route path={AbsoluteRouteOnlyPath.EXPLORER}>
                        <Explorer />
                    </Route>

                    <Route path={AbsoluteRouteOnlyPath.METRICS}>
                        <Metrics />
                    </Route>
                    <Route path={AbsoluteRouteOnlyPath.STATUSES}>
                        <CoreContainersTable />
                    </Route>
                    <Route path={AbsoluteRoutePathWithId.RESOURCES_STATUS}>
                        <CoreContainerDetails />
                    </Route>
                    <Route path={AbsoluteRouteOnlyPath.AGENTS}>
                        <Agents />
                    </Route>
                    <Route path={AbsoluteRouteOnlyPath.INFO}>
                        <InfoPage />
                    </Route>
                    <Route path={AbsoluteRouteOnlyPath.MAINTENANCE}>
                        <Maintenance />
                    </Route>
                    <Route path={AbsoluteRouteOnlyPath.RULE_ENGINE_SANDBOX}>
                        <RuleEngineSandbox Editor={JSONEditor} />
                    </Route>

                    <Route paths={[AbsoluteRouteOnlyPath.SETTINGS, AbsoluteRouteOnlyPath.LOGIN_SETTINGS]}>
                        <SettingsTabs>
                            <Switch onNotFound={AbsoluteRouteOnlyPath.LOGIN_SETTINGS}>
                                <Route path={AbsoluteRouteOnlyPath.LOGIN_SETTINGS}>
                                    <LoginSettings />
                                </Route>
                            </Switch>
                        </SettingsTabs>
                    </Route>
                </CybusRouter>
            </HashRouter>
            <VersionMismatchNotifier />
        </>
    )
}
