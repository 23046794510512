import type { Loadable } from '../Loadable'

type CybusCatalogService = Readonly<{
    /**
     * Catalog available version
     */
    version: string

    /**
     * Last time the service was updated on the catalog
     */
    updatedAt: Date

    /**
     * The commissioning file made available by the catalog
     */
    commissioningFile: string

    /**
     * The directory configuration attached to that service
     */
    directory: string

    /**
     * The filename configuration attached to that service
     */
    filename: string

    /**
     * If the service is available on the license
     */
    available: boolean

    /**
     * Category of the service
     */
    category: CybusCatalogApplicationCategory

    /**
     * Name of service
     */
    name: string

    /**
     * Icon of the service (always present)
     */
    icon: string

    /**
     * Who provided the service
     */
    provider: string | null

    /**
     * If the service is a template too
     */
    template: boolean

    /**
     * Description of the service
     */
    description: string

    /**
     * Url of the service
     */
    homepage: string
}>

/**
 * This is the information necessary in order to fetch further service information
 */
export type CybusCatalogServiceConfiguration = Pick<CybusCatalogService, 'filename' | 'directory'>

/**
 * This is the information related to a version of a services catalog maintained service
 */
export type CybusCatalogServiceVersionData = Pick<CybusCatalogService, 'version' | 'updatedAt' | 'commissioningFile'>

export enum CybusCatalogApplicationCategory {
    APPLICATIONS = 'APPLICATIONS',
    NORTHBOUND = 'NORTHBOUND',
    SOUTHBOUND = 'SOUTHBOUND',
}

export type CybusCatalogApplication = Pick<
    CybusCatalogService,
    'version' | 'updatedAt' | 'directory' | 'filename' | 'available' | 'category' | 'name' | 'icon' | 'provider' | 'template' | 'description' | 'homepage'
>

export type CybusServiceCatalog = Readonly<{
    applications: Loadable<CybusCatalogApplication[]>
    search: string
    filtered: CybusCatalogApplication[]
    template: CybusCatalogApplication | CybusCatalogApplicationCategory | null
    application: CybusCatalogApplication | null
}>
