import React, { type FC } from 'react'

import {
    Capability,
    createIsAuthenticatedWithCapabilitiesSelector,
    type CybusVolume,
    selectLoadedVolumes,
    selectVolumesPage,
    type StatusType,
    Translation,
} from '../../../domain'

import { Status, useTableClearSelection } from '../common'
import { RelativeRoutePathWithId } from '../routing'
import { useTranslator } from '../Internationalization'

import { RedirectingResourcesTable } from '../Resources'
import { DeleteModal } from './Bulk'

const Modals: FC = () => {
    const clearSelection = useTableClearSelection()
    return <DeleteModal volumeSelector={selectLoadedVolumes} onConfirmed={clearSelection} />
}

const selectCanManageVolumes = createIsAuthenticatedWithCapabilitiesSelector(Capability.VOLUMES_MANAGE)

export const Table: FC<Readonly<{ serviceId?: string }>> = ({ serviceId = null }) => {
    const translator = useTranslator()

    const customCellRender = (status: StatusType): JSX.Element => <Status status={status} />

    return (
        <RedirectingResourcesTable<CybusVolume, CybusVolume, 'bulkDeleteVolumesUsecase'>
            subscriptionUsecase="manageVolumesUsecase"
            filter={serviceId ? { service: serviceId } : undefined}
            data={selectVolumesPage}
            hasManagementCapabilitiesSelector={selectCanManageVolumes}
            dataTableMapper={(volumes) => volumes}
            columns={{
                service: { label: Translation.SERVICE_ID, display: !serviceId, sort: true },
                name: { label: Translation.NAME, sort: true },
                created: {
                    label: Translation.CREATED_AT,
                    customCellRender: (date) => translator.formatTranslation(Translation.DATETIME, { type: 'datetime', date }),
                    sort: true,
                },
                status: { label: Translation.STATUS, customCellRender, sort: true },
            }}
            short={Boolean(serviceId)}
            redirectOnRowclick={RelativeRoutePathWithId.VOLUME}
            onSelect={{ DELETE: { usecase: 'bulkDeleteVolumesUsecase' }, resource: 'id', usecaseMethod: 'selectVolumes' }}
            translations={{ resourceName: Translation.VOLUME, noResultsOrEmptyTable: Translation.NO_VOLUMES_AVAILABLE }}
        >
            <Modals />
        </RedirectingResourcesTable>
    )
}
