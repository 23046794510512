import React, { type FC } from 'react'
import { Box, Button, type SxProps, type Theme, Typography, type TypographyProps } from '@mui/material'
import { ErrorOutlined } from '@mui/icons-material'

import { type TopicSubscriptionError, Translation } from '../../../../domain'

import { createHandlerWithoutPropagation } from '../..'

import { createFormatter, FormattedList, FormattedTranslation } from '../../Internationalization'
import { AnchorProvider, Popover, useAnchorCloser, useAnchorSetter } from '..'

const wrapperStlye: SxProps<Theme> = { p: 1.5, maxWidth: 400, wordBreak: 'break-word' }
const buttonStyle: SxProps<Theme> = { mr: 1, px: 0, pb: 0, minWidth: 0 }
const topicHighlightStyle: SxProps<Theme> = { color: 'error.main' }

const stopPropagation = createHandlerWithoutPropagation()

type Props = Readonly<{
    /** The topics that yielded the errors */
    topics: string[]
    errors: ReadonlySet<TopicSubscriptionError>

    /** Pass a callback if retry should be available */
    onRetry?: VoidFunction

    /** Pass a callback if unsub should be available */
    onUnsub?: VoidFunction
}>

const Content: FC<Props> = ({ topics, errors, onRetry, onUnsub, ...props }) => {
    const setAnchor = useAnchorSetter()
    const close = useAnchorCloser()

    const createCloser: (afterClose?: VoidFunction) => VoidFunction | undefined = (afterClose) =>
        afterClose
            ? () => {
                  close()
                  afterClose()
              }
            : close

    /**
     * Resolve the test id
     */
    const testId = String(('data-testid' in props && props['data-testid']) || topics.join('-').replace(/\W+/g, ''))

    return (
        <>
            <ErrorOutlined
                data-testid={`show-topic-error-button-${testId}`}
                color="error"
                fontSize="medium"
                onClick={createHandlerWithoutPropagation((e) => setAnchor(e.currentTarget))}
            />
            <Popover
                data-testid={`show-topic-error-popover-${testId}`}
                onClick={stopPropagation}
                onClose={createHandlerWithoutPropagation(close)}
                anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                elevation={1}
            >
                <Box sx={wrapperStlye}>
                    <FormattedTranslation
                        id={Translation.TOPIC_SUBSCRIPTION_ERROR}
                        values={{
                            topicsCount: topics.length,
                            topics: (
                                <FormattedList
                                    value={topics.map((topic, k) => (
                                        <Box component="b" sx={topicHighlightStyle} key={k} title={topic}>
                                            {topic}
                                        </Box>
                                    ))}
                                />
                            ),
                            error: errors.size > 1 ? 'multiple' : errors.values().next().value,

                            retry: Boolean(onRetry) && errors.has('unknown'),
                            retryButton: createFormatter(Button, {
                                'data-testid': `retry-topic-link-${testId}`,
                                sx: buttonStyle,
                                onClick: createCloser(onRetry),
                            }),

                            unsub: Boolean(onUnsub),
                            unsubButton: createFormatter(Button, {
                                'data-testid': `unsub-topic-link-${testId}`,
                                sx: buttonStyle,
                                onClick: createCloser(onUnsub),
                            }),

                            paragraph: createFormatter<TypographyProps>(Typography, { variant: 'body2' }),
                        }}
                    />
                </Box>
            </Popover>
        </>
    )
}

export const TopicsSubscriptionErrorsPopover: FC<Props> = (props) => (
    <AnchorProvider>
        <Content {...props} />
    </AnchorProvider>
)
