import type { CybusNode } from '../../../domain'
import type { SubscriptionFilterArgs } from '../../../application'

import { mapResourceNames, mapToStatusType, type NodeProxyParams } from '..'

const mapCybusNode = ({ id, currentState, parentId, operation, protocol }: NodeProxyParams): CybusNode => {
    const [service, name] = mapResourceNames(id)

    return {
        id,
        service,
        name,
        status: mapToStatusType(currentState),
        parent: parentId,
        operation: operation || null,
        protocol,
    }
}

export const mapCybusNodes = (nodes: NodeProxyParams[], { server }: SubscriptionFilterArgs): CybusNode[] =>
    nodes.reduce<CybusNode[]>((r, params) => {
        const node = mapCybusNode(params)

        if (!server || server === node.parent) {
            r.push(node)
        }

        return r
    }, [])
