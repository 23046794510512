import { ExtendedStateManager } from 'react-extended-state'

import { type IntlShape } from '@formatjs/intl'

import { EventListener } from '../../utils'

import {
    type AppState,
    Capability,
    ConnectwareError,
    ConnectwareErrorType,
    ResourceType,
    selectAuthenticatedCapabilitiesWithDefault,
    selectAuthenticatedToken,
    selectAuthenticatedUsername,
    selectMfaRequiredAuthenticationToken,
} from '../../domain'

import type {
    ApplicationStateService,
    AuthenticationPersistenceService,
    AuthenticationService,
    CertificatesService,
    ClientRegistryService,
    ConfigurationService,
    ConnectwareLogsService,
    ConnectwareResourcesService,
    ConnectwareServicesCatalogService,
    ConnectwareServicesService,
    FileService,
    LoggerService,
    MfaService,
    RuleEngineService,
    SubscriptionsService,
    SystemService,
    TablePersistenceService,
    TopicsPersistenceService,
    TopicsService,
    TranslationService,
    UsecaseServices,
    UserService,
} from '../../application'

import { HTML5BrowserService } from '../HTML5'
import { createTranslationInternationalization, FormatJSTranslationService } from '../FormatJS'
import { WebStorageAuthenticationPersistenceService, WebStorageTablePersistenceService, WebStorageTopicsPersistenceService } from '../webstorage'
import { WebFileService } from '../FileService'
import { CybusConfigurationService } from '../CybusConfiguration'
import { MixedConnectwareResourcesService, MixedSubscriptionsService } from './Mixin'
import {
    ConnectwareHTTPAuthenticationService,
    ConnectwareHTTPCertificatesService,
    ConnectwareHTTPClientRegistryService,
    ConnectwareHTTPLogsService,
    ConnectwareHTTPMfaService,
    ConnectwareHTTPResourcesService,
    ConnectwareHTTPRuleEngineService,
    ConnectwareHTTPServicesCatalogService,
    ConnectwareHTTPServicesService,
    ConnectwareHTTPSubscriptionsService,
    ConnectwareHTTPTopicsService,
    ConnectwareHTTPUserService,
    CybusSystemService,
} from '../http'
import { type MqttMessageFromWorker, type MqttMessageToWorker, MqttTopicsService } from '../mqtt'
import { VrpcConnectwareResourcesService, VrpcDomainType, VrpcRemoteManager, VrpcSubscriptionsService } from '../vrpc'
import type { TranslationProviderProps } from '../react/Internationalization'
import { WrappedWorker } from '../workers'
import type { ConnectwareAdminWebAppConfig } from '../config'

import { getMqttWorkerRef } from './workers'

/**
 * This class is here to enable the PermissionSplitter to do its job
 * But it also collects the many tecnologies used on the Admin UI
 * to be injected into the application's usecases
 */
class InjectedServices implements UsecaseServices {
    readonly subscriptionsService: SubscriptionsService

    readonly connectwareResourcesService: ConnectwareResourcesService

    readonly connectwareLogsService: ConnectwareLogsService

    readonly connectwareServicesCatalogService: ConnectwareServicesCatalogService

    readonly connectwareServicesService: ConnectwareServicesService

    readonly logger: LoggerService

    readonly applicationState: ApplicationStateService

    readonly authenticationService: AuthenticationService

    readonly browserService: HTML5BrowserService

    readonly authenticationPersistenceService: AuthenticationPersistenceService

    readonly userService: UserService

    readonly clientRegistryService: ClientRegistryService

    readonly configurationService: ConfigurationService

    readonly systemService: SystemService

    readonly fileService: FileService

    readonly translationService: TranslationService

    readonly topicsService: TopicsService

    readonly ruleEngineService: RuleEngineService

    readonly topicsPersistenceService: TopicsPersistenceService

    readonly certificatesService: CertificatesService

    readonly tablePersistenceService: TablePersistenceService

    readonly mfaService: MfaService

    constructor (applicationState: ExtendedStateManager<AppState>, logger: LoggerService, intl: IntlShape<string>, config: ConnectwareAdminWebAppConfig) {
        const {
            version,
            salesEmail,
            supportEmail,
            servicesCatalogDefaultImageLink,
            protectedRoles,
            protectedUsers,
            userManagementPagination,
            userManagementIndexCache,
            usernamePolicy,
            backendOrigin,
            vrpc,
            mqtt,
        } = config

        /**
         * Services that are used in both react and usecases
         */
        this.applicationState = applicationState
        this.logger = logger

        const createStateSelector =
            <T, A extends unknown[]>(selector: (s: AppState, ...args: A) => T): ((...args: A) => T) =>
            (...args) =>
                selector(this.applicationState.getState(), ...args)

        const selectCapabilities = createStateSelector(selectAuthenticatedCapabilitiesWithDefault)
        const tokenSelector = createStateSelector(selectAuthenticatedToken)
        const tokenOrMfaTokenSelector = createStateSelector((s) => selectMfaRequiredAuthenticationToken(s) ?? selectAuthenticatedToken(s))
        const baseMqttAuthenticationSelector = createStateSelector((state, path: string) => {
            const username = selectAuthenticatedUsername(state)
            const password = selectAuthenticatedToken(state)

            if (password === null || username === null) {
                throw new ConnectwareError(ConnectwareErrorType.AUTHENTICATION, 'Authentication is missing')
            }

            return { url: this.getMqttUrl(path), username, password }
        })

        this.translationService = new FormatJSTranslationService(intl)

        /**
         * Web services
         */
        this.browserService = new HTML5BrowserService(logger, backendOrigin)
        this.fileService = new WebFileService()
        this.authenticationPersistenceService = new WebStorageAuthenticationPersistenceService(window.localStorage, version)
        this.topicsPersistenceService = new WebStorageTopicsPersistenceService(window.localStorage, version)
        this.tablePersistenceService = new WebStorageTablePersistenceService(window.localStorage, version)

        const { origin, host } = this.browserService

        /**
         * Due to how changes are expected on the domain and business layers, propagating resource changes SHOULD ONLY
         * be done under the hood and without extra work from the rendering layer
         *
         * This listener hooks "the http resource service" to "the http subscription service" and
         * ensures that whenever a service changes, there will be an immediate change on the UI
         */
        const resourceChangeListeners = new EventListener<void>()

        /**
         * Http services
         */
        this.ruleEngineService = new ConnectwareHTTPRuleEngineService(origin, tokenSelector)
        this.userService = new ConnectwareHTTPUserService(
            origin,
            tokenSelector,
            {
                usersConfig: { protectedRoles: new Set(protectedRoles), protectedUsers: new Set(protectedUsers) },
                pagination: userManagementPagination,
                cacheInterval: userManagementIndexCache,
                usernamePolicy,
            },
            this.translationService
        )
        this.clientRegistryService = new ConnectwareHTTPClientRegistryService(origin, tokenSelector, this.translationService)
        this.authenticationService = new ConnectwareHTTPAuthenticationService(origin, tokenSelector)
        this.configurationService = new CybusConfigurationService(
            { origin, host, salesEmail, supportEmail, mqttEndpoints: [this.getMqttUrl(), this.getMqttUrl(vrpc.path), this.getMqttUrl(mqtt.path)] },
            new ConnectwareHTTPTopicsService(origin, tokenSelector, this.translationService)
        )
        this.systemService = new CybusSystemService(origin, tokenSelector, version)
        this.certificatesService = new ConnectwareHTTPCertificatesService(origin, tokenSelector)
        const httpSubscriptionsService = new ConnectwareHTTPSubscriptionsService(
            origin,
            tokenSelector,
            selectCapabilities,
            this.translationService,
            resourceChangeListeners
        )
        const httpConnectwareResourcesService = new ConnectwareHTTPResourcesService(origin, tokenSelector, this.translationService, resourceChangeListeners)
        this.connectwareLogsService = new ConnectwareHTTPLogsService(origin, tokenSelector, this.translationService)
        this.connectwareServicesCatalogService = new ConnectwareHTTPServicesCatalogService(
            origin,
            tokenSelector,
            this.configurationService,
            this.logger,
            servicesCatalogDefaultImageLink
        )
        this.connectwareServicesService = new ConnectwareHTTPServicesService(origin, tokenSelector, this.configurationService, resourceChangeListeners)
        this.mfaService = new ConnectwareHTTPMfaService(origin, tokenOrMfaTokenSelector, this.translationService)

        /**
         * Mqtt services
         */
        this.topicsService = new MqttTopicsService(() => ({
            ...baseMqttAuthenticationSelector(mqtt.path),
            cycle: mqtt.cycle,
            maxTopicDepth: mqtt.maxTopicDepth,
            timeout: mqtt.timeout,
            worker: new WrappedWorker<MqttMessageToWorker, MqttMessageFromWorker>(getMqttWorkerRef()),
        }))

        /**
         * Vrpc services
         *
         * Wait a full second before disconnection
         */
        const remote = new VrpcRemoteManager({
            remoteArgs: () => {
                const { url, ...args } = baseMqttAuthenticationSelector(vrpc.path)
                return { ...args, timeout: vrpc.timeout, broker: url.toString(), bestEffort: true }
            },
            agentLoadGracePeriod: 500,
            disconnectGracePeriod: 5_000,
            logger,
            domains: { [VrpcDomainType.DEFAULT]: 'internal.cybus', [VrpcDomainType.EDGE]: 'edge.cybus' },
        })

        const vrpcConnectwareResourcesService = new VrpcConnectwareResourcesService(remote, this.translationService)
        const vrpcSubscriptionsService = new VrpcSubscriptionsService(remote, this.translationService)

        this.subscriptionsService = new MixedSubscriptionsService(
            {},
            selectCapabilities,
            { [Capability.LEGACY_ADMIN_PERMISSION]: vrpcSubscriptionsService },
            httpSubscriptionsService
        )

        this.connectwareResourcesService = new MixedConnectwareResourcesService(
            { [ResourceType.CORE_CONTAINER]: httpConnectwareResourcesService, [ResourceType.SERVICE]: httpConnectwareResourcesService },
            selectCapabilities,
            { [Capability.LEGACY_ADMIN_PERMISSION]: vrpcConnectwareResourcesService },
            httpConnectwareResourcesService
        )
    }

    private getMqttUrl (path?: string): URL {
        let url = new URL(`wss:${this.browserService.host}`)
        if (path) {
            url = new URL(path, url)
        }
        return url
    }
}

export const resolveInjections = (
    state: AppState,
    config: ConnectwareAdminWebAppConfig
): [UsecaseServices, ExtendedStateManager<AppState>, TranslationProviderProps['internationalization']] => {
    const applicationState = new ExtendedStateManager<AppState>(state)
    const logger: LoggerService = console
    const internationalization = createTranslationInternationalization(config.translation, logger)

    return [new InjectedServices(applicationState, logger, internationalization, config), applicationState, internationalization]
}
