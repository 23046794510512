import React, { type FC } from 'react'

import { ListItemIcon, ListItemText, type SxProps, type Theme } from '@mui/material'
import { type SvgIconComponent } from '@mui/icons-material'

import { type Translation } from '../../../../domain'

import { useTranslator } from '../../Internationalization'
import { sideMenuIconStyle } from './Styles'

const textStyle: SxProps<Theme> = { color: 'secondary.contrastText' }
const listIconStyle: SxProps<Theme> = { ...sideMenuIconStyle, minWidth: 'fit-content', mr: 2 }

export const Icon: FC<Readonly<{ icon: SvgIconComponent }>> = ({ icon: Icon }) => (
    <ListItemIcon sx={listIconStyle}>
        <Icon />
    </ListItemIcon>
)

export const Text: FC<Readonly<{ title: Translation, isTitleSingular?: boolean, description?: Translation, inset?: boolean }>> = ({
    title,
    isTitleSingular,
    description,
    inset,
}) => {
    const translator = useTranslator()

    return (
        <ListItemText
            sx={textStyle}
            primary={translator.formatTranslation(title, { count: isTitleSingular ? 1 : 0 })}
            primaryTypographyProps={{ variant: 'subtitle1' }}
            title={description && translator.formatTranslation(description)}
            inset={inset}
        />
    )
}
