import { ConnectwareError } from '../..'
import { type ClientRegistry, type ClientRegistryData, selectUsersManagementRegistry, type UsersManagementAppState } from '..'

export const selectUsersManagementRegistryData = (s: UsersManagementAppState): ClientRegistry['data'] => selectUsersManagementRegistry(s).data

export const selectIsUsersManagementRegistryLoading = (s: UsersManagementAppState): boolean => selectUsersManagementRegistryData(s) === null

export const selectUsersManagementRegistryError = (s: UsersManagementAppState): ConnectwareError | null => {
    const data = selectUsersManagementRegistryData(s)
    return ConnectwareError.is(data) ? data : null
}

export const selectUsersManagementRegistryLock = (s: UsersManagementAppState): ClientRegistry['locked'] => selectUsersManagementRegistry(s).locked

export const selectIsUsersManagementRegistryLocked = (s: UsersManagementAppState): ClientRegistry['locked'] => {
    const locked = selectUsersManagementRegistryLock(s)
    return locked === null || locked
}

const createLoadedRegistrySelector =
    <P extends keyof ClientRegistryData, T>(propName: P, defaultValue: T): ((s: UsersManagementAppState) => ClientRegistryData[P] | T) =>
    (s) => {
        const data = selectUsersManagementRegistryData(s)
        return data === null || ConnectwareError.is(data) ? defaultValue : data[propName]
    }

export const selectLoadedRegistryUnlockedUntil = createLoadedRegistrySelector('unlockedUntil', null)

export const selectLoadedRegistryRequests = createLoadedRegistrySelector('requests', [])
