import { areArrayEquals, Droppable } from '../../../utils'
import {
    type AppState,
    BufferType,
    ConnectwareError,
    ConnectwareErrorType,
    isTopicSubscriptionResponseSuccessful,
    selectSandbox,
    type TopicMessageEvent,
    Translation,
} from '../../../domain'
import type { TopicsSubscription } from '../..'
import { BaseSandboxUsecase } from './Base'

export class SubscribeToMessagesSandboxUsecase extends BaseSandboxUsecase {
    private static mapInput (message: ConnectwareError | TopicMessageEvent): Pick<AppState['ruleEngineSandbox']['input'], 'topic' | 'value'> {
        if (ConnectwareError.is(message)) {
            /** Error, just pass it on */
            return { topic: null, value: message }
        }

        const { possibleTypes, topic, payload } = message
        return { topic, value: possibleTypes.includes(BufferType.OBJECT) ? JSON.parse(payload.toString()) : payload.toString() }
    }

    private updateInput (message: ConnectwareError | TopicMessageEvent): void {
        this.setSandbox({ input: { loaded: true, ...SubscribeToMessagesSandboxUsecase.mapInput(message) } })
    }

    private propagateStateChanges (subscription: TopicsSubscription): VoidFunction {
        const droppable = new Droppable()

        const updateSubscription: VoidFunction = () => {
            const { selectedTopics } = selectSandbox(this.getState())
            if (selectedTopics) {
                void subscription.subscribe(selectedTopics).then((response) => {
                    if (isTopicSubscriptionResponseSuccessful(response)) {
                        return
                    }

                    const { selectedEndpoints } = selectSandbox(this.getState())

                    /** Topic inputed/endpoints selected by the user causes issues with-in the client */
                    this.updateInput(
                        new ConnectwareError(
                            selectedEndpoints ? ConnectwareErrorType.AUTHENTICATION : ConnectwareErrorType.BAD_REQUEST,
                            this.translationService.translate(Translation.SANDBOX_SUBSCRIPTION_ISSUE, {
                                endpoints: selectedEndpoints && selectedEndpoints.length,
                            }),
                            response
                        )
                    )
                })
            }
        }

        updateSubscription()

        /** Listen to state subscription changes */
        droppable.onDrop(
            this.subscribeToState(
                (previous, current) => areArrayEquals(selectSandbox(previous).selectedTopics || [], selectSandbox(current).selectedTopics || []),
                updateSubscription
            )
        )

        return () => droppable.drop()
    }

    subscribe (): VoidFunction {
        const droppable = new Droppable()

        const subscription = this.topicsService.create()

        /** Once dropped subscription goes away */
        droppable.onDrop(() => subscription.end())

        /** Listen to state changes */
        droppable.onDrop(this.propagateStateChanges(subscription))

        /** Handle messages */
        droppable.onDrop(subscription.onLastMessage((message) => this.updateInput(message)))

        /** Handle errors */
        droppable.onDrop(subscription.onError((error) => this.updateInput(error)))

        return () => droppable.drop()
    }
}
