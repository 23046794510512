import React, { type FC } from 'react'
import { Grid, Paper, type SxProps, type Theme, Typography } from '@mui/material'

import { BufferType, type TailedTopicMessage } from '../../../../domain'

import { FormattedDateTime } from '../../Internationalization'

import { FormattedBuffer, useBufferTypeTranslator } from '../../common'

const wrapperStyle: SxProps<Theme> = {
    mb: 1,
    py: 0.5,
    px: 0,
    '& > * > :first-of-type': { pl: 1 },
    '& > * > :last-of-type': { pr: 1 },
}
const longTextStyle: SxProps<Theme> = { overflowX: 'hidden', textOverflow: 'ellipsis', pr: 1 }
const verticalAlignedStyle: SxProps<Theme> = { mt: 'auto', mb: 'auto' }

export const TailLine: FC<TailedTopicMessage> = ({ topic, timestamp, payload, selectedType }) => {
    const translate = useBufferTypeTranslator()

    return (
        <Paper sx={wrapperStyle} variant="outlined">
            <Grid container>
                <Grid sx={verticalAlignedStyle} item xs={2}>
                    <Typography variant="caption">
                        <FormattedDateTime date={timestamp} format="datetime" />
                    </Typography>
                </Grid>
                <Grid sx={verticalAlignedStyle} item xs={5}>
                    <Typography sx={longTextStyle} title={topic} variant="subtitle1">
                        {topic}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <FormattedBuffer sx={[selectedType === BufferType.OBJECT ? {} : longTextStyle]} buffer={payload} type={selectedType} />
                </Grid>
                <Grid sx={verticalAlignedStyle} item xs={1}>
                    {translate(selectedType)}
                </Grid>
            </Grid>
        </Paper>
    )
}
