import React, { type FC } from 'react'

import { Box, type SxProps, type Theme } from '@mui/material'

import { type CybusVolume, Translation } from '../../../domain'

import { PermissionedLink, RelativeRoutePathWithId } from '../routing'
import { FormattedList, useTranslator } from '../Internationalization'
import { DetailsEntry } from '../Details'

const linkStyle: SxProps<Theme> = { '& > a': { color: 'primary.700', textDecoration: 'unset' } }

export const VolumeLinksEntry: FC<{ volumes: CybusVolume['id'][], linkVolumes: boolean }> = ({ volumes, linkVolumes }) => {
    const translator = useTranslator()

    if (!volumes.length) {
        return null
    }

    return (
        <DetailsEntry title={translator.formatTranslation(Translation.VOLUME, { count: volumes.length })}>
            <Box sx={linkStyle} data-testid="volume-links">
                <FormattedList
                    type="conjunction"
                    style="long"
                    value={
                        linkVolumes
                            ? volumes.map((volume, k) => (
                                  <PermissionedLink key={k} path={RelativeRoutePathWithId.VOLUME} id={volume}>
                                      {volume}
                                  </PermissionedLink>
                              ))
                            : volumes
                    }
                />
            </Box>
        </DetailsEntry>
    )
}
