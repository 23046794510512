import React, { type FC, useState } from 'react'
import { Box, type SxProps, type Theme } from '@mui/material'

const BASE_TOOLBAR_HEIGHT = 40

const toolbarStyle = (height: number): SxProps<Theme> => ({
    display: 'flex',
    /**
     * Why are these hacky styles here?
     *
     * While the mui-datatables is used,
     * the only way to display an elevation that does not affect the toolbar
     * is by moving it upwards outside of the paper effect
     *
     * Yes, this is very ugly, but so is mui-datatables
     * Once that is disposed, so should this be
     */
    position: 'relative',
    top: `-${height + 12}px`,
    marginTop: `${height + 24}px`,
    marginBottom: `-${height}px`,
})

export const Toolbar: FC<{ position: 'start' | 'space-between', align?: 'start' | 'center' }> = ({ position, align = 'center', children }) => {
    const [toolbarRef, setToolbarRef] = useState<HTMLDivElement | null>(null)

    return (
        <Box
            ref={setToolbarRef}
            sx={{ ...toolbarStyle(toolbarRef?.getBoundingClientRect().height ?? BASE_TOOLBAR_HEIGHT), justifyContent: position, alignItems: align }}
        >
            {children}
        </Box>
    )
}
