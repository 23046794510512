/* eslint-disable typescript-sort-keys/string-enum */

export enum AbsoluteRouteOnlyPath {
    SERVICES = 'SERVICES',
    SERVICES_LINKS = 'SERVICES_LINKS',
    SERVICE_LOGS = 'SERVICE_LOGS',

    RESOURCES = 'RESOURCES',
    RESOURCES_SERVERS = 'RESOURCES_SERVERS',
    RESOURCES_CONTAINERS = 'RESOURCES_CONTAINERS',
    RESOURCES_VOLUMES = 'RESOURCES_VOLUMES',
    RESOURCES_CONNECTIONS = 'RESOURCES_CONNECTIONS',
    RESOURCES_ENDPOINTS = 'RESOURCES_ENDPOINTS',
    RESOURCES_MAPPINGS = 'RESOURCES_MAPPINGS',

    USER_MANAGEMENT = 'USER_MANAGEMENT',
    CLIENT_REGISTRY = 'CLIENT_REGISTRY',
    CERTIFICATES = 'CERTIFICATES',

    CATALOG = 'CATALOG',

    EXPLORER = 'EXPLORER',
    RULE_ENGINE_SANDBOX = 'RULE_ENGINE_SANDBOX',

    METRICS = 'METRICS',
    STATUSES = 'STATUSES',
    AGENTS = 'AGENTS',
    INFO = 'INFO',
    MAINTENANCE = 'MAINTENANCE',

    SETTINGS = 'SETTINGS',
    LOGIN_SETTINGS = 'LOGIN_SETTINGS',
}

export enum AbsoluteRoutePathWithServiceId {
    SERVICE = 'SERVICE',
}

export enum AbsoluteRoutePathWithId {
    RESOURCES_SERVICE = 'RESOURCES_SERVICE',
    RESOURCES_SERVER = 'RESOURCES_SERVER',
    RESOURCES_CONTAINER = 'RESOURCES_CONTAINER',
    RESOURCES_VOLUME = 'RESOURCES_VOLUME',
    RESOURCES_CONNECTION = 'RESOURCES_CONNECTION',
    RESOURCES_ENDPOINT = 'RESOURCES_ENDPOINT',
    RESOURCES_MAPPING = 'RESOURCES_MAPPING',
    RESOURCES_STATUS = 'RESOURCES_STATUS',
}

export enum AbsoluteRoutePathWithServiceAndResourceId {
    SERVICE_SERVER = 'SERVICE_SERVER',
    SERVICE_CONTAINER = 'SERVICE_CONTAINER',
    SERVICE_VOLUME = 'SERVICE_VOLUME',
    SERVICE_CONNECTION = 'SERVICE_CONNECTION',
    SERVICE_ENDPOINT = 'SERVICE_ENDPOINT',
    SERVICE_MAPPING = 'SERVICE_MAPPING',
}

export type AbsoluteRoutePath = AbsoluteRoutePathWithId | AbsoluteRouteOnlyPath | AbsoluteRoutePathWithServiceId | AbsoluteRoutePathWithServiceAndResourceId

export enum RelativeRoutePathWithId {
    SERVER = 'REL_SERVER',
    CONTAINER = 'REL_CONTAINER',
    VOLUME = 'REL_VOLUME',
    CONNECTION = 'REL_CONNECTION',
    ENDPOINT = 'REL_ENDPOINT',
    MAPPING = 'REL_MAPPING',
    SERVICE = 'REL_SERVICE',
}

export type RouterState = Readonly<
    | { path: AbsoluteRouteOnlyPath, resourceId: null, serviceId: null }
    | { path: AbsoluteRoutePathWithId, resourceId: string, serviceId: null }
    | { path: AbsoluteRoutePathWithServiceId, resourceId: null, serviceId: string }
    | { path: AbsoluteRoutePathWithServiceAndResourceId, resourceId: string, serviceId: string }
>

export type AbsoluteRouteUpdateArgs =
    | [onlyPath: AbsoluteRouteOnlyPath]
    | [pathWithId: AbsoluteRoutePathWithId | AbsoluteRoutePathWithServiceId | AbsoluteRoutePathWithServiceAndResourceId, id: string]

export type RouteUpdateArgs = AbsoluteRouteUpdateArgs | [pathWithId: RelativeRoutePathWithId, id: string]
