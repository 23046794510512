import React, { type FC } from 'react'
import { type SxProps, TextField, type Theme, Typography } from '@mui/material'

import { selectExplorerTailSize, Translation } from '../../../../domain'

import { useAppState, useAppUsecase } from '../../State'
import { FormattedTranslation } from '../../Internationalization'

const titleStyle: SxProps<Theme> = { my: 'auto', mx: 1, lineHeight: 3 }
const fieldStyle: SxProps<Theme> = { width: 80, justifyContent: 'center' }

export const TailSize: FC = () => {
    const tailSize = useAppState(selectExplorerTailSize)
    const updateTail = useAppUsecase('updateExplorerTailUsecase')

    return (
        <>
            <Typography sx={titleStyle} variant="subtitle1">
                <FormattedTranslation id={Translation.TAIL_SIZE} />
            </Typography>
            <TextField
                id="topicsTailSize"
                sx={fieldStyle}
                variant="outlined"
                type="number"
                size="small"
                value={tailSize}
                onChange={({ target: { value } }) => updateTail.updateTailSize(Math.round(Number(value)))}
            />
        </>
    )
}
