import React, { type FC } from 'react'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    Link,
    ListItem,
    ListItemText,
    type SxProps,
    type Theme,
    Typography,
} from '@mui/material'

import { isEnumOf } from '../../../../utils'
import { ConnectwareError, CybusCatalogApplicationCategory, Translation } from '../../../../domain'
import { DocumentationType } from '../../../../application'

import { createFormatter, FormattedTranslation } from '../../Internationalization'
import { useAppUsecases } from '../../State'
import { useAsyncCallback } from '../../Callback'
import { useDocumentation } from '../../Documentation'
import { ErrorMessage } from '../../ErrorMessage'

import { useCategoryTranslation, useFilteredApplications, useTemplate } from './Hooks'
import { ServiceCard } from './Card'

const howToParagraphStyle: SxProps<Theme> = { mb: 2 }
const templatesStyle: SxProps<Theme> = { display: 'flex' }
const actionsStyle: SxProps<Theme> = { '& > *': { my: 'auto' } }
const errorStyle: SxProps<Theme> = { mr: 'auto' }

const Paragraph: FC = ({ children }) => (
    <ListItem>
        <ListItemText secondary={<Typography>{children}</Typography>} />
    </ListItem>
)

export const TemplateDialog: FC = () => {
    const translateCategory = useCategoryTranslation()

    const { downloadServiceCatalogTemplateUsecase } = useAppUsecases()
    const openCommissioningFile = useDocumentation(DocumentationType.SERVICES_COMMISSIONING_FILE)
    const openCybusLearn = useDocumentation(DocumentationType.CYBUS_LEARN)

    const filtered = useFilteredApplications()
    const template = useTemplate()

    const [download, loading, value] = useAsyncCallback(() => downloadServiceCatalogTemplateUsecase.download(), [downloadServiceCatalogTemplateUsecase])

    if (template === null) {
        return <></>
    }

    const isEnum = isEnumOf(CybusCatalogApplicationCategory, template)

    const category = isEnum ? template : template.category
    const close: VoidFunction = () => downloadServiceCatalogTemplateUsecase.close()

    return (
        <Dialog id="service-catalog-template-dialog" fullWidth disableEscapeKeyDown onClose={close} maxWidth="md" open>
            <DialogTitle>
                <FormattedTranslation id={Translation.CREATE_YOUR_OWN_SERVICE} />
            </DialogTitle>
            {loading ? <LinearProgress variant="indeterminate" /> : <LinearProgress variant="determinate" value={0} />}
            <DialogContent>
                <Box id="template-how-to" sx={howToParagraphStyle}>
                    <Typography variant="h5">
                        <FormattedTranslation id={Translation.HOW_TO} />
                    </Typography>
                    <FormattedTranslation
                        id={Translation.TEMPLATE_COMMISSIONING_FILE_DOWNLOAD_HOW_TO}
                        values={{
                            p: createFormatter(Paragraph, {}),
                            commissioningFileLink: createFormatter(Link, { onClick: openCommissioningFile }),
                            cybusLearnLink: createFormatter(Link, { onClick: openCybusLearn }),
                        }}
                    />
                </Box>
                <Typography variant="h5">
                    <FormattedTranslation id={Translation.TEMPLATES_FROM_CATEGORY} values={{ category: translateCategory(category) }} />
                </Typography>
                <Box sx={templatesStyle}>
                    {filtered.getTemplates(category).map((service, k) => (
                        <ServiceCard
                            key={k}
                            service={service}
                            selected={template === service}
                            onClick={() => downloadServiceCatalogTemplateUsecase.selectTemplate(service)}
                        />
                    ))}
                </Box>
            </DialogContent>
            <DialogActions sx={actionsStyle}>
                {!loading && ConnectwareError.is(value) && (
                    <ErrorMessage id="service-catalog-template-error" sx={errorStyle} titleVariant="body2" error={value} />
                )}
                <Button color="primary" variant="contained" onClick={close}>
                    <FormattedTranslation id={Translation.CLOSE} />
                </Button>
                <Button color="secondary" variant="contained" onClick={download} disabled={isEnum}>
                    <FormattedTranslation id={Translation.DOWNLOAD_TEMPLATE} />
                </Button>
            </DialogActions>
        </Dialog>
    )
}
