import React from 'react'
import ReactDOM from 'react-dom'

import { createUsecases, initialState } from '../../application'

import { App, AppWrapper, cybusTheme } from '../react'

import configPromise from './config'

import { resolveInjections } from './injections'

void configPromise.then((config) => {
    const [services, manager, internationalization] = resolveInjections(initialState, config)
    const usecases = createUsecases(services)

    ReactDOM.render(
        <AppWrapper usecases={usecases} manager={manager} internationalization={internationalization} theme={cybusTheme}>
            <App />
        </AppWrapper>,
        document.getElementById('root')
    )
})
