type ListenerArgs<K extends keyof WorkerEventMap> = [type: K, listener: (ev: WorkerEventMap[K]) => unknown]

export type RelevantWorker = Pick<Worker, 'postMessage' | 'terminate'> &
    Readonly<{
        /**
         * @see {Worker['addEventListener']}
         */
        addEventListener(...args: ListenerArgs<'message'>): void
        /**
         * @see {Worker['removeEventListener']}
         */
        removeEventListener(...args: ListenerArgs<'message'>): void
    }>

export type RelevantTopFrame = Pick<Window, 'postMessage'> &
    Readonly<{
        /**
         * @see {Window['addEventListener']}
         */
        addEventListener(...args: ListenerArgs<'message'>): void
        /**
         * @see {Window['removeEventListener']}
         */
        removeEventListener(...args: ListenerArgs<'message'>): void
    }>

abstract class BaseMessenger<I, O, W extends RelevantWorker | RelevantTopFrame> {
    constructor (protected readonly worker: W) {}

    protected addEventListener (handler: (message: I) => void): VoidFunction {
        const listener = (e: MessageEvent): void => handler(e.data as I)
        this.worker.addEventListener('message', listener)
        return () => this.worker.removeEventListener('message', listener)
    }

    send (message: O): void {
        this.worker.postMessage(message)
    }
}

/**
 * Use this to wrap your top frame reference from the worker's PoV
 * With this wrapper now you are forced to send strongly typed messages
 * and therefore should expect to recieve strongly typed messages back
 */
export class TopFrameWrapper<ToTopFrame, FromTopFrame> extends BaseMessenger<FromTopFrame, ToTopFrame, RelevantTopFrame> {
    listen (handler: (message: FromTopFrame) => void): void {
        this.addEventListener(handler)
    }
}

/**
 * Use this to wrap your worker from the top frame PoV
 * With this wrapper now you are forced to send strongly typed messages
 * and therefore should expect to recieve strongly typed messages back
 */
export class WrappedWorker<ToWorker, FromWorker> extends BaseMessenger<FromWorker, ToWorker, RelevantWorker> {
    listen (handler: (data: FromWorker) => void): VoidFunction {
        return this.addEventListener(handler)
    }

    terminate (): void {
        this.worker.terminate()
    }
}
