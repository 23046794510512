import type { StatusType } from '../../../../../../domain'

import { mapToStatusType } from '../../../../../Connectware'
import type { EndpointProxy, MappingProxy, NodeProxy } from '../../../../proxies'
import { ENDPOINT_CLASSNAME_FILTER, MAPPING_CLASSNAME_FILTER, NODE_CLASSNAME_FILTER } from '../../../../constants'
import { listenToProxy, type UnsubFromInstanceFunction, type VrcpEntityHandler, type VrpcHandlerConfiguration, type VrpcInstanceMapper } from '../Handler'

class VrpcProxyInstanceStatusMapper implements VrpcInstanceMapper<MappingProxy | EndpointProxy | NodeProxy, StatusType> {
    mapToDomain (proxy: MappingProxy | EndpointProxy | NodeProxy): Promise<StatusType> {
        return proxy.getState().then(mapToStatusType)
    }

    onChange (proxy: MappingProxy | EndpointProxy | NodeProxy, listener: () => void): Promise<UnsubFromInstanceFunction> {
        return listenToProxy(proxy, ['state'], listener)
    }
}

export class VrpcMappingProxyInstanceStatusHandler implements VrcpEntityHandler<MappingProxy, StatusType> {
    readonly instanceMapper = new VrpcProxyInstanceStatusMapper()

    readonly remoteMapper = null

    readonly configuration: VrpcHandlerConfiguration = { classNameFilter: MAPPING_CLASSNAME_FILTER, supportedFilters: [] }
}

export class VrpcEndpointProxyInstanceStatusHandler implements VrcpEntityHandler<EndpointProxy, StatusType> {
    readonly instanceMapper = new VrpcProxyInstanceStatusMapper()

    readonly remoteMapper = null

    readonly configuration: VrpcHandlerConfiguration = { classNameFilter: ENDPOINT_CLASSNAME_FILTER, supportedFilters: [] }
}

export class VrpcNodeProxyInstanceStatusHandler implements VrcpEntityHandler<NodeProxy, StatusType> {
    readonly instanceMapper = new VrpcProxyInstanceStatusMapper()

    readonly remoteMapper = null

    readonly configuration: VrpcHandlerConfiguration = { classNameFilter: NODE_CLASSNAME_FILTER, supportedFilters: [] }
}
