import React, { type FC } from 'react'

import { canServerBeDeleted, canServerBeDisabled, canServerBeEnabled, Capability, type CybusServer, StatusType, Translation } from '../../../../domain'

import { useAppUsecases } from '../../State'
import { DetailsActionButton } from '../../Details'

const disableButtonLabels: Partial<Record<StatusType, Translation>> = { [StatusType.ENABLED]: Translation.DISABLE, [StatusType.ENABLING]: Translation.ENABLING }

export const HeaderActions: FC<{ resource: CybusServer }> = ({ resource }) => {
    const { bulkDeleteServersUsecase, bulkEnableServersUsecase, bulkDisableServersUsecase } = useAppUsecases()

    return (
        <>
            <DetailsActionButton
                id="server-action-button"
                disabled={!canServerBeDisabled(resource) && !canServerBeEnabled(resource)}
                translation={disableButtonLabels[resource.status] || Translation.ENABLE}
                capabilities={[Capability.SERVERS_MANAGE]}
                onClick={
                    canServerBeDisabled(resource)
                        ? () => bulkDisableServersUsecase.selectServers([resource.id])
                        : () => bulkEnableServersUsecase.selectServers([resource.id])
                }
            />

            <DetailsActionButton
                id="delete-server-button"
                translation={Translation.DELETE}
                disabled={!canServerBeDeleted(resource)}
                capabilities={[Capability.SERVERS_MANAGE]}
                onClick={() => bulkDeleteServersUsecase.selectServers([resource.id])}
            />
        </>
    )
}
