import React, { type FC } from 'react'
import { Card, CardActionArea, CardContent, CardMedia, type SxProps, type Theme, Tooltip, Typography } from '@mui/material'
import { AddCircleOutline } from '@mui/icons-material'

import { type CybusCatalogApplication, Translation } from '../../../../domain'

import { useTranslator } from '../../Internationalization'

import { useCategoryTranslation } from './Hooks'

const cardStyle: SxProps<Theme> = { width: '200px', height: '200px', m: 3 }
const cardAreaStyle: SxProps<Theme> = { height: '100%' }
const selectedStyle: SxProps<Theme> = { ...cardStyle, border: '2px solid', borderColor: 'secondary.main' }

const serviceIconStyle: SxProps<Theme> = { height: '40%', objectFit: 'scale-down', p: 1 }
const serviceInfoStyle: SxProps<Theme> = { display: 'flex', flexDirection: 'column', '& > *': { textOverflow: 'ellipsis', overflow: 'hidden' } }
const serviceapplicationNameStyle: SxProps<Theme> = {
    display: '-webkit-inline-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
    fontSize: '1.2rem',
}

const templateIconStyle: SxProps<Theme> = { height: '50%', width: '100%', color: 'primary.dark' }

const BaseCard: FC<Readonly<{ title: string, selected?: boolean, onClick: VoidFunction }>> = ({ title, selected, onClick, children }) => {
    return (
        <Tooltip title={title}>
            <Card sx={selected ? selectedStyle : cardStyle} raised>
                <CardActionArea sx={cardAreaStyle} onClick={onClick}>
                    {children}
                </CardActionArea>
            </Card>
        </Tooltip>
    )
}

export const ServiceCard: FC<{ service: CybusCatalogApplication, selected?: boolean, onClick: VoidFunction }> = ({
    service: { name, icon, provider },
    ...props
}) => (
    <BaseCard {...props} title={name}>
        <CardMedia sx={serviceIconStyle} component="img" src={icon} title={name} />
        <CardContent sx={serviceInfoStyle}>
            <Typography data-testid="catalog-service-title" gutterBottom variant="h5" component="h2" sx={serviceapplicationNameStyle}>
                {name}
            </Typography>
            <Typography variant="body1" color="textSecondary" component="p">
                {provider}
            </Typography>
        </CardContent>
    </BaseCard>
)

export const TemplateCard: FC<{ onClick: VoidFunction } & Pick<CybusCatalogApplication, 'category'>> = ({ category, ...props }) => {
    const translator = useTranslator()
    const translateCategory = useCategoryTranslation()

    return (
        <BaseCard {...props} title={translator.formatTranslation(Translation.CREATE_NEW_CATEGORY_SERVICE, { category: translateCategory(category) })}>
            <AddCircleOutline sx={templateIconStyle} />
        </BaseCard>
    )
}
