import { ConnectwareError, ConnectwareErrorType, mapMfaState, type MfaAppState, type MfaSettings, type MfaSettingsState, selectMFaState } from '../../../domain'
import { Usecase } from '../Usecase'

export abstract class MfaUsecase extends Usecase {
    protected setMfa (mfa: MfaAppState['mfa']): void {
        this.setState(mapMfaState(mfa))
    }

    protected getMFaSettings<S extends MfaSettingsState> (...states: S[]): MfaSettings<S> {
        const state = this.getState()
        const mfa = selectMFaState(state, ...states)

        if (!mfa) {
            throw new ConnectwareError(ConnectwareErrorType.STATE, 'Mfa state is not in the expected state', { states })
        }

        return mfa
    }

    protected updateMfaSettings<S extends MfaSettingsState> (update: Pick<MfaSettings<S>, 'state'> & Partial<MfaSettings<S>>): void {
        this.setMfa({ ...this.getMFaSettings(update.state), ...update })
    }
}
