import React, { type FC } from 'react'
import { Box, type SxProps, type Theme, Typography } from '@mui/material'

import type { CybusMappingDetailedEntry } from '../../../../domain'

import { Json, NoReRenderOnHover } from '../../common'
import { ErrorPopover } from './ErrorPopover'

const wrapperStyle: SxProps<Theme> = { display: 'flex' }
const rulesStyle: SxProps<Theme> = { my: 'auto', mx: 0, maxWidth: 320, overflowX: 'auto' }
const errorStyle: SxProps<Theme> = { m: 'auto', mr: 0 }

export const Rules: FC<Pick<CybusMappingDetailedEntry, 'rules' | 'executionError'>> = ({ rules, executionError }) =>
    rules ? (
        <Box sx={wrapperStyle}>
            <NoReRenderOnHover sx={rulesStyle}>
                <Json src={rules} />
            </NoReRenderOnHover>
            {executionError && (
                <Box sx={errorStyle}>
                    <ErrorPopover executionError={executionError} />
                </Box>
            )}
        </Box>
    ) : (
        <Typography>-</Typography>
    )
