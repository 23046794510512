import React, { type FC } from 'react'
import { Alert, Box, type SxProps, type Theme, Typography } from '@mui/material'

import { type CommissioningFileParsingError, Translation } from '../../../../domain'

import { ErrorPopover } from './Error'

const alertStyle: SxProps<Theme> = { color: 'error.dark', display: 'flex', alignItems: 'center' }
const errorStyle: SxProps<Theme> = { display: 'flex', alignItems: 'baseline' }
const errorLabelStyle: SxProps<Theme> = { color: 'text.primary' }
const nonFirstChildStyle: SxProps<Theme> = { ...errorStyle, mt: 1 }
const propertyPathStyle: SxProps<Theme> = { color: 'error.dark', mr: 0.5 }

export const ParsingError: FC<Readonly<{ error: CommissioningFileParsingError }>> = ({ error }) => (
    <ErrorPopover linkTranslation={Translation.COMMISSIONING_FILE_PARSING_ERROR} linkTranslationValues={{ count: error.parseErrors.size }}>
        <Alert sx={alertStyle} severity="error" variant="outlined">
            {Array.from(error.parseErrors).map(([propertyPath, error], k) => (
                <Box key={k} sx={k > 0 ? nonFirstChildStyle : errorStyle}>
                    {propertyPath && (
                        <Typography sx={propertyPathStyle} variant="subtitle1">
                            {propertyPath}:
                        </Typography>
                    )}
                    <Typography sx={errorLabelStyle} variant="subtitle1">
                        {error}
                    </Typography>
                </Box>
            ))}
        </Alert>
    </ErrorPopover>
)
