import React, { type FC } from 'react'
import { Button, Dialog, DialogActions, DialogTitle, Grid, LinearProgress, type SxProps, type Theme } from '@mui/material'

import { CommissioningFileParsingError, ConnectwareServiceCreationError, Translation } from '../../../../domain'

import { FormattedTranslation, useTranslator } from '../../Internationalization'
import { ErrorMessage } from '../../ErrorMessage'

import { isOpen, useCancel, useConfirm, useConfirmationButtonText, useError, useId, useIsDisabled, useRequesting, useTitle } from './Hooks'
import { type ModalConfig, ModalConfigProvider } from './Context'
import { FileUploadSection } from './FileUpload'
import { FileLabel } from './FileLabel'
import { ConfigurationSection } from './ConfigurationSection'
import { ParsingError } from './ParsingError'
import { GenericError } from './GenericError'

const footerStyle: SxProps<Theme> = { display: 'flex', pl: 3, '& > *': { my: 'auto' } }
const actionsStyle: SxProps<Theme> = { ml: 'auto' }

const InternalModal: FC = () => {
    const translator = useTranslator()

    const open = isOpen()
    const title = useTitle()
    const requesting = useRequesting()
    const error = useError()
    const id = useId()
    const cancel = useCancel()
    const isDisabled = useIsDisabled()
    const confirm = useConfirm()
    const confirmationButtonText = useConfirmationButtonText()

    if (!open) {
        return null
    }

    return (
        <Dialog id="add-or-update-modal" maxWidth="lg" fullWidth disableEscapeKeyDown open>
            <Grid container>
                {/* Title */}
                <Grid item xs={12}>
                    <DialogTitle>
                        <FormattedTranslation id={title} />
                    </DialogTitle>
                    <LinearProgress variant={requesting === true ? 'indeterminate' : 'determinate'} value={0} />
                </Grid>
                {/* Main */}
                <Grid item xs={5}>
                    <FileUploadSection />
                    <FileLabel />
                </Grid>
                <Grid item xs={7}>
                    <ConfigurationSection />
                </Grid>
                {/* Error / Actions */}
                <Grid id="modal-footer" sx={footerStyle} item xs={12}>
                    {error && (
                        <ErrorMessage
                            id="error-column"
                            error={error}
                            titleVariant="body2"
                            customTitle={(e) =>
                                CommissioningFileParsingError.is(e) ? (
                                    <ParsingError error={e} />
                                ) : ConnectwareServiceCreationError.is(e) ? (
                                    <FormattedTranslation id={e.translation} values={{ id }} />
                                ) : (
                                    <GenericError error={e} />
                                )
                            }
                        />
                    )}
                    <DialogActions sx={actionsStyle}>
                        <Button
                            data-testid="add-or-update-modal-cancel"
                            aria-label={translator.formatTranslation(Translation.CANCEL)}
                            color="primary"
                            onClick={cancel}
                        >
                            <FormattedTranslation id={Translation.CANCEL} />
                        </Button>
                        <Button
                            data-testid="add-or-update-modal-confirm"
                            aria-label={translator.formatTranslation(confirmationButtonText)}
                            color="primary"
                            disabled={isDisabled}
                            onClick={confirm}
                        >
                            <FormattedTranslation id={confirmationButtonText} />
                        </Button>
                    </DialogActions>
                </Grid>
            </Grid>
        </Dialog>
    )
}

const config: Record<'CREATE' | 'EDIT', ModalConfig> = {
    CREATE: {
        title: Translation.SERVICE_CREATE,
        configurationTitle: Translation.SERVICE_CONFIGURE_NEW,
        confirmationButtonText: Translation.INSTALL,
        usecase: 'addServiceUsecase',
    },
    EDIT: {
        title: Translation.SERVICE_UPDATE_RECONFIGURE,
        configurationTitle: Translation.SERVICE_CONFIGURE_CURRENT,
        confirmationButtonText: Translation.UPDATE,
        usecase: 'updateServiceUsecase',
    },
}

export const AddOrUpdateModal: FC<{ mode: keyof typeof config } & Pick<ModalConfig, 'onConfirmed'>> = ({ mode, onConfirmed }) => (
    <ModalConfigProvider value={{ ...config[mode], onConfirmed }}>
        <InternalModal />
    </ModalConfigProvider>
)
