import React, { type FC } from 'react'

import {
    type CybusDetailedEndpoint,
    selectDetailedEndpoint,
    selectDisabableLoadedDetailedEndpoint,
    selectEnabableLoadedDetailedEndpoint,
    selectLoadedDetailedEndpoint,
    Translation,
} from '../../../../domain'

import { useRouting } from '../../routing'

import { ResourceDetails } from '../../Resources'
import { Content } from './Content'
import { DeleteModal, DisableModal, EnableModal } from '../Bulk'
import { HeaderActions } from './HeaderActions'
import { HeaderDetails } from './HeaderDetails'

const Modals: FC = () => {
    const routing = useRouting()

    return (
        <>
            <EnableModal endpointsSelector={selectEnabableLoadedDetailedEndpoint} />
            <DisableModal endpointsSelector={selectDisabableLoadedDetailedEndpoint} />
            <DeleteModal endpointsSelector={selectLoadedDetailedEndpoint} onConfirmed={() => routing.redirectToParent()} />
        </>
    )
}

export const Details: FC = () => (
    <ResourceDetails<CybusDetailedEndpoint>
        subscriptionUsecase="manageEndpointUsecase"
        resourceSelector={selectDetailedEndpoint}
        notFoundTitle={Translation.ENDPOINT_NOT_FOUND}
        modals={Modals}
        content={Content}
        headerResourceName={Translation.ENDPOINT}
        headerName="name"
        headerActions={HeaderActions}
        infoTitle={Translation.ENDPOINT_DETAILS_DOCUMENTATION_TITLE}
        infoBody={Translation.ENDPOINT_DETAILS_DOCUMENTATION_BODY}
        headerDetails={HeaderDetails}
    />
)
