import React, { type FC } from 'react'
import { Link, type LinkProps } from '@mui/material'

import { isEnumOf } from '../../../utils'

import { useInternalRoutingHook } from './Provider'
import { useHasCapabilities } from './Hooks'
import {
    type AbsoluteRouteOnlyPath,
    type AbsoluteRoutePathWithId,
    type AbsoluteRoutePathWithServiceId,
    RelativeRoutePathWithId,
    type RouteUpdateArgs,
} from './Domain'

type RoutingProps = Readonly<
    { path: AbsoluteRoutePathWithServiceId | AbsoluteRoutePathWithId | RelativeRoutePathWithId, id: string } | { path: AbsoluteRouteOnlyPath }
>
type CosmeticProps = Pick<LinkProps, 'color' | 'sx'>
type WrapperProps = Readonly<{ authorized: boolean }>
type WrapperAsArgsProps = Readonly<{ wrapper?: FC<WrapperProps> }>

type InternalProps = RoutingProps &
    CosmeticProps &
    WrapperAsArgsProps &
    Readonly<{ routing: ReturnType<typeof useInternalRoutingHook>, hasCapabilities: ReturnType<typeof useHasCapabilities> }>

const defaultWrapper: FC<WrapperProps> = ({ children }) => <>{children}</>

const InternalLink: FC<InternalProps> = ({ wrapper: Wrapper = defaultWrapper, hasCapabilities, routing, color, sx, children, ...props }) => {
    const authorized = hasCapabilities(isEnumOf(RelativeRoutePathWithId, props.path) ? routing.getAbsoluteRoute(props.path) : props.path)
    const args: RouteUpdateArgs = 'id' in props ? [props.path, props.id] : [props.path]

    return (
        <Wrapper authorized={authorized}>
            {authorized ? (
                <Link data-testid={`permissioned-link-${props.path}`} underline="none" color={color} sx={sx} href={routing.createHref(...args)}>
                    {children}
                </Link>
            ) : (
                children
            )}
        </Wrapper>
    )
}

type ExternalProps = RoutingProps & CosmeticProps & WrapperAsArgsProps

export const usePermissionedLink: () => FC<ExternalProps> = () => {
    const hasCapabilities = useHasCapabilities()
    const routing = useInternalRoutingHook()
    return (props) => <InternalLink {...props} routing={routing} hasCapabilities={hasCapabilities} />
}

export const PermissionedLink: FC<ExternalProps> = (props) => {
    const InternalLink = usePermissionedLink()
    return <InternalLink {...props} />
}
