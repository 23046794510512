import React, { type FC } from 'react'

import {
    Capability,
    type CybusDetailedService,
    CybusLogSource,
    selectDeletableLoadedDetailedService,
    selectDisablableLoadedDetailedService,
    selectService,
    Translation,
} from '../../../../domain'

import { useRouting } from '../../routing'

import { ServiceContainersTable } from '../../Containers'
import Endpoints from '../../Endpoints'
import Mappings from '../../Mappings'
import Connections from '../../Connections'
import Volumes from '../../Volumes'
import Servers from '../../Servers'
import Logs, { useAreLogsAvailable } from '../../Logs'
import { ResourceDetails } from '../../Resources'
import { Tab, Tabs } from '../../Tabs'

import { AddOrUpdateModal } from '../AddOrUpdate'
import { BulkDeletionModal, BulkDisablingModal } from '../Bulk'
import { EnablingAuthorizationModal } from '../Enabling'
import { HeaderActions } from './HeaderActions'
import { HeaderDetails } from './HeaderDetails'

const Content: FC<{ resource: CybusDetailedService }> = ({
    resource: {
        id,
        availableResources: { servers, containers, volumes, connections, endpoints, mappings },
    },
}) => {
    const available = useAreLogsAvailable(CybusLogSource.SERVICE, id)

    return (
        <Tabs>
            <Tab title={Translation.SERVICE_LOGS} disabled={!available} requiredCapabilities={[Capability.LOGS_READ]}>
                <Logs id={id} type={CybusLogSource.SERVICE} />
            </Tab>
            <Tab title={Translation.SERVER} count={servers} disabled={servers === 0} requiredCapabilities={[Capability.SERVERS_READ]}>
                <Servers serviceId={id} />
            </Tab>
            <Tab title={Translation.CONTAINER} count={containers} disabled={containers === 0} requiredCapabilities={[Capability.SERVICE_CONTAINERS_READ]}>
                <ServiceContainersTable serviceId={id} />
            </Tab>
            <Tab title={Translation.VOLUME} count={volumes} disabled={volumes === 0} requiredCapabilities={[Capability.VOLUMES_READ]}>
                <Volumes serviceId={id} />
            </Tab>
            <Tab title={Translation.CONNECTION} count={connections} disabled={connections === 0} requiredCapabilities={[Capability.CONNECTIONS_READ]}>
                <Connections serviceId={id} />
            </Tab>
            <Tab title={Translation.ENDPOINT} count={endpoints} disabled={endpoints === 0} requiredCapabilities={[Capability.ENDPOINTS_READ]}>
                <Endpoints serviceId={id} />
            </Tab>
            <Tab title={Translation.MAPPING} count={mappings} disabled={mappings === 0} requiredCapabilities={[Capability.MAPPINGS_READ]}>
                <Mappings serviceId={id} />
            </Tab>
        </Tabs>
    )
}

const Modals: FC = () => {
    const routing = useRouting()

    return (
        <>
            <BulkDisablingModal resourceSelector={selectDisablableLoadedDetailedService} />
            <BulkDeletionModal resourceSelector={selectDeletableLoadedDetailedService} onConfirmed={() => routing.redirectToParent()} />
            <EnablingAuthorizationModal />
            <AddOrUpdateModal mode="EDIT" />
        </>
    )
}

export const Details: FC = () => (
    <ResourceDetails<CybusDetailedService>
        subscriptionUsecase="manageServiceUsecase"
        resourceSelector={selectService}
        notFoundTitle={Translation.SERVICE_NOT_FOUND}
        content={Content}
        modals={Modals}
        headerResourceName={Translation.SERVICE}
        headerName="name"
        headerActions={HeaderActions}
        infoTitle={Translation.SERVICE_DETAILS_DOCUMENTATION_TITLE}
        infoBody={Translation.SERVICE_DETAILS_DOCUMENTATION_BODY}
        headerDetails={HeaderDetails}
    />
)
