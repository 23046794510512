import { ConnectwareError, ConnectwareErrorType, type Translation } from '..'

type Extras = Readonly<{ translation: Translation }>

export class ConnectwareServiceCreationError extends ConnectwareError<ConnectwareErrorType.SERVICE_CREATION_ERROR, Extras> {
    static is (e: unknown): e is ConnectwareServiceCreationError {
        return e instanceof ConnectwareServiceCreationError
    }

    constructor (translation: Extras['translation']) {
        super(ConnectwareErrorType.SERVICE_CREATION_ERROR, 'Could not create service', { translation })
    }

    get translation (): Translation {
        return this.extras.translation
    }
}
