import React, { type FC, type ReactNode } from 'react'
import { Grid, Stack, type SxProps, type Theme, Typography } from '@mui/material'

import { Translation } from '../../../../domain'

import { useTranslator } from '../../Internationalization'

const infoTextStyle: SxProps<Theme> = { whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis', mr: 4 }
const wrapperStyle: SxProps<Theme> = { mb: 1 }

export const LicenseInfo: FC<Readonly<{ title: Translation | [Translation, Record<string, ReactNode>], additionalInfo?: ReactNode }>> = ({
    title,
    children,
    additionalInfo,
}) => {
    const translator = useTranslator()
    const translatedTitle = translator.formatTranslation(Array.isArray(title) ? title[0] : title, Array.isArray(title) ? title[1] : undefined)

    return (
        <Grid container alignItems="center" sx={wrapperStyle}>
            <Grid item xs={2}>
                <Typography title={translatedTitle} variant="body2" className="license-info-label">
                    {translatedTitle}
                </Typography>
            </Grid>
            <Grid item xs={10}>
                <Stack direction="row" alignItems="center">
                    <Typography variant="body2" sx={infoTextStyle} className="license-info">
                        {children || translator.formatTranslation(Translation.NOT_AVAILABLE)}
                    </Typography>
                    {children && additionalInfo}
                </Stack>
            </Grid>
        </Grid>
    )
}
