import React, { type FC } from 'react'
import { Checkbox, FormControlLabel, FormGroup, type SxProps, type Theme } from '@mui/material'

import { toggleSet } from '../../../../utils'

import { CybusUserAuthenticationMethod, Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'

export const FormattedMethod: FC<{ method: CybusUserAuthenticationMethod }> = ({ method }) => (
    <FormattedTranslation id={Translation.USER_AUTHENTICATION_METHOD} values={{ method }} />
)

const checkboxesWrapperStyle: SxProps<Theme> = { justifyContent: 'space-evenly' }
const checkboxStyle: SxProps<Theme> = { p: 0.5 }

type Props = Readonly<{
    disabled: boolean
    authenticationMethods: CybusUserAuthenticationMethod[]
    required?: CybusUserAuthenticationMethod[]
    usecase: { update(update: Pick<Props, 'authenticationMethods'>): void }
}>

export const AuthenticationMethodsForm: FC<Props> = ({ disabled, authenticationMethods, required, usecase }) => (
    <FormGroup data-testid="authentication-methods" sx={checkboxesWrapperStyle} row>
        {Object.values(CybusUserAuthenticationMethod).map((method) => (
            <FormControlLabel
                key={method}
                control={
                    <Checkbox
                        data-testid={method}
                        sx={checkboxStyle}
                        size="small"
                        checked={authenticationMethods.includes(method)}
                        disabled={disabled || required?.includes(method)}
                        onChange={() => usecase.update({ authenticationMethods: Array.from(toggleSet(new Set(authenticationMethods), method)) })}
                    />
                }
                label={<FormattedMethod method={method} />}
            />
        ))}
    </FormGroup>
)
