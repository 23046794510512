import { Usecase } from '../Usecase'

export class ContactInformationUsecase extends Usecase {
    private sendEmail (email: string, subject?: string): void {
        this.browserService.sendEmail(email, subject || null)
    }

    sendSalesEmail (subject?: string): void {
        this.sendEmail(this.getSalesEmail(), subject)
    }

    sendSupportEmail (subject?: string): void {
        this.sendEmail(this.getSupportEmail(), subject)
    }

    getSalesEmail (): string {
        return this.configurationService.getSalesEmail()
    }

    getSupportEmail (): string {
        return this.configurationService.getSupportEmail()
    }
}
