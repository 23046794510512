import React, { type FC } from 'react'
import { Box, Button, CircularProgress, Grid, type SxProps, type Theme, Tooltip, Typography } from '@mui/material'
import { Error } from '@mui/icons-material'

import { ConnectwareError, Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'
import { Popover, useAnchorSetter } from '../../common'

const paddedStyle: SxProps<Theme> = { p: 1 }
const deleteIconStyle: SxProps<Theme> = { ml: 1 }

export const DeleteButton: FC<Readonly<{ loading: boolean, error: ConnectwareError | null, disabled: boolean }>> = ({ loading, error, ...props }) => {
    const setOrigin = useAnchorSetter()

    return (
        <Button {...props} color="secondary" variant="outlined" size="small" onClick={(e) => setOrigin(e.currentTarget)}>
            {loading ? (
                <CircularProgress size="1.375rem" />
            ) : (
                <>
                    <FormattedTranslation id={Translation.DELETE} />
                    {ConnectwareError.is(error) && (
                        <Tooltip data-testid="delete-error-tooltip" data-title={error.message} title={error.message}>
                            <Error sx={deleteIconStyle} fontSize="small" color="error" />
                        </Tooltip>
                    )}
                </>
            )}
        </Button>
    )
}

export const DeletePopover: FC<Readonly<{ onConfirm: VoidFunction }>> = ({ onConfirm, children }) => {
    const setOrigin = useAnchorSetter()

    const close: VoidFunction = () => setOrigin(null)

    return (
        <Popover onClose={close} anchorOrigin={{ vertical: 'center', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Box sx={paddedStyle}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" align="center">
                            {children}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Button fullWidth color="primary" variant="contained" size="small" onClick={close}>
                            <FormattedTranslation id={Translation.CANCEL} />
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            data-testid="deletion-confirmation"
                            fullWidth
                            color="secondary"
                            variant="contained"
                            size="small"
                            onClick={() => {
                                close()
                                onConfirm()
                            }}
                        >
                            <FormattedTranslation id={Translation.DELETE} />
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Popover>
    )
}
