import React, { type FC, type ReactNode } from 'react'

import { Box, type SxProps, type Theme } from '@mui/material'

import { Json, NoReRenderOnHover } from '../../common'

const wrapperStyle: SxProps<Theme> = { height: '100%' }
const codeStyle: SxProps<Theme> = {
    my: 0,
    p: 2,
    lineHeight: 1.5,
    backgroundColor: '#f6f8fa',
    borderRadius: 1,
    border: 'solid 1px',
    borderColor: 'grey.500',
    color: 'grey.900',
    height: '100%',
    overflowY: 'auto',
}

const Display: FC<{ json: unknown }> = ({ json }) =>
    json instanceof Object ? <Json src={json} displayObjectSize={false} collapsed={1} /> : <>{String(json)}</>

export const Code: FC<{ id: string, json?: unknown, buttons?: ReactNode }> = ({ id, children, buttons, ...props }) => (
    <NoReRenderOnHover sx={wrapperStyle}>
        {buttons}
        <Box component="pre" id={id} sx={codeStyle}>
            {'json' in props ? <Display json={props.json} /> : children}
        </Box>
    </NoReRenderOnHover>
)
