import { entries } from '../../../../../utils'

import { type CybusAgent } from '../../../../../domain'

import { mapAgents } from '../../../../Connectware'
import { listenToAgentsChange, type ManagedVrpcRemote, VrpcDomainType } from '../../../utils'
import { type UnsubFromRemoteFunction, type VrcpEntityHandler, type VrpcHandlerConfiguration, type VrpcRemoteMapper } from '.'

class VrpcRemoteToCybusAgentMapper implements VrpcRemoteMapper<CybusAgent> {
    mapToDomain (remote: ManagedVrpcRemote): Promise<Map<string, CybusAgent>> {
        return Promise.resolve(mapAgents(entries(remote.getSystemInformation()), ([name, agent]) => [name, agent]))
    }

    onChange (remote: ManagedVrpcRemote, listener: VoidFunction): Promise<UnsubFromRemoteFunction> {
        return Promise.resolve(listenToAgentsChange(remote, 200, listener))
    }
}

export class VrpcRemoteToCybusAgentHandler implements VrcpEntityHandler<never, CybusAgent> {
    readonly remoteMapper = new VrpcRemoteToCybusAgentMapper()

    readonly instanceMapper = null

    readonly configuration: VrpcHandlerConfiguration = { domains: [VrpcDomainType.DEFAULT, VrpcDomainType.EDGE], supportedFilters: [] }
}
