import React, { type FC } from 'react'
import { Box, type SxProps, type Theme, Typography } from '@mui/material'
import { Warning } from '@mui/icons-material'

import { Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'

const wrapperStyle: SxProps<Theme> = { display: 'flex' }
const iconStyle: SxProps<Theme> = { my: 'auto', mx: 0 }
const titleStyle: SxProps<Theme> = { m: 'auto' }

export const Title: FC<{ readonly loading: boolean }> = ({ loading }) => (
    <Box sx={wrapperStyle}>
        {!loading && <Warning sx={iconStyle} color="error" fontSize="large" />}
        <Typography sx={titleStyle} variant="h6">
            <FormattedTranslation id={Translation.LICENSE_MODAL_TITLE} values={{ loading }} />
        </Typography>
    </Box>
)
