import React, { type FC } from 'react'

import { Capability, CybusLogSource, type CybusServer, Translation } from '../../../../domain'

import { Tab, Tabs } from '../../Tabs'
import Logs, { useAreLogsAvailable } from '../../Logs'
import NodesTable from '../../Nodes'

export const Content: FC<{ resource: CybusServer }> = ({ resource: { id } }) => {
    const available = useAreLogsAvailable(CybusLogSource.SERVER, id)

    return (
        <Tabs>
            <Tab title={Translation.SERVER_LOGS} disabled={!available} requiredCapabilities={[Capability.LOGS_READ]}>
                <Logs id={id} type={CybusLogSource.SERVER} />
            </Tab>
            <Tab title={Translation.NODE} requiredCapabilities={[Capability.NODES_READ]}>
                <NodesTable serverId={id} />
            </Tab>
        </Tabs>
    )
}
