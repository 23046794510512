import { OutlinedInput, Stack, type SxProps, type Theme, Typography } from '@mui/material'
import React, { type ChangeEvent, type FC, useCallback, useEffect, useState } from 'react'

import { createOnEnterHandler } from '../../utils'

type Props = Readonly<{
    label: string
    page: number
    totalPages: number
    onSubmit: (page: number) => void
}>

const labelStyle: SxProps<Theme> = { mr: 1 }
const inputStyle: SxProps<Theme> = {
    width: 54,
    '& input': {
        px: 1,
        py: 0.5,
    },
    '& input[type=number]': {
        MozAppearance: 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
    },
}

export const JumpToPage: FC<Props> = ({ label, page, totalPages, onSubmit }) => {
    const [currPage, setCurrPage] = useState<number | ''>(page)

    useEffect(() => {
        setCurrPage(page)
    }, [page])

    const onChange = useCallback(
        ({ target: { valueAsNumber } }: ChangeEvent<HTMLInputElement>) =>
            setCurrPage(isNaN(valueAsNumber) ? '' : Math.min(Math.max(valueAsNumber, 1), totalPages)),
        [totalPages]
    )

    const handleSubmit = createOnEnterHandler(
        useCallback(() => {
            if (currPage) {
                onSubmit(currPage)
            }
        }, [onSubmit, currPage])
    )

    return (
        <Stack direction="row" alignItems="center">
            <Typography component="label" variant="body2" htmlFor="jumpToPage" sx={labelStyle}>
                {label}
            </Typography>
            <OutlinedInput id="jumpToPage" type="number" value={currPage} onChange={onChange} onKeyDown={handleSubmit} sx={inputStyle} />
        </Stack>
    )
}
