import { useMemo } from 'react'

import { type NonEmptyArray } from '../../../../utils'

import { ConnectwareError, type CybusServiceForm, selectServiceForm, type Translation } from '../../../../domain'

import type { Usecases } from '../../../../application'

import { useAppState, useAppUsecases } from '../../State'

import { type ModalConfig, useModalConfig } from './Context'

const useUsecase = <T>(selector: (usecase: Usecases[ModalConfig['usecase']]) => T): T => {
    const usecaseName = useModalConfig('usecase')
    const { [usecaseName]: usecase } = useAppUsecases()
    return selector(usecase)
}

const useServiceForm = <T, D>(selector: (form: CybusServiceForm) => T, defaultValue: D): T | D =>
    useAppState((s) => {
        const form = selectServiceForm(s)
        return form ? selector(form) : defaultValue
    })

export const useTitle = (): Translation => useModalConfig('title')
export const useConfigurationTitle = (): Translation => useModalConfig('configurationTitle')
export const useConfirmationButtonText = (): Translation => useModalConfig('confirmationButtonText')

export const isOpen = (): boolean => useServiceForm((f) => f !== null, false)
export const useRequesting = (): CybusServiceForm['requesting'] => useServiceForm((f) => f.requesting, false)
export const useManaged = (): CybusServiceForm['managed'] => useServiceForm((f) => f.managed, null)
export const useFileContent = (): string | null => useServiceForm((f) => (typeof f.file === 'string' ? f.file : null), null)
export const useError = (): ConnectwareError | null =>
    useServiceForm(({ requesting, file, schema }) => [requesting, file, schema].find((e): e is ConnectwareError => ConnectwareError.is(e)) || null, null)

export const useSchema = (): CybusServiceForm['schema'] => useServiceForm((f) => f.schema, null)
export const useParameters = (): CybusServiceForm['parameters'] => useServiceForm((f) => f.parameters, null)
export const useHasParameters = (): boolean => useServiceForm((f) => Boolean(f.parameters), false)
export const useId = (): CybusServiceForm['id'] => useServiceForm((f) => f.id, null)

export const useCancel = (): VoidFunction => useUsecase((u) => () => u.cancel())
export const useConfirm = (): VoidFunction => {
    const confirm = useModalConfig('onConfirmed')
    return useUsecase((u) => () => void u.confirm().then((id) => id && confirm?.(id)))
}
export const useSupportedCommissioningFileTypes = (): NonEmptyArray<string> => useUsecase((u) => useMemo(() => u.getSupportedCommissioningFileTypes(), [u]))
export const useSupportedCommissioningFileTypesList = (): string => {
    const types = useSupportedCommissioningFileTypes()
    return useMemo(() => types.map((t) => `.${t}`).join(','), [types])
}

export const useOnFileChange = (): ((file: CybusServiceForm['file']) => void) => useUsecase((u) => (file) => void u.updateCommissioningFile(file))
export const useIsIdEditable = (): boolean => useUsecase((u) => useMemo(() => u.isCreating(), [u]))
export const useUpdateServiceParameters = (): ((id: CybusServiceForm['id'], newParameters: CybusServiceForm['parameters']) => void) =>
    useUsecase(
        (u) =>
            (...params) =>
                u.updateServiceParameters(...params)
    )

export const useFile = (): File | null => {
    const [type] = useSupportedCommissioningFileTypes()
    const id = useId()
    const content = useFileContent()

    return useMemo(() => (id && content ? new File([content], `${id}.${type}`) : null), [type, id, content])
}

export const useIsDisabled = (): boolean => {
    const id = useId()
    const requesting = useRequesting()
    const parameters = useParameters()
    return useUsecase((u) => useMemo(() => !u.isConfirmationRequestValid(), [u, id, requesting, parameters]))
}
