import { MAX_SAFE_TIMEOUT } from './Constants'

/**
 * @see https://github.com/nodejs/node-v0.x-archive/blob/master/lib/timers.js#L29
 * @see https://nodejs.org/docs/latest-v14.x/api/timers.html#timers_settimeout_callback_delay_args
 */
const createUnsafeTimeout = (callback: VoidFunction, ms: number): VoidFunction => {
    const id = setTimeout(callback, ms)
    return () => clearTimeout(id)
}

/**
 * This class was created to circunvent the time limit with-in `setTimeout`
 * of using only int32 numbers of wait time
 */
export class Timer {
    private readonly target: VoidFunction

    private readonly limit: number

    private readonly safeTimeout: number

    cancel: VoidFunction

    constructor (callback: VoidFunction, waitInMilliseconds: number) {
        this.target = callback
        this.limit = Date.now() + waitInMilliseconds
        this.safeTimeout = this.getMaxSafeInteger()

        this.cancel = this.next()
    }

    private get timeout (): number {
        /** Retrieve theoretical limit, that does not care for safety */
        const limit = Math.max(this.limit - Date.now(), 0)

        /** Yield number with-in safety */
        return Math.min(limit, this.safeTimeout)
    }

    private next (): VoidFunction {
        return this.setTimeout(() => {
            if (this.timeout > 0) {
                // Wait again
                this.cancel = this.next()
            } else {
                // Call target finally
                this.target()
            }
        }, this.timeout)
    }

    /**
     * @see {createUnsafeTimeout}
     */
    protected getMaxSafeInteger (): number {
        return MAX_SAFE_TIMEOUT
    }

    protected setTimeout (callback: VoidFunction, ms: number): VoidFunction {
        return createUnsafeTimeout(callback, ms)
    }
}

export const createTimeout = (callback: VoidFunction, ms: number): VoidFunction => {
    const timeout = new Timer(callback, ms)
    return () => timeout.cancel()
}
