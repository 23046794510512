import { createLoadedSelector, type Loadable } from '../Loadable'
import type { CybusDetailedVolume, CybusVolume } from '.'
import { createPageSelector, type Page } from '..'

export type VolumesAppState = Readonly<{ volume: Loadable<CybusDetailedVolume>, volumesPage: Page<CybusVolume> }>

export const selectDetailedVolume = (s: VolumesAppState): VolumesAppState['volume'] => s.volume
export const selectVolumesPage = (s: VolumesAppState): VolumesAppState['volumesPage'] => s.volumesPage

export const selectLoadedDetailedVolume = createLoadedSelector(selectDetailedVolume, 'id')
export const selectLoadedVolumes = createPageSelector(selectVolumesPage, 'id', 'selectLoadedVolumes')
