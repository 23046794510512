import { ConnectwareError, ConnectwareErrorType } from '../../../../../domain'

import type { SubscriptionsTypes } from '../../../../../application'

import { getInstance, type ManagedVrpcRemote, type VrpcRemoteManager } from '../../../utils'
import { VrpcEntitiesSubscription } from './Entities'

export class SingleSubscription<T extends keyof SubscriptionsTypes> extends VrpcEntitiesSubscription<T> {
    constructor (
        /**
         * The id the user sees
         */
        private readonly userId: string,
        eventName: T,
        remote: VrpcRemoteManager
    ) {
        super(eventName, remote)

        this.withRemoteManager(() => {
            throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Unexpected internal subscription manager')
        })
    }

    /**
     * If there is an instance configured, use it instead of the passed id
     */
    private get retrievalId (): string {
        return this.instanceName || this.userId
    }

    private async addInstance (remote: ManagedVrpcRemote): Promise<void> {
        /**
         * Actually get the instance
         */
        await this.withInstanceManager((manager) =>
            manager.addInstance(SingleSubscription.mapInstanceAddress(this.userId), {}, getInstance(remote, this.retrievalId))
        )
    }

    private async removeInstance (): Promise<void> {
        /**
         * Actually get the instance
         */
        await this.withInstanceManager((manager) => manager.removeInstance(SingleSubscription.mapInstanceAddress(this.userId), true))
    }

    protected onRelevantNewInstances (remote: ManagedVrpcRemote, added: string[]): void {
        if (added.includes(this.userId)) {
            void this.addInstance(remote)
        }
    }

    protected onRelevantGoneInstances (gone: string[]): void {
        if (gone.includes(this.userId)) {
            void this.removeInstance().then(() =>
                this.triggerError(new ConnectwareError(ConnectwareErrorType.NOT_FOUND, 'The VRPC instance was deleted', { id: this.userId }))
            )
        }
    }

    protected async onConnected (remote: ManagedVrpcRemote): Promise<void> {
        if (this.getInstanceConfigurations(remote, this.retrievalId).length > 0) {
            await this.addInstance(remote)
        } else {
            this.triggerError(new ConnectwareError(ConnectwareErrorType.NOT_FOUND, 'The VRPC instance was not found', { id: this.retrievalId }))
        }
    }
}
