import React, { type FC, useMemo } from 'react'
import { Helmet } from 'react-helmet'

import { entries } from '../../../utils'

import { Translation } from '../../../domain'

import { useAppUsecase } from '../State'
import { useTranslator } from '../Internationalization'

export const Head: FC = () => {
    const translator = useTranslator()

    const getApplicationMetadataUsecase = useAppUsecase('getApplicationMetadataUsecase')

    const metadata = useMemo(() => getApplicationMetadataUsecase.invoke(), [getApplicationMetadataUsecase])

    return (
        <Helmet>
            <title>{translator.formatTranslation(Translation.APPLICATION_TITLE)}</title>
            {entries(metadata).map(([name, value], k) => (
                <meta key={k} httpEquiv={name} content={value} />
            ))}
        </Helmet>
    )
}
