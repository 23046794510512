import React, { type FC } from 'react'
import { Autocomplete, Box, Chip, Popper, type SxProps, TextField, type Theme, Typography } from '@mui/material'
import { CloudQueue, DeviceHub, ScatterPlot } from '@mui/icons-material'

import { ConnectwareError, Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'
import { useAppUsecase } from '../../State'
import { ErrorMessage } from '../../ErrorMessage'
import { useEndpoints } from './Hooks'

/**
 * since the mr: auto should be applied to a typography component (p), we can select everyting thats not a div
 */
const optionStyle: SxProps<Theme> = { display: 'flex', width: '100%', '& > *': { my: 'auto', ml: 1 }, '& > :not(div)': { mr: 'auto' } }
const optionChipStyle: SxProps<Theme> = { pl: 0.5 }

export const EndpointsDropdown: FC = () => {
    const filter = useAppUsecase('filterSandboxUsecase')
    const endpoints = useEndpoints()

    if (!endpoints) {
        return null
    }

    if (ConnectwareError.is(endpoints)) {
        return <ErrorMessage id="rule-engine-endpoints-dropdown" titleVariant="subtitle1" error={endpoints} extras="popover" />
    }

    return (
        <Autocomplete
            id="rule-engine-endpoints-dropdown"
            multiple
            filterSelectedOptions
            size="medium"
            options={endpoints}
            getOptionLabel={(option) => option.id}
            disabled={endpoints.length === 0}
            renderOption={(props, option) => (
                <li {...props}>
                    <ScatterPlot color="primary" />
                    <Box sx={optionStyle} title={option.id}>
                        <Typography variant="body2">{option.name}</Typography>
                        {option.service && <Chip sx={optionChipStyle} size="small" variant="outlined" avatar={<CloudQueue />} label={option.service} />}
                        {option.connection && <Chip sx={optionChipStyle} size="small" variant="outlined" avatar={<DeviceHub />} label={option.connection} />}
                    </Box>
                </li>
            )}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        data-testid="selected-endpoint-chip"
                        title={option.id}
                        avatar={<ScatterPlot />}
                        size="small"
                        variant="outlined"
                        label={option.name}
                        {...getTagProps({ index })}
                        key={index}
                    />
                ))
            }
            renderInput={(params) => <TextField {...params} variant="standard" size="medium" />}
            noOptionsText={
                <Typography variant="body2">
                    <FormattedTranslation id={Translation.NO_ENDPOINTS_AVAILABLE} />
                </Typography>
            }
            onChange={(_, endpoints) => filter.selectEndpoints(endpoints.map((e) => e.id))}
            PopperComponent={(props) => <Popper id="rule-engine-endpoints-dropdown-popper" {...props} />}
        />
    )
}
