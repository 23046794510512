import type { PromiseType } from 'utility-types'

import { CONTAINER_MANAGER_AGENT } from '../../../../../../Connectware'

import { CONTAINER_MANAGER_CLASSNAME, CONTAINER_MANAGER_CORE_INSTANCE, CONTAINER_MANAGER_DOCKER_INSTANCE } from '../../../../../constants'
import { type ContainerManagerOrchestratorProxy } from '../../../../../proxies'

import { type VrpcHandlerConfiguration } from '../..'

export type ContainerInspectionResponse = PromiseType<ReturnType<ContainerManagerOrchestratorProxy['inspectContainer']>>

export const coreConfiguration: VrpcHandlerConfiguration = {
    classNameFilter: CONTAINER_MANAGER_CLASSNAME,
    agent: CONTAINER_MANAGER_AGENT,
    instanceNameFilter: CONTAINER_MANAGER_CORE_INSTANCE,
    supportedFilters: [],
}

export const serviceConfiguration: VrpcHandlerConfiguration = {
    classNameFilter: CONTAINER_MANAGER_CLASSNAME,
    agent: CONTAINER_MANAGER_AGENT,
    instanceNameFilter: CONTAINER_MANAGER_DOCKER_INSTANCE,
    supportedFilters: [],
}
