import type { CybusLog, CybusLogSource } from '../../../domain'

import { Usecase } from '..'
import type { LogOptions, RawLogOptions } from '../..'

export class ContainerLogsUsecase extends Usecase {
    areLogsAvailable (type: CybusLogSource, id: string): Promise<boolean> {
        return this.connectwareLogsService.doesCollectLogs(type, id)
    }

    fetchLogs (type: CybusLogSource, options: LogOptions): Promise<CybusLog[]> {
        return this.connectwareLogsService.fetchLogs(type, options)
    }

    async downloadRawlogs (type: CybusLogSource, resource: RawLogOptions['resource']): Promise<void> {
        this.fileService.download(await this.connectwareLogsService.fetchAllRawLogs(type, { resource, lines: 'all' }))
    }
}
