import { FormControlLabel, Switch } from '@mui/material'
import React, { type FC, type ReactNode } from 'react'

import { MfaSettingsState, selectMfa, Translation } from '../../../domain'

import { FormattedTranslation } from '../Internationalization'
import { useAppState, useAppUsecases } from '../State'
import { useAsyncCallback } from '../Callback'
import { ErrorMessage } from '../ErrorMessage'
import { CircularLoader } from '../common'

import { Enabling } from './Enabling'
import { EnabledActionSection } from './EnabledAction'
import { MfaBackupCodes } from './BackupCodes'
import { Block } from './Common'

export const Toggle: FC = () => {
    const { enableMfaUsecase, disableMfaUsecase } = useAppUsecases()

    const state = useAppState((s) => selectMfa(s)?.state ?? null)

    const [enable, enabling] = useAsyncCallback(() => enableMfaUsecase.start(), [enableMfaUsecase])
    const disable: VoidFunction = () => disableMfaUsecase.start()

    let enabled = false
    let onChange: VoidFunction | null = null

    switch (state) {
        case MfaSettingsState.DISABLED:
            onChange = enable
            break
        case MfaSettingsState.REGENERATED_BACKUP_CODES:
        case MfaSettingsState.ENABLED:
            enabled = true
            onChange = disable
            break
    }

    return (
        onChange && (
            <Block>
                <FormControlLabel
                    data-testid="mfa-toggle"
                    control={<Switch />}
                    checked={enabled}
                    label={<FormattedTranslation id={Translation.MFA_TOGGLE} values={{ enabled }} />}
                    disabled={enabling}
                    onChange={onChange}
                />
            </Block>
        )
    )
}

export const Content: FC = () => {
    const state = useAppState(selectMfa)

    let content: ReactNode = null

    switch (state?.state) {
        case MfaSettingsState.LOADING:
            content = state.error ? <ErrorMessage error={state.error} extras="section" /> : <CircularLoader />
            break
        case MfaSettingsState.ENABLING:
            content = <Enabling {...state} />
            break
        case MfaSettingsState.REGENERATED_BACKUP_CODES:
        case MfaSettingsState.ENABLED:
            content = <MfaBackupCodes {...state} />
            break
        case MfaSettingsState.REGENERATING_BACKUP_CODES:
            content = <EnabledActionSection usecase="regenerateMfaBackupCodesUsecase" idPrefix="mfa-regenerating-backup-codes" {...state} />
            break
        case MfaSettingsState.DISABLING:
            content = <EnabledActionSection usecase="disableMfaUsecase" idPrefix="mfa-disabling" {...state} />
            break
    }

    return content && <Block data-testid="mfa-content">{content}</Block>
}
