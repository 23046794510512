import React, { type FC } from 'react'
import { Popover as BasePopover, type PopoverProps } from '@mui/material'

import { useAnchor } from '.'

const Popover: FC<Omit<PopoverProps, 'ref' | 'anchorEl' | 'open'>> = (props) => {
    const anchorEl = useAnchor()
    return <BasePopover {...props} anchorEl={anchorEl} open={Boolean(anchorEl)} />
}

export default Popover
