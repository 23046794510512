import { areCybusMappingEquals, Capability, type CybusMapping } from '../../../../../../domain'
import type { SubscriptionFilterArgs } from '../../../../../../application'
import { mapMapping, type MappingProxyParams } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

export class MappingsStrategy extends BaseSubscriptionStrategy<'mappings'> {
    protected readonly supportedFilters: (keyof SubscriptionFilterArgs)[] = ['service']

    readonly requiredPermissions = [Capability.MAPPINGS_READ]

    protected readonly areEquals = areCybusMappingEquals

    retrieveAll ({ service }: SubscriptionFilterArgs): Promise<CybusMapping[]> {
        return this.retrieve({
            capability: Capability.MAPPINGS_READ,
            method: 'GET',
            path: '/api/mappings',
            mapper: (data: MappingProxyParams[]) =>
                data.reduce<CybusMapping[]>((r, mapping) => {
                    const cybusMapping = mapMapping(mapping)
                    return service && service !== cybusMapping.service ? r : [...r, cybusMapping]
                }, []),
        })
    }
}
