import React, { type FC } from 'react'

import { type SxProps, type Theme } from '@mui/material'
import { useSnackbar } from 'notistack'

import { ContentCopy } from '@mui/icons-material'

import { selectLastCopyId, Translation } from '../../../domain'

import { useAppState, useAppUsecase } from '../State'
import { FormattedTranslation } from '../Internationalization'
import { type BaseCommonProps, SmallButton, TiniestButton } from '../common'

type ButtonProps = Readonly<{ disabled?: boolean, onClick?: VoidFunction }> & BaseCommonProps

type Props = Pick<ButtonProps, 'className' | 'sx'> & Readonly<{ button: FC<ButtonProps> }>

const iconStyle: SxProps<Theme> = { opacity: 0.5 }
const copiedIconStyle: SxProps<Theme> = { ...iconStyle, fill: 'grey' }
const copyIconStyle: SxProps<Theme> = { ...iconStyle, fill: 'black' }
const buttonStyle: SxProps<Theme> = { border: 'unset' }

const SNACKBAR_TIMEOUT = 2000

const InternalCopy: FC<Props> = ({ children, button: Button, ...props }) => {
    const copy = useAppUsecase('copyValueUsecase')
    const lastCopyId = useAppState(selectLastCopyId)
    const { enqueueSnackbar } = useSnackbar()
    const isItemCopied = lastCopyId === children

    return (
        <Button
            {...props}
            sx={{ ...buttonStyle, ...props.sx }}
            data-testid={isItemCopied ? 'copied-id' : 'copy-id'}
            onClick={() => {
                copy.invoke(children)
                enqueueSnackbar(
                    <div data-testid="copied-to-clipboard">
                        <FormattedTranslation id={Translation.COPIED_TO_CLIPBOARD} />
                    </div>,
                    { variant: 'info', autoHideDuration: SNACKBAR_TIMEOUT }
                )
            }}
        >
            <ContentCopy sx={isItemCopied ? copiedIconStyle : copyIconStyle} />
        </Button>
    )
}

export const TiniestCopy: FC<Omit<Props, 'button'>> = (props) => <InternalCopy {...props} button={TiniestButton} />
export const SmallCopy: FC<Omit<Props, 'button'>> = (props) => <InternalCopy {...props} button={SmallButton} />
export const CustomCopy: FC<Props> = (props) => <InternalCopy {...props} />
