import React, { type FC } from 'react'
import type { SxProps, Theme } from '@mui/material'

import { type ConnectwareError, Translation } from '../../../../domain'

import { ErrorMessage } from '../../ErrorMessage'

import { ErrorPopover } from './Error'

const errorStyle: SxProps<Theme> = { m: 2 }

export const GenericError: FC<Readonly<{ error: ConnectwareError }>> = ({ error }) => (
    <ErrorPopover linkTranslation={Translation.SERVICE_CREATION_GENERIC_ERROR}>
        <ErrorMessage sx={errorStyle} error={error} stack extras="section" />
    </ErrorPopover>
)
