import React, { type FC } from 'react'
import {
    Avatar,
    Button,
    Card,
    CardContent,
    CardMedia,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    LinearProgress,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    type ListItemTextProps,
    type SxProps,
    type Theme,
    Typography,
} from '@mui/material'
import { AddShoppingCart } from '@mui/icons-material'

import { Translation } from '../../../../domain'

import { DocumentationType } from '../../../../application'
import { FormattedDateTime, FormattedTranslation, useTranslator } from '../../Internationalization'
import { useAppUsecase } from '../../State'
import { useAsyncCallback } from '../../Callback'
import { useDocumentation } from '../../Documentation'
import { SalesEmailLink } from '../../Contact'

import { isAddOrUpdateModalOpen } from '../AddOrUpdate'
import { useApplication } from './Hooks'

const headerStyle: SxProps<Theme> = { display: 'flex', mb: 2 }
const applicationIconStyle: SxProps<Theme> = { maxWidth: '150px', m: 3, objectFit: 'scale-down', objectPosition: 'top' }
const cardInfoStyle: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    '& > *': { display: '-webkit-inline-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', wordBreak: 'break-word', overflow: 'hidden' },
}

const muiListItemTextSecondaryStyle: SxProps<Theme> = {
    display: '-webkit-inline-box',
    WebkitLineClamp: 10,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
    overflow: 'hidden',
}
const avatarStyle: SxProps<Theme> = { backgroundColor: 'secondary.main' }

const ItemText: FC<Pick<ListItemTextProps, 'primary' | 'secondary' | 'secondaryTypographyProps'>> = (props) => (
    <ListItemText {...props} primaryTypographyProps={{ variant: 'body1' }} />
)

export const ApplicationDialog: FC = () => {
    const translator = useTranslator()

    const installUsecase = useAppUsecase('installServiceCatalogApplicationUsecase')
    const openCommissioningFile = useDocumentation(DocumentationType.SERVICES_COMMISSIONING_FILE)
    const openCybusLearn = useDocumentation(DocumentationType.CYBUS_LEARN)

    const application = useApplication()
    const isOpen = isAddOrUpdateModalOpen()

    const [start, loading] = useAsyncCallback(() => installUsecase.start(), [installUsecase])

    if (application === null || isOpen) {
        return <></>
    }

    const close: VoidFunction = () => installUsecase.close()

    return (
        <Dialog id="service-catalog-application-dialog" fullWidth disableEscapeKeyDown onClose={close} maxWidth="sm" open>
            <DialogTitle>
                <FormattedTranslation id={Translation.SERVICE_DETAILS} />
            </DialogTitle>
            <LinearProgress variant="determinate" value={0} />
            <DialogContent>
                <Card sx={headerStyle}>
                    <CardMedia sx={applicationIconStyle} component="img" src={application.icon} title={application.name} />
                    <CardContent sx={cardInfoStyle}>
                        <Typography gutterBottom variant="h4">
                            {application.name}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            {application.provider}
                        </Typography>
                    </CardContent>
                </Card>
                <Grid container>
                    <Grid item xs={9}>
                        <List>
                            <ListItem>
                                <ItemText
                                    primary={<FormattedTranslation id={Translation.DESCRIPTION} />}
                                    secondary={application.description}
                                    secondaryTypographyProps={{ sx: muiListItemTextSecondaryStyle, variant: 'body2' }}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={3}>
                        <List>
                            <ListItem>
                                <ItemText
                                    primary={<FormattedTranslation id={Translation.DOCUMENTATION} />}
                                    secondary={
                                        <Link onClick={openCommissioningFile}>
                                            <FormattedTranslation id={Translation.CYBUS_DOCS} />
                                        </Link>
                                    }
                                    secondaryTypographyProps={{ variant: 'body2' }}
                                />
                            </ListItem>
                            <ListItem>
                                <ItemText
                                    primary={<FormattedTranslation id={Translation.CYBUS_LEARN} />}
                                    secondary={
                                        <Link onClick={openCybusLearn}>
                                            <FormattedTranslation id={Translation.CYBUS_LEARN} />
                                        </Link>
                                    }
                                    secondaryTypographyProps={{ variant: 'body2' }}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
                <Typography gutterBottom variant="h6">
                    <FormattedTranslation id={Translation.ADDITIONAL_INFO} />
                </Typography>
                <Grid container>
                    <Grid item xs={9}>
                        <List>
                            <ListItem>
                                <ItemText
                                    primary={<FormattedTranslation id={Translation.REQUIRED_PERMISSION} />}
                                    secondary={application.directory}
                                    secondaryTypographyProps={{ variant: 'body2' }}
                                />
                                {!application.available && (
                                    <ListItemAvatar>
                                        <Avatar sx={avatarStyle}>
                                            <AddShoppingCart />
                                        </Avatar>
                                    </ListItemAvatar>
                                )}
                            </ListItem>
                            <ListItem>
                                <ItemText
                                    primary={<FormattedTranslation id={Translation.HOMEPAGE} />}
                                    secondary={
                                        <Link href={application.homepage} target="_blank">
                                            {application.homepage}
                                        </Link>
                                    }
                                    secondaryTypographyProps={{ variant: 'body2' }}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={3}>
                        <List>
                            <ListItem>
                                <ItemText
                                    primary={<FormattedTranslation id={Translation.VERSION} />}
                                    secondary={application.version}
                                    secondaryTypographyProps={{ variant: 'body2' }}
                                />
                            </ListItem>
                            <ListItem>
                                <ItemText
                                    primary={<FormattedTranslation id={Translation.LAST_UPDATED} />}
                                    secondary={<FormattedDateTime date={application.updatedAt} format="dateOnly" />}
                                    secondaryTypographyProps={{ variant: 'body2' }}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button data-testid="service-catalog-application-dialog-cancel" color="primary" onClick={close}>
                    <FormattedTranslation id={Translation.CLOSE} />
                </Button>
                {application.available ? (
                    <Button data-testid="service-catalog-application-dialog-install" color="primary" variant="contained" disabled={loading} onClick={start}>
                        {loading ? <CircularProgress size="1.5rem" color="inherit" /> : <FormattedTranslation id={Translation.INSTALL} />}
                    </Button>
                ) : (
                    <SalesEmailLink
                        data-testid="service-catalog-application-dialog-request"
                        variant="button"
                        color="secondary"
                        subject={translator.formatTranslation(Translation.REQUEST_SERVICE_SUBJECT, application)}
                    >
                        <FormattedTranslation id={Translation.REQUEST_SERVICE} />
                    </SalesEmailLink>
                )}
            </DialogActions>
        </Dialog>
    )
}
