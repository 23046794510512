import React, { type FC } from 'react'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material'

import { Translation } from '../../../domain'

import { useAppUsecase } from '../State'
import { FormattedTranslation } from '../Internationalization'
import { Validation } from '../common'

export const SessionToken: FC = () => {
    const usecase = useAppUsecase('loginFromTokenUsecase')

    return (
        <Dialog disableEscapeKeyDown open>
            <DialogTitle>
                <FormattedTranslation id={Translation.TOKEN_ERROR_DIALOG_TITLE} />
            </DialogTitle>
            <LinearProgress variant="determinate" value={0} />
            <DialogContent>
                <Validation error labelVariant="body1">
                    <FormattedTranslation id={Translation.TOKEN_ERROR_DIALOG_CONTENT} />
                </Validation>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={() => usecase.logout()}>
                    <FormattedTranslation id={Translation.BACK_TO_LOGIN} />
                </Button>
            </DialogActions>
        </Dialog>
    )
}
