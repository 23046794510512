import { selectUsersManagementRegistryLock } from '../../../../domain'

import { ClientRegistryUsecase } from './Base'

export class ToggleLockClientRegistryUsecase extends ClientRegistryUsecase {
    private async lock (): Promise<void> {
        await this.clientRegistryService.lock()
        this.setRegistry({ locked: true })
    }

    private async unlock (): Promise<void> {
        await this.clientRegistryService.unlock()
        this.setRegistry({ locked: false })
    }

    async invoke (): Promise<void> {
        await (selectUsersManagementRegistryLock(this.getState()) ? this.unlock() : this.lock())
    }
}
