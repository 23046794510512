import React, { type FC } from 'react'
import { Box, CircularProgress, DialogContent, type SxProps, type Theme, Typography } from '@mui/material'

import { isObjectEmpty } from '../../../../utils'

import { ConnectwareError, type CybusServiceParameters, Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'

import { useConfigurationTitle, useFileContent, useHasParameters, useId, useIsIdEditable, useParameters, useSchema, useUpdateServiceParameters } from './Hooks'
import { Form } from './Form'

type FormData = CybusServiceParameters & Record<'id', string | null>

const SchemaForm: FC = () => {
    const updateParameters = useUpdateServiceParameters()
    const isIdEditable = useIsIdEditable()

    const file = useFileContent()
    const id = useId()
    const parameters = useParameters()
    const schema = useSchema()

    if (file === null) {
        /**
         * File has not been loaded, render nothing
         */
        return null
    }

    if (schema === null) {
        /**
         * File has been loaded but schema hasnt, show loader
         */
        return <CircularProgress />
    }

    if (ConnectwareError.is(schema) || !parameters) {
        /**
         * Errors get displayed elsewhere, no need to display anything here
         */
        return null
    }

    if (!isIdEditable && isObjectEmpty(parameters)) {
        /**
         * Handles when the id is not editable and there are no parameters to change
         */
        return (
            <Typography id="nothing-to-configure-message" variant="subtitle2" align="left">
                <FormattedTranslation id={Translation.NOTHING_TO_CONFIGURE} />
            </Typography>
        )
    }

    return (
        <Form<FormData>
            id="serviceConfigurationForm"
            schema={schema}
            /** @todo investigate how to merge the type of two records */
            formData={{ id, ...parameters } as FormData}
            onChange={({ formData: { id, ...parameters } }) => updateParameters(id, parameters)}
        />
    )
}

const opaqueStyle: SxProps<Theme> = { opacity: 0.5 }
const formWrapperStyle: SxProps<Theme> = { maxHeight: 'calc(100vh - 250px)', overflowY: 'inherit', p: 1 }

export const ConfigurationSection: FC = () => {
    const configurationTitle = useConfigurationTitle()
    const hasParameters = useHasParameters()

    return (
        <DialogContent>
            <Typography variant="subtitle1" align="left" sx={hasParameters ? {} : opaqueStyle}>
                {'2. '}
                <FormattedTranslation id={configurationTitle} />
            </Typography>
            {!hasParameters && (
                <Typography variant="subtitle2" align="left" sx={opaqueStyle}>
                    <FormattedTranslation id={Translation.SERVICE_CONFIGURE_HINT} />
                </Typography>
            )}
            <Box sx={formWrapperStyle}>
                <SchemaForm />
            </Box>
        </DialogContent>
    )
}
