import React, { type FC, memo } from 'react'

import { type CybusUser, selectUsersManagementUsersTabData, Translation } from '../../../../domain'

import { FormattedList, FormattedTimePast, useTranslator } from '../../Internationalization'
import { useAppUsecases } from '../../State'
import { useAsyncCallback } from '../../Callback'

import { Table } from '../Table'

/**
 * @todo review this code block when https://cybusio.atlassian.net/browse/CYB-3564 is resolved
 */
export const UsersTable: FC = memo(() => {
    const translator = useTranslator()
    const { createUserUsecase, editUserUsecase } = useAppUsecases()
    const [onUserClick] = useAsyncCallback((u: CybusUser) => editUserUsecase.load(u))

    return (
        <Table
            columns={{
                username: { label: Translation.USERNAME },
                roles: {
                    label: translator.formatTranslation(Translation.ROLE, { count: 2 }),
                    customCellRender: (roles) =>
                        roles.length > 0 ? <FormattedList value={roles} style="narrow" /> : translator.formatTranslation(Translation.ROLE, { count: 0 }),
                },
                allPermissions: {
                    label: translator.formatTranslation(Translation.PERMISSION, { count: 0 }),
                    customCellRender: (permissions) =>
                        translator.formatTranslation(Translation.TOTAL_PERMISSIONS, { count: new Set(permissions.map((p) => p.resource)).size }),
                },
                authenticationMethods: {
                    label: Translation.AUTHENTICATION_METHODS,
                    customCellRender: (methods) =>
                        methods.length > 0 ? (
                            <FormattedList
                                value={methods.map((method) => translator.formatTranslation(Translation.USER_AUTHENTICATION_METHOD, { method })).sort()}
                                style="narrow"
                            />
                        ) : (
                            translator.formatTranslation(Translation.CANNOT_AUTHENTICATE)
                        ),
                },
                lastSeen: {
                    label: Translation.LAST_SEEN,
                    customCellRender: (lastSeen) =>
                        lastSeen === null ? translator.formatTranslation(Translation.NOT_AVAILABLE) : <FormattedTimePast value={lastSeen} />,
                },
                provider: {
                    label: Translation.PROVIDER,
                    customCellRender: (provider) => translator.formatTranslation(Translation.USER_PROVIDER, { provider }),
                },
            }}
            selector={selectUsersManagementUsersTabData}
            notFound={Translation.NO_USERS_FOUND}
            onAdd={() => createUserUsecase.start()}
            addLabel={Translation.ADD_USER}
            onRowClick={onUserClick}
        />
    )
})
