import type { VrcpRemoteEventHandler, VrpcRemote, VrpcRemoteEventsMap } from 'vrpc'

type ListenableRemote = Pick<VrpcRemote, 'on' | 'off'>

export const listenToRemote = <E extends keyof VrpcRemoteEventsMap>(
    remote: ListenableRemote,
    eventName: E,
    handler: VrcpRemoteEventHandler<E>
): VoidFunction => {
    remote.on(eventName, handler)
    return () => remote.off(eventName, handler)
}
