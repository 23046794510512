import React, { createRef, type FC } from 'react'
import { Box, chipClasses, type SxProps, TextField, type Theme } from '@mui/material'
import { Download, Search } from '@mui/icons-material'

import { TableToolbar as BaseTableToolbar, type MUIDataTableToolbar } from 'mui-datatables'

import { Translation } from '../../../../../domain'

import { useTranslator } from '../../../Internationalization'
import { Chip } from '../..'
import { Toolbar } from './Base'

const searchIconStyle: SxProps<Theme> = { mr: 1 }
const realToolbar: SxProps<Theme> = { display: 'none' }
const buttonsWrapper: SxProps<Theme> = { [`& .${chipClasses.root}`]: { my: 0.5, mr: 2, '&:last-of-type': { mr: 0 } } }

export const TableToolbar: FC<MUIDataTableToolbar> = (props) => {
    const { searchText, searchTextUpdate, options, displayData } = props
    const search = Boolean(options && options.search)
    const download = Boolean(options && options.download)
    const customToolbar = options && options.customToolbar

    const ref = createRef<HTMLElement>()
    const translator = useTranslator()

    return (
        <Toolbar position="space-between" align="start">
            <Box sx={buttonsWrapper}>
                {download && (
                    <>
                        <Chip
                            label={Translation.DOWNLOAD_CSV}
                            avatar={Download}
                            onClick={() => {
                                /**
                                 * The mui-datatables toolbar does not allow
                                 * for easy usage of the download CSV function
                                 * so this button proxies it
                                 */
                                const realCSVDownloadButton =
                                    ref.current && ref.current.querySelector<HTMLButtonElement>('button[data-testid="DownloadCSV-iconButton"]')
                                if (realCSVDownloadButton) {
                                    realCSVDownloadButton.click()
                                }
                            }}
                        />
                        <Box ref={ref} sx={realToolbar}>
                            <BaseTableToolbar {...props} options={{ ...options, customToolbar: undefined }} />
                        </Box>
                    </>
                )}
                {customToolbar && displayData && customToolbar({ displayData })}
            </Box>
            {search && (
                <TextField
                    placeholder={`${translator.formatTranslation(Translation.SEARCH)}...`}
                    variant="filled"
                    size="small"
                    margin="none"
                    InputProps={{ disableUnderline: true, hiddenLabel: true, startAdornment: <Search sx={searchIconStyle} fontSize="medium" /> }}
                    value={searchText || ''}
                    onChange={(e) => {
                        if (searchTextUpdate) {
                            searchTextUpdate(e.currentTarget.value)
                        }
                    }}
                    disabled={!searchTextUpdate}
                />
            )}
        </Toolbar>
    )
}
