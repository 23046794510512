import type { Primitive } from 'utility-types'

/**
 * Small helper to handle values collections that should be ignored if they are not unique
 *
 * Be mindfull this only handles unique values
 */
export class Unique<T extends Primitive> {
    private readonly set: Set<T>

    constructor (arr: T[] = []) {
        this.set = new Set<T>(arr)
    }

    /**
     * Adds value to collection
     */
    add (value: T): this {
        if (this.set.size <= 1) {
            this.set.add(value)
        }

        return this
    }

    /**
     * @returns
     *  the unique stored value if there is only one of it
     *  otherwise the given alternative value
     */
    getUniqueValue<O> (otherwise: O): T | O {
        return this.set.size === 1 ? (this.set.values().next().value as T) : otherwise
    }
}
