import { StatusType } from '../../../domain'

const valuesMap: Record<string, StatusType> = {
    available: StatusType.AVAILABLE,
    connected: StatusType.CONNECTED,
    connecting: StatusType.CONNECTING,
    created: StatusType.CREATED,
    dead: StatusType.DEAD,
    deviated: StatusType.DEVIATED,
    disabled: StatusType.DISABLED,
    disabling: StatusType.DISABLING,
    disconnected: StatusType.DISCONNECTED,
    disconnecting: StatusType.DISCONNECTING,
    enabled: StatusType.ENABLED,
    enabling: StatusType.ENABLING,
    exited: StatusType.EXITED,
    no_deviation: StatusType.NO_DEVIATION,
    not_enabled: StatusType.NOT_ENABLED,
    offline: StatusType.OFFLINE,
    online: StatusType.ONLINE,
    paused: StatusType.PAUSED,
    reconnecting: StatusType.RECONNECTING,
    reenabling: StatusType.REENABLING,
    restarting: StatusType.RESTARTING,
    rollingBack: StatusType.ROLLING_BACK,
    running: StatusType.RUNNING,
    starting: StatusType.STARTING,
    stopping: StatusType.STOPPING,
    unavailable: StatusType.UNAVAILABLE,
    updating: StatusType.UPDATING,
    /** k8 only @see https://bitbucket.org/cybusio/cybus/src/12c5735defa9b9c83b66feae2e720a51024e92ad/container-manager/src/Kubernetes.js#lines-453 */
    unknown: StatusType.UNKNOWN,
    waiting: StatusType.WAITING,
    terminated: StatusType.TERMINATED,
}

export const mapToStatusType = (value: unknown): StatusType => valuesMap[String(value)] || StatusType.UNKNOWN
