import { listenToProxy, type UnsubFromInstanceFunction, type VrcpEntityHandler, type VrpcHandlerConfiguration, type VrpcInstanceMapper } from '../Handler'

import { SERVICE_AGENT } from '../../../../../Connectware'
import { SERVICE_CLASSNAME } from '../../../../constants'
import type { ServiceProxy } from '../../../../proxies'

export abstract class BaseServiceMapper<T> implements VrpcInstanceMapper<ServiceProxy, T> {
    abstract mapToDomain (service: ServiceProxy): Promise<T | Map<string, T>>

    onChange (proxy: ServiceProxy, listener: () => void): Promise<UnsubFromInstanceFunction> {
        return listenToProxy(proxy, ['state', 'deviation'], listener)
    }
}

export abstract class BaseVrpcServiceProxyInstanceHandler<T> implements VrcpEntityHandler<ServiceProxy, T> {
    abstract readonly instanceMapper: VrpcInstanceMapper<ServiceProxy, T>

    readonly remoteMapper = null

    readonly configuration: VrpcHandlerConfiguration = { classNameFilter: SERVICE_CLASSNAME, agent: SERVICE_AGENT, supportedFilters: [] }
}
