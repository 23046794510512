import { useMemo } from 'react'

import { type CybusPermissionOperations, Translation } from '../../../domain'

import { useTranslator } from '../Internationalization'

export const useTranslatedOperationPermission: () => (operations: CybusPermissionOperations) => string = () => {
    const translator = useTranslator()

    return useMemo(
        () =>
            ({ read, write }) =>
                translator.formatTranslation(Translation.OPERATION_PERMISSION, { read, write }),
        [translator]
    )
}
