import { BelatedThrottler, Droppable } from '../../../utils'
import { areUsersManagementSelectedTabInformationFiltersEquals } from '../../../domain'

import { BaseUsecase } from './Base'

export class LoadUserManagementDataUsecase extends BaseUsecase {
    invoke (): VoidFunction {
        const droppable = new Droppable()

        /** So the load does not get triggered every time there is a state change */
        const throttler = new BelatedThrottler(this.configurationService.getUserManagementDelay())

        /** Listen to state changes, and after the calls are throttled, then load again */
        droppable.onDrop(
            this.subscribeToState(areUsersManagementSelectedTabInformationFiltersEquals, () => droppable.onDrop(throttler.run(() => void this.loadTab(false))))
        )

        /** Finally load everything */
        void this.loadTab(false)

        return () => droppable.drop()
    }
}
