import React, { type FC } from 'react'
import { type Theme, ThemeProvider } from '@mui/material'
import { SnackbarProvider } from 'notistack'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import 'typeface-roboto'

import { type AppStateProps, AppStateProvider } from './State'
import { TranslationProvider, type TranslationProviderProps } from './Internationalization'
import { ErrorBoundary } from './ErrorMessage'

export type ReactAppInjections = TranslationProviderProps & AppStateProps & Readonly<{ theme: Theme }>

export const AppWrapper: FC<ReactAppInjections> = ({ internationalization, manager, usecases, children, theme }) => (
    <AppStateProvider manager={manager} usecases={usecases}>
        <TranslationProvider internationalization={internationalization}>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <SnackbarProvider maxSnack={5} preventDuplicate>
                        <ErrorBoundary>{children}</ErrorBoundary>
                    </SnackbarProvider>
                </LocalizationProvider>
            </ThemeProvider>
        </TranslationProvider>
    </AppStateProvider>
)
