import { MAX_SAFE_TIMEOUT } from './Constants'

export const createInterval = (callback: VoidFunction, ms: number): VoidFunction => {
    /**
     * @see https://nodejs.org/api/timers.html#setintervalcallback-delay-args
     */
    if (ms > MAX_SAFE_TIMEOUT) {
        throw new Error(`Interval ${ms} not supported`)
    }

    const id = setInterval(callback, ms)
    return () => clearInterval(id)
}
