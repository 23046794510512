import type { PickByValueExact } from 'utility-types'

import { BelatedThrottler, createEqualityChecker, Droppable, executeOnce } from '../../../utils'

import {
    type AppState,
    arePaginationOptionsEquals,
    areSortConfigurationEquals,
    ConnectwareError,
    ConnectwareErrorType,
    type Page,
    type PaginationParameters,
    type SortableColumn,
} from '../../../domain'

import { initialState, type PageSubscriptionsTypes, type SubscriptionFilterArgs, type SubscriptionPageEvent, type SubscriptionPageOptions } from '../..'

import { Usecase } from '..'

export type PageSubscriptionArgs = SubscriptionFilterArgs & Readonly<{ short: boolean, throttle: number }>

const areSubscriptionPageOptionsEquals: <T extends keyof PageSubscriptionsTypes>(a: SubscriptionPageOptions<T>, b: SubscriptionPageOptions<T>) => boolean =
    createEqualityChecker<SubscriptionPageOptions<keyof PageSubscriptionsTypes>>({
        search: null,
        sort: areSortConfigurationEquals,
        pagination: arePaginationOptionsEquals,
    }) as <T extends keyof PageSubscriptionsTypes>(a: SubscriptionPageOptions<T>, b: SubscriptionPageOptions<T>) => boolean

export abstract class ResourcePageSubscriptionUsecase<PageName extends keyof PageSubscriptionsTypes> extends Usecase {
    /** The type of subscription that is being made */
    protected abstract readonly pageName: PageName

    /** Where the page should be stored in the state */
    protected abstract readonly pageAddress: keyof PickByValueExact<AppState, Page<PageSubscriptionsTypes[PageName]>>

    protected abstract readonly initialSortColumn: SortableColumn<PageSubscriptionsTypes[PageName]>

    readonly getPaginationSizes = executeOnce(() => this.configurationService.getResourcesPaginationSizes())

    private generatePersistenceName (short: boolean): string {
        /** Generate a unique id for the tables that are initialized as short or as default */
        return `${this.pageName}_${this.pageAddress}_${String(short)}`
    }

    private setPageState (page: Page<PageSubscriptionsTypes[PageName]>): void {
        this.setState({ [this.pageAddress]: page })
    }

    private selectPageFromState (s: AppState): Page<PageSubscriptionsTypes[PageName]> {
        return s[this.pageAddress] as Page<PageSubscriptionsTypes[PageName]>
    }

    private selectLoadedPageFromState (s: AppState): Exclude<Page<PageSubscriptionsTypes[PageName]>, null> {
        const state = this.selectPageFromState(s)
        if (state === null) {
            throw new ConnectwareError(ConnectwareErrorType.STATE, 'Resource page is not initialized', { pageAddress: this.pageAddress })
        }
        return state
    }

    private updatePageState (update: Partial<Page<PageSubscriptionsTypes[PageName]>>): void {
        this.setPageState({ ...this.selectLoadedPageFromState(this.getState()), ...update })
    }

    private selectSubscriptionPageOptions (s: AppState): SubscriptionPageOptions<PageName> {
        const { search, sort, pagination } = this.selectLoadedPageFromState(s)
        return { search, sort, pagination }
    }

    private updateStateWithPageEvents (event: SubscriptionPageEvent<PageName>): void {
        let { pagination, data } = this.selectLoadedPageFromState(this.getState())

        if (ConnectwareError.is(event)) {
            data = event
        } else {
            data = { current: event.current, totalCount: event.totalCount }

            if (pagination.page !== event.page) {
                /** Re-map the current page to be in track with BE */
                pagination = { ...pagination, page: event.page }
            }

            if (pagination.pageSize !== event.pageSize) {
                /** Re-map the current page to be in track with BE */
                pagination = { ...pagination, pageSize: event.pageSize }
            }
        }

        this.updatePageState({ pagination, data })
    }

    private initialize (short: boolean): void {
        const resourcePage = this.selectPageFromState(this.getState())

        if (resourcePage !== null) {
            throw new ConnectwareError(ConnectwareErrorType.STATE, 'Resource page was already initialized', { pageAddress: this.pageAddress })
        }

        const { page, pageSize, sort, search } = this.tablePersistenceService.retrieveTable<PageSubscriptionsTypes[PageName]>(
            this.generatePersistenceName(short)
        )

        this.setPageState({
            search: search === undefined ? null : search,
            sort: sort ? { asc: sort.asc, column: sort.column } : { asc: true, column: this.initialSortColumn },
            pagination: {
                /** Either use the customized page, or, if there is nothing customized, use the short or medium sizes */
                pageSize: pageSize === undefined ? (short ? this.getPaginationSizes()[0] : this.getPaginationSizes()[1]) : pageSize,
                page: page === undefined ? 0 : page,
            },
            data: null,
        })
    }

    subscribe ({ short, throttle, ...filter }: PageSubscriptionArgs): VoidFunction {
        const droppable = new Droppable()

        /** Initialize state to its first state */
        this.initialize(short)

        const updateThrottler = new BelatedThrottler(throttle)

        /** Start subscriptions finally */
        this.subscriptionsService
            .subscribeToPage(this.pageName, { ...this.selectSubscriptionPageOptions(this.getState()), ...filter })
            .then((subscription) => {
                /** Finally set internal listener */
                droppable.onDrop(subscription.onData((event) => this.updateStateWithPageEvents(event)))

                /** Listen to the state for any changes to the pagination setup */
                droppable.onDrop(
                    this.subscribeToState(
                        (prev, curr) => areSubscriptionPageOptionsEquals(this.selectSubscriptionPageOptions(prev), this.selectSubscriptionPageOptions(curr)),
                        () =>
                            /** Schedule an update */
                            droppable.onDrop(
                                updateThrottler.run(() => {
                                    const options = this.selectSubscriptionPageOptions(this.getState())

                                    this.tablePersistenceService.persistTable<PageSubscriptionsTypes[PageName]>(this.generatePersistenceName(short), {
                                        page: options.pagination.page,
                                        pageSize: options.pagination.pageSize,
                                        sort: options.sort,
                                        search: options.search,
                                    })

                                    subscription.update(options)
                                })
                            )
                    )
                )

                /** Kill internal subscription when dropped */
                droppable.onDrop(() => subscription.stop())
            })
            /** Warn off any issues with setting up the subscription */
            .catch((e: ConnectwareError) => this.updateStateWithPageEvents(e))
            /** Schedule reset as well */
            .finally(() => droppable.onDrop(() => this.setPageState(this.selectPageFromState(initialState))))

        return () => droppable.drop()
    }

    /**
     * @returns the updated parameters
     */
    updateParameters (update: Partial<PaginationParameters<PageSubscriptionsTypes[PageName]>>): PaginationParameters<PageSubscriptionsTypes[PageName]> {
        const current = this.selectSubscriptionPageOptions(this.getState())
        let updated = { ...current, ...update }

        if (updated.sort !== current.sort || updated.search !== current.search || updated.pagination.pageSize !== current.pagination.pageSize) {
            /** If updating the sorting, search or page size, reset user to the first page */
            updated = { ...updated, pagination: { ...updated.pagination, page: 0 } }
        }

        this.updatePageState({ ...updated, data: null })

        return updated
    }
}
