import React, { type FC } from 'react'

import { Alert, Link, type SxProps, type Theme, Typography } from '@mui/material'
import { Launch } from '@mui/icons-material'

import { Translation } from '../../../../domain'
import { DocumentationType } from '../../../../application'

import { FormattedTranslation, useTranslator } from '../../Internationalization'
import { useDocumentation } from '../../Documentation'

const actionNeededStyle: SxProps<Theme> = { my: '10px', mx: 0 }
const openDocumentationLinkStyle: SxProps<Theme> = { height: '1rem', width: '1rem', verticalAlign: 'text-bottom' }

export const NoLicense: FC<Readonly<{ id?: string }>> = ({ id }) => {
    const translator = useTranslator()

    const open = useDocumentation(DocumentationType.LICENSE_OFFLINE_ACTIVATION)

    return (
        <div id={id}>
            <Alert sx={actionNeededStyle} severity="warning">
                <FormattedTranslation id={Translation.LICENSE_ACTIVATION_NEEDED} />
            </Alert>
            <FormattedTranslation
                id={Translation.LICENSE_ACTIVATION_NEEDED_DESCRIPTION}
                values={{
                    paragraph: (...content: string[]) => <Typography variant="body1">{content}</Typography>,
                    link: (...content: string[]) => (
                        <Link title={translator.formatTranslation(Translation.OPEN_DOCUMENTATION)} onClick={open}>
                            {content} <Launch sx={openDocumentationLinkStyle} />
                        </Link>
                    ),
                }}
            />
        </div>
    )
}
