import type { EventListener } from '../../../utils'

import { type Capability, ConnectwareError, ConnectwareErrorType } from '../../../domain'

import type {
    CountSubscriptionsTypes,
    PageSubscription,
    PageSubscriptionsTypes,
    SingleSubscriptionsTypes,
    SubscriptionEventHandler,
    SubscriptionFilterArgs,
    SubscriptionListEventHandler,
    SubscriptionPageOptions,
    SubscriptionsService,
    SubscriptionsTypes,
    TranslationService,
} from '../../../application'

import { PageSubscriptionAdapter } from '../../Connectware'
import { FetchConnectwareHTTPService } from '../Base'
import { Fetcher } from './Fetcher'
import { getSubscriptionStrategyFactory, type SubscriptionStrategy } from './strategies'

/**
 * The vrpc alternative to subscribing to the changes of resources on the Connectware
 * Currently it is very limited, to only simple services list
 */
export class ConnectwareHTTPSubscriptionsService extends FetchConnectwareHTTPService implements SubscriptionsService {
    private readonly pageSubscriptionAdapter = new PageSubscriptionAdapter(this, this.translationService)

    constructor (
        baseURL: string,
        tokenGetter: () => string | null,
        private readonly capabilitiesGetter: () => Capability[],
        private readonly translationService: TranslationService,
        private readonly resourceChangeListeners: Pick<EventListener<void>, 'on'>
    ) {
        super(baseURL, tokenGetter)
    }

    private subscribe<T extends keyof SubscriptionsTypes, F> (
        eventName: T,
        fetcherCreator: (strategy: SubscriptionStrategy<T>) => Fetcher<F>
    ): Promise<VoidFunction> {
        const Strategy = getSubscriptionStrategyFactory(eventName)
        const strategy = new Strategy((args) => this.request(args))

        const userPermissions = new Set(this.capabilitiesGetter())
        const { requiredPermissions } = strategy

        if (requiredPermissions.some((p) => !userPermissions.has(p))) {
            return Promise.reject(
                new ConnectwareError(ConnectwareErrorType.AUTHENTICATION, "Can't subscribe to this type due to permissioning issues", {
                    userPermissions: Array.from(userPermissions),
                    requiredPermissions,
                    eventName,
                })
            )
        }

        const fetcher = fetcherCreator(strategy).start(this.getPollingInterval())

        /** Setup listener for any changes made by the current user */
        const unsubChanges = this.resourceChangeListeners.on(() => fetcher.update())

        return Promise.resolve(() => {
            /** Stop listing to external changes */
            unsubChanges()

            /** Stop regular fetcher */
            fetcher.stop()
        })
    }

    // eslint-disable-next-line class-methods-use-this
    protected getPollingInterval (): number {
        return 30_000
    }

    subscribeToPage<T extends keyof PageSubscriptionsTypes> (
        eventName: T,
        options: SubscriptionFilterArgs & SubscriptionPageOptions<T>
    ): Promise<PageSubscription<T>> {
        return this.pageSubscriptionAdapter.subscribeToPage(eventName, options)
    }

    async subscribeToAll<T extends keyof SubscriptionsTypes> (
        eventName: T,
        filter: SubscriptionFilterArgs,
        listener: SubscriptionListEventHandler<T>
    ): Promise<VoidFunction> {
        return this.subscribe(eventName, (strategy) =>
            new Fetcher(
                () =>
                    strategy.supportsFilters(filter)
                        ? strategy.retrieveAll(filter)
                        : Promise.reject(new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'This strategy does NOT support this filter', { filter })),
                (...args) => strategy.compareAll(...args)
            ).onChange(listener)
        )
    }

    async subscribeToOne<T extends keyof SingleSubscriptionsTypes> (eventName: T, id: string, listener: SubscriptionEventHandler<T>): Promise<VoidFunction> {
        return this.subscribe(eventName, (strategy) =>
            new Fetcher(
                () => strategy.retrieveOne(id),
                (...args) => strategy.compareOne(...args)
            ).onChange(listener)
        )
    }

    async subscribeToCount<T extends keyof CountSubscriptionsTypes> (eventName: T, listener: (count: number | ConnectwareError) => void): Promise<VoidFunction> {
        return this.subscribe(eventName, (strategy) =>
            new Fetcher(
                () => strategy.retrieveCount(),
                (a, b) => a === b
            ).onChange(listener)
        )
    }
}
