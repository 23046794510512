import { ConnectwareError } from '../../../../../domain'

import type { SubscriptionData, SubscriptionFilterArgs, SubscriptionsTypes } from '../../../../../application'

import type { UnsubFromInstanceFunction, VrpcInstanceMapper, VrpcInstanceTypes } from '../handlers'
import { EntitiesManager, EntityManager } from '../managers/Manager'

/**
 * Manages a single proxy instance
 * Said proxy intance can spawn
 * - one or many front-end domain entities
 * - one listener unsub function
 */
class VrpcInstanceManager<T extends keyof SubscriptionsTypes> extends EntityManager<T, Parameters<UnsubFromInstanceFunction>> {
    protected readonly errorMessage: string = 'Error while interacting with instance'

    protected readonly internallyDroppedArgs: Parameters<UnsubFromInstanceFunction> = [false]

    constructor (
        private readonly name: string,
        private readonly filter: SubscriptionFilterArgs,
        private readonly proxy: VrpcInstanceTypes[T],
        private readonly mapper: VrpcInstanceMapper<VrpcInstanceTypes[T], SubscriptionData<T>>,
        handler: VoidFunction
    ) {
        super(handler)

        void this.safelyAddListener()
        void this.safelySetValue()
    }

    protected getErrorExtras (): Record<string, unknown> {
        return { ...super.getErrorExtras(), instance: this.name }
    }

    protected addListener (): Promise<UnsubFromInstanceFunction | void> | void {
        return this.mapper.onChange?.(this.proxy, () => void this.safelySetValue())
    }

    protected generateValue (): Promise<SubscriptionData<T> | Map<string, SubscriptionData<T>>> {
        return this.mapper.mapToDomain(this.proxy, this.name, this.filter)
    }
}

export type InstanceAddress = [agent: string | null, className: string | null, name: string] | string

/**
 * Manages all proxy instances
 */
export class VrpcInstancesManager<T extends keyof SubscriptionsTypes> extends EntitiesManager<T, string, Parameters<UnsubFromInstanceFunction>> {
    protected readonly internallyDroppedArgs: Parameters<UnsubFromInstanceFunction> = [false]

    private static parse (address: InstanceAddress): [name: string, hash: string] {
        const fullAddress: Exclude<InstanceAddress, string> = typeof address === 'string' ? [null, null, address] : address
        const [, , name] = fullAddress
        return [name, JSON.stringify(fullAddress)]
    }

    constructor (private readonly mapper: VrpcInstanceMapper<VrpcInstanceTypes[T], SubscriptionData<T>>, handler: VrpcInstancesManager<T>['handler']) {
        super(handler)
    }

    /**
     * To add a specific instance
     * @throws never, errors go to the change listener
     */
    async addInstance (address: InstanceAddress, filter: SubscriptionFilterArgs, proxyPromise: Promise<VrpcInstanceTypes[T]>): Promise<void> {
        /** Load proxy or get ConnectwareError */
        const proxy = await proxyPromise.catch((e: ConnectwareError) => e)

        const [name, hash] = VrpcInstancesManager.parse(address)

        await super.add(hash, () => (ConnectwareError.is(proxy) ? proxy : new VrpcInstanceManager(name, filter, proxy, this.mapper, () => this.emitChange())))
    }

    /**
     * To drop a specific instance
     * @throws never, errors go to the change listener
     */
    async removeInstance (address: InstanceAddress, isGone: boolean): Promise<void> {
        const [, hash] = VrpcInstancesManager.parse(address)

        await this.remove(hash, isGone)

        /** Emit change after removal */
        this.emitChange()
    }

    /**
     * Calls the listener with the values of the managed instances
     */
    emitChange (): void {
        super.emitChange()
    }
}
