import { selectUsersManagement, selectUsersManagementSelectedTab, type UsersManagement, type UsersManagementAppState } from '..'

const createUsersManagementSelectedTabInformationSelector =
    <S extends UsersManagement['selected'], P extends keyof UsersManagement[S]>(
        type: S,
        propName: P
    ): ((s: UsersManagementAppState) => UsersManagement[S][P]) =>
    (s) =>
        selectUsersManagement(s)[type][propName]

export const selectUsersManagementUsersTabData = createUsersManagementSelectedTabInformationSelector('users', 'data')
export const selectUsersManagementRolesTabData = createUsersManagementSelectedTabInformationSelector('roles', 'data')
export const selectUsersManagementPermissionsTabData = createUsersManagementSelectedTabInformationSelector('permissions', 'data')

export const selectUsersManagementSelectedTabInformation = (s: UsersManagementAppState): UsersManagement[UsersManagement['selected']] =>
    selectUsersManagement(s)[selectUsersManagementSelectedTab(s)]

export const selectUsersManagementPage = (s: UsersManagementAppState): number => selectUsersManagementSelectedTabInformation(s).page
export const selectUsersManagementShowInternal = (s: UsersManagementAppState): boolean => selectUsersManagementSelectedTabInformation(s).showInternal
export const selectUsersManagementSearch = (s: UsersManagementAppState): string => selectUsersManagementSelectedTabInformation(s).search

export const areUsersManagementSelectedTabInformationFiltersEquals = (a: UsersManagementAppState, b: UsersManagementAppState): boolean =>
    selectUsersManagementSelectedTab(a) === selectUsersManagementSelectedTab(b) &&
    selectUsersManagementSearch(a) === selectUsersManagementSearch(b) &&
    selectUsersManagementPage(a) === selectUsersManagementPage(b) &&
    selectUsersManagementShowInternal(a) === selectUsersManagementShowInternal(b)
