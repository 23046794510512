import { areCybusServiceEquals, Capability, type CybusService } from '../../../../../../domain'

import { type CybusServiceProxyInfo, type LinkedService, mapBaseService } from '../../../../../Connectware'

import { BaseSubscriptionStrategy } from '../Base'

export class ServicesStrategy extends BaseSubscriptionStrategy<'services'> {
    readonly requiredPermissions = [Capability.SERVICES_READ]

    protected readonly areEquals = areCybusServiceEquals

    retrieveAll (): Promise<CybusService[]> {
        return Promise.all([
            this.retrieve({
                capability: Capability.SERVICES_READ,
                method: 'GET',
                path: '/api/services/info',
                mapper: (data: CybusServiceProxyInfo[]) => data,
            }),
            this.retrieve({
                capability: Capability.SERVICES_READ,
                method: 'GET',
                path: '/api/services/all-links',
                mapper: (data: LinkedService[]) => data,
            }),
        ]).then(([info, links]) => {
            const indexed = links.reduce<Record<LinkedService['id'], LinkedService['links']>>(
                (r, linkedService) => ({ ...r, [linkedService.id]: linkedService.links }),
                {}
            )
            return info.map((data) => ({ ...mapBaseService(data), links: indexed[data.id] || [] })).sort((a, b) => a.id.localeCompare(b.id))
        })
    }

    retrieveCount (): Promise<number> {
        return this.retrieve({ capability: Capability.SERVICES_READ, method: 'GET', path: '/api/services/instances', mapper: (data: string[]) => data.length })
    }
}
