import React, { type FC } from 'react'
import { Box, CircularProgress, type CircularProgressProps, type SxProps, type Theme } from '@mui/material'

const wrapperStyle: SxProps<Theme> = { my: 4, textAlign: 'center' }

export const CircularLoader: FC<Pick<CircularProgressProps, 'size'>> = ({ size, ...props }) => (
    <Box {...props} sx={wrapperStyle}>
        <CircularProgress size={size} />
    </Box>
)
