import type { MfaRequiredAuthentication } from '..'
import { ConnectwareError, ConnectwareErrorType } from '../Error'

export class SessionFromTokenNotFoundError extends ConnectwareError {
    static is (e: unknown): e is SessionFromTokenNotFoundError {
        return e instanceof SessionFromTokenNotFoundError
    }

    constructor () {
        super(ConnectwareErrorType.AUTHENTICATION, 'Session not found')
    }
}

export class MfaRequiredError extends ConnectwareError<ConnectwareErrorType.AUTHENTICATION, Readonly<{ secret: string }>> {
    static is (e: unknown): e is MfaRequiredError {
        return e instanceof MfaRequiredError
    }

    constructor (secret: string) {
        super(ConnectwareErrorType.AUTHENTICATION, 'Multi-factor authentication required', { secret })
    }

    get secret (): string {
        return this.extras.secret
    }
}

export class MfaSetupRequiredError extends ConnectwareError<ConnectwareErrorType.AUTHENTICATION, MfaRequiredAuthentication> {
    static is (e: unknown): e is MfaSetupRequiredError {
        return e instanceof MfaSetupRequiredError
    }

    constructor (authentication: MfaRequiredAuthentication) {
        super(ConnectwareErrorType.AUTHENTICATION, 'Multi-factor authentication setup required', authentication)
    }
}
