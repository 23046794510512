import type { CybusService, SortableColumn } from '../../../domain'

import { ResourcePageSubscriptionUsecase, ResourceSingleSubscriptionUsecase } from '../Resources'

export class ManageServicesUsecase extends ResourcePageSubscriptionUsecase<'services'> {
    protected readonly pageName = 'services'

    protected readonly pageAddress = 'servicesPage'

    protected readonly initialSortColumn: SortableColumn<CybusService> = 'id'
}

export class ManageServiceUsecase extends ResourceSingleSubscriptionUsecase<'service'> {
    protected readonly singularName = 'service'

    protected readonly singleAddress = 'service'
}
