import { createNamedEqualityChecker } from '../../utils'

import { type CybusService, StatusType } from '..'

export type CybusServer = Readonly<{ id: string, service: CybusService['id'] | null, name: string, protocol: string, status: StatusType }>

export const canServerBeEnabled = (Server: Pick<CybusServer, 'status'>): boolean => Server.status === StatusType.DISABLED
export const canServerBeDeleted = (Server: Pick<CybusServer, 'status'>): boolean => Server.status === StatusType.DISABLED
export const canServerBeDisabled = (Server: Pick<CybusServer, 'status'>): boolean => Server.status === StatusType.ENABLED

export const areCybusServersEquals = createNamedEqualityChecker<CybusServer>(
    { id: null, service: null, name: null, status: null, protocol: null },
    'areCybusServersEquals'
)
