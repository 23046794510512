import {
    type AppVersions,
    type BackupMaintenanceStatus,
    Capability,
    ConnectwareError,
    ConnectwareErrorType,
    type ConnectwareLicense,
    type Metrics,
    type SystemConnectivity,
} from '../../../domain'

import type { SystemService } from '../../../application'

import { FetchConnectwareHTTPService, type HttpRequestArgs } from '../Base'
import type { MaintenanceDbResponse, MetricsResponse, PreflightResponse, SystemInfoResponse } from './Response'
import { isVersionOutdated, mapBackupFile, mapBackupFileError, mapConnectivity, mapLicenseRequestError, mapMaintenanceDbStatus, mapMetrics } from './Mappers'

type Config = Pick<HttpRequestArgs<never, never>, 'capability' | 'method' | 'path'>

export class CybusSystemService extends FetchConnectwareHTTPService implements SystemService {
    private readonly systemReadConfig: Config = { capability: Capability.MINIMUM, method: 'GET', path: '/api/system/preflight' }
    private readonly metricsReadConfig: Config = { capability: Capability.SYSTEM_METRICS_READ, method: 'GET', path: '/api/system/preflight' }

    constructor (baseURL: string, tokenGetter: () => string | null, private readonly uiVersion: AppVersions['ui']) {
        super(baseURL, tokenGetter)
    }

    private fetchSystemInfo<T> (mapper: (response: SystemInfoResponse) => T): Promise<T> {
        return this.request({
            capability: Capability.MINIMUM,
            method: 'GET',
            path: '/api/system/info',
            authenticate: true,
            handlers: { 200: (response) => response.getJson<SystemInfoResponse>().then(mapper) },
        })
    }

    private fetchInternalConnectivity (config: 'systemReadConfig' | 'metricsReadConfig'): Promise<SystemConnectivity> {
        return this.request({
            ...this[config],
            authenticate: true,
            handlers: { 200: async (response) => response.getJson<PreflightResponse>().then(mapConnectivity) },
        })
    }

    getUIVersion (): string {
        return this.uiVersion
    }

    async fetchConnectivity (): Promise<SystemConnectivity> {
        const connectivity = await this.fetchInternalConnectivity('systemReadConfig')

        if (!connectivity.latest?.version) {
            return connectivity
        }

        const currentVersion = this.getUIVersion()

        if (currentVersion === connectivity.latest.version) {
            return connectivity
        }

        const isCurrentVersionOutdated = isVersionOutdated(connectivity.latest.version, currentVersion)

        if (isCurrentVersionOutdated) {
            /**
             * Current connectware is out of date
             * And that is ok
             */
            return connectivity
        }

        /**
         * Current version is ahead, so just say the latest is the current
         */
        return { ...connectivity, latest: { ...connectivity.latest, version: currentVersion } }
    }

    fetchLicense (): Promise<ConnectwareLicense> {
        return this.fetchSystemInfo(({ license: { file: { id, associatedAccount, connectwareLicenseClass, expiration } = {}, name, valid } }) => {
            const parsedExpiration = (expiration && new Date(expiration)) ?? null

            if (!id || !associatedAccount || !name || !connectwareLicenseClass || !parsedExpiration || isNaN(parsedExpiration.getTime())) {
                return { id: null, activated: false, account: null, name: null, class: null, expiration: null, valid: false }
            }

            return {
                id,
                activated: true,
                account: associatedAccount,
                name,
                class: connectwareLicenseClass,
                expiration: parsedExpiration,
                valid: Boolean(valid),
            }
        })
    }

    refreshLicense (): Promise<void> {
        return this.request({
            capability: Capability.SYSTEM_LICENSE_MANAGE,
            method: 'GET',
            path: '/api/system/refresh',
            authenticate: true,
            handlers: { 200: () => Promise.resolve(), 400: (response) => mapLicenseRequestError(response, 'unableToRefresh') },
        })
    }

    uploadLicenseFile (payload: string): Promise<void> {
        return this.request({
            capability: Capability.SYSTEM_LICENSE_MANAGE,
            method: 'PUT',
            path: '/api/system/licensefile',
            authenticate: true,
            body: { payload },
            handlers: { 200: () => Promise.resolve(), 400: (response) => mapLicenseRequestError(response, 'unableToUpdate') },
        })
    }

    async fetchVersions (): Promise<AppVersions> {
        return { ui: this.getUIVersion(), connectware: await this.fetchSystemInfo((data) => data.version) }
    }

    fetchMetrics (): Promise<Metrics> {
        return Promise.all([
            this.request({
                capability: Capability.SYSTEM_METRICS_READ,
                method: 'GET',
                path: '/api/system/metrics',
                authenticate: true,
                queryParams: { daily: 'true', hourly: 'true' },
                handlers: {
                    200: async (response) => await response.getJson<MetricsResponse>(),
                    400: () => new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, 'Unable to fetch metrics'),
                },
            }),
            this.fetchInternalConnectivity('metricsReadConfig'),
        ]).then(([metrics, connectivity]) => mapMetrics(metrics, connectivity))
    }

    sendMetricsToPortal (): Promise<void> {
        return this.request({
            capability: Capability.SYSTEM_METRICS_MANAGE,
            method: 'PUT',
            path: '/api/system/metrics',
            authenticate: true,
            handlers: {
                200: () => Promise.resolve(),
                400: () => new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, 'Unable to send metrics to portal'),
            },
        })
    }

    fetchBackupStatus (): Promise<BackupMaintenanceStatus> {
        return this.request({
            capability: Capability.SYSTEM_BACKUP_MANAGE,
            method: 'GET',
            path: '/api/maintenance/db',
            authenticate: true,
            handlers: {
                200: async (response) => mapMaintenanceDbStatus(await response.getJson<MaintenanceDbResponse>()),
                400: () => new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, 'Unable to fetch maintenance status'),
                423: () => new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, 'Database maintenance operation in progress'),
            },
        })
    }

    createBackup (): Promise<void> {
        return this.request({
            capability: Capability.SYSTEM_BACKUP_MANAGE,
            method: 'POST',
            path: '/api/maintenance/db/backup',
            authenticate: true,
            handlers: {
                202: () => Promise.resolve(),
                400: () => new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, 'Unable to create backup'),
                423: () => new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, 'Database maintenance operation in progress'),
            },
        })
    }

    downloadBackup (): Promise<File> {
        return this.request({
            capability: Capability.SYSTEM_BACKUP_MANAGE,
            method: 'GET',
            path: '/api/maintenance/db/backup',
            authenticate: true,
            handlers: {
                200: async (response) => mapBackupFile(response),
                400: () => new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, 'Unable to download backup_archive file'),
                423: () => new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, 'Database maintenance operation in progress'),
                500: async (response) => mapBackupFileError(response),
            },
        })
    }

    uploadBackup (fileContent: File): Promise<void> {
        /**
         * in order for the controller to correctly process the file a formdata has to be created and the file appended
         */
        const formData = new FormData()
        formData.append('file', fileContent)
        return this.request({
            capability: Capability.SYSTEM_BACKUP_MANAGE,
            method: 'POST',
            path: '/api/maintenance/db/restore',
            authenticate: true,
            body: formData,
            handlers: {
                202: () => Promise.resolve(),
                400: () => new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, 'Unable to restore from backup'),
                423: () => new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, 'Database maintenance operation in progress'),
            },
        })
    }
}
