import { type BufferType, Translation } from '../../../../domain'

import { useTranslator } from '../../Internationalization'

export const useBufferTypeTranslator = (): ((type: BufferType) => string) => {
    const translator = useTranslator()

    return (type) => {
        return translator.formatTranslation(Translation.TOPIC_TYPE, { type })
    }
}
