import { Droppable, EventListener } from '../../../utils'

import { ConnectwareError, ConnectwareErrorType } from '../../../domain'

import type {
    ListSubscriptionsTypes,
    SingleSubscriptionsTypes,
    SubscriptionEvent,
    SubscriptionFilterArgs,
    SubscriptionListEvent,
    SubscriptionsService,
    SubscriptionsTypes,
} from '../..'

import { type LogoutUsecase } from '..'

abstract class SubscriptionBuilder<T extends keyof SubscriptionsTypes, E> {
    protected readonly onErrorsHandlers = new EventListener<ConnectwareError>()

    protected readonly onChangeHandlers = new EventListener<E>()

    constructor (
        protected readonly eventName: T,
        protected readonly subscriptionsService: SubscriptionsService,
        private readonly logoutUsecase: LogoutUsecase
    ) {}

    protected abstract listen (): Promise<VoidFunction>

    onChange (onChange: (data: ConnectwareError | E) => void): this {
        this.onErrorsHandlers.on(onChange)
        this.onChangeHandlers.on(onChange)
        return this
    }

    subscribe (): VoidFunction {
        const droppable = new Droppable()

        /**
         * Subscribe to changes
         */
        this.listen()
            .then((off) => {
                /**
                 * Once parent callback is called, we too should signal the service that it can drop its internall assets
                 */
                droppable.onDrop(off)
            })
            .catch((e: ConnectwareError) => {
                const isAuth = ConnectwareError.isOfTypes(e, ConnectwareErrorType.AUTHENTICATION)

                if (isAuth) {
                    /**
                     * Notify of authorization issue
                     */
                    this.logoutUsecase.logout()
                } else {
                    /**
                     * If during the sub process there are any errors, this will handle them
                     */
                    this.onErrorsHandlers.trigger(e)
                }
            })

        return () => droppable.drop()
    }
}

export class SubscriptionToAllBuilder<T extends keyof ListSubscriptionsTypes> extends SubscriptionBuilder<T, SubscriptionListEvent<T>> {
    private filter: SubscriptionFilterArgs = {}

    protected listen (): Promise<VoidFunction> {
        return this.subscriptionsService.subscribeToAll(this.eventName, this.filter, (data) => this.onChangeHandlers.trigger(data))
    }

    withFilter (filter: SubscriptionFilterArgs): this {
        this.filter = filter
        return this
    }
}

export class SubscriptionToSingleBuilder<T extends keyof SingleSubscriptionsTypes> extends SubscriptionBuilder<T, SubscriptionEvent<T>> {
    private id: string | null = null

    protected listen (): Promise<VoidFunction> {
        if (!this.id) {
            return Promise.reject(new ConnectwareError(ConnectwareErrorType.STATE, 'Id is not set'))
        }

        return this.subscriptionsService.subscribeToOne(this.eventName, this.id, (data) => this.onChangeHandlers.trigger(data))
    }

    withId (id: string): this {
        this.id = id
        return this
    }
}
