import React, { type FC } from 'react'
import { Button, type ButtonProps, CircularProgress, type SxProps, type Theme, Tooltip } from '@mui/material'

import { isEnumOf, type NonEmptyArray } from '../../../utils'

import { type Capability, Translation } from '../../../domain'
import { FormattedTranslation } from '../Internationalization'
import { useEnabled } from './hooks'

type Props = Readonly<{
    tooltip?: Translation
    translation?: string | Translation
    target?: string
    loading?: boolean
    capabilities?: NonEmptyArray<Capability>
}> &
    Pick<ButtonProps, 'id' | 'onClick' | 'color' | 'disabled' | 'href'>

const suffixStyle: SxProps<Theme> = { ml: 1, my: 'auto' }
const buttonStyle: SxProps<Theme> = { color: 'primary.700', '&:hover': { backgroundColor: 'grey.100' } }

export const ActionButton: FC<Props> = ({ tooltip, translation, loading = false, disabled, capabilities, children, ...props }) => {
    const enabled = useEnabled(disabled, capabilities)

    const button = (
        <Button variant="text" sx={buttonStyle} disabled={!enabled} {...props}>
            {translation ? isEnumOf(Translation, translation) ? <FormattedTranslation id={translation} /> : translation : children}
            {loading && <CircularProgress sx={suffixStyle} size="1rem" color="inherit" />}
        </Button>
    )

    if (!enabled || !tooltip) {
        return button
    }

    return (
        <Tooltip placement="bottom" title={<FormattedTranslation id={tooltip} />}>
            {button}
        </Tooltip>
    )
}
