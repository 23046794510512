import { createPageSelector, type Page } from '..'
import { createLoadedSelector, type Loadable } from '../Loadable'
import {
    canServiceBeDisabled,
    canServiceBeEnabled,
    type CybusDetailedService,
    type CybusLinkedService,
    type CybusService,
    type CybusServiceCatalog,
    type CybusServiceForm,
} from '.'

export type ServicesAppState = Readonly<{
    service: Loadable<CybusDetailedService>
    servicesPage: Page<CybusService>
    servicesLinksPage: Page<CybusLinkedService>
    serviceForm: CybusServiceForm | null
    catalog: CybusServiceCatalog
    deviations: CybusService['id'][]
}>

export const selectService = (s: ServicesAppState): ServicesAppState['service'] => s.service

export const selectServicesPage = (s: ServicesAppState): ServicesAppState['servicesPage'] => s.servicesPage

export const selectServicesLinksPage = (s: ServicesAppState): ServicesAppState['servicesLinksPage'] => s.servicesLinksPage

export const selectServiceForm = (s: ServicesAppState): ServicesAppState['serviceForm'] => s.serviceForm

export const selectServiceCatalog = (s: ServicesAppState): ServicesAppState['catalog'] => s.catalog

const createServiceCatalogSelector =
    <K extends keyof ServicesAppState['catalog']>(name: K) =>
    (s: ServicesAppState): ServicesAppState['catalog'][K] =>
        selectServiceCatalog(s)[name]

export const selectServiceCatalogTemplate = createServiceCatalogSelector('template')
export const selectServiceCatalogFiltered = createServiceCatalogSelector('filtered')
export const selectServiceCatalogApplication = createServiceCatalogSelector('application')
export const selectServiceCatalogApplications = createServiceCatalogSelector('applications')
export const selectServiceCatalogSearch = createServiceCatalogSelector('search')

export const selectDisablableLoadedDetailedService = createLoadedSelector(selectService, 'id', canServiceBeDisabled)
export const selectDeletableLoadedDetailedService = createLoadedSelector(selectService, 'id')
export const selectEnabableLoadedDetailedService = createLoadedSelector(selectService, 'id', canServiceBeEnabled)

export const selectDisablableLoadedServices = createPageSelector(selectServicesPage, 'id', 'selectDisablableLoadedServices', canServiceBeDisabled)
export const selectDeletableLoadedServices = createPageSelector(selectServicesPage, 'id', 'selectDeletableLoadedServices')

export const selectDeviations = (s: ServicesAppState): ServicesAppState['deviations'] => s.deviations

export const selectHasDeviations = (s: ServicesAppState): boolean => selectDeviations(s).length > 0
