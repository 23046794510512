import { areRecordsEquals, type Comparators, createNamedEqualityChecker, createPrimitiveValueComparator } from '../../utils'
import type { StatusType } from '..'

export type CybusVolume = Readonly<{ id: string, name: string, service: string | null, created: Date, status: StatusType }>

export type CybusDetailedVolume = CybusVolume & Readonly<{ labels: Record<string, string> }>

const cybusVolumeComparators: Comparators<CybusVolume> = {
    id: null,
    name: null,
    service: null,
    created: createPrimitiveValueComparator((c) => c.getTime()),
    status: null,
}

export const areCybusVolumeEquals = createNamedEqualityChecker<CybusVolume>(cybusVolumeComparators, 'areCybusVolumeEquals')

export const areDetailedCybusVolumeEquals = createNamedEqualityChecker<CybusDetailedVolume>(
    { ...cybusVolumeComparators, labels: areRecordsEquals },
    'areDetailedCybusVolumeEquals'
)
