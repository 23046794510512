import { ConnectwareError } from '../Error'
import { createLoadedSelector, type Loadable } from '../Loadable'
import { createPageSelector, type Page } from '..'
import { type TopicMessageEvent } from '../Topics'
import { canMappingBeDeleted, canMappingBeDisabled, canMappingBeEnabled, type CybusDetailedMapping, type CybusMapping, type CybusPubSub } from './Mapping'

export type MappingsAppState = Readonly<{
    mapping: Loadable<CybusDetailedMapping>
    mappingEntries: Loadable<Record<CybusPubSub['id'], TopicMessageEvent>>
    mappingsPage: Page<CybusMapping>
}>

export const selectMapping = (s: MappingsAppState): MappingsAppState['mapping'] => s.mapping
export const selectMappingEntries = (s: MappingsAppState): MappingsAppState['mappingEntries'] => s.mappingEntries
export const selectMappingsPage = (s: MappingsAppState): MappingsAppState['mappingsPage'] => s.mappingsPage

const createLoadedMappingSelector =
    <K extends keyof CybusDetailedMapping, D>(key: K, defaultValue: D): ((s: MappingsAppState) => CybusDetailedMapping[K] | D) =>
    (s) => {
        const mapping = selectMapping(s)
        if (!mapping || ConnectwareError.is(mapping)) {
            return defaultValue
        }
        return mapping[key]
    }

export const selectLoadedMappingEntries = createLoadedMappingSelector('entries', [])

export const selectLoadedMappingEntriesTopics = (s: MappingsAppState): [CybusPubSub['topics'], Record<CybusPubSub['id'], CybusPubSub['topics']>] => {
    const entries = selectLoadedMappingEntries(s)

    /** All the entry topics and mapping back to entries need to be mapped */
    const allTopics: CybusPubSub['topics'] = []
    const idToTopics: Record<CybusPubSub['id'], CybusPubSub['topics']> = {}

    entries.forEach(({ subscribe, publish }) => {
        const pubSub = [...subscribe, publish]

        pubSub.forEach(({ id, topics, externalBroker }) => {
            if (externalBroker) return
            idToTopics[id] = idToTopics[id] || topics
            allTopics.push(...topics)
        })
    })

    return [allTopics, idToTopics]
}

export const selectDeletableLoadedMappings = createPageSelector(selectMappingsPage, 'id', 'selectDeletableLoadedMappings', canMappingBeDeleted)
export const selectEnabableLoadedMappings = createPageSelector(selectMappingsPage, 'id', 'selectEnabableLoadedMappings', canMappingBeEnabled)
export const selectDisabableLoadedMappings = createPageSelector(selectMappingsPage, 'id', 'selectDisabableLoadedMappings', canMappingBeDisabled)

export const selectDeletableLoadedDetailedMapping = createLoadedSelector(selectMapping, 'id', canMappingBeDeleted)
export const selectEnabableLoadedDetailedMapping = createLoadedSelector(selectMapping, 'id', canMappingBeEnabled)
export const selectDisabableLoadedDetailedMapping = createLoadedSelector(selectMapping, 'id', canMappingBeDisabled)

export const selectMappingEntriesError = (s: MappingsAppState): ConnectwareError | null => {
    const entries = selectMappingEntries(s)
    return ConnectwareError.is(entries) ? entries : null
}

export const selectMappingEntry = (s: MappingsAppState, id: CybusPubSub['id']): TopicMessageEvent | null => {
    const entries = selectMappingEntries(s)
    return (!ConnectwareError.is(entries) && entries && entries[id]) || null
}
