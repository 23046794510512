import type { NonUndefined } from 'utility-types'

import { type Translation } from '../../../../domain'

import {
    AbsoluteRouteOnlyPath,
    type AbsoluteRoutePath,
    AbsoluteRoutePathWithId,
    AbsoluteRoutePathWithServiceAndResourceId,
    AbsoluteRoutePathWithServiceId,
} from '../Domain'
import { getRouteConfig } from '../Config'

/**
 * Either a stand-in title or an actual route
 */
type BreadcrumbParentConfig =
    | Readonly<{ title: Translation, isTitleSingular: boolean, id: null, path: null }>
    | Readonly<{
          title: Translation
          isTitleSingular: boolean
          id: AbsoluteRouteOnlyPath | AbsoluteRoutePathWithServiceId
          path: string
          breadcrumbTitle: Translation | null
      }>

type BreadcrumbConfig = Readonly<{
    title: Translation
    isTitleSingular: boolean
    path: string
    parents: BreadcrumbParentConfig[]
}>

const getParents = (parent: NonUndefined<ReturnType<typeof getRouteConfig>['parent']>): BreadcrumbParentConfig[] => {
    if ('title' in parent) {
        /**
         * Is the title, there won't be other parents
         */
        return [{ title: parent.title, isTitleSingular: Boolean(parent.isTitleSingular), id: null, path: null }]
    }

    const firstParent = getRouteConfig(parent.path)

    const parents: BreadcrumbParentConfig[] = [
        {
            title: firstParent.title,
            breadcrumbTitle: parent.breadcrumbTitle ?? null,
            isTitleSingular: 'isTitleSingular' in firstParent && firstParent.isTitleSingular,
            id: parent.path,
            path: firstParent.path,
        },
    ]

    if (firstParent.parent) {
        /**
         * Recursive parent retrieval
         */
        parents.push(...getParents(firstParent.parent))
    }

    return parents
}

const breadcrumbs = [
    ...Object.values(AbsoluteRouteOnlyPath),
    ...Object.values(AbsoluteRoutePathWithId),
    ...Object.values(AbsoluteRoutePathWithServiceId),
    ...Object.values(AbsoluteRoutePathWithServiceAndResourceId),
].reduce<Record<AbsoluteRoutePath, BreadcrumbConfig>>((r, routePath) => {
    const route = getRouteConfig(routePath)
    const breadcrumb: BreadcrumbConfig = {
        title: route.title,
        isTitleSingular: 'isTitleSingular' in route && route.isTitleSingular,
        path: route.path,
        parents: route.parent ? getParents(route.parent) : [],
    }

    return { ...r, [routePath]: breadcrumb }
}, {} as Record<AbsoluteRoutePath, BreadcrumbConfig>)

/**
 * Retrieve the breadcrumbs config by the given route
 * They are completely generated by the routes
 */
export const getBreadcrumbConfig = (path: AbsoluteRoutePath): BreadcrumbConfig => breadcrumbs[path]
