import React, { type FC } from 'react'
import { ThemeProvider, useTheme } from '@mui/material'
import { type FormProps, withTheme } from '@rjsf/core'
import { Theme5, Widgets } from '@rjsf/material-ui'

const InternalForm = withTheme({
    ...Theme5,
    showErrorList: false,
    liveValidate: true,
    widgets: {
        ...Widgets,
        /** Hides the submit button (and only the submit button) */
        SubmitButton: () => null,
    },
})

/**
 * The standard form (from '@rjsf/material-ui') comes with UI choices that do not match with the connectware
 * This components re-aligns it with the least amount of code to maintain
 */
export const Form = <T,>(props: FormProps<T>): ReturnType<FC> => {
    const theme = useTheme()

    return (
        <ThemeProvider
            theme={{
                ...theme,
                components: {
                    /** Forces text fields to follow standard styling */
                    MuiTextField: { defaultProps: { variant: 'standard' } },
                },
            }}
        >
            <InternalForm {...props} />
        </ThemeProvider>
    )
}
