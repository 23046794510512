import React, { type FC, useCallback } from 'react'

import { entries } from '../../../utils'
import { selectUsersManagementSelectedTab, type Translation, type UsersManagement } from '../../../domain'

import { useAppState, useAppUsecase } from '../State'

import { permissionConfig } from './Permissions'
import { roleConfig } from './Roles'
import { userConfig } from './Users'
import { Tab, Tabs } from '../Tabs'
import { SearchField } from './Filter'

const tabs: Record<UsersManagement['selected'], Readonly<{ title: Translation, table: FC }>> = {
    users: userConfig,
    roles: roleConfig,
    permissions: permissionConfig,
}

export const TabsContent: FC = () => {
    const usecase = useAppUsecase('filterUserManagementUsecase')
    const selected = useAppState(selectUsersManagementSelectedTab)

    const onTabChange = useCallback((value: typeof selected) => {
        usecase.selectTab(value)
    }, [])

    return (
        <Tabs value={selected} onChange={onTabChange}>
            {entries(tabs).map(([id, config], k) => {
                const { title, table: Content } = config

                return (
                    <Tab key={k} title={title} value={id}>
                        <div data-testid="management-table">
                            <SearchField />
                            <Content />
                        </div>
                    </Tab>
                )
            })}
        </Tabs>
    )
}
