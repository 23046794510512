import { ChangeEventListener } from '../../../../../utils'
import { ConnectwareError, ConnectwareErrorType } from '../../../../../domain'

import type { SubscriptionsTypes } from '../../../../../application'

import type { ManagedVrpcRemote, VrpcRemoteManager } from '../../../utils'

import { VrpcSubscription } from './Base'

export class CountSubscription<T extends keyof SubscriptionsTypes> extends VrpcSubscription<T> {
    private readonly changeListeners = new ChangeEventListener<number>(0)

    constructor (eventName: T, remote: VrpcRemoteManager) {
        super(eventName, remote)

        if (!this.instanceMapper || this.remoteMapper) {
            throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Unexpected internal subscription mapper')
        }
    }

    protected onRelevantNewInstances (_: ManagedVrpcRemote, added: string[]): void {
        this.changeListeners.change(this.changeListeners.value + added.length)
    }

    protected onRelevantGoneInstances (gone: string[]): void {
        this.changeListeners.change(this.changeListeners.value - gone.length)
    }

    protected onConnected (remote: ManagedVrpcRemote): Promise<void> | void {
        this.changeListeners.change(this.getInstanceConfigurations(remote).length)
    }

    protected onDropped? (): void

    onChange (listener: (count: number) => void): this {
        this.changeListeners.onChange(([, newCount]) => listener(newCount))
        return this
    }
}
