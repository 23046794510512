import React, { type FC, useState } from 'react'
import { Box, Button, CircularProgress, Input, type SxProps, type Theme, ToggleButton, toggleButtonClasses, ToggleButtonGroup, Typography } from '@mui/material'
import { Build, DataUsage, PermDataSetting, Tag } from '@mui/icons-material'

import { ConnectwareError, CybusPermissionContext, type EditableCybusPermission, Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'
import { useAsyncCallback } from '../../Callback'
import { ErrorMessage } from '../../ErrorMessage'

import { useContextLabel, useContextResourceTranslation } from './Hooks'
import { OperationsList } from './Operations'

const rootStyle: SxProps<Theme> = { '& > *': { mb: 1 } }
const labelWrapperStyle: SxProps<Theme> = { display: 'flex', '& > *': { my: 'auto', mx: 0 } }
const labelIconStyle: SxProps<Theme> = { color: 'secondary.main', mr: 1 }
const contextButtonStyle: SxProps<Theme> = { color: 'secondary.main' }
const contextButtonsStyle: SxProps<Theme> = { width: '100%', height: 30, '& > *': { flexGrow: 1 } }
const contextSelectedStyle: SxProps<Theme> = { [`&.${toggleButtonClasses.selected}`]: { color: 'secondary.dark' } }
const operationsStyle: SxProps<Theme> = { display: 'flex', width: '100%', justifyContent: 'space-around' }
const confirmationButtonStyle: SxProps<Theme> = { mb: 0 }

export const CreationForm: FC<{
    disabled: boolean
    context: CybusPermissionContext
    onAdd: (permission: EditableCybusPermission) => Promise<void>
}> = ({ disabled, context, onAdd }) => {
    const translateContext = useContextLabel()
    const translateContextResource = useContextResourceTranslation()

    const [permission, setPermission] = useState<EditableCybusPermission>(() => ({ id: null, context, resource: '', read: true, write: false }))

    const [add, loading, result] = useAsyncCallback(() => onAdd(permission), [onAdd, permission])

    return (
        <Box data-testid="permission-creation-form" sx={rootStyle}>
            <Typography variant="subtitle2" align="center">
                <FormattedTranslation id={Translation.ADD_PERMISSION} />
            </Typography>
            <Box sx={labelWrapperStyle}>
                <Build color="secondary" sx={labelIconStyle} />
                <ToggleButtonGroup sx={contextButtonsStyle} exclusive size="small">
                    {Object.values(CybusPermissionContext).map((context) => {
                        const selected = permission.context === context
                        return (
                            <ToggleButton
                                key={context}
                                sx={[contextButtonStyle, selected && contextSelectedStyle]}
                                data-testid={`permission-context-${context.toLowerCase()}`}
                                color="secondary"
                                size="small"
                                value={context}
                                selected={selected}
                                onClick={() => setPermission((p) => ({ ...p, context }))}
                            >
                                {translateContext(context)}
                            </ToggleButton>
                        )
                    })}
                </ToggleButtonGroup>
            </Box>
            <Box sx={labelWrapperStyle}>
                {permission.context === CybusPermissionContext.MQTT ? <Tag sx={labelIconStyle} /> : <DataUsage sx={labelIconStyle} />}
                <Input
                    data-testid="permission-resource"
                    placeholder={translateContextResource(permission.context)}
                    fullWidth
                    value={permission.resource}
                    onChange={({ target: { value } }) => setPermission((p) => ({ ...p, resource: value.trim() }))}
                />
            </Box>
            <Box sx={labelWrapperStyle}>
                <PermDataSetting sx={labelIconStyle} />
                <Box sx={operationsStyle}>
                    <OperationsList
                        read={permission.read}
                        write={permission.write}
                        onChange={(operations) => setPermission((p) => ({ ...p, ...operations }))}
                    />
                </Box>
            </Box>

            {!loading && ConnectwareError.is(result) && (
                <ErrorMessage data-testid="permission-creation-error" error={result} titleVariant="body2" customTitle={(e) => e.message} />
            )}

            <Button
                data-testid="permission-creation-confirmation"
                fullWidth
                color="secondary"
                variant="contained"
                size="small"
                disabled={disabled}
                onClick={add}
                sx={confirmationButtonStyle}
            >
                {loading ? <CircularProgress size="1.375rem" /> : <FormattedTranslation id={Translation.ADD} />}
            </Button>
        </Box>
    )
}
