import React, { type FC, useEffect } from 'react'

import { Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'
import { useAppUsecase } from '../../State'
import { DetailsHeader } from '../../common'
import { UserCreationModal, UserEditModal } from '../Users'
import { RoleCreationModal, RoleEditModal } from '../Roles'
import { PermissionViewModal } from '../Permissions'

import { TabsContent } from '../TabsContent'
import { Filter } from '../Filter'

export const Management: FC = () => {
    const loadUsecase = useAppUsecase('loadUserManagementDataUsecase')

    useEffect(() => loadUsecase.invoke(), [loadUsecase])

    return (
        <>
            <DetailsHeader
                title={{ title: <FormattedTranslation id={Translation.USERS_AND_ROLES} />, actions: <Filter /> }}
                section={{ title: Translation.USERS_AND_ROLES_DOCUMENTATION_TITLE, body: Translation.USERS_AND_ROLES_DOCUMENTATION_BODY }}
            />

            <TabsContent />

            <RoleEditModal />
            <RoleCreationModal />
            <UserEditModal />
            <UserCreationModal />
            <PermissionViewModal />
        </>
    )
}
