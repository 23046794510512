import { Stack } from '@mui/material'
import React, { type FC } from 'react'

import { LeftContent } from '../LeftContent'
import { NavigationContext } from './Context'
import { NavigationMenu } from './SideMenu'

export const AppNavigation: FC = ({ children }) => (
    <NavigationContext>
        <Stack direction="row">
            <LeftContent mode="dense" redirect>
                <NavigationMenu />
            </LeftContent>
            {children}
        </Stack>
    </NavigationContext>
)
