import { Box, type SxProps, type Theme } from '@mui/material'
import React, { type FC } from 'react'

import { CybusLogSource, Translation } from '../../../domain'
import { DetailsHeader } from '../common'
import Logs from '../Logs'
import { FormattedTranslation, useTranslator } from '../Internationalization'

const logsWrapperStyle: SxProps<Theme> = { pt: 1 }

export const ServiceLogs: FC = () => {
    const translator = useTranslator()
    return (
        <Box>
            <DetailsHeader
                title={{ title: <FormattedTranslation id={Translation.SERVICE_LOGS} /> }}
                section={{ title: Translation.SERVICE_LOGS_DOCUMENTATION_TITLE, body: Translation.SERVICE_LOGS_DOCUMENTATION_BODY }}
            />
            <Box sx={logsWrapperStyle}>
                <Logs
                    type={CybusLogSource.SERVICE}
                    lines="all"
                    showDownloadRawLogs
                    showServiceId
                    downloadSuffix={translator.formatTranslation(Translation.SERVICE_LOGS)}
                />
            </Box>
        </Box>
    )
}
