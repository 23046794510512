import React, { type FC } from 'react'

import { Alert, Link, Snackbar, type SxProps, type Theme, Typography } from '@mui/material'

import { type AppVersions, selectDifferingVersions, Translation } from '../../../domain'

import { useAppState, useAppUsecases } from '../State'
import { createFormatter, FormattedTranslation } from '../Internationalization'
import { useIntervalEffect } from '../Interval'

const whiteButtonstyle: SxProps<Theme> = { color: 'inherit', cursor: 'pointer' }

export const Notifier: FC = () => {
    const differingVersions = useAppState(selectDifferingVersions, (v: AppVersions | null) => `${v?.ui || ''}_${v?.connectware || ''}`)

    const { fixVersionUsecase, versionUsecase } = useAppUsecases()

    /**
     * Verify the version from the backend every couple of seconds
     */
    useIntervalEffect(() => void versionUsecase.loadVersions(), 20_000, [versionUsecase])

    if (!differingVersions) {
        return null
    }

    return (
        <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert severity="error" variant="filled">
                <Typography variant="h6">
                    <FormattedTranslation id={Translation.VERSION_MISMATCH_TITLE} />
                </Typography>
                <Typography variant="body2">
                    <FormattedTranslation
                        id={Translation.VERSION_MISMATCH_BODY}
                        values={{
                            backend: differingVersions.connectware,
                            frontend: differingVersions.ui,
                            br: <br />,
                            link: createFormatter(Link, { sx: whiteButtonstyle, onClick: () => fixVersionUsecase.invoke() }),
                        }}
                    />
                </Typography>
            </Alert>
        </Snackbar>
    )
}
