import type { PickByValueExact } from 'utility-types'

import { type ConnectwareError, type StatusType, TopicType } from '../../../domain'

import type { SubscriptionsTypes } from '../..'
import { LogoutUsecase } from '../Authentication'
import { SubscriptionToSingleBuilder } from '../Resources'

import { ExplorerUsecase } from './Explorer'

const eventNames: Record<TopicType.ENDPOINT | TopicType.MAPPING | TopicType.NODE, keyof PickByValueExact<SubscriptionsTypes, StatusType>> = {
    [TopicType.ENDPOINT]: 'endpointStatus',
    [TopicType.MAPPING]: 'mappingStatus',
    [TopicType.NODE]: 'nodeStatus',
}

export class ExplorerResourceStatusUsecase extends ExplorerUsecase {
    subscribe (source: keyof typeof eventNames, id: string, handler: (e: StatusType | ConnectwareError) => void): VoidFunction | void {
        try {
            return new SubscriptionToSingleBuilder(eventNames[source], this.subscriptionsService, this.getUsecase(LogoutUsecase))
                .onChange((status) => handler(status))
                .withId(id)
                .subscribe()
        } catch (e: unknown) {
            /**
             * User does not have the permissions to use `subscriptionsService`
             * So just give up with the error on the state
             */
            handler(e as ConnectwareError)
        }
    }
}
