import {
    ConnectwareError,
    ConnectwareErrorType,
    isMfaRequiredOnLoginForm,
    type LoginForm,
    MfaRequiredError,
    MfaSetupRequiredError,
    selectLoginForm,
    selectLoginFormAuthenticationRequest,
} from '../../../domain'

import { initialOtp, initialState } from '../..'
import { EnforceMfaUsageUsecase } from '../Mfa'
import { LoginUsecase } from './Login'

export class LoginFormUsecase extends LoginUsecase {
    /**
     * Updates form to be used for login
     */
    updateLoginForm (update: Partial<LoginForm>): void {
        const currentForm = selectLoginForm(this.getState())

        if ('username' in update && isMfaRequiredOnLoginForm(currentForm)) {
            /** User decided to change their username again, and no longer is doing */
            update = { ...selectLoginForm(initialState), username: update.username, password: currentForm.password }
        }

        this.setState({ loginForm: { ...currentForm, ...update }, authentication: null })
    }

    toggleMfaMethod (): void {
        const { backup, otp } = selectLoginForm(this.getState())
        this.updateLoginForm({ backup: typeof backup === 'string' ? null : '', otp: Array.isArray(otp) ? null : initialOtp })
    }

    cancelMfa (): void {
        const { backup, otp, otpSecret } = selectLoginForm(initialState)
        this.updateLoginForm({ backup, otp, otpSecret })
    }

    /**
     * Attempts to login with information on loginForm
     * @returns if the user was able to be logged in or not
     */
    async login (): Promise<boolean> {
        const request = selectLoginFormAuthenticationRequest(this.getState())

        if (!request) {
            throw new ConnectwareError(ConnectwareErrorType.STATE, 'Could not log-in')
        }

        this.setState({ authentication: null })

        try {
            /**
             * Retrieve response from the service
             */
            this.updateAuthentication({ ...(await this.authenticationService.authenticate(request)), username: request.username })
            return true
        } catch (e: unknown) {
            if (MfaSetupRequiredError.is(e)) {
                /** Mfa is required, drop user's password and then load mfa */
                const { password: initialPassword } = selectLoginForm(initialState)
                this.updateLoginForm({ password: initialPassword })
                await this.getUsecase(EnforceMfaUsageUsecase).load(e)
            } else if (MfaRequiredError.is(e)) {
                /** User is still not logged in, needs to provide otp */
                this.updateLoginForm({ otpSecret: e.secret, otp: initialOtp })
            } else {
                this.updateAuthentication(e as ConnectwareError)
            }

            return false
        }
    }
}
