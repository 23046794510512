import React, { type FC, useState } from 'react'
import { Box, Collapse, Link, type SxProps, type Theme, Typography } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

import { Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'
import { useFileContent, useManaged } from './Hooks'

const wrapperEntryStyle: SxProps<Theme> = { ml: 3 }
const fileTitleStyle: SxProps<Theme> = { display: 'flex', justifyContent: 'space-between' }
const collapseStyle: SxProps<Theme> = { width: '100%' }
const codeStyle: SxProps<Theme> = {
    maxHeight: 'calc(100vh - 400px)',
    overflowY: 'scroll',
    p: 2,
    typography: 'body2',
    whiteSpace: 'pre-wrap',
    lineHeight: 1.25,
    backgroundColor: 'grey.100',
    borderRadius: '6px',
    overflowWrap: 'break-word',
    fontFamily: 'monospace',
}

const Display: FC = ({ children }) => {
    const [open, setOpen] = useState(false)

    return (
        <Box sx={wrapperEntryStyle}>
            <Box id="file-content-display" sx={fileTitleStyle}>
                <Typography variant="subtitle2" align="left">
                    <FormattedTranslation id={Translation.SERVICE_COMMISSIONING_FILE_LABEL} />
                </Typography>
                <Link onClick={() => setOpen(!open)}>{open ? <ExpandLess /> : <ExpandMore />}</Link>
            </Box>
            <Collapse sx={collapseStyle} in={open} timeout="auto">
                <Box component="pre" sx={codeStyle}>
                    {children}
                </Box>
            </Collapse>
        </Box>
    )
}

export const FileLabel: FC = () => {
    const file = useFileContent()
    const managed = useManaged()

    /**
     * If there is no file to render,
     * Or the service is managed
     * Then display nothing
     */
    return file && managed === null ? <Display>{file}</Display> : null
}
