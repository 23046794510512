import React, { type FC } from 'react'

import { Box, type SxProps, type Theme } from '@mui/material'

import { type CybusPubSub, CybusPubSubType, selectMappingEntry, Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'
import { PermissionedLink, RelativeRoutePathWithId } from '../../routing'
import { useAppState } from '../../State'
import { BufferDisplay, Delay, TopicsSubscriptionErrorsPopover } from '../../common'

const wrapperStyle: SxProps<Theme> = { display: 'flex', '& > *': { my: 'auto', ml: 1 } }
const labelStyle: SxProps<Theme> = { ml: 0, mr: 'auto' }

const LatestValue: FC<Pick<CybusPubSub, 'id'>> = ({ id }) => {
    const event = useAppState((s) => selectMappingEntry(s, id), [id])

    if (event === null) {
        return <FormattedTranslation id={Translation.NO_MESSAGES} />
    }

    return (
        <Delay delay={300}>
            <BufferDisplay buffer={event.payload} types={event.possibleTypes} minimized />
        </Delay>
    )
}

export const PubSub: FC<Readonly<{ pubSub: CybusPubSub, enabled: boolean }>> = ({ pubSub, enabled }) => (
    <Box sx={wrapperStyle}>
        <Box sx={labelStyle}>
            {pubSub.type === CybusPubSubType.ENDPOINT ? (
                <PermissionedLink path={RelativeRoutePathWithId.ENDPOINT} id={pubSub.id}>
                    {pubSub.id}
                </PermissionedLink>
            ) : (
                pubSub.id
            )}
        </Box>
        <Box>{enabled && pubSub.externalBroker === null && <LatestValue id={pubSub.id} />}</Box>
        {pubSub.subscriptionErrors.size > 0 && <TopicsSubscriptionErrorsPopover topics={pubSub.topics} errors={pubSub.subscriptionErrors} />}
    </Box>
)
