import React, { type FC } from 'react'
import { Box, Stack } from '@mui/material'

import { Translation } from '../../../domain'

import { RouteTabs } from '../Tabs'
import { AbsoluteRouteOnlyPath } from '../routing'

const tabs = {
    [AbsoluteRouteOnlyPath.LOGIN_SETTINGS]: null,
} as const

export const SettingsTabs: FC = ({ children: tab, ...props }) => (
    <RouteTabs
        {...props}
        title={Translation.SETTINGS}
        tabs={tabs}
        orientation="vertical"
        tabsWrapper={({ children: tabs, ...props }) => (
            <Stack {...props} gap={3} direction="row">
                {tabs}
                <Box data-testid="settings-tab-content">{tab}</Box>
            </Stack>
        )}
    />
)
