import { type BufferType, ConnectwareError, ConnectwareErrorType, selectLoadedExplorer } from '../../../domain'

import { ExplorerUsecase } from './Explorer'

export class ExplorerTopicsFormattingUsecase extends ExplorerUsecase {
    selectLatestValueType (topic: string, selectedType: BufferType): void {
        const { latestValues } = selectLoadedExplorer(this.getState())
        const { [topic]: latestValue } = latestValues

        if (!latestValue || latestValue.payload === null) {
            throw new ConnectwareError(ConnectwareErrorType.STATE, 'Latest value not found', { topic })
        }

        this.setExplorer({ latestValues: { ...latestValues, [topic]: { ...latestValue, selectedType } } })
    }
}
