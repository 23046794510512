import { entries, type PartialReadonlyRecord } from '../../utils'
import type { Capability, ConnectwareError, ResourceType } from '../../domain'
import type {
    ConnectwareResourcesService,
    CountSubscriptionsTypes,
    ListSubscriptionsTypes,
    PageSubscription,
    PageSubscriptionsTypes,
    SingleSubscriptionsTypes,
    SubscriptionEventHandler,
    SubscriptionFilterArgs,
    SubscriptionListEventHandler,
    SubscriptionPageOptions,
    SubscriptionsService,
    SubscriptionsTypes,
} from '../../application'

/**
 * This class helps the codebase to deal with partially implemented/usable services
 */
export abstract class MixedinAdapter<Service, Key extends string> {
    constructor (
        private readonly override: PartialReadonlyRecord<Key, Service>,
        private readonly selectCapabilities: () => Capability[],
        private readonly byCapability: PartialReadonlyRecord<Capability, Service>,
        private readonly fallback: Service
    ) {}

    protected getService (type: Key): Service {
        const override = this.override[type]

        if (override) {
            /** Always override */
            return override
        }

        const capabilities = this.selectCapabilities()
        const byCapability = entries(this.byCapability).find(([capability]) => capabilities.includes(capability))?.[1]
        if (byCapability) {
            /** Found one that can be used by capability */
            return byCapability
        }

        return this.fallback
    }
}

export class MixedConnectwareResourcesService extends MixedinAdapter<ConnectwareResourcesService, ResourceType> implements ConnectwareResourcesService {
    delete (type: ResourceType, ids: string[]): Promise<void> {
        return this.getService(type).delete(type, ids)
    }

    disable (type: ResourceType, ids: string[]): Promise<void> {
        return this.getService(type).disable(type, ids)
    }

    enable (type: ResourceType, ids: string[]): Promise<void> {
        return this.getService(type).enable(type, ids)
    }

    reenable (type: ResourceType, ids: string[]): Promise<void> {
        return this.getService(type).reenable(type, ids)
    }
}

export class MixedSubscriptionsService extends MixedinAdapter<SubscriptionsService, keyof SubscriptionsTypes> implements SubscriptionsService {
    subscribeToPage<T extends keyof PageSubscriptionsTypes> (
        eventName: T,
        options: SubscriptionFilterArgs & SubscriptionPageOptions<T>
    ): Promise<PageSubscription<T>> {
        return this.getService(eventName).subscribeToPage(eventName, options)
    }

    subscribeToAll<T extends keyof ListSubscriptionsTypes> (
        eventName: T,
        filter: SubscriptionFilterArgs,
        listener: SubscriptionListEventHandler<T>
    ): Promise<VoidFunction> {
        return this.getService(eventName).subscribeToAll(eventName, filter, listener)
    }

    subscribeToOne<T extends keyof SingleSubscriptionsTypes> (eventName: T, id: string, listener: SubscriptionEventHandler<T>): Promise<VoidFunction> {
        return this.getService(eventName).subscribeToOne(eventName, id, listener)
    }

    subscribeToCount<T extends keyof CountSubscriptionsTypes> (eventName: T, listener: (count: number | ConnectwareError) => void): Promise<VoidFunction> {
        return this.getService(eventName).subscribeToCount(eventName, listener)
    }
}
