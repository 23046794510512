import React, { type FC } from 'react'

import {
    Capability,
    type CybusDetailedCoreContainer,
    CybusLogSource,
    ResourceType,
    selectCoreContainer,
    selectRestartableLoadedDetailedCoreContainer,
    selectStartableLoadedDetailedCoreContainer,
    selectStoppableLoadedDetailedCoreContainer,
    Translation,
} from '../../../domain'

import { ResourceDetails } from '../Resources'

import { RestartModal, StartModal, StopModal } from './Bulk'
import { Content, HeaderActions, HeaderDetails } from './Details'

const Modals: FC = () => (
    <>
        <StopModal
            dimension={ResourceType.CORE_CONTAINER}
            usecase="bulkStopCoreContainersUsecase"
            containersSelector={selectStoppableLoadedDetailedCoreContainer}
        />
        <StartModal
            dimension={ResourceType.CORE_CONTAINER}
            usecase="bulkStartCoreContainersUsecase"
            containersSelector={selectStartableLoadedDetailedCoreContainer}
        />
        <RestartModal
            dimension={ResourceType.CORE_CONTAINER}
            usecase="bulkRestartCoreContainersUsecase"
            containersSelector={selectRestartableLoadedDetailedCoreContainer}
        />
    </>
)

export const Details: FC = () => (
    <ResourceDetails<CybusDetailedCoreContainer>
        subscriptionUsecase="manageCoreContainerUsecase"
        resourceSelector={selectCoreContainer}
        notFoundTitle={Translation.CONTAINER_NOT_FOUND}
        modals={Modals}
        headerDetails={(props) => <HeaderDetails linkVolumes={false} {...props} />}
        headerResourceName={Translation.CONTAINER}
        headerName="name"
        headerActions={(props) => (
            <HeaderActions
                restartUsecaseName="bulkRestartCoreContainersUsecase"
                startUsecaseName="bulkStartCoreContainersUsecase"
                stopUsecaseName="bulkStopCoreContainersUsecase"
                managementCapability={Capability.CORE_CONTAINERS_MANAGE}
                {...props}
            />
        )}
        infoTitle={Translation.CORE_CONTAINER_DETAILS_DOCUMENTATION_TITLE}
        infoBody={Translation.CORE_CONTAINER_DETAILS_DOCUMENTATION_BODY}
        content={(props) => <Content logType={CybusLogSource.CORE_CONTAINER} {...props} />}
    />
)
