import { createLocation } from 'history'

import type {
    AbsoluteRoutePathWithId,
    AbsoluteRoutePathWithServiceAndResourceId,
    AbsoluteRoutePathWithServiceId,
    RelativeRoutePathWithId,
    RouteUpdateArgs,
} from '../Domain'
import { getAbsoluteRoute, getContext } from '../Config'
import { createRoutingHook } from './Hook'
import { RoutingHook } from './ExternalRouteHook'

class InternalRoutingHook extends RoutingHook {
    getAbsoluteRoute (
        relativePath: RelativeRoutePathWithId
    ): AbsoluteRoutePathWithServiceId | AbsoluteRoutePathWithId | AbsoluteRoutePathWithServiceAndResourceId {
        return getAbsoluteRoute(relativePath, getContext(this.state.path))
    }

    createHref (...args: RouteUpdateArgs): string {
        return this.history.createHref(createLocation(this.generateRedirection(...args)))
    }
}

export const useInternalRoutingHook = createRoutingHook('useInternalRoutingHook', InternalRoutingHook)
