import React, { type FC } from 'react'
import { Box, type SxProps, type Theme } from '@mui/material'

import background from './Background.png'

const style: SxProps<Theme> = {
    height: '100%',
    width: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${background})`,
}

export const Background: FC = () => <Box data-testid="working-background" sx={style} />
