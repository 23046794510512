import { createInterval, Droppable } from '../../../utils'

import { type ConnectwareError, type Information, selectInfo } from '../../../domain'

import { Usecase } from '../Usecase'

export class InformationUsecase extends Usecase {
    private setInfo (update: Partial<Information> = {}): void {
        let info = selectInfo(this.getState())

        if (!info) {
            info = {
                version: this.systemService.getUIVersion(),
                servicesCount: null,
                connectivity: null,
            }
        }

        this.setState({ info: { ...info, ...update } })
    }

    private refreshConnectivity (): void {
        this.systemService
            .fetchConnectivity()
            .then((connectivity) => this.setInfo({ connectivity }))
            .catch((connectivity: ConnectwareError) => this.setInfo({ connectivity }))
    }

    /**
     * Loads the connectivity & services count information
     *
     * 2 Different async requests are made internally independent of each other
     */
    loadInformation (): VoidFunction {
        const droppable = new Droppable()

        /**
         * Just initializes the info
         */
        this.setInfo()

        const refreshConnectivity: VoidFunction = () => this.refreshConnectivity()

        /**
         * Fetches initial data
         * and
         * Sets up a subscription of the service count
         */
        refreshConnectivity()

        this.subscriptionsService
            .subscribeToCount('services', (servicesCount) => this.setInfo({ servicesCount }))
            .then((unsub) => droppable.onDrop(unsub))
            .catch((servicesCount: ConnectwareError) => this.setInfo({ servicesCount }))

        const refreshRate = this.configurationService.getInfoPageRefreshRate()

        /** Updates data */
        droppable.onDrop(createInterval(refreshConnectivity, refreshRate))

        return () => droppable.drop()
    }
}
