import React, { type FC, useEffect } from 'react'

import { selectAuthentication, selectDoesRequiresMfa, selectIsAuthenticated, selectIsLicenseValid, SessionFromTokenNotFoundError } from '../../../domain'

import { useAppState, useAppUsecase } from '../State'

import { LicenseModal } from '../Info'
import Login from '../Login'
import SessionToken from '../Token'
import { EnforceMfaForm } from '../MFA'

/**
 * There are two barriers of entry to using the application
 *
 * - User either had trouble connecting or has not attempted to do so
 * - Passed token has not yielded a successful authentication
 * - License is invalid or there was an error fetching it
 *
 * This components handles these barriers
 */
export const Credentials: FC = ({ children }) => {
    const authenticated = useAppState(selectIsAuthenticated)
    const requiresMfa = useAppState(selectDoesRequiresMfa)
    const hasSessionError = useAppState((s) => SessionFromTokenNotFoundError.is(selectAuthentication(s)))
    const isLicenseValid = useAppState(selectIsLicenseValid)

    const usecase = useAppUsecase('loadLicenseUsecase')
    useEffect(() => usecase.loadLicense(), [usecase, authenticated])

    if (hasSessionError) return <SessionToken />
    if (requiresMfa) return <EnforceMfaForm />
    if (!authenticated) return <Login />
    if (!isLicenseValid) return <LicenseModal />

    return <>{children}</>
}
