import { type MfaSettings, MfaSettingsState, type MfaUpdateCredentials } from '../../../domain'
import { initialOtp } from '../..'
import { ChangeEnabledMfaUsecase } from './ChangeEnabled'

export class DisableMfaUsecase extends ChangeEnabledMfaUsecase {
    protected readonly initialSettings = { state: MfaSettingsState.DISABLING, error: null, otp: initialOtp, backupCode: null } as const

    protected async execute (input: MfaUpdateCredentials): Promise<MfaSettings> {
        await this.mfaService.disable(input)
        return { state: MfaSettingsState.DISABLED }
    }
}
