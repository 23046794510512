import React, { type FC } from 'react'

import { Capability, type CybusDetailedConnection, StatusType, Translation } from '../../../../domain'

import { useAppUsecases } from '../../State'
import { DetailsActionButton } from '../../Details'

export const HeaderActions: FC<{ resource: CybusDetailedConnection }> = ({ resource: { id, status } }) => {
    const { bulkDeleteConnectionsUsecase, bulkConnectConnectionsUsecase, bulkDisconnectConnectionsUsecase } = useAppUsecases()

    const disabled = status !== StatusType.CONNECTED && status !== StatusType.DISCONNECTED
    const statusTranslation =
        status === StatusType.CONNECTED ? Translation.DISCONNECT : status === StatusType.CONNECTING ? Translation.CONNECTING : Translation.CONNECT

    const handleConnectDisconnect =
        status === StatusType.CONNECTED
            ? () => bulkDisconnectConnectionsUsecase.selectConnections([id])
            : () => bulkConnectConnectionsUsecase.selectConnections([id])

    return (
        <>
            <DetailsActionButton
                id="connection-action-button"
                disabled={disabled}
                translation={statusTranslation}
                capabilities={[Capability.CONNECTIONS_MANAGE]}
                onClick={handleConnectDisconnect}
            />

            <DetailsActionButton
                id="delete-connection-button"
                translation={Translation.DELETE}
                capabilities={[Capability.CONNECTIONS_MANAGE]}
                onClick={() => bulkDeleteConnectionsUsecase.selectConnections([id])}
            />
        </>
    )
}
