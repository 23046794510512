import type { CybusCoreContainer, CybusServiceContainer, SortableColumn } from '../../../domain'

import { ResourcePageSubscriptionUsecase, ResourceSingleSubscriptionUsecase } from '../Resources'

export class ManageServiceContainersUsecase extends ResourcePageSubscriptionUsecase<'serviceContainers'> {
    protected readonly pageName = 'serviceContainers'

    protected readonly pageAddress = 'serviceContainersPage'

    protected readonly initialSortColumn: SortableColumn<CybusServiceContainer> = 'service'
}

export class ManageServiceContainerUsecase extends ResourceSingleSubscriptionUsecase<'serviceContainer'> {
    protected readonly singularName = 'serviceContainer'

    protected readonly singleAddress = 'serviceContainer'
}

export class ManageCoreContainerUsecase extends ResourceSingleSubscriptionUsecase<'coreContainer'> {
    protected readonly singularName = 'coreContainer'

    protected readonly singleAddress = 'coreContainer'
}

export class ManageCoreContainersUsecase extends ResourcePageSubscriptionUsecase<'coreContainers'> {
    protected readonly pageName = 'coreContainers'

    protected readonly pageAddress = 'coreContainersPage'

    protected readonly initialSortColumn: SortableColumn<CybusCoreContainer> = 'name'
}
