import type { CybusDetailedEndpoint } from '../../../../../../domain'
import type { VrcpEntityHandler, VrpcInstanceMapper } from '..'
import type { EndpointProxy } from '../../../../proxies'

import { ENDPOINT_CLASSNAME_FILTER } from '../../../../constants'
import { listenToProxy, type UnsubFromInstanceFunction, type VrpcHandlerConfiguration } from '../Handler'
import { mapDetailedEndpoint } from '../../../../../Connectware'

class VrpcDetailedCEndpointProxyInstanceMapper implements VrpcInstanceMapper<EndpointProxy, CybusDetailedEndpoint> {
    async mapToDomain (proxy: EndpointProxy): Promise<CybusDetailedEndpoint> {
        return Promise.all([proxy.getParams(), proxy.getTopics()]).then(([param, topics]) => mapDetailedEndpoint(param, topics))
    }

    onChange (proxy: EndpointProxy, listener: () => void): Promise<UnsubFromInstanceFunction> {
        return listenToProxy(proxy, ['state'], listener)
    }
}

export class VrpcDetailedCEndpointProxyInstanceHandler implements VrcpEntityHandler<EndpointProxy, CybusDetailedEndpoint> {
    readonly instanceMapper = new VrpcDetailedCEndpointProxyInstanceMapper()

    readonly remoteMapper = null

    readonly configuration: VrpcHandlerConfiguration = { classNameFilter: ENDPOINT_CLASSNAME_FILTER, supportedFilters: [] }
}
