import type { CybusConnectionConfiguration } from '../../../../domain'
import type { CommissioningDataResourceProperties } from '../../proxies'
import { mapConnectionProperties } from '../Connection'

export const mapConnectionConfiguration = (
    name: string,
    properties: CommissioningDataResourceProperties<'Cybus::Connection'>
): CybusConnectionConfiguration => ({
    name,
    protocol: properties.protocol,
    ...mapConnectionProperties(properties),
})
