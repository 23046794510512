import { type AppState, Capability, type ConnectwareError, createIsAuthenticatedWithCapabilitiesSelector } from '../../../domain'

import { Usecase } from '..'

const canReadSystemInfo = createIsAuthenticatedWithCapabilitiesSelector(Capability.MINIMUM)

export class VersionUsecase extends Usecase {
    private async getVersions (): Promise<AppState['versions']> {
        if (!canReadSystemInfo(this.getState())) {
            return null
        }

        try {
            return await this.systemService.fetchVersions()
        } catch (e: unknown) {
            return e as ConnectwareError
        }
    }

    async loadVersions (): Promise<void> {
        const versions = await this.getVersions()
        const { versions: oldVersions } = this.getState()

        if (oldVersions !== versions) {
            this.setState({ versions })
        }
    }

    getUIVersion (): string {
        return this.systemService.getUIVersion()
    }
}
