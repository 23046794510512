import React, { type FC, useEffect, useState } from 'react'
import { Card, CardActions, Collapse, Grid, IconButton, Paper, type SxProps, type Theme, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { useSnackbar } from 'notistack'

import { deltaFromArray } from '../../../utils'

import { Capability, createIsAuthenticatedWithCapabilitiesSelector, selectDeviations, selectHasDeviations, Translation } from '../../../domain'

import { AbsoluteRoutePathWithServiceId, usePermissionedLink } from '../routing'
import { useAppState, useAppUsecase } from '../State'
import { FormattedTranslation, useTranslator } from '../Internationalization'
import { useShadowEffect } from '../Shadow'

const DEVIATIONS_KEY = 'deviations'

const rootStyle: SxProps<Theme> = { color: 'inherit', background: 'inherit' }
const actionsStyle: SxProps<Theme> = { p: 0 }
const linkStyle: SxProps<Theme> = { ml: 1, color: 'inherit' }
const expandStyle: SxProps<Theme> = {
    color: 'inherit',
    p: 2,
    transform: 'rotate(0deg)',
    transition: ({ transitions }) => transitions.create('transform', { duration: transitions.duration.shortest }),
}
const expandOpenStyle: SxProps<Theme> = { transform: 'rotate(180deg)' }
const itemStyle: SxProps<Theme> = { p: 1, background: 'secondary.main', mb: 0.25, '&:last-of-type': { mb: 0 } }
const newDeviationStyle: SxProps<Theme> = { background: 'secondary.main' }
const deviationListStyle: SxProps<Theme> = { flexDirection: 'column', flexWrap: 'nowrap', maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }
const innerContainerStyle: SxProps<Theme> = { justifyContent: 'space-between', gap: 2, alignItems: 'baseline' }

const useDeviationMessages = (): FC => {
    const PermissionedLink = usePermissionedLink()

    return () => {
        const deviations = useAppState(selectDeviations)

        const { enqueueSnackbar } = useSnackbar()
        const translator = useTranslator()
        const [expanded, setExpanded] = useState(false)
        // state to provide visual effect whenever a new deviation appears
        const [highlightNewDeviation, setHighlightNewDeviation] = useState(false)

        useShadowEffect((oldDeviations) => {
            const [resolved, , added] = deltaFromArray(oldDeviations, deviations)

            /**
             * show snackbar for resolved deviations
             */
            for (const resolvedId of resolved) {
                enqueueSnackbar(
                    <div data-testid="resolved-deviation">{translator.formatTranslation(Translation.DEVIATION_RESOLVED, { id: resolvedId })}</div>,
                    { key: resolvedId, variant: 'info' }
                )
            }

            if (added.length) {
                setHighlightNewDeviation(true)
            }
        }, deviations)

        const handleExpanded: VoidFunction = () => {
            if (highlightNewDeviation) {
                setHighlightNewDeviation(false)
            }
            setExpanded(!expanded)
        }

        return (
            <Card sx={rootStyle} elevation={0}>
                <CardActions
                    data-testid="deviation-card-actions"
                    className={highlightNewDeviation ? 'newDeviation' : ''}
                    sx={[actionsStyle, highlightNewDeviation && newDeviationStyle]}
                >
                    <Typography>
                        <FormattedTranslation id={Translation.DEVIATION_ENCOUNTERED} values={{ count: deviations.length }} />
                    </Typography>
                    <div>
                        <IconButton onClick={handleExpanded} sx={[expandStyle, expanded && expandOpenStyle]}>
                            <ExpandMore />
                        </IconButton>
                    </div>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Paper>
                        <Grid data-testid="deviations-list" container sx={deviationListStyle}>
                            {deviations.map((deviation) => (
                                <Grid item key={deviation} sx={itemStyle}>
                                    <Grid container sx={innerContainerStyle}>
                                        <Grid className="deviation-label" item>
                                            {deviation}
                                        </Grid>
                                        <Grid className="deviation-link" item>
                                            <PermissionedLink
                                                sx={linkStyle}
                                                path={AbsoluteRoutePathWithServiceId.SERVICE}
                                                id={deviation}
                                                wrapper={({ authorized, children }) => (authorized ? <>{children}</> : null)}
                                            >
                                                <FormattedTranslation id={Translation.MORE} />
                                            </PermissionedLink>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Paper>
                </Collapse>
            </Card>
        )
    }
}

const selectCanReadDeviations = createIsAuthenticatedWithCapabilitiesSelector(Capability.DEVIATIONS_READ)

export const Notifications: FC = () => {
    const canReadDeviations = useAppState(selectCanReadDeviations)
    /** Subscribe to deviations */
    const manageDeviations = useAppUsecase('manageDeviationsUsecase')

    useEffect(() => {
        if (!canReadDeviations) {
            return
        }

        return manageDeviations.subscribe()
    }, [manageDeviations, canReadDeviations])

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const DeviationMessages = useDeviationMessages()
    /** Track deviations and their changes */
    const showDeviations = useAppState(selectHasDeviations)

    useEffect(() => {
        if (showDeviations) {
            enqueueSnackbar(<DeviationMessages />, {
                key: DEVIATIONS_KEY,
                variant: 'warning',
                persist: true,
                anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
            })
        } else {
            closeSnackbar(DEVIATIONS_KEY)
        }
    }, [showDeviations])

    return <></>
}
