import React, { type FC } from 'react'
import { Box, type SxProps, type Theme } from '@mui/material'
import ReactJson, { type ReactJsonViewProps } from 'react-json-view'

type JsonProps = Pick<ReactJsonViewProps, 'src' | 'displayObjectSize' | 'collapsed'>

const style: SxProps<Theme> = { typography: 'body2', wordBreak: 'break-all' }

export const Json: FC<JsonProps> = ({ src, ...props }) => (
    <Box sx={style}>
        <ReactJson {...props} src={src} sortKeys name={false} displayDataTypes={false} />
    </Box>
)
