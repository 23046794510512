import React, { type FC } from 'react'
import { type SvgIconTypeMap, type SxProps, type Theme, Typography } from '@mui/material'
import { type SvgIconComponent } from '@mui/icons-material'

export type BaseOption = Readonly<{ label: string, icon?: SvgIconComponent }>
export type Props = Readonly<{ option: BaseOption, color?: SvgIconTypeMap['props']['color'] }>

const optionStyle: SxProps<Theme> = { mx: 'auto' }

export const Label: FC<Props> = ({ option: { label, icon }, color = 'primary' }) => {
    const Icon = icon

    return (
        <>
            {Icon && <Icon color={color} />}
            <Typography sx={optionStyle} align="center" variant="body2">
                {label}
            </Typography>
        </>
    )
}
