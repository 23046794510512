import React, { type FC } from 'react'
import { PauseCircleFilled, PlayCircleFilled } from '@mui/icons-material'

import {
    canContainerBeRestarted,
    canContainerBeStarted,
    canContainerBeStopped,
    Capability,
    createIsAuthenticatedWithCapabilitiesSelector,
    type CybusCoreContainer,
    ResourceType,
    selectCoreContainersPage,
    selectRestartableLoadedCoreContainers,
    selectStartableLoadedCoreContainers,
    selectStoppableLoadedCoreContainers,
    Translation,
} from '../../../domain'

import { AbsoluteRoutePathWithId } from '../routing'

import { RedirectingResourcesTable } from '../Resources'

import { RestartModal, StartModal, StopModal } from './Bulk'
import { useContainerTableColums } from './Columns'

const selectCanManageCoreContainers = createIsAuthenticatedWithCapabilitiesSelector(Capability.CORE_CONTAINERS_MANAGE)

export const Table: FC = () => {
    const columns = useContainerTableColums()

    return (
        <RedirectingResourcesTable<
            CybusCoreContainer,
            CybusCoreContainer,
            'bulkRestartCoreContainersUsecase' | 'bulkStartCoreContainersUsecase' | 'bulkStopCoreContainersUsecase'
        >
            subscriptionUsecase="manageCoreContainersUsecase"
            data={selectCoreContainersPage}
            hasManagementCapabilitiesSelector={selectCanManageCoreContainers}
            dataTableMapper={(containers) => containers}
            columns={columns}
            redirectOnRowclick={AbsoluteRoutePathWithId.RESOURCES_STATUS}
            onSelect={{
                DISABLE: { usecase: 'bulkStopCoreContainersUsecase', filter: canContainerBeStopped, icon: PauseCircleFilled, label: Translation.STOP },
                ENABLE: { usecase: 'bulkStartCoreContainersUsecase', filter: canContainerBeStarted, icon: PlayCircleFilled, label: Translation.START },
                RESTART: { usecase: 'bulkRestartCoreContainersUsecase', filter: canContainerBeRestarted },
                resource: 'id',
                usecaseMethod: 'selectContainers',
            }}
            title={Translation.STATUES_PAGE_TITLE}
            translations={{
                resourceName: Translation.STATUS,
                noResultsOrEmptyTable: Translation.NO_CONTAINERS_AVAILABLE,
                infoTitle: Translation.CORE_CONTAINERS_DOCUMENTATION_TITLE,
                infoBody: Translation.CORE_CONTAINERS_DOCUMENTATION_BODY,
            }}
        >
            <StopModal
                dimension={ResourceType.CORE_CONTAINER}
                usecase="bulkStopCoreContainersUsecase"
                containersSelector={selectStoppableLoadedCoreContainers}
            />
            <StartModal
                dimension={ResourceType.CORE_CONTAINER}
                usecase="bulkStartCoreContainersUsecase"
                containersSelector={selectStartableLoadedCoreContainers}
            />
            <RestartModal
                dimension={ResourceType.CORE_CONTAINER}
                usecase="bulkRestartCoreContainersUsecase"
                containersSelector={selectRestartableLoadedCoreContainers}
            />
        </RedirectingResourcesTable>
    )
}
