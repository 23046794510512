import { type CybusAgent, ResourceType } from '../../../domain'

import { BulkDeleteResourceUsecase } from '../Resources'

export class BulkDeleteAgentsUsecase extends BulkDeleteResourceUsecase {
    protected readonly type = ResourceType.AGENT

    selectAgents (names: CybusAgent['name'][] | null): void {
        this.select(names)
    }

    delete (): Promise<void> {
        return super.delete()
    }
}
