export const toggleSet = <T>(set: Set<T>, toggled: T): Set<T> => {
    if (set.has(toggled)) {
        set.delete(toggled)
    } else {
        set.add(toggled)
    }

    return set
}

export const toggleReadonlySet = <T>(set: ReadonlySet<T>, toggled: T): ReadonlySet<T> => toggleSet(new Set(set), toggled)
