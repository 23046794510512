import type { CybusLinkedService, SortableColumn } from '../../../domain'

import { ResourcePageSubscriptionUsecase } from '../Resources'

export class ManageServicesLinksUsecase extends ResourcePageSubscriptionUsecase<'servicesLinks'> {
    protected readonly pageName = 'servicesLinks'

    protected readonly pageAddress = 'servicesLinksPage'

    protected readonly initialSortColumn: SortableColumn<CybusLinkedService> = 'id'
}
