import type { CybusDetailedService } from '../../../../../../domain'

import type { SubscriptionsTypes } from '../../../../../../application'

import type { ServiceProxy } from '../../../../proxies'

import { BaseServiceMapper, BaseVrpcServiceProxyInstanceHandler } from './Base'
import { mapDetailedService } from '../../../../../Connectware'

class DetailedServiceMapper extends BaseServiceMapper<CybusDetailedService> {
    async mapToDomain (proxy: ServiceProxy): Promise<CybusDetailedService> {
        const [params, dependencies] = await Promise.all([proxy.getParams(), proxy.getDependencies()])
        return mapDetailedService(params, dependencies)
    }
}

export class VrpcDetailedServiceProxyInstanceHandler extends BaseVrpcServiceProxyInstanceHandler<SubscriptionsTypes['service']> {
    instanceMapper = new DetailedServiceMapper()
}
