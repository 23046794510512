export class Queue {
    private lastPromise: Promise<unknown> = Promise.resolve()

    private async awaitNext<T> (asyncCall: () => Promise<T>, resolve: (value: T | PromiseLike<T>) => void, reject: (reason: unknown) => void): Promise<void> {
        try {
            resolve(await asyncCall())
        } catch (e: unknown) {
            reject(e)
        }
    }

    private async awaitSafely (previous: Promise<unknown>): Promise<void> {
        try {
            await previous
        } catch {
            // It is ok to hide errors here, only the code coverage tool is going to judge us
        }
    }

    /**
     * Adds an async callback to the async queue
     * This callback will only be executed after the previous callbacks
     *
     * @returns the promise that you gave to it through its callback, no errors are held
     */
    push<T> (asyncCall: () => Promise<T>): Promise<T> {
        const previous = this.lastPromise

        /**
         * Replace previous with new one
         */
        const current = new Promise<T>((resolve, reject) => {
            /**
             * Await for previous to finish
             */
            void this.awaitSafely(previous).then(() => {
                /**
                 * Then execute new one
                 */
                void this.awaitNext<T>(asyncCall, resolve, reject)
            })
        })

        /**
         * Replace previous with current
         */
        this.lastPromise = current

        return current
    }
}
