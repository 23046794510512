import { type CybusService, StatusType } from '../../../../domain'
import { type CreatedCommissioningResourceProperties, type CybusServiceProxyInfo, mapToStatusType } from '../..'

export const mapBaseService = ({ id, name, version, state, isDeviated }: CybusServiceProxyInfo): Omit<CybusService, 'links'> => {
    const status = mapToStatusType(state)

    return {
        id,
        name,
        version: version || null,
        status,
        deviation: isDeviated ? StatusType.DEVIATED : status === StatusType.DISABLED ? StatusType.NOT_ENABLED : StatusType.NO_DEVIATION,
    }
}

export const mapServiceLinks = (links: CreatedCommissioningResourceProperties<'Cybus::Link'>[]): CybusService['links'] =>
    links.map(({ physicalId: { href, name } }) => ({ href, name }))

export const mapService = (info: CybusServiceProxyInfo, links: CreatedCommissioningResourceProperties<'Cybus::Link'>[]): CybusService => ({
    ...mapBaseService(info),
    links: mapServiceLinks(links),
})
