import type { Intersection } from 'utility-types'

import type { SubscriptionsTypes } from '../../../../../../application'

import type { MappingProxyParams } from '../../../../../Connectware'
import type { MappingProxy } from '../../../../proxies'
import { listenToProxy, type UnsubFromInstanceFunction, type VrcpEntityHandler, type VrpcHandlerConfiguration, type VrpcInstanceMapper } from '../Handler'

export type BaseMapping = Intersection<SubscriptionsTypes['mapping'], SubscriptionsTypes['mappings']>
export type ListenableEvent = Parameters<MappingProxy['on']>[0] & Parameters<MappingProxy['off']>[0]

export abstract class BaseMappingMapper<T extends keyof SubscriptionsTypes, FetchedInfo = void>
    implements VrpcInstanceMapper<MappingProxy, SubscriptionsTypes[T]> {
    protected get events (): ListenableEvent[] {
        return ['state']
    }

    /**
     * Async custom loading is done here
     */
    protected abstract fetchInformation (proxy: MappingProxy): Promise<FetchedInfo>

    /**
     * Custom mapping is done here
     */
    protected abstract mapMapping (params: MappingProxyParams, extraInformation: FetchedInfo): Omit<SubscriptionsTypes[T], keyof BaseMapping>

    async mapToDomain (proxy: MappingProxy): Promise<SubscriptionsTypes[T]> {
        /** Fetch all information at the same time */
        const [params, info] = await Promise.all([proxy.getParams(), this.fetchInformation(proxy)])

        return this.mapMapping(params, info) as SubscriptionsTypes[T]
    }

    onChange (proxy: MappingProxy, listener: () => void): Promise<UnsubFromInstanceFunction> {
        return listenToProxy(proxy, this.events, listener)
    }
}

export abstract class VrpcBaseMappingProxyInstanceHandler<T extends keyof SubscriptionsTypes>
    implements VrcpEntityHandler<MappingProxy, SubscriptionsTypes[T]> {
    abstract readonly instanceMapper: VrpcInstanceMapper<MappingProxy, SubscriptionsTypes[T]>

    abstract readonly configuration: VrpcHandlerConfiguration

    readonly remoteMapper = null
}
