import { areArrayEquals, isEnumOf, nameFunction } from '../../../utils'

import { type Capability, createIndexedCapabilities, selectAuthenticatedCapabilities } from '../../../domain'

import { useAppState } from '../State'

import { getRouteCapabilities, getRouteConfig } from './Config'
import { type AbsoluteRoutePath, type AbsoluteRoutePathWithId, RelativeRoutePathWithId } from './Domain'
import { useInternalRoutingHook } from './Provider'

/**
 * This file contains the external hooks
 * Meant to be used outside of the routing hooks folder
 */

/** Historically, the access to routing config would be done through hooks, this function mimics them */
const createFakeConfigHook = <N extends string, T>(
    name: N,
    mapper: (config: ReturnType<typeof getRouteConfig>) => T
): (() => (routePath: AbsoluteRoutePath) => T) =>
    nameFunction(
        () =>
            (routePath: AbsoluteRoutePath): T =>
                mapper(getRouteConfig(routePath)),
        name
    )

export const useRouteDescription = createFakeConfigHook('useRouteDescription', (config) => ('description' in config && config.description) || null)
export const useRouteTitle = createFakeConfigHook('useRouteTitle', (config) => ({
    title: config.title,
    isTitleSingular: 'isTitleSingular' in config && config.isTitleSingular,
}))

export const useHasCapabilities = (): ((requiredCapabilitiesAlternativesOrRoute: Capability[] | AbsoluteRoutePath) => boolean) => {
    const [hasCapabilities] = useAppState(
        (s) => [createIndexedCapabilities(s), selectAuthenticatedCapabilities(s) ?? []],
        ([, a]: [unknown, Capability[]], [, b]: [unknown, Capability[]]) => areArrayEquals(a, b, { sort: false })
    )
    return (alternativesOrRoute) => {
        const alternatives = Array.isArray(alternativesOrRoute)
            ? [alternativesOrRoute]
            : getRouteCapabilities(alternativesOrRoute).map((capabilities) => capabilities.required)
        return alternatives.some(hasCapabilities)
    }
}

export const useRouteWithIdRedirect = <I>(
    route: AbsoluteRoutePathWithId | RelativeRoutePathWithId,
    idMapper: (input: I) => string
): ((input: I) => void) | void => {
    const hasCapabilities = useHasCapabilities()
    const router = useInternalRoutingHook()

    const absoluteRoute = isEnumOf(RelativeRoutePathWithId, route) ? router.getAbsoluteRoute(route) : route

    return hasCapabilities(absoluteRoute) ? (i: I) => router.redirect(absoluteRoute, idMapper(i)) : undefined
}
