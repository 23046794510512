/**
 * Warning, it removes the props of the original object, not a copy
 * @param obj
 * @param keys
 */
export const removeKeys = <T, K extends keyof T>(obj: T, ...keys: K[]): Omit<T, K> => {
    for (const k of keys) {
        delete obj[k]
    }
    return obj
}

export const isObjectEmpty = <T extends Record<string, unknown>>(obj: T): boolean => Object.keys(obj).length === 0
