import React, { type FC } from 'react'

import {
    type ClientRegistryRequest,
    selectIsUsersManagementRegistryLocked,
    selectLoadedRegistryRequests,
    selectUsersManagementTransfering,
    Translation,
} from '../../../../domain'

import { FormattedDateTime, FormattedTranslation } from '../../Internationalization'
import { useAppState, useAppUsecase } from '../../State'
import { useAsyncCallback } from '../../Callback'
import { DetailsHeader, Table } from '../../common'

import { FormattedMethod } from '../Users'
import { LockButton } from './Button'

const NoRequests: FC = () => {
    const locked = useAppState(selectIsUsersManagementRegistryLocked)
    return <FormattedTranslation id={Translation.CLIENT_REGISTRY_NO_REQUESTS} values={{ locked }} />
}

export const RequestsTable: FC = () => {
    const locked = useAppState(selectIsUsersManagementRegistryLocked)
    const requests = useAppState(selectLoadedRegistryRequests)
    const isTransfering = useAppState(selectUsersManagementTransfering)

    const usecase = useAppUsecase('allowClientRegistryRequestUsecase')
    const [onRowClick] = useAsyncCallback((request: ClientRegistryRequest) => usecase.load(request))

    return (
        <>
            <DetailsHeader
                title={{ title: <FormattedTranslation id={Translation.CLIENT_REGISTRY} />, actions: <LockButton /> }}
                section={{ title: Translation.CLIENT_REGISTRY_DOCUMENTATION_TITLE, body: Translation.CLIENT_REGISTRY_DOCUMENTATION_BODY }}
            />
            <Table
                columns={{
                    username: { label: Translation.USERNAME },
                    description: { label: Translation.CONTEXT },
                    timestamp: { label: Translation.TIMESTAMP, customCellRender: (date) => <FormattedDateTime date={date} format="datetime" /> },
                    authenticationMethod: { label: Translation.CREDENTIALS, customCellRender: (method) => <FormattedMethod method={method} /> },
                }}
                initialPagination={{ selected: 15, options: [15] }}
                data={requests}
                initialSearch={!locked}
                onRowClick={isTransfering ? undefined : onRowClick}
                translations={{ noResultsOrEmptyTable: <NoRequests /> }}
            />
        </>
    )
}
