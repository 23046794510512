import React, { type FC } from 'react'

import {
    type CybusServer,
    selectDeletableLoadedServer,
    selectDisablableLoadedServer,
    selectEnabableLoadedServer,
    selectServer,
    Translation,
} from '../../../../domain'

import { useRouting } from '../../routing'

import { ResourceDetails } from '../../Resources'
import { Content } from './Content'
import { DeleteModal, DisableModal, EnableModal } from '../Bulk'
import { HeaderActions } from './HeaderActions'
import { HeaderDetails } from './HeaderDetails'

const Modals: FC = () => {
    const routing = useRouting()

    return (
        <>
            <EnableModal serversSelector={selectEnabableLoadedServer} />
            <DisableModal serversSelector={selectDisablableLoadedServer} />
            <DeleteModal serversSelector={selectDeletableLoadedServer} onConfirmed={() => routing.redirectToParent()} />
        </>
    )
}

export const Details: FC = () => (
    <ResourceDetails<CybusServer>
        subscriptionUsecase="manageServerUsecase"
        resourceSelector={selectServer}
        notFoundTitle={Translation.SERVER_NOT_FOUND}
        modals={Modals}
        content={Content}
        headerResourceName={Translation.SERVER}
        headerName="name"
        headerActions={HeaderActions}
        infoTitle={Translation.SERVER_DETAILS_DOCUMENTATION_TITLE}
        infoBody={Translation.SERVER_DETAILS_DOCUMENTATION_BODY}
        headerDetails={HeaderDetails}
    />
)
