import React, { type FC, type ReactNode } from 'react'

import { type Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'
import { DetailsHeader } from '../../common'

type HeaderProps = Readonly<{
    /**
     * If should show the resource name as a title or have a custom title
     */
    title?: boolean | Translation

    /**
     * If there should be title actions to the right of the title
     */
    actions?: ReactNode

    resourceName: Translation
    infoTitle?: Translation
    infoBody?: Translation
}>

export const Header: FC<HeaderProps> = ({ title = false, actions, resourceName, infoBody, infoTitle }) => (
    <DetailsHeader
        title={title ? { title: <FormattedTranslation id={typeof title === 'string' ? title : resourceName} values={{ count: 2 }} />, actions } : undefined}
        section={infoTitle && infoBody && { title: infoTitle, body: infoBody }}
    />
)
