import React, { type FC, type ReactNode } from 'react'
import { Divider, Grid, Paper, type SxProps, type Theme, Typography } from '@mui/material'

import { isEnumOf } from '../../../utils'
import { type Certificate, Translation } from '../../../domain'

import { FormattedList, FormattedTranslation } from '../Internationalization'
import { SmallCopy } from '../Copy'

const autoStyle: SxProps<Theme> = { m: 'auto', wordBreak: 'break-word' }
const detailsEntryStyle: SxProps<Theme> = { p: 2 }
const detailsTitleStyle: SxProps<Theme> = { mr: 2 }
const copyStyle: SxProps<Theme> = { ...autoStyle, textAlign: 'right' }
const titleStyle: SxProps<Theme> = { backgroundColor: 'grey.200', p: 2 }

const CertificateDetailsSection: FC<Readonly<{ title: Translation, children: ReactNode[], copy?: boolean }>> = ({ title, children, copy }) => (
    <Paper>
        <Grid container>
            <Grid sx={autoStyle} className="certificate-details-section" item xs={copy ? 8 : 12} md={copy ? 10 : 12}>
                <Typography sx={titleStyle} variant="subtitle2">
                    <FormattedTranslation id={title} values={{ count: children.length }} />
                </Typography>
            </Grid>

            {copy && (
                <Grid sx={copyStyle} item xs={4} md={2}>
                    <Typography sx={titleStyle} variant="subtitle2">
                        <FormattedTranslation id={Translation.ACTION} values={{ count: 0 }} />
                    </Typography>
                </Grid>
            )}
        </Grid>

        {children}
    </Paper>
)

const CertificateDetailsEntry: FC<Readonly<{ title: Translation | string, copy?: boolean, notAvailable?: boolean, none?: boolean }>> = ({
    title,
    copy,
    notAvailable,
    none,
    children,
}) => {
    if (!children && notAvailable) {
        children = (
            <>
                <span>{'<'}</span>
                <FormattedTranslation id={Translation.CERTIFICATE_PROPERTY_NOT_PROVIDED} />
                <span>{'>'}</span>
            </>
        )
    }

    if (Array.isArray(children) && children.length === 0 && none) {
        children = <FormattedTranslation id={Translation.NONE} />
    }

    return (
        <>
            <Grid container sx={detailsEntryStyle}>
                <Grid sx={autoStyle} item xs={4} md={2}>
                    <Typography sx={detailsTitleStyle} className="certificate-detail-title" variant="body2">
                        {isEnumOf(Translation, title) ? (
                            <FormattedTranslation id={title} values={{ count: Array.isArray(children) ? children.length : 0 }} />
                        ) : (
                            title
                        )}
                    </Typography>
                </Grid>
                <Grid sx={autoStyle} className="certificate-detail" item xs={copy ? 6 : 8} md={copy ? 8 : 10}>
                    <Typography variant="body2">{Array.isArray(children) ? <FormattedList type="unit" value={children} /> : children}</Typography>
                </Grid>
                {copy && (
                    <Grid sx={copyStyle} item md={2} xs={2}>
                        <SmallCopy>{children}</SmallCopy>
                    </Grid>
                )}
            </Grid>
            <Divider />
        </>
    )
}

export const CertificateDetails: FC<Readonly<{ certificate: Certificate }>> = (props) => (
    <>
        <CertificateDetailsSection title={Translation.ISSUED_TO}>
            <CertificateDetailsEntry title={Translation.COMMON_NAME} notAvailable>
                {props.certificate.issuedTo.commonName}
            </CertificateDetailsEntry>
            <CertificateDetailsEntry title={Translation.ORGANIZATION} notAvailable>
                {props.certificate.issuedTo.organization}
            </CertificateDetailsEntry>
            <CertificateDetailsEntry title={Translation.ORGANTIZATION_UNIT} notAvailable>
                {props.certificate.issuedTo.organizationalUnit}
            </CertificateDetailsEntry>
            {props.certificate.issuedTo.alternativeNames.length > 0 && (
                <CertificateDetailsEntry title={Translation.ALTERNATIVE_NAME}>{props.certificate.issuedTo.alternativeNames}</CertificateDetailsEntry>
            )}
        </CertificateDetailsSection>
        <CertificateDetailsSection title={Translation.ISSUED_BY}>
            <CertificateDetailsEntry title={Translation.COMMON_NAME} notAvailable>
                {props.certificate.issuedBy.commonName}
            </CertificateDetailsEntry>
            <CertificateDetailsEntry title={Translation.ORGANIZATION} notAvailable>
                {props.certificate.issuedBy.organization}
            </CertificateDetailsEntry>
            <CertificateDetailsEntry title={Translation.ORGANTIZATION_UNIT} notAvailable>
                {props.certificate.issuedBy.organizationalUnit}
            </CertificateDetailsEntry>
        </CertificateDetailsSection>
        <CertificateDetailsSection title={Translation.VALIDITY_PERIOD}>
            <CertificateDetailsEntry title={Translation.ISSUED_ON}>{props.certificate.issuedOn.toISOString()}</CertificateDetailsEntry>
            <CertificateDetailsEntry title={Translation.EXPIRES_ON}>{props.certificate.expiresOn.toISOString()}</CertificateDetailsEntry>
        </CertificateDetailsSection>
        <CertificateDetailsSection title={Translation.EXTENSIONS}>
            <CertificateDetailsEntry title={Translation.KEY_USAGE} none>
                {props.certificate.keyUsage.map((usage, k) => (
                    <FormattedTranslation key={k} id={Translation.KEY_USAGE_TYPE} values={{ usage }} />
                ))}
            </CertificateDetailsEntry>
            <CertificateDetailsEntry title={Translation.EXTENDED_KEY_USAGE} none>
                {props.certificate.extendedKeyUsage.map((usage, k) => (
                    <FormattedTranslation key={k} id={Translation.EXTENDED_KEY_USAGE_TYPE} values={{ usage }} />
                ))}
            </CertificateDetailsEntry>
            <CertificateDetailsEntry title={Translation.BASIC_CONSTRAINT}>
                <>
                    <FormattedTranslation id={Translation.CERTIFICATE_AUTHORITY} />
                    {': '}
                    <FormattedTranslation id={Translation.BOOLEAN} values={{ value: props.certificate.basicConstraints.authority }} />
                    {props.certificate.basicConstraints.authority && (
                        <>
                            , <FormattedTranslation id={Translation.PATH_LENGTH} />: {String(props.certificate.basicConstraints.pathLength)}
                        </>
                    )}
                </>
            </CertificateDetailsEntry>
        </CertificateDetailsSection>
        <CertificateDetailsSection title={Translation.FINGERPRINT} copy>
            <CertificateDetailsEntry title="SHA-1" copy>
                {props.certificate.fingerprints.sha1.toUpperCase()}
            </CertificateDetailsEntry>
            <CertificateDetailsEntry title="SHA-256" copy>
                {props.certificate.fingerprints.sha256.toUpperCase()}
            </CertificateDetailsEntry>
        </CertificateDetailsSection>
    </>
)
