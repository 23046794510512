import { areArrayEquals, areRecordsEquals, type Comparators, createNamedEqualityChecker } from '../../utils'
import { StatusType } from '..'

type CybusBaseConnection = Readonly<{
    /**
     * Full id of the connection
     */
    id: string

    /**
     * Name of the connection
     */
    name: string

    /**
     * Service that created the connection
     */
    service: string | null

    /**
     * Status of the connection
     */
    status: StatusType

    /**
     * Connection specific configuration
     */
    host: string | null
    port: string | null
    deviceAddress: string | null
    deviceInstance: string | null
    ipAddress: string | null
    cncType: string | null
    agent: string | null
    className: string | null
    device: string | null
    localInterface: string | null
    brokers: string[] | null
}>

const baseConnectionsComparators: Comparators<CybusBaseConnection> = {
    id: null,
    name: null,
    service: null,
    status: null,
    host: null,
    port: null,
    deviceAddress: null,
    deviceInstance: null,
    ipAddress: null,
    cncType: null,
    agent: null,
    className: null,
    device: null,
    localInterface: null,
    brokers: (a, b) => Array.isArray(a) && Array.isArray(b) && areArrayEquals(a, b, { sort: false }),
}

export type CybusConnection = CybusBaseConnection &
    Readonly<{
        /**
         * Protocol of the connection
         */
        protocol:
            | 'Ads'
            | 'Bacnet'
            | 'EthernetIp'
            | 'Focas'
            | 'GenericVrpc'
            | 'Hbmdaq'
            | 'Heidenhain'
            | 'Http'
            | 'Influxdb'
            | 'Kafka'
            | 'Modbus'
            | 'Mqtt'
            | 'Mssql'
            | 'Opcda'
            | 'Opcua'
            | 'Profinet'
            | 'S7'
            | 'Shdr'
            | 'Sinumerik'
            | 'Sopas'
            | 'Sql'
            | 'Systemstate'
            | 'Werma'
    }>

export const areCybusConnectionsEquals = createNamedEqualityChecker<CybusConnection>(
    { ...baseConnectionsComparators, protocol: null },
    'areCybusConnectionsEquals'
)

export type CybusDetailedConnection = CybusBaseConnection &
    Readonly<{
        /**
         * Further detailed configuration
         */
        extraConfiguration: Record<string, string>
    }>

export const areCybusDetailedConnectionEquals = createNamedEqualityChecker<CybusDetailedConnection>(
    { ...baseConnectionsComparators, extraConfiguration: areRecordsEquals },
    'areCybusConnectionsEquals'
)

export const canConnectionBeConnected = (connection: Pick<CybusBaseConnection, 'status'>): boolean => connection.status === StatusType.DISCONNECTED
export const canConnectionBeDisconnected = (connection: Pick<CybusBaseConnection, 'status'>): boolean => connection.status === StatusType.CONNECTED
