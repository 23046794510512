import React, { type FC, type PropsWithChildren, useRef, useState } from 'react'
import { Button, ButtonGroup, type ButtonProps, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, type SxProps, type Theme } from '@mui/material'
import { ArrowDropDown } from '@mui/icons-material'

import { type BaseOption, Label, type Props as LabelProps } from './Option'

type Props<Option,> = Readonly<{
    id?: string

    variant?: 'text' | 'outlined' | 'contained'
    color?: ButtonProps['color'] | undefined
    iconColor?: LabelProps['color']

    options: Option[]
    selected?: Option | null

    onChange: (option: Option) => void
}>

const popperStyle: SxProps<Theme> = { zIndex: 5 }
const arrowStyle: SxProps<Theme> = { width: '20px' }

const useToggle = (): [open: boolean, toggle: VoidFunction] => {
    const [open, setOpen] = useState(false)
    return [open, () => setOpen((prevOpen) => !prevOpen)]
}

/**
 * @see https://mui.com/components/button-group/#split-button
 */
export const SplitButton = <O extends BaseOption,>({
    id,
    color = 'primary',
    iconColor,
    variant = 'contained',
    selected = null,
    options,
    onChange,
}: PropsWithChildren<Props<O>>): ReturnType<FC> => {
    const groupRef = useRef<HTMLDivElement>(null)
    const [open, toggle] = useToggle()

    return (
        <>
            <ButtonGroup id={id && `${id}-button-group`} fullWidth variant={variant} color={color} ref={groupRef} size="small">
                <Button fullWidth size="small" onClick={toggle}>
                    {selected && <Label option={selected} color={'inherit'} />}
                </Button>
                <Button id={id && `${id}-arrow`} sx={arrowStyle} color={color} size="small" onClick={toggle}>
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>
            <Popper sx={popperStyle} open={open} anchorEl={groupRef.current} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                        <Paper>
                            <ClickAwayListener onClickAway={(event) => !groupRef.current?.contains(event.target as HTMLElement) && toggle()}>
                                <MenuList id={id && `${id}-menu-list`}>
                                    {options.map((option, k) => (
                                        <MenuItem
                                            title={option.label}
                                            key={k}
                                            style={{ width: groupRef.current?.clientWidth }}
                                            selected={option === selected}
                                            onClick={() => {
                                                toggle()
                                                onChange(option)
                                            }}
                                        >
                                            <Label option={option} color={iconColor} />
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}
