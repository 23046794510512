import React, { type FC } from 'react'

import { AnchorProvider } from '../../../common'

import { PermissionsTableProvider, type PermissionsTableProviderProps } from './State'
import { PermissionsTableTabs } from './Tabs'
import { InternalPermissionsTable } from './Table'

/**
 * This component renders a table with permission information
 * It also allows the user to manipulate the passed permissions in order to
 * - Add custom permissions
 * - Customize permissions (from inherited permissions)
 * - Remove custom permissions
 */
export const PermissionsTable: FC<PermissionsTableProviderProps> = (props) => (
    <PermissionsTableProvider {...props}>
        <AnchorProvider>
            <PermissionsTableTabs />
        </AnchorProvider>
        <InternalPermissionsTable />
    </PermissionsTableProvider>
)
