import type { RouterProps } from 'react-router-dom'

import { isEnumOf } from '../../../../utils'
import { ConnectwareError, ConnectwareErrorType, type Translation } from '../../../../domain'

import { generateIntenalPath, mergeState } from '../utils'
import { AbsoluteRouteOnlyPath, type AbsoluteRoutePath, AbsoluteRoutePathWithServiceId, type RouterState, type RouteUpdateArgs } from '../Domain'
import { getBreadcrumbConfig, getRouteConfig } from '../Config'
import { createRoutingHook } from './Hook'

export class RoutingHook {
    constructor (protected readonly history: Pick<RouterProps['history'], 'push' | 'createHref' | 'location'>, protected readonly state: RouterState) {}

    protected generateRedirection (...args: RouteUpdateArgs): string {
        return generateIntenalPath(mergeState(this.state, ...args))
    }

    redirect (...args: RouteUpdateArgs): void {
        this.history.push(this.generateRedirection(...args))
    }

    redirectToParent (moves = 1): void {
        let current = moves
        let path: AbsoluteRouteOnlyPath | AbsoluteRoutePathWithServiceId | null = null

        while (current--) {
            /**
             * For some reason typescript does not like this while
             * And misstypes some stuff as any
             *
             * @todo upgrade to the latest typescript and check if this still fails
             * if it does, create typescript bug ticket
             */
            const config = getRouteConfig(path ?? this.state.path) as ReturnType<typeof getRouteConfig>
            path = 'parent' in config && config.parent && 'path' in config.parent ? config.parent.path : null

            if (current <= 0 || !path) {
                /** Done moving, go to resolution */
                break
            }
        }

        if (isEnumOf(AbsoluteRouteOnlyPath, path)) {
            this.redirect(path)
            return
        }

        if (isEnumOf(AbsoluteRoutePathWithServiceId, path) && this.state.serviceId) {
            this.redirect(path, this.state.serviceId)
            return
        }

        throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Could not find expected parent path', { ...this.state, moves })
    }

    redirectHome (): void {
        this.redirect(AbsoluteRouteOnlyPath.SERVICES)
    }

    getBreadcrumbs (): Translation[] {
        const config = getBreadcrumbConfig(this.state.path)

        return [...config.parents.flatMap((parent) => ('breadcrumbTitle' in parent && parent.breadcrumbTitle ? [parent.breadcrumbTitle] : []))].reverse()
    }

    /**
     * @returns boolean indicating if the given current route is either the parent route or its child
     */
    isAtRoute (path: AbsoluteRoutePath): boolean {
        return (
            !this.state.path ||
            /**
             * Either it is at the route it-self
             */
            this.state.path === path ||
            /**
             * Or the parent contains it
             */
            getBreadcrumbConfig(this.state.path).parents.some((parent) => parent.id === path)
        )
    }

    /**
     * @returns boolean indicating if the given current route is in the given route group
     */
    isAtGroup (group: Translation): boolean {
        return Boolean(this.state.path && getBreadcrumbConfig(this.state.path).parents.some((parent) => parent.id === null && parent.title === group))
    }
}

export const useRouting = createRoutingHook('useRouting', RoutingHook)
