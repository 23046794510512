import React, { type FC, memo } from 'react'

import { selectUsersManagementRolesTabData, Translation } from '../../../../domain'

import { useAppUsecases } from '../../State'
import { useTranslator } from '../../Internationalization'

import { Table } from '../Table'

/**
 * @todo review this code block when https://cybusio.atlassian.net/browse/CYB-3564 is resolved
 */
export const RolesTable: FC = memo(() => {
    const translator = useTranslator()
    const { editRoleUsecase, createRoleUsecase } = useAppUsecases()

    return (
        <Table
            columns={{
                name: { label: Translation.NAME },
                users: {
                    label: translator.formatTranslation(Translation.USER, { count: 2 }),
                    customCellRender: (users) => translator.formatTranslation(Translation.ACTIVE_USER, { count: users.length }),
                },
                permissions: {
                    label: translator.formatTranslation(Translation.PERMISSION, { count: 0 }),
                    customCellRender: (permissions) =>
                        `${permissions.length} ${translator.formatTranslation(Translation.PERMISSION, { count: permissions.length }).toLowerCase()}`,
                },
            }}
            selector={selectUsersManagementRolesTabData}
            notFound={Translation.NO_ROLES_FOUND}
            onAdd={() => void createRoleUsecase.start()}
            addLabel={Translation.ADD_ROLE}
            onRowClick={(role) => void editRoleUsecase.start(role)}
        />
    )
})
