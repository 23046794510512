import React, { type FC, type ReactNode } from 'react'
import { Link as BaseLink, Box, inputBaseClasses, type LinkProps, outlinedInputClasses, type SxProps, type Theme, Typography } from '@mui/material'

import { type Translation } from '../../../domain'

import { FormattedTranslation } from '../Internationalization'
import {
    type BaseCommonProps,
    LengthLimitedField as BaseLengthLimitedField,
    type LengthLimitedFieldProps as BaseLengthLimitedFieldProps,
    TextField as BaseTextField,
    type TextFieldProps as BaseTextFieldProps,
} from '../common'

const baseTextFieldProps = {
    InputLabelProps: {
        sx: { color: 'white', [`&.${inputBaseClasses.focused}`]: { color: 'white' }, '&:hover': { color: 'white' } },
    },
    InputProps: {
        sx: {
            color: 'white',
            '&:hover': { color: 'white', [`& .${outlinedInputClasses.notchedOutline}`]: { borderColor: '#ffffff3b' } },
            [`&.${inputBaseClasses.focused} .${outlinedInputClasses.notchedOutline}`]: { borderColor: '#ffffff3b' },
            '& > fieldset': { borderColor: '#ffffff3b' },
        },
    },
} as const

export const LengthLimitedField: FC<Pick<BaseLengthLimitedFieldProps, 'id' | 'type' | 'value' | 'disabled' | 'onChange' | 'autoFocus'>> = (props) => (
    <BaseLengthLimitedField {...baseTextFieldProps} {...props} />
)

export const Field: FC<Pick<BaseTextFieldProps, 'name' | 'id' | 'type' | 'disabled' | 'autoFocus' | 'label' | 'value' | 'onKeyUp' | 'onChange'>> = (props) => (
    <BaseTextField {...baseTextFieldProps} fullWidth required {...props} />
)

const linkStyle: SxProps<Theme> = { cursor: 'pointer' }

export const Link: FC<{ id: string, disabled?: boolean, onClick?: VoidFunction } & Pick<LinkProps, 'color'>> = ({
    onClick,
    disabled,
    color = 'secondary',
    ...props
}) => <BaseLink display="block" underline="none" fontSize="small" sx={linkStyle} color={color} onClick={disabled ? undefined : onClick} {...props} />

const formChildStyle: SxProps<Theme> = { mt: 2 }

export const Form: FC<Pick<BaseCommonProps, 'id'> & Readonly<{ title: Translation, children: ReactNode[] }>> = ({ title, children, ...props }) => (
    <Box {...props}>
        <Typography variant="h5" color="white">
            <FormattedTranslation id={title} />
        </Typography>
        {children.map((child, k) => (
            <Box key={k} sx={formChildStyle}>
                {child}
            </Box>
        ))}
    </Box>
)
