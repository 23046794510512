import React, { type FC } from 'react'
import { Box, type SxProps, type Theme } from '@mui/material'

import { ConnectwareError, ConnectwareErrorType, selectInfo, Translation } from '../../../../domain'

import { AbsoluteRouteOnlyPath, PermissionedLink } from '../../routing'
import { FormattedTranslation } from '../../Internationalization'
import { useAppState } from '../../State'
import { CircularLoader } from '../../common'
import { ErrorMessage } from '../../ErrorMessage'
import { LicenseInfo } from '../Details'

const containerStyle: SxProps<Theme> = { my: 4 }
const linkStyle: SxProps<Theme> = { fontWeight: 500, textTransform: 'uppercase' }

const ServiceCountText: FC<{ count: number }> = ({ count }) => (
    <>
        {count} <FormattedTranslation id={Translation.SERVICE} values={{ count }} />
    </>
)

export const Services: FC = () => {
    const servicesCount = useAppState((s) => selectInfo(s)?.servicesCount ?? null)

    if (servicesCount === null) return <CircularLoader />

    return (
        <Box data-testid="info-services" sx={containerStyle}>
            {ConnectwareError.is(servicesCount) ? (
                <ErrorMessage
                    error={servicesCount}
                    extras={!ConnectwareError.isOfTypes(servicesCount, ConnectwareErrorType.AUTHENTICATION) ? 'popover' : 'section'}
                />
            ) : (
                <>
                    <LicenseInfo title={[Translation.INSTALLED_SERVICES, { count: 0 }]}>
                        <PermissionedLink path={AbsoluteRouteOnlyPath.SERVICES} sx={linkStyle}>
                            <ServiceCountText count={servicesCount} />
                        </PermissionedLink>
                    </LicenseInfo>
                </>
            )}
        </Box>
    )
}
