import type { CybusConnection, SortableColumn } from '../../../domain'

import { ResourcePageSubscriptionUsecase, ResourceSingleSubscriptionUsecase } from '../Resources'

export class ManageConnectionsUsecase extends ResourcePageSubscriptionUsecase<'connections'> {
    protected readonly pageName = 'connections'

    protected readonly pageAddress = 'connectionsPage'

    protected readonly initialSortColumn: SortableColumn<CybusConnection> = 'service'
}

export class ManageConnectionUsecase extends ResourceSingleSubscriptionUsecase<'connection'> {
    protected readonly singularName = 'connection'

    protected readonly singleAddress = 'connection'
}
