import { Cache } from '../../../utils'

import { Capability, CybusRuleEngineExecutionError } from '../../../domain'

import type { RuleEngineService } from '../../../application'

import { FetchConnectwareHTTPService } from '../Base'

export type Response = Readonly<{
    error?: Readonly<{ prefix: string | null, details: string, rule: Record<string, unknown>, ruleName: string, message: Record<string, unknown> }>
    output?: unknown
}>

export class ConnectwareHTTPRuleEngineService extends FetchConnectwareHTTPService implements RuleEngineService {
    /** Setup cache for successful results */
    private readonly cache = new Cache<Promise<unknown>>()

    transform (input: unknown, transformation: string): Promise<unknown> {
        return this.cache.get(
            () =>
                this.request({
                    capability: Capability.RULE_ENGINE_SANDBOX_USE,
                    method: 'POST',
                    path: '/api/rule-engine/transform',
                    body: { input, transformation },
                    authenticate: true,
                    handlers: {
                        200: async (response) => {
                            const data = await response.getJson<Response>()

                            if (data.error) {
                                const { prefix: messagePrefix, details: messageDetails, rule, ruleName, message: payload } = data.error
                                throw new CybusRuleEngineExecutionError({ messagePrefix, messageDetails, rule, ruleName, payload })
                            }

                            // This handles undefined output
                            return 'output' in data ? data.output : undefined
                        },
                    },
                }),
            input,
            transformation
        )
    }
}
