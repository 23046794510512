import type { Optional } from 'utility-types'

import { createArrayComparator, createNamedEqualityChecker } from '../../utils'
import type { ConnectwareError, CybusRole } from '..'
import type { Loadable } from '../Loadable'
import { type FieldValidation, isFieldValidated } from '../Field'
import { arePermissionArrayEquals, type CybusPersistedPermission, type EditableCybusPermissionInheritance, isPermissionValid } from '.'
import type { PermissionedEntity, PermissionedForm, UserManagementEditMode } from './Base'

export enum CybusUserProvider {
    CONNECTWARE = 'CONNECTWARE',
    LDAP = 'LDAP',
}

export enum CybusUserAuthenticationMethod {
    CERTIFICATE = 'CERTIFICATE',
    PASSWORD = 'PASSWORD',
    TOKEN = 'TOKEN',
}

export type CybusUser = PermissionedEntity<UserManagementEditMode.ALL | UserManagementEditMode.MFA_ONLY> &
    Readonly<{
        /**
         * Unique
         */
        username: string

        /**
         * If multifactor authentication is enabled for that user
         */
        mfa: boolean

        /**
         * The roles the user has
         */
        roles: CybusRole['name'][]

        /**
         * All permissions of the user
         */
        allPermissions: CybusPersistedPermission[]

        /**
         * The methods through which the user can authenticate
         */
        authenticationMethods: CybusUserAuthenticationMethod[]

        /**
         * When was the user last seen
         *
         * If null, then the information is not available
         */
        lastSeen: Date | null

        /**
         * The source of the user
         */
        provider: CybusUserProvider

        mqttPublishPrefix: string | null

        enforceMFAEnrollment: boolean
    }>

type FullUserForm = Pick<
    CybusUser,
    'authenticationMethods' | 'id' | 'editMode' | 'isProtected' | 'mqttPublishPrefix' | 'username' | 'mfa' | 'enforceMFAEnrollment'
> &
    PermissionedForm &
    Readonly<{
        password: string | null
        passwordConfirmation: string | null

        passwordValidation: FieldValidation
        usernameValidation: FieldValidation
        /**
         * @todo replace this with FieldValidation
         * @see {FieldValidation}
         */
        mqttPublishPrefixValidation: ConnectwareError | null

        roles: CybusRole[]
        rolesPermissions: EditableCybusPermissionInheritance[]

        roleInput: string | null
        allRoles: Loadable<CybusRole[]>
    }>

export type UserCreationForm = Pick<
    FullUserForm,
    | 'allRoles'
    | 'authenticationMethods'
    | 'mqttPublishPrefix'
    | 'mqttPublishPrefixValidation'
    | 'password'
    | 'passwordConfirmation'
    | 'passwordValidation'
    | 'permissions'
    | 'roleInput'
    | 'roles'
    | 'rolesPermissions'
    | 'selectedTemplate'
    | 'templateInput'
    | 'templates'
    | 'username'
    | 'usernameValidation'
    | 'enforceMFAEnrollment'
>

export type UserCreationRequest = Pick<
    UserCreationForm,
    'authenticationMethods' | 'mqttPublishPrefix' | 'password' | 'permissions' | 'roles' | 'username' | 'enforceMFAEnrollment'
>

export type UserEditingForm = Pick<
    FullUserForm,
    | 'allRoles'
    | 'authenticationMethods'
    | 'id'
    | 'editMode'
    | 'isProtected'
    | 'mqttPublishPrefix'
    | 'mqttPublishPrefixValidation'
    | 'password'
    | 'passwordConfirmation'
    | 'passwordValidation'
    | 'mfa'
    | 'permissions'
    | 'roleInput'
    | 'roles'
    | 'rolesPermissions'
    | 'selectedTemplate'
    | 'templateInput'
    | 'templates'
    | 'username'
    | 'usernameValidation'
    | 'enforceMFAEnrollment'
>
export type UserEditingRequest = Optional<
    Pick<
        UserEditingForm,
        'id' | 'authenticationMethods' | 'mqttPublishPrefix' | 'password' | 'permissions' | 'roles' | 'username' | 'mfa' | 'enforceMFAEnrollment'
    >,
    'authenticationMethods' | 'mqttPublishPrefix' | 'password' | 'permissions' | 'roles' | 'username' | 'mfa' | 'enforceMFAEnrollment'
>

export const areUserCreationFormsEqual = createNamedEqualityChecker<
    Pick<
        UserCreationForm,
        'username' | 'mqttPublishPrefix' | 'password' | 'passwordConfirmation' | 'roles' | 'permissions' | 'authenticationMethods' | 'enforceMFAEnrollment'
    >
>(
    {
        username: null,
        mqttPublishPrefix: null,
        password: null,
        passwordConfirmation: null,
        roles: createArrayComparator(),
        permissions: arePermissionArrayEquals,
        authenticationMethods: createArrayComparator(),
        enforceMFAEnrollment: null,
    },
    'areUserFormsEqual'
)

export const areUserEditingFormsEqual = createNamedEqualityChecker<
    Pick<
        UserEditingForm,
        | 'username'
        | 'mqttPublishPrefix'
        | 'password'
        | 'passwordConfirmation'
        | 'roles'
        | 'permissions'
        | 'authenticationMethods'
        | 'mfa'
        | 'enforceMFAEnrollment'
    >
>(
    {
        username: null,
        mfa: null,
        mqttPublishPrefix: null,
        password: null,
        passwordConfirmation: null,
        roles: createArrayComparator(),
        permissions: arePermissionArrayEquals,
        authenticationMethods: createArrayComparator(),
        enforceMFAEnrollment: null,
    },
    'areUserFormsEqual'
)

/**
 * Checks if username is present and if validation is positive
 */
const isUsernameValid = ({ username, usernameValidation }: Pick<UserCreationForm, 'username' | 'usernameValidation'>): boolean =>
    Boolean(username) && isFieldValidated(usernameValidation)

const arePermissionsValid = ({ permissions }: Pick<UserCreationForm, 'permissions'>): boolean => permissions.every(isPermissionValid)

/**
 * Checks if password is relevant or if validation is positive
 */
const isPasswordValid = ({ password, passwordValidation }: Pick<UserCreationForm, 'password' | 'passwordValidation'>): boolean =>
    (password === null && passwordValidation === null) || isFieldValidated(passwordValidation)

export const isUserCreatable = (form: UserCreationForm): boolean => isUsernameValid(form) && arePermissionsValid(form) && isPasswordValid(form)

export const isUserUpdatable = (form: UserEditingForm): boolean =>
    (form.isProtected || isUsernameValid(form)) && arePermissionsValid(form) && isPasswordValid(form)

export const isUserRemovable = (u: UserEditingForm): boolean => Boolean(u.id) && !u.isProtected
