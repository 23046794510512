import {
    type CybusRole,
    type LdapConfiguration,
    type RoleCreationForm,
    type RoleEditingForm,
    selectUsersManagementRoleCreation,
    selectUsersManagementRoleUpdating,
} from '../../../domain'

import { FormWithTemplatesUsecase } from './Template'

export class EditRoleUsecase extends FormWithTemplatesUsecase<RoleEditingForm> {
    protected readonly formName = 'roleUpdating'

    protected readonly selectForm = selectUsersManagementRoleUpdating

    protected async request (): Promise<void> {
        const { id, name, permissions, ldapGroupDn } = this.getCurrentForm()
        await this.userService.updateRole({ id, name, permissions, ldapGroupDn })
    }

    async delete (id: CybusRole['id']): Promise<void> {
        await this.userService.deleteRole(id)
        await this.reload()
    }

    async start ({ id, isProtected, editMode, name, permissions, users, ldapGroupDn }: CybusRole): Promise<void> {
        await this.userService.fetchLdapConfiguration().then(({ enabled }: LdapConfiguration) => {
            this.initializeForm({
                id,
                isProtected,
                editMode,
                name,
                permissions,
                users,
                ldapGroupDn: enabled ? ldapGroupDn : null,
                ...EditRoleUsecase.derivedTemplateValues,
            })
        })
    }

    async load (roleName: CybusRole['name']): Promise<void> {
        await this.transfer(this.userService.fetchRole(roleName).then((role) => this.start(role)))
    }
}

export class CreateRoleUsecase extends FormWithTemplatesUsecase<RoleCreationForm> {
    protected readonly formName = 'roleCreation'

    protected readonly selectForm = selectUsersManagementRoleCreation

    protected async request (): Promise<void> {
        const { name, permissions, ldapGroupDn } = this.getCurrentForm()
        await this.userService.createRole({ name, permissions, ldapGroupDn })
    }

    async start (): Promise<void> {
        await this.userService.fetchLdapConfiguration().then(({ enabled }: LdapConfiguration) => {
            this.initializeForm({ name: '', permissions: [], ...CreateRoleUsecase.derivedTemplateValues, ldapGroupDn: enabled ? '' : null })
        })
    }
}
