import React, { type FC } from 'react'
import { Grid, type SxProps, type Theme, Typography } from '@mui/material'

import { Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'

const headerStyle: SxProps<Theme> = { pt: 1, pb: 2 }

export const TableHead: FC = () => (
    <Grid container sx={headerStyle}>
        <Grid item xs={5}>
            <Typography variant="subtitle1">
                <FormattedTranslation id={Translation.TOPIC} values={{ count: 0 }} />
            </Typography>
        </Grid>
        <Grid item xs={7}>
            <Typography variant="subtitle1">
                <FormattedTranslation id={Translation.DATA} />
            </Typography>
        </Grid>
    </Grid>
)
