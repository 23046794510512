import { useEffect, useState } from 'react'

type Hasher<V> = (value: V) => unknown

/**
 *
 * @returns an one-tick-behind variable
 */
const useShadow = <V>(value: V, hasher: Hasher<V> = (v) => v): V => {
    const [shadow, setShadow] = useState(value)
    const hasChanged = hasher(shadow) !== hasher(value)

    useEffect(() => {
        // Leave this check to be done inside this scope
        if (hasher(shadow) !== hasher(value)) {
            setShadow(value)
        }
    }, [hasChanged])

    return shadow
}

/**
 * Helper to use one-tick-behind variables
 */
export const useShadowEffect = <V>(callback: (shadow: V) => void | VoidFunction, value: V, hasher?: Hasher<V>): void => {
    const shadow = useShadow(value, hasher)

    useEffect(() => (shadow === value ? undefined : callback(shadow)), [shadow, value])
}
