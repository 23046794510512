import type { Mutable } from 'utility-types'

import { entries } from '../../../../utils'
import { type CybusAvailableResources, StatusType } from '../../../../domain'

import type { CybusServiceProxyParams } from '../../proxies'
import { mapToStatusType } from '..'

const resourcesCategoryMap: Record<string, keyof CybusAvailableResources> = {
    Server: 'servers',
    Container: 'containers',
    Volume: 'volumes',
    Endpoint: 'endpoints',
    Connection: 'connections',
    Mapping: 'mappings',
}

/**
 * Copied from the Service manager
 * @see https://bitbucket.org/cybusio/cybus/src/44d79f2de76b44a3a43f1bbee3e2a81780514f4a/service-manager/src/Service.js#service-manager/src/Service.js-251
 */
export const mapCybusAvailableResources = ({ resources, deviation }: Pick<CybusServiceProxyParams, 'resources' | 'deviation'>): CybusAvailableResources => {
    const count: Mutable<CybusAvailableResources> = { servers: 0, connections: 0, volumes: 0, containers: 0, endpoints: 0, mappings: 0 }

    entries(resources).forEach(([logicalId, resource]) => {
        const match = deviation[logicalId]

        if (mapToStatusType(match?.actual) === StatusType.UNAVAILABLE) {
            return
        }

        const [, category] = resource.type.split('::')
        const prop = category && resourcesCategoryMap[category]
        if (!prop) {
            return
        }

        count[prop]++
    })

    return count
}
