import { delay, normalize, search } from '../../../../utils'

import { type StatusType } from '../../../../domain'

import { useStatusTranslator } from '../Status'
import { type TableSearcher } from '.'
import { useDispatchTableState } from './Context'

export const useStatusSearcher = (): TableSearcher<StatusType> => {
    const translator = useStatusTranslator()
    return (status, { normalizedTerms }) => search(normalize(translator(status)), normalizedTerms)
}

export const useClearSelection = (): VoidFunction => {
    const dispatch = useDispatchTableState()
    return () => {
        /** Delays are here so react does not complain :) */

        /** This forces the table to update (de-selecting everything) */
        delay(0).finally(() => dispatch({ clearSelection: true }))

        /** And this reverts that intention  */
        delay(1).finally(() => dispatch({ clearSelection: false }))
    }
}

export const useSearchDispatcher = (): ((search: string) => void) => {
    const dispatch = useDispatchTableState()
    return (search) => dispatch({ search })
}
