import { ConnectwareError, ConnectwareErrorType } from '../../../domain'

import { BaseConnectwareHTTPService, type HttpFetchArgs, type HttpResponse } from './Http'

const mapError = (error: Error): ConnectwareError => {
    if (error instanceof TypeError) {
        switch (error.message) {
            case 'Failed to fetch':
                /** Should happen in production, you will get a lot of problems here due to cors issues */
                return new ConnectwareError(ConnectwareErrorType.SERVER_NOT_AVAILABLE, 'Could not fetch information from server')
            case "Failed to execute 'blob' on 'Response': body stream already read":
            case "Failed to execute 'json' on 'Response': body stream already read":
            case "Failed to execute 'text' on 'Response': body stream already read":
                /** Should not happen in production */
                return new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Body stream was already closed')
        }
    }

    if (error instanceof SyntaxError && /Unexpected token .+ is not valid JSON/g.test(error.message)) {
        /** Should not happen in production */
        return new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Could not parse json')
    }

    /** Should only happen with node-fetch when running tests */
    if (/connect ECONNREFUSED 127.0.0.1:443/g.test(error.message)) {
        return new ConnectwareError(ConnectwareErrorType.SERVER_NOT_AVAILABLE, 'Could not fetch information from server', { fetchError: error.message })
    }

    /**
     * Should not happen in production
     * if the code has reached this place in a real world scenario
     * it means it neds revision
     */
    return new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Unexpected fetch error', { fetchError: error.message })
}

export type RelevantFetchResponse = Pick<Response, 'status' | 'headers' | 'blob' | 'text' | 'json'>

const wrapResponseError = async <T>(response: RelevantFetchResponse, executor: (response: RelevantFetchResponse) => Promise<T>): Promise<T> =>
    executor(response).catch((e: Error) => Promise.reject(mapError(e)))

class FetchHttpResponse implements HttpResponse {
    constructor (private readonly response: RelevantFetchResponse) {}

    getStatus (): number {
        return this.response.status
    }

    getHeaders (): [string, string][] {
        return Array.from(this.response.headers)
    }

    getBlob (): Promise<Blob> {
        return wrapResponseError(this.response, (r) => r.blob())
    }

    getText (): Promise<string> {
        return wrapResponseError(this.response, (r) => r.text())
    }

    getJson<T> (): Promise<T> {
        return wrapResponseError(this.response, (r) => r.json() as Promise<T>)
    }
}

export abstract class FetchConnectwareHTTPService extends BaseConnectwareHTTPService {
    protected async fetch ({ url, ...args }: HttpFetchArgs): Promise<HttpResponse> {
        return fetch(url, args)
            .catch((e: Error) => Promise.reject(mapError(e)))
            .then((r) => new FetchHttpResponse(r))
    }
}
