import { generatePath } from 'react-router-dom'

import { isEnumOf } from '../../../utils'
import { ConnectwareError, ConnectwareErrorType } from '../../../domain'

import {
    AbsoluteRouteOnlyPath,
    type AbsoluteRoutePath,
    AbsoluteRoutePathWithId,
    AbsoluteRoutePathWithServiceAndResourceId,
    AbsoluteRoutePathWithServiceId,
    RelativeRoutePathWithId,
    type RouterState,
    type RouteUpdateArgs,
} from './Domain'
import { getAbsoluteRoute, getContext, getRouteInternalPath } from './Config'

export const mapState = (path: AbsoluteRoutePath, resourceId: string | null, serviceId: string | null): RouterState => {
    if (resourceId === null && serviceId === null && isEnumOf(AbsoluteRouteOnlyPath, path)) {
        return { path, resourceId, serviceId }
    }

    if (typeof resourceId === 'string' && serviceId === null && isEnumOf(AbsoluteRoutePathWithId, path)) {
        return { path, resourceId, serviceId }
    }

    if (resourceId === null && typeof serviceId === 'string' && isEnumOf(AbsoluteRoutePathWithServiceId, path)) {
        return { path, resourceId, serviceId }
    }

    if (typeof resourceId === 'string' && typeof serviceId === 'string' && isEnumOf(AbsoluteRoutePathWithServiceAndResourceId, path)) {
        return { path, resourceId, serviceId }
    }

    throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Router state is invalid', { path, resourceId, serviceId })
}

export const mergeState = (state: RouterState, ...args: RouteUpdateArgs): RouterState => {
    if (args.length === 1) {
        return { path: args[0], resourceId: null, serviceId: null }
    }

    const [path, id] = args
    const absolutePath = isEnumOf(RelativeRoutePathWithId, path) ? getAbsoluteRoute(path, getContext(state.path)) : path

    if (isEnumOf(AbsoluteRoutePathWithId, absolutePath)) {
        return { path: absolutePath, resourceId: id, serviceId: null }
    }

    if (isEnumOf(AbsoluteRoutePathWithServiceId, absolutePath)) {
        return { path: absolutePath, resourceId: null, serviceId: id }
    }

    const { serviceId } = state

    if (!serviceId) {
        throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Could not find expected serviceId', { args })
    }

    return { path: absolutePath, serviceId, resourceId: id }
}

export const generateIntenalPath = ({ path, serviceId, resourceId }: RouterState): string =>
    generatePath(getRouteInternalPath(path), { serviceId: serviceId || undefined, resourceId: resourceId || undefined })
