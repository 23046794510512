import React, { type FC } from 'react'
import { List, ListItem, ListItemText, listItemTextClasses, type SxProps, type Theme } from '@mui/material'
import { Lens } from '@mui/icons-material'

import { StatusType } from '../../../../domain'

import { useStatusTranslator } from './Hooks'

const fullWidthListStyle: SxProps<Theme> = { m: 'auto 0', p: 0, width: '100%', maxWidth: '150px' }
const listStyle: SxProps<Theme> = { m: 'auto 0', p: 0, maxWidth: '150px' }
const listItemTextStyle: SxProps<Theme> = { m: 0, [`& > .${listItemTextClasses.secondary}`]: { ml: 1, fontSize: '0.7125rem' } }
const red: SxProps<Theme> = { color: 'red.500' }
const orange: SxProps<Theme> = { color: 'orange.500' }
const green: SxProps<Theme> = { color: 'green.500' }
const blue: SxProps<Theme> = { color: 'primary.main' }
const grey: SxProps<Theme> = { color: 'grey.500' }

const colors: Record<StatusType, SxProps<Theme>> = {
    [StatusType.DISABLED]: red,
    [StatusType.DISCONNECTED]: red,
    [StatusType.EXITED]: red,
    [StatusType.OFFLINE]: red,
    [StatusType.PAUSED]: red,
    [StatusType.UNAVAILABLE]: red,

    [StatusType.CONNECTING]: orange,
    [StatusType.DEVIATED]: orange,
    [StatusType.DISABLING]: orange,
    [StatusType.DISCONNECTING]: orange,
    [StatusType.ENABLING]: orange,
    [StatusType.RECONNECTING]: orange,
    [StatusType.REENABLING]: orange,
    [StatusType.RESTARTING]: orange,
    [StatusType.ROLLING_BACK]: orange,

    [StatusType.AVAILABLE]: green,
    [StatusType.CONNECTED]: green,
    [StatusType.ENABLED]: green,
    [StatusType.NO_DEVIATION]: green,
    [StatusType.ONLINE]: green,
    [StatusType.RUNNING]: green,
    [StatusType.STARTING]: green,
    [StatusType.STOPPING]: green,

    [StatusType.UPDATING]: blue,

    [StatusType.CREATED]: grey,
    [StatusType.DEAD]: grey,
    [StatusType.TERMINATED]: grey,
    [StatusType.WAITING]: grey,
    [StatusType.NOT_ENABLED]: grey,
    [StatusType.UNKNOWN]: grey,
}

const Status: FC<Readonly<{ status: StatusType }>> = ({ status, ...props }) => {
    const translateStatus = useStatusTranslator()

    return (
        <List {...props} sx={listStyle} dense>
            <ListItem sx={fullWidthListStyle} dense>
                <Lens sx={colors[status]} />
                <ListItemText sx={listItemTextStyle} secondary={translateStatus(status)} />
            </ListItem>
        </List>
    )
}

export default Status
