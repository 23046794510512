import type { ManagedVrpcRemote, VrpcRemoteManager } from '.'

/**
 * Creates a connection to be used inside the given callback
 *
 * If there is already an connection stablished, another connection will not be made
 *
 * @param callback
 * @param onDroppedCallback
 * @throws `ConnectwareError`
 */
export const withConnection = async <T>(remote: VrpcRemoteManager, callback: (vrpcRemote: ManagedVrpcRemote) => Promise<T> | T): Promise<T> => {
    const [vrpcRemote, notifyDisuse] = await remote.getManagedVrpcRemote()

    try {
        return await callback(vrpcRemote)
    } finally {
        notifyDisuse()
    }
}
