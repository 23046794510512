import type { PickByValue } from 'utility-types'
import React, { type FC } from 'react'

import { type AppState, type CybusCoreContainer, type CybusServiceContainer, type ResourceType, Translation } from '../../../domain'

import type {
    BulkDeleteServiceContainersUsecase,
    BulkRestartCoreContainersUsecase,
    BulkRestartServiceContainersUsecase,
    BulkStartCoreContainersUsecase,
    BulkStartServiceContainersUsecase,
    BulkStopCoreContainersUsecase,
    BulkStopServiceContainersUsecase,
    Usecases,
} from '../../../application'

import { useAppUsecases } from '../State'

import { DeleteResourceModal, DisableResourceModal, EnableResourceModal, ReenableResourceModal, ServiceModalBody } from '../Resources'

type Container = Pick<CybusCoreContainer, 'id' | 'name' | 'status'> | Pick<CybusServiceContainer, 'id' | 'name' | 'service' | 'status'>

type Props<Usecase,> = Readonly<{
    dimension: ResourceType
    containersSelector: (s: AppState, ids: string[]) => Container[]
    usecase: keyof PickByValue<Usecases, Usecase>
}>

export const StartModal: FC<Props<BulkStartCoreContainersUsecase | BulkStartServiceContainersUsecase>> = ({ dimension, usecase, containersSelector }) => {
    const { [usecase]: startUsecase } = useAppUsecases()

    return (
        <EnableResourceModal
            title={Translation.START_CONTAINERS}
            subtitle={Translation.START_CONTAINERS_SUBTITLE}
            confirm={Translation.START}
            resourceSelector={containersSelector}
            dimension={dimension}
            onRenderLineContent={ServiceModalBody}
            onCancel={() => startUsecase.selectContainers(null)}
            onConfirm={() => startUsecase.start()}
        />
    )
}

export const RestartModal: FC<Props<BulkRestartServiceContainersUsecase | BulkRestartCoreContainersUsecase>> = ({ dimension, usecase, containersSelector }) => {
    const { [usecase]: restartUsecase } = useAppUsecases()

    return (
        <ReenableResourceModal
            title={Translation.RESTART_CONTAINERS}
            subtitle={Translation.RESTART_CONTAINERS_SUBTITLE}
            confirm={Translation.RESTART}
            resourceSelector={containersSelector}
            dimension={dimension}
            onRenderLineContent={ServiceModalBody}
            onCancel={() => restartUsecase.selectContainers(null)}
            onConfirm={() => restartUsecase.restart()}
        />
    )
}

export const StopModal: FC<Props<BulkStopServiceContainersUsecase | BulkStopCoreContainersUsecase>> = ({ dimension, usecase, containersSelector }) => {
    const { [usecase]: stopUsecase } = useAppUsecases()

    return (
        <DisableResourceModal
            title={Translation.STOP_CONTAINERS}
            subtitle={Translation.STOP_CONTAINERS_SUBTITLE}
            confirm={Translation.STOP}
            resourceSelector={containersSelector}
            dimension={dimension}
            onRenderLineContent={ServiceModalBody}
            onCancel={() => stopUsecase.selectContainers(null)}
            onConfirm={() => stopUsecase.stop()}
        />
    )
}

export const DeleteModal: FC<Props<BulkDeleteServiceContainersUsecase> & { onConfirmed: VoidFunction }> = ({
    dimension,
    usecase,
    containersSelector,
    onConfirmed,
}) => {
    const { [usecase]: deleteUsecase } = useAppUsecases()

    return (
        <DeleteResourceModal
            title={Translation.DELETE_CONTAINERS}
            subtitle={Translation.DELETE_CONTAINERS_SUBTITLE}
            confirm={Translation.DELETE}
            resourceSelector={containersSelector}
            dimension={dimension}
            onRenderLineContent={ServiceModalBody}
            onCancel={() => deleteUsecase.selectContainers(null)}
            onConfirm={async () => {
                await deleteUsecase.delete()
                onConfirmed()
            }}
        />
    )
}
