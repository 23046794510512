import { Droppable } from '../../../utils'
import { type ConnectwareError, MfaSettingsState } from '../../../domain'
import { MfaUsecase } from './Base'

export class LoadMfaSettingsUsecase extends MfaUsecase {
    load (): VoidFunction {
        const dropable = new Droppable()

        this.setMfa({ state: MfaSettingsState.LOADING, error: null })

        this.mfaService
            .isEnabled()
            .then((enabled) =>
                dropable.ifNotDropped(() =>
                    this.setMfa(enabled ? { state: MfaSettingsState.ENABLED, backupCodes: null } : { state: MfaSettingsState.DISABLED })
                )
            )
            .catch((error: ConnectwareError) => dropable.ifNotDropped(() => this.updateMfaSettings({ state: MfaSettingsState.LOADING, error })))

        dropable.onDrop(() => this.setMfa(null))

        return () => dropable.drop()
    }
}
