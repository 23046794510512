import React, { type FC, type ReactNode } from 'react'
import { Typography, type TypographyProps } from '@mui/material'

import { type Translation } from '../../../domain'

import { boldFormatter, FormattedTranslation } from '../Internationalization'

const baseValues = { b: boldFormatter, br: <br /> }

type Props = Readonly<{ id: Translation, values?: Record<string, ReactNode> }>

const Base: FC<Props & Pick<TypographyProps, 'variant'>> = ({ id, values, variant }) => (
    <Typography variant={variant}>
        <FormattedTranslation id={id} values={{ ...values, ...baseValues }} />
    </Typography>
)

export const Title: FC<Props> = (props) => <Base variant="h6" {...props} />
export const SmallParagraph: FC<Props> = (props) => <Base variant="body2" {...props} />
