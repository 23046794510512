import React, { type FC } from 'react'

import { Divider, type SxProps, type Theme, Typography } from '@mui/material'

import { type Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'

const subHeaderStyle: SxProps<Theme> = { lineHeight: 'inherit', mt: 3, mb: 1 }
const dividerStyle: SxProps<Theme> = { mb: 2 }
const subtitleStyle: SxProps<Theme> = { color: 'grey.600', mb: 2 }

export const ResourcesList: FC<{ readonly title: Translation, readonly subtitle?: Translation }> = ({ title, subtitle, children }) => {
    return (
        <>
            <Typography variant="h6" sx={subHeaderStyle}>
                <FormattedTranslation id={title} values={{ count: 0 }} />
            </Typography>
            <Divider sx={dividerStyle} />
            {subtitle && (
                <Typography sx={subtitleStyle}>
                    <FormattedTranslation id={subtitle} />
                </Typography>
            )}

            {children}
        </>
    )
}
