import { generateUtilityClasses } from '@mui/material'

export const errorMessagesClasses = generateUtilityClasses('CybusErrorMessage', [
    'root',
    'header',
    'popover',
    'popover-button',
    'popover-content',
    'popover-extras',
    'stack',
    'extras',
    'extras-info',
    'extras-info-title',
])
