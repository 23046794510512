import type { Metrics } from '../../../domain'

import { Usecase } from '../Usecase'

export class SystemMetricsUsecase extends Usecase {
    fetchMetrics (): Promise<Metrics> {
        return this.systemService.fetchMetrics()
    }

    sendMetricsToPortal (): Promise<void> {
        return this.systemService.sendMetricsToPortal()
    }
}
