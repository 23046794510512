import { ConnectwareError, ConnectwareErrorType } from '../../../domain'
import { type PermissionRequirement } from '../../Connectware'

const replacecementSymbol = '+'

/**
 * This function is based on how the auth-server's permissions resources are written
 *
 * It is here like so in order to allow the admin-ui to handle the permissions written as foo/bar/+
 *
 * Replaces the given template {replacecementSymbol} with the given params
 * @param permissionPathTemplate a given path template as seen in Connectware (EG.: foo/bar/+/x/+)
 * @param params array of possible ids
 * @returns the actual path
 */
export const buildPath = (permissionPathTemplate: Exclude<PermissionRequirement['resource'], null>, ...params: string[]): string => {
    const parts = permissionPathTemplate.split(replacecementSymbol)

    if (parts.length !== params.length + 1) {
        throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'There is a mismatching number of replacement symbols and params', {
            replacecementSymbol,
            params,
            permissionPathTemplate,
        })
    }

    return parts.reduce((out, part, k) => `${out}${part}${encodeURIComponent(params[k] ?? '')}`, '')
}
