import type { ReadonlyRecord } from '../utils'
import type { Loadable } from './Loadable'

export enum CertificateKeyUsage {
    CRITICAL = 'CRITICAL',
    CRL_SIGN = 'CRL_SIGN',
    DATA_ENCIPHERMENT = 'DATA_ENCIPHERMENT',
    DECIPHER_ONLY = 'DECIPHER_ONLY',
    DIGITAL_SIGNATURE = 'DIGITAL_SIGNATURE',
    ENCIPHER_ONLY = 'ENCIPHER_ONLY',
    KEY_AGREEMENT = 'KEY_AGREEMENT',
    KEY_CERTSIGN = 'KEY_CERTSIGN',
    KEY_ENCIPHERMENT = 'KEY_ENCIPHERMENT',
    NON_REPUDIATION = 'NON_REPUDIATION',
}

export enum CertificateExtendedKeyUsage {
    CLIENT_AUTH = 'CLIENT_AUTH',
    CODE_SIGNING = 'CODE_SIGNING',
    CRITICAL = 'CRITICAL',
    EMAIL_PROTECTION = 'EMAIL_PROTECTION',
    IPSEC_IKE = 'IPSEC_IKE',
    MS_CODE_COM = 'MS_CODE_COM',
    MS_CODE_IND = 'MS_CODE_IND',
    MS_CTL_SIGN = 'MS_CTL_SIGN',
    MS_EFS = 'MS_EFS',
    OCSP_SIGNING = 'OCSP_SIGNING',
    SERVER_AUTH = 'SERVER_AUTH',
    TIME_STAMPING = 'TIME_STAMPING',
}

export type Certificate = Readonly<{
    id: string
    issuedBy: Readonly<{ commonName: string | null, organization: string, organizationalUnit: string | null }>

    issuedTo: Readonly<{ commonName: string | null, organization: string | null, organizationalUnit: string | null, alternativeNames: string[] }>

    keyUsage: CertificateKeyUsage[]
    extendedKeyUsage: CertificateExtendedKeyUsage[]
    basicConstraints: Readonly<{ authority: true, pathLength: number | null } | { authority: false }>

    issuedOn: Date
    expiresOn: Date

    fingerprints: ReadonlyRecord<'sha1' | 'sha256', string>

    removable: boolean
}>

export const getCertificateTitle = (certificate: Certificate): string => certificate.issuedBy.commonName || certificate.issuedBy.organization

/**
 * @description Sort certificates by commonName or organization
 * @param certs
 * @returns sorted certs
 */
export const sortCertificates = ([...certs]: Certificate[]): Certificate[] =>
    certs.sort((certA, certB) => (getCertificateTitle(certA) > getCertificateTitle(certB) ? 1 : -1))

export type CertificatesAppState = Readonly<{ certificates: Loadable<Certificate[]> }>

export const selectCertificates = (s: CertificatesAppState): CertificatesAppState['certificates'] => s.certificates
