/**
 * This is a utility class to assist with asynchronous usage of assets
 */
export class Droppable {
    private readonly callbacks = new Set<() => unknown>()

    private dropped = false

    get isDropped (): boolean {
        return this.dropped
    }

    private execute (): void {
        if (!this.dropped) {
            return
        }

        /**
         * Make copy of callbacks
         */
        const callbacks = Array.from(this.callbacks)

        /**
         * Drop all listed callbacks
         */
        this.callbacks.clear()

        /**
         * Execute all callbacks
         */
        callbacks.forEach((callback) => callback())
    }

    drop (): void {
        this.dropped = true
        this.execute()
    }

    onDrop (callback: () => unknown): void {
        this.callbacks.add(callback)
        this.execute()
    }

    ifNotDropped<R> (callback: () => R): R | void {
        if (!this.dropped) {
            return callback()
        }
    }
}
