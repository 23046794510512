import { buttonBaseClasses, type CSSInterpolation, type ThemeOptions } from '@mui/material'

import { cybusTheme } from '../../theme'

type Props = Readonly<{ cursorHover: boolean }>

export const createThemeOptions = ({ cursorHover }: Props): ThemeOptions => {
    const padding = cybusTheme.spacing(1)

    /** Changes the cells background and removes its sorting buttons padding */
    const headerStyle: CSSInterpolation = {
        background: '#F9F9F9',
        boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12)',
        [`& .${buttonBaseClasses.root}`]: { paddingTop: 0, paddingBottom: 0 },
    }

    /** Flattens the cells top and bottom padding */
    const cellsStyle: CSSInterpolation = { paddingTop: padding, paddingBottom: padding, cursor: cursorHover ? 'pointer' : undefined, fontSize: 'inherit' }

    return {
        ...cybusTheme,
        components: {
            MUIDataTableHeadCell: { styleOverrides: { root: headerStyle } },
            MUIDataTableSelectCell: { styleOverrides: { headerCell: headerStyle } },
            MUIDataTableBodyCell: { styleOverrides: { root: cellsStyle } },
        },
    }
}
