import React, { type FC } from 'react'

import { Box, Link, type SxProps, type Theme } from '@mui/material'

import { selectChangePasswordForm, Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'
import { useAppState, useAppUsecase } from '../../State'

import { Section } from '../Section'
import { ChangePasswordForm } from './Form'
import { SmallParagraph, Title } from '../../common'

const openPasswordLink: SxProps<Theme> = { cursor: 'pointer' }

const ShowChangePasswordFormLink: FC = () => {
    const usecase = useAppUsecase('changePasswordUsecase')

    return (
        <Link data-testid="show-change-password-form" sx={openPasswordLink} onClick={() => usecase.toggle()}>
            <FormattedTranslation id={Translation.CHANGE_PASSWORD} />
        </Link>
    )
}

const settingsStyle: SxProps<Theme> = { pt: 3 }

export const ChangePasswordSettings: FC = () => {
    const form = useAppState(selectChangePasswordForm)

    return (
        <Section width={300}>
            <Title id={Translation.CHANGE_PASSWORD} />
            <SmallParagraph id={Translation.CHANGE_PASSWORD_DESCRIPTION} />
            <Box data-testid="change-password-settings" sx={settingsStyle}>
                {form ? <ChangePasswordForm form={form} /> : <ShowChangePasswordFormLink />}
            </Box>
        </Section>
    )
}
