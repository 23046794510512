import type { SubscriptionsTypes } from '../../../../application'

import type { SubscriptionStrategy } from './Strategy'

import {
    AgentsStrategy,
    ConnectionsStrategy,
    CoreContainerStrategy,
    DetailedConnectionStrategy,
    DetailedCoreContainerStrategy,
    DetailedEndpointStrategy,
    DetailedMappingStrategy,
    DetailedServiceContainerStrategy,
    DetailedServiceStrategy,
    DetailedVolumeStrategy,
    DeviationsStrategy,
    EndpointsStatusStrategy,
    EndpointsStrategy,
    MappingsStatusStrategy,
    MappingsStrategy,
    NodesStatusStrategy,
    NodesStrategy,
    ServerStrategy,
    ServiceContainersStrategy,
    ServicesLinksStrategy,
    ServicesStrategy,
    VolumesStrategy,
} from './implementations'
import type { HttpRequestArgs, HttpRequestBody } from '../../Base'

/**
 * @todo attempt ro generate some from {BaseSubscriptionStrategy}
 */
type StrategyConstructor<T extends keyof SubscriptionsTypes> = new (
    request: <ReqBody extends HttpRequestBody, Return>(args: HttpRequestArgs<ReqBody, Return>) => Promise<Return>
) => SubscriptionStrategy<T>

const strategyCreators: Record<keyof SubscriptionsTypes, StrategyConstructor<keyof SubscriptionsTypes>> = {
    services: ServicesStrategy,
    service: DetailedServiceStrategy,
    servicesLinks: ServicesLinksStrategy,
    mappingStatus: MappingsStatusStrategy,
    nodeStatus: NodesStatusStrategy,
    endpointStatus: EndpointsStatusStrategy,
    endpoints: EndpointsStrategy,
    endpoint: DetailedEndpointStrategy,
    deviations: DeviationsStrategy,
    volumes: VolumesStrategy,
    volume: DetailedVolumeStrategy,
    serviceContainers: ServiceContainersStrategy,
    serviceContainer: DetailedServiceContainerStrategy,
    coreContainers: CoreContainerStrategy,
    coreContainer: DetailedCoreContainerStrategy,
    connections: ConnectionsStrategy,
    connection: DetailedConnectionStrategy,
    mappings: MappingsStrategy,
    mapping: DetailedMappingStrategy,
    agents: AgentsStrategy,
    nodes: NodesStrategy,
    servers: ServerStrategy,
    server: ServerStrategy,
}

export const getSubscriptionStrategyFactory = <T extends keyof SubscriptionsTypes>(type: T): StrategyConstructor<T> =>
    strategyCreators[type] as StrategyConstructor<T>
