import React, { type FC, type ReactElement, useEffect } from 'react'
import { Collapse, Divider, List, ListItem, ListItemButton, type SxProps, type Theme } from '@mui/material'
import { ExpandLess, ExpandMore, type SvgIconComponent } from '@mui/icons-material'

import { type Translation } from '../../../../domain'

import { useRouting } from '../../routing'
import { useTranslator } from '../../Internationalization'

import { isNavigationSectionOpen, useToggleNavigationSection } from '../Context'

import { type NavigationLinkProps } from './Link'
import { Icon, Text } from './Item'
import { useChildrenCapabilitiesChecker } from './Hooks'
import { listItemStyle, sideMenuIconStyle } from './Styles'

const fullWidthStyle: SxProps<Theme> = { width: '100%' }
const noPaddingStyle: SxProps<Theme> = { p: 0 }

type NavigationLinkListProps = Readonly<{
    icon: SvgIconComponent
    title: Translation
    description: Translation
    children: ReactElement<NavigationLinkProps>[]
    divide?: boolean
}>

export const NavigationLinkList: FC<NavigationLinkListProps> = ({ title, description, icon, children, divide }: NavigationLinkListProps) => {
    const translator = useTranslator()
    const routing = useRouting()
    const selected = routing.isAtGroup(title)

    const expanded = isNavigationSectionOpen(title)
    const toggleNavigationSection = useToggleNavigationSection(title)
    const doesUserHasCapabilitiesForChildren = useChildrenCapabilitiesChecker()

    useEffect(() => {
        /** Only for first render and wait for selected to be updated.
         * check if child menu is selected
         * and the parent is not expanded
         * then should toggle the navigation section to expand the menu
         */
        if (selected && !expanded) {
            toggleNavigationSection()
        }
    }, [selected])

    if (!doesUserHasCapabilitiesForChildren(children)) {
        return null
    }

    return (
        <>
            {divide && <Divider variant="white" />}
            <ListItemButton
                sx={listItemStyle}
                data-testid={`navigation-list-${title.toLowerCase().replaceAll('_', '-')}`}
                title={translator.formatTranslation(title, { count: 0 })}
                component="li"
                onClick={toggleNavigationSection}
            >
                <Icon icon={icon} />
                <Text title={title} description={description} />
                {expanded ? <ExpandLess fontSize="small" sx={sideMenuIconStyle} /> : <ExpandMore fontSize="small" sx={sideMenuIconStyle} />}
            </ListItemButton>

            <ListItem sx={noPaddingStyle} component="li">
                <Collapse sx={fullWidthStyle} in={expanded} timeout="auto" unmountOnExit>
                    <List disablePadding>{children}</List>
                </Collapse>
            </ListItem>
        </>
    )
}
