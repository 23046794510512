import { useMemo } from 'react'

import {
    type AppState,
    type CybusCatalogApplication,
    type CybusCatalogApplicationCategory,
    selectServiceCatalogApplication,
    selectServiceCatalogApplications,
    selectServiceCatalogFiltered,
    selectServiceCatalogSearch,
    selectServiceCatalogTemplate,
    Translation,
} from '../../../../domain'

import { useTranslator } from '../../Internationalization'
import { useAppState } from '../../State'

export const useSearch = (): AppState['catalog']['search'] => useAppState(selectServiceCatalogSearch)

export const useServices = (): AppState['catalog']['applications'] => useAppState(selectServiceCatalogApplications)

export const useFilteredApplications = (): {
    isEmpty(): boolean
    getTemplates(category: CybusCatalogApplicationCategory): CybusCatalogApplication[]
    getApplications(category: CybusCatalogApplicationCategory): CybusCatalogApplication[]
} => {
    const filtered = useAppState(selectServiceCatalogFiltered)

    const [byCategory, templates] = useMemo(() => {
        const templates = new Map<CybusCatalogApplicationCategory, CybusCatalogApplication[]>()
        const byCategory = new Map<CybusCatalogApplicationCategory, CybusCatalogApplication[]>()

        filtered.forEach((service) => {
            byCategory.set(service.category, [...(byCategory.get(service.category) || []), service])
            if (service.template) {
                templates.set(service.category, [...(templates.get(service.category) || []), service])
            }
        })

        return [byCategory, templates]
    }, [filtered])

    return {
        isEmpty: () => filtered.length === 0,
        getTemplates: (category) => templates.get(category) || [],
        getApplications: (category) => byCategory.get(category) || [],
    }
}

export const useTemplate = (): AppState['catalog']['template'] => useAppState(selectServiceCatalogTemplate)

export const useApplication = (): AppState['catalog']['application'] => useAppState(selectServiceCatalogApplication)

export const useCategoryTranslation = (): ((category: CybusCatalogApplicationCategory) => string) => {
    const translator = useTranslator()
    return (category) => translator.formatTranslation(Translation.SERVICE_CATEGORY, { category })
}
