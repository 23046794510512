import type { PickByValueExact, ValuesType } from 'utility-types'
import React, { type FC, type PropsWithChildren, useEffect } from 'react'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    type DialogProps,
    DialogTitle,
    LinearProgress,
    type SxProps,
    type Theme,
    Typography,
    type TypographyProps,
} from '@mui/material'

import { areArrayEquals, createEqualityChecker } from '../../../utils'

import { type AppState, ConnectwareError, selectResourcesByDimensionAndAction, Translation } from '../../../domain'

import { useAppState } from '../State'
import { createFormatter, FormattedTranslation } from '../Internationalization'
import { useAsyncCallback } from '../Callback'
import { ErrorMessage } from '../ErrorMessage'
import { DetailsHeader } from '../common'

const dialogEntryStyle: SxProps<Theme> = { mt: 1 }
const progressStyle: SxProps<Theme> = { width: '100%', position: 'absolute' }
const dialogTitleStyle: SxProps<Theme> = { pt: 5, px: 5, pb: 0 }
const dialogContentStyle: SxProps<Theme> = { px: 5 }
const dialogActionsStyle: SxProps<Theme> = { px: 5, pb: 5, pt: 2 }

type AllBulkModalProps<T,> = {
    /**
     * Mapper to turn the given id into resources
     * to be read from the given state
     */
    type: keyof ValuesType<AppState['resources']>
    dimension: keyof AppState['resources']

    resourceSelector: (state: AppState, id: string[]) => T[]

    showOnlyOnError?: (value: T) => boolean

    selected: T[]
    load: boolean | ConnectwareError

    title: Translation
    subtitle: Translation
    infoBody?: Translation
    confirm: Translation

    /**
     * Modal styles
     */
    maxWidth?: DialogProps['maxWidth']

    /**
     * Renders the given resources (yielded from resourceSelector)
     * Rendered if there is at least one resource
     */
    onRenderLine?: FC

    /**
     * The content inside the line, if given a key, will use the value of the resource derived from the key
     */
    onRenderLineContent: FC<{ resource: T }> | (keyof PickByValueExact<T, string> & string)

    /**
     * User has canceled the bulk action
     */
    onCancel: () => void

    /**
     * User has confirmed the action
     */
    onConfirm: () => Promise<void> | void
}

const OpenedBulkModal = <T,>({
    type,
    title,
    subtitle,
    infoBody,
    confirm,

    maxWidth = 'sm',

    selected,
    load,

    onRenderLine: Render = Typography,
    onRenderLineContent: RenderContent,

    onCancel,
    onConfirm,
    showOnlyOnError,
}: PropsWithChildren<Omit<AllBulkModalProps<T>, 'resourceSelector'>>): ReturnType<FC> => {
    const [onClick] = useAsyncCallback(() => Promise.resolve(onConfirm()), [onConfirm])

    if (showOnlyOnError && selected.every(showOnlyOnError) && !ConnectwareError.is(load)) {
        return null
    }

    return (
        <Dialog id={`bulk-modal-${type}`} disableEscapeKeyDown maxWidth={maxWidth} fullWidth open>
            {load === true && <LinearProgress variant="indeterminate" sx={progressStyle} />}
            <DialogTitle id={`bulk-modal-${type}-title`} sx={dialogTitleStyle}>
                <DetailsHeader
                    title={{ title: <FormattedTranslation id={title} values={{ count: selected.length }} /> }}
                    section={infoBody && { title: subtitle, body: infoBody }}
                />
            </DialogTitle>
            <DialogContent id={`bulk-modal-${type}-content`} sx={dialogContentStyle}>
                {ConnectwareError.is(load) && <ErrorMessage error={load} titleVariant="subtitle1" extras="popover" />}
                {!infoBody && (
                    <Box sx={dialogEntryStyle}>
                        <FormattedTranslation
                            id={subtitle}
                            values={{
                                count: selected.length,
                                br: <br />,
                                p: createFormatter<TypographyProps>(Typography, { variant: 'body1', color: 'textSecondary' }),
                            }}
                        />
                    </Box>
                )}
                <>
                    {selected.map((resource, k) => (
                        <Render key={k}>{typeof RenderContent === 'string' ? <>{resource[RenderContent]}</> : <RenderContent resource={resource} />}</Render>
                    ))}
                </>
            </DialogContent>
            <DialogActions sx={dialogActionsStyle}>
                <Button id={`bulk-modal-${type}-confirmation`} color="primary" onClick={onClick} disabled={load === true} variant="contained">
                    <FormattedTranslation id={confirm} />
                </Button>
                <Button id={`bulk-modal-${type}-cancellation`} color="primary" onClick={onCancel}>
                    <FormattedTranslation id={Translation.CANCEL} />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

type BulkModalProps<T,> = Omit<AllBulkModalProps<T>, 'selected' | 'load'>

type HooksProps<T,> = Pick<AllBulkModalProps<T>, 'load' | 'selected'> & Readonly<{ shouldCancel: boolean }>

const areHooksPropsEquals = createEqualityChecker<HooksProps<unknown>>({
    selected: (a, b) => Array.isArray(a) && Array.isArray(b) && areArrayEquals(a, b, { sort: false }),
    load: null,
    shouldCancel: null,
})

const BulkModal = <T,>({ resourceSelector, ...props }: PropsWithChildren<BulkModalProps<T>>): ReturnType<FC> => {
    const { dimension, type, onCancel } = props

    const { selected, load, shouldCancel } = useAppState<HooksProps<T>>(
        (s) => {
            const { ids, load } = selectResourcesByDimensionAndAction(s, dimension, type)

            const selected = resourceSelector(s, ids ?? [])

            return {
                selected,
                load,
                /** There is a mismatch and nothing is being loaded right now, then close the modal  */
                shouldCancel: load === false && (ids?.length ?? 0) !== selected.length,
            }
        },
        [dimension, type, resourceSelector],
        areHooksPropsEquals
    )

    useEffect(() => {
        if (shouldCancel) {
            onCancel()
        }
    }, [shouldCancel])

    return selected.length ? <OpenedBulkModal<T> {...props} selected={selected} load={load} /> : null
}

export const DisableResourceModal = <T,>(props: Omit<BulkModalProps<T>, 'type'>): ReturnType<FC> => <BulkModal type="disable" {...props} />
export const EnableResourceModal = <T,>(props: Omit<BulkModalProps<T>, 'type'>): ReturnType<FC> => <BulkModal type="enable" {...props} />
export const ReenableResourceModal = <T,>(props: Omit<BulkModalProps<T>, 'type'>): ReturnType<FC> => <BulkModal type="reenable" {...props} />
export const DeleteResourceModal = <T,>(props: Omit<BulkModalProps<T>, 'type'>): ReturnType<FC> => <BulkModal type="delete" {...props} />
