import React, { type FC } from 'react'

import { canServiceBeDisabled, canServiceBeEnabled, Capability, type CybusDetailedService, doesServiceNeedAuthorization, Translation } from '../../../../domain'

import { useAsyncCallback } from '../../Callback'
import { useAppUsecases } from '../../State'
import { useTranslator } from '../../Internationalization'
import { useStatusTranslator } from '../../common'
import { DetailsActionButton } from '../../Details'

export const HeaderActions: FC<{ resource: CybusDetailedService }> = ({ resource: service }) => {
    const translation = useTranslator()
    const translateStatus = useStatusTranslator()

    const { bulkDisableServicesUsecase, bulkDeleteServicesUsecase, bulkEnableServiceUsecase, downloadServiceUsecase, updateServiceUsecase } = useAppUsecases()
    const [startUpdate, updateLoading] = useAsyncCallback(() => updateServiceUsecase.startUpdate(service), [updateServiceUsecase, service])

    const isAuthorizationRequired = doesServiceNeedAuthorization(service)

    const handleServiceEnabledClick = (): void => {
        bulkEnableServiceUsecase.selectServices([service.id])
        if (!isAuthorizationRequired) {
            void bulkEnableServiceUsecase.enable()
        }
    }

    return (
        <>
            {canServiceBeEnabled(service) ? (
                <DetailsActionButton
                    id="enableService"
                    translation={Translation.ENABLE}
                    capabilities={[Capability.SERVICES_MANAGE]}
                    onClick={handleServiceEnabledClick}
                />
            ) : canServiceBeDisabled(service) ? (
                <DetailsActionButton
                    id="disableService"
                    translation={Translation.DISABLE}
                    capabilities={[Capability.SERVICES_MANAGE]}
                    onClick={() => bulkDisableServicesUsecase.selectServices([service.id])}
                />
            ) : (
                <DetailsActionButton id="serviceStatus" disabled>
                    {translateStatus(service.status)}
                </DetailsActionButton>
            )}
            <DetailsActionButton
                id="deleteService"
                translation={Translation.DELETE}
                capabilities={[Capability.SERVICES_MANAGE]}
                onClick={() => bulkDeleteServicesUsecase.selectServices([service.id])}
            />
            <DetailsActionButton
                id="update-or-reconfigure"
                translation={Translation.UPDATE}
                capabilities={[Capability.SERVICES_MANAGE]}
                disabled={updateLoading}
                loading={updateLoading}
                onClick={startUpdate}
            />
            {!service.catalog && (
                <DetailsActionButton
                    id="downloadServiceCommissioningFile"
                    tooltip={Translation.DOWNLOAD_COMMISSIONING_FILE_DESCRIPTION}
                    translation={translation.formatTranslation(Translation.DOWNLOAD, { count: 1 })}
                    capabilities={[Capability.SERVICES_MANAGE]}
                    onClick={() => downloadServiceUsecase.invoke(service)}
                />
            )}
        </>
    )
}
