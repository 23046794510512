import React, { type FC } from 'react'

import { Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'

export const Broker: FC<{ externalBroker: string | null }> = ({ externalBroker }) => (
    <>
        <FormattedTranslation id={externalBroker === null ? Translation.INTERNAL : Translation.EXTERNAL} />
        {externalBroker && ` (${externalBroker})`}
    </>
)
