import { createExtendedState } from 'react-extended-state'

type TableState = Readonly<{ clearSelection: boolean, search: string }>

const {
    Provider: TableStateProvider,
    useExtendedState: useTableState,
    useExtendedStateDispatcher: useDispatchTableState,
} = createExtendedState<TableState>({ ignorePropsChanges: true })

export const initialTableState: TableState = { clearSelection: false, search: '' }

export { TableStateProvider, useTableState, useDispatchTableState }
