import { type MfaSettings, MfaSettingsState, type MfaUpdateCredentials } from '../../../domain'
import { initialOtp } from '../..'
import { ChangeEnabledMfaUsecase } from './ChangeEnabled'

export class RegenerateMfaBackupCodesUsecase extends ChangeEnabledMfaUsecase {
    protected readonly initialSettings = {
        state: MfaSettingsState.REGENERATING_BACKUP_CODES,
        error: null,
        otp: initialOtp,
        backupCode: null,
    } as const

    protected async execute (input: MfaUpdateCredentials): Promise<MfaSettings> {
        return { state: MfaSettingsState.REGENERATED_BACKUP_CODES, backupCodes: await this.mfaService.regenerateBackupCodes(input) }
    }
}
