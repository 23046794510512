import { createNamedEqualityChecker } from '../../utils'
import { createPageSelector, type Page, StatusType } from '..'

export type CybusAgent = Readonly<{ name: string, version: string, status: StatusType, hostname: string | null, containerId: string | null }>

export const areAgentEquals = createNamedEqualityChecker<CybusAgent>(
    { containerId: null, hostname: null, name: null, status: null, version: null },
    'areAgentEquals'
)

export const canAgentBeDeleted = (agent: Pick<CybusAgent, 'status'>): boolean => agent.status === StatusType.OFFLINE

export type AgentsAppState = Readonly<{ agentsPage: Page<CybusAgent> }>

export const selectAgentsPage = (s: AgentsAppState): AgentsAppState['agentsPage'] => s.agentsPage

export const selectDeletableLoadedAgents = createPageSelector(selectAgentsPage, 'name', 'selectDeletableLoadedAgents', canAgentBeDeleted)
