import { Grid } from '@mui/material'
import React, { type FC } from 'react'

import { type CybusServer, Translation } from '../../../../domain'
import { Status } from '../../common'
import { DetailsEntry } from '../../Details'
import { useTranslator } from '../../Internationalization'
import { ServiceLinkEntry } from '../../Services'

export const HeaderDetails: FC<{ resource: CybusServer }> = ({ resource: { service, status, protocol, name } }) => {
    const translation = useTranslator()

    return (
        <Grid id="server-header-description" item xs={7}>
            <DetailsEntry title={Translation.NAME} type="text">
                {name}
            </DetailsEntry>
            <ServiceLinkEntry service={service} />
            <DetailsEntry title={translation.formatTranslation(Translation.PROTOCOL, { count: 1 })} type="text">
                {protocol}
            </DetailsEntry>
            <DetailsEntry title={Translation.STATUS}>
                <Status status={status} />
            </DetailsEntry>
        </Grid>
    )
}
