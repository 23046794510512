export enum CybusLogLevel {
    DEBUG = 'DEBUG',
    ERROR = 'ERROR',
    FATAL = 'FATAL',
    INFO = 'INFO',
    TRACE = 'TRACE',
    WARN = 'WARN',
}

export type CybusLog = Readonly<{
    level: CybusLogLevel
    className: string | null
    timestamp: Date
    message: string
    pid: number | null
    hostname: string | null
    serviceId: string | null
}>

export enum CybusLogSource {
    CONNECTION = 'CONNECTION',
    CORE_CONTAINER = 'CORE_CONTAINER',
    ENDPOINT = 'ENDPOINT',
    MAPPING = 'MAPPING',
    SERVER = 'SERVER',
    SERVICE = 'SERVICE',
    SERVICE_CONTAINER = 'SERVICE_CONTAINER',
    VOLUME = 'VOLUME',
}
