import { type NonEmptyArray } from '../../../utils'
import { type ConnectwareError } from '../../../domain'

import { Usecase } from '../Usecase'

export class SystemMaintenanceUsecase extends Usecase {
    async loadMaintenanceStatus (): Promise<void> {
        try {
            this.setState({ backupMaintenance: await this.systemService.fetchBackupStatus() })
        } catch (e) {
            this.setState({ backupMaintenance: e as ConnectwareError })
        }
    }

    async createBackup (): Promise<void> {
        await this.systemService.createBackup()
        await this.loadMaintenanceStatus()
    }

    getSupportedRestoreBackupFileExtensions (): NonEmptyArray<string> {
        return this.configurationService.getSupportedRestoreBackupFileExtensions()
    }

    async downloadBackup (): Promise<void> {
        const file = await this.systemService.downloadBackup()
        this.fileService.download(file)
    }

    async uploadBackup (fileContent: File): Promise<void> {
        await this.systemService.uploadBackup(fileContent)
        await this.loadMaintenanceStatus()
    }
}
