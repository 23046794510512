export class ExtendedMap<K, V> extends Map<K, V> {
    /**
     * Same has Map.get but with default value functionality
     * @see {Map.get}
     */
    getDefault (key: K, defaultValue: V): V {
        return this.has(key) ? (this.get(key) as V) : defaultValue
    }

    /**
     * Short hand for converting map values to an array
     */
    toValuesArray (): V[] {
        return Array.from(this.values())
    }
}
