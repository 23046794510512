import type { ConnectwareError, CybusPersistedPermission, CybusRole, CybusUser, EditableCybusPermission, EditableCybusPermissionInheritance } from '..'
import type { Loadable } from '../Loadable'

export enum UserManagementEditMode {
    ALL = 'ALL',
    MFA_ONLY = 'MFA_ONLY',
    NONE = 'NONE',
}

export type PermissionedEntity<Mode> = Readonly<{
    /**
     * The unique identifier of the permissioned entity
     */
    id: string

    /**
     * The permissions associated with the entity
     */
    permissions: CybusPersistedPermission[]

    /**
     * Protected entities cannot have their name altered or deleted
     */
    isProtected: boolean

    /**
     * If the entity is editable
     */
    editMode: Mode
}>

export type PermissionedForm = Readonly<{
    /** The template data */
    templateInput: string | null
    selectedTemplate: CybusRole | CybusUser | null
    templates: Loadable<(CybusRole | CybusUser)[]>

    /** The user specific permissions */
    permissions: EditableCybusPermission[]
}>

export type UserForm = Pick<CybusUser, 'username' | 'mqttPublishPrefix'> &
    Readonly<{
        /** The role dropdown */
        roleInput: string | null
        roles: CybusRole[]
        allRoles: Loadable<CybusRole[]>

        /** The permissions from the selected roles */
        rolesPermissions: EditableCybusPermissionInheritance[]

        /**
         * @todo replace this with FieldValidation
         * @see {FieldValidation}
         */
        mqttPublishPrefixValidation: ConnectwareError | null
    }> &
    PermissionedForm
