import React, { type FC, Fragment, type PropsWithChildren, type ReactElement } from 'react'
import { Route as BaseRoute, Switch as BaseSwitch, Redirect } from 'react-router-dom'

import { isEnumOf } from '../../../../utils'

import {
    AbsoluteRouteOnlyPath,
    type AbsoluteRoutePath,
    type AbsoluteRoutePathWithId,
    type AbsoluteRoutePathWithServiceAndResourceId,
    type AbsoluteRoutePathWithServiceId,
} from '../Domain'
import { getRouteInternalPath } from '../Config'
import { ConnectwareRoute, ConnectwareRouter } from './InternalRouter'

type RouteProps = PropsWithChildren<
    Readonly<
        | { path: AbsoluteRoutePath }
        | { paths: AbsoluteRouteOnlyPath[] | (AbsoluteRoutePathWithServiceId | AbsoluteRoutePathWithId | AbsoluteRoutePathWithServiceAndResourceId)[] }
    >
>

type AllProps = Readonly<{
    /**
     * Navigations components to be rendered (through injection) inside the router
     */
    appNavigation?: FC
    routeNavigation?: FC

    /**
     * Where the application will start
     */
    initial: AbsoluteRouteOnlyPath

    /**
     * In case you get redirected elsewhere
     */
    onNotFound?: AbsoluteRouteOnlyPath

    /**
     * Only expect routes as children
     */
    children: ReactElement<RouteProps>[] | ReactElement<RouteProps>
}>

export const Route: FC<RouteProps> = () => <Fragment />

type SwitchProps = Omit<AllProps, 'appNavigation' | 'router' | 'initial'>

export const Switch: FC<SwitchProps> = ({ routeNavigation: RouteNavigation = Fragment, onNotFound, children }: SwitchProps) => (
    <BaseSwitch>
        {(Array.isArray(children) ? children : [children]).map((child, k) =>
            child && child.type === Route ? (
                /**
                 * Don't try to move this to another place
                 * The ('react-router-dom').Route needs to be exposed directly to the Switch
                 */
                <BaseRoute
                    key={k}
                    path={'paths' in child.props ? child.props.paths.map((p) => getRouteInternalPath(p)) : getRouteInternalPath(child.props.path)}
                    exact={
                        'paths' in child.props
                            ? child.props.paths.some((p) => isEnumOf(AbsoluteRouteOnlyPath, p))
                            : isEnumOf(AbsoluteRouteOnlyPath, child.props.path)
                    }
                    render={({ match }) => (
                        <RouteNavigation>
                            <ConnectwareRoute
                                path={
                                    'paths' in child.props
                                        ? ((child.props.paths as AbsoluteRoutePath[]).find((p) => getRouteInternalPath(p) === match.path) as AbsoluteRoutePath)
                                        : child.props.path
                                }
                            >
                                {child.props.children}
                            </ConnectwareRoute>
                        </RouteNavigation>
                    )}
                />
            ) : (
                <Fragment key={k}>{child}</Fragment>
            )
        )}
        {/* Fallback */}
        {onNotFound && <Redirect to={getRouteInternalPath(onNotFound)}>{children}</Redirect>}
    </BaseSwitch>
)

export const CybusRouter: FC<AllProps> = ({ appNavigation: AppNavigation = Fragment, ...props }) => (
    <ConnectwareRouter initial={props.initial}>
        <AppNavigation>
            <Switch {...props} />
        </AppNavigation>
    </ConnectwareRouter>
)
