"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.throwCaptured = void 0;
/**
 * Captures error so it appears to be yielded from the given function
 *
 * @param error Error to be thrown
 * @param func fake error origin
 */
var throwCaptured = function (error, func) {
    try {
        throw error;
    }
    catch (e) {
        Error.captureStackTrace(e, func);
        throw e;
    }
};
exports.throwCaptured = throwCaptured;
