import React, { type FC, useMemo } from 'react'
import { Box, CircularProgress, Grid, Link, type SxProps, type Theme, Typography, type TypographyProps } from '@mui/material'

import { ifUnresolved, usePromise } from 'react-sync-promise'

import type { NullableValues } from '../../../../utils'
import {
    ConnectwareError,
    ConnectwareErrorType,
    type ConnectwareLicense,
    type EndpointConnectivity,
    selectAuthenticatedCapabilities,
    Translation,
} from '../../../../domain'

import { DocumentationType } from '../../../../application'

import { boldFormatter, createFormatter, FormattedDateTime, FormattedList, FormattedTranslation } from '../../Internationalization'
import { useAppState, useAppUsecase } from '../../State'
import { useDocumentation } from '../../Documentation'

import { useCanUpdateLicense } from '../Hooks'
import { SalesEmailLink, SupportEmailLink } from '../../Contact'
import { RefreshLicenseButton, UploadLicenseFileButton } from '../Buttons'

const loaderStyle: SxProps<Theme> = { textAlign: 'center' }
const contentStyle: SxProps<Theme> = { py: 1 }
const leftColumnContentStyle: SxProps<Theme> = { p: 1, pl: 0 }
const rightColumnsContentStyle: SxProps<Theme> = { p: 1, pr: 0 }
const buttonStyle: SxProps<Theme> = { textAlign: 'center', py: 1 }
const helpFooterStyle: SxProps<Theme> = { pt: 1 }

const formatting = {
    p: createFormatter<TypographyProps>(Typography, { variant: 'body1', className: 'license-modal-paragraph' }),
    b: boldFormatter,
}

const useIsPortalReachable = (): NullableValues<EndpointConnectivity> | null => {
    const license = useAppUsecase('licenseViewUsecase')

    const promise = useMemo(() => license.fetchPortalConnectivity(), [license])
    return ifUnresolved(usePromise(promise), null)
}

const ErrorMessage: FC<{ readonly error: ConnectwareError }> = ({ error }) => {
    const reason = ConnectwareError.isOfTypes(error, ConnectwareErrorType.AUTHENTICATION) ? 'permissions' : null
    const permissions = useAppState(selectAuthenticatedCapabilities)

    return (
        <>
            <FormattedTranslation id={Translation.LICENSE_VALIDATION_ERROR} values={{ reason, ...formatting }} />
            <FormattedTranslation
                id={Translation.PLEASE_CONTACT_YOUR_CYBUS_ADMINISTRATOR}
                values={{
                    reason,
                    permissions: permissions && permissions.length ? <FormattedList value={permissions} /> : <FormattedTranslation id={Translation.NONE} />,
                    ...formatting,
                }}
            />
        </>
    )
}

const OfflineCheck: FC = () => {
    const connectivity = useIsPortalReachable()
    const openOffline = useDocumentation(DocumentationType.LICENSE_OFFLINE_ACTIVATION)
    const offlineActivationLink = createFormatter(Link, { className: 'offline-activation-link', onClick: openOffline })

    if (!connectivity) {
        return (
            <Grid container columnSpacing={2} alignItems={'center'}>
                <Grid item>
                    <CircularProgress size={30} />
                </Grid>
                <Grid item>
                    <FormattedTranslation id={Translation.LICENSE_ONLINE_AVAILABILITY_CHECK} values={{ ...formatting }} />
                </Grid>
            </Grid>
        )
    }
    if (connectivity && connectivity.reachable) {
        return (
            <>
                <FormattedTranslation id={Translation.LICENSE_EXPIRED_EXTENSION_NEEDED} values={{ ...formatting }} />
                <Box sx={buttonStyle}>
                    <RefreshLicenseButton />
                </Box>
            </>
        )
    }
    return (
        <>
            <FormattedTranslation id={Translation.LICENSE_OFFLINE_ACTIVATION} values={{ offlineActivationLink, ...formatting }} />

            <Box sx={buttonStyle}>
                <UploadLicenseFileButton />
            </Box>
        </>
    )
}

export const Content: FC<{ readonly license: ConnectwareLicense | ConnectwareError | null }> = ({ license }) => {
    const canUpdateLicense = useCanUpdateLicense()

    const openLicensing = useDocumentation(DocumentationType.LICENSING)
    const openOffline = useDocumentation(DocumentationType.LICENSE_OFFLINE_ACTIVATION)
    if (license === null) {
        return (
            <Box sx={loaderStyle}>
                <CircularProgress />
            </Box>
        )
    }

    if (ConnectwareError.is(license)) {
        return <ErrorMessage error={license} />
    }

    const { name, id, activated, expiration } = license
    const documentationUrl = createFormatter(Link, { className: 'licensing-link', onClick: openLicensing })
    const offlineActivationLink = createFormatter(Link, { className: 'offline-activation-link', onClick: openOffline })
    const supportEmail = <SupportEmailLink />
    const salesEmailLink = <SalesEmailLink />

    return (
        <>
            <FormattedTranslation
                id={Translation.LICENSE_ERROR_SUMMARY}
                values={{
                    name,
                    id,
                    activatable: !activated && canUpdateLicense,
                    activated,
                    expiration: expiration && <FormattedDateTime date={expiration} format="dateOnly" />,
                    ...formatting,
                }}
            />

            {canUpdateLicense ? (
                <>
                    {license.activated ? (
                        <Box sx={contentStyle}>
                            <FormattedTranslation id={Translation.LICENSE_EXPIRED_CONTACT_SALES} values={{ salesEmailLink, ...formatting }} />
                            <OfflineCheck />
                        </Box>
                    ) : (
                        <Grid container>
                            <Grid item xs={6}>
                                <Box sx={leftColumnContentStyle}>
                                    <FormattedTranslation id={Translation.LICENSE_ACTIVATION_TITLE} values={{ type: 'online', ...formatting }} />
                                    <FormattedTranslation id={Translation.LICENSE_ONLINE_ACTIVATION} values={{ salesEmailLink, ...formatting }} />
                                </Box>
                            </Grid>

                            <Grid item xs={6}>
                                <Box sx={rightColumnsContentStyle}>
                                    <FormattedTranslation id={Translation.LICENSE_ACTIVATION_TITLE} values={{ type: 'offline', ...formatting }} />
                                    <FormattedTranslation id={Translation.LICENSE_OFFLINE_ACTIVATION} values={{ offlineActivationLink, ...formatting }} />
                                </Box>
                            </Grid>

                            <Grid item xs={6} sx={buttonStyle}>
                                <RefreshLicenseButton />
                            </Grid>

                            <Grid item xs={6} sx={buttonStyle}>
                                <UploadLicenseFileButton />
                            </Grid>
                        </Grid>
                    )}
                    <Box sx={helpFooterStyle}>
                        <FormattedTranslation id={Translation.LICENSE_MODAL_FOOTER} values={{ documentationUrl, supportEmail, ...formatting }} />
                    </Box>
                </>
            ) : (
                <FormattedTranslation id={Translation.PLEASE_CONTACT_YOUR_CYBUS_ADMINISTRATOR} values={{ reason: 'license', ...formatting }} />
            )}
        </>
    )
}
