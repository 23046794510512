import { isEnumOf } from '../../../../utils'

import {
    ConnectwareError,
    ConnectwareErrorType,
    type CybusCatalogApplication,
    CybusCatalogApplicationCategory,
    selectServiceCatalogTemplate,
} from '../../../../domain'

import { BaseCatalogUsecase } from './Base'

export class DownloadServiceCatalogTemplateUsecase extends BaseCatalogUsecase {
    close (): void {
        this.setCatalog({ template: null })
    }

    selectCategory (template: CybusCatalogApplicationCategory | null): void {
        this.setCatalog({ template })
    }

    selectTemplate (newTemplate: CybusCatalogApplication): void {
        const template = selectServiceCatalogTemplate(this.getState())
        this.setCatalog({ template: newTemplate === template ? newTemplate.category : newTemplate })
    }

    async download (): Promise<void> {
        const template = selectServiceCatalogTemplate(this.getState())

        if (!template || isEnumOf(CybusCatalogApplicationCategory, template)) {
            throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'User should not be able to attempt to download template')
        }

        const file = await this.connectwareServicesCatalogService.fetchCommissioningFile(template)
        this.fileService.download(file)
    }
}
