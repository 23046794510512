import React, { type FC, useEffect, useMemo } from 'react'
import { isPending, isRejected, usePromise } from 'react-sync-promise'
import { FormattedNumber } from 'react-intl'
import { useSnackbar } from 'notistack'
import { Box, Button, CircularProgress } from '@mui/material'
import { Send } from '@mui/icons-material'

import {
    Capability,
    ConnectwareError,
    createIsAuthenticatedWithCapabilitiesSelector,
    type Metrics as MetricsModel,
    selectLicenseId,
    Translation,
} from '../../../domain'

import { FormattedDateTime, FormattedTranslation, useTranslator } from '../Internationalization'
import { useAppState, useAppUsecase } from '../State'
import { useAsyncCallback } from '../Callback'
import { CircularLoader, DetailsHeader, Table } from '../common'
import { ErrorMessage } from '../ErrorMessage'
import { Tab, Tabs } from '../Tabs'

const selectCanManageMetrics = createIsAuthenticatedWithCapabilitiesSelector(Capability.SYSTEM_METRICS_MANAGE)

const dateSort = (t: Date): number => t.getTime()

export const Metrics: FC = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const translator = useTranslator()
    const systemMetricsUsecase = useAppUsecase('systemMetricsUsecase')

    const licenseID = useAppState(selectLicenseId)
    const isAuthenticated = useAppState(selectCanManageMetrics)

    const metrics = usePromise<MetricsModel, ConnectwareError>(useMemo(() => systemMetricsUsecase.fetchMetrics(), [systemMetricsUsecase]))
    const [sendMetricsToPortal, isLoading, result] = useAsyncCallback(() => systemMetricsUsecase.sendMetricsToPortal(), [systemMetricsUsecase])

    const sentResponse = isLoading || result === null ? false : result

    useEffect(() => {
        if (sentResponse === false) {
            /** Nothing has happened yet */
            return
        }

        const key = enqueueSnackbar(
            <Box data-testid="metrics-sent-response">
                {ConnectwareError.is(result) ? result.message : <FormattedTranslation id={Translation.SUCCESSFULLY_SENT_METRICS_TO_PORTAL} />}
            </Box>,
            { autoHideDuration: 5_000, anchorOrigin: { horizontal: 'right', vertical: 'bottom' }, variant: ConnectwareError.is(result) ? 'error' : 'success' }
        )

        return () => closeSnackbar(key)
    }, [sentResponse])

    if (isPending(metrics)) {
        return <CircularLoader />
    }

    if (isRejected(metrics)) {
        return <ErrorMessage error={metrics.value} stack extras="section" />
    }

    return (
        <>
            <DetailsHeader
                title={{
                    title: <FormattedTranslation id={Translation.METRICS_PAGE_TITLE} />,
                    actions: metrics.value.isPortalReachable && (
                        <Button
                            id="sendMetricsButton"
                            color="primary"
                            onClick={sendMetricsToPortal}
                            disabled={!isAuthenticated || isLoading}
                            startIcon={isLoading ? <CircularProgress size="1rem" /> : <Send />}
                        >
                            <FormattedTranslation id={Translation.SEND_METRICS_TO_PORTAL} />
                        </Button>
                    ),
                }}
                section={{ title: Translation.METRICS_DOCUMENTATION_TITLE, body: Translation.METRICS_DOCUMENTATION_BODY }}
            />
            <Tabs>
                <Tab title={Translation.MESSAGE_PER_HOUR}>
                    <Table
                        title={translator.formatTranslation(Translation.COLLECTED_HOURLY_METRICS_TITLE)}
                        columns={{
                            timestamp: {
                                label: Translation.TIME,
                                customCellRender: (date) => <FormattedDateTime date={date} format="datetime" />,
                                sort: dateSort,
                            },
                            numberOfConnectedDatapoints: { label: Translation.NUMBER_OF_DATAPOINTS, sort: true },
                            numberOfReceivedMessages: {
                                label: Translation.NUMBER_OF_MESSAGES_PER_HOUR,
                                customCellRender: (value) => <FormattedNumber value={value} style="decimal" />,
                                sort: true,
                            },
                        }}
                        initialSortOrder={{ name: 'timestamp', direction: 'desc' }}
                        initialPagination={{ selected: 15, options: [15, 50, 100] }}
                        download
                        data={metrics.value.hourly}
                        translations={{ noResultsOrEmptyTable: Translation.NO_METRICS }}
                        initialSearch
                    />
                </Tab>

                <Tab title={Translation.MESSAGE_PER_DAY}>
                    <Table
                        title={translator.formatTranslation(Translation.COLLECTED_DAILY_METRICS_TITLE)}
                        columns={{
                            licenseID: { label: Translation.LICENSE_ID, display: false },
                            date: {
                                label: Translation.DATE,
                                customCellRender: (date) => <FormattedDateTime date={date} format="dateOnly" />,
                                sort: dateSort,
                            },
                            numberOfConnectedDatapoints: { label: Translation.NUMBER_OF_DATAPOINTS, sort: true },
                            numberOfReceivedMessages: {
                                label: Translation.NUMBER_OF_MESSAGES_PER_DAY,
                                customCellRender: (value) => <FormattedNumber value={value} style="decimal" />,
                                sort: true,
                            },
                            encrypted: { label: Translation.ENCRYPTED, display: false },
                        }}
                        initialSortOrder={{ name: 'date', direction: 'desc' }}
                        initialPagination={{ selected: 15, options: [15, 50, 100] }}
                        download
                        data={metrics.value.daily.map((d) => ({ licenseID, ...d }))}
                        translations={{ noResultsOrEmptyTable: Translation.NO_METRICS }}
                        initialSearch
                    />
                </Tab>
            </Tabs>
        </>
    )
}
