import { createExtendedState } from 'react-extended-state'
import type { PickByValue } from 'utility-types'

import type { NonEmptyArray } from '../../../../utils'

import { type CybusServiceForm, type Translation } from '../../../../domain'

import type { Usecases } from '../../../../application'

/**
 * Creation can happen in two modes
 * Update and Creation
 *
 * This config object facilitates communication between the react components
 */
export type ModalConfig = {
    /**
     * UI Config
     */
    readonly title: Translation
    readonly configurationTitle: Translation
    readonly confirmationButtonText: Translation

    /**
     * The targeted usecase
     */
    readonly usecase: keyof PickByValue<
        Usecases,
        {
            getSupportedCommissioningFileTypes(): NonEmptyArray<string>

            /**
             * Called when the file changes
             */
            updateCommissioningFile(file: CybusServiceForm['file']): void

            /**
             * Returns if the service being manipulated is being created or updated
             */
            isCreating(): boolean

            /**
             * Called when user cancels the process
             */
            cancel(): void

            /**
             * Called when user confirms the process
             */
            confirm(): Promise<string | null>

            /**
             * When the input parameters change
             */
            updateServiceParameters(id: CybusServiceForm['id'], newParameters: Partial<CybusServiceForm['parameters']>): void

            /**
             * Called when the validity of the given form parameters need to be checked
             */
            isConfirmationRequestValid(): boolean
        }
    >

    readonly onConfirmed?: (id: string) => void
}

const { Provider: ModalConfigProvider, useExtendedState } = createExtendedState<ModalConfig>({ ignorePropsChanges: true })

export const useModalConfig = <K extends keyof ModalConfig>(k: K): ModalConfig[K] => useExtendedState((s) => s[k])

export { ModalConfigProvider }
