import React, { type FC } from 'react'
import type { PickByValue } from 'utility-types'

import {
    canContainerBeDeleted,
    canContainerBeRestarted,
    canContainerBeStarted,
    canContainerBeStopped,
    type Capability,
    type CybusDetailedCoreContainer,
    type CybusDetailedServiceContainer,
    Translation,
} from '../../../../domain'

import {
    type BulkDeleteServiceContainersUsecase,
    type BulkRestartCoreContainersUsecase,
    type BulkRestartServiceContainersUsecase,
    type BulkStartCoreContainersUsecase,
    type BulkStartServiceContainersUsecase,
    type BulkStopCoreContainersUsecase,
    type BulkStopServiceContainersUsecase,
    type Usecases,
} from '../../../../application'

import { useAppUsecases } from '../../State'

import { DetailsActionButton } from '../../Details'

type ContainerUsecaseName = keyof PickByValue<
    Usecases,
    | BulkDeleteServiceContainersUsecase
    | BulkRestartServiceContainersUsecase
    | BulkStartServiceContainersUsecase
    | BulkStopServiceContainersUsecase
    | BulkRestartCoreContainersUsecase
    | BulkStartCoreContainersUsecase
    | BulkStopCoreContainersUsecase
>

export const HeaderActions: FC<{
    resource: CybusDetailedServiceContainer | CybusDetailedCoreContainer
    managementCapability: Capability
    deleteUsecaseName?: ContainerUsecaseName
    startUsecaseName: ContainerUsecaseName
    stopUsecaseName: ContainerUsecaseName
    restartUsecaseName: ContainerUsecaseName
}> = ({ resource, managementCapability, deleteUsecaseName, restartUsecaseName, stopUsecaseName, startUsecaseName }) => {
    const usecases = useAppUsecases()

    return (
        <>
            {canContainerBeStarted(resource) && (
                <DetailsActionButton
                    id="start-container-button"
                    translation={Translation.START}
                    capabilities={[managementCapability]}
                    onClick={() => usecases[startUsecaseName].selectContainers([resource.id])}
                />
            )}
            {canContainerBeStopped(resource) && (
                <DetailsActionButton
                    id="stop-container-button"
                    translation={Translation.STOP}
                    capabilities={[managementCapability]}
                    onClick={() => usecases[stopUsecaseName].selectContainers([resource.id])}
                />
            )}

            <DetailsActionButton
                id="restart-container-button"
                translation={Translation.RESTART}
                disabled={!canContainerBeRestarted(resource)}
                capabilities={[managementCapability]}
                onClick={() => usecases[restartUsecaseName].selectContainers([resource.id])}
            />

            {deleteUsecaseName && (
                <DetailsActionButton
                    id="delete-container-button"
                    translation={Translation.DELETE}
                    disabled={!canContainerBeDeleted(resource)}
                    capabilities={[managementCapability]}
                    onClick={() => usecases[deleteUsecaseName].selectContainers([resource.id])}
                />
            )}
        </>
    )
}
