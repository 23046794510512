import { Grid } from '@mui/material'
import React, { type FC } from 'react'

import { type CybusDetailedCoreContainer, type CybusDetailedServiceContainer, Translation } from '../../../../domain'
import { Status } from '../../common'
import { DetailsEntry } from '../../Details'
import { FormattedDateTime, FormattedTranslation } from '../../Internationalization'
import { ServiceLinkEntry } from '../../Services'
import { VolumeLinksEntry } from '../../Volumes'

export const HeaderDetails: FC<{ resource: CybusDetailedServiceContainer | CybusDetailedCoreContainer, linkVolumes: boolean }> = ({
    resource: { name, created, status, volumes },
    resource,
    linkVolumes,
}) => {
    return (
        <Grid container alignItems="center">
            <Grid id="container-header-description" item xs={7}>
                <DetailsEntry title={Translation.NAME} type="text">
                    {name}
                </DetailsEntry>
                {'service' in resource && <ServiceLinkEntry service={resource.service} />}
                <VolumeLinksEntry volumes={volumes} linkVolumes={linkVolumes} />
                <DetailsEntry title={Translation.CREATED_AT}>
                    {created ? <FormattedDateTime date={created} format="datetime" /> : <FormattedTranslation id={Translation.NOT_AVAILABLE} />}
                </DetailsEntry>
                <DetailsEntry title={Translation.STATUS}>
                    <Status status={status} />
                </DetailsEntry>
            </Grid>
        </Grid>
    )
}
