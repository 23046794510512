import React, { type ChangeEvent, createRef, type FC } from 'react'
import { Box, Button, LinearProgress, type SxProps, type Theme } from '@mui/material'
import { Add as AddIcon, Warning } from '@mui/icons-material'

import { ConnectwareError, Translation } from '../../../domain'

import { createClickerHandler, readFirstFileAsText } from '../utils'
import { useAsyncCallback } from '../Callback'
import { FormattedTranslation } from '../Internationalization'
import { useAppUsecases } from '../State'

const wrapperStyle: SxProps<Theme> = { display: 'flex' }
const addStyle: SxProps<Theme> = { ml: 'auto' }
const hiddenStyle: SxProps<Theme> = { display: 'none' }

export const Add: FC = () => {
    const ref = createRef<HTMLInputElement>()

    const { addCertificateUsecase } = useAppUsecases()

    const [onChange, loading, value] = useAsyncCallback(
        ({ target }: ChangeEvent<HTMLInputElement>) =>
            readFirstFileAsText(target.files).then((content) => (content ? addCertificateUsecase.add(content) : Promise.resolve())),
        [addCertificateUsecase]
    )

    return (
        <Box sx={wrapperStyle} className="add-certificate">
            <Button
                sx={addStyle}
                size="medium"
                color="primary"
                variant="contained"
                title={ConnectwareError.is(value) ? value.message : undefined}
                startIcon={<AddIcon />}
                endIcon={ConnectwareError.is(value) && <Warning data-testid="new-certificate-error" color="error" />}
                onClick={createClickerHandler(ref)}
            >
                <FormattedTranslation id={Translation.ADD_CERTIFICATE} />
                {loading && <LinearProgress />}
            </Button>
            <Box sx={hiddenStyle}>
                <input type="file" ref={ref} onChange={onChange} />
            </Box>
        </Box>
    )
}
