import type { PickByValueExact } from 'utility-types'

import { Droppable } from '../../../utils'

import type { AppState, ConnectwareError } from '../../../domain'

import type { SingleSubscriptionsTypes, SubscriptionEvent } from '../..'

import { Usecase } from '..'

/**
 * Describes the behaviour of a generic unifinished subscriptuion to a Connectware resource
 * Relies heavily on `subscriptionsService`
 */
export abstract class ResourceSingleSubscriptionUsecase<SingularName extends keyof SingleSubscriptionsTypes> extends Usecase {
    /**
     * The single event name and by reflection,
     * type the implementation wants to subscribe
     */
    protected abstract readonly singularName: SingularName

    /** Where the page should be stored in the state */
    protected abstract readonly singleAddress: keyof PickByValueExact<AppState, SubscriptionEvent<SingularName> | null>

    private setSingleState (event: SubscriptionEvent<SingularName> | null): void {
        this.setState({ [this.singleAddress]: event })
    }

    /**
     * @returns an unsub function, to be called when the state should stop being updated
     */
    subscribe (id: string): VoidFunction {
        const droppable = new Droppable()

        /** Reset state */
        this.setSingleState(null)

        /** Schedule state reset when done */
        droppable.onDrop(() => this.setSingleState(null))

        this.subscriptionsService
            .subscribeToOne(this.singularName, id, (data) => this.setSingleState(data))
            .then((off) => droppable.onDrop(off))
            .catch((e: ConnectwareError) => this.setSingleState(e))

        return () => droppable.drop()
    }
}
