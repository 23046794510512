import React, { type FC } from 'react'

import { type SvgIconProps, type Theme } from '@mui/material'
import { type SystemCssProperties } from '@mui/system'
import { Cancel, CheckCircle, Lens } from '@mui/icons-material'

import { type BaseCommonProps } from '../common/Base'

type Status = 'GREEN' | 'RED'

type Props = Readonly<{ status: Status, onlyCircle?: boolean }> & Pick<BaseCommonProps, 'sx'>

const Icons: Record<Status, FC<SvgIconProps>> = { GREEN: CheckCircle, RED: Cancel }

const statusStyles: Record<Status, SystemCssProperties<Theme>> = {
    GREEN: { color: 'green.500' },
    RED: { color: 'red.500' },
}

const StatusIcon: FC<Props> = ({ status, sx, onlyCircle }) => {
    const Icon = onlyCircle ? Lens : Icons[status]
    return <Icon sx={{ ...statusStyles[status], ...sx }} />
}

export default StatusIcon
