import { Grid } from '@mui/material'
import React, { type FC } from 'react'

import { type CybusDetailedVolume, Translation } from '../../../../domain'
import { Status } from '../../common'
import { DetailsEntry } from '../../Details'
import { FormattedDateTime } from '../../Internationalization'
import { ServiceLinkEntry } from '../../Services'

export const HeaderDetails: FC<{ resource: CybusDetailedVolume }> = ({ resource: { service, name, created, status } }) => (
    <Grid container alignItems="center">
        <Grid id="volume-header-description" item xs={7}>
            <DetailsEntry title={Translation.NAME} type="text">
                {name}
            </DetailsEntry>
            <ServiceLinkEntry service={service} />
            <DetailsEntry title={Translation.CREATED_AT}>
                <FormattedDateTime date={created} format="datetime" />
            </DetailsEntry>
            <DetailsEntry title={Translation.STATUS}>
                <Status status={status} />
            </DetailsEntry>
        </Grid>
    </Grid>
)
