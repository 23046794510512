import React, { type FC } from 'react'

import {
    canAgentBeDeleted,
    Capability,
    createIsAuthenticatedWithCapabilitiesSelector,
    type CybusAgent,
    ResourceType,
    selectAgentsPage,
    selectDeletableLoadedAgents,
    type StatusType,
    Translation,
} from '../../../domain'

import { FormattedTranslation } from '../Internationalization'
import { useAppUsecase } from '../State'
import { Status, useTableClearSelection } from '../common'

import { DeleteResourceModal, ResourcesTable } from '../Resources'

const Modals: FC = () => {
    const bulkDelete = useAppUsecase('bulkDeleteAgentsUsecase')
    const clearSelection = useTableClearSelection()

    return (
        <DeleteResourceModal
            title={Translation.DELETE_AGENTS}
            subtitle={Translation.DELETE_AGENTS_SUBTITLE}
            confirm={Translation.DELETE}
            resourceSelector={selectDeletableLoadedAgents}
            dimension={ResourceType.AGENT}
            onRenderLineContent="name"
            onCancel={() => bulkDelete.selectAgents(null)}
            onConfirm={async () => {
                await bulkDelete.delete()
                clearSelection()
            }}
        />
    )
}

const selectCanManageAgents = createIsAuthenticatedWithCapabilitiesSelector(Capability.AGENTS_MANAGE)

export const Table: FC = () => {
    const customCellRender = (status: StatusType): JSX.Element => <Status status={status} />

    return (
        <ResourcesTable<
            CybusAgent,
            Omit<CybusAgent, 'hostname' | 'containerId'> & Readonly<{ hostnameOrContainer: Pick<CybusAgent, 'hostname' | 'containerId'> }>,
            'bulkDeleteAgentsUsecase'
        >
            subscriptionUsecase="manageAgentsUsecase"
            data={selectAgentsPage}
            hasManagementCapabilitiesSelector={selectCanManageAgents}
            dataTableMapper={(agents) => agents.map(({ hostname, containerId, ...a }) => ({ ...a, hostnameOrContainer: { hostname, containerId } }))}
            columns={{
                name: { label: Translation.NAME, sort: true },
                version: { label: Translation.VERSION, sort: true },
                status: { label: Translation.STATUS, customCellRender, sort: true },
                hostnameOrContainer: {
                    label: Translation.HOSTNAME,
                    customCellRender: ({ hostname, containerId }) =>
                        hostname || (
                            <>
                                {containerId} (<FormattedTranslation id={Translation.CONTAINER_ID} />)
                            </>
                        ),
                },
            }}
            onSelect={{ DELETE: { usecase: 'bulkDeleteAgentsUsecase', filter: canAgentBeDeleted }, resource: 'name', usecaseMethod: 'selectAgents' }}
            title={Translation.AGENTS_PAGE_TITLE}
            translations={{
                resourceName: Translation.AGENT,
                noResultsOrEmptyTable: Translation.NO_AGENTS_RUNNING,
                infoTitle: Translation.AGENT_DOCUMENTATION_TITLE,
                infoBody: Translation.AGENT_DOCUMENTATION_BODY,
            }}
        >
            <Modals />
        </ResourcesTable>
    )
}
