import { type RouterProps, useHistory } from 'react-router-dom'

import { nameFunction } from '../../../../utils'
import type { RouterState } from '../Domain'
import { useConnectwareRouting } from './InternalRouter'

type ClassType<Hooked> = new (history: RouterProps['history'], state: RouterState) => Hooked

export const createRoutingHook = <N extends string, H>(name: N, Clazz: ClassType<H>): (() => H) =>
    nameFunction((): H => new Clazz(useHistory(), useConnectwareRouting()), name)
