import React, { type FC, useState } from 'react'

import { Button, Typography } from '@mui/material'

import { Translation } from '../../../domain'

import { FormattedTranslation } from '../Internationalization'

const PAGE_SIZE = 25

const PaginationButton: FC<{ nextPageSize: number, onClick: VoidFunction }> = ({ nextPageSize: count, onClick }) => (
    <Typography variant="body2" align="center" onClick={onClick}>
        <Button className="explorer-pagination-button" color="info" size="small">
            <FormattedTranslation id={Translation.SHOW_THE_NEXT} values={{ count }} />
            ...
        </Button>
    </Typography>
)

export const usePagination = <T,>(list: T[]): [page: T[], nextPageButton: JSX.Element | null] => {
    const [limited, setLimited] = useState(1)

    const pageSize = limited * PAGE_SIZE
    const nextPage = Math.min((limited + 1) * PAGE_SIZE, list.length) - pageSize

    return [list.slice(0, pageSize), nextPage > 0 ? <PaginationButton nextPageSize={nextPage} onClick={() => setLimited((limited) => ++limited)} /> : null]
}
