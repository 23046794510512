import type { NullableValues } from '../../../utils'

import { Capability, createIsAuthenticatedWithCapabilitiesSelector, type EndpointConnectivity } from '../../../domain'

import { Usecase } from '..'

const canManageLicense = createIsAuthenticatedWithCapabilitiesSelector(Capability.SYSTEM_LICENSE_MANAGE)

export class LicenseViewUsecase extends Usecase {
    async fetchPortalConnectivity (): Promise<NullableValues<EndpointConnectivity>> {
        if (!canManageLicense(this.getState())) {
            /**
             * No need to reach the portal
             * As the user would not care about the results anyhow
             */
            return { url: null, reachable: false }
        }

        try {
            const { endpoints } = await this.systemService.fetchConnectivity()
            return endpoints.cybusPortal
        } catch {
            return { url: null, reachable: false }
        }
    }
}
