import React, { type FC } from 'react'
import { List } from '@mui/material'

import { type CybusDetailedService, Translation } from '../../../../domain'
import { ResourcesList } from './ResourcesList'
import { ItemText } from './Commons'

export const Endpoints: FC<Pick<CybusDetailedService['configuredResources'], 'endpoints'>> = ({ endpoints }) => (
    <ResourcesList title={Translation.ENDPOINT} subtitle={Translation.AUTHORIZE_ENDPOINTS}>
        <List data-testid="service-endpoints">
            {endpoints.map((name, k) => (
                <ItemText key={`endpoint-${k}`} primary={name} />
            ))}
        </List>
    </ResourcesList>
)
