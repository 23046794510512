import React, { type FC } from 'react'

import { Translation } from '../../../domain'

import { SmallParagraph, Title } from '../common'

export const Description: FC<{ required: boolean }> = ({ required }) => (
    <>
        <Title id={Translation.MFA_SETTINGS_TITLE} />
        <SmallParagraph id={Translation.MFA_SETTINGS_SUBTITLE} values={{ required }} />
    </>
)
