import { Droppable } from '../../../utils'

import { type ConnectwareError, selectCertificates } from '../../../domain'

import { initialState } from '../..'
import { CertificatesUsecase } from './Base'

export class LoadCertificatesUsecase extends CertificatesUsecase {
    invoke (): VoidFunction {
        const droppable = new Droppable()

        this.certificatesService
            .fetchCertificates()
            .then((certificates) => droppable.ifNotDropped(() => this.setState({ certificates })))
            .catch((certificates: ConnectwareError) => droppable.ifNotDropped(() => this.setState({ certificates })))

        /** Once dropped, reset */
        droppable.onDrop(() => this.setState({ certificates: selectCertificates(initialState) }))

        return () => droppable.drop()
    }
}
