import { VrpcRemote, type VrpcRemoteArgs } from 'vrpc'

import { createNumberCounter, delay, executeOnce, Queue } from '../../../utils'

import { ConnectwareError, ConnectwareErrorType } from '../../../domain'
import type { LoggerService } from '../../../application'

import { awaitAgentsLoad, isTimeoutError } from '.'

export type ManagedVrpcRemote = Omit<VrpcRemote, 'connect' | 'end'>

export enum VrpcDomainType {
    DEFAULT = 'DEFAULT',
    EDGE = 'EDGE',
}

type Args = Readonly<{
    /** For the creation of the args without the logger */
    remoteArgs: () => Omit<VrpcRemoteArgs, 'console' | 'domain'>
    /** Logger for errors */
    logger: LoggerService
    /** In milliseconds */
    disconnectGracePeriod: number
    /** In milliseconds */
    agentLoadGracePeriod: number
    /** The possible domains of vrpc */
    domains: Record<VrpcDomainType, string>
}>

/**
 * Creates a VrpcRemote ready logger from the LoggerService
 */
export const wrapLogger = (logger: LoggerService): Exclude<VrpcRemoteArgs['console'], string | undefined> => ({
    debug: (...args: unknown[]) => logger.debug('VrpcRemote debug', { ...args }),
    info: (...args: unknown[]) => logger.info('VrpcRemote info', { ...args }),
    warn: (...args: unknown[]) => logger.warn('VrpcRemote warn', { ...args }),
    error: (...args: unknown[]) =>
        logger.error(
            new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'There was an error with the VrpcRemote', {
                ...args.map((e) => (e instanceof Error ? e.message : e)),
            })
        ),
})

type ManagedRemoteTupple = [remote: ManagedVrpcRemote, disuseCallback: VoidFunction]

class VrpcRemoteDomainManager {
    private readonly remoteArgsGetter: () => VrpcRemoteArgs

    private readonly disconnectGrace: number

    private readonly agentLoadGracePeriod: number

    private readonly logger: LoggerService

    /**
     * Every diconnection and connection request must succeed the previous call
     */
    private readonly asyncQueue = new Queue()

    private readonly users = new Set<number>()

    private readonly getNextUserId = createNumberCounter()

    private remote: VrpcRemote | null = null

    constructor ({ remoteArgs, logger, disconnectGracePeriod, agentLoadGracePeriod }: Omit<Args, 'domains'>, domain: string) {
        this.remoteArgsGetter = executeOnce(() => ({ ...remoteArgs(), domain, console: wrapLogger(logger) }))
        this.disconnectGrace = disconnectGracePeriod
        this.agentLoadGracePeriod = agentLoadGracePeriod
        this.logger = logger
    }

    private get hasUsers (): boolean {
        return this.users.size > 0
    }

    private ensureRemote (): VrpcRemote {
        this.remote = this.remote || new VrpcRemote(this.remoteArgsGetter())
        return this.remote
    }

    private async initializeConnect (): Promise<void> {
        const remote = this.ensureRemote()
        await remote.connect()

        if (this.agentLoadGracePeriod >= 0) {
            await awaitAgentsLoad(remote, this.agentLoadGracePeriod)
        }
    }

    private async disconnectAndDrop (): Promise<void> {
        const remote = this.ensureRemote()

        this.remote = null

        await remote.end()
    }

    private async drop (userId: number): Promise<void> {
        if (this.disconnectGrace >= 0) {
            /**
             * It makeses sense for the disuse to take a while to start dropping the remote
             */
            await delay(this.disconnectGrace)
        }

        try {
            await this.asyncQueue.push(async () => {
                /** Remove user */
                this.users.delete(userId)

                if (!this.hasUsers) {
                    /** Has no other users, than disconnect */
                    await this.disconnectAndDrop()
                }
            })
        } catch (e: unknown) {
            this.logger.error(new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, 'Could not disconnect from VRPC', { message: (e as Error).message }))
        }
    }

    private async use (): Promise<number> {
        /**
         * So are sue the connection is properly handled
         */
        const userId = this.getNextUserId()

        try {
            await this.asyncQueue.push(async () => {
                const hasNoUsers = !this.hasUsers

                /** Add user */
                this.users.add(userId)

                if (hasNoUsers) {
                    /** First to use, please connect */
                    await this.initializeConnect()
                }
            })
        } catch (e: unknown) {
            /** Drop user as connection failed */
            await this.drop(userId)

            if (isTimeoutError(e)) {
                throw new ConnectwareError(ConnectwareErrorType.SERVER_TIMEOUT, 'Could not connect to VRPC due to timeout')
            }

            throw new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, 'Could not connect to VRPC', { message: (e as Error).message })
        }

        return userId
    }

    /**
     * @example
     * // If its the first notification of usage, will cause a connection to be made, a `ConnectwareError` maybe thrown
     * const [internalRemote, notifyDisuse] = managed.getManagedVrpcRemote()
     * // If its the last notification of disusage, will cause a connection to be ended, a `ConnectwareError` will never be thrown
     * await notifyDisuse()
     *
     * @throws `ConnectwareError` if user is not authenticated properly
     */
    async getManagedVrpcRemote (): Promise<ManagedRemoteTupple> {
        const userId = await this.use()
        const remote = this.ensureRemote()
        return [remote, () => this.drop(userId)]
    }
}

/**
 * To manage vrpc subscriptions and re-use a single `VrpcRemote` instance and connection
 *
 * Supposed to be shared across multiple subscription threads in the `VrpcSubscriptions` implementation
 *
 * @deprecated
 * @todo remove when possible
 */
export class VrpcRemoteManager {
    private readonly managers: Record<VrpcDomainType, VrpcRemoteDomainManager>

    constructor ({ domains, ...args }: Args) {
        this.managers = Object.values(VrpcDomainType).reduce(
            (r, domain) => ({ ...r, [domain]: new VrpcRemoteDomainManager(args, domains[domain]) }),
            {} as Record<VrpcDomainType, VrpcRemoteDomainManager>
        )
    }

    /**
     * This function yields a managed vrpc remote instance, that should have its callback called once done
     * @throws `ConnectwareError` if user is not authenticated properly
     */
    async getManagedVrpcRemote (domain: VrpcDomainType = VrpcDomainType.DEFAULT): Promise<ManagedRemoteTupple> {
        return this.managers[domain].getManagedVrpcRemote()
    }
}
