import type { SubscriptionFilterArgs } from '../../../../../application'

import { mapResourceNames } from '../../../../Connectware'
import type { VrpcDomainType } from '../../..'
import type { ManagedVrpcRemote } from '../../../utils'

type VrpcHandlerBaseConfiguration = Readonly<{
    /**
     * The class name used to filter out irrelevant instances
     */
    classNameFilter: RegExp | string

    /**
     * The instance name used to filter out irrelevant instance
     */
    instanceNameFilter: RegExp | string

    /**
     * The agent of the desired instances
     *
     * If null then will atemp to read **all** agents
     */
    agent: string

    /**
     * If any extra remote with custom domains need to be added for this handler to tackle
     */
    domains: VrpcDomainType[]

    /**
     * This ought to be defined on every handler
     * Mapping out what types of filters it supports
     */
    supportedFilters: (keyof SubscriptionFilterArgs)[]

    /**
     * Some instances follow a pattern that makes it useful to exclude their loading by their name
     * This function enables this behaviour
     */
    excludeByInstanceName: (instanceName: string, args: SubscriptionFilterArgs) => boolean
}>

export type VrpcHandlerConfiguration =
    | Pick<VrpcHandlerBaseConfiguration, 'classNameFilter' | 'supportedFilters'>
    | Pick<VrpcHandlerBaseConfiguration, 'classNameFilter' | 'supportedFilters' | 'agent'>
    | Pick<VrpcHandlerBaseConfiguration, 'classNameFilter' | 'supportedFilters' | 'agent' | 'instanceNameFilter'>
    | Pick<VrpcHandlerBaseConfiguration, 'classNameFilter' | 'supportedFilters' | 'instanceNameFilter'>
    | Pick<VrpcHandlerBaseConfiguration, 'classNameFilter' | 'supportedFilters' | 'instanceNameFilter' | 'excludeByInstanceName'>
    // Configuration for remote based handlers
    | Pick<VrpcHandlerBaseConfiguration, 'domains' | 'supportedFilters'>

/**
 * @param isGone if the unsub function was called because the instance was removed
 * @throws `Error`, convertion to `ConnectwareError` is expected to be done in `mapError`
 */
export type UnsubFromInstanceFunction = (isGone: boolean) => Promise<unknown> | unknown

export type UnsubFromRemoteFunction = () => Promise<unknown> | unknown

/**
 * This mapper helps the Instance Manager to convert VRPC instances into domain instances
 */
export interface VrpcInstanceMapper<VrpcInstance, Domain> {
    /**
     * @description will be called once initially, and after every `onChange` call
     * @returns
     *  the domain specific entity or a Map of entities
     *
     *  if a map is yielded, then it will cause a replacement of other entities
     * @throws `Error`, convertion to `ConnectwareError` is expected to be done outside
     */
    readonly mapToDomain: (instance: VrpcInstance, instanceName: string, filter: SubscriptionFilterArgs) => Promise<Domain | Map<string, Domain>>

    /**
     * @param listener function, should be called once there is an internal change on the vrpc instance
     * @returns Promise of an unsub method (Which also yields a promise, look around simba, everything this interface touches is async 🦁)
     * @throws `Error`, convertion to `ConnectwareError` is expected to be done outside
     */
    readonly onChange?: (instance: VrpcInstance, listener: VoidFunction) => Promise<UnsubFromInstanceFunction | void>
}

/**
 * This mapper helps the Remote Manager to convert the VRPCRemote into domain entities
 */
export interface VrpcRemoteMapper<Domain> {
    /**
     * @description will be called once initially, and after every `onChange` call
     * @returns
     *  the domain specific entity or a Map of entities
     *
     *  if a map is yielded, then it will cause a replacement of other entities
     * @throws `Error`, convertion to `ConnectwareError` is expected to be done outside
     */
    readonly mapToDomain: (remote: ManagedVrpcRemote) => Promise<Domain | Map<string, Domain>>

    /**
     * @param listener function, should be called once there is an internal change on the vrpc remote
     * @returns Promise of an unsub method (Which also yields a promise, look around simba, everything this interface touches is async 🦁)
     * @throws `Error`, convertion to `ConnectwareError` is expected to be done outside
     */
    readonly onChange?: (remote: ManagedVrpcRemote, listener: VoidFunction) => Promise<UnsubFromRemoteFunction | void>
}

export type VrcpEntityHandler<VrpcInstance, Domain> = Readonly<{
    configuration: VrpcHandlerConfiguration
    remoteMapper: VrpcRemoteMapper<Domain> | null
    instanceMapper: VrpcInstanceMapper<VrpcInstance, Domain> | null
}>

type ListenerMethods<E extends string> = Record<'on' | 'off', (eventNames: E, handler: VoidFunction) => Promise<unknown>>

/** Helper function to listen to proxy events */
export const listenToProxy = async <E extends string, P extends ListenerMethods<E>>(
    proxy: P,
    eventNames: E[],
    handler: VoidFunction
): Promise<UnsubFromInstanceFunction> => {
    /** Listen to all events */
    await Promise.all(eventNames.map((eventName) => proxy.on(eventName, handler)))

    return (isGone) => {
        if (isGone) {
            /**
             * @see https://i.imgur.com/MPbX6OD.jpg
             *
             * No need to drop anything
             */
            return Promise.resolve()
        }

        /** Drop all listeners */
        return Promise.all(eventNames.map((eventName) => proxy.off(eventName, handler)))
    }
}

/**
 * This function filters out the instances to be loaded by their name
 * If their name is part of a service that is being filtered out
 * It will exclude it
 */
export const excludeInstanceByServiceName = (instance: string, { service }: SubscriptionFilterArgs): boolean => {
    if (!service) {
        return false
    }
    const [serviceName] = mapResourceNames(instance)
    return service !== serviceName
}
