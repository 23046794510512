import React, { type FC } from 'react'

import { Box, type SxProps, type Theme } from '@mui/material'

import { type CybusService, Translation } from '../../../domain'

import { FormattedList } from '../Internationalization'
import { PermissionedLink, RelativeRoutePathWithId } from '../routing'
import { DetailsEntry } from '../Details'

const linkStyle: SxProps<Theme> = { '& > a': { color: 'primary.700', textDecoration: 'unset' } }

const Links: FC<{ title: Translation, services: CybusService['id'][] }> = ({ title, services }) => (
    <DetailsEntry title={title}>
        <Box sx={linkStyle}>
            <FormattedList
                type="conjunction"
                style="long"
                value={services.map((service, k) => (
                    <PermissionedLink key={k} path={RelativeRoutePathWithId.SERVICE} id={service}>
                        {service}
                    </PermissionedLink>
                ))}
            />
        </Box>
    </DetailsEntry>
)

export const ServiceLinkEntry: FC<{ service: CybusService['id'] | null }> = ({ service }) =>
    service ? <Links title={Translation.SERVICE_ID} services={[service]} /> : null

export const CrossReferenceServiceLinks: FC<{ title: Translation, services: CybusService['id'][] }> = ({ title, services }) => (
    <div className="serviceDependencies">
        <Links title={title} services={services} />
    </div>
)
