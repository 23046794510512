import { isEnumOf } from '../../../../utils'
import { ConnectwareError, ConnectwareErrorType, CybusContainerCapability, type CybusContainerConfiguration } from '../../../../domain'
import type { CommissioningDataResourceProperties } from '../..'

export const mapContainer = (name: string, properties: CommissioningDataResourceProperties<'Cybus::Container'>): CybusContainerConfiguration => ({
    name,
    image: properties.image,
    ports: properties.ports || [],
    privileged: Boolean(properties.privileged),
    capabilities: (properties.capAdd || []).map((rawValue) => {
        if (isEnumOf(CybusContainerCapability, rawValue)) {
            return rawValue
        }

        throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'Could not find valid CybusContainerCapability', {
            CybusContainerCapability,
            rawValue,
        })
    }),
    devices: (properties.devices || []).map((device) => ({
        pathInContainer: device.PathInContainer,
        pathOnHost: device.PathOnHost,
        cGroupPermissions: device.CgroupPermissions,
    })),
})
