import React, { type FC, type ReactNode } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Paper, type SxProps, type Theme, Typography } from '@mui/material'

import { type CybusLog, Translation } from '../../../domain'
import { FormattedTranslation, useTranslator } from '../Internationalization'

const itemStyle: SxProps<Theme> = { p: 2 }

const Item: FC<Readonly<{ primary: Translation, secondary: ReactNode }>> = ({ primary, secondary }) => (
    <>
        <Grid container sx={itemStyle}>
            <Grid item xs={2}>
                <Typography variant="subtitle2">
                    <FormattedTranslation id={primary} />
                </Typography>
            </Grid>
            <Grid item xs={10}>
                <Typography variant="subtitle2">{secondary}</Typography>
            </Grid>
        </Grid>
        <Divider />
    </>
)

export const Modal: FC<Readonly<{ log: CybusLog, onClose: VoidFunction }>> = ({ log: { timestamp, pid, hostname, className, message }, onClose }) => {
    const translator = useTranslator()

    return (
        <Dialog id="log-details-modal" maxWidth="lg" fullWidth open aria-labelledby={translator.formatTranslation(Translation.LOG_DETAILS)} onClose={onClose}>
            <DialogTitle variant="h6">{translator.formatTranslation(Translation.LOG_DETAILS)}</DialogTitle>
            <DialogContent>
                <Paper>
                    <Item primary={Translation.TIME} secondary={timestamp.getTime()} />
                    {pid && <Item primary={Translation.PID} secondary={pid} />}
                    {hostname && <Item primary={Translation.HOSTNAME} secondary={hostname} />}
                    {className && <Item primary={Translation.CLASS_NAME} secondary={className} />}
                    <Item primary={Translation.MESSAGE} secondary={message} />
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onClose}>
                    {translator.formatTranslation(Translation.CLOSE)}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
