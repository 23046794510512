import { type CybusServerConfiguration, CybusServerProtocol } from '../../../../domain'

import type { CommissioningDataResourceProperties } from '../..'

export const mapOpcuaServer = (name: string, properties: CommissioningDataResourceProperties<'Cybus::Server::Opcua'> | null): CybusServerConfiguration => ({
    name,
    protocol: CybusServerProtocol.OPCUA,
    basePath: null,
    port: properties?.port !== undefined ? properties.port : null,
    allowsAnonymous: Boolean(properties ? properties.allowAnonymous : null),
})

export const mapHttpServer = (name: string, properties: CommissioningDataResourceProperties<'Cybus::Server::Http'> | null): CybusServerConfiguration => ({
    name,
    protocol: CybusServerProtocol.HTTP,
    basePath: properties?.basePath || null,
    port: null,
    allowsAnonymous: null,
})
