import { normalize, search } from '../../../../utils'

export enum SearchStrategy {
    /**
     * Searching is disabled
     */
    DISABLED = 'DISABLED',

    /**
     * Value will be converted to a string, stripped of its diacritics accents and then compared
     */
    LOWER_CASE_DIACRITICLESS = 'LOWER_CASE_DIACRITICLESS',
}

export type Search = {
    /**
     * Without diacritics, split by black spaces, lowercased
     */
    readonly normalizedTerms: string[]
    readonly raw: string
}

export type Searcher<V> = (value: V, search: Search) => boolean

const searchers: Record<SearchStrategy, Searcher<unknown>> = {
    [SearchStrategy.DISABLED]: () => false,
    [SearchStrategy.LOWER_CASE_DIACRITICLESS]: (value, { normalizedTerms }) => search(normalize(String(value)), normalizedTerms),
}

export const getDefaultSearcher = (strategy: SearchStrategy): Searcher<unknown> => searchers[strategy]
