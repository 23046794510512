import { Cache, EventListener, isEnumOf, normalize, Throttler } from '../../utils'
import { ConnectwareError, StatusType, Translation } from '../../domain'
import type {
    PageSubscription,
    PageSubscriptionsTypes,
    SubscriptionFilterArgs,
    SubscriptionListEvent,
    SubscriptionPageEvent,
    SubscriptionPageEventHandler,
    SubscriptionPageOptions,
    SubscriptionsService,
    TranslationService,
} from '../../application'

class Filter<T extends keyof PageSubscriptionsTypes> {
    private readonly statusCache = new Cache<string>()

    private readonly dateCache = new Cache<string>()

    constructor (private readonly translationService: TranslationService) {}

    private isValueRelevant (value: unknown, search: string): boolean {
        if (isEnumOf(StatusType, value)) {
            /**
             * Known issue:
             * If there is a value that is supposed to be a string
             * But happens to have the value of StatusType
             * A false positive will happen
             * And the Status Type strategy will be used
             */
            const label = this.statusCache.get(() => normalize(this.translationService.translate(Translation.STATUS_LABEL, { status: value })), value)
            return label.includes(search)
        }

        if (typeof value === 'number' || typeof value === 'bigint' || typeof value === 'string') {
            return normalize(String(value)).includes(search)
        }

        if (value instanceof Date) {
            return this.dateCache
                .get(() => normalize(this.translationService.translate(Translation.DATETIME, { type: 'datetime', date: value })), value.getTime())
                .includes(search)
        }

        /** Other values should get ignored and not used for search */
        return false
    }

    isSearched (entry: PageSubscriptionsTypes[T], search: string | null): boolean {
        if (!search) {
            /** No search made, then everything is relevant */
            return true
        }

        for (const value of Object.values(entry)) {
            if (this.isValueRelevant(value, search)) {
                return true
            }
        }

        return false
    }
}

class AdaptedPageSubscription<T extends keyof PageSubscriptionsTypes> implements PageSubscription<T> {
    /** The last data set that was loaded */
    private rawData: SubscriptionListEvent<T> | null = null

    /** The kill switch for listening to the actual subscription */
    private readonly off: VoidFunction

    /** Holder of the listeners of the application */
    private readonly listener = new EventListener<SubscriptionPageEvent<T>>()

    /** To stop excessive changes */
    private readonly throttler: Throttler

    constructor (
        private readonly filter: Filter<T>,

        /** The current filtering options */
        private options: SubscriptionPageOptions<T>,

        /** The actual subscription */
        eventSource: EventListener<SubscriptionListEvent<T>>,

        /** Called when wanting to unhook */
        readonly cancel: VoidFunction,

        /** By how much throttle should changes be taken */
        throttle: number
    ) {
        this.throttler = new Throttler(throttle)
        this.off = eventSource.on((events) => {
            this.rawData = events
            this.scheduleRefresh()
        })
    }

    private mapPage (): SubscriptionPageEvent<T> | null {
        if (!this.rawData) {
            /** No data has loaded yet, so don't do anything */
            return null
        }

        if (ConnectwareError.is(this.rawData)) {
            return this.rawData
        }

        const { sort, pagination, search } = this.options

        const entries: PageSubscriptionsTypes[T][] = []
        const normalizedSearch = search && normalize(search)

        for (const entry of this.rawData) {
            if (ConnectwareError.is(entry)) {
                /** If a single error is found, just give up */
                return entry
            }

            if (this.filter.isSearched(entry, normalizedSearch)) {
                entries.push(entry)
            }
        }

        /** Sort results */
        entries.sort((a, b) => {
            let invert = a[sort.column] > b[sort.column] ? -1 : 1
            if (sort.asc) {
                invert *= -1
            }
            return invert
        })

        return {
            page: pagination.page,
            pageSize: pagination.pageSize,
            totalCount: entries.length,

            /** Cut off only to page */
            current: entries.slice(pagination.page * pagination.pageSize, (pagination.page + 1) * pagination.pageSize),
        }
    }

    private scheduleRefresh (): void {
        this.throttler.run(() => {
            const page = this.mapPage()

            if (page !== null) {
                this.listener.trigger(page)
            }
        })
    }

    update (options: Partial<SubscriptionPageOptions<T>>): void {
        this.options = { ...this.options, ...options }
        this.scheduleRefresh()
    }

    onData (handler: SubscriptionPageEventHandler<T>): VoidFunction {
        return this.listener.on(handler)
    }

    stop (): void {
        /** Stop internal listener */
        this.off()

        /** Cancel subscription */
        this.cancel()
    }
}

/**
 * This class is an adapter that allows implementations of `SubscriptionsService`
 * to implement `subscribeToPage` by only having the `subscribeToAll` method implemented
 *
 * @todo remove this class once the implementation of subscribeToPage can actually be finished
 * @deprecated this is here only until the backend is able to implement proper filtering and sorting
 */
export class PageSubscriptionAdapter implements Pick<SubscriptionsService, 'subscribeToPage'> {
    protected throttle = 50

    constructor (private readonly base: Pick<SubscriptionsService, 'subscribeToAll'>, private readonly translationService: TranslationService) {}

    async subscribeToPage<T extends keyof PageSubscriptionsTypes> (
        eventName: T,
        { search, sort, pagination, ...filter }: SubscriptionFilterArgs & SubscriptionPageOptions<T>
    ): Promise<PageSubscription<T>> {
        const eventLister = new EventListener<SubscriptionListEvent<T>>()
        const entityFilter = new Filter(this.translationService)
        const cancel = await this.base.subscribeToAll(eventName, filter, (event) => eventLister.trigger(event))
        return new AdaptedPageSubscription<T>(entityFilter, { search, sort, pagination }, eventLister, cancel, this.throttle)
    }
}
