import type { CybusCoreContainer, CybusServiceContainer } from '../../../../../../../domain'
import type { SubscriptionFilterArgs } from '../../../../../../../application'

import {
    ContainersListFetcher,
    type DockerContainerResponse,
    type DockerContainersResponse,
    type KubernetesContainersResponse,
} from '../../../../../../Connectware'
import type { ContainerManagerOrchestratorProxy, DockerProxy } from '../../../../../proxies'

import type { VrcpEntityHandler, VrpcHandlerConfiguration, VrpcInstanceMapper } from '../..'
import { fetchImplementationResolver } from '../Implementation'
import { coreConfiguration, serviceConfiguration } from './Base'
import { VrpcContainerManagerOrchestratorProxyBaseMapper } from '../Base'

class ServiceContainersListFetcher extends ContainersListFetcher {
    constructor (isDocker: Promise<boolean>, private readonly proxy: ContainerManagerOrchestratorProxy, filter: SubscriptionFilterArgs) {
        super(isDocker, filter)
    }

    protected fetchContainersResponse (): Promise<DockerContainersResponse | KubernetesContainersResponse> {
        return this.proxy.getContainers()
    }

    protected fetchDockerContainerMetadata (name: string): Promise<DockerContainerResponse | null> {
        return (this.proxy as DockerProxy).inspectContainer(name).then((i) => i || null)
    }
}

abstract class VrpcContainerManagerOrchestratorProxyToContainerMapper<C extends CybusServiceContainer | CybusCoreContainer>
    extends VrpcContainerManagerOrchestratorProxyBaseMapper<C>
    implements VrpcInstanceMapper<ContainerManagerOrchestratorProxy, C> {
    protected abstract fetchContainers (fetcher: ServiceContainersListFetcher): Promise<C[]>

    async mapToDomain (proxy: ContainerManagerOrchestratorProxy, _: string, filter: SubscriptionFilterArgs): Promise<Map<string, C>> {
        const isDocker = fetchImplementationResolver(proxy).then((c) => c.isDocker(proxy))
        const fetcher = new ServiceContainersListFetcher(isDocker, proxy, filter)
        return this.fetchContainers(fetcher).then((containers) => containers.reduce((m, c) => m.set(c.id, c), new Map()))
    }
}

class VrpcContainerManagerOrchestratorProxyToServiceContainerMapper extends VrpcContainerManagerOrchestratorProxyToContainerMapper<CybusServiceContainer> {
    protected fetchContainers (fetcher: ServiceContainersListFetcher): Promise<CybusServiceContainer[]> {
        return fetcher.fetchServiceContainers()
    }
}

class VrpcContainerManagerOrchestratorProxyToCoreContainerMapper extends VrpcContainerManagerOrchestratorProxyToContainerMapper<CybusCoreContainer> {
    protected fetchContainers (fetcher: ServiceContainersListFetcher): Promise<CybusCoreContainer[]> {
        return fetcher.fetchCoreContainers()
    }
}

export class VrpcContainerManagerOrchestratorProxyToCoreContainerHandler implements VrcpEntityHandler<ContainerManagerOrchestratorProxy, CybusCoreContainer> {
    readonly configuration = coreConfiguration

    readonly remoteMapper = null

    readonly instanceMapper = new VrpcContainerManagerOrchestratorProxyToCoreContainerMapper()
}

export class VrpcContainerManagerOrchestratorProxyToServiceContainerHandler
    implements VrcpEntityHandler<ContainerManagerOrchestratorProxy, CybusServiceContainer> {
    readonly configuration: VrpcHandlerConfiguration = { ...serviceConfiguration, supportedFilters: ['service'] }

    readonly remoteMapper = null

    readonly instanceMapper = new VrpcContainerManagerOrchestratorProxyToServiceContainerMapper()
}
