import { Capability, type CybusCatalogApplication, type CybusCatalogServiceConfiguration, type CybusCatalogServiceVersionData } from '../../../domain'

import type { ConfigurationService, ConnectwareServicesCatalogService, LoggerService } from '../../../application'
import { FetchConnectwareHTTPService } from '../Base'
import {
    catalogApplicationsMapper,
    catalogDataMapper,
    commissioningFileMapper,
    type FullServiceMetadata,
    type ServiceCatalogMetaResponse,
    type ServiceContent,
} from '../../Connectware'

export class ConnectwareHTTPServicesCatalogService extends FetchConnectwareHTTPService implements ConnectwareServicesCatalogService {
    constructor (
        origin: string,
        tokenGetter: () => string | null,
        private readonly configuration: ConfigurationService,
        private readonly logger: LoggerService,
        private readonly servicesCatalogDefaultImageLink: string
    ) {
        super(origin, tokenGetter)
    }

    fetchCatalogData (queryParams: CybusCatalogServiceConfiguration): Promise<CybusCatalogServiceVersionData> {
        return this.request({
            capability: Capability.SERVICES_CATALOG_READ,
            method: 'GET',
            path: '/api/marketplace/app/meta',
            authenticate: true,
            queryParams,
            handlers: { 200: (response) => response.getJson<ServiceCatalogMetaResponse>().then((data) => catalogDataMapper(data.meta, data.content)) },
        })
    }

    fetchCatalogApplications (): Promise<CybusCatalogApplication[]> {
        return this.request({
            capability: Capability.SERVICES_CATALOG_READ,
            method: 'GET',
            path: '/api/marketplace/apps',
            authenticate: true,
            handlers: {
                200: (response) =>
                    response
                        .getJson<FullServiceMetadata[]>()
                        .then((services) => catalogApplicationsMapper(services, this.logger, this.servicesCatalogDefaultImageLink)),
            },
        })
    }

    fetchCommissioningFile ({ name, directory, filename }: Pick<CybusCatalogApplication, 'name' | 'directory' | 'filename'>): Promise<File> {
        return this.request({
            capability: Capability.SERVICES_CATALOG_READ,
            method: 'GET',
            path: '/api/marketplace/app',
            authenticate: true,
            queryParams: { directory, filename },
            handlers: {
                200: async (response) => response.getJson<ServiceContent>().then((content) => commissioningFileMapper(content, name, this.configuration)),
            },
        })
    }
}
