import React, { type FC, useState } from 'react'
import { Box, Button, type SxProps, TextField, type Theme } from '@mui/material'
import { Add } from '@mui/icons-material'

import { Translation } from '../../../../domain'

import { useAppUsecases } from '../../State'
import { FormattedTranslation } from '../../Internationalization'

import { createOnEnterHandler } from '../..'
import { useAnchorCloser } from '../../common'

import { PopoverButton } from './PopoverButton'

const wrapperStlye: SxProps<Theme> = { display: 'flex' }
const buttonStyle: SxProps<Theme> = { ml: 1 }

const AddTopicPopover: FC = () => {
    const close = useAnchorCloser()
    const [newTopic, setNewTopic] = useState('')
    const { explorerTopicManagementUsecase } = useAppUsecases()

    const create: VoidFunction = () => {
        if (explorerTopicManagementUsecase.addTopic(newTopic)) {
            close()
        }
    }

    return (
        <Box sx={wrapperStlye}>
            <TextField
                autoFocus
                value={newTopic}
                onChange={({ currentTarget: { value } }) => setNewTopic(value)}
                onKeyUp={createOnEnterHandler(create)}
                size="small"
                variant="standard"
            />
            <Button sx={buttonStyle} size="small" variant="contained" color="primary" onClick={create}>
                <FormattedTranslation id={Translation.ADD_CUSTOM_TOPIC} />
            </Button>
        </Box>
    )
}

export const AddTopicButton: FC = () => <PopoverButton id="addTopic" icon={<Add />} text={Translation.ADD_CUSTOM_TOPIC} popover={<AddTopicPopover />} />
