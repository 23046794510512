import { canConnectionBeConnected, canConnectionBeDisconnected, type CybusConnection, type CybusDetailedConnection } from '.'
import { createLoadedSelector, type Loadable } from '../Loadable'
import { createPageSelector, type Page } from '..'

export type ConnectionsAppState = Readonly<{ connection: Loadable<CybusDetailedConnection>, connectionsPage: Page<CybusConnection> }>

export const selectConnection = (s: ConnectionsAppState): ConnectionsAppState['connection'] => s.connection
export const selectConnectionsPage = (s: ConnectionsAppState): ConnectionsAppState['connectionsPage'] => s.connectionsPage

export const selectConnectableLoadedDetailedConnections = createPageSelector(
    selectConnectionsPage,
    'id',
    'selectConnectableLoadedDetailedConnections',
    canConnectionBeConnected
)
export const selectDisconnectableLoadedDetailedConnections = createPageSelector(
    selectConnectionsPage,
    'id',
    'selectDisconnectableLoadedDetailedConnections',
    canConnectionBeDisconnected
)
export const selectLoadedConnections = createPageSelector(selectConnectionsPage, 'id', 'selectLoadedConnections')

export const selectConnectableLoadedDetailedConnection = createLoadedSelector(selectConnection, 'id', canConnectionBeConnected)
export const selectDisconnectableLoadedDetailedConnection = createLoadedSelector(selectConnection, 'id', canConnectionBeDisconnected)
export const selectLoadedDetailedConnection = createLoadedSelector(selectConnection, 'id')
