import type { Mutable, PickByValue } from 'utility-types'
import React, { type FC, type PropsWithChildren, type ReactNode, useEffect, useMemo } from 'react'

import type { ReadonlyRecord } from '../../../../utils'
import {
    type AppState,
    arePagesEquals,
    ConnectwareError,
    type Page,
    type PaginationParameters,
    type SortableColumn,
    type Translation,
} from '../../../../domain'
import type { PageSubscriptionArgs, SubscriptionFilterArgs, Usecases } from '../../../../application'

import { type AbsoluteRoutePathWithId, type RelativeRoutePathWithId, useRouteWithIdRedirect } from '../../routing'
import { useAppState, useAppUsecases } from '../../State'
import { ErrorMessage } from '../../ErrorMessage'
import { CircularLoader, Table, type TableColumn, type TableLine, type TableProps, type TableTranslations } from '../../common'

import { useSelection, type UseSelectionProps } from './Selection'
import { Header } from './Header'

/** Includes only supported columns */
type Column<Value, Line,> = Pick<TableColumn<Value, Line>, 'customCSVRender' | 'customCellRender' | 'display' | 'label'>
export type Columns<Resource, TableResource,> = Partial<Readonly<{ [P in keyof TableResource]: Column<TableResource[P], TableResource> }>> &
    /** This type forces the sorting to now be a boolean, and only if the original resource also contains that column */
    Partial<Readonly<{ [P in SortableColumn<Resource>]: Readonly<{ sort?: true }> }>>

type Props<Resource, TableResource extends TableLine, UsecaseSelectNames extends keyof Usecases,> = Readonly<{
    /**
     * The usecase the table will use to fetch data
     */
    subscriptionUsecase: keyof PickByValue<
        Usecases,
        {
            /** Used to subscribe to changes */
            subscribe(args: PageSubscriptionArgs): VoidFunction
            /** Used to update the pagination parameters */
            updateParameters(update: Partial<PaginationParameters<Resource>>): PaginationParameters<Resource>
            getPaginationSizes(): number[]
        }
    >

    /**
     * Filter parameters for the resources to be fetched
     */
    filter?: SubscriptionFilterArgs

    /** How data should be provided for the table to be rendered */
    data: (s: AppState) => Page<Resource>

    /**
     * Once errors/loading states have been filtered out
     * This function will be called to assist to creation of the contents of the table itself
     */
    dataTableMapper: (resource: Resource[]) => TableResource[]

    /** The columns of the table */
    columns: Columns<Resource, TableResource>

    /**
     * The translations of the table
     */
    translations: TableTranslations &
        Readonly<{
            /** The name of the resource, to be displayed in case of errors and title */
            resourceName: Translation

            infoTitle?: Translation
            infoBody?: Translation
        }>

    /**
     * If the list should be short
     */
    short?: boolean

    /**
     * If should show the resource name as a title or have a custom title
     */
    title?: boolean | Translation

    /**
     * If there should be title actions
     */
    actions?: ReactNode
}> &
    Pick<TableProps<TableResource>, 'onRowClick'> &
    UseSelectionProps<TableResource, UsecaseSelectNames>

/**
 * This component has a bult-in structure to render a table that
 *
 * - While rendered, subscribes to the given usecase (if the same is subscribable)
 * - Handles state changes (loading, error, success yielf) of the given resources in order to properly render the contents
 * - Has a standardized look and feel
 *
 * Children will be rendered with-in the Table component
 */
export const ResourcesTable = <Resource extends TableLine, TableResource extends TableLine = Resource, UsecaseSelectNames extends keyof Usecases = never,>({
    subscriptionUsecase: subscriptionUsecaseName,
    filter = {},
    data,
    hasManagementCapabilitiesSelector,
    dataTableMapper,
    translations: { resourceName, infoTitle, infoBody, ...translations },
    onSelect,
    title,
    actions,
    short = false,
    children,
    ...props
}: PropsWithChildren<Props<Resource, TableResource, UsecaseSelectNames>>): ReturnType<FC> => {
    const usecases = useAppUsecases()
    const subscriptionUsecase = usecases[subscriptionUsecaseName]

    const resourcesPage = useAppState(data, arePagesEquals as (a: Page<Resource>, b: Page<Resource>) => boolean)
    const selection = useSelection({ hasManagementCapabilitiesSelector, onSelect })

    /** Subscribe to changes of resources */
    useEffect(
        () => subscriptionUsecase.subscribe({ ...filter, short, throttle: 200 }),
        [subscriptionUsecase, filter.connection, filter.server, filter.service, short]
    )

    /** Load the possible page sizes to be used */
    const pageSizes = useMemo(() => subscriptionUsecase.getPaginationSizes(), [])

    return (
        <>
            <Header title={title} resourceName={resourceName} infoTitle={infoTitle} infoBody={infoBody} actions={actions} />
            {/* Resources have not loaded */}
            {resourcesPage === null ? (
                <CircularLoader data-testid="no-data-loader" />
            ) : (
                <>
                    {/* General error has ocurred */}
                    {ConnectwareError.is(resourcesPage.data) ? (
                        <ErrorMessage data-testid="general-error-message" error={resourcesPage.data} stack extras="section" />
                    ) : (
                        <Table
                            data-testid={subscriptionUsecaseName}
                            selection={selection}
                            initialSearch={resourcesPage.search === null || resourcesPage.search}
                            data={dataTableMapper(resourcesPage.data === null ? [] : resourcesPage.data.current)}
                            isLoading={resourcesPage.data === null}
                            initialPagination={{ selected: resourcesPage.pagination.pageSize, options: pageSizes }}
                            totalCount={resourcesPage.data && 'totalCount' in resourcesPage.data ? resourcesPage.data.totalCount : undefined}
                            initialSortOrder={{ name: resourcesPage.sort.column, direction: resourcesPage.sort.asc ? 'asc' : 'desc' }}
                            translations={translations}
                            onCustomized={(s) => {
                                const update: Mutable<Partial<PaginationParameters<unknown>>> = {}

                                if (resourcesPage.search !== (s.searchText || null)) {
                                    update.search = s.searchText
                                }

                                if (resourcesPage.pagination.page !== s.page) {
                                    update.pagination = { ...resourcesPage.pagination, page: s.page }
                                }

                                if (resourcesPage.pagination.pageSize !== s.rowsPerPage) {
                                    update.pagination = { ...resourcesPage.pagination, pageSize: s.rowsPerPage }
                                }

                                if (
                                    s.sortOrder &&
                                    // If sort is not initially set, the object will be empty despite what is on the type definitions
                                    'name' in s.sortOrder &&
                                    'direction' in s.sortOrder &&
                                    ((s.sortOrder.direction === 'asc') !== resourcesPage.sort.asc || s.sortOrder.name !== resourcesPage.sort.column)
                                ) {
                                    update.sort = { column: s.sortOrder.name as SortableColumn<unknown>, asc: s.sortOrder.direction === 'asc' }
                                }

                                const { pagination } = subscriptionUsecase.updateParameters(update)
                                return { page: pagination.page }
                            }}
                            {...props}
                        >
                            {children}
                        </Table>
                    )}
                </>
            )}
        </>
    )
}

type RedirectingProps<Resource extends TableLine, TableResource extends TableLine, UsecaseSelectNames extends keyof Usecases = never,> = Omit<
    Props<Resource, TableResource, UsecaseSelectNames>,
    'onRowClick'
> &
    Readonly<{ redirectOnRowclick: AbsoluteRoutePathWithId | RelativeRoutePathWithId }>

/**
 * The base resource needs an id for redirecting else where
 */
type BaseResource = TableLine & ReadonlyRecord<'id', string>

export const RedirectingResourcesTable = <
    Resource extends BaseResource,
    TableResource extends BaseResource = Resource,
    UsecaseSelectNames extends keyof Usecases = never,
>({
    redirectOnRowclick,
    ...props
}: PropsWithChildren<RedirectingProps<Resource, TableResource, UsecaseSelectNames>>): ReturnType<FC> => {
    const onRowClick = useRouteWithIdRedirect<TableResource>(redirectOnRowclick, ({ id }) => id)
    return <ResourcesTable {...props} onRowClick={onRowClick} />
}
