import type { ValuesType } from 'utility-types'

import { entries } from '../../../utils'

import { Capability, ConnectwareError, ConnectwareErrorType, type RuleEngineEndpoint, type TopicConfiguration, TopicType, Translation } from '../../../domain'

import { type TranslationService } from '../../../application'
import { type AvailableTopicsResponse, type EndpointProxyParams, mapResourceNames } from '../../Connectware'
import { FetchConnectwareHTTPService } from '../Base'

type IndexedTopics = Record<string, Exclude<TopicConfiguration['source'], TopicType.CUSTOM>>

const aggSources = (resourceTopics: ValuesType<AvailableTopicsResponse>, source: Exclude<TopicType, TopicType.CUSTOM>, indexedTopics: IndexedTopics): void =>
    entries(resourceTopics).forEach(([instance, topics]) =>
        topics.forEach((topic) => {
            indexedTopics[topic] = [...(indexedTopics[topic] || []), { type: source, id: instance }]
        })
    )

export class ConnectwareHTTPTopicsService extends FetchConnectwareHTTPService {
    constructor (baseURL: string, tokenGetter: () => string | null, private readonly translationService: TranslationService) {
        super(baseURL, tokenGetter)
    }

    private async fetchRawTopics (): Promise<AvailableTopicsResponse> {
        return this.request({
            capability: Capability.TOPICS_SUBSCRIPTION_METADATA,
            path: '/api/topics',
            method: 'GET',
            authenticate: true,
            handlers: {
                200: async (response) => await response.getJson<AvailableTopicsResponse>(),
                500: async () => new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, this.translationService.translate(Translation.TOPICS_FETCHING_ERROR)),
            },
        })
    }

    async fetchTopics (): Promise<TopicConfiguration[]> {
        return await this.fetchRawTopics()
            .then(({ endpoints, mappings, nodes }) => {
                const indexedTopics: IndexedTopics = {}

                aggSources(endpoints, TopicType.ENDPOINT, indexedTopics)
                aggSources(mappings, TopicType.MAPPING, indexedTopics)
                aggSources(nodes, TopicType.NODE, indexedTopics)

                return entries(indexedTopics).map(([topic, source]) => ({ source, rawPath: topic, path: topic.split('/') }))
            })
            .catch((e: ConnectwareError) => {
                throw e
            })
    }

    async fetchEndpoints (): Promise<RuleEngineEndpoint[]> {
        return Promise.all([
            this.request({
                capability: Capability.RULE_ENGINE_SANDBOX_USE,
                path: '/api/endpoints',
                method: 'GET',
                authenticate: true,
                handlers: {
                    200: async (response) => await response.getJson<EndpointProxyParams[]>(),
                },
            }),
            this.fetchRawTopics().then(({ endpoints }) => endpoints),
        ]).then(([endpoints, topicEndpoints]) =>
            endpoints.reduce<RuleEngineEndpoint[]>((r, { id, connectionId }) => {
                const topics = topicEndpoints[id]

                if (!topics) {
                    return r
                }

                const [service, name] = mapResourceNames(id)
                return [...r, { id, name, service, connection: connectionId || null, topics }]
            }, [])
        )
    }
}
