import React, { type FC, Fragment, type ReactNode } from 'react'
import { Box, Divider, type SxProps, type Theme } from '@mui/material'

export const Section: FC<Readonly<{ width?: number }>> = ({ width, children }) => <Box sx={{ width }}>{children}</Box>

const dividerStyle: SxProps<Theme> = { my: 2, borderWidth: 2 }

export const Sections: FC<{ children: ReactNode[] }> = ({ children }) => (
    <>
        {children.map((child, k) => (
            <Fragment key={k}>
                {k > 0 && <Divider variant="fullWidth" sx={dividerStyle} />}
                {child}
            </Fragment>
        ))}
    </>
)
