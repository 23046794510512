import React, { type FC } from 'react'
import { Box, type SxProps, type Theme } from '@mui/material'
import { Delete, PauseCircleFilled, Replay, type SvgIconComponent, ToggleOn } from '@mui/icons-material'

import { Translation } from '../../../../../domain'

import { Chip, useTableClearSelection } from '../..'
import type { ActionNames, Actions, Selection } from '../Domain'
import { Toolbar } from './Base'

const chipStyle: SxProps<Theme> = { my: 0.5, mx: 1, '&:first-of-type': { ml: 0 } }

const defaultIcons: Record<ActionNames, SvgIconComponent> = { DELETE: Delete, DISABLE: PauseCircleFilled, ENABLE: ToggleOn, RESTART: Replay }
const defaultTranslations: Record<ActionNames, Translation> = {
    DELETE: Translation.DELETE,
    DISABLE: Translation.DISABLE,
    ENABLE: Translation.ENABLE,
    RESTART: Translation.RESTART,
}

const ToolbarButton = <L,>({
    action,
    actions: { [action]: onClick },
    icons: { [action]: Avatar = defaultIcons[action] } = {},
    labels: { [action]: label = defaultTranslations[action] },
    lines,
}: Readonly<{ actions: Actions<L>, action: ActionNames, lines: L[] }> & Pick<Selection<L>, 'icons' | 'labels'>): ReturnType<FC> =>
    onClick ? <Chip sx={chipStyle} avatar={Avatar} label={label} onClick={() => onClick(lines)} /> : null

export const TableToolbarSelect = <L,>({ lines, onSelect, ...props }: Selection<L> & Readonly<{ lines: L[] }>): ReturnType<FC> => {
    const clearTableSelection = useTableClearSelection()

    if (!lines.length) {
        /**
         * Whatever was selected is now gone, this tells the table to de-select everything
         */
        clearTableSelection()
        return null
    }

    const actions = onSelect(lines)

    return (
        <Toolbar position="start">
            <Box>
                <ToolbarButton {...props} actions={actions} lines={lines} action="ENABLE" />
                <ToolbarButton {...props} actions={actions} lines={lines} action="DISABLE" />
                <ToolbarButton {...props} actions={actions} lines={lines} action="DELETE" />
                <ToolbarButton {...props} actions={actions} lines={lines} action="RESTART" />
            </Box>
        </Toolbar>
    )
}
