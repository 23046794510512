import { useMemo, useState } from 'react'
import { isPending, usePromise } from 'react-sync-promise'

import { type Dayjs } from 'dayjs'

import { type ConnectwareError, type CybusLog, type CybusLogSource } from '../../../domain'

import { type LogOptions } from '../../../application'
import { useAppUsecase } from '../State'

export const useLogs = (type: CybusLogSource, { resource, lines, levels, start, end }: LogOptions): [CybusLog[] | ConnectwareError | null, VoidFunction] => {
    /**
     * Aux hook to cause a refresh every time the user clicks on it
     */
    const [tick, refresh] = useState<number>(0)

    const containerLogsUsecase = useAppUsecase('containerLogsUsecase')

    const logs = usePromise<CybusLog[], ConnectwareError>(
        useMemo(
            () => containerLogsUsecase.fetchLogs(type, { resource, lines, levels, start, end }),
            [containerLogsUsecase, type, tick, levels?.join('_'), start?.getTime(), end?.getTime()]
        )
    )

    return [isPending(logs) ? null : logs.value, () => refresh((t) => ++t)]
}

export const useAreLogsAvailable = (type: CybusLogSource, id: string): boolean => {
    const containerLogsUsecase = useAppUsecase('containerLogsUsecase')

    const logs = usePromise<boolean, never>(useMemo(() => containerLogsUsecase.areLogsAvailable(type, id), [containerLogsUsecase, id, type]))

    return isPending(logs) ? false : logs.value
}

export const useDates = (
    initialDate: Dayjs | null,
    initialEndDate: Dayjs | null
): [[Dayjs | null, Dayjs | null], (newStartDate: Dayjs | null) => void, (newEndDate: Dayjs | null) => void] => {
    const [[startDate, endDate], setDates] = useState<[Dayjs | null, Dayjs | null]>([initialDate, initialEndDate])

    return [
        [startDate, endDate],
        /**
         * if newStartDate > endDate then set endDate to null
         */
        (newStartDate) => setDates(([, endDate]) => [newStartDate, newStartDate?.isAfter(endDate) ? null : endDate]),
        /**
         * if newEndDate < startDate then set endDate to startDate
         */
        (newEndDate) => setDates(([startDate]) => [startDate, startDate?.isAfter(newEndDate) ? startDate : newEndDate]),
    ]
}
