import type { EventHandler, KeyboardEvent, KeyboardEventHandler, RefObject, SyntheticEvent } from 'react'
import { readAsText } from 'promise-file-reader'

import { ConnectwareError, ConnectwareErrorType } from '../../domain'

export const wasKeyHit = (e: KeyboardEvent<unknown>, key: string): boolean => e.key === key

export const createOnEnterHandler =
    <E>(callback: (e: KeyboardEvent<E>) => unknown): KeyboardEventHandler<E> =>
    (e) =>
        wasKeyHit(e, 'Enter') && callback(e)

export const createHandlerWithoutPropagation =
    <E extends SyntheticEvent, R>(callback?: (e: E) => R): EventHandler<E> =>
    (e) => {
        e.stopPropagation()
        callback?.(e)
    }

export const createClickerHandler =
    (ref: RefObject<HTMLElement>): VoidFunction =>
    () =>
        ref.current?.click()

export const readFirstFile = (files: FileList | File[] | null): File | null => files?.[0] ?? null

/**
 * @returns {Promise<string>} if there was a file
 * @returns {Promise<null>} if there was no file
 */
export const readFirstFileAsText = (files: FileList | File[] | null): Promise<string | null> => {
    const file = readFirstFile(files)

    if (!file) {
        return Promise.resolve(null)
    }

    return readAsText(file).catch((e: Error) => Promise.reject(new ConnectwareError(ConnectwareErrorType.UNEXPECTED, e.message)))
}
