import React, { type FC } from 'react'
import { Chip as BaseChip, chipClasses, type ChipProps, svgIconClasses, type SxProps, type Theme } from '@mui/material'
import { type SvgIconComponent } from '@mui/icons-material'

import type { Translation } from '../../../domain'

import { useTranslator } from '../Internationalization'

const avatarStyle: SxProps<Theme> = { [`&.${svgIconClasses.root}.${chipClasses.avatarColorPrimary}`]: { backgroundColor: 'transparent' } }

export const Chip = ({
    label,
    avatar: Avatar,
    sx,
    onClick,
    ...props
}: Readonly<{ label: Translation, avatar: SvgIconComponent }> & Pick<ChipProps, 'sx' | 'onClick'>): ReturnType<FC> => {
    const translator = useTranslator()
    const title = translator.formatTranslation(label)

    return <BaseChip {...props} sx={sx} title={title} label={title} avatar={<Avatar sx={avatarStyle} />} color="primary" variant="filled" onClick={onClick} />
}
