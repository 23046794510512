import {
    indexExplorerFilteredResources,
    indexExplorerFilteredTypes,
    selectExplorerFilteredResources,
    selectExplorerFilteredTypes,
    selectExplorerTopics,
    type SystemTopicSource,
    TopicType,
    Translation,
} from '../../../../domain'

import { useTranslator } from '../../Internationalization'
import { useAppState } from '../../State'

export type FilterOption = Readonly<(SystemTopicSource | { types: TopicType[] }) & { label: string, selected: boolean }>

/**
 * @returns list of options the user can filter topics by
 */
export const useFilterOptions = (): FilterOption[] => {
    const translator = useTranslator()

    const topics = useAppState(selectExplorerTopics)
    const isResourceSelected = indexExplorerFilteredResources(useAppState(selectExplorerFilteredResources))
    const isTypeSelected = indexExplorerFilteredTypes(useAppState(selectExplorerFilteredTypes))

    let serviceTopicCount = 0
    let customTopicCount = 0
    const customSources: Record<string, FilterOption> = {}

    /** Iterate options so count labels are acurate */
    topics.forEach((topic) => {
        if (Array.isArray(topic.source)) {
            topic.source.forEach((source) => (customSources[source.id] = { ...source, label: source.id, selected: isResourceSelected(source.id) === true }))
            serviceTopicCount++
        } else {
            customTopicCount++
        }
    })

    const options: FilterOption[] = []

    /** Add ability to filter out service topics */
    if (serviceTopicCount) {
        options.push({
            label: translator.formatTranslation(Translation.TOPICS_SOURCE, { source: false, count: serviceTopicCount }),
            types: [TopicType.ENDPOINT, TopicType.MAPPING, TopicType.NODE],
            selected: isTypeSelected(TopicType.ENDPOINT) && isTypeSelected(TopicType.MAPPING) && isTypeSelected(TopicType.NODE),
        })
    }

    /** Add ability to filter out custom topics */
    if (customTopicCount) {
        options.push({
            label: translator.formatTranslation(Translation.TOPICS_SOURCE, { source: true, count: customTopicCount }),
            types: [TopicType.CUSTOM],
            selected: isTypeSelected(TopicType.CUSTOM),
        })
    }

    /** Add ability to filter out topics by resource */
    options.push(...Object.values(customSources).sort(({ label: a }, { label: b }) => (a > b ? 1 : -1)))

    return options
}
