import type { CybusNode } from '../../../../../../domain'
import type { SubscriptionFilterArgs } from '../../../../../../application'

import { mapCybusNodes } from '../../../../../Connectware'
import { NODE_CLASSNAME_FILTER } from '../../../../constants'
import type { NodeProxy } from '../../../../proxies'
import type { VrcpEntityHandler, VrpcInstanceMapper } from '..'

import { listenToProxy, type UnsubFromInstanceFunction, type VrpcHandlerConfiguration } from '../Handler'

class VrpcNodeProxyToCybusNodeMapper implements VrpcInstanceMapper<NodeProxy, CybusNode> {
    mapToDomain (proxy: NodeProxy, _: string, filter: SubscriptionFilterArgs): Promise<Map<string, CybusNode>> {
        return proxy.getParams().then((params) => mapCybusNodes([params], filter).reduce((r, n) => r.set(n.id, n), new Map<string, CybusNode>()))
    }

    onChange (proxy: NodeProxy, listener: () => void): Promise<UnsubFromInstanceFunction> {
        return listenToProxy(proxy, ['state'], listener)
    }
}

export class VrpcNodeProxyToCybusNodeHandler implements VrcpEntityHandler<NodeProxy, CybusNode> {
    readonly instanceMapper = new VrpcNodeProxyToCybusNodeMapper()

    readonly remoteMapper = null

    readonly configuration: VrpcHandlerConfiguration = { classNameFilter: NODE_CLASSNAME_FILTER, supportedFilters: ['server'] }
}
