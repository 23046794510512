import type { CybusEndpoint, SortableColumn } from '../../../domain'
import { ResourcePageSubscriptionUsecase, ResourceSingleSubscriptionUsecase } from '../Resources'

export class ManageEndpointsUsecase extends ResourcePageSubscriptionUsecase<'endpoints'> {
    protected readonly pageName = 'endpoints'

    protected readonly pageAddress = 'endpointsPage'

    protected readonly initialSortColumn: SortableColumn<CybusEndpoint> = 'service'
}

export class ManageEndpointUsecase extends ResourceSingleSubscriptionUsecase<'endpoint'> {
    protected readonly singularName = 'endpoint'

    protected readonly singleAddress = 'endpoint'
}
