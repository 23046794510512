import { type ClientRegistryAuthorizationRequest, type ClientRegistryData, type ClientRegistryRequest } from '../../../../domain'

import { type AuthRequestsResponse, type ConfirmRegistrationRequest, type PermissionPostRequest } from '../Types'
import { mapToDefault } from './Commons'
import { mapAuthenticationMethodToGrantType, mapCybusUserAuthenticationMethodToAuthenticationMethod } from './Grant'
import { mapPermissionRequest, mapPermissions } from './Permission'

export const mapClientRegistryData = (unlockedUntil: number | null, requests: AuthRequestsResponse): ClientRegistryData => ({
    unlockedUntil: unlockedUntil ? new Date(unlockedUntil) : null,
    requests: requests
        .filter((r) => !r.granted)
        .map<ClientRegistryRequest>(({ timestamp, username, credentialType, roles, context, permissions }) => ({
            username,
            timestamp: new Date(timestamp),
            authenticationMethod: mapCybusUserAuthenticationMethodToAuthenticationMethod(credentialType),
            roles,
            description: context,
            permissions: mapPermissions(permissions),
        })),
})

export const mapConfirmRegistrationRequest = ({
    username,
    roles,
    permissions,
    mqttPublishPrefix,
    authenticationMethods,
}: ClientRegistryAuthorizationRequest): ConfirmRegistrationRequest => ({
    username,
    roles: roles.map(({ name }) => name),
    mqttPublishPrefix: mapToDefault(mqttPublishPrefix, ''),
    initialPermissions: permissions.map(mapPermissionRequest) as PermissionPostRequest[],
    grantTypes: authenticationMethods.map(mapAuthenticationMethodToGrantType),
})
