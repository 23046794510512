import { Warning } from '@mui/icons-material'
import { Box, Grid, type SxProps, type Theme, Typography } from '@mui/material'
import React, { type FC } from 'react'

import { type CybusDetailedMapping, Translation } from '../../../../domain'
import { Status } from '../../common'
import { DetailsEntry } from '../../Details'
import { useTranslator } from '../../Internationalization'
import { ServiceLinkEntry } from '../../Services'

const entriesErrorStyle: SxProps<Theme> = { display: 'flex', '& > *': { my: 'auto', mx: 0 } }
const errorTextStyle: SxProps<Theme> = { ml: 1 }

export const HeaderDetails: FC<{ resource: CybusDetailedMapping }> = ({ resource: { service, id, status, entries } }) => {
    const translation = useTranslator()

    const errorsCount = entries.reduce((c, e) => c + Number(Boolean(e.executionError)), 0)

    return (
        <Grid container alignItems="center">
            <Grid id="mapping-header-description" item xs={7}>
                <DetailsEntry title={Translation.MAPPING_ID} type="text">
                    {id}
                </DetailsEntry>
                <ServiceLinkEntry service={service} />
                <DetailsEntry title={translation.formatTranslation(Translation.ENTRY, { count: 2 })} type="number">
                    {entries.length}
                </DetailsEntry>
                <DetailsEntry title={Translation.STATUS}>
                    <Status status={status} />
                </DetailsEntry>
                {errorsCount > 0 && (
                    <DetailsEntry title={translation.formatTranslation(Translation.ERROR, { count: 2 })}>
                        <Box sx={entriesErrorStyle}>
                            <Warning fontSize="small" color="error" />
                            <Typography variant="body2" color="error" sx={errorTextStyle}>
                                {translation.formatTranslation(Translation.ERROR_WAS_ENCOUNTERED, { count: errorsCount })}
                            </Typography>
                        </Box>
                    </DetailsEntry>
                )}
            </Grid>
        </Grid>
    )
}
