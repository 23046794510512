import type { PickByValue, ValuesType } from 'utility-types'

import { type AppState, ConnectwareError, ConnectwareErrorType, selectResources, selectResourcesByDimensionAndAction } from '../../../domain'

import { Usecase } from '..'
import { type ConnectwareResourcesService, initialState } from '../..'

type ResourcesState = AppState['resources']

/**
 * This is a base usecase
 * Describes the manipulation of a prop under AppState['resources']
 *
 * Other usecases can specify the specific use of each dimension
 * And implement service calls to each
 */
abstract class BulkResourceUsecase extends Usecase {
    protected abstract readonly dimension: keyof ValuesType<ResourcesState>

    protected abstract readonly type: keyof ResourcesState

    protected get selected (): string[] {
        const { ids } = this.getResources()

        if (!ids) {
            throw new ConnectwareError(ConnectwareErrorType.STATE, `Can't ${this.dimension} the ${this.type}, state is not properly setup`)
        }

        return ids
    }

    private getResources (): ValuesType<ValuesType<ResourcesState>> {
        return selectResourcesByDimensionAndAction(this.getState(), this.type, this.dimension)
    }

    private setResources (update: Partial<ValuesType<ValuesType<ResourcesState>>>): void {
        const resources = selectResources(this.getState())
        this.setState({
            resources: {
                ...resources,
                [this.type]: { ...resources[this.type], [this.dimension]: { ...this.getResources(), ...update } },
            },
        })
    }

    protected select (ids: string[] | null): void {
        this.setResources({ ...selectResourcesByDimensionAndAction(initialState, this.type, this.dimension), ids })
    }

    protected async execute (
        method: keyof PickByValue<ConnectwareResourcesService, (type: keyof ResourcesState, ids: string[]) => Promise<void>>
    ): Promise<void> {
        try {
            this.setResources({ load: true })
            await this.connectwareResourcesService[method](this.type, this.selected)
            this.setResources({ ids: null, load: false })
        } catch (e: unknown) {
            this.setResources({ load: e as ConnectwareError })
        }
    }
}

export abstract class BulkEnableResourceUsecase extends BulkResourceUsecase {
    protected readonly dimension = 'enable'

    protected async enable (): Promise<void> {
        await this.execute('enable')
    }
}

export abstract class BulkDisableResourceUsecase extends BulkResourceUsecase {
    protected readonly dimension = 'disable'

    protected async disable (): Promise<void> {
        await this.execute('disable')
    }
}

export abstract class BulkDeleteResourceUsecase extends BulkResourceUsecase {
    protected readonly dimension = 'delete'

    protected async delete (): Promise<void> {
        await this.execute('delete')
    }
}

export abstract class BulkReenableResourceUsecase extends BulkResourceUsecase {
    protected readonly dimension = 'reenable'

    protected async reenable (): Promise<void> {
        await this.execute('reenable')
    }
}
