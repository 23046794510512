import React, { type FC } from 'react'
import { Input } from '@mui/material'

import { Translation } from '../../../../domain'
import { useAppUsecase } from '../../State'
import { useTranslator } from '../../Internationalization'

export const TopicsInput: FC = () => {
    const translator = useTranslator()
    const filter = useAppUsecase('filterSandboxUsecase')

    return (
        <Input
            id="rule-engine-topics-input"
            fullWidth
            placeholder={translator.formatTranslation(Translation.TOPIC_PLACEHOLDER)}
            onChange={({ target }) => filter.selectTopics([target.value])}
        />
    )
}
