import { QRCodeSVG } from 'qrcode.react'

import React, { type FC } from 'react'

import logo from '../../../assets/logo.png'

type Props = Readonly<{
    value: string
    /**
     * sizes in px
     */
    size?: number
}>

const imageSize = 32

export const QRCode: FC<Props> = ({ size = 160, ...props }) => (
    <QRCodeSVG {...props} size={size} imageSettings={{ src: logo, height: imageSize, width: imageSize, excavate: true }} />
)
