import type { Intersection } from 'utility-types'

import type { SubscriptionsTypes } from '../../../../../../application'

import type { ConnectionProxyParams } from '../../../../../Connectware'
import type { ConnectionProxy } from '../../../../proxies'

import { listenToProxy, type UnsubFromInstanceFunction, type VrcpEntityHandler, type VrpcHandlerConfiguration, type VrpcInstanceMapper } from '../Handler'

export type BaseConnection = Intersection<SubscriptionsTypes['connection'], SubscriptionsTypes['connections']>

export abstract class BaseConnectionMapper<T extends keyof SubscriptionsTypes> implements VrpcInstanceMapper<ConnectionProxy, SubscriptionsTypes[T]> {
    protected abstract mapConnection (connection: ConnectionProxyParams): SubscriptionsTypes[T]

    async mapToDomain (proxy: ConnectionProxy): Promise<SubscriptionsTypes[T]> {
        return this.mapConnection(await proxy.getParams())
    }

    onChange (proxy: ConnectionProxy, listener: () => void): Promise<UnsubFromInstanceFunction> {
        return listenToProxy(proxy, ['state'], listener)
    }
}

export abstract class VrpcBaseConnectionProxyInstanceHandler<T extends keyof SubscriptionsTypes>
    implements VrcpEntityHandler<ConnectionProxy, SubscriptionsTypes[T]> {
    abstract readonly instanceMapper: VrpcInstanceMapper<ConnectionProxy, SubscriptionsTypes[T]>

    readonly remoteMapper = null

    abstract readonly configuration: VrpcHandlerConfiguration
}
