import type { CybusPersistedPermission, EditableCybusPermission } from '../../../../domain'

import { mapContextToPermissionContext, mapOperationsToResponse, mapPermissionContextToContext, mapResponseToOperations } from '../../../Connectware'
import { type Permission, type PermissionPostRequest, type PermissionPutRequest } from '../Types'

export const mapPermissionRequest = ({ id, read, write, context, resource }: EditableCybusPermission): PermissionPutRequest | PermissionPostRequest => {
    const operation = mapOperationsToResponse({ read, write })
    return id ? { id, operation } : { context: mapPermissionContextToContext(context), resource, operation }
}

export const mapPermissions = (permissions: Permission[]): CybusPersistedPermission[] =>
    permissions.map(({ id, context, resource, operation }) => ({
        id,
        context: mapContextToPermissionContext(context),
        resource,
        ...mapResponseToOperations(operation),
    }))
