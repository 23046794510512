import type { ValuesType } from 'utility-types'

import { Unique } from '../../../utils'

import { type AvailableTopic, type Explorer, selectLoadedExplorer, type TopicPath, TopicType } from '../../../domain'

import { ExplorerUsecase } from './Explorer'

export class ExplorerTopicManagementUsecase extends ExplorerUsecase {
    private updateTopics (filter: (topic: AvailableTopic) => boolean, collect: (() => boolean) | boolean): void {
        const { topics } = selectLoadedExplorer(this.getState())

        const matched: AvailableTopic[] = []
        const notMatched: AvailableTopic[] = []

        topics.forEach((topic) => {
            /**
             * Has matched, so it will be updated
             */
            if (filter(topic)) {
                matched.push(topic)
            } else {
                notMatched.push(topic)
            }
        })

        /**
         * Update topics selection by whats outputted by the collector
         */
        const selected = typeof collect === 'boolean' ? collect : collect()

        this.setExplorer({ topics: [...matched.map((t) => ({ ...t, selected, subscriptionErrors: [] })), ...notMatched] })
    }

    filterTopics (resources: string[], types: TopicType[]): void {
        this.setExplorer({ filter: { resources, types } })
    }

    unsubTopics (sources: ValuesType<Explorer['latestValues']>['sources']): void {
        this.updateTopics((topic) => sources.some((fullPath) => ExplorerUsecase.areTopicPathsEquals(fullPath, topic.path)), false)
    }

    retryTopic (fullPath: TopicPath): void {
        /** De-select relevant topics, forcing a subscription change */
        this.toggleTopic(fullPath)
        /** Re-select relevant topics, forcing re-subscription */
        this.toggleTopic(fullPath)
    }

    /**
     * (Un)subscribe to a topic
     */
    toggleTopic (fullPath: TopicPath): void {
        const allSelected = new Unique<boolean>()

        this.updateTopics(
            (topic) => {
                const matched = ExplorerUsecase.isParentOrPathOf(fullPath, topic.path)
                if (matched) {
                    allSelected.add(topic.selected)
                }
                return matched
            },
            () => !allSelected.getUniqueValue(false)
        )
    }

    removeTopic (path: TopicPath): void {
        const { topics } = selectLoadedExplorer(this.getState())
        this.setExplorer({
            topics: topics.filter((current) => current.source !== TopicType.CUSTOM || !ExplorerUsecase.isParentOrPathOf(path, current.path)),
        })
    }

    addTopic (rawPath: string): boolean {
        const path = this.topicsService.parseTopic(rawPath)
        const { topics, filter } = selectLoadedExplorer(this.getState())

        if (topics.some((current) => ExplorerUsecase.areTopicPathsEquals(path, current.path))) {
            /**
             * Topic is already covered, no need to add it
             */
            return false
        }

        this.setExplorer({
            topics: [...topics, { path, rawPath, source: TopicType.CUSTOM, selected: true, subscriptionErrors: [] }],

            /**
             * If custom topics are hiddenthey should be made visible
             */
            filter: { ...filter, types: Array.from(new Set(filter.types).add(TopicType.CUSTOM)) },
        })

        return true
    }
}
