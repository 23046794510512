import React, { type FC } from 'react'
import { Box, Link, svgIconClasses, type SxProps, type Theme, Typography, typographyClasses } from '@mui/material'
import { Warning } from '@mui/icons-material'

import { Translation } from '../../../../domain'

import { DocumentationType } from '../../../../application'

import { createFormatter, FormattedTranslation, useTranslator } from '../../Internationalization'
import { useAppUsecase } from '../../State'
import { useDocumentation } from '../../Documentation'
import { TiniestButton } from '../../common'
import { TiniestCopy } from '../../Copy'

import { useTransformation } from './Hooks'

const textareaStyle: SxProps<Theme> = {
    p: 2,
    lineHeight: 1.5,
    backgroundColor: '#f6f8fa',
    border: 'solid 1px',
    borderColor: 'grey.500',
    outline: 'none',
    color: 'grey.900',
    width: '100%',
    height: '100%',
    resize: 'vertical',
    minHeight: 50,
}

const helperTextStyle: SxProps<Theme> = {
    display: 'flex',
    mt: 1.5,
    [[typographyClasses.root, svgIconClasses.root].map((clazz) => `& > .${clazz}`).join(',')]: { my: 'auto' },
}

export const TransformationInputTitle: FC = () => {
    const transformation = useTransformation()
    const open = useDocumentation(DocumentationType.RULE_ENGINE_PARSE_RULE)

    return (
        <>
            <FormattedTranslation id={Translation.TRANSFORMATION_RULE} values={{ copy: transformation && <TiniestCopy>{transformation}</TiniestCopy> }} />
            <TiniestButton onClick={open}>
                <FormattedTranslation id={Translation.HELP} />
            </TiniestButton>
        </>
    )
}

export const TransformationInputHelperText: FC = () => {
    const openTransformDocumentation = useDocumentation(DocumentationType.RULE_ENGINE_PARSE_RULE)
    return (
        <Box sx={helperTextStyle}>
            <Warning color="action" />
            <Typography variant="body2" color="textSecondary" data-testid="transform-helper-text">
                <FormattedTranslation
                    id={Translation.TRANSFORMATION_INPUT_HELPER_TEXT}
                    values={{ link: createFormatter(Link, { onClick: openTransformDocumentation }) }}
                />
            </Typography>
        </Box>
    )
}

export const TransformationInput: FC = () => {
    const translator = useTranslator()
    const transformation = useTransformation()
    const filter = useAppUsecase('filterSandboxUsecase')

    return (
        <Box
            component="textarea"
            id="rule-engine-rule-input"
            sx={textareaStyle}
            placeholder={translator.formatTranslation(Translation.RULE_ENGINE_SANDBOX_TRANSFORMATION_PLACEHOLDER)}
            value={transformation}
            onChange={({ target }) => filter.setTransformation(target.value)}
        />
    )
}
