import React, { type FC, useEffect } from 'react'
import { Grid, type SxProps, type Theme } from '@mui/material'

import { isEnumOf } from '../../../utils'

import { selectExplorerError, Translation } from '../../../domain'

import { useAppState, useAppUsecase } from '../State'
import { FormattedTranslation } from '../Internationalization'
import { DetailsHeader } from '../common'
import { ErrorMessage } from '../ErrorMessage'

import { AvailableTopics } from './AvailableTopics'
import { MonitoredTopics } from './MonitoredTopics'
import { History } from './History'

const spacing = 2

const leftColumnStyle: SxProps<Theme> = { p: spacing, borderRight: '1px solid', borderColor: 'grey.300' }
const monitoredRowStyle: SxProps<Theme> = { p: spacing, borderBottom: '1px solid', borderColor: 'grey.300' }
const historyRowStyle: SxProps<Theme> = { p: spacing }

export const Explorer: FC = () => {
    const error = useAppState(selectExplorerError)

    const startExplorer = useAppUsecase('startExplorerSubscriptionUsecase')
    useEffect(() => startExplorer.invoke(), [startExplorer])

    if (error !== null) {
        return (
            <ErrorMessage
                data-testid="explorer-general-error"
                error={error}
                stack
                extras="section"
                customTitle={(e) => (isEnumOf(Translation, e.message) ? <FormattedTranslation id={e.message} /> : null)}
            />
        )
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <DetailsHeader
                    title={{ title: <FormattedTranslation id={Translation.DATA_EXPLORER} /> }}
                    section={{ title: Translation.DATA_EXPLORER_DOCUMENTATION_TITLE, body: Translation.DATA_EXPLORER_DOCUMENTATION_BODY }}
                />
            </Grid>
            <Grid item xs={4} sx={leftColumnStyle}>
                <AvailableTopics />
            </Grid>
            <Grid item xs={8}>
                <Grid container direction="column">
                    <Grid item xs={12} sx={monitoredRowStyle}>
                        <MonitoredTopics />
                    </Grid>
                    <Grid item xs={12} sx={historyRowStyle}>
                        <History />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
