import type { Intersection } from 'utility-types'
import type { VrpcProxy } from 'vrpc'

import type { ReadonlyRecord } from '../../../../../utils'

import type { SubscriptionsTypes } from '../../../../../application'

import type {
    ConnectionProxy,
    ContainerManagerOrchestratorProxy,
    EndpointProxy,
    MappingProxy,
    NodeProxy,
    ResourceStateListenerProxy,
    ServerProxy,
    ServiceProxy,
} from '../../../proxies'

import {
    VrpcDetailedServiceProxyInstanceHandler,
    VrpcLinkedServiceProxyInstanceHandler,
    VrpcServiceProxyInstanceHandler,
    VrpcServiceProxyInstanceToNotificationHandler,
} from './Service'
import { VrpcConnectionProxyInstanceHandler, VrpcDetailedConnectionProxyInstanceHandler } from './Connection'
import { VrpcDetailedMappingProxyInstanceHandler, VrpcMappingProxyInstanceHandler } from './Mapping'
import { VrpcDetailedCEndpointProxyInstanceHandler, VrpcResourceStateListenerEndpointProxyInstanceHandler } from './Endpoint'
import { VrpcEndpointProxyInstanceStatusHandler, VrpcMappingProxyInstanceStatusHandler, VrpcNodeProxyInstanceStatusHandler } from './Status'
import {
    VrpcContainerManagerOrchestratorProxyToCoreContainerHandler,
    VrpcContainerManagerOrchestratorProxyToDetailedCoreContainerHandler,
    VrpcContainerManagerOrchestratorProxyToDetailedServiceContainerHandler,
    VrpcContainerManagerOrchestratorProxyToDetailedVolumeHandler,
    VrpcContainerManagerOrchestratorProxyToServiceContainerHandler,
    VrpcContainerManagerOrchestratorProxyToVolumeHandler,
} from './ContainerManager'
import { VrpcNodeProxyToCybusNodeHandler } from './Node'
import { VrpcServerProxyToCybusServerHandler, VrpcServerProxyToCybusServersHandler } from './Server'
import { VrpcRemoteToCybusAgentHandler } from './Agent'

import type { VrcpEntityHandler } from './Handler'

/**
 * The types expected from VRPC
 */
export interface VrpcInstanceTypes extends ReadonlyRecord<keyof SubscriptionsTypes, VrpcProxy> {
    services: ServiceProxy
    service: ServiceProxy
    deviations: ServiceProxy
    servicesLinks: ServiceProxy
    connection: ConnectionProxy
    connections: ConnectionProxy
    mappings: MappingProxy
    mapping: MappingProxy
    mappingStatus: MappingProxy
    endpointStatus: EndpointProxy
    nodeStatus: NodeProxy
    endpoints: ResourceStateListenerProxy
    endpoint: EndpointProxy
    volume: ContainerManagerOrchestratorProxy
    volumes: ContainerManagerOrchestratorProxy
    coreContainer: ContainerManagerOrchestratorProxy
    coreContainers: ContainerManagerOrchestratorProxy
    serviceContainer: ContainerManagerOrchestratorProxy
    serviceContainers: ContainerManagerOrchestratorProxy
    nodes: NodeProxy
    servers: ServerProxy
    server: ServerProxy
    agents: never
}

type SupportedHandlerNames = keyof Intersection<SubscriptionsTypes, VrpcInstanceTypes>

type HandlersCheckerType<T, VrpcTypes> = { [P in keyof T & keyof VrpcTypes]: new () => VrcpEntityHandler<VrpcTypes[P], T[P]> }

const handlers: HandlersCheckerType<SubscriptionsTypes, VrpcInstanceTypes> = {
    service: VrpcDetailedServiceProxyInstanceHandler,
    services: VrpcServiceProxyInstanceHandler,
    deviations: VrpcServiceProxyInstanceToNotificationHandler,
    servicesLinks: VrpcLinkedServiceProxyInstanceHandler,
    connection: VrpcDetailedConnectionProxyInstanceHandler,
    connections: VrpcConnectionProxyInstanceHandler,
    mappings: VrpcMappingProxyInstanceHandler,
    mapping: VrpcDetailedMappingProxyInstanceHandler,
    mappingStatus: VrpcMappingProxyInstanceStatusHandler,
    endpointStatus: VrpcEndpointProxyInstanceStatusHandler,
    nodeStatus: VrpcNodeProxyInstanceStatusHandler,
    endpoints: VrpcResourceStateListenerEndpointProxyInstanceHandler,
    endpoint: VrpcDetailedCEndpointProxyInstanceHandler,
    volume: VrpcContainerManagerOrchestratorProxyToDetailedVolumeHandler,
    volumes: VrpcContainerManagerOrchestratorProxyToVolumeHandler,
    serviceContainers: VrpcContainerManagerOrchestratorProxyToServiceContainerHandler,
    serviceContainer: VrpcContainerManagerOrchestratorProxyToDetailedServiceContainerHandler,
    coreContainers: VrpcContainerManagerOrchestratorProxyToCoreContainerHandler,
    coreContainer: VrpcContainerManagerOrchestratorProxyToDetailedCoreContainerHandler,
    nodes: VrpcNodeProxyToCybusNodeHandler,
    servers: VrpcServerProxyToCybusServersHandler,
    server: VrpcServerProxyToCybusServerHandler,
    agents: VrpcRemoteToCybusAgentHandler,
}

/**
 * Gets the vrpc instance handler that can convert VRPC events/instances into useful domain objects
 */
export const getVrpcEntityHandler = <T extends SupportedHandlerNames>(mapperName: T): VrcpEntityHandler<VrpcInstanceTypes[T], SubscriptionsTypes[T]> =>
    new handlers[mapperName]()
