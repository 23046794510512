import { selectLoadedExplorer } from '../../../domain'

import { ExplorerUsecase } from './Explorer'

export class UpdateExplorerTailUsecase extends ExplorerUsecase {
    updateTailSize (tailSize: number): void {
        this.setExplorer({ tailSize: Math.max(tailSize, 0) })
    }

    toggleTailing (): void {
        const { isTailing } = selectLoadedExplorer(this.getState())
        this.setExplorer({ isTailing: !isTailing })
    }

    clearTail (): void {
        this.setExplorer({ tail: [] })
    }
}
