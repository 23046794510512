import React, { type FC } from 'react'
import { Box, Button, DialogContent, Link, type SxProps, type Theme, Typography } from '@mui/material'
import { CloudUpload } from '@mui/icons-material'

import { type ConnectwareError, isManagedServiceUpToDate, Translation } from '../../../../domain'

import { DocumentationType } from '../../../../application'

import { readFirstFileAsText } from '../../utils'
import { createFormatter, FormattedDateTime, FormattedList, FormattedTranslation, useTranslator } from '../../Internationalization'
import { AbsoluteRouteOnlyPath, useRouting } from '../../routing'
import { useDocumentation } from '../../Documentation'
import { FileUpload, Table } from '../../common'

import { useCancel, useFile, useManaged, useOnFileChange, useSupportedCommissioningFileTypes, useSupportedCommissioningFileTypesList } from './Hooks'

const uploadFormStyle: SxProps<Theme> = { textAlign: 'center' }
const uploadDialogContentStyle: SxProps<Theme> = { display: 'flex', flexDirection: 'column' }
const uploadDialogEntryStyle: SxProps<Theme> = { display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1 }
const uploadIconStyle: SxProps<Theme> = { mr: 2, color: 'secondary.main' }
const tableWrapperStyle: SxProps<Theme> = { my: 2 }

const SimpleFileUploadSection: FC = () => {
    const file = useFile()
    const accept = useSupportedCommissioningFileTypesList()
    const onFileChange = useOnFileChange()

    const managed = useManaged()

    if (managed !== null) {
        return null
    }

    return (
        <Box id="file-upload-section" sx={uploadDialogEntryStyle}>
            <CloudUpload sx={uploadIconStyle} />
            <FileUpload
                initialValue={file ? [file] : undefined}
                accept={accept}
                onChange={({ target }) =>
                    void readFirstFileAsText(target.files)
                        .catch((e: ConnectwareError) => e)
                        .then((content) => onFileChange(content))
                }
            />
        </Box>
    )
}

const ManagedFileUploadSection: FC = () => {
    const onFileChange = useOnFileChange()
    const translator = useTranslator()

    const managed = useManaged()

    if (managed === null || isManagedServiceUpToDate(managed)) {
        return null
    }

    const { commissioningFile: newCommissioningFile, updatedAt: newUpdatedAt, version: newVersion } = managed.new
    const { version: currentVersion, updatedAt: currentUpdatedAt } = managed.current

    return (
        <>
            <Box sx={tableWrapperStyle}>
                <Table
                    data={[
                        { label: translator.formatTranslation(Translation.VERSION), current: currentVersion, new: newVersion },
                        {
                            label: translator.formatTranslation(Translation.LAST_UPDATED),
                            current: <FormattedDateTime date={currentUpdatedAt} format="datetime" />,
                            new: <FormattedDateTime date={newUpdatedAt} format="datetime" />,
                        },
                    ]}
                    columns={{ label: {}, current: { label: Translation.CURRENT }, new: { label: Translation.NEW } }}
                />
            </Box>
            <Button id="update-service-from-catalog" size="small" variant="outlined" onClick={() => onFileChange(newCommissioningFile)}>
                <FormattedTranslation id={Translation.USE_VERSION} values={{ version: newVersion }} />
            </Button>
        </>
    )
}

export const FileUploadSection: FC = () => {
    const supportedCommissioningFileTypes = useSupportedCommissioningFileTypes()
    const redirect = useRouting()

    const cancel = useCancel()
    const managed = useManaged()

    const open = useDocumentation(DocumentationType.SERVICES)

    return (
        <Box sx={uploadFormStyle}>
            <DialogContent sx={uploadDialogContentStyle}>
                <Typography id="file-upload-summary-1" variant="subtitle1" align="left">
                    {'1. '}
                    <FormattedTranslation
                        id={managed === null ? Translation.SERVICE_SELECT_COMMISSIONING_FILE : Translation.SERVICE_UPDATE_FROM_CATALOG}
                        values={{
                            types: <FormattedList type="disjunction" value={supportedCommissioningFileTypes} />,
                            servicesCatalogLink: redirect.isAtRoute(AbsoluteRouteOnlyPath.CATALOG)
                                ? createFormatter(({ children }) => <>{children}</>, {})
                                : createFormatter(Link, {
                                      onClick: () => {
                                          cancel()
                                          redirect.redirect(AbsoluteRouteOnlyPath.CATALOG)
                                      },
                                  }),
                        }}
                    />
                </Typography>
                <Typography id="file-upload-summary-2" variant="subtitle2" align="left">
                    <FormattedTranslation
                        id={
                            managed === null
                                ? Translation.SERVICE_DOCUMENTATION_LINK
                                : isManagedServiceUpToDate(managed)
                                ? Translation.SERVICE_UPDATE_NOT_AVAILABLE
                                : Translation.SERVICE_UPDATE_AVAILABLE
                        }
                        values={{
                            documentationLink: createFormatter(Link, { onClick: open }),
                            managedName: (
                                <b>
                                    {managed?.directory}
                                    {managed?.filename}
                                </b>
                            ),
                        }}
                    />
                </Typography>

                <SimpleFileUploadSection />
                <ManagedFileUploadSection />
            </DialogContent>
        </Box>
    )
}
