import React, { type FC } from 'react'

import { Capability, type CybusDetailedEndpoint, StatusType, Translation } from '../../../../domain'

import { useAppUsecases } from '../../State'
import { DetailsActionButton } from '../../Details'

const disableButtonLabels: Partial<Record<StatusType, Translation>> = { [StatusType.ENABLED]: Translation.DISABLE, [StatusType.ENABLING]: Translation.ENABLING }

export const HeaderActions: FC<{ resource: CybusDetailedEndpoint }> = ({ resource: { id, status } }) => {
    const { bulkDeleteEndpointsUsecase, bulkEnableEndpointsUsecase, bulkDisableEndpointsUsecase } = useAppUsecases()

    return (
        <>
            <DetailsActionButton
                id="endpoint-action-button"
                disabled={status !== StatusType.ENABLED && status !== StatusType.DISABLED}
                translation={disableButtonLabels[status] || Translation.ENABLE}
                capabilities={[Capability.ENDPOINTS_MANAGE]}
                onClick={
                    status === StatusType.ENABLED
                        ? () => bulkDisableEndpointsUsecase.selectEndpoints([id])
                        : () => bulkEnableEndpointsUsecase.selectEndpoints([id])
                }
            />

            <DetailsActionButton
                id="delete-endpoint-button"
                translation={Translation.DELETE}
                capabilities={[Capability.ENDPOINTS_MANAGE]}
                onClick={() => bulkDeleteEndpointsUsecase.selectEndpoints([id])}
            />
        </>
    )
}
