import React, { type FC } from 'react'
import { Divider, Grid, Typography } from '@mui/material'

import { selectExplorerTail, Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'
import { useAppState } from '../../State'
import { Delay } from '../../common'

import { Section } from '../Section'
import { TailSize } from './TailSize'
import { Controls } from './Controls'
import { Header } from './Header'
import { TailLine } from './Line'

const Content: FC = () => {
    const tail = useAppState(selectExplorerTail)

    return (
        <Delay delay={300}>
            {tail.length ? (
                tail.map((line, k) => <TailLine key={k} {...line} />)
            ) : (
                <Typography variant="body2" align="center">
                    <FormattedTranslation id={Translation.NO_MESSAGES} />
                </Typography>
            )}
        </Delay>
    )
}

export const History: FC = () => (
    <Section
        id="topicsHistory"
        title={Translation.HISTORY}
        controls={
            <>
                <Controls />
                <TailSize />
            </>
        }
    >
        <Divider />
        <Header />

        <Grid id="topicsTail" item xs={12}>
            <Content />
        </Grid>
    </Section>
)
