import React, { type FC } from 'react'
import { Divider, Grid } from '@mui/material'
import { Build, Description, PersonRounded, Security, Tag, VpnKey } from '@mui/icons-material'

import {
    areClientRegistryRequestFormsEqual,
    type ClientRegistryRequestForm,
    isClientRegistryRequestCreatable,
    selectUsersManagementRegistryRequest,
    Translation,
} from '../../../../domain'

import { EntryInput, EntryInputLabel, GridFormEntry, GridFormItem, ManagedDialog, type ManagedDialogContentProps } from '../Modal'
import { AuthenticationMethodsForm, MqttPublishPrefixInput, MqttPublishPrefixInputValidation } from '../Users'
import { PermissionsTable, PermissionsTemplateSection } from '../Permissions'
import { RolesDropdown } from '../Roles'

type Props = ManagedDialogContentProps<ClientRegistryRequestForm, 'allowClientRegistryRequestUsecase'>

const RegistryModalContent: FC<Props> = ({
    loading,
    usecase,
    advanced,
    form: {
        username,
        roles,
        authenticationMethods,
        permissions,
        rolesPermissions,
        mqttPublishPrefix,
        request,
        mqttPublishPrefixValidation,
        templateInput,
        templates,
        selectedTemplate,
        roleInput,
        allRoles,
    },
}) => {
    const disabled = loading

    return (
        <Grid container spacing={2} alignContent="center" alignItems="center">
            <GridFormEntry labelSize={3} label={<EntryInputLabel icon={PersonRounded} label={Translation.USERNAME} />}>
                <EntryInput data-testid="username" value={username} disabled />
            </GridFormEntry>
            <GridFormEntry labelSize={3} label={<EntryInputLabel icon={Description} label={Translation.CONTEXT} />}>
                {request.description}
            </GridFormEntry>
            <GridFormEntry labelSize={3} label={<EntryInputLabel icon={Security} label={Translation.ROLE} values={{ count: 2 }} />}>
                <RolesDropdown disabled={disabled} required={request.roles} roleInput={roleInput} roles={roles} allRoles={allRoles} usecase={usecase} />
            </GridFormEntry>

            <GridFormItem expand={advanced} size={12}>
                <Divider />
            </GridFormItem>

            <GridFormEntry expand={advanced} label={<EntryInputLabel icon={Build} label={Translation.ADDITIONAL_PERMISSION} values={{ count: 0 }} />} />
            <PermissionsTemplateSection
                expand={advanced}
                disabled={disabled}
                usecase={usecase}
                templateInput={templateInput}
                templates={templates}
                selectedTemplate={selectedTemplate}
            />
            <GridFormItem expand={advanced} size={12}>
                <PermissionsTable disabled={disabled} permissions={permissions} required={request.permissions} inherited={rolesPermissions} usecase={usecase} />
            </GridFormItem>

            <GridFormEntry expand={advanced} label={<EntryInputLabel icon={VpnKey} label={Translation.AUTHENTICATION_METHODS} />}>
                <AuthenticationMethodsForm
                    authenticationMethods={authenticationMethods}
                    required={[request.authenticationMethod]}
                    disabled={disabled}
                    usecase={usecase}
                />
            </GridFormEntry>
            <GridFormEntry expand={advanced} label={<EntryInputLabel icon={Tag} label={Translation.MQTT_PUBLISH_PREFIX} />}>
                <MqttPublishPrefixInput mqttPublishPrefix={mqttPublishPrefix} disabled={disabled} usecase={usecase} />
            </GridFormEntry>

            <MqttPublishPrefixInputValidation mqttPublishPrefixValidation={mqttPublishPrefixValidation} />
        </Grid>
    )
}

export const RegistryRequestDialog: FC = () => (
    <ManagedDialog
        maxWidth="md"
        title={Translation.GRANT_ACCESS}
        usecase="allowClientRegistryRequestUsecase"
        formSelector={selectUsersManagementRegistryRequest}
        areEquals={areClientRegistryRequestFormsEqual}
        isConfirmable={isClientRegistryRequestCreatable}
        advanced={false}
        resetable
        confirmation={Translation.ALLOW}
    >
        {RegistryModalContent}
    </ManagedDialog>
)
