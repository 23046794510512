import { createInterval, createTimeout, Droppable } from '../../../../utils'

import {
    areClientRegistryDataEquals,
    type ClientRegistry,
    type ClientRegistryData,
    ConnectwareError,
    selectUsersManagementRegistry,
    selectUsersManagementRegistryData,
    selectUsersManagementRegistryLock,
} from '../../../../domain'

import { ClientRegistryUsecase } from './Base'

export class LoadClientRegistryUsecase extends ClientRegistryUsecase {
    private mapDataUpdate (response: ClientRegistryData | ConnectwareError): ClientRegistry['data'] {
        const data = selectUsersManagementRegistryData(this.getState())

        if (ConnectwareError.is(response) || ConnectwareError.is(data) || data === null || !areClientRegistryDataEquals(response, data)) {
            /**
             * Data should be updated if
             *  - there is an error on the response
             *  - previous data was an error
             *  - previous data was not loaded
             *  - is different
             */
            return response
        }

        // Keep same as old one
        return data
    }

    private mapLockedUpdate (response: ClientRegistryData | ConnectwareError): ClientRegistry['locked'] {
        const locked = selectUsersManagementRegistryLock(this.getState())

        if (ConnectwareError.is(response)) {
            /** There was an error, just use current status */
            return locked
        }

        const isLocked = response.unlockedUntil === null

        if (locked !== isLocked) {
            /**
             * User hasn't actively locked or unlocked
             * Or data is missaligned with the users selection
             * So use the data
             */
            return isLocked
        }

        return locked
    }

    private async fetchRegistry (): Promise<ClientRegistry> {
        const response = await this.clientRegistryService.fetchData().catch((e: ConnectwareError) => e)
        return { locked: this.mapLockedUpdate(response), data: this.mapDataUpdate(response) }
    }

    private scheduleRelock (droppable: Pick<Droppable, 'onDrop'>): void {
        const data = selectUsersManagementRegistryData(this.getState())

        if (ConnectwareError.is(data) || data === null || data.unlockedUntil === null) {
            /** Nothing to be locked */
            return
        }

        const unlockedUntil = data.unlockedUntil

        droppable.onDrop(() =>
            createTimeout(() => {
                const currentRegistry = selectUsersManagementRegistry(this.getState())

                if (data === currentRegistry.data) {
                    /** Registry window is now closed, time to lock */
                    this.setRegistry({ locked: true, data: { ...data, unlockedUntil: null } })
                }
            }, unlockedUntil.getTime() - Date.now())
        )
    }

    private async load (droppable: Droppable): Promise<void> {
        const oldRegistry = selectUsersManagementRegistry(this.getState())
        const update = await this.fetchRegistry()
        const registry = selectUsersManagementRegistry(this.getState())

        if (oldRegistry !== registry || droppable.isDropped || (registry.locked === update.locked && registry.data === update.data)) {
            /**
             * It was either
             *  - updated in the in-between
             *  - dropped in the between
             *  - no actual change in the data
             * Therefore, no need to do anything
             */
            return
        }

        this.setRegistry(update)
        this.scheduleRelock(droppable)
    }

    invoke (): VoidFunction {
        const droppable = new Droppable()

        const load: VoidFunction = () => void this.load(droppable)

        // Already update the registry
        load()

        // Schedule regulare update
        droppable.onDrop(createInterval(load, this.configurationService.getClientRegistryRefreshRate()))

        // Schedule load when state has changed
        droppable.onDrop(this.subscribeToState((a, b) => selectUsersManagementRegistryLock(a) === selectUsersManagementRegistryLock(b), load))

        return () => droppable.drop()
    }
}
