import { mapConnection, VALID_CONNECTION_INSTANCE_PATTERN } from '../../../../../Connectware'
import { CONNECTION_CLASSNAME_FILTER } from '../../../../constants'
import { excludeInstanceByServiceName, type VrpcHandlerConfiguration } from '../Handler'

import { BaseConnectionMapper, VrpcBaseConnectionProxyInstanceHandler } from './Base'

class ConnectionMapper extends BaseConnectionMapper<'connections'> {
    protected mapConnection = mapConnection
}

export class VrpcConnectionProxyInstanceHandler extends VrpcBaseConnectionProxyInstanceHandler<'connections'> {
    readonly instanceMapper = new ConnectionMapper()

    readonly configuration: VrpcHandlerConfiguration = {
        classNameFilter: CONNECTION_CLASSNAME_FILTER,
        // Ignore __internal__ connection
        instanceNameFilter: VALID_CONNECTION_INSTANCE_PATTERN,
        supportedFilters: ['service'],
        excludeByInstanceName: excludeInstanceByServiceName,
    }
}
