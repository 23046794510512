import { type AppState, ConnectwareError, ConnectwareErrorType } from '../../../domain'

import type { ListSubscriptionsTypes, SingleSubscriptionsTypes, SubscriptionEvent, SubscriptionFilterArgs, SubscriptionListEvent } from '../..'

import { Usecase } from '..'
import { LogoutUsecase } from '../Authentication'

import { SubscriptionToAllBuilder, SubscriptionToSingleBuilder } from './SubscriptionBuilder'

/**
 * Describes the behaviour of a generic unifinished subscriptuion to a Connectware resource
 * Relies heavily on the `subscriptionsService`
 * @deprecated remove on CC-1024
 * @todo replace this usecase with ResourcePageSubscriptionUsecase and ResourceSingleSubscriptionUsecase
 * @see {ResourcePageSubscriptionUsecase}
 * @see {ResourceSingleSubscriptionUsecase}
 */
export abstract class ResourceSubscriptionUsecase<
    SingularName extends keyof SingleSubscriptionsTypes,
    ListName extends keyof ListSubscriptionsTypes
> extends Usecase {
    /**
     * The single event name and by reflection,
     * type the implementation wants to subscribe
     */
    protected abstract readonly singularName: SingularName

    /**
     * The single event name and by reflection,
     * type the implementation wants to subscribe
     */
    protected abstract readonly listName: ListName

    /**
     * @description
     *  each implementation needs to map out what an event
     *  should look like in the updated application state
     */
    protected mapSingularEvents (event: SubscriptionEvent<SingularName> | null): Partial<AppState> {
        throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Single subscription not implemented', { event })
    }

    /**
     * @description
     *  each implementation needs to map out what an event
     *  should look like in the updated application state
     */
    protected abstract mapListEvents (event: SubscriptionListEvent<ListName> | null): Partial<AppState>

    /**
     * @returns an unsub function, to be called when the state should stop being updated
     */
    subscribe (idOrFilter: string | SubscriptionFilterArgs = {}): VoidFunction {
        const { singularName, listName, subscriptionsService } = this
        const logoutUsecase = this.getUsecase(LogoutUsecase)

        let initialState: Partial<AppState>
        let builder: SubscriptionToSingleBuilder<SingularName> | SubscriptionToAllBuilder<ListName>

        if (typeof idOrFilter === 'string') {
            initialState = this.mapSingularEvents(null)
            builder = new SubscriptionToSingleBuilder(singularName, subscriptionsService, logoutUsecase)
                .onChange((resource) => this.setState(this.mapSingularEvents(resource)))
                .withId(idOrFilter)
        } else {
            initialState = this.mapListEvents(null)
            builder = new SubscriptionToAllBuilder(listName, subscriptionsService, logoutUsecase)
                .onChange((resources) => this.setState(this.mapListEvents(resources)))
                .withFilter(idOrFilter)
        }

        this.setState(initialState)

        /**
         * This will take care of updating the resources state whenever there are changes on the resources
         */
        const off = builder.subscribe()

        return () => {
            /**
             * Unsubscribe for further changes
             */
            off()

            /**
             * Clear information to initial state
             */
            this.setState(initialState)
        }
    }
}
