import { normalize } from '../../../utils'

import { ConnectwareError, ConnectwareErrorType, type PaginatedData } from '../../../domain'

import { type PaginationConfiguration } from './mappers'

type InternalArgs<T = never, R = never> = Readonly<{
    search: string
    exactSearch: boolean
    showInternal: boolean | null
    pageNumber: number
    pageSize: number
    mapper: (response: R) => T
    noMatches: T
}>

type FilterValuesMapperArgs = Pick<InternalArgs, 'search' | 'showInternal' | 'exactSearch'> & Readonly<{ rawSearch: string }>

/**
 * This is an utility class that helps the http user service to fetch roles, users and permissions data
 */
export class PaginatedDataFetcher<Domain, Index, Response> {
    protected readonly pagination: PaginationConfiguration

    /** Function to yield indexes so everything is faster to search */
    private readonly requestIndex: () => Promise<Index[]>

    /** Function to actually retrieve the paginated response */
    private readonly requestInformation: <T>(params: URLSearchParams, pageMapper: (response: Response) => T) => Promise<T>

    /** If the index should be used */
    private readonly shouldFilter?: (args: Pick<InternalArgs, 'showInternal'>) => boolean

    /** The parameter that will take in the filtered values */
    private readonly filterField: string

    /** The function to turn the yielded indexed values into filter parameters */
    private readonly filterValuesMapper: (index: Index, args: FilterValuesMapperArgs) => string[]

    /** Url parameters that ought to always be present */
    private readonly createStaticParameters?: (args: Pick<InternalArgs, 'showInternal'>) => Record<string, unknown>

    private readonly mapPageResponse: (response: Response) => PaginatedData<Domain>

    constructor ({
        pagination,
        indexRequester,
        informationRequester,
        shouldFilter,
        filterParameter,
        createStaticParameters,
        mapPageResponse,
        filterParameterValuesMapper,
    }: {
        indexRequester: PaginatedDataFetcher<Domain, Index, Response>['requestIndex']
        informationRequester: PaginatedDataFetcher<Domain, Index, Response>['requestInformation']
        shouldFilter?: PaginatedDataFetcher<Domain, Index, Response>['shouldFilter']
        filterParameter: PaginatedDataFetcher<Domain, Index, Response>['filterField']
        filterParameterValuesMapper: PaginatedDataFetcher<Domain, Index, Response>['filterValuesMapper']
        pagination: PaginatedDataFetcher<Domain, Index, Response>['pagination']
        createStaticParameters?: PaginatedDataFetcher<Domain, Index, Response>['createStaticParameters']
        mapPageResponse: PaginatedDataFetcher<Domain, Index, Response>['mapPageResponse']
    }) {
        this.pagination = pagination
        this.requestIndex = indexRequester
        this.requestInformation = informationRequester
        this.shouldFilter = shouldFilter
        this.filterField = filterParameter
        this.createStaticParameters = createStaticParameters
        this.mapPageResponse = mapPageResponse
        this.filterValuesMapper = filterParameterValuesMapper
    }

    private async createParams ({
        search,
        exactSearch,
        showInternal,
        pageNumber,
        pageSize,
    }: Pick<InternalArgs, 'search' | 'showInternal' | 'pageNumber' | 'pageSize' | 'exactSearch'>): Promise<URLSearchParams | null> {
        const { shouldFilter = () => false, createStaticParameters = () => ({}), filterValuesMapper, filterField } = this
        const params = new URLSearchParams()

        const args: FilterValuesMapperArgs = {
            search: normalize(search.trim()),
            showInternal,
            exactSearch,
            rawSearch: search,
        }

        if (args.exactSearch || Boolean(args.search) || shouldFilter(args) || Boolean(args.rawSearch)) {
            const matches = (await this.requestIndex()).flatMap((entry) => filterValuesMapper(entry, args))

            if (matches.length === 0) {
                /** No match was found */
                return null
            }

            matches.forEach((value) => params.append(filterField, value))
        }

        /** Set pagination */
        params.set('rowsPerPage', String(pageSize))
        params.set('pageNumber', String(pageNumber))

        /** Append other static fields */
        Object.entries(createStaticParameters({ showInternal })).forEach(([key, value]) => params.set(key, String(value)))

        return params
    }

    protected async request<T> ({ mapper, noMatches, ...paramsArgs }: InternalArgs<T, Response>): Promise<T> {
        const params = await this.createParams(paramsArgs)
        return params ? this.requestInformation(params, (response) => mapper(response)) : noMatches
    }

    fetchPage (search: string, showInternal: boolean, pageNumber: number): Promise<PaginatedData<Domain>> {
        const {
            mapPageResponse: mapper,
            pagination: { pageSize },
        } = this

        return this.request({
            search,
            exactSearch: false,
            showInternal,
            pageNumber,
            pageSize,
            mapper,
            noMatches: { current: [], totalCount: 0, pageSize, page: 1 },
        })
    }
}

type ListDataFetcherArgs<Domain, Index, Response> = ConstructorParameters<typeof PaginatedDataFetcher<Domain, Index, Response>>[0] &
    Readonly<{ mapListResponse: ListDataFetcher<Domain, Index, Response>['mapListResponse'] }>

export class ListDataFetcher<Domain, Index, Response> extends PaginatedDataFetcher<Domain, Index, Response> {
    private readonly mapListResponse: (response: Response) => Domain[]

    constructor ({ mapListResponse, ...args }: ListDataFetcherArgs<Domain, Index, Response>) {
        super(args)
        this.mapListResponse = mapListResponse
    }

    fetch (search: string): Promise<Domain[]> {
        const { mapListResponse, pagination } = this

        return this.request({
            search,
            exactSearch: false,
            showInternal: false,
            pageNumber: 1,
            pageSize: pagination.searchSize,
            mapper: mapListResponse,
            noMatches: [],
        })
    }

    fetchOne (name: string): Promise<Domain> {
        const { mapListResponse } = this

        return this.request({ search: name, exactSearch: true, showInternal: null, pageNumber: 1, pageSize: 1, mapper: mapListResponse, noMatches: [] }).then(
            ([first]) => first ?? Promise.reject(new ConnectwareError(ConnectwareErrorType.NOT_FOUND, 'Entity not found', { name }))
        )
    }
}
