import React, { type FC, type PropsWithChildren } from 'react'
import { type SxProps, type Theme } from '@mui/material'
import { AddRounded } from '@mui/icons-material'
import type { MUIDataTableState } from 'mui-datatables'

import {
    type AppState,
    ConnectwareError,
    type PaginatedData,
    selectUsersManagementPage,
    selectUsersManagementTransfering,
    type Translation,
} from '../../../domain'

import { useAppState, useAppUsecase } from '../State'

import { Table as BaseTable, Chip, CircularLoader, type TableLine, type TableProps } from '../common'
import { ErrorMessage } from '../ErrorMessage'

const errorStyle: SxProps<Theme> = { pt: 2 }

type Props<L extends TableLine,> = Readonly<{
    selector: (s: AppState) => PaginatedData<L> | ConnectwareError | null
    notFound: Translation
    onAdd?: VoidFunction
    addLabel?: Translation
}> &
    Pick<TableProps<L>, 'columns' | 'onRowClick'>

export const Table = <L extends TableLine,>({ selector, notFound, columns, ...props }: PropsWithChildren<Props<L>>): ReturnType<FC> => {
    const data = useAppState(selector)
    const page = useAppState(selectUsersManagementPage)
    const isTransfering = useAppState(selectUsersManagementTransfering)

    const usecase = useAppUsecase('filterUserManagementUsecase')

    if (data === null) {
        return <CircularLoader data-testid="users-management-loader" size="4rem" />
    }

    if (ConnectwareError.is(data)) {
        return <ErrorMessage sx={errorStyle} data-testid="users-management-error" error={data} stack extras="section" />
    }

    const [onAdd, addLabel, onRowClick, onCustomized] = isTransfering
        ? []
        : [props.onAdd, props.addLabel, props.onRowClick, (s: MUIDataTableState) => usecase.setPage(s.page + 1)]

    return (
        <BaseTable
            columns={columns}
            data={data.current}
            translations={{ emptyTable: notFound }}
            extendedToolbar={onAdd && addLabel && <Chip data-testid="add-button" label={addLabel} avatar={AddRounded} onClick={onAdd} />}
            initialPagination={{ selected: data.pageSize, options: [data.pageSize], page: page - 1 }}
            totalCount={data.totalCount}
            onRowClick={onRowClick}
            onCustomized={onCustomized}
        />
    )
}
