import { listItemButtonClasses, type SxProps } from '@mui/material'
import { type SystemStyleObject, type Theme } from '@mui/system'

export const sideMenuIconStyle: SxProps<Theme> = { color: 'rgba(255, 255, 255, 0.56)' }
export const listItemStyle: SystemStyleObject<Theme> = {
    px: 2,
    [`&.${listItemButtonClasses.selected}`]: {
        backgroundColor: 'rgba(144, 202, 249, 0.16)',
    },
}
