/**
 * @param id resource full id such as "servicename-resource"
 */
export const mapResourceNames = (id: string): [serviceName: string | null, resourceNameOrFullId: string] => {
    const [service, ...labels] = id.split('-')
    return [service || null, labels.join('-') || id]
}

const referencePattern = /\$\{(?<ref>.*)\}/
const justReferencePattern = /^\$\{(?<ref>.*)\}$/

export const hasReference = (input: string): boolean => referencePattern.test(input)

/**
 * @param input something along the lines of `${reference}`
 * @returns the name or the original input if it cannot extract anything
 */
export const extractReferenceName = (input: string): string => {
    const ref = justReferencePattern.exec(input)?.groups?.ref
    return ref || input
}

const patterns = ['Cybus::ServiceId']

/**
 * @description Connectware has some ref variables baked into it that need to be replaced by actual values at run-time
 * Sometimes those values do not get replaced on the backend, so it needs to be done on the front-end
 */
export const replaceReferences = (input: string, { serviceId }: { serviceId: string }): string => {
    for (const name of patterns) {
        input = input.replaceAll('${' + name + '}', serviceId)
    }

    return input
}

/**
 * Use this function to replace the reference to another service's resource to a redable resource name
 * @example
 *  replaceInterServicesReferenceName("${connectivitylayer::spindleSpeed}") yields 'connectivitylayer-spindleSpeed'
 */
export const replaceInterServicesReferenceName = (input: string): string => extractReferenceName(input).replaceAll('::', '-')

/**
 * @returns if the the given input is a reference to another service's resource
 * @example
 *  isReferencingResourceFromAnotherService("${connectivitylayer::spindleSpeed}") yields true
 * @example
 *  isReferencingResourceFromAnotherService("connectivitylayer::spindleSpeed") yields false
 * @example
 *  isReferencingResourceFromAnotherService("${spindleSpeed}") yields false
 */
export const isReferencingResourceFromAnotherService = (input: string): boolean => hasReference(input) && input.includes('::')
