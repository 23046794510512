import React, { type FC } from 'react'
import { type MUIDataTableTextLabelsPagination } from 'mui-datatables'
import { Box, InputLabel, MenuItem, Pagination, Select, selectClasses, type SxProps, TableCell, TableFooter, TableRow, type Theme } from '@mui/material'

import { JumpToPage } from './JumpToPage'

type FooterProps = Readonly<{
    /** Page info */
    page: number
    totalPages: number

    /** Page size info */
    rowsPerPage: number
    rowsPerPageOptions: number[]

    /** Listeners */
    changeRowsPerPage: (page: string | number) => void
    changePage: (newPage: number) => void

    /** Required labels */
    textLabels: Pick<MUIDataTableTextLabelsPagination, 'rowsPerPage' | 'jumpToPage'>
}>

const boxStyle: SxProps<Theme> = { flex: 1 }
const cellStyle: SxProps<Theme> = { display: 'flex', py: 1, alignItems: 'center', justifyContent: 'space-between' }
const paginationStyle: SxProps<Theme> = { ...boxStyle, display: 'flex', alignItems: 'center', px: 1, justifyContent: 'flex-end' }
const paginatonLabelStyle: SxProps<Theme> = { mr: 1, fontSize: 'body2.fontSize' }
const paginationSelectStyle: SxProps<Theme> = { [`& .${selectClasses.select}`]: { pb: 0, fontSize: 'body2.fontSize' } }

export const Footer: FC<FooterProps> = ({ totalPages, page, rowsPerPage, changeRowsPerPage, changePage, textLabels, rowsPerPageOptions }) => {
    const hasPageSizeOptions = rowsPerPageOptions.length > 1

    return (
        <TableFooter>
            <TableRow>
                <TableCell sx={cellStyle}>
                    <Box sx={boxStyle}>
                        <JumpToPage label={textLabels.jumpToPage} totalPages={totalPages} page={page + 1} onSubmit={(page) => changePage(page - 1)} />
                    </Box>
                    <Pagination count={totalPages} page={page + 1} size="large" onChange={(_, page) => changePage(page - 1)} />
                    {hasPageSizeOptions ? (
                        <Box sx={paginationStyle}>
                            <InputLabel variant="standard" sx={paginatonLabelStyle}>
                                {textLabels.rowsPerPage}
                            </InputLabel>
                            <Select
                                // Only here to make tests backwards compatible
                                id="pagination-rows"
                                sx={paginationSelectStyle}
                                size="small"
                                value={rowsPerPage}
                                variant="standard"
                                onChange={(e) => changeRowsPerPage(e.target.value)}
                                disableUnderline
                                // Only here to make tests backwards compatible
                                MenuProps={{ MenuListProps: { id: 'pagination-menu-list' } }}
                            >
                                {rowsPerPageOptions.map((value) => (
                                    <MenuItem key={value} value={value}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    ) : (
                        <Box sx={boxStyle} />
                    )}
                </TableCell>
            </TableRow>
        </TableFooter>
    )
}
