import { type AppState, type ConnectwareError, selectSandbox } from '../../../domain'

import { BaseSandboxUsecase } from './Base'

/**
 * @todo make this usecase listen to the state of the application and update the transformation accordingly
 * Pehaps join it with the start usecase
 */
export class TransformSandboxUsecase extends BaseSandboxUsecase {
    private setOutputValue (id: number, value: AppState['ruleEngineSandbox']['output']['value']): void {
        const { output } = selectSandbox(this.getState())

        if (output.id !== id) {
            return
        }

        this.setSandbox({ output: { loaded: true, id, value } })
    }

    invoke (): void {
        const {
            input,
            transformation,
            output: { id },
        } = selectSandbox(this.getState())

        if (!input.loaded || !transformation) {
            this.setSandbox({ output: { loaded: false, id, value: null } })
            return
        }

        const newId = id + 1

        this.setSandbox({ output: { loaded: false, id: newId, value: null } })

        this.ruleEngineService
            .transform(input.value, transformation)
            .then((payload) => this.setOutputValue(newId, payload))
            .catch((e: ConnectwareError) => this.setOutputValue(newId, e))
    }
}
