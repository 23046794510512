import React, { type FC } from 'react'
import { Box, type SxProps, type Theme, Typography } from '@mui/material'

import { type NullableValues } from '../../../../utils'

import { type EndpointConnectivity, Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'

import { useCanUpdateLicense } from '../Hooks'
import StatusIcon from '../StatusIcon'

const portalStyle: SxProps<Theme> = { display: 'flex', mb: 2, px: 0, '& > *': { my: 'auto', mx: 0 } }
const portalIconStyle: SxProps<Theme> = { mr: 2 }

export const PortalReachabilityError: FC<Readonly<{ connectivity: NullableValues<EndpointConnectivity> | null }>> = ({ connectivity }) => {
    const canUpdateLicense = useCanUpdateLicense()

    if (!canUpdateLicense || connectivity?.reachable) {
        /**
         * If its loaded, render nothing
         */
        return null
    }

    return (
        <Box sx={portalStyle} className="portal-reachability-issues">
            {connectivity && (
                <>
                    <StatusIcon sx={portalIconStyle} status="RED" />
                    <div>
                        <Typography variant="body2">
                            <FormattedTranslation
                                id={Translation.IS_URL_REACHABLE}
                                values={{ url: connectivity.url?.toString() || <FormattedTranslation id={Translation.CYBUS_PORTAL} />, reachable: false }}
                            />
                        </Typography>
                        <Typography variant="body2">
                            <FormattedTranslation id={Translation.LICENSE_REFRESH_REQUIRES_INTERNET} />
                        </Typography>
                    </div>
                </>
            )}
        </Box>
    )
}
