import type { Intersection } from 'utility-types'

import { type CybusDetailedEndpoint, type CybusEndpoint } from '../../../domain'

import type { SubscriptionFilterArgs } from '../../../application'
import { type EndpointProxyParams } from '../proxies'
import { mapResourceNames } from './Resource'
import { mapToStatusType } from './Status'

export const mapBaseEndpoint = ({
    id,
    operation,
    protocol,
    currentState,
}: Pick<EndpointProxyParams, 'id' | 'operation' | 'protocol' | 'currentState'>): Intersection<CybusDetailedEndpoint, CybusEndpoint> => {
    const [service, name] = mapResourceNames(id)

    return {
        id,
        name,
        service,
        operation,
        protocol,
        status: mapToStatusType(currentState),
    }
}

export const mapEndpoints = (params: EndpointProxyParams[], { service, connection }: SubscriptionFilterArgs): CybusEndpoint[] =>
    params.reduce<CybusEndpoint[]>((endpoints, { connectionId, ...data }) => {
        if (connection && connection !== connectionId) {
            return endpoints
        }

        const endpoint: CybusEndpoint = { ...mapBaseEndpoint(data), connection: connectionId || null }
        if (service && service !== endpoint.service) {
            return endpoints
        }

        return [...endpoints, endpoint]
    }, [])

export const mapDetailedEndpoint = ({ address, rules, ...param }: EndpointProxyParams, topics: string[]): CybusDetailedEndpoint => ({
    ...mapBaseEndpoint(param),
    address,
    rules: rules || null,
    topics,
})
