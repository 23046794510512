import React, { type FC } from 'react'

import {
    type CybusDetailedMapping,
    selectDeletableLoadedDetailedMapping,
    selectDisabableLoadedDetailedMapping,
    selectEnabableLoadedDetailedMapping,
    selectMapping,
    Translation,
} from '../../../../domain'

import { useRouting } from '../../routing'

import { ResourceDetails } from '../../Resources'
import { Content } from './Content'
import { DeleteModal, DisableModal, EnableModal } from '../Bulk'
import { HeaderActions } from './HeaderActions'
import { HeaderDetails } from './HeaderDetails'

const Modals: FC = () => {
    const routing = useRouting()

    return (
        <>
            <EnableModal mappingSelector={selectEnabableLoadedDetailedMapping} />
            <DisableModal mappingSelector={selectDisabableLoadedDetailedMapping} />
            <DeleteModal mappingSelector={selectDeletableLoadedDetailedMapping} onConfirmed={() => routing.redirectToParent()} />
        </>
    )
}

export const Details: FC = () => (
    <ResourceDetails<CybusDetailedMapping>
        subscriptionUsecase="manageMappingUsecase"
        resourceSelector={selectMapping}
        notFoundTitle={Translation.MAPPING_NOT_FOUND}
        modals={Modals}
        content={Content}
        headerResourceName={Translation.MAPPING}
        headerName="name"
        headerActions={HeaderActions}
        infoTitle={Translation.MAPPING_DETAILS_DOCUMENTATION_TITLE}
        infoBody={Translation.MAPPING_DETAILS_DOCUMENTATION_BODY}
        headerDetails={HeaderDetails}
    />
)
