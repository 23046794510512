import React, { type FC, useMemo } from 'react'
import { Box } from '@mui/material'

import type { NonEmptyArray, ReadonlyRecord } from '../../../utils'

import type { Translation } from '../../../domain'
import { type AbsoluteRouteOnlyPath, useHasCapabilities, useRouteTitle, useRouting } from '../routing'
import { FormattedTranslation } from '../Internationalization'
import { DetailsHeader } from '../common'
import { Tab, Tabs } from '.'
import type { TabsProps } from './Tabs'

type RouteTabsProps<R extends string,> = Readonly<{
    title: Translation
    tabs: ReadonlyRecord<R, ReadonlyRecord<'documentationTitle' | 'documentationBody', Translation> | null>
    tabsWrapper?: FC
}> &
    Pick<TabsProps<R>, 'orientation'>

const defaultTabsWrapper: FC = ({ children }) => <>{children}</>

export const RouteTabs = <R extends AbsoluteRouteOnlyPath,>({
    title,
    tabs,
    orientation,
    tabsWrapper: TabsWrapper = defaultTabsWrapper,
    ...props
}: RouteTabsProps<R>): ReturnType<FC> => {
    const router = useRouting()
    const hasCapabilities = useHasCapabilities()
    const routeTitle = useRouteTitle()

    const keys = Object.keys(tabs) as NonEmptyArray<R>

    const currentTab: R = useMemo(() => keys.find((route) => router.isAtRoute(route)) ?? keys[0], [router])

    const { documentationTitle, documentationBody } = tabs[currentTab] ?? {}

    return (
        <Box {...props}>
            <DetailsHeader
                title={{ title: <FormattedTranslation id={title} values={{ count: 0 }} /> }}
                section={documentationTitle && documentationBody && { title: documentationTitle, body: documentationBody }}
            />

            <TabsWrapper>
                <Tabs orientation={orientation} onChange={(value) => router.redirect(value)} value={currentTab}>
                    {keys.map((route) => {
                        const { title, isTitleSingular } = routeTitle(route)
                        const disabled = !hasCapabilities(route)
                        return <Tab key={route} title={title} count={Number(isTitleSingular)} value={route} disabled={disabled} />
                    })}
                </Tabs>
            </TabsWrapper>
        </Box>
    )
}
