import React, { type FC } from 'react'

import { type AppState, type CybusServer, ResourceType, Translation } from '../../../domain'

import { useAppUsecases } from '../State'

import { DeleteResourceModal, DisableResourceModal, EnableResourceModal, ServiceModalBody } from '../Resources'

type Server = Pick<CybusServer, 'id' | 'name' | 'service'>

type Props = Readonly<{ serversSelector: (s: AppState, id: string[]) => Server[] }>

export const EnableModal: FC<Props> = ({ serversSelector }) => {
    const { bulkEnableServersUsecase } = useAppUsecases()

    return (
        <EnableResourceModal
            title={Translation.ENABLE_SERVERS}
            subtitle={Translation.ENABLE_SERVERS_SUBTITLE}
            confirm={Translation.ENABLE}
            resourceSelector={serversSelector}
            dimension={ResourceType.SERVER}
            onRenderLineContent={ServiceModalBody}
            onCancel={() => bulkEnableServersUsecase.selectServers(null)}
            onConfirm={() => bulkEnableServersUsecase.enable()}
        />
    )
}

export const DisableModal: FC<Props> = ({ serversSelector }) => {
    const { bulkDisableServersUsecase } = useAppUsecases()

    return (
        <DisableResourceModal
            title={Translation.DISABLE_SERVERS}
            subtitle={Translation.DISABLE_SERVERS_SUBTITLE}
            confirm={Translation.DISABLE}
            resourceSelector={serversSelector}
            dimension={ResourceType.SERVER}
            onRenderLineContent={ServiceModalBody}
            onCancel={() => bulkDisableServersUsecase.selectServers(null)}
            onConfirm={() => bulkDisableServersUsecase.disable()}
        />
    )
}

export const DeleteModal: FC<Props & { onConfirmed: VoidFunction }> = ({ serversSelector, onConfirmed }) => {
    const { bulkDeleteServersUsecase } = useAppUsecases()

    return (
        <DeleteResourceModal
            title={Translation.DELETE_SERVERS}
            subtitle={Translation.DELETE_SERVERS_SUBTITLE}
            confirm={Translation.DELETE}
            resourceSelector={serversSelector}
            dimension={ResourceType.SERVER}
            onRenderLineContent={ServiceModalBody}
            onCancel={() => bulkDeleteServersUsecase.selectServers(null)}
            onConfirm={async () => {
                await bulkDeleteServersUsecase.delete()
                onConfirmed()
            }}
        />
    )
}
