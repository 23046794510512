import { type ReactElement } from 'react'

import { useHasCapabilities } from '../../routing'

import { type NavigationLinkProps } from './Link'

export const useChildrenCapabilitiesChecker = (): ((children: ReactElement<NavigationLinkProps>[]) => boolean) => {
    const hasCapabilities = useHasCapabilities()

    return (children) =>
        children.some((child) =>
            'requiredCapabilities' in child.props ? hasCapabilities(child.props.requiredCapabilities) : hasCapabilities(child.props.route)
        )
}
