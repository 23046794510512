import type { ArrayType } from '../../utils'
import { ConnectwareError, ConnectwareErrorType, type CybusCoreContainer, type CybusServiceContainer } from '../../domain'

import type { SubscriptionFilterArgs } from '../../application'
import { mapDockerContainerState, mapResourceNames, mapToStatusType } from './mappers'
import type { DockerContainerResponse, DockerContainersResponse, KubernetesContainersResponse } from './Types'

/**
 * Abstract class that helps the fetching of containers list into domain level entities
 * Needs to pass a promise of what is the backend orchestrator and implement fetchers
 */
export abstract class ContainersListFetcher {
    constructor (private readonly isDocker: Promise<boolean>, private readonly filter: SubscriptionFilterArgs) {}

    private fetchContainers<C extends CybusServiceContainer | CybusCoreContainer, M> (
        extractIdMetadata: (id: string) => M | null,
        mapCustomProperties: (
            baseValues: Pick<C, 'id' | 'status' | 'lastUpdate' | 'created'>,
            metadata: M,
            response: Omit<ArrayType<DockerContainersResponse | KubernetesContainersResponse>, 'Names' | 'State'>
        ) => C
    ): Promise<C[]> {
        return this.fetchContainersResponse().then((containers) =>
            Promise.all(
                containers.map(async ({ Names, Names: [firstName], State, ...response }) => {
                    if (!firstName) {
                        throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'Names in container not in expected format', { Names })
                    }

                    // Name starts with slash, this removes them
                    const id = firstName.slice(1)
                    const idMetadata = extractIdMetadata(id)

                    if (idMetadata === null) {
                        // Can give up on this container as it is not relevant for this search
                        return null
                    }

                    const dockerMetadata = await this.isDocker.then((isDocker) => (isDocker ? this.fetchDockerContainerMetadata(id) : null))
                    const metadata = dockerMetadata && { ...mapDockerContainerState(dockerMetadata.State), created: new Date(dockerMetadata.Created) }

                    // Map state (asynchronously) or defaults to basic
                    const { status, lastUpdate, created } = metadata || { status: mapToStatusType(State?.toLowerCase()), lastUpdate: null, created: null }

                    // Map all properties
                    return mapCustomProperties({ id, status, lastUpdate, created }, idMetadata, response)
                })
            ).then((containers: (C | null)[]) => containers.filter((c): c is C => c !== null))
        )
    }

    protected abstract fetchContainersResponse (): Promise<DockerContainersResponse | KubernetesContainersResponse>

    /**
     * Function that yields the docker container metadata of the given container name
     * Yielding null is fine if the container can not be found
     * Will only be called if the system is operating in a docker environment
     */
    protected abstract fetchDockerContainerMetadata (name: string): Promise<DockerContainerResponse | null>

    fetchCoreContainers (): Promise<CybusCoreContainer[]> {
        return this.fetchContainers(
            (name) => ({ name }),
            (base, metatada) => ({ ...base, ...metatada })
        )
    }

    fetchServiceContainers (): Promise<CybusServiceContainer[]> {
        return this.fetchContainers(
            (id) => {
                const [service, name] = mapResourceNames(id)

                const { service: serviceFilter } = this.filter
                if (serviceFilter && serviceFilter !== service) {
                    return null
                }

                return { name, service }
            },
            (base, metadata, { Mounts }) => ({ ...base, ...metadata, volumesCount: Mounts.length })
        )
    }
}
