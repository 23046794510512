import React, { type FC } from 'react'

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, type SxProps, type Theme } from '@mui/material'

import { type Certificate, ConnectwareError, getCertificateTitle, Translation } from '../../../domain'

import { FormattedTranslation } from '../Internationalization'
import { useAsyncCallback } from '../Callback'

import { ErrorMessage } from '../ErrorMessage'

const errorMessageStyle: SxProps<Theme> = { width: '100%' }
const dialogTitleStyle: SxProps<Theme> = { mb: 0 }

export type CertificateDialogProps = Readonly<{
    certificate: Certificate
    confirmation?: Translation
    onClose: VoidFunction
    confirmable?: boolean
    onConfirm?: () => Promise<void>
}>

export const CertificateDialog: FC<CertificateDialogProps> = ({ certificate, confirmation, confirmable, onConfirm, onClose, children }) => {
    const title = getCertificateTitle(certificate)

    const [confirm, isLoading, result] = useAsyncCallback(async () => {
        await onConfirm?.()
        /** Close modal */
        onClose()
    }, [onConfirm])

    return (
        <Dialog maxWidth="md" fullWidth open onClose={onClose}>
            <DialogTitle sx={dialogTitleStyle} variant="body1" paragraph>
                <FormattedTranslation id={Translation.CERTIFICATE_VIEWER_TITLE} values={{ title }} />
            </DialogTitle>
            <DialogContent dividers>{children}</DialogContent>
            <DialogActions>
                {isLoading && <CircularProgress size={20} />}
                {ConnectwareError.is(result) && (
                    <ErrorMessage sx={errorMessageStyle} data-testid="certificate-deletion-error" error={result} titleVariant="body2" extras="popover" />
                )}
                <Button color="primary" size="small" onClick={onClose} id="certificate-cancel-button">
                    <FormattedTranslation id={Translation.CLOSE} />
                </Button>
                {confirmation && onConfirm && (
                    <Button id="certificate-confirmation-button" color="secondary" size="small" disabled={isLoading || confirmable === false} onClick={confirm}>
                        <FormattedTranslation id={confirmation} />
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}
