import type { ValuesType } from 'utility-types'

type Values = string | number
type EnumType = Record<string, Values>

/**
 * Used to cache value checks, so later calls are faster
 */
const enumValueCache = new Map<EnumType, Set<ValuesType<EnumType>>>()

const getCachedEnumValues = <E extends EnumType>(e: E): Set<Values> => {
    let values = enumValueCache.get(e)

    if (values) {
        return values
    }

    /**
     * Cache miss, add to cache
     */
    values = new Set(Object.values(e))
    enumValueCache.set(e, new Set(Object.values(e)))

    return values
}

export const getEnumValue = <E extends EnumType>(e: E, v: unknown): ValuesType<E> | null => {
    const values = getCachedEnumValues<E>(e)

    if (values.has(v as ValuesType<E>)) {
        return v as ValuesType<E>
    }

    return null
}

export const isEnumOf = <E extends Record<string, string | number>>(e: E, v: unknown): v is ValuesType<E> => getEnumValue(e, v) !== null
