import React, { type FC, memo } from 'react'

import { selectUsersManagementPermissionsTabData, Translation } from '../../../../domain'

import { useAppUsecase } from '../../State'
import { useTranslator } from '../../Internationalization'

import { useContextLabel } from './Hooks'
import { Table } from '../Table'

/**
 * @todo review this code block when https://cybusio.atlassian.net/browse/CYB-3564 is resolved
 */
export const PermissionsTable: FC = memo(() => {
    const translator = useTranslator()
    const translateContext = useContextLabel()
    const viewPermissionUsecase = useAppUsecase('viewPermissionUsecase')

    return (
        <Table
            columns={{
                resource: { label: translator.formatTranslation(Translation.RESOURCE, { count: 0 }) },
                context: {
                    label: Translation.CONTEXT,
                    customCellRender: translateContext,
                },
                users: {
                    label: translator.formatTranslation(Translation.USER, { count: 2 }),
                    customCellRender: (users) => translator.formatTranslation(Translation.ACTIVE_USER, { count: Object.keys(users).length }),
                },
                roles: {
                    label: translator.formatTranslation(Translation.ROLE, { count: 2 }),
                    customCellRender: (roles) => translator.formatTranslation(Translation.ACTIVE_ROLE, { count: Object.keys(roles).length }),
                },
            }}
            selector={selectUsersManagementPermissionsTabData}
            notFound={Translation.NO_PERMISSIONS_FOUND}
            onRowClick={(permission) => viewPermissionUsecase.show(permission)}
        />
    )
})
