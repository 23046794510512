import { type ConnectwareError } from '../../../domain'
import { LogoutUsecase } from '.'

export class LoginFromTokenUsecase extends LogoutUsecase {
    loginBrowserFromToken (): boolean {
        const token = this.browserService.getToken()

        if (!token) {
            // There is no token, give up
            return false
        }

        this.authenticationService
            .fetchSession(token)
            .then((session) => this.updateAuthentication({ ...session, token }))
            .catch((error: ConnectwareError) => this.updateAuthentication(error))

        return true
    }
}
