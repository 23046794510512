import type { JSONSchema7 } from 'json-schema'
import { validate } from 'jsonschema'

import { Cache, delay, encodeToBase64, entries, type EventListener, type NullableValues } from '../../../utils'
import {
    Capability,
    CommissioningFileParsingError,
    ConnectwareError,
    ConnectwareErrorType,
    ConnectwareServiceCreationError,
    type CybusDetailedService,
    type CybusServiceForm,
    type CybusServiceParameters,
    type CybusServiceSchema,
    Translation,
} from '../../../domain'
import type { ConfigurationService, ConnectwareServicesService, ServiceCreationOrUpdateRequest } from '../../../application'

import { type CybusServiceParameter } from '../../Connectware'
import { FetchConnectwareHTTPService, type HttpResponse } from '../Base'
import { mapParserErrorsInformation, type ParserError } from './Parser'

type CybusServiceRequestArgs = Readonly<{
    marketplace?: Readonly<{ filename: string, directory: string, version: string, updatedAt: string }>
    commissioningFile: string
    parameters: Record<string, CybusServiceParameter>
}>

const withErrorMessage = <T>(data: unknown, messageParser: (message: string) => T): T | null => {
    if (data && typeof data === 'object' && 'message' in data) {
        const { message } = data as Record<string, unknown>
        if (typeof message === 'string') {
            return messageParser(message)
        }
    }

    return null
}

/**
 * Extracts the error message errors
 * @example {"code":"InvalidContent","message":"Error in commissioning file: []"}
 */
const extractErrors = (data: unknown): ParserError[] | null =>
    withErrorMessage(data, (message) => {
        const errors = /^Error in commissioning file: (?<errors>.{2,})$/.exec(message)?.groups?.errors

        if (typeof errors !== 'string') {
            return null
        }

        try {
            return JSON.parse(errors) as ParserError[]
        } catch {
            return null
        }
    })

/**
 * Extracts if the information of a service already existing
 * @example {"code":"InvalidContent","message":"Provided service id connectivitylayer already exists"}
 */
const extractAlreadyExists = (data: unknown): boolean | null => withErrorMessage(data, (message) => /^Provided service id .+ already exists$/.test(message))

/**
 * Extracts the error message errors
 * @example {"code":"InvalidContent","message":"Error on creating service connectivitylayer: ..."}
 */
const extractCreationError = (data: unknown): string | null =>
    withErrorMessage(data, (message) => {
        const error = /^Error on creating service .+: (?<error>.+)$/.exec(message)?.groups?.error
        return typeof error === 'string' ? error : null
    })

const mapSchemaValues = (schema: JSONSchema7, currentServiceId: CybusServiceForm['id']): [CybusServiceForm['id'], NullableValues<CybusServiceParameters>] => {
    let id: CybusServiceForm['id'] = currentServiceId
    let parameters: NullableValues<CybusServiceParameters> = {}

    if (schema.properties) {
        entries(schema.properties).forEach(([name, prop]) => {
            /**
             * If possible, retrieve the default value, otherwise, just declare the value is there
             */
            let defaultValue = typeof prop === 'boolean' || !('default' in prop) || prop.default === undefined ? undefined : prop.default

            if (name === 'id') {
                /** Use id if its valid and is not already set */
                id = id || typeof defaultValue !== 'string' ? id : defaultValue
                return
            }

            if (
                typeof defaultValue !== 'string' &&
                typeof defaultValue !== 'number' &&
                typeof defaultValue !== 'boolean' &&
                !(Array.isArray(defaultValue) && defaultValue.every((v): v is string | number => typeof v === 'string' || typeof v === 'number'))
            ) {
                defaultValue = null
            }

            parameters = { ...parameters, [name]: defaultValue }
        })
    }

    return [id, parameters]
}

const validationErrorHandler = async (response: HttpResponse): Promise<ConnectwareError> => {
    const data = await response.getJson<[{ message: string }]>()
    return new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, data[0].message)
}

export class ConnectwareHTTPServicesService extends FetchConnectwareHTTPService implements ConnectwareServicesService {
    private readonly schemaCache = new Cache<Promise<JSONSchema7>>()

    protected updateBreathingRoom = 5_000

    constructor (
        baseURL: string,
        tokenGetter: () => string | null,
        private readonly configuration: ConfigurationService,
        private readonly changeDoneListeners: Pick<EventListener<void>, 'trigger'>
    ) {
        super(baseURL, tokenGetter)
    }

    async parseCommissioningFile (
        content: string,
        currentServiceId: CybusServiceForm['id']
    ): Promise<[CybusServiceSchema, CybusServiceForm['id'], NullableValues<CybusServiceParameters>]> {
        let schema = await this.schemaCache.get(
            () =>
                this.request({
                    capability: Capability.SERVICES_CREATE_OR_UPDATE,
                    method: 'POST',
                    path: '/api/services/parametersSchema',
                    authenticate: true,
                    body: { commissioningFile: encodeToBase64(content) },
                    handlers: {
                        201: async (response) => {
                            const data = await response.getJson<{ schemas: JSONSchema7 }>()
                            return data.schemas
                        },
                        400: validationErrorHandler,
                        406: async (response) => {
                            const { message } = await response.getJson<{ message: string }>()
                            const errors = extractErrors({ message })
                            return errors
                                ? new CommissioningFileParsingError(mapParserErrorsInformation(errors))
                                : new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, message)
                        },
                    },
                }),
            content
        )

        /**
         * The schema is a very complex json, see `json-schema`
         * But for what is relevant for the admin UI, only `ServiceSchema` is enough
         */
        const [id, values] = mapSchemaValues(schema, currentServiceId)

        /**
         * If there is an id
         * It means the service already exists
         * And therefore, the id cannot be set again
         * Then delete it from the outputted schema
         * As it is not necessary
         */
        if (currentServiceId) {
            if (schema.properties) {
                schema = {
                    ...schema,
                    properties: entries(schema.properties)
                        .filter(([f]) => f !== 'id')
                        .reduce((r, [f, v]) => ({ ...r, [f]: v }), {}),
                }
            }

            if (schema.required) {
                schema = { ...schema, required: schema.required.filter((r) => r !== 'id') }
            }
        }

        return [schema as CybusServiceSchema, id, values]
    }

    async createOrUpdate ({ commissioningFile, id, parameters, catalog, isCreation }: ServiceCreationOrUpdateRequest): Promise<void> {
        if (!id) {
            throw new ConnectwareServiceCreationError(Translation.INVALID_SERVICE_ID)
        }

        const body: CybusServiceRequestArgs = {
            commissioningFile: encodeToBase64(commissioningFile),
            parameters,
            marketplace: catalog ? { ...catalog, updatedAt: catalog.updatedAt.toISOString() } : undefined,
        }

        if (isCreation) {
            await this.request({
                capability: Capability.SERVICES_CREATE_OR_UPDATE,
                method: 'POST',
                path: '/api/services',
                authenticate: true,
                body: { id, ...body },
                handlers: {
                    201: () => Promise.resolve(),
                    400: validationErrorHandler,
                    406: async (response) => {
                        const data = await response.getJson<{ message: string }>()
                        if (extractAlreadyExists(data)) {
                            return new ConnectwareServiceCreationError(Translation.SERVICE_ID_ALREADY_USED)
                        }
                        const creationErrorMessage = extractCreationError(data)
                        if (creationErrorMessage !== null) {
                            return new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, creationErrorMessage)
                        }
                        return new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, data.message)
                    },
                },
            })
        } else {
            await this.request({
                capability: Capability.SERVICES_CREATE_OR_UPDATE,
                method: 'PUT',
                path: '/api/services/+',
                pathParams: [id],
                authenticate: true,
                body,
                handlers: {
                    202: () => Promise.resolve(),
                    400: validationErrorHandler,
                    404: async (response) => {
                        const data = await response.getJson<{ message: string }>()
                        return new ConnectwareError(ConnectwareErrorType.NOT_FOUND, data.message)
                    },
                },
            })
        }

        if (!isCreation) {
            /**
             * Due to updating being done asynchronously
             * This here makes sure the UI waits a bit before fetching informaiton
             */
            await delay(this.updateBreathingRoom)
        }

        this.changeDoneListeners.trigger()
    }

    validate (schema: CybusServiceSchema, id: CybusServiceForm['id'], parameters: CybusServiceParameters): boolean {
        const result = validate(id !== null ? { ...parameters, id } : parameters, schema)
        return result.errors.length === 0
    }

    createFile ({ id, commissioningFile, updatedAt }: Pick<CybusDetailedService, 'id' | 'commissioningFile' | 'updatedAt'>): File {
        const fileName = `${id}.${this.configuration.getServiceCommissioningFileType()}`
        const type = this.configuration.getServiceCommissioningFileEncodingType()
        return new File([commissioningFile], fileName, { type, lastModified: updatedAt ? updatedAt.getTime() : Date.now() })
    }
}
