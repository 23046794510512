import { decodeFromBase64 } from '../../../utils'
import {
    ConnectwareError,
    ConnectwareErrorType,
    type CybusCatalogApplication,
    CybusCatalogApplicationCategory,
    type CybusCatalogServiceVersionData,
} from '../../../domain'
import { type ConfigurationService, type LoggerService } from '../../../application'
import { type FullServiceMetadata, type ServiceContent, type ServiceMeta } from '../proxies/Catalog'

export const serviceCategoriesMap: Record<FullServiceMetadata['category'], CybusCatalogApplicationCategory> = {
    Applications: CybusCatalogApplicationCategory.APPLICATIONS,
    'Northbound Connectivity': CybusCatalogApplicationCategory.NORTHBOUND,
    'Southbound Connectivity': CybusCatalogApplicationCategory.SOUTHBOUND,
}

export const catalogApplicationsMapper = (
    services: FullServiceMetadata[] | null,
    logger: LoggerService,
    servicesCatalogDefaultImageLink: string
): CybusCatalogApplication[] => {
    if (!services) {
        throw new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, 'Could not load services')
    }
    return services.reduce<CybusCatalogApplication[]>((prev, service) => {
        const category = serviceCategoriesMap[service.category]

        if (category) {
            prev.push({
                available: service.availableInLicense,
                category,
                name: service.name,
                icon: service.icon || servicesCatalogDefaultImageLink,
                provider: service.provider,
                template: service.isTemplate,
                directory: service.directory,
                filename: service.filename,
                description: service.description,
                homepage: service.homepage,
                version: service.version,
                updatedAt: new Date(service.updatedAt),
            })
        } else {
            logger.warn('Could not find category for catalog service', { category: service.category, serviceCategoriesMap })
        }
        return prev
    }, [])
}

export const catalogDataMapper = (meta?: ServiceMeta | null, content?: ServiceContent | null): CybusCatalogServiceVersionData => {
    if (!meta || !content) {
        throw new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, 'Could not load catalog data')
    }
    /**
     * The graphql, unlike vrpc, yields milleconds on its last updated value
     * This causes issues later when comparing both, so we reset it
     */
    const updatedAt = new Date(meta.updatedAt)
    updatedAt.setMilliseconds(0)

    return { version: meta.version, updatedAt, commissioningFile: decodeFromBase64(content.data) }
}

export const commissioningFileMapper = (content: ServiceContent | null, name: string, configuration: ConfigurationService): File => {
    if (!content) {
        throw new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, 'Could not load commissioning file content')
    }
    const fileName = `${name}.${configuration.getServiceCommissioningFileType()}`
    const type = configuration.getServiceCommissioningFileEncodingType()

    return new File([decodeFromBase64(content.data)], fileName, { type })
}
