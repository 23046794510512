"use strict";
/**
 * Represnts a function that once called, results in the removal of a subscription
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtendedStateManager = exports.areResultsEqual = void 0;
var areResultsEqual = function (previous, current, filter) {
    var result = filter(previous, current);
    /**
     * If result is boolean, hash method is not used
     */
    if (typeof result === 'boolean') {
        return result;
    }
    /**
     * Else, hash method is being used
     */
    return result === filter(current, previous);
};
exports.areResultsEqual = areResultsEqual;
var ExtendedStateManager = /** @class */ (function () {
    function ExtendedStateManager(state) {
        this.state = state;
        this.subscribers = new Set();
    }
    ExtendedStateManager.prototype.addSubscriber = function (subscriber) {
        var _this = this;
        this.subscribers.add(subscriber);
        return function () {
            _this.subscribers.delete(subscriber);
        };
    };
    /**
     * Allows for partial update of the state, triggers subscriber changes
     * @param state
     */
    ExtendedStateManager.prototype.setState = function (state) {
        this.state = __assign(__assign({}, this.state), state);
        this.subscribers.forEach(function (l) { return l(); });
    };
    /**
     * Gets the total state being managed
     */
    ExtendedStateManager.prototype.getState = function () {
        return this.state;
    };
    /**
     * @param filter Optional filter for changes before subscriber gets called. See `Filter` for more
     * @param subscriber To subscribe to state changes
     */
    ExtendedStateManager.prototype.subscribe = function () {
        var _this = this;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _a = args.length === 1 ? [function () { return false; }, args[0]] : args, filter = _a[0], subscriber = _a[1];
        var previous = this.getState();
        return this.addSubscriber(function () {
            var current = _this.getState();
            if (!(0, exports.areResultsEqual)(previous, current, filter)) {
                subscriber();
            }
            previous = current;
        });
    };
    return ExtendedStateManager;
}());
exports.ExtendedStateManager = ExtendedStateManager;
