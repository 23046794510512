import React, { type FC } from 'react'
import { Link, Breadcrumbs as MUIBreadcrumbs, type SxProps, type Theme } from '@mui/material'
import { NavigateBefore } from '@mui/icons-material'

import { type Translation } from '../../../domain'
import { useRouting } from '../routing'
import { FormattedTranslation } from '../Internationalization'

const linkStyle: SxProps = { cursor: 'pointer', display: 'flex', alignItems: 'center' }
const wrapperStyle: SxProps<Theme> = { color: 'info.main' }

const Breadcrumb: FC<{ title: Translation, position: number, totalCount: number }> = ({ title, position, totalCount }) => {
    const redirect = useRouting()

    const isFirst = position === 0
    /**
     * Route is available so use it
     */
    return (
        <Link sx={linkStyle} onClick={() => redirect.redirectToParent(totalCount - position)} color="inherit" underline="none">
            {isFirst && <NavigateBefore color="inherit" />}
            <FormattedTranslation id={title} />
        </Link>
    )
}

export const Breadcrumbs: FC = () => {
    const redirect = useRouting()

    return (
        <MUIBreadcrumbs id="appBreadcrumbs" sx={wrapperStyle}>
            {redirect.getBreadcrumbs().map((title, k, breadcrumbs) => (
                <Breadcrumb key={k} title={title} position={k} totalCount={breadcrumbs.length} />
            ))}
        </MUIBreadcrumbs>
    )
}
