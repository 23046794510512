import React, { type FC, useEffect } from 'react'
import { Box, type SxProps, type Theme } from '@mui/material'

import { type CybusDetailedMapping, selectMappingEntriesError, StatusType, Translation } from '../../../../domain'

import { useAppState, useAppUsecase } from '../../State'
import { useTranslator } from '../../Internationalization'
import { Table } from '../../common'
import { ErrorMessage } from '../../ErrorMessage'

import { Broker } from './Broker'
import { PubSub } from './PubSub'
import { Rules } from './Rules'

const lineStyle: SxProps<Theme> = { minHeight: 32, lineHeight: '32px' }
const errorStyle: SxProps<Theme> = { pt: 1 }

const Line: FC = ({ children }) => <Box sx={lineStyle}>{children}</Box>

export const Entries: FC<Pick<CybusDetailedMapping, 'entries' | 'status'>> = ({ entries, status }) => {
    const translator = useTranslator()

    const entriesError = useAppState(selectMappingEntriesError)
    const enabled = status === StatusType.ENABLED

    /** Subscribe to all entries only once */
    const usecase = useAppUsecase('subscribeToMappingEntriesUsecase')
    useEffect(() => (enabled ? usecase.subscribe() : undefined), [usecase, enabled])

    return entriesError ? (
        <ErrorMessage data-testid="entries-error" sx={errorStyle} titleVariant="subtitle1" error={entriesError} />
    ) : (
        <Table
            data={entries.map(({ subscribe, publish, ...rules }) => ({
                subscribe,
                subscribeExternalBrokers: subscribe.map((s) => s.externalBroker),
                publish,
                publishExternalBroker: publish.externalBroker,
                rules,
            }))}
            columns={{
                subscribe: {
                    label: Translation.SUBSCRIBE,
                    customCellRender: (pubSubs) => (
                        <>
                            {pubSubs.map((pubSub, k) => (
                                <Line key={k}>
                                    <PubSub pubSub={pubSub} enabled={enabled} />
                                </Line>
                            ))}
                        </>
                    ),
                },
                subscribeExternalBrokers: {
                    label: Translation.SUBSCRIBE_BROKER,
                    customCellRender: (externalBrokers) => (
                        <>
                            {externalBrokers.map((externalBroker, k) => (
                                <Line key={k}>
                                    <Broker externalBroker={externalBroker} />
                                </Line>
                            ))}
                        </>
                    ),
                },
                publish: {
                    label: Translation.PUBLISH,
                    customCellRender: (pubSub) => <PubSub pubSub={pubSub} enabled={enabled} />,
                },
                publishExternalBroker: {
                    label: Translation.PUBLISH_BROKER,
                    customCellRender: (externalBroker) => <Broker externalBroker={externalBroker} />,
                },
                rules: {
                    label: translator.formatTranslation(Translation.RULE, { count: 2 }),
                    customCellRender: (rules) => <Rules {...rules} />,
                },
            }}
            translations={{ emptyTable: Translation.NO_ENTRIES_AVAILABLE }}
        />
    )
}
