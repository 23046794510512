import React, { type FC } from 'react'
import { Box, Link, type SxProps, type Theme, Typography } from '@mui/material'

import { ConnectwareError, selectInfo, type SystemConnectivity, Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'
import { useAppState } from '../../State'
import { CircularLoader, Table } from '../../common'
import { ErrorMessage } from '../../ErrorMessage'
import StatusIcon from '../StatusIcon'

const titleStyle: SxProps<Theme> = { mb: 4 }

const Wrapper: FC = ({ children }) => {
    return (
        <Box data-testid="info-preflight">
            <Typography variant="h5" sx={titleStyle}>
                <FormattedTranslation id={Translation.INTERNET_CONNECTIVITY} />
            </Typography>
            {children}
        </Box>
    )
}

const Reachable: FC<{ type: keyof SystemConnectivity['endpoints'], endpoints: SystemConnectivity['endpoints'] }> = ({ type, endpoints }) => (
    <FormattedTranslation id={Translation.IS_URL_REACHABLE} values={{ url: endpoints[type].url.toString(), reachable: endpoints[type].reachable }} />
)

const Status: FC<{ isGreen: boolean }> = ({ isGreen }) => <StatusIcon status={isGreen ? 'GREEN' : 'RED'} onlyCircle />

export const InternetConnectivity: FC = () => {
    const info = useAppState(selectInfo)
    if (!info) return null
    const { connectivity, version } = info
    if (ConnectwareError.is(connectivity)) {
        return (
            <Wrapper>
                <ErrorMessage error={connectivity} extras="popover" />
            </Wrapper>
        )
    }

    if (!connectivity) {
        return (
            <Wrapper>
                <CircularLoader />
            </Wrapper>
        )
    }

    const { endpoints, latest } = connectivity

    const tableData = [
        {
            name: <FormattedTranslation id={Translation.CYBUS_PORTAL} />,
            info: (
                <>
                    <Reachable type="cybusPortal" endpoints={endpoints} />{' '}
                    <FormattedTranslation id={Translation.CYBUS_PORTAL_STATUS} values={{ updatesAutomatically: endpoints.cybusPortal.reachable }} />
                </>
            ),
            status: <Status isGreen={endpoints.cybusPortal.reachable} />,
        },
        {
            name: <FormattedTranslation id={Translation.CYBUS_REGISTRY} />,
            info: (
                <>
                    <Reachable type="cybusRegistry" endpoints={endpoints} />{' '}
                    <FormattedTranslation id={Translation.CYBUS_REGISTRY_STATUS} values={{ installable: endpoints.cybusRegistry.reachable }} />
                </>
            ),
            status: <Status isGreen={endpoints.cybusRegistry.reachable} />,
        },
        {
            name: <FormattedTranslation id={Translation.DOCKER_HUB} />,
            info: (
                <>
                    <Reachable type="dockerHub" endpoints={endpoints} />{' '}
                    <FormattedTranslation id={Translation.DOCKER_HUB_STATUS} values={{ installable: endpoints.dockerHub.reachable }} />
                </>
            ),
            status: <Status isGreen={endpoints.dockerHub.reachable} />,
        },
        {
            name: <FormattedTranslation id={Translation.CONNECTWARE_VERSION_INFORMATION} />,
            info:
                latest === null || version === latest.version ? (
                    <FormattedTranslation id={Translation.CONNECTWARE_UPDATE_UNAVAILABLE} values={{ latest, version }} />
                ) : (
                    <span data-testid="info-preflight-version">
                        <FormattedTranslation id={Translation.CONNECTWARE_UPDATE_AVAILABLE} values={{ latestVersion: latest.version }} />{' '}
                        <FormattedTranslation
                            id={Translation.CONNECTWARE_NEW_SELF_HOSTED_APPLIANCE_VERSION}
                            values={{
                                latestVersion: latest.version,
                                InstallerLink: (...content: string[]) => <Link href={latest.installerURL.toString()}>{content}</Link>,
                                ReleaseNotesLink: (...content: string[]) => <Link href={latest.releaseNotes.toString()}>{content}</Link>,
                            }}
                        />
                    </span>
                ),
            status: <Status isGreen={latest !== null} />,
        },
    ]

    return (
        <Wrapper>
            <Table
                data={tableData}
                columns={{
                    name: { label: Translation.NAME, sort: true },
                    info: { label: Translation.INFO },
                    status: { label: Translation.STATUS },
                }}
            />
        </Wrapper>
    )
}
