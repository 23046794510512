/**
 * Generates a delta between the two given arrays
 *
 * Relies on reference equality for delta creation
 * If the same object is sent twice, it will be referenced only once
 */
export const delta = <T>(before: T[], after: T[]): [removed: T[], notUpdated: T[], added: T[]] => {
    const beforeSet = new Set(before)
    const afterSet = new Set(after)
    const eitherSet = new Set([...before, ...after])

    const removed: T[] = []
    const notUpdated: T[] = []
    const added: T[] = []

    eitherSet.forEach((i) => {
        const beforeHas = beforeSet.has(i)
        const afterHas = afterSet.has(i)

        if (beforeHas === afterHas) {
            // Nothing has changed
            notUpdated.push(i)
        } else if (afterHas) {
            // Something has changed and it was added
            added.push(i)
        } else {
            // Last alternative
            removed.push(i)
        }
    })

    return [removed, notUpdated, added]
}
