import type { Optional } from 'utility-types'
import React, { type FC } from 'react'
import { List, ListItem, Paper, type SxProps, type Theme } from '@mui/material'

import { useRouting } from '../routing'
import { Logo } from '../common'

type Mode = 'dense' | 'regular'
type Props = Readonly<{ mode: Mode, redirect: boolean }>

export const leftContentWidths: Record<Mode, number> = { dense: 256, regular: 480 }

const basePaperStyle: SxProps<Theme> = {
    position: 'sticky',
    left: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: 'primary.dark',
}

const paperStyles: Record<Mode, SxProps<Theme>> = {
    dense: { ...basePaperStyle, minWidth: leftContentWidths.dense, maxWidth: leftContentWidths.dense },
    regular: { ...basePaperStyle, width: leftContentWidths.regular },
}

const logoItemStyle: SxProps<Theme> = { p: 3 }
const regularLogoItemStyle: SxProps<Theme> = { px: 10 }
const clickableStyle: SxProps<Theme> = { cursor: 'pointer' }

const LogoItem: FC<Props> = ({ redirect, mode }) => {
    const routing = useRouting()

    return (
        <ListItem
            sx={[logoItemStyle, mode === 'regular' ? regularLogoItemStyle : null, redirect ? clickableStyle : null]}
            onClick={redirect ? () => routing.redirectHome() : undefined}
            component="li"
        >
            <Logo />
        </ListItem>
    )
}

export const LeftContent: FC<Optional<Props, 'redirect'>> = ({ mode, redirect = false, children }) => (
    <Paper square sx={paperStyles[mode]}>
        <List disablePadding>
            <LogoItem mode={mode} redirect={redirect} />
        </List>
        {children}
    </Paper>
)
