import React, { type FC } from 'react'

import {
    type CybusDetailedConnection,
    selectConnectableLoadedDetailedConnection,
    selectConnection,
    selectDisconnectableLoadedDetailedConnection,
    selectLoadedDetailedConnection,
    Translation,
} from '../../../../domain'

import { useRouting } from '../../routing'

import { ResourceDetails } from '../../Resources'
import { DeleteModal, DisableModal, EnableModal } from '../Bulk'
import { Content } from './Content'
import { HeaderDetails } from './HeaderDetails'
import { HeaderActions } from './HeaderActions'

const Modals: FC = () => {
    const routing = useRouting()

    return (
        <>
            <EnableModal connectionsSelector={selectConnectableLoadedDetailedConnection} />
            <DisableModal connectionsSelector={selectDisconnectableLoadedDetailedConnection} />
            <DeleteModal connectionsSelector={selectLoadedDetailedConnection} onConfirmed={() => routing.redirectToParent()} />
        </>
    )
}

export const Details: FC = () => (
    <ResourceDetails<CybusDetailedConnection>
        subscriptionUsecase="manageConnectionUsecase"
        resourceSelector={selectConnection}
        notFoundTitle={Translation.CONNECTION_NOT_FOUND}
        modals={Modals}
        content={Content}
        headerResourceName={Translation.CONNECTION}
        headerName="name"
        headerActions={HeaderActions}
        infoTitle={Translation.CONNECTION_DETAILS_DOCUMENTATION_TITLE}
        infoBody={Translation.CONNECTION_DETAILS_DOCUMENTATION_BODY}
        headerDetails={HeaderDetails}
    />
)
