import { type ArrayType } from '../../../../utils'
import {
    type CybusPermission,
    type CybusPermissionOperations,
    type CybusPersistedPermission,
    type CybusRole,
    type CybusUser,
    CybusUserProvider,
    type PaginatedData,
    UserManagementEditMode,
} from '../../../../domain'

import { mapContextToPermissionContext, mapResponseToOperations } from '../../../Connectware'

import {
    type PaginatedPermissionsResponse,
    type PaginatedRole,
    type PaginatedRoles,
    type PaginatedUsers,
    type PaginationResponse,
    type Permission,
    type PermissionWithUsage,
    type User,
} from '../Types'
import { mapGrantTypeToAuthenticationMethod } from './Grant'

export type MapUserDataConfiguration = Readonly<{ protectedUsers: Set<string>, protectedRoles: Set<string> }>

export type PaginationConfiguration = Readonly<{ pageSize: number, searchSize: number }>

export class UserManagementMapper {
    private static mapPage<T> (current: T[], { rowsPerPage, pageNumber, totalRows }: PaginationResponse): PaginatedData<T> {
        return { current, totalCount: totalRows, pageSize: rowsPerPage, page: pageNumber }
    }

    private static mapCybusPersistedPermission ({ id, ...rawPermission }: Permission): CybusPersistedPermission {
        return {
            id,
            context: mapContextToPermissionContext(rawPermission.context),
            resource: rawPermission.resource,
            ...mapResponseToOperations(rawPermission.operation),
        }
    }

    private static mapPermission ({ context, resource, usage }: PermissionWithUsage): CybusPermission {
        const users: Record<string, CybusPermissionOperations> = {}
        const roles: Record<string, CybusPermissionOperations> = {}

        usage.forEach(({ isRoleShared, operation, roleName, username }) => {
            const operations = mapResponseToOperations(operation)

            if (isRoleShared) {
                roles[roleName] = operations
            }

            if (username) {
                users[username] = operations
            }
        })

        return { context: mapContextToPermissionContext(context), resource, users, roles }
    }

    constructor (private readonly config: MapUserDataConfiguration) {}

    private mapUser ({
        id,
        username,
        grantTypes,
        tokens,
        identityProvider,
        roles,
        mqttPublishPrefix,
        mfa_is_enrolled: mfaEnrolled,
        enforceMFAEnrollment,
    }: User): CybusUser {
        const user: CybusUser = {
            permissions: [],
            allPermissions: [],
            roles: [],

            mfa: mfaEnrolled,
            enforceMFAEnrollment,

            id,
            username,
            provider: identityProvider === 'ldap' ? CybusUserProvider.LDAP : CybusUserProvider.CONNECTWARE,
            authenticationMethods: grantTypes.map(mapGrantTypeToAuthenticationMethod),
            isProtected: this.config.protectedUsers.has(username),

            /**
             * @todo investigate how ldap users and roles should be managed
             * @see https://cybusio.atlassian.net/browse/CYB-3606
             */
            editMode: identityProvider === 'local' ? UserManagementEditMode.ALL : UserManagementEditMode.MFA_ONLY,

            /**
             * @todo actually map this properly
             * @see https://cybusio.atlassian.net/browse/CYB-3609
             */
            lastSeen: tokens.reduce<Date | null>((r, t) => {
                const created = new Date(t.created_at)
                return r === null || r.getTime() < created.getTime() ? created : r
            }, null),
            mqttPublishPrefix,
        }

        roles.forEach(({ isShared, name, permissions }) => {
            if (isShared) {
                // This is actually a role, so add it
                user.roles.push(name)
            }

            permissions.forEach((rawPermission) => {
                const userPermission = UserManagementMapper.mapCybusPersistedPermission(rawPermission)

                if (!isShared) {
                    user.permissions.push(userPermission)
                }

                user.allPermissions.push(userPermission)
            })
        })

        return user
    }

    private mapRole ({ id, name, permissions, users, ldapgroupdn }: PaginatedRole): CybusRole {
        return {
            id,
            name,
            isProtected: this.config.protectedRoles.has(name),
            permissions: Array.from(
                (permissions as ArrayType<PaginatedRole['permissions']>[])
                    .reduce(
                        (m, p) => (p.context && p.resource && p.id && p.operation ? m.set(p.id, UserManagementMapper.mapCybusPersistedPermission(p)) : m),
                        new Map<string, CybusPersistedPermission>()
                    )
                    .values()
            ),
            users: users.flatMap((u) => (u.username ? [u.username] : [])),

            /**
             * @todo investigate how ldap users and roles should be managed
             * @see https://cybusio.atlassian.net/browse/CYB-3606
             */
            editMode: UserManagementEditMode.ALL,
            ldapGroupDn: ldapgroupdn,
        }
    }

    mapUsers (users: User[]): CybusUser[] {
        return users.map((user) => this.mapUser(user))
    }

    mapUsersPage ({ users, pagination }: PaginatedUsers): PaginatedData<CybusUser> {
        return UserManagementMapper.mapPage(this.mapUsers(users), pagination)
    }

    mapRoles (roles: PaginatedRole[]): CybusRole[] {
        return roles.map((role) => this.mapRole(role))
    }

    mapRolesPage ({ roles, pagination }: PaginatedRoles): PaginatedData<CybusRole> {
        return UserManagementMapper.mapPage(this.mapRoles(roles), pagination)
    }

    mapPermissionsPage ({ permissions, pagination }: PaginatedPermissionsResponse): PaginatedData<CybusPermission> {
        return UserManagementMapper.mapPage(
            permissions.map((permission) => UserManagementMapper.mapPermission(permission)),
            pagination
        )
    }
}
