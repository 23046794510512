import type { CybusEndpoint } from '../../../../../../domain'
import type { SubscriptionFilterArgs } from '../../../../../../application'

import { type EndpointProxyParams, mapEndpoints } from '../../../../../Connectware'
import { ENDPOINT_CLASSNAME_FILTER, PROTOCOL_MAPPER_RESOURCE_STATE_LISTENER } from '../../../../constants'
import type { EndpointProxy, ResourceStateListenerProxy } from '../../../../proxies'
import { listenToProxy, type UnsubFromInstanceFunction, type VrcpEntityHandler, type VrpcHandlerConfiguration, type VrpcInstanceMapper } from '..'

/** Retrieve the content on the pattern */
const ENDPOINT_PATTERN = ENDPOINT_CLASSNAME_FILTER.toString().replace(/\//g, '')

class VrpcResourceStateListenerEndpointProxyInstanceMapper implements VrpcInstanceMapper<ResourceStateListenerProxy, CybusEndpoint> {
    async mapToDomain (proxy: ResourceStateListenerProxy, _: string, { connection, service }: SubscriptionFilterArgs): Promise<Map<string, CybusEndpoint>> {
        return proxy
            .getParams<EndpointProxy>(ENDPOINT_PATTERN, { serviceIds: service ? [service] : undefined, connectionIds: connection ? [connection] : undefined })
            .then((params: EndpointProxyParams[]) =>
                mapEndpoints(params, {}).reduce<Map<string, CybusEndpoint>>((m, data) => m.set(data.id, data), new Map([]))
            )
    }

    onChange (proxy: ResourceStateListenerProxy, listener: () => void): Promise<UnsubFromInstanceFunction> {
        return listenToProxy(proxy, ['state', 'registered', 'unregistered'], listener)
    }
}

export class VrpcResourceStateListenerEndpointProxyInstanceHandler implements VrcpEntityHandler<ResourceStateListenerProxy, CybusEndpoint> {
    readonly instanceMapper = new VrpcResourceStateListenerEndpointProxyInstanceMapper()

    readonly remoteMapper = null

    readonly configuration: VrpcHandlerConfiguration = { classNameFilter: PROTOCOL_MAPPER_RESOURCE_STATE_LISTENER, supportedFilters: ['connection', 'service'] }
}
