import { ConnectwareError, ConnectwareErrorType, type CybusDetailedMapping } from '../../../../../../domain'

import { mapDetailedMapping, type MappingProxyParams } from '../../../../../Connectware'
import type { MappingProxy } from '../../../../proxies'
import { MAPPING_CLASSNAME_FILTER } from '../../../../constants'
import type { VrpcHandlerConfiguration } from '../Handler'
import { BaseMappingMapper, type ListenableEvent, VrpcBaseMappingProxyInstanceHandler } from './Base'

class DetailedMappingMapper extends BaseMappingMapper<'mapping', Record<string, string[]>> {
    protected get events (): ListenableEvent[] {
        return [...super.events, 'executionError']
    }

    protected async fetchInformation (proxy: MappingProxy): Promise<Record<string, string[]>> {
        try {
            return await proxy.getMappedEndpointTopics()
        } catch (e: unknown) {
            throw new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, 'Could not retrieve configured endpoints topics on mapping', {
                id: proxy._targetId,
                message: (e as Error).message,
            })
        }
    }

    protected mapMapping (params: MappingProxyParams, topics: Record<string, string[]>): CybusDetailedMapping {
        return mapDetailedMapping(params, topics)
    }
}

export class VrpcDetailedMappingProxyInstanceHandler extends VrpcBaseMappingProxyInstanceHandler<'mapping'> {
    readonly instanceMapper = new DetailedMappingMapper()

    readonly configuration: VrpcHandlerConfiguration = { classNameFilter: MAPPING_CLASSNAME_FILTER, supportedFilters: [] }
}
