/** Yields the mqtt Worker to be manipulated on the mqtt services */
export const getMqttWorkerRef = (): Worker => {
    /**
     * The way that parcel works when setting up workers is to
     * identify a valid url to file and a worker
     *
     * All of them need to be at the same place in order
     * for this worker to be properly created
     */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return new Worker(new URL('./MqttWorker.ts', import.meta.url), { type: 'module' })
}
