import React, { type FC } from 'react'
import { List } from '@mui/material'

import { type CybusDetailedService, Translation } from '../../../../domain'

import { useTranslator } from '../../Internationalization'

import { ResourcesList } from './ResourcesList'
import { ItemText, TextBlock } from './Commons'

export const Connections: FC<Pick<CybusDetailedService['configuredResources'], 'connections'>> = ({ connections }) => {
    const translator = useTranslator()

    return (
        <ResourcesList title={Translation.CONNECTION} subtitle={Translation.AUTHORIZE_CONNECTIONS}>
            <List data-testid="service-connections">
                {connections.map(({ name, protocol, ...props }, k) => {
                    const host =
                        props.deviceAddress ||
                        props.ipAddress ||
                        props.agent ||
                        props.localInterface ||
                        (props.brokers && translator.formatList(props.brokers)) ||
                        props.host
                    const port = props.deviceInstance || props.cncType || props.device || props.port

                    return (
                        <ItemText
                            key={`connection-${k}`}
                            primary={name}
                            secondary={
                                <>
                                    <TextBlock>
                                        {`${translator.formatTranslation(Translation.PROTOCOL, {
                                            count: 1,
                                        })}: ${protocol}`}
                                    </TextBlock>
                                    {host && (
                                        <TextBlock>
                                            {`${translator.formatTranslation(Translation.HOST, {
                                                count: 1,
                                            })}: ${host}`}
                                        </TextBlock>
                                    )}
                                    {port && (
                                        <TextBlock>
                                            {`${translator.formatTranslation(Translation.PORT, {
                                                count: 1,
                                            })}: ${port}`}
                                        </TextBlock>
                                    )}
                                </>
                            }
                        />
                    )
                })}
            </List>
        </ResourcesList>
    )
}
