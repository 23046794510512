import React, { type FC } from 'react'

import { type AppState, type CybusVolume, ResourceType, Translation } from '../../../domain'

import { useAppUsecases } from '../State'

import { DeleteResourceModal } from '../Resources'

type Volume = Pick<CybusVolume, 'id' | 'name' | 'service'>

type Props = Readonly<{ volumeSelector: (s: AppState, id: string[]) => Volume[] }>

export const DeleteModal: FC<Props & { onConfirmed: VoidFunction }> = ({ volumeSelector, onConfirmed }) => {
    const { bulkDeleteVolumesUsecase } = useAppUsecases()

    return (
        <DeleteResourceModal
            title={Translation.DELETE_VOLUMES}
            subtitle={Translation.DELETE_VOLUMES_SUBTITLE}
            confirm={Translation.DELETE}
            resourceSelector={volumeSelector}
            dimension={ResourceType.VOLUME}
            onRenderLineContent="id"
            onCancel={() => bulkDeleteVolumesUsecase.selectVolumes(null)}
            onConfirm={async () => {
                await bulkDeleteVolumesUsecase.delete()
                onConfirmed()
            }}
        />
    )
}
