import type { FileService } from '../../application'

export class WebFileService implements FileService {
    download (file: File): void {
        const link = document.createElement('a')

        link.download = file.name

        /**
         * Makes blob fetchable through url
         */
        link.href = URL.createObjectURL(file)

        /**
         * Attach to document
         */
        document.body.appendChild(link)

        /**
         * Opens it (effectively downloads it)
         */
        link.click()

        /**
         * Drops garbage
         */
        document.body.removeChild(link)

        /**
         * Tells the browser they don't have to reference the object anymore
         */
        URL.revokeObjectURL(link.href)
    }
}
