import type { PickByValueExact } from 'utility-types'

import { ConnectwareError } from '.'

export type Loadable<T = never, E = never> = T | E | ConnectwareError | null

type LoadableSelector<S, E> = (s: S) => Loadable<E>
type EntitySelector<S, E> = (s: S, id: string[]) => E[]

/** Creates a selector for the loadable items on the domain */
export const createLoadedSelector =
    <AppState, Entity extends object, IdField extends keyof PickByValueExact<Entity, string>>(
        select: LoadableSelector<AppState, Entity>,
        idField: IdField,
        filter?: (c: Entity) => boolean
    ): EntitySelector<AppState, Entity> =>
    (s, ids) => {
        /** Nothing is selected, load nothing */
        if (ids.length === 0) {
            return []
        }

        /** Select from the state */
        const loadable = select(s)

        if (loadable !== null && !ConnectwareError.is(loadable)) {
            const entity = loadable
            const passesFilter = !filter || filter(entity)

            if (passesFilter && ids.includes(entity[idField] as string)) {
                return [entity]
            }
        }

        return []
    }
