import {
    areUsersManagementSelectedTabInformationFiltersEquals,
    type ConnectwareError,
    selectUsersManagement,
    selectUsersManagementPage,
    selectUsersManagementSelectedTab,
    selectUsersManagementSelectedTabInformation,
    type UsersManagement,
} from '../../../domain'

import { Usecase } from '..'

export abstract class BaseUsecase extends Usecase {
    private fetch (): Promise<UsersManagement[UsersManagement['selected']]['data']> {
        const state = this.getState()

        const { search, showInternal, page } = selectUsersManagementSelectedTabInformation(state)

        let promise: Promise<UsersManagement[UsersManagement['selected']]['data']>

        switch (selectUsersManagementSelectedTab(state)) {
            case 'users':
                promise = this.userService.fetchUsersPage(search, showInternal, page)
                break
            case 'roles':
                promise = this.userService.fetchRolesPage(search, showInternal, page)
                break
            case 'permissions':
                promise = this.userService.fetchPermissionsPage(search, showInternal, page)
                break
        }

        return promise.catch((e: ConnectwareError) => e)
    }

    private updateTabInformation (update: Partial<UsersManagement[UsersManagement['selected']]>): void {
        const state = this.getState()

        this.setUsersManagement({
            [selectUsersManagementSelectedTab(state)]: {
                ...selectUsersManagementSelectedTabInformation(state),
                ...update,
            },
        })
    }

    protected setUsersManagement (changes: Partial<UsersManagement>): void {
        this.setState({ usersManagement: { ...selectUsersManagement(this.getState()), ...changes } })
    }

    protected async loadTab (resetTab: boolean): Promise<void> {
        const previousState = this.getState()

        if (resetTab) {
            /** Set the tab as loading */
            this.updateTabInformation({ data: null })
        }

        /** Finally get the data */
        const data = await this.fetch()

        /** Check if filter has been updated */
        if (!areUsersManagementSelectedTabInformationFiltersEquals(previousState, this.getState())) {
            return
        }

        const page = data && 'page' in data ? data.page : null

        if (page !== null && selectUsersManagementPage(previousState) !== page) {
            /** Page does not match what backend says, so update it and let the data be refreshed */
            this.updateTabInformation({ page })
            return
        }

        /** State hasn't been re-fetched */
        this.updateTabInformation({ data } as Partial<UsersManagement[UsersManagement['selected']]>)
    }

    protected async transfer (transfer: Promise<void>): Promise<void> {
        this.setUsersManagement({ transfering: true })
        try {
            await transfer
        } finally {
            this.setUsersManagement({ transfering: false })
        }
    }
}
