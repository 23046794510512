import React, { type FC } from 'react'
import { List } from '@mui/material'

import { type CybusDetailedService, Translation } from '../../../../domain'

import { ResourcesList } from './ResourcesList'
import { ItemText } from './Commons'

export const Volumes: FC<Pick<CybusDetailedService['configuredResources'], 'volumes'>> = ({ volumes }) => (
    <ResourcesList title={Translation.VOLUME} subtitle={Translation.AUTHORIZE_VOLUMES_CREATION_EXPLANATION}>
        <List data-testid="service-volume-list">
            {volumes.map((volume, k) => (
                <ItemText key={`volume-${k}`} primary={volume} />
            ))}
        </List>
    </ResourcesList>
)
