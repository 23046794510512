import { type CybusConnection, ResourceType } from '../../../domain'

import { BulkDeleteResourceUsecase, BulkDisableResourceUsecase, BulkEnableResourceUsecase } from '../Resources'

export class BulkDeleteConnectionsUsecase extends BulkDeleteResourceUsecase {
    protected readonly type = ResourceType.CONNECTION

    selectConnections (ids: CybusConnection['id'][] | null): void {
        this.select(ids)
    }

    delete (): Promise<void> {
        return super.delete()
    }
}

export class BulkConnectConnectionsUsecase extends BulkEnableResourceUsecase {
    protected readonly type = ResourceType.CONNECTION

    selectConnections (ids: CybusConnection['id'][] | null): void {
        this.select(ids)
    }

    connect (): Promise<void> {
        return super.enable()
    }
}

export class BulkDisconnectConnectionsUsecase extends BulkDisableResourceUsecase {
    protected readonly type = ResourceType.CONNECTION

    selectConnections (ids: CybusConnection['id'][] | null): void {
        this.select(ids)
    }

    disconnect (): Promise<void> {
        return super.disable()
    }
}
