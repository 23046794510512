import { Grid } from '@mui/material'
import React, { type FC } from 'react'

import { type CybusDetailedConnection, Translation } from '../../../../domain'

import { useTranslator } from '../../Internationalization'
import { Status } from '../../common'
import { DetailsEntry } from '../../Details'
import { ServiceLinkEntry } from '../../Services'

export const HeaderDetails: FC<{ resource: CybusDetailedConnection }> = ({
    resource: { id, service, status, host, port, deviceAddress, deviceInstance, ipAddress, cncType, agent, className, device, localInterface, brokers },
}) => {
    const translation = useTranslator()

    return (
        <Grid container alignItems="center">
            <Grid id="connection-header-description" item xs={7}>
                <DetailsEntry title={Translation.CONNECTION_ID} type="text">
                    {id}
                </DetailsEntry>
                <ServiceLinkEntry service={service} />

                {host && (
                    <DetailsEntry title={translation.formatTranslation(Translation.HOST, { count: 1 })} type="text">
                        {host}
                    </DetailsEntry>
                )}
                {port && (
                    <DetailsEntry title={translation.formatTranslation(Translation.PORT, { count: 1 })} type="text">
                        {port}
                    </DetailsEntry>
                )}
                {deviceAddress && (
                    <DetailsEntry title={Translation.DEVICE_ADDRESS} type="text">
                        {deviceAddress}
                    </DetailsEntry>
                )}
                {deviceInstance && (
                    <DetailsEntry title={Translation.DEVICE_INSTANCE} type="text">
                        {deviceInstance}
                    </DetailsEntry>
                )}
                {ipAddress && (
                    <DetailsEntry title={Translation.IP_ADDRESS} type="text">
                        {ipAddress}
                    </DetailsEntry>
                )}
                {cncType && (
                    <DetailsEntry title={Translation.CNC_TYPE} type="text">
                        {cncType}
                    </DetailsEntry>
                )}
                {agent && (
                    <DetailsEntry title={translation.formatTranslation(Translation.AGENT, { count: 1 })} type="text">
                        {agent}
                    </DetailsEntry>
                )}
                {className && (
                    <DetailsEntry title={Translation.CLASS_NAME} type="text">
                        {className}
                    </DetailsEntry>
                )}
                {device && (
                    <DetailsEntry title={translation.formatTranslation(Translation.DEVICE, { count: 1 })} type="text">
                        {device}
                    </DetailsEntry>
                )}
                {localInterface && (
                    <DetailsEntry title={Translation.LOCAL_INTERFACE} type="text">
                        {localInterface}
                    </DetailsEntry>
                )}
                {brokers && (
                    <DetailsEntry title={translation.formatTranslation(Translation.BROKER, { count: brokers.length })}>{brokers.join(', ')}</DetailsEntry>
                )}

                <DetailsEntry title={Translation.STATUS}>
                    <Status status={status} />
                </DetailsEntry>
            </Grid>
        </Grid>
    )
}
