import {
    areArrayEquals,
    areRecordsEquals,
    type Comparators,
    createArrayComparator,
    createEqualityChecker,
    createNamedEqualityChecker,
    createPrimitiveValueComparator,
    type ReadonlyRecord,
} from '../../utils'

import { type CybusService, type CybusVolume, StatusType } from '..'

type CybusBaseContainer = Readonly<{
    id: string
    name: string
    service: CybusService['id'] | null
    created: Date | null
    status: StatusType
    volumes: CybusVolume['id'][]
    volumesCount: number
    lastUpdate: Date | null
    config: Readonly<{
        image: string
        workingDir: string
        author: string | null
        vendor: string | null
        capAdd: string | string[] | null
        exposedPorts: string[]
        privileged: boolean
    }>
    variables: ReadonlyRecord<string, string>
}>

type CybusContainer<K extends keyof CybusBaseContainer> = Pick<CybusBaseContainer, 'id' | 'name' | 'status' | 'created' | K>

const nullableDateComparator = createPrimitiveValueComparator((d: Date | null) => d?.getTime())
const baseCybusContainersComparators: Comparators<CybusContainer<never>> = { id: null, name: null, status: null, created: nullableDateComparator }

export type CybusCoreContainer = CybusContainer<'lastUpdate'>

const cybusCoreContainersComparators: Comparators<CybusCoreContainer> = { ...baseCybusContainersComparators, lastUpdate: nullableDateComparator }
export const areCybusCoreContainersEquals = createNamedEqualityChecker<CybusCoreContainer>(cybusCoreContainersComparators, 'areCybusCoreContainersEquals')

export type CybusDetailedCoreContainer = CybusContainer<'volumes' | 'config' | 'variables'>

const areCybusContainerConfigsEquals = createEqualityChecker<CybusBaseContainer['config']>({
    image: null,
    workingDir: null,
    author: null,
    vendor: null,
    capAdd: (a, b) => Array.isArray(a) && Array.isArray(b) && areArrayEquals(a, b, { sort: false }),
    exposedPorts: createArrayComparator(),
    privileged: null,
})

export const areCybusDetailedCoreContainersEquals = createNamedEqualityChecker<CybusDetailedCoreContainer>(
    { ...baseCybusContainersComparators, volumes: createArrayComparator(), config: areCybusContainerConfigsEquals, variables: areRecordsEquals },
    'areCybusDetailedCoreContainersEquals'
)

export type CybusServiceContainer = CybusCoreContainer & CybusContainer<'volumesCount' | 'service'>

export const areCybusServiceContainersEquals = createNamedEqualityChecker<CybusServiceContainer>(
    { ...cybusCoreContainersComparators, volumesCount: null, service: null },
    'areCybusServiceContainersEquals'
)

export type CybusDetailedServiceContainer = CybusDetailedCoreContainer & CybusContainer<'service'>

export const areCybusDetailedServiceContainersEquals = createNamedEqualityChecker<CybusDetailedServiceContainer>(
    { ...baseCybusContainersComparators, service: null, volumes: createArrayComparator(), config: areCybusContainerConfigsEquals, variables: areRecordsEquals },
    'areCybusDetailedServiceContainersEquals'
)

export const canContainerBeDeleted = ({ status }: Pick<CybusBaseContainer, 'status'>): boolean => status === StatusType.EXITED
export const canContainerBeStarted = ({ status }: Pick<CybusBaseContainer, 'status'>): boolean => status === StatusType.EXITED
export const canContainerBeRestarted = ({ status }: Pick<CybusBaseContainer, 'status'>): boolean => status === StatusType.RUNNING
export const canContainerBeStopped = ({ status }: Pick<CybusBaseContainer, 'status'>): boolean => status === StatusType.RUNNING
