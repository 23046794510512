import type { ErrorObject } from 'ajv'

import type { ReadonlyRecord } from '../../../utils'

/**
 * This class is highly dependent on https://bitbucket.org/cybusio/cybus/src/master/service-manager/src/Parser.js
 */

/**
 * Based Parser `this._errors`
 */
export type ParserError =
    | ErrorObject
    | { readonly message: string }
    | { readonly message: string, readonly keyword: string, readonly dataPath: string }
    | { readonly message: string, readonly keyword: string, readonly dataPath: string, readonly params: string }

type ErrorInformation = ReadonlyRecord<'dataPath' | 'content' | 'message', string | null>

const mapParserErrorContent = (error: ParserError): string | null => {
    if (!('params' in error)) {
        return null
    }

    if (typeof error.params === 'string') {
        return error.params || null
    }

    if ('additionalProperty' in error.params) {
        return error.params.additionalProperty || null
    }

    if ('allowedValues' in error.params) {
        return error.params.allowedValues.join(', ') || null
    }

    return null
}

const mapParserErrorInformation = (error: ParserError): ErrorInformation => ({
    dataPath: ('dataPath' in error && error.dataPath) || null,
    content: mapParserErrorContent(error),
    message: error.message || null,
})

export const mapParserErrorsInformation = (errors: ParserError[]): Map<string | null, string> =>
    errors.reduce<Map<string | null, string>>((m, error) => {
        const { dataPath, message, content } = mapParserErrorInformation(error)
        const finalContent = [message, content].filter((m) => m).join(': ')
        return !finalContent && !dataPath ? m : m.set(dataPath, finalContent)
    }, new Map([]))
