import type { CybusService } from '../../../../../../domain'

import type { SubscriptionsTypes } from '../../../../../../application'

import { mapService } from '../../../../../Connectware'
import type { ServiceProxy } from '../../../../proxies'

import { BaseServiceMapper, BaseVrpcServiceProxyInstanceHandler } from './Base'

export class ServiceMapper extends BaseServiceMapper<CybusService> {
    mapToDomain (service: ServiceProxy): Promise<CybusService> {
        return Promise.all([service.getInfo(), service.getLinks()]).then(([info, links]) => mapService(info, links))
    }
}

export class VrpcServiceProxyInstanceHandler extends BaseVrpcServiceProxyInstanceHandler<SubscriptionsTypes['services']> {
    readonly instanceMapper = new ServiceMapper()
}
