import React, { type FC, useEffect } from 'react'
import { Box, Input, type SxProps, type Theme, Typography } from '@mui/material'
import { Search } from '@mui/icons-material'

import { ConnectwareError, Translation } from '../../../../domain'

import { AbsoluteRoutePathWithServiceId, useRouting } from '../../routing'
import { FormattedTranslation, useTranslator } from '../../Internationalization'
import { useAppUsecases } from '../../State'
import { CircularLoader, DetailsHeader } from '../../common'
import { ErrorMessage } from '../../ErrorMessage'

import { AddOrUpdateModal } from '../AddOrUpdate'

import { useSearch, useServices } from './Hooks'
import { ServicesGrid } from './Grid'
import { TemplateDialog } from './Template'
import { ApplicationDialog } from './Application'

const searchStyle: SxProps<Theme> = { my: 'auto' }
const bodyContentStyle: SxProps<Theme> = { my: 2 }

export const Catalog: FC = () => {
    const translator = useTranslator()
    const routing = useRouting()

    const { loadCatalogServicesUsecase, filterCatalogServicesUsecase, installServiceCatalogApplicationUsecase } = useAppUsecases()
    useEffect(() => void loadCatalogServicesUsecase.invoke(), [loadCatalogServicesUsecase])

    const services = useServices()
    const search = useSearch()

    return (
        <>
            <DetailsHeader
                title={{
                    title: <FormattedTranslation id={Translation.SERVICES_CATALOG} />,
                    actions: Array.isArray(services) && services.length > 0 && (
                        <Input
                            id="services-catalog-search"
                            sx={searchStyle}
                            endAdornment={<Search />}
                            placeholder={translator.formatTranslation(Translation.SEARCH_FOR_SERVICES)}
                            value={search}
                            onChange={({ target }) => filterCatalogServicesUsecase.invoke(target.value)}
                        />
                    ),
                }}
                section={{ title: Translation.SERVICES_CATALOG_DOCUMENTATION_TITLE, body: Translation.SERVICES_CATALOG_DOCUMENTATION_BODY }}
            />
            <Box id="services-catalog-grid" sx={bodyContentStyle}>
                {services === null && <CircularLoader />}
                {ConnectwareError.is(services) && <ErrorMessage id="catalog-error-message" error={services} stack extras="section" />}
                {Array.isArray(services) &&
                    (services.length === 0 ? (
                        <Typography align="center">
                            <FormattedTranslation id={Translation.NO_CATALOG_SERVICES} />
                        </Typography>
                    ) : (
                        <ServicesGrid />
                    ))}
            </Box>
            <TemplateDialog />
            <ApplicationDialog />
            <AddOrUpdateModal
                mode="CREATE"
                onConfirmed={(id) => {
                    // Close modal
                    installServiceCatalogApplicationUsecase.close()

                    // Redirect user
                    routing.redirect(AbsoluteRoutePathWithServiceId.SERVICE, id)
                }}
            />
        </>
    )
}
