import type { PickByValueExact } from 'utility-types'

import { type AppState, ConnectwareError, ConnectwareErrorType, selectUsersManagement, type UsersManagement } from '../../../domain'

import { initialState } from '../..'
import { BaseUsecase } from './Base'

export abstract class ViewFormUsecase<Form> extends BaseUsecase {
    protected abstract readonly formName: keyof PickByValueExact<UsersManagement, Form | null>

    protected abstract readonly selectForm: (s: AppState) => Form | null

    private getHiddenFormsState (): Partial<UsersManagement> {
        const { transfering, roleCreation, roleUpdating, userUpdating, userCreation, viewPermission, registryRequest } = selectUsersManagement(initialState)
        const hiddenFormsState: Omit<UsersManagement, 'selected' | 'users' | 'roles' | 'permissions' | 'registry'> = {
            transfering,
            roleCreation,
            roleUpdating,
            userUpdating,
            userCreation,
            viewPermission,
            registryRequest,
        }
        return hiddenFormsState
    }

    protected setForm (form: Form): void {
        this.setUsersManagement({ ...this.getHiddenFormsState(), [this.formName]: form })
    }

    protected getNullableForm (): Form | null {
        return this.selectForm(this.getState())
    }

    protected getForm (): Form {
        const form = this.getNullableForm()

        if (!form) {
            throw new ConnectwareError(ConnectwareErrorType.STATE, 'Can not retrieve form')
        }

        return form
    }

    close (): void {
        this.setUsersManagement(this.getHiddenFormsState())
    }
}
