import React, { type FC } from 'react'

import { Button, type SxProps, type Theme } from '@mui/material'
import { FormatAlignLeft, UploadFile } from '@mui/icons-material'

import { Translation } from '../../../domain'
import { FormattedTranslation } from '../Internationalization'

const containerStyle: SxProps<Theme> = { ml: 2 }

type Props = Readonly<{ onClick: VoidFunction, disabled: boolean }>

export const UploadServiceButton: FC<Props> = ({ disabled, onClick }) => (
    <Button sx={containerStyle} data-testid="add-service" variant="contained" startIcon={<UploadFile />} onClick={onClick} disabled={disabled}>
        <FormattedTranslation id={Translation.UPLOAD_SERVICE} />
    </Button>
)

export const ServiceLogsButton: FC<Props> = ({ onClick, disabled }) => (
    <Button sx={containerStyle} data-testid="service-logs-button" variant="text" startIcon={<FormatAlignLeft />} onClick={onClick} disabled={disabled}>
        <FormattedTranslation id={Translation.SERVICE_LOGS} />
    </Button>
)
