import React, { type FC } from 'react'

import { Translation } from '../../../../domain'

import { Section } from '../Section'
import { FilterTopicsButton } from './FilterTopicsButton'
import { AddTopicButton } from './AddTopicButton'
import { TopicsTree } from './Tree'

export const AvailableTopics: FC = () => (
    <Section
        title={Translation.AVAILABLE_TOPICS}
        collapsable={false}
        controls={
            <>
                <FilterTopicsButton />
                <AddTopicButton />
            </>
        }
    >
        <TopicsTree />
    </Section>
)
