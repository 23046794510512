import { Input, Typography } from '@mui/material'
import React, { type FC, useState } from 'react'

import { Translation } from '../../../domain'
import { FormattedTranslation } from '../Internationalization'
import { useAppUsecases } from '../State'
import { CertificateDialog, type CertificateDialogProps } from './Dialog'

export const DeleteCertificateDialog: FC<Pick<CertificateDialogProps, 'certificate' | 'onClose'>> = (props) => {
    const { deleteCertificateUsecase } = useAppUsecases()
    const [confirmation, setConfirmation] = useState('')

    return (
        <CertificateDialog
            {...props}
            confirmation={Translation.DELETE}
            confirmable={confirmation === props.certificate.issuedBy.commonName}
            onConfirm={() => deleteCertificateUsecase.delete(props.certificate)}
        >
            <Typography>
                <FormattedTranslation
                    id={Translation.DELETE_CERTIFICATE_CONFIRMATION}
                    values={{ br: <br />, confirmation: <b>{props.certificate.issuedBy.commonName}</b> }}
                />
            </Typography>
            <Input id="deletion-confirmation-input" value={confirmation} onChange={(e) => setConfirmation(e.target.value)} />
        </CertificateDialog>
    )
}
