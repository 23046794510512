import { type CybusPermission, selectUsersManagementViewPermission } from '../../../domain'

import { ViewFormUsecase } from './View'

export class ViewPermissionUsecase extends ViewFormUsecase<CybusPermission> {
    protected readonly selectForm = selectUsersManagementViewPermission

    protected readonly formName = 'viewPermission'

    show (viewPermission: CybusPermission): void {
        this.setForm(viewPermission)
    }
}
