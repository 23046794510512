import { type ArrayType, createInterval, executeOnce, normalize } from '../../../utils'

import {
    Capability,
    ConnectwareError,
    ConnectwareErrorType,
    type CybusPermission,
    type CybusRole,
    type CybusUser,
    CybusUserAuthenticationMethod,
    type LdapConfiguration,
    type PaginatedData,
    type RoleCreationRequest,
    type RoleEditingRequest,
    Translation,
    type UserCreationRequest,
    type UserEditingRequest,
} from '../../../domain'

import type { PasswordChangeRequest, TranslationService, UserService } from '../../../application'

import { FetchConnectwareHTTPService } from '../Base'

import type {
    LdapConfigurationResponse,
    PaginatedPermissionsResponse,
    PaginatedRoles,
    PaginatedUsers,
    PasswordPolicy,
    PermissionIndex,
    RoleIndex,
    UserIndex,
    UsernamePolicy,
} from './Types'
import {
    mapRoleCreationRequestToPostRequest,
    mapRoleEditingRequestToPostRequest,
    mapUserCreationRequestToUserPostRequest,
    type MapUserDataConfiguration,
    mapUserEditingRequestToUserPutRequest,
    type PaginationConfiguration,
    UserManagementMapper,
} from './mappers'
import { ListDataFetcher, PaginatedDataFetcher } from './UserFetcher'
import { PolicyValidator } from './PolicyValidator'

type Config = Readonly<{ usersConfig: MapUserDataConfiguration, pagination: PaginationConfiguration, usernamePolicy: UsernamePolicy, cacheInterval: number }>

export const LDAP_GROUP_MODE = 'GROUP'

export class ConnectwareHTTPUserService extends FetchConnectwareHTTPService implements UserService {
    private readonly fetchUsernamePolicy = executeOnce(() => Promise.resolve<UsernamePolicy>(this.config.usernamePolicy), true)

    private readonly fetchPasswordPolicy = executeOnce(
        () =>
            this.request({
                capability: Capability.USERS_AND_ROLES_MANAGE,
                method: 'GET',
                path: '/api/policy/password',
                authenticate: true,
                handlers: {
                    200: (r) => r.getJson<PasswordPolicy>(),
                    503: () => new ConnectwareError(ConnectwareErrorType.SERVER_NOT_AVAILABLE, 'Network error when reaching server'),
                },
            }),
        true
    )

    private readonly fetchUsernamesIndex = executeOnce(
        () =>
            this.request({
                capability: Capability.USERS_AND_ROLES_MANAGE,
                method: 'GET',
                path: '/api/users/usernames',
                authenticate: true,
                handlers: { 200: (r) => r.getJson<UserIndex>().then((data) => data.map((u) => u.username)) },
            }),
        true
    )

    private readonly fetchRoleNamesIndex = executeOnce(
        () =>
            this.request({
                capability: Capability.USERS_AND_ROLES_MANAGE,
                method: 'GET',
                path: '/api/roles/names',
                authenticate: true,
                /** Filters out auto generated names which are internal roles */
                handlers: {
                    200: (r) => r.getJson<RoleIndex>().then((roles) => roles.filter((r) => !/^\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/.test(r.name))),
                },
            }),
        true
    )

    private readonly fetchPermissionsIndex = executeOnce(
        () =>
            this.request({
                capability: Capability.USERS_AND_ROLES_MANAGE,
                method: 'GET',
                path: '/api/permissions/ids',
                authenticate: true,
                handlers: { 200: (r) => r.getJson<PermissionIndex>() },
            }),
        true
    )

    fetchLdapConfiguration: () => Promise<LdapConfiguration> = executeOnce(
        () =>
            this.request({
                capability: Capability.USERS_AND_ROLES_MANAGE,
                method: 'GET',
                path: '/api/auth/ldap',
                authenticate: true,
                handlers: {
                    200: async (response) => {
                        const { enabled, mode } = await response.getJson<LdapConfigurationResponse>()
                        return {
                            enabled: enabled && mode === LDAP_GROUP_MODE,
                        }
                    },
                },
            }),
        true
    )

    private readonly passwordValidator: PolicyValidator

    private readonly roleFetcher: ListDataFetcher<CybusRole, ArrayType<RoleIndex>, PaginatedRoles>

    private readonly userFetcher: ListDataFetcher<CybusUser, string, PaginatedUsers>

    private readonly permissionFetcher: PaginatedDataFetcher<CybusPermission, ArrayType<PermissionIndex>, PaginatedPermissionsResponse>

    constructor (baseURL: string, tokenGetter: () => string | null, private readonly config: Config, private readonly translationService: TranslationService) {
        super(baseURL, tokenGetter)

        const { usersConfig, pagination, cacheInterval } = config

        this.passwordValidator = new PolicyValidator(translationService)

        const responseMapper = new UserManagementMapper(usersConfig)

        this.roleFetcher = new ListDataFetcher<CybusRole, ArrayType<RoleIndex>, PaginatedRoles>({
            pagination,
            indexRequester: () => this.fetchRoleNamesIndex(),
            informationRequester: (queryParams, mapper) =>
                this.request({
                    capability: Capability.USERS_AND_ROLES_MANAGE,
                    method: 'GET',
                    path: '/api/roles/page',
                    authenticate: true,
                    queryParams,
                    handlers: { 200: (r) => r.getJson<PaginatedRoles>().then(mapper) },
                }),
            createStaticParameters: ({ showInternal }) => {
                const staticValues = { isShared: true }
                return showInternal === null || showInternal ? staticValues : { autoGenerated: false, ...staticValues }
            },
            filterParameter: 'names',
            mapPageResponse: (response) => responseMapper.mapRolesPage(response),
            mapListResponse: (response) => responseMapper.mapRoles(response.roles),
            filterParameterValuesMapper: ({ name }, { search, exactSearch, rawSearch }) =>
                (exactSearch && name === rawSearch) || (!exactSearch && normalize(name).includes(search)) ? [name] : [],
        })

        this.userFetcher = new ListDataFetcher<CybusUser, string, PaginatedUsers>({
            pagination,
            indexRequester: () => this.fetchUsernamesIndex(),
            informationRequester: (queryParams, mapper) =>
                this.request({
                    capability: Capability.USERS_AND_ROLES_MANAGE,
                    method: 'GET',
                    path: '/api/listUsers',
                    queryParams,
                    authenticate: true,
                    handlers: { 200: (r) => r.getJson<PaginatedUsers>().then(mapper) },
                }),
            createStaticParameters: (a) => (a.showInternal === null ? {} : { excludeAutoGenerated: !a.showInternal }),
            filterParameter: 'usernameEq',
            mapPageResponse: (response) => responseMapper.mapUsersPage(response),
            mapListResponse: (response) => responseMapper.mapUsers(response.users),
            filterParameterValuesMapper: (name, { search, exactSearch, rawSearch }) =>
                (exactSearch && name === rawSearch) || (!exactSearch && normalize(name).includes(search)) ? [name] : [],
        })

        this.permissionFetcher = new PaginatedDataFetcher({
            pagination,
            indexRequester: () => this.fetchPermissionsIndex(),
            informationRequester: (queryParams, mapper) =>
                this.request({
                    capability: Capability.USERS_AND_ROLES_MANAGE,
                    method: 'GET',
                    path: '/api/permissions/page',
                    queryParams,
                    authenticate: true,
                    handlers: { 200: (r) => r.getJson<PaginatedPermissionsResponse>().then(mapper) },
                }),
            shouldFilter: (args) => args.showInternal === false,
            filterParameter: 'ids',
            mapPageResponse: (response) => responseMapper.mapPermissionsPage(response),
            filterParameterValuesMapper: ({ context, operation, resource, ids }, { search, showInternal }) =>
                (!showInternal && resource.startsWith('/api/')) ||
                (search && !normalize(context).includes(search) && !normalize(operation).includes(search) && !normalize(resource).includes(search))
                    ? []
                    : ids,
        })

        createInterval(() => this.clearUsersCache(), cacheInterval)
    }

    private async ensureNameUsage (
        entityByNamePromise: Promise<{ id: string }>,
        id: string | null,
        ...translate: Parameters<TranslationService['translate']>
    ): Promise<void> {
        const entityByNameOrError = await entityByNamePromise.catch((e: ConnectwareError) => e)

        if (
            /** The entity should not exist while updating or creating, and the BE error dictates so, all good */
            ConnectwareError.isOfTypes(entityByNameOrError, ConnectwareErrorType.NOT_FOUND) ||
            /** The entity should exist, and its id equals then current one, all good */
            (id !== null && !ConnectwareError.is(entityByNameOrError) && entityByNameOrError.id === id)
        ) {
            return
        }

        /** Either propage issues with the current promise or throw the usage error we found */
        throw ConnectwareError.is(entityByNameOrError)
            ? entityByNameOrError
            : new ConnectwareError(ConnectwareErrorType.GENERAL_BUSINESS_RULE_INFRACTION, this.translationService.translate(...translate))
    }

    private ensureUsernameUsage (id: CybusUser['id'] | null, username: CybusUser['username']): Promise<void> {
        return this.ensureNameUsage(this.fetchUser(username), id, Translation.USERNAME_ALREADY_USED, { username })
    }

    private ensureRoleNameUsage (id: CybusRole['id'] | null, name: CybusRole['name']): Promise<void> {
        return this.ensureNameUsage(this.fetchRole(name), id, Translation.ROLE_NAME_ALREADY_USED, { name })
    }

    protected clearUsersCache (): void {
        this.fetchUsernamesIndex.clear()
        this.fetchRoleNamesIndex.clear()
        this.fetchPermissionsIndex.clear()
    }

    changePassword (body: PasswordChangeRequest): Promise<void> {
        return this.request({
            capability: Capability.MINIMUM,
            method: 'PUT',
            path: '/api/users/change-password',
            authenticate: true,
            body,
            handlers: {
                204: () => Promise.resolve(),
                403: () => new ConnectwareError(ConnectwareErrorType.AUTHENTICATION, 'There were issues while authenticating the user'),
            },
        })
    }

    validatePassword (password: string): Promise<(string | ConnectwareError)[]> {
        return this.passwordValidator.validatePassword(this.fetchPasswordPolicy(), password)
    }

    validateUsername (username: string): Promise<(string | ConnectwareError)[]> {
        return this.passwordValidator.validateUsername(this.fetchUsernamePolicy(), username)
    }

    fetchRoles (search: string): Promise<CybusRole[]> {
        return this.roleFetcher.fetch(search)
    }

    fetchRolesPage (search: string, showInternal: boolean, pageNumber: number): Promise<PaginatedData<CybusRole>> {
        return this.roleFetcher.fetchPage(search, showInternal, pageNumber)
    }

    fetchRole (roleName: CybusRole['name']): Promise<CybusRole> {
        return this.roleFetcher.fetchOne(roleName)
    }

    fetchUsers (search: string): Promise<CybusUser[]> {
        return this.userFetcher.fetch(search)
    }

    fetchUsersPage (search: string, showInternal: boolean, pageNumber: number): Promise<PaginatedData<CybusUser>> {
        return this.userFetcher.fetchPage(search, showInternal, pageNumber)
    }

    fetchUser (username: CybusUser['username']): Promise<CybusUser> {
        return this.userFetcher.fetchOne(username)
    }

    fetchPermissionsPage (search: string, showInternal: boolean, pageNumber: number): Promise<PaginatedData<CybusPermission>> {
        return this.permissionFetcher.fetchPage(search, showInternal, pageNumber)
    }

    async createUser (request: UserCreationRequest): Promise<void> {
        await this.ensureUsernameUsage(null, request.username)
        await this.request({
            capability: Capability.USERS_AND_ROLES_MANAGE,
            method: 'POST',
            path: '/api/users/batch',
            authenticate: true,
            body: [mapUserCreationRequestToUserPostRequest(request)],
            handlers: { 201: () => Promise.resolve() },
        })
        this.clearUsersCache()
    }

    async updateUser ({ id, ...request }: UserEditingRequest): Promise<void> {
        // check if the user is protected and if the authentication method "TOKEN" is part of the request
        if (
            request.username &&
            this.config.usersConfig.protectedUsers.has(request.username) &&
            !request.authenticationMethods?.includes(CybusUserAuthenticationMethod.TOKEN)
        ) {
            throw new ConnectwareError(
                ConnectwareErrorType.BAD_REQUEST,
                this.translationService.translate(Translation.USER_UPDATE_ERROR_PROTECTED_USER, {
                    method: this.translationService.translate(Translation.USER_AUTHENTICATION_METHOD, { method: CybusUserAuthenticationMethod.TOKEN }),
                    username: request.username,
                })
            )
        }

        if (request.username !== undefined) {
            await this.ensureUsernameUsage(id, request.username)
        }

        await this.request({
            capability: Capability.USERS_AND_ROLES_MANAGE,
            method: 'PUT',
            path: '/api/users/+',
            pathParams: [id],
            authenticate: true,
            body: mapUserEditingRequestToUserPutRequest(request),
            handlers: { 200: () => Promise.resolve() },
        })
        this.clearUsersCache()
    }

    async deleteUser (id: CybusUser['id']): Promise<void> {
        await this.request({
            capability: Capability.USERS_AND_ROLES_MANAGE,
            method: 'POST',
            path: '/api/users/batch/delete',
            authenticate: true,
            body: [id],
            handlers: { 200: () => Promise.resolve() },
        })
        this.clearUsersCache()
    }

    async createRole (request: RoleCreationRequest): Promise<void> {
        await this.ensureRoleNameUsage(null, request.name)
        await this.request({
            capability: Capability.USERS_AND_ROLES_MANAGE,
            method: 'POST',
            path: '/api/roles',
            authenticate: true,
            body: mapRoleCreationRequestToPostRequest(request),
            handlers: { 201: () => Promise.resolve() },
        })
        this.clearUsersCache()
    }

    async updateRole ({ id, ...request }: RoleEditingRequest): Promise<void> {
        const body = mapRoleEditingRequestToPostRequest(request)
        await this.ensureRoleNameUsage(id, request.name)
        await this.request({
            capability: Capability.USERS_AND_ROLES_MANAGE,
            method: 'PUT',
            path: '/api/roles/+',
            pathParams: [id],
            authenticate: true,
            body,
            handlers: { 200: () => Promise.resolve() },
        })
        this.clearUsersCache()
    }

    async deleteRole (id: CybusRole['id']): Promise<void> {
        const name = await this.fetchRoleNamesIndex().then((roles) => roles.find((r) => r.id === id)?.name)

        if (!name) {
            /** Should only happen in inconsistent scenarios where the role is somehow gone from the BE */
            throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Could not find role by name', { id })
        }

        const { users } = await this.roleFetcher.fetchOne(name)

        if (users.length) {
            /** There are users with this role still */
            throw new ConnectwareError(
                ConnectwareErrorType.GENERAL_BUSINESS_RULE_INFRACTION,
                this.translationService.translate(Translation.IS_USED_BY, {
                    used: this.translationService.translate(Translation.ROLE, { count: 1 }),
                    user: this.translationService.list(users),
                })
            )
        }

        await this.request({
            capability: Capability.USERS_AND_ROLES_MANAGE,
            method: 'DELETE',
            path: '/api/roles/+',
            pathParams: [id],
            authenticate: true,
            handlers: { 200: () => Promise.resolve() },
        })
        this.clearUsersCache()
    }
}
