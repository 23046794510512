import { selectAuthenticationExpiresAt } from '../../../domain'
import { LogoutUsecase } from '.'

export class LoginFromPersistenceUsecase extends LogoutUsecase {
    /**
     * Reads from persistence layer, searching for persisted token that can be re-used
     *
     * @returns if information was there and valid from the perspective of the front-end
     */
    loginWithPersistedInformation (): boolean {
        let authentication = this.authenticationPersistenceService.retrieve()

        /**
         * Check if the token is expired
         */
        if (authentication && selectAuthenticationExpiresAt(authentication) < new Date()) {
            /**
             * Either there is no info, or its in the past, so drop it
             */
            authentication = null
        }

        this.updateAuthentication(authentication)

        return Boolean(authentication)
    }
}
