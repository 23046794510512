import React, { type FC } from 'react'
import { List, type SxProps, type Theme } from '@mui/material'
import { AccountCircle, Assessment, Category, Construction, Logout, ManageAccounts, Memory, MenuBook, SettingsSuggest } from '@mui/icons-material'

import { Capability, Translation } from '../../../../domain'

import { AbsoluteRouteOnlyPath } from '../../routing'
import { useAppUsecases } from '../../State'
import { useDocumentation } from '../../Documentation'

import { NavigationLink } from './Link'
import { NavigationLinkList } from './List'

const listStyle: SxProps<Theme> = { overflowY: 'auto', flex: 1, mx: 1 }

export const NavigationMenu: FC = () => {
    const { navigationUsecase, logoutUsecase } = useAppUsecases()
    const openDocumentation = useDocumentation()

    return (
        <>
            <List sx={listStyle} disablePadding>
                <NavigationLink route={AbsoluteRouteOnlyPath.SERVICES} icon={SettingsSuggest} />

                <NavigationLink route={AbsoluteRouteOnlyPath.RESOURCES} icon={Memory} />

                <NavigationLinkList
                    title={Translation.USER_MANAGEMENT}
                    description={Translation.NAVIGATION_USER_MANAGEMENT_DESCRIPTION}
                    icon={ManageAccounts}
                    divide
                >
                    <NavigationLink nested route={AbsoluteRouteOnlyPath.USER_MANAGEMENT} />
                    <NavigationLink nested route={AbsoluteRouteOnlyPath.CLIENT_REGISTRY} />
                    <NavigationLink nested route={AbsoluteRouteOnlyPath.CERTIFICATES} />
                </NavigationLinkList>

                <NavigationLink route={AbsoluteRouteOnlyPath.CATALOG} icon={Category} divide />

                <NavigationLinkList title={Translation.TOOL} description={Translation.NAVIGATION_TOOLS_DESCRIPTION} icon={Construction}>
                    <NavigationLink nested route={AbsoluteRouteOnlyPath.EXPLORER} divide />
                    <NavigationLink nested route={AbsoluteRouteOnlyPath.RULE_ENGINE_SANDBOX} />
                    <NavigationLink
                        nested
                        title={Translation.WORKBENCH}
                        isTitleSingular
                        onClick={() => navigationUsecase.openWorkbench()}
                        requiredCapabilities={[Capability.WORKBENCH]}
                    />
                </NavigationLinkList>

                <NavigationLinkList title={Translation.SYSTEM} description={Translation.NAVIGATION_SYSTEM_DESCRIPTION} icon={Assessment} divide>
                    <NavigationLink nested route={AbsoluteRouteOnlyPath.INFO} />
                    <NavigationLink nested route={AbsoluteRouteOnlyPath.METRICS} />
                    <NavigationLink nested route={AbsoluteRouteOnlyPath.STATUSES} />
                    <NavigationLink nested route={AbsoluteRouteOnlyPath.AGENTS} />
                    <NavigationLink nested route={AbsoluteRouteOnlyPath.MAINTENANCE} />
                </NavigationLinkList>
            </List>
            <List>
                <NavigationLink
                    title={Translation.DOCUMENTATION}
                    isTitleSingular={false}
                    icon={MenuBook}
                    onClick={openDocumentation}
                    requiredCapabilities={[]}
                />
                <NavigationLink route={AbsoluteRouteOnlyPath.SETTINGS} icon={AccountCircle} />
                <NavigationLink
                    title={Translation.LOGOUT}
                    isTitleSingular={false}
                    icon={Logout}
                    onClick={() => logoutUsecase.logout()}
                    requiredCapabilities={[]}
                />
            </List>
        </>
    )
}
