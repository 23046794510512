import { type TopicPathConfiguration, type TopicSubscriptionError } from '../Topics'

export enum TopicType {
    CUSTOM = 'CUSTOM',
    ENDPOINT = 'ENDPOINT',
    MAPPING = 'MAPPING',
    NODE = 'NODE',
}

export type SystemTopicSource = Readonly<{ type: Exclude<TopicType, TopicType.CUSTOM>, id: string }>

export type TopicConfiguration = TopicPathConfiguration & Readonly<{ source: TopicType.CUSTOM | SystemTopicSource[] }>

export type AvailableTopic = TopicConfiguration & Readonly<{ selected: boolean, subscriptionErrors: TopicSubscriptionError[] }>
