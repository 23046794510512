import React, { type FC, useEffect } from 'react'
import { useSnackbar } from 'notistack'

import { selectIsUsersManagementRegistryLocked, selectLoadedRegistryUnlockedUntil, Translation } from '../../../../domain'

import { useAppState } from '../../State'
import { FormattedTimePast, FormattedTranslation } from '../../Internationalization'

export const RegistrationMessage: FC = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const locked = useAppState(selectIsUsersManagementRegistryLocked)
    const unlockedUntil = useAppState(selectLoadedRegistryUnlockedUntil)

    useEffect(() => {
        if (unlockedUntil === null || locked) {
            return
        }

        const snackKey = `registration-${unlockedUntil.getTime()}`

        enqueueSnackbar(
            <FormattedTranslation
                id={Translation.CLIENT_REGISTRY_SELF_REGISTRATION}
                values={{ formattedTimeLeft: <FormattedTimePast value={unlockedUntil} /> }}
            />,
            { variant: 'info', persist: true, key: snackKey }
        )

        return () => closeSnackbar(snackKey)
    }, [unlockedUntil?.getTime(), locked])

    return null
}
