type Clearable<A extends unknown[], V> = { (...args: A): V, clear(): void }

/**
 * @description wraps the given function, and will call it only once
 */
export const executeOnce = <A extends unknown[], V>(generator: (...args: A) => V, retryOnError = false): Clearable<A, V> => {
    let result: [value: V, isError: boolean] | null = null

    const handler = (...args: A): V => {
        if (!result || (retryOnError && result[1])) {
            try {
                const value = generator(...args)

                result = [value, false]

                if (value instanceof Promise) {
                    /**
                     * If yielded value is a promise
                     * Correctly flag as an error
                     */
                    value.catch(() => {
                        result = [value, true]
                    })
                }
            } catch (e: unknown) {
                result = [e as V, true]
            }
        }

        const [value, isError] = result

        if (isError) {
            throw value as unknown as Error
        }

        return value
    }

    /**
     * Useful function to reset value
     */
    handler.clear = () => {
        result = null
    }

    return handler
}
