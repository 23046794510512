import { createLoadedSelector, type Loadable } from '../Loadable'
import { createPageSelector, type Page } from '..'
import { canEndpointBeDisabled, canEndpointBeEnabled, type CybusDetailedEndpoint, type CybusEndpoint } from './Endpoint'

export type EndpointsAppState = Readonly<{ endpoint: Loadable<CybusDetailedEndpoint>, endpointsPage: Page<CybusEndpoint> }>

export const selectDetailedEndpoint = (s: EndpointsAppState): EndpointsAppState['endpoint'] => s.endpoint
export const selectEndpointsPage = (s: EndpointsAppState): EndpointsAppState['endpointsPage'] => s.endpointsPage

export const selectEnabableLoadedDetailedEndpoint = createLoadedSelector(selectDetailedEndpoint, 'id', canEndpointBeEnabled)
export const selectDisabableLoadedDetailedEndpoint = createLoadedSelector(selectDetailedEndpoint, 'id', canEndpointBeDisabled)
export const selectLoadedDetailedEndpoint = createLoadedSelector(selectDetailedEndpoint, 'id')

export const selectEnabableLoadedEndpoints = createPageSelector(selectEndpointsPage, 'id', 'selectEnabableLoadedEndpoints', canEndpointBeEnabled)
export const selectDisabableLoadedEndpoints = createPageSelector(selectEndpointsPage, 'id', 'selectDisabableLoadedEndpoints', canEndpointBeDisabled)
export const selectLoadedEndpoints = createPageSelector(selectEndpointsPage, 'id', 'selectLoadedEndpoints')
