import type { PickByValue, PickByValueExact } from 'utility-types'
import React, { type FC, type PropsWithChildren, useEffect } from 'react'

import { Box, type SxProps, type Theme } from '@mui/material'

import { type AppState, ConnectwareError, ConnectwareErrorType, type Translation } from '../../../domain'
import type { Usecases } from '../../../application'

import { useAppState, useAppUsecase } from '../State'
import { useId } from '../routing'
import { FormattedTranslation, useTranslator } from '../Internationalization'
import { CircularLoader, DetailsHeader } from '../common'
import { ErrorMessage } from '../ErrorMessage'

const wrapperStyle: SxProps<Theme> = { mt: 2, width: '100%' }

type Props<Resource,> = Readonly<{
    /**
     * The use case the details will subscribe to
     *
     * It expects the name of the usecase that has the subscribe method AND yields a unsub function
     */
    subscriptionUsecase: keyof PickByValue<Usecases, { subscribe: (id: string) => VoidFunction | void }>

    /**
     * Should yield the single resource
     */
    resourceSelector: (s: AppState) => Resource | ConnectwareError | null

    /**
     * In case the resource is not found, it will display this as the title
     */
    notFoundTitle: Translation

    /**
     * The header information of the resource
     */
    infoTitle: Translation
    infoBody: Translation

    /**
     * The header title of the resource
     */
    headerResourceName: Translation
    headerName: keyof PickByValueExact<Resource, string>
    headerActions: FC<{ resource: Resource }>

    /**
     * The header details of the resource
     */
    headerDetails: FC<{ resource: Resource }>

    /**
     * The content of the resource
     */
    content: FC<{ resource: Resource }>

    /**
     * The modals to manage the resource
     */
    modals: FC
}>

export const ResourceDetails = <Resource extends Record<string, unknown>,>({
    resourceSelector,
    subscriptionUsecase,
    notFoundTitle,
    modals: Modals,
    headerResourceName,
    headerName,
    headerActions: HeaderActions,
    infoTitle,
    infoBody,
    headerDetails: HeaderDetails,
    content: Content,
}: PropsWithChildren<Props<Resource>>): ReturnType<FC> => {
    const id = useId()

    const resource = useAppState(resourceSelector)
    const translator = useTranslator()

    const subscription = useAppUsecase(subscriptionUsecase)

    /**
     * Subscribe to resource changes
     */
    useEffect(() => subscription.subscribe(id), [subscription, id])

    if (resource === null) {
        return <CircularLoader />
    }

    if (ConnectwareError.is(resource)) {
        return (
            <ErrorMessage
                customTitle={(e) =>
                    ConnectwareError.isOfTypes(e, ConnectwareErrorType.NOT_FOUND) ? translator.formatTranslation(notFoundTitle, { id }) : null
                }
                error={resource}
                stack
                extras="section"
            />
        )
    }

    return (
        <>
            <DetailsHeader
                title={{
                    title: (
                        <>
                            <FormattedTranslation id={headerResourceName} values={{ count: 1 }} />
                            {': '}
                            {resource[headerName]}
                        </>
                    ),
                    actions: <HeaderActions resource={resource} />,
                }}
                section={{ title: infoTitle, body: infoBody }}
            />
            <HeaderDetails resource={resource} />
            <Box sx={wrapperStyle}>
                <Content resource={resource} />
            </Box>
            <Modals />
        </>
    )
}
