import { executeOnce } from '../../utils'
import { ConnectwareError, ConnectwareErrorType } from '../Error'
import type { Loadable } from '../Loadable'
import type { Explorer, TopicType } from '.'

export type ExplorerAppState = Readonly<{ explorer: Loadable<Explorer> }>

export const selectExplorer = (s: ExplorerAppState): ExplorerAppState['explorer'] => s.explorer

export const selectLoadedExplorer = (s: ExplorerAppState): Explorer => {
    const explorer = selectExplorer(s)

    if (!explorer || ConnectwareError.is(explorer)) {
        throw new ConnectwareError(ConnectwareErrorType.STATE, 'Can not retrieve explorer')
    }

    return explorer
}

export const selectExplorerError = (s: ExplorerAppState): ConnectwareError | null => {
    const explorer = selectExplorer(s)
    return ConnectwareError.is(explorer) ? explorer : null
}

const createInitializedExplorerSelector =
    <T, D>(selector: (explorer: Explorer) => T, defaultValue: D): ((s: ExplorerAppState) => T | D) =>
    (s) => {
        const explorer = selectExplorer(s)
        return !explorer || ConnectwareError.is(explorer) ? defaultValue : selector(explorer)
    }

export const selectSelectedTopicsRawPath = (s: ExplorerAppState): string[] => {
    const { topics } = selectLoadedExplorer(s)
    return topics.reduce<string[]>((r, t) => (t.selected ? [...r, t.rawPath] : r), [])
}

export const selectExplorerFilteredResources = createInitializedExplorerSelector((e) => e.filter.resources, [])

export const indexExplorerFilteredResources = (resources: ReturnType<typeof selectExplorerFilteredResources>): ((resource: string) => boolean | null) => {
    const getIndexed = executeOnce(() => new Set(resources))
    return (resource) => (getIndexed().size === 0 ? null : getIndexed().has(resource))
}

export const selectExplorerFilteredTypes = createInitializedExplorerSelector((e) => e.filter.types, [])

export const indexExplorerFilteredTypes = (types: ReturnType<typeof selectExplorerFilteredTypes>): ((type: TopicType) => boolean) => {
    const getIndexed = executeOnce(() => new Set(types))
    return (type) => getIndexed().has(type)
}

export const selectExplorerTopics = createInitializedExplorerSelector((e) => e.topics, [])

export const selectExplorerLatestTopicValues = createInitializedExplorerSelector((e) => e.latestValues, {})

export const selectExplorerTailSize = createInitializedExplorerSelector((e) => e.tailSize, 0)

export const selectExplorerIsTailing = createInitializedExplorerSelector((e) => e.isTailing, null)

export const selectExplorerTail = createInitializedExplorerSelector((e) => e.tail, [])

export const selectConfigurationLoadError = createInitializedExplorerSelector((e) => e.configurationLoadError, null)
