import React, { type FC } from 'react'

import { ConnectwareError } from '../../../../domain'

import { useAppUsecase } from '../../State'

import { type JsonEditorFC } from '../../JsonEditor'
import { useInput } from './Hooks'

export const JsonInput: FC<{ Editor: JsonEditorFC }> = ({ Editor }) => {
    const filter = useAppUsecase('filterSandboxUsecase')
    const input = useInput()

    return (
        <Editor
            initialJson={input.value}
            onChange={(newJson) => {
                if (!ConnectwareError.is(newJson)) {
                    filter.setJson(newJson)
                }
            }}
        />
    )
}
