import React, { type FC, Fragment } from 'react'
import { Box, type SxProps, type Theme, Typography } from '@mui/material'

import { type AppState, type CybusService, ResourceType, Translation } from '../../../domain'

import { useAppUsecase } from '../State'
import { DeleteResourceModal, DisableResourceModal } from '../Resources'
import { Status } from '../common'

const entriesStyle: SxProps<Theme> = { display: 'flex' }
const entryNameStyle: SxProps<Theme> = { mr: 'auto' }

const ModalBody: FC<Readonly<{ resource: CybusService }>> = ({ resource }) => (
    <Box sx={entriesStyle}>
        <Typography sx={entryNameStyle}>
            {resource.name} ({resource.id})
        </Typography>
        <Status status={resource.status} />
    </Box>
)

export const BulkDeletionModal: FC<Readonly<{ onConfirmed: VoidFunction, resourceSelector: (s: AppState, ids: CybusService['id'][]) => CybusService[] }>> = ({
    resourceSelector,
    onConfirmed,
}) => {
    const usecase = useAppUsecase('bulkDeleteServicesUsecase')

    return (
        <DeleteResourceModal<CybusService>
            title={Translation.DELETE_SERVICES}
            subtitle={Translation.DELETE_SERVICES_SUBTITLE}
            confirm={Translation.DELETE}
            resourceSelector={resourceSelector}
            dimension={ResourceType.SERVICE}
            onRenderLine={Fragment}
            onRenderLineContent={ModalBody}
            onCancel={() => usecase.selectServices(null)}
            onConfirm={async () => {
                await usecase.delete()
                onConfirmed()
            }}
        />
    )
}

export const BulkDisablingModal: FC<{ resourceSelector: (s: AppState, ids: CybusService['id'][]) => CybusService[] }> = ({ resourceSelector }) => {
    const usecase = useAppUsecase('bulkDisableServicesUsecase')

    return (
        <DisableResourceModal<CybusService>
            title={Translation.DISABLE_SERVICES}
            subtitle={Translation.BULK_DISABLE_SERVICES_CONFIRMATION}
            confirm={Translation.DISABLE}
            resourceSelector={resourceSelector}
            dimension={ResourceType.SERVICE}
            onRenderLine={Fragment}
            onRenderLineContent={ModalBody}
            onCancel={() => usecase.selectServices(null)}
            onConfirm={() => usecase.disable()}
        />
    )
}
