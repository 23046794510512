import type { SubscriptionData, SubscriptionsTypes } from '../../../../../application'

import { type ManagedVrpcRemote } from '../../../utils'
import type { UnsubFromRemoteFunction, VrpcRemoteMapper } from '../handlers'
import { EntitiesManager, EntityManager } from './Manager'

class RemoteManager<T extends keyof SubscriptionsTypes> extends EntityManager<T, Parameters<UnsubFromRemoteFunction>> {
    private readonly remote: ManagedVrpcRemote

    private readonly mapper: VrpcRemoteMapper<SubscriptionData<T>>

    protected readonly errorMessage: string = 'Error while interacting with remote'

    protected readonly internallyDroppedArgs: Parameters<UnsubFromRemoteFunction> = []

    constructor (remote: ManagedVrpcRemote, mapper: VrpcRemoteMapper<SubscriptionData<T>>, handler: VoidFunction) {
        super(handler)
        this.remote = remote
        this.mapper = mapper

        void this.safelyAddListener()
        void this.safelySetValue()
    }

    protected addListener (): Promise<UnsubFromRemoteFunction | void> | void {
        return this.mapper.onChange?.(this.remote, () => void this.safelySetValue())
    }

    protected generateValue (): Promise<SubscriptionData<T> | Map<string, SubscriptionData<T>>> {
        return this.mapper.mapToDomain(this.remote)
    }
}

/**
 * Some of our subscription mappers interact with the remotes instead of the instances
 * This class deals with those mapper scenarios
 */

export class RemotesManager<T extends keyof SubscriptionsTypes> extends EntitiesManager<T, ManagedVrpcRemote, Parameters<UnsubFromRemoteFunction>> {
    private readonly mapper: VrpcRemoteMapper<SubscriptionData<T>>

    protected readonly internallyDroppedArgs: Parameters<UnsubFromRemoteFunction> = []

    constructor (mapper: VrpcRemoteMapper<SubscriptionData<T>>, handler: RemotesManager<T>['handler']) {
        super(handler)
        this.mapper = mapper
    }

    addRemote (remote: ManagedVrpcRemote): Promise<void> {
        return this.add(remote, () => new RemoteManager(remote, this.mapper, () => this.emitChange()))
    }
}
