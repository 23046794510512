import { ExtendedMap } from '../../../utils'
import {
    ConnectwareError,
    ConnectwareErrorType,
    type CybusRole,
    type CybusUser,
    type EditableCybusPermission,
    type PermissionedForm,
    Translation,
} from '../../../domain'

import { FormUsecase } from './Form'

export abstract class FormWithTemplatesUsecase<Form extends PermissionedForm> extends FormUsecase<Form> {
    protected static derivedTemplateValues: Pick<PermissionedForm, 'templateInput' | 'templates' | 'selectedTemplate'> = {
        templateInput: null,
        templates: null,
        selectedTemplate: null,
    }

    private getValidateNewPermissionError (permission: EditableCybusPermission): Translation | null {
        if (!permission.resource) {
            return Translation.RESOURCE_IS_MISSING
        }

        if (!permission.read && !permission.write) {
            return Translation.MISSING_READ_OR_WRITE_PERMISSION
        }

        const { permissions } = this.getCurrentForm()

        if (permissions.some((p) => p.context === permission.context && p.resource === permission.resource)) {
            return Translation.PERMISSION_ALREADY_EXISTS
        }

        return null
    }

    private async updateTemplates (): Promise<void> {
        await this.updateFormWithDelay(
            (a, b) => a.templateInput === b.templateInput,
            ({ templateInput }) =>
                templateInput === null
                    ? {}
                    : Promise.all([this.userService.fetchRoles(templateInput), this.userService.fetchUsers(templateInput)])
                          .then(([roles, users]) => [...roles, ...users])
                          .catch((e: ConnectwareError) => e)
                          .then((templates) => ({ templates } as Partial<Form>))
        )
    }

    private async updateTemplateInput (): Promise<void> {
        await this.updateFormWithDelay(
            (a, b) => a.selectedTemplate === b.selectedTemplate,
            () => ({ templateInput: null } as Partial<Form>)
        )
    }

    protected initializeForm (form: Form): void {
        super.initializeForm(form)

        void this.updateTemplates()
        void this.updateTemplateInput()
    }

    protected setPartialForm (form: Partial<Form>): void {
        super.setPartialForm(form)

        if ('templateInput' in form) {
            void this.updateTemplates()
        }

        if ('selectedTemplate' in form) {
            void this.updateTemplateInput()
        }
    }

    validateNewPermission (permission: EditableCybusPermission): void {
        const errorTranslation = this.getValidateNewPermissionError(permission)
        if (errorTranslation) {
            throw new ConnectwareError(ConnectwareErrorType.GENERAL_BUSINESS_RULE_INFRACTION, this.translationService.translate(errorTranslation, permission))
        }
    }

    applyTemplate (userOrRole: CybusUser | CybusRole): void {
        const permissions = 'username' in userOrRole ? userOrRole.allPermissions : userOrRole.permissions
        const { permissions: currentPermissions } = this.getCurrentForm()
        const mergedPermissions = new ExtendedMap<string, EditableCybusPermission>()
        // Add current permissions
        currentPermissions.forEach((p) => mergedPermissions.set(p.context + p.resource, p))
        // Add new permissions
        permissions.forEach((p) => {
            const { read, write, ...merged } = mergedPermissions.getDefault(p.context + p.resource, {
                context: p.context,
                resource: p.resource,
                id: null,
                read: false,
                write: false,
            })
            mergedPermissions.set(p.context + p.resource, { ...merged, read: read || p.read, write: write || p.write })
        })

        // Finally update the form
        this.setPartialForm({ permissions: mergedPermissions.toValuesArray(), ...FormWithTemplatesUsecase.derivedTemplateValues } as Partial<Form>)
    }

    selectTemplate (selectedTemplate: CybusUser | CybusRole | null): void {
        this.setPartialForm({ selectedTemplate } as Partial<Form>)
    }

    searchTemplates (templateInput: string): void {
        this.setPartialForm({ templateInput } as Partial<Form>)
    }
}
