import { type ConnectwareError, selectSandbox } from '../../../domain'

import { initialState } from '../..'
import { FilterSandboxUsecase } from '../RuleEngine'
import { BaseSandboxUsecase } from './Base'

export class StartSandboxUsecase extends BaseSandboxUsecase {
    invoke (): VoidFunction {
        /** Load endpoints again */
        this.configurationService
            .fetchRuleEngineEndpointsConfiguration()
            .then((endpoints) => this.setSandbox({ endpoints }))
            .catch((endpoints: ConnectwareError) => {
                // Move to initial source (away from endpoints)
                this.getUsecase(FilterSandboxUsecase).selectType(selectSandbox(initialState).type)
                this.setSandbox({ endpoints })
            })
        /** Reset to init state */
        return () => this.setSandbox(selectSandbox(initialState))
    }
}
