import React, { type FC } from 'react'

import { Box, type SxProps, type Theme, Typography } from '@mui/material'

import { isEnumOf } from '../../../utils'

import { Translation } from '../../../domain'

import { FormattedTranslation } from '../Internationalization'

type Props = Readonly<{ title: string | Translation, sx?: SxProps<Theme>, type?: 'text' | 'number' }>

const rootStyle: SxProps<Theme> = { display: 'flex', alignItems: 'center', typography: 'body2', mb: 1, '&:last-of-type': { marginBottom: 0 } }
const titleStyle: SxProps<Theme> = { minWidth: 120, mr: 2, fontSize: 'body2.fontSize' }

export const Entry: FC<Props> = ({ title, sx = {}, children, type }) => (
    <Box sx={{ ...rootStyle, ...sx }}>
        <Typography sx={titleStyle}>{isEnumOf(Translation, title) ? <FormattedTranslation id={title} /> : title}</Typography>
        {type ? <Typography variant="body2">{children}</Typography> : children}
    </Box>
)
