import React, { type FC, useState } from 'react'

import { useIntervalEffect } from '../../Interval'

type Props = Readonly<{
    /**
     * In milliseconds
     */
    delay: number
}>

/**
 * This component allows for delayed rendering
 * Good for components that need a lot of re-rendering
 * This forces the render to happen at most, once every {param0.delay}ms
 *
 * @param props
 */
export const Delay: FC<Props> = ({ delay, children }) => {
    /**
     * Will hold the information to rendered with a delay
     */
    const [updatedChildren, updateChildren] = useState(() => children)

    /**
     * At the given refresh rate
     * Update the children
     */
    useIntervalEffect(() => {
        if (children !== updatedChildren) {
            updateChildren(children)
        }
    }, delay)

    return <>{updatedChildren}</>
}
