export const areEquals = <T>(a: T[], b: T[], config: { sort?: ((a: T, b: T) => number) | false, equals?: (a: T, b: T) => boolean } = {}): boolean => {
    if (a === b) {
        return true
    }

    const { sort, equals = (a: T, b: T) => a === b } = config

    if (sort !== false) {
        // Copy arrays before sorting
        a = [...a]
        b = [...b]

        a.sort(sort)
        b.sort(sort)
    }

    return a.length === b.length && a.every((a, k) => a === b[k] || equals(a, b[k] as T))
}
