import React, { type FC } from 'react'

import { type AppState, type CybusConnection, ResourceType, Translation } from '../../../domain'

import { useAppUsecases } from '../State'

import { DeleteResourceModal, DisableResourceModal, EnableResourceModal, ServiceModalBody } from '../Resources'

type Connection = Pick<CybusConnection, 'id' | 'name' | 'service'>

type Props = Readonly<{ connectionsSelector: (s: AppState, id: string[]) => Connection[] }>

export const EnableModal: FC<Props> = ({ connectionsSelector }) => {
    const { bulkConnectConnectionsUsecase } = useAppUsecases()

    return (
        <EnableResourceModal
            title={Translation.CONNECT_CONNECTIONS}
            subtitle={Translation.CONNECT_CONNECTIONS_SUBTITLE}
            confirm={Translation.CONNECT}
            resourceSelector={connectionsSelector}
            dimension={ResourceType.CONNECTION}
            onRenderLineContent={ServiceModalBody}
            onCancel={() => bulkConnectConnectionsUsecase.selectConnections(null)}
            onConfirm={() => bulkConnectConnectionsUsecase.connect()}
        />
    )
}

export const DisableModal: FC<Props> = ({ connectionsSelector }) => {
    const { bulkDisconnectConnectionsUsecase } = useAppUsecases()

    return (
        <DisableResourceModal
            title={Translation.DISCONNECT_CONNECTIONS}
            subtitle={Translation.DISCONNECT_CONNECTIONS_SUBTITLE}
            confirm={Translation.DISCONNECT}
            resourceSelector={connectionsSelector}
            dimension={ResourceType.CONNECTION}
            onRenderLineContent={ServiceModalBody}
            onCancel={() => bulkDisconnectConnectionsUsecase.selectConnections(null)}
            onConfirm={() => bulkDisconnectConnectionsUsecase.disconnect()}
        />
    )
}

export const DeleteModal: FC<Props & { onConfirmed: VoidFunction }> = ({ connectionsSelector, onConfirmed }) => {
    const { bulkDeleteConnectionsUsecase } = useAppUsecases()

    return (
        <DeleteResourceModal
            title={Translation.DELETE_CONNECTIONS}
            subtitle={Translation.DELETE_CONNECTIONS_SUBTITLE}
            confirm={Translation.DELETE}
            resourceSelector={connectionsSelector}
            dimension={ResourceType.CONNECTION}
            onRenderLineContent={ServiceModalBody}
            onCancel={() => bulkDeleteConnectionsUsecase.selectConnections(null)}
            onConfirm={async () => {
                await bulkDeleteConnectionsUsecase.delete()
                onConfirmed()
            }}
        />
    )
}
