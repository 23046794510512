import React, { type FC } from 'react'
import { Grid, type SxProps, type Theme, Typography } from '@mui/material'

import { Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'

const headerStyle: SxProps<Theme> = { pt: 1, pb: 2 }

export const Header: FC = () => {
    return (
        <Grid sx={headerStyle} container>
            <Grid item xs={2}>
                <Typography variant="subtitle1">
                    <FormattedTranslation id={Translation.TIME} />
                </Typography>
            </Grid>
            <Grid item xs={5}>
                <Typography variant="subtitle1">
                    <FormattedTranslation id={Translation.TOPIC} values={{ count: 1 }} />
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="subtitle1">
                    <FormattedTranslation id={Translation.DATA} />
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography variant="subtitle1">
                    <FormattedTranslation id={Translation.TYPE} />
                </Typography>
            </Grid>
        </Grid>
    )
}
