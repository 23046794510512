import { useEffect, useMemo } from 'react'

import { Droppable } from '../../../utils'

const useOnUnhooked = (): Droppable => {
    const droppable = useMemo(() => new Droppable(), [])

    useEffect(() => {
        /**
         * When unhooked, drop it
         */
        return () => droppable.drop()
    }, [])

    return droppable
}

export const useIfHookedCallback = (): ((callback: VoidFunction) => void) => {
    const droppable = useOnUnhooked()
    return (callback) => droppable.ifNotDropped(callback)
}
