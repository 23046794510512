import React, { type FC } from 'react'
import { Divider } from '@mui/material'

import { selectExplorerLatestTopicValues, Translation } from '../../../../domain'

import { useAppState } from '../../State'
import { Delay } from '../../common'

import { Section } from '../Section'
import { TableHead } from './Header'
import { TableLines } from './Lines'

const Content: FC = () => {
    const latest = useAppState(selectExplorerLatestTopicValues)

    return (
        <Delay delay={300}>
            <Divider />
            <TableHead />
            <TableLines latestValues={latest} />
        </Delay>
    )
}

export const MonitoredTopics: FC = () => (
    <Section id="monitoredTopics" title={Translation.MONITORED_TOPICS}>
        <Content />
    </Section>
)
