import type { ConnectwareError } from '../../../../domain'
import type {
    CountSubscriptionsTypes,
    ListSubscriptionsTypes,
    PageSubscription,
    PageSubscriptionsTypes,
    SingleSubscriptionsTypes,
    SubscriptionEventHandler,
    SubscriptionFilterArgs,
    SubscriptionListEventHandler,
    SubscriptionPageOptions,
    SubscriptionsService,
    TranslationService,
} from '../../../../application'

import { PageSubscriptionAdapter } from '../../../Connectware'

import type { VrpcRemoteManager } from '../../utils'
import { CountSubscriptionBuilder, ListSubscriptionBuilder, SingleSubscriptionBuilder } from './builders'

/**
 * @deprecated
 * @todo remove when possible
 */
export class VrpcSubscriptionsService implements SubscriptionsService {
    private readonly pageSubscriptionAdapter = new PageSubscriptionAdapter(this, this.translationService)

    constructor (private readonly remote: VrpcRemoteManager, private readonly translationService: TranslationService) {}

    subscribeToPage<T extends keyof PageSubscriptionsTypes> (
        eventName: T,
        options: SubscriptionFilterArgs & SubscriptionPageOptions<T>
    ): Promise<PageSubscription<T>> {
        return this.pageSubscriptionAdapter.subscribeToPage(eventName, options)
    }

    subscribeToAll<T extends keyof ListSubscriptionsTypes> (
        eventName: T,
        filter: SubscriptionFilterArgs,
        listener: SubscriptionListEventHandler<T>
    ): Promise<VoidFunction> {
        return new ListSubscriptionBuilder<T>(filter, eventName, this.remote).onChange(listener).onError(listener).start()
    }

    subscribeToOne<T extends keyof SingleSubscriptionsTypes> (eventName: T, id: string, listener: SubscriptionEventHandler<T>): Promise<VoidFunction> {
        return new SingleSubscriptionBuilder<T>(id, eventName, this.remote)
            .onChange(([instance]) => instance && listener(instance))
            .onError(listener)
            .start()
    }

    subscribeToCount<T extends keyof CountSubscriptionsTypes> (eventName: T, listener: (count: number | ConnectwareError) => void): Promise<VoidFunction> {
        return new CountSubscriptionBuilder<T>(eventName, this.remote).onChange(listener).onError(listener).start()
    }
}
