import { createNamedEqualityChecker } from '../../utils'

import { type StatusType } from '..'

export enum CybusDeviationType {
    RESOURCE = 'RESOURCE',
    STATE = 'STATE',
}

export type CybusDeviation = Readonly<{ type: CybusDeviationType, actual: StatusType, expected: StatusType, id: string, reason: string | null }>

export const areCybusDeviationsEqual = createNamedEqualityChecker<CybusDeviation>(
    { type: null, actual: null, expected: null, id: null, reason: null },
    'areCybusDeviationsEqual'
)
