import type { CybusService } from '../../../../../../domain'

import type { SubscriptionsTypes } from '../../../../../../application'

import type { ServiceProxy } from '../../../../proxies'
import { mapDeviatedServiceIds } from '../../../../../Connectware'

import { listenToProxy, type UnsubFromInstanceFunction, type VrpcInstanceMapper } from '../Handler'
import { BaseVrpcServiceProxyInstanceHandler } from './Base'

export class DeviationMapper implements VrpcInstanceMapper<ServiceProxy, CybusService['id']> {
    mapToDomain (service: ServiceProxy, id: string): Promise<Map<string, CybusService['id']>> {
        return service
            .getInfo()
            .then(({ isDeviated }) =>
                mapDeviatedServiceIds([{ id, isDeviated }]).reduce<Map<string, CybusService['id']>>((m, id) => m.set(id, id), new Map([]))
            )
    }

    onChange (proxy: ServiceProxy, listener: () => void): Promise<UnsubFromInstanceFunction> {
        /**
         * listen to deviation changes to update Notifications
         * listen to state changes to clear Notifications whenever a deviated Service gets disabled
         */
        return listenToProxy(proxy, ['deviation', 'state'], listener)
    }
}

export class VrpcServiceProxyInstanceToNotificationHandler extends BaseVrpcServiceProxyInstanceHandler<SubscriptionsTypes['deviations']> {
    readonly instanceMapper = new DeviationMapper()
}
