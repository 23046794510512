import { selectAuthenticatedToken } from '../../../domain'

import { Usecase } from '..'

export class FixVersionUsecase extends Usecase {
    invoke (): void {
        const token = selectAuthenticatedToken(this.getState())

        if (token !== null) {
            /**
             * Ignore authentication issues
             * As there might be some issues with the backends authentication
             */
            void this.authenticationService.flushAuthentication(token)

            /**
             * Log user out on the application
             */
            this.authenticationPersistenceService.flush()
        }

        /**
         * Reloading the page should do it
         */
        this.browserService.reload()
    }
}
