import React, { type FC } from 'react'
import { createExtendedState, type ExtendedStateManager } from 'react-extended-state'

import type { AppState } from '../../domain'

import type { Usecases } from '../../application'

const { Provider: UsecasesProvider, useExtendedState: useUsecasesExtendedState } = createExtendedState<Usecases>()
const { Provider: StateProvider, useExtendedState: useAppExtendedState } = createExtendedState<AppState>()

export type AppStateProps = {
    /**
     * Represents the state of the application as to be read by react (through `useAppState`)
     */
    readonly manager: ExtendedStateManager<AppState>

    /**
     * The usecases of the front end application, that can alter the state of the application
     */
    readonly usecases: Usecases
}

export const AppStateProvider: FC<AppStateProps> = ({ manager, usecases, children }) => (
    <StateProvider manager={manager}>
        <UsecasesProvider value={usecases}>{children}</UsecasesProvider>
    </StateProvider>
)

/**
 * To read the state of the application as a hook, use this
 */
export const useAppState = useAppExtendedState

/**
 * To call business actions to be executed on the application, use these
 */

export const useAppUsecases = (): Usecases => useUsecasesExtendedState((u) => u)
export const useAppUsecase = <N extends keyof Usecases,>(name: N): Usecases[N] => useUsecasesExtendedState((u) => u[name])
