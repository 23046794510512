import type { PickByValueExact } from 'utility-types'

import { Capability, type StatusType } from '../../../../../../domain'

import type { SubscriptionsTypes } from '../../../../../../application'

import { mapToStatusType } from '../../../../../Connectware'

import { type BaseStrategyRequestArgs, BaseSubscriptionStrategy } from '../Base'

type Config = Pick<BaseStrategyRequestArgs<never, never>, 'path' | 'method' | 'capability'>

abstract class StatusStrategy<K extends keyof PickByValueExact<SubscriptionsTypes, StatusType>> extends BaseSubscriptionStrategy<K> {
    protected abstract readonly config: Config

    retrieveOne (id: string): Promise<SubscriptionsTypes[K]> {
        return this.retrieve({ ...this.config, pathParams: [id], mapper: (data: string | null) => mapToStatusType(data), handleNotFound: true })
    }
}

export class MappingsStatusStrategy extends StatusStrategy<'mappingStatus'> {
    readonly requiredPermissions = [Capability.MAPPING_STATE_READ]

    protected readonly config: Config = { capability: Capability.MAPPING_STATE_READ, method: 'GET', path: '/api/mappings/+/state' }
}

export class NodesStatusStrategy extends StatusStrategy<'nodeStatus'> {
    readonly requiredPermissions = [Capability.NODE_STATE_READ]

    protected readonly config: Config = { capability: Capability.NODE_STATE_READ, method: 'GET', path: '/api/nodes/+/state' }
}

export class EndpointsStatusStrategy extends StatusStrategy<'endpointStatus'> {
    readonly requiredPermissions = [Capability.ENDPOINT_STATE_READ]

    protected readonly config: Config = { capability: Capability.ENDPOINT_STATE_READ, method: 'GET', path: '/api/endpoints/+/state' }
}
