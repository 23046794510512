import { type Primitive } from 'utility-types'

import { decodeFromBase64, encodeToBase64, type ReadonlyRecord } from '../../utils'

type RecursivePrimitive = Primitive | RecursivePrimitive[] | ReadonlyRecord<string, Primitive | RecursivePrimitive[]>

/**
 * This webstorage manipulation class ensures all values are:
 *  - Centrally typed
 *  - Properly serialized and deserialized
 */
export abstract class BaseWebStorage<Value, Fallback, Serializable extends RecursivePrimitive> {
    protected abstract readonly key: string

    protected abstract readonly fallbackValue: Fallback

    constructor (private readonly storage: Pick<Storage, 'getItem' | 'removeItem' | 'setItem'>, private readonly version: string) {}

    private isJsonWrapperValid (json: unknown): json is [string, unknown] {
        return Array.isArray(json) && json.length === 2 && json[0] === this.version
    }

    private getValue (): string | null {
        return this.storage.getItem(this.key)
    }

    private setValue (value: string): void {
        this.storage.setItem(this.key, value)
    }

    protected remove (): void {
        this.storage.removeItem(this.key)
    }

    /**
     * Just a converter
     */
    protected abstract toSerializable (value: Value): Serializable

    /**
     * Validate the given type is correct
     */
    protected abstract isValidSerializable (unknownValue: unknown): unknownValue is Serializable

    /**
     * Just a converter
     */
    protected abstract fromSerializable (serialized: Serializable): Value

    protected persistSerialize (value: Value): void {
        const stringfied = JSON.stringify([this.version, this.toSerializable(value)])
        const content = encodeToBase64(stringfied)
        this.setValue(content)
    }

    protected retrieveDeserialize (): Value | Fallback {
        let value: Value | Fallback = this.fallbackValue

        const raw = this.getValue()

        if (raw === null) {
            return value
        }

        try {
            const content = decodeFromBase64(raw)
            const parsed = JSON.parse(content) as unknown

            if (this.isJsonWrapperValid(parsed) && this.isValidSerializable(parsed[1])) {
                value = this.fromSerializable(parsed[1])
            }
        } catch {}

        return value
    }
}
