import { delay } from '../../../utils'
import {
    Capability,
    mapMfaRequiredAuthenticationState,
    mapMfaState,
    MfaSettingsState,
    type MfaSetupRequiredError,
    selectAuthenticationCapabilities,
    selectAuthenticationExpiresAt,
    selectAuthenticationToken,
    selectMfaRequiredAuthenticationToken,
} from '../../../domain'
import { EnableMfaUsecase } from '.'
import { MfaUsecase } from './Base'

export class EnforceMfaUsageUsecase extends MfaUsecase {
    async load ({ extras }: MfaSetupRequiredError): Promise<void> {
        this.setState({
            /** Mark mfa as disabled (as it currently is) */
            ...mapMfaState({ state: MfaSettingsState.DISABLED }),
            /** Load seperate authentication */
            ...mapMfaRequiredAuthenticationState(extras),
        })

        if (selectAuthenticationCapabilities(extras).includes(Capability.USE_MFA)) {
            /** Run first mfa enabling step if user can manage it */
            await this.getUsecase(EnableMfaUsecase).start()
        }

        /** If token runs out, it needs to be dealt with */
        delay(Math.max(selectAuthenticationExpiresAt(extras).getTime() - Date.now(), 0)).finally(() => {
            if (selectAuthenticationToken(extras) === selectMfaRequiredAuthenticationToken(this.getState())) {
                this.reset()
            }
        })
    }

    reset (): void {
        this.setState({ ...mapMfaRequiredAuthenticationState(null), ...mapMfaState(null) })
    }
}
