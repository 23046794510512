import React, { type FC } from 'react'
import { type MUIDataTableColumn } from 'mui-datatables'
import { TableBody, TableCell, TableRow } from '@mui/material'

import { CircularLoader } from '../Loaders'

export const LoadingTableBody: FC<Readonly<{ selectable: boolean, columns: MUIDataTableColumn[] }>> = ({ selectable, columns }) => {
    const columnsCount = columns.filter((c) => c.options && c.options.display !== 'false').length

    return (
        <TableBody data-testid="loading-table-body">
            <TableRow>
                <TableCell colSpan={selectable ? columnsCount + 1 : columnsCount}>
                    <CircularLoader />
                </TableCell>
            </TableRow>
        </TableBody>
    )
}
