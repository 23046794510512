import { useCallback, useState } from 'react'

import { type ConnectwareError } from '../../../domain'

import { useIfHookedCallback } from '../Droppable'

/**
 * This a helper hook to better control the execution of asynchronous callbacks
 *
 * @returns a tupple with the current lading status, the last response or null and the callback to start it
 * @todo use 'react-async-callback'
 * @deprecated
 */
export const useAsyncCallback = <R, P extends unknown[]>(
    asyncCallback: (...args: P) => Promise<R>,
    dep: ReadonlyArray<unknown> = []
): [execute: (...args: P) => void, isLoading: boolean, value: R | ConnectwareError | null] => {
    /**
     * Thi is here to make sure once things are unhooked they are no longer updated
     */
    const ifHooked = useIfHookedCallback()

    const [[isLoading, value], setState] = useState<[boolean, R | ConnectwareError | null]>([false, null])

    const callback = useCallback(
        (...args: P) => {
            if (isLoading) {
                return
            }

            /**
             * Mark that the execution has started
             */
            setState(([, lastValue]) => [true, lastValue])

            /**
             * Call the async flow
             */
            asyncCallback(...args)
                /**
                 * All good, update with the status and response
                 */
                .then((r) => ifHooked(() => setState([false, r])))

                /**
                 * Catch any errors and update the state
                 */
                .catch((e: ConnectwareError) => ifHooked(() => setState([false, e])))
        },
        [...dep, isLoading]
    )

    return [callback, isLoading, value]
}
