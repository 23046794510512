const searchCache: Record<string, Record<string, boolean>> = {}

/**
 * Searches in the given `content` string for the given `terms`
 *
 * @param cache optional, defaults to use
 */
export const search = (content: string, terms: string[]): boolean => {
    for (let i = 0; i < terms.length; i++) {
        const term = terms[i] as string

        /**
         * Attempts to get from cache
         */
        const hit = searchCache[term]?.[content]

        const wasHit = hit !== undefined

        /**
         * Check if matches
         */
        const matches = wasHit ? hit : content.includes(term)

        if (!wasHit) {
            /**
             * Stores on cache
             */
            searchCache[term] = { ...searchCache[term], [content]: matches }
        }

        if (matches) {
            return true
        }
    }

    return false
}
