import React, { type FC } from 'react'

import { Capability, type CybusDetailedMapping, CybusLogSource, Translation } from '../../../../domain'

import { Tab, Tabs } from '../../Tabs'
import Logs, { useAreLogsAvailable } from '../../Logs'

import { Entries } from './Entries'

export const Content: FC<Readonly<{ resource: CybusDetailedMapping }>> = ({ resource: { id, entries, status } }) => {
    const available = useAreLogsAvailable(CybusLogSource.MAPPING, id)

    return (
        <Tabs>
            <Tab title={Translation.MAPPING_LOGS} disabled={!available} requiredCapabilities={[Capability.LOGS_READ]}>
                <Logs id={id} type={CybusLogSource.MAPPING} />
            </Tab>
            <Tab title={Translation.ENTRY} requiredCapabilities={[Capability.TOPICS_SUBSCRIPTION]}>
                <Entries entries={entries} status={status} />
            </Tab>
        </Tabs>
    )
}
