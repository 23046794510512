import hasher from 'object-hash'

export class Cache<T> {
    private readonly cache = new Map<string, T>()

    get (fetcher: () => T, ...hashable: unknown[]): T {
        /** Get hash key */
        const hash = hasher(hashable)

        /** Use pre loaded or fetch */
        const cached = this.cache.has(hash) ? (this.cache.get(hash) as T) : fetcher()

        /** Store on cache */
        this.cache.set(hash, cached)

        return cached
    }
}
