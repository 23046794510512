import React, { type FC } from 'react'

import { type Intersection } from 'utility-types'

import { type CybusCoreContainer, type CybusServiceContainer, type StatusType, Translation } from '../../../domain'

import { Status } from '../common'
import { FormattedDateTime, FormattedTimePast, FormattedTranslation } from '../Internationalization'
import type { ResourcesTableColumns } from '../Resources'

const createRenderer = (Renderer: FC<{ timestamp: Date }>) => (timestamp: Date | null) =>
    timestamp ? <Renderer timestamp={timestamp} /> : <FormattedTranslation id={Translation.NOT_AVAILABLE} />
const lastUpdateRenderer = createRenderer(({ timestamp }) => <FormattedDateTime date={timestamp} format="datetime" />)
const createdRenderer = createRenderer(({ timestamp }) => <FormattedTimePast value={timestamp} />)

type BaseContainer = Intersection<CybusServiceContainer, CybusCoreContainer>
export const useContainerTableColums = (): ResourcesTableColumns<BaseContainer, BaseContainer> => {
    const customCellRender = (status: StatusType): JSX.Element => <Status status={status} />

    return {
        name: { label: Translation.NAME, sort: true },
        lastUpdate: { label: Translation.LAST_UPDATED, customCellRender: lastUpdateRenderer, sort: true },
        created: { label: Translation.CREATED_AT, customCellRender: createdRenderer, sort: true },
        status: { label: Translation.STATUS, customCellRender, sort: true },
    }
}
