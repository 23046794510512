import React, { type FC } from 'react'

import {
    canEndpointBeDisabled,
    canEndpointBeEnabled,
    Capability,
    createIsAuthenticatedWithCapabilitiesSelector,
    type CybusEndpoint,
    selectDisabableLoadedEndpoints,
    selectEnabableLoadedEndpoints,
    selectEndpointsPage,
    selectLoadedEndpoints,
    type StatusType,
    Translation,
} from '../../../domain'

import { useTranslator } from '../Internationalization'
import { RelativeRoutePathWithId } from '../routing'
import { Status, useTableClearSelection } from '../common'

import { RedirectingResourcesTable } from '../Resources'
import { DeleteModal, DisableModal, EnableModal } from './Bulk'

const Modals: FC = () => {
    const clearSelection = useTableClearSelection()

    return (
        <>
            <DeleteModal endpointsSelector={selectLoadedEndpoints} onConfirmed={clearSelection} />
            <EnableModal endpointsSelector={selectEnabableLoadedEndpoints} />
            <DisableModal endpointsSelector={selectDisabableLoadedEndpoints} />
        </>
    )
}

const selectCanManageEndpoints = createIsAuthenticatedWithCapabilitiesSelector(Capability.ENDPOINTS_MANAGE)

export const Table: FC<Readonly<{ serviceId: string } | { connectionId: string } | Record<string, never>>> = (props) => {
    const connection = 'connectionId' in props ? props.connectionId : null
    const service = 'serviceId' in props ? props.serviceId : null

    const translator = useTranslator()

    const customCellRender = (status: StatusType): JSX.Element => <Status status={status} />

    return (
        <RedirectingResourcesTable<CybusEndpoint, CybusEndpoint, 'bulkDeleteEndpointsUsecase' | 'bulkEnableEndpointsUsecase' | 'bulkDisableEndpointsUsecase'>
            subscriptionUsecase="manageEndpointsUsecase"
            filter={connection ? { connection } : service ? { service } : undefined}
            data={selectEndpointsPage}
            hasManagementCapabilitiesSelector={selectCanManageEndpoints}
            dataTableMapper={(endpoints) => endpoints}
            columns={{
                service: { label: Translation.SERVICE_ID, display: !service, sort: true },
                name: { label: Translation.NAME, sort: true },
                connection: { label: translator.formatTranslation(Translation.CONNECTION_ID, { count: 1 }), display: !connection, sort: true },
                protocol: { label: translator.formatTranslation(Translation.PROTOCOL, { count: 1 }), sort: true },
                operation: { label: translator.formatTranslation(Translation.OPERATION, { count: 1 }), sort: true },
                status: { label: Translation.STATUS, customCellRender, sort: true },
            }}
            short={Boolean(service || connection)}
            redirectOnRowclick={RelativeRoutePathWithId.ENDPOINT}
            onSelect={{
                DELETE: { usecase: 'bulkDeleteEndpointsUsecase' },
                DISABLE: { usecase: 'bulkDisableEndpointsUsecase', filter: canEndpointBeDisabled },
                ENABLE: { usecase: 'bulkEnableEndpointsUsecase', filter: canEndpointBeEnabled },
                resource: 'id',
                usecaseMethod: 'selectEndpoints',
            }}
            translations={{ resourceName: Translation.ENDPOINT, noResultsOrEmptyTable: Translation.NO_ENDPOINTS_AVAILABLE }}
        >
            <Modals />
        </RedirectingResourcesTable>
    )
}
