import type { Intersection, Mutable } from 'utility-types'

import type { CybusConnection, CybusDetailedConnection } from '../../../domain'

import { type CommissioningDataResourceProperties, type ConnectionProxyParams, mapResourceNames, mapToStatusType, safelyMapPlainProperties } from '..'

type NormalizedConnectionProps = Pick<
    CybusDetailedConnection,
    'host' | 'port' | 'deviceAddress' | 'deviceInstance' | 'ipAddress' | 'cncType' | 'agent' | 'className' | 'device' | 'localInterface' | 'brokers'
>

export const mapConnectionProperties = (properties: CommissioningDataResourceProperties<'Cybus::Connection'>): NormalizedConnectionProps => {
    const props: Mutable<NormalizedConnectionProps> = {
        host: null,
        port: null,
        deviceAddress: null,
        deviceInstance: null,
        ipAddress: null,
        agent: null,
        className: null,
        localInterface: null,
        cncType: null,
        device: null,
        brokers: null,
    }

    switch (properties.protocol) {
        case 'Bacnet':
            props.deviceAddress = properties.connection.deviceAddress || null
            props.deviceInstance = String(properties.connection.deviceInstance)
            break
        case 'Heidenhain':
            props.ipAddress = properties.connection.ipAddress || null
            props.cncType = properties.connection.cncType || null
            break
        case 'GenericVrpc':
            props.agent = properties.connection.vrpc.agent || null
            props.className = properties.connection.vrpc.className || null
            break
        case 'Profinet':
            props.localInterface = properties.connection.localInterface || null
            break
        case 'Kafka':
            props.brokers = properties.connection.brokers
            break
        case 'Ads':
            props.host = properties.connection.host || null
            props.port = typeof properties.connection.routerTcpPort === 'number' ? String(properties.connection.routerTcpPort) : null
            break
        default:
            props.host = typeof properties.connection.host === 'string' ? properties.connection.host : null
            props.port =
                typeof properties.connection.port === 'string' || typeof properties.connection.port === 'number' ? String(properties.connection.port) : null
    }

    return props
}

type BaseConnection = Intersection<CybusDetailedConnection, CybusConnection>

const mapBaseConnection = ({ id, currentState, ...properties }: ConnectionProxyParams): BaseConnection => {
    const [service, name] = mapResourceNames(id)

    return {
        id,
        name,
        service,
        status: mapToStatusType(currentState),
        ...mapConnectionProperties(properties),
    }
}

export const mapDetailedConnection = (params: ConnectionProxyParams): CybusDetailedConnection => ({
    ...mapBaseConnection(params),
    extraConfiguration: safelyMapPlainProperties(params.connection),
})

export const mapConnection = (params: ConnectionProxyParams): CybusConnection => ({ ...mapBaseConnection(params), protocol: params.protocol })

export const VALID_CONNECTION_INSTANCE_PATTERN = /^(?!__internal__).*$/i
