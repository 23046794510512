import React, { type FC, useCallback } from 'react'
import { useSnackbar } from 'notistack'
import { Grid, Link, type SxProps, type Theme, Typography } from '@mui/material'

import {
    canServiceBeDisabled,
    Capability,
    createIsAuthenticatedWithCapabilitiesSelector,
    type CybusService,
    selectDeletableLoadedServices,
    selectDisablableLoadedServices,
    selectServicesPage,
    type StatusType,
    Translation,
} from '../../../domain'

import { Status, useTableClearSelection } from '../common'
import { AbsoluteRouteOnlyPath, RelativeRoutePathWithId, useHasCapabilities, usePermissionedLink, useRouting } from '../routing'
import { createFormatter, FormattedTranslation, useTranslator } from '../Internationalization'
import { useAppState, useAppUsecase } from '../State'

import { RedirectingResourcesTable } from '../Resources'
import { AddOrUpdateModal } from './AddOrUpdate'
import { BulkDeletionModal, BulkDisablingModal } from './Bulk'
import { ServiceLinks } from './Links'
import { ServiceLogsButton, UploadServiceButton } from './Buttons'

const SNACKBAR_TIMEOUT = 3000

const Modals: FC = () => {
    const clearSelection = useTableClearSelection()
    const { enqueueSnackbar } = useSnackbar()
    const PermissionedLink = usePermissionedLink()

    return (
        <>
            <BulkDeletionModal resourceSelector={selectDeletableLoadedServices} onConfirmed={clearSelection} />
            <BulkDisablingModal resourceSelector={selectDisablableLoadedServices} />

            <AddOrUpdateModal
                mode="CREATE"
                onConfirmed={(id) =>
                    enqueueSnackbar(
                        <div key={id} data-testid="installed-service-item">
                            <Grid container justifyContent="space-between" spacing={6} alignItems="baseline">
                                <Grid data-testid="installed-service-name" item>
                                    {id}
                                </Grid>
                                <Grid data-testid="installed-service-link" item>
                                    <PermissionedLink color="inherit" path={RelativeRoutePathWithId.SERVICE} id={id}>
                                        <FormattedTranslation id={Translation.MORE} />
                                    </PermissionedLink>
                                </Grid>
                            </Grid>
                        </div>,
                        { key: id, variant: 'info', autoHideDuration: SNACKBAR_TIMEOUT }
                    )
                }
            />
        </>
    )
}

const selectCanInstallServices = createIsAuthenticatedWithCapabilitiesSelector(Capability.SERVICES_CREATE_OR_UPDATE)
const selectCanManageServices = createIsAuthenticatedWithCapabilitiesSelector(Capability.SERVICES_MANAGE)

const buttonStyle: SxProps<Theme> = { cursor: 'pointer' }

const NoServicesMessage: FC = () => {
    const canInstallServices = useAppState(selectCanInstallServices)
    const addServiceUsecase = useAppUsecase('addServiceUsecase')
    const link = createFormatter(Link, { sx: buttonStyle, onClick: () => addServiceUsecase.startCreation() })

    return <FormattedTranslation id={Translation.NO_SERVICES_INSTALLED} values={{ canInstallServices, link }} />
}

const HeaderActions: FC = () => {
    const addServiceUsecase = useAppUsecase('addServiceUsecase')
    const canInstallServices = useAppState(selectCanInstallServices)
    const hasCapabilities = useHasCapabilities()
    const routing = useRouting()

    const onUploadService = useCallback(() => addServiceUsecase.startCreation(), [addServiceUsecase])

    const redirectToServiceLogsPage: VoidFunction = () => routing.redirect(AbsoluteRouteOnlyPath.SERVICE_LOGS)

    return (
        <>
            <UploadServiceButton onClick={onUploadService} disabled={!canInstallServices} />
            <ServiceLogsButton onClick={redirectToServiceLogsPage} disabled={!hasCapabilities(AbsoluteRouteOnlyPath.SERVICE_LOGS)} />
        </>
    )
}

export const Table: FC = () => {
    const translation = useTranslator()
    const customCellRender = (status: StatusType): JSX.Element => <Status status={status} />

    return (
        <RedirectingResourcesTable<CybusService, CybusService, 'bulkDeleteServicesUsecase' | 'bulkDisableServicesUsecase'>
            subscriptionUsecase="manageServicesUsecase"
            data={selectServicesPage}
            hasManagementCapabilitiesSelector={selectCanManageServices}
            dataTableMapper={(r) => r}
            columns={{
                id: { label: Translation.SERVICE_ID, sort: true },
                name: { label: Translation.NAME, sort: true },
                version: { label: Translation.VERSION, sort: true },
                links: {
                    label: translation.formatTranslation(Translation.LINK, { count: 2 }),
                    customCellRender: (links) => (links.length ? <ServiceLinks links={links} /> : <Typography>-</Typography>),
                },
                status: { label: Translation.STATUS, customCellRender, sort: true },
                deviation: { label: Translation.DEVIATION, customCellRender, sort: true },
            }}
            redirectOnRowclick={RelativeRoutePathWithId.SERVICE}
            actions={<HeaderActions />}
            onSelect={{
                DELETE: { usecase: 'bulkDeleteServicesUsecase' },
                DISABLE: { usecase: 'bulkDisableServicesUsecase', filter: canServiceBeDisabled },
                resource: 'id',
                usecaseMethod: 'selectServices',
            }}
            title
            translations={{
                resourceName: Translation.SERVICE,
                noResultsOrEmptyTable: <NoServicesMessage />,
                infoTitle: Translation.SERVICES_DETAILS_DOCUMENTATION_TITLE,
                infoBody: Translation.SERVICES_DETAILS_DOCUMENTATION_BODY,
            }}
        >
            <Modals />
        </RedirectingResourcesTable>
    )
}
