import type { ValuesType } from 'utility-types'

import { entries } from '../../../../utils'

import { type CybusDeviation, CybusDeviationType, type CybusService } from '../../../../domain'

import { type CybusServiceProxyInfo, type CybusServiceProxyParams, mapToStatusType } from '../..'

const deviationKindsMap: Record<ValuesType<CybusServiceProxyParams['deviation']>['kind'], CybusDeviationType> = {
    state: CybusDeviationType.STATE,
    resource: CybusDeviationType.RESOURCE,
}

export const mapDeviations = (deviation: CybusServiceProxyParams['deviation']): CybusDeviation[] =>
    entries(deviation).map(([id, { kind, expected, actual, reason }]) => ({
        id,
        type: deviationKindsMap[kind],
        expected: mapToStatusType(expected),
        actual: mapToStatusType(actual),
        reason: reason || null,
    }))

export const mapDeviatedServiceIds = (params: Pick<CybusServiceProxyInfo, 'id' | 'isDeviated'>[]): CybusService['id'][] => {
    return params.reduce((total: string[], serviceId) => (serviceId.isDeviated ? [...total, serviceId.id] : total), [])
}
