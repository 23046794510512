import React, { type FC } from 'react'

import { Capability, createIsAuthenticatedWithCapabilitiesSelector } from '../../../domain'

import { useAppState } from '../State'
import { MfaSettings } from './MFA'
import { Sections } from './Section'
import { ChangePasswordSettings } from './ChangePassword'

const selectCanManageMfa = createIsAuthenticatedWithCapabilitiesSelector(Capability.USE_MFA)

export const LoginSettings: FC = () => {
    const canUseMfa = useAppState(selectCanManageMfa)

    return canUseMfa ? (
        <Sections>
            <ChangePasswordSettings />
            <MfaSettings />
        </Sections>
    ) : (
        <ChangePasswordSettings />
    )
}
