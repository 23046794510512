import React, { type FC } from 'react'
import { createExtendedState } from 'react-extended-state'

import { type Translation } from '../../../domain'

type State = {
    /**
     * If the left side menus are opened (Controlled by their translation name key)
     */
    readonly section: Translation | null
}

const { Provider, useExtendedState, useExtendedStateDispatcher } = createExtendedState<State>()

export const NavigationContext: FC = ({ children }) => <Provider value={{ section: null }}>{children}</Provider>

/**
 * Section navigation toggling
 */
export const isNavigationSectionOpen = (translation: Translation): boolean => useExtendedState((s) => s.section === translation)

export const useToggleNavigationSection = (translation: Translation): (() => void) => {
    const dispatch = useExtendedStateDispatcher()
    return () => dispatch(({ section }) => ({ section: section === translation ? null : translation }))
}
