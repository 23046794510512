import React, { type FC } from 'react'

import { type AppState, type CybusMapping, ResourceType, Translation } from '../../../domain'

import { useAppUsecases } from '../State'

import { DeleteResourceModal, DisableResourceModal, EnableResourceModal, ServiceModalBody } from '../Resources'

type Mapping = Pick<CybusMapping, 'id' | 'name' | 'service'>

type Props = Readonly<{ mappingSelector: (s: AppState, id: string[]) => Mapping[] }>

export const EnableModal: FC<Props> = ({ mappingSelector }) => {
    const { bulkEnableMappingsUsecase } = useAppUsecases()

    return (
        <EnableResourceModal
            title={Translation.ENABLE_MAPPINGS}
            subtitle={Translation.ENABLE_MAPPINGS_SUBTITLE}
            confirm={Translation.ENABLE}
            resourceSelector={mappingSelector}
            dimension={ResourceType.MAPPING}
            onRenderLineContent={ServiceModalBody}
            onCancel={() => bulkEnableMappingsUsecase.selectMappings(null)}
            onConfirm={() => bulkEnableMappingsUsecase.enable()}
        />
    )
}

export const DisableModal: FC<Props> = ({ mappingSelector }) => {
    const { bulkDisableMappingsUsecase } = useAppUsecases()

    return (
        <DisableResourceModal
            title={Translation.DISABLE_MAPPINGS}
            subtitle={Translation.DISABLE_MAPPINGS_SUBTITLE}
            confirm={Translation.DISABLE}
            resourceSelector={mappingSelector}
            dimension={ResourceType.MAPPING}
            onRenderLineContent={ServiceModalBody}
            onCancel={() => bulkDisableMappingsUsecase.selectMappings(null)}
            onConfirm={() => bulkDisableMappingsUsecase.disable()}
        />
    )
}

export const DeleteModal: FC<Props & { onConfirmed: VoidFunction }> = ({ mappingSelector, onConfirmed }) => {
    const { bulkDeleteMappingsUsecase } = useAppUsecases()

    return (
        <DeleteResourceModal
            title={Translation.DELETE_MAPPINGS}
            subtitle={Translation.DELETE_MAPPINGS_SUBTITLE}
            confirm={Translation.DELETE}
            resourceSelector={mappingSelector}
            dimension={ResourceType.MAPPING}
            onRenderLineContent={ServiceModalBody}
            onCancel={() => bulkDeleteMappingsUsecase.selectMappings(null)}
            onConfirm={async () => {
                await bulkDeleteMappingsUsecase.delete()
                onConfirmed()
            }}
        />
    )
}
