import type { CybusConfiguredResources, CybusNamedPermissionConfiguration, CybusPermissionConfiguration } from '../../../../domain'

import { type CybusRolePermissionConfigurationResponse, mapContextToPermissionContext, mapResponseToOperations } from '../..'

const mapPermission = ({ resource, context, operation }: CybusRolePermissionConfigurationResponse): CybusPermissionConfiguration => ({
    resource,
    context: mapContextToPermissionContext(context),
    ...mapResponseToOperations(operation),
})

const ROLES_FILTER = /[^${]*\$\{\s*(\S*)\s*\}[^${]*/

export class UserAndRolesConfigurationMapper {
    private readonly indexedRoles = new Map<string, CybusPermissionConfiguration[]>()

    private readonly indexedUsers = new Map<string, [string[], CybusPermissionConfiguration[]]>()

    get flush (): Pick<CybusConfiguredResources, 'users' | 'roles'> {
        const users: CybusNamedPermissionConfiguration[] = []

        this.indexedUsers.forEach(([roles, userPermissions], name) => {
            roles.forEach((role) => {
                const extractedName = ROLES_FILTER.exec(role)?.[1]
                const roles = extractedName && this.indexedRoles.get(extractedName)

                if (roles) {
                    /**
                     * Add role permissions to user
                     */
                    userPermissions.push(...roles)
                }

                if (extractedName) {
                    /**
                     * Delete role
                     */
                    this.indexedRoles.delete(extractedName)
                }
            })

            users.push({ name, permissions: userPermissions })
        })

        const output = {
            users,
            roles: Array.from(this.indexedRoles).map<CybusNamedPermissionConfiguration>(([name, permissions]) => ({ permissions, name })),
        }

        /**
         * This makes sure the indexed information is cleared out
         */
        this.indexedRoles.clear()
        this.indexedUsers.clear()

        return output
    }

    addRole (name: string, permissions: CybusRolePermissionConfigurationResponse[]): void {
        this.indexedRoles.set(name, permissions.map(mapPermission))
    }

    addUser (name: string, roles: string[] = [], permissions: CybusRolePermissionConfigurationResponse[] = []): void {
        this.indexedUsers.set(name, [roles, permissions.map(mapPermission)])
    }
}
