import type { CybusNode, CybusServer, SortableColumn } from '../../../domain'

import { ResourcePageSubscriptionUsecase, ResourceSingleSubscriptionUsecase } from '../Resources'

export class ManageServersUsecase extends ResourcePageSubscriptionUsecase<'servers'> {
    protected readonly pageName = 'servers'

    protected readonly pageAddress = 'serversPage'

    protected readonly initialSortColumn: SortableColumn<CybusServer> = 'service'
}

export class ManageServerUsecase extends ResourceSingleSubscriptionUsecase<'server'> {
    protected readonly singularName = 'server'

    protected readonly singleAddress = 'server'
}

export class ManageNodesUsecase extends ResourcePageSubscriptionUsecase<'nodes'> {
    protected readonly pageName = 'nodes'

    protected readonly pageAddress = 'nodesPage'

    protected initialSortColumn: SortableColumn<CybusNode> = 'service'
}
