import { CertificatesUsecase } from './Base'

export class AddCertificateUsecase extends CertificatesUsecase {
    async add (content: string): Promise<void> {
        const certificate = await this.certificatesService.upload(content)

        /** Add certificates to state */
        this.updateCertificates((certificates) => [...certificates, certificate])
    }
}
