import type { NonEmptyArray } from '../../../utils'
import { type Capability, createIsAuthenticatedWithCapabilitiesSelector } from '../../../domain'
import { useAppState } from '../State'

/**
 * @returns if the button should be enabled giving the user permissions and parameters
 */
export const useEnabled = (disabled = false, capabilities?: NonEmptyArray<Capability>): boolean =>
    useAppState(
        (s) => {
            if (!capabilities || disabled) {
                /** It is disabled or has no capabilities, no need to check */
                return !disabled
            }

            /** Check with permissions */
            return createIsAuthenticatedWithCapabilitiesSelector(...capabilities)(s)
        },
        // Warning: This dep list relies on the capabilities not changing order
        [capabilities && capabilities.join('_'), disabled]
    )
