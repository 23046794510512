import React, { type FC } from 'react'
import { Box, Grid, Link, type SxProps, type Theme, Typography } from '@mui/material'

import { entries, isObjectEmpty } from '../../../../utils'
import {
    type CybusPermission,
    type CybusPermissionOperations,
    selectUsersManagementTransfering,
    selectUsersManagementViewPermission,
    Translation,
} from '../../../../domain'

import { FormattedList, FormattedTranslation } from '../../Internationalization'
import { useAppState, useAppUsecase, useAppUsecases } from '../../State'
import { useAsyncCallback } from '../../Callback'

import { SmallCopy } from '../../Copy'

import { useTranslatedOperationPermission } from '../Hooks'
import { EntryInputLabel, GridFormEntry, ViewDialog } from '../Modal'

import { useContextLabel, useContextResourceTranslation } from './Hooks'

const resourceWrapperStyle: SxProps<Theme> = { display: 'flex', '& > *': { my: 'auto', mx: 0 } }
const resourceStyle: SxProps<Theme> = {
    typography: 'body2',
    background: 'grey.100',
    borderRadius: 3,
    color: 'grey.800',
    py: 0.25,
    px: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}
const copyWrapperStyle: SxProps<Theme> = { display: 'flex' }
const copyStyle: SxProps<Theme> = { ml: 'auto' }

const OperationsTable: FC<{ data: Record<string, CybusPermissionOperations>, onClick: (name: string) => Promise<void> }> = ({ data, onClick }) => {
    const translate = useTranslatedOperationPermission()
    const isTransfering = useAppState(selectUsersManagementTransfering)
    const [onAsyncClick] = useAsyncCallback((name: string) => onClick(name), [onClick])

    const grouped = entries(data).reduce<Record<string, string[]>>((r, [name, { read, write }]) => {
        const label = translate({ read, write })
        return { ...r, [label]: [...(r[label] || []), name] }
    }, {})

    return (
        <Grid container alignContent="center" alignItems="center">
            {entries(grouped).map(([label, values], k) => (
                <GridFormEntry
                    key={k}
                    label={
                        <Typography variant="body2">
                            {'- '}
                            {label}
                        </Typography>
                    }
                >
                    <Typography variant="body2">
                        <FormattedList
                            value={
                                isTransfering
                                    ? values
                                    : values.sort().map((name, k) => (
                                          <Link key={k} onClick={() => onAsyncClick(name)}>
                                              {name}
                                          </Link>
                                      ))
                            }
                            style="narrow"
                        />
                    </Typography>
                </GridFormEntry>
            ))}
        </Grid>
    )
}

const ViewModalContent: FC<Readonly<{ permission: CybusPermission }>> = ({ permission: info }) => {
    const { editUserUsecase, editRoleUsecase } = useAppUsecases()
    const translateContext = useContextLabel()
    const translateContextResource = useContextResourceTranslation()

    return (
        <Grid container spacing={2} alignContent="center" alignItems="center">
            <GridFormEntry
                label={<EntryInputLabel label={translateContextResource(info.context)} shrink />}
                endAdornment={
                    <Box sx={copyWrapperStyle}>
                        <SmallCopy sx={copyStyle}>{info.resource}</SmallCopy>
                    </Box>
                }
            >
                <Box sx={resourceWrapperStyle}>
                    <Box component={'span'} sx={resourceStyle}>
                        {info.resource}
                    </Box>
                </Box>
            </GridFormEntry>
            <GridFormEntry label={<EntryInputLabel label={Translation.CONTEXT} shrink />}>
                <Typography variant="body2">{translateContext(info.context)}</Typography>
            </GridFormEntry>

            {!isObjectEmpty(info.users) && (
                <>
                    <GridFormEntry label={<EntryInputLabel label={Translation.USER} shrink values={{ count: 2 }} />} />
                    <Grid data-testid="users" item xs={12}>
                        <OperationsTable data={info.users} onClick={(name) => editUserUsecase.load(name)} />
                    </Grid>
                </>
            )}

            {!isObjectEmpty(info.roles) && (
                <>
                    <GridFormEntry label={<EntryInputLabel label={Translation.ROLE} shrink values={{ count: 2 }} />} />
                    <Grid data-testid="roles" item xs={12}>
                        <OperationsTable data={info.roles} onClick={(name) => editRoleUsecase.load(name)} />
                    </Grid>
                </>
            )}
        </Grid>
    )
}

export const PermissionViewModal: FC = () => {
    const viewPermissionUsecase = useAppUsecase('viewPermissionUsecase')
    const permission = useAppState(selectUsersManagementViewPermission)
    const isTransfering = useAppState(selectUsersManagementTransfering)

    return (
        permission && (
            <ViewDialog
                maxWidth="sm"
                title={<FormattedTranslation id={Translation.PERMISSION} values={{ count: 1 }} />}
                onClose={!isTransfering ? () => viewPermissionUsecase.close() : undefined}
                loading={isTransfering ? true : null}
            >
                <ViewModalContent permission={permission} />
            </ViewDialog>
        )
    )
}
