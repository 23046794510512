import React, { createRef, type FC, type ReactNode, useMemo } from 'react'
import { Box, Button, type SxProps, type Theme, Tooltip } from '@mui/material'

import { CloudUpload, Sync } from '@mui/icons-material'

import { ConnectwareError, Translation } from '../../../domain'

import { createClickerHandler, readFirstFileAsText } from '../utils'
import { useAppUsecases } from '../State'
import { useAsyncCallback } from '../Callback'
import { FormattedTranslation, useTranslator } from '../Internationalization'

const hiddenStyle: SxProps<Theme> = { display: 'none' }
const buttonStyle: SxProps<Theme> = { ml: 3 }

type Props = Readonly<{
    tooltip: Translation
    disabled?: boolean
    Hidden?: FC<{ setLoading: (loading: Promise<void>) => void }>
    onClick?: () => Promise<void> | void
    startIcon: ReactNode
}>

const LicenseActionButton: FC<Props> = ({ tooltip, Hidden, disabled, onClick, startIcon, children }) => {
    const translator = useTranslator()

    const [load, loading, result] = useAsyncCallback((promise: Promise<void>) => promise, [])

    return (
        <Tooltip title={!loading && ConnectwareError.is(result) ? result.message : translator.formatTranslation(tooltip)}>
            <Button
                variant="text"
                color={ConnectwareError.is(result) ? 'error' : 'primary'}
                startIcon={startIcon}
                disabled={disabled || loading}
                onClick={() => {
                    const result = onClick?.()
                    if (result instanceof Promise) {
                        load(result)
                    }
                }}
                sx={buttonStyle}
            >
                {Hidden && (
                    <Box sx={hiddenStyle}>
                        <Hidden setLoading={load} />
                    </Box>
                )}
                {children}
            </Button>
        </Tooltip>
    )
}

export const UploadLicenseFileButton: FC<Pick<Props, 'disabled'>> = ({ disabled }) => {
    const { uploadLicenseFileUsecase } = useAppUsecases()
    const supportedFileTypes = useMemo(
        () =>
            uploadLicenseFileUsecase
                .getSupportedLicenseFilesExtensions()
                .map((t) => `.${t}`)
                .join(','),
        [uploadLicenseFileUsecase]
    )
    const ref = createRef<HTMLInputElement>()

    return (
        <LicenseActionButton
            tooltip={Translation.UPLOAD_LICENSE_FILE}
            onClick={createClickerHandler(ref)}
            startIcon={<CloudUpload />}
            disabled={disabled}
            /**
             * This button relies on a hidden file upload field
             * Whenever the user clicks on the button, the file input is triggered
             *
             * If there is a file upload, then attempt loading
             */
            Hidden={({ setLoading }) => (
                <input
                    type="file"
                    ref={ref}
                    accept={supportedFileTypes}
                    onChange={({ target }) =>
                        setLoading(
                            /** Read the selected file and upload its content */
                            readFirstFileAsText(target.files).then(async (content) => {
                                if (content) {
                                    await uploadLicenseFileUsecase.upload(content)
                                }
                            })
                        )
                    }
                />
            )}
        >
            <FormattedTranslation id={Translation.UPLOAD_LICENSE_FILE} />
        </LicenseActionButton>
    )
}

export const RefreshLicenseButton: FC<Pick<Props, 'disabled'>> = ({ disabled }) => {
    const { refreshLicenseUsecase } = useAppUsecases()
    return (
        <LicenseActionButton
            tooltip={Translation.REFRESH_LICENSE_FILE_FROM_PORTAL}
            onClick={() => refreshLicenseUsecase.refresh()}
            startIcon={<Sync />}
            disabled={disabled}
        >
            <FormattedTranslation id={Translation.REFRESH_LICENSE} />
        </LicenseActionButton>
    )
}
