import { executeOnce } from '../../../../../../utils'

import { areCybusConnectionsEquals, Capability, type CybusConnection } from '../../../../../../domain'
import type { SubscriptionFilterArgs } from '../../../../../../application'

import { type ConnectionProxyParams, mapConnection, VALID_CONNECTION_INSTANCE_PATTERN } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

export class ConnectionsStrategy extends BaseSubscriptionStrategy<'connections'> {
    readonly requiredPermissions = [Capability.CONNECTIONS_READ]

    protected readonly supportedFilters: (keyof SubscriptionFilterArgs)[] = ['service']

    protected areEquals = areCybusConnectionsEquals

    retrieveAll ({ service }: SubscriptionFilterArgs): Promise<CybusConnection[]> {
        return this.retrieve({
            capability: Capability.CONNECTIONS_READ,
            method: 'GET',
            path: '/api/connections',
            mapper: (data: ConnectionProxyParams[]) =>
                data.reduce<CybusConnection[]>((r, c) => {
                    const mapOnce = executeOnce(() => mapConnection(c))
                    return !VALID_CONNECTION_INSTANCE_PATTERN.test(c.id) || (service && service !== mapOnce().service) ? r : [...r, mapOnce()]
                }, []),
        })
    }
}
