import React, { type FC } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Box, type SxProps, type Theme } from '@mui/material'

import { ConnectwareError, ConnectwareErrorType } from '../../../domain'

import { ErrorMessage } from '.'

const wrapperStyle: SxProps<Theme> = { p: 2 }

/** Caught react Errors need to be converted to the ConnectwareError to be displayed */
const mapReactError = (error: Error): ConnectwareError => {
    if (ConnectwareError.is(error)) {
        return error
    }

    const newError = new ConnectwareError(ConnectwareErrorType.UNEXPECTED, error.message)
    newError.stack = error.stack

    return newError
}

/**
 * A component that nicely displays a react only exception
 */
export const ConnectwareErrorBoundary: FC = ({ children }) => (
    <ErrorBoundary
        FallbackComponent={({ error }) => (
            <Box sx={wrapperStyle}>
                <ErrorMessage data-testid="boundary-error" error={mapReactError(error)} stack extras="section" />
            </Box>
        )}
    >
        {children}
    </ErrorBoundary>
)
