import { createPageSelector, type Page } from '..'
import {
    canContainerBeDeleted,
    canContainerBeRestarted,
    canContainerBeStarted,
    canContainerBeStopped,
    type CybusCoreContainer,
    type CybusDetailedCoreContainer,
    type CybusDetailedServiceContainer,
    type CybusServiceContainer,
} from '.'
import { createLoadedSelector, type Loadable } from '../Loadable'

export type ContainersAppState = Readonly<{
    serviceContainer: Loadable<CybusDetailedServiceContainer>
    serviceContainersPage: Page<CybusServiceContainer>

    coreContainer: Loadable<CybusDetailedCoreContainer>
    coreContainersPage: Page<CybusCoreContainer>
}>

export const selectServiceContainer = (s: ContainersAppState): ContainersAppState['serviceContainer'] => s.serviceContainer
export const selectServiceContainersPage = (s: ContainersAppState): ContainersAppState['serviceContainersPage'] => s.serviceContainersPage

export const selectCoreContainer = (s: ContainersAppState): ContainersAppState['coreContainer'] => s.coreContainer
export const selectCoreContainersPage = (s: ContainersAppState): ContainersAppState['coreContainersPage'] => s.coreContainersPage

export const selectStartableLoadedDetailedCoreContainer = createLoadedSelector(selectCoreContainer, 'id', canContainerBeStarted)
export const selectRestartableLoadedDetailedCoreContainer = createLoadedSelector(selectCoreContainer, 'id', canContainerBeRestarted)
export const selectStoppableLoadedDetailedCoreContainer = createLoadedSelector(selectCoreContainer, 'id', canContainerBeStopped)

export const selectStartableLoadedDetailedServiceContainer = createLoadedSelector(selectServiceContainer, 'id', canContainerBeStarted)
export const selectRestartableLoadedDetailedServiceContainer = createLoadedSelector(selectServiceContainer, 'id', canContainerBeRestarted)
export const selectStoppableLoadedDetailedServiceContainer = createLoadedSelector(selectServiceContainer, 'id', canContainerBeStopped)
export const selectDeletableLoadedDetailedServiceContainer = createLoadedSelector(selectServiceContainer, 'id', canContainerBeDeleted)

export const selectStartableLoadedCoreContainers = createPageSelector(
    selectCoreContainersPage,
    'id',
    'selectStartableLoadedCoreContainers',
    canContainerBeStarted
)
export const selectRestartableLoadedCoreContainers = createPageSelector(
    selectCoreContainersPage,
    'id',
    'selectRestartableLoadedCoreContainers',
    canContainerBeRestarted
)
export const selectStoppableLoadedCoreContainers = createPageSelector(
    selectCoreContainersPage,
    'id',
    'selectStoppableLoadedCoreContainers',
    canContainerBeStopped
)

export const selectStartableLoadedServiceContainers = createPageSelector(
    selectServiceContainersPage,
    'id',
    'selectStartableLoadedServiceContainers',
    canContainerBeStarted
)
export const selectRestartableLoadedServiceContainers = createPageSelector(
    selectServiceContainersPage,
    'id',
    'selectRestartableLoadedServiceContainers',
    canContainerBeRestarted
)
export const selectStoppableLoadedServiceContainers = createPageSelector(
    selectServiceContainersPage,
    'id',
    'selectStoppableLoadedServiceContainers',
    canContainerBeStopped
)
export const selectDeletableLoadedServiceContainers = createPageSelector(
    selectServiceContainersPage,
    'id',
    'selectDeletableLoadedServiceContainers',
    canContainerBeDeleted
)
