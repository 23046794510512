import React, { type FC } from 'react'

import { Translation } from '../../../domain'

import { AbsoluteRouteOnlyPath } from '../routing'
import { RouteTabs } from '../Tabs'

const tabs = {
    [AbsoluteRouteOnlyPath.SERVICES_LINKS]: {
        documentationTitle: Translation.RESOURCES_AND_SERVICE_LINK_DOCUMENTATION_TITLE,
        documentationBody: Translation.RESOURCES_AND_SERVICE_LINK_DOCUMENTATION_BODY,
    },
    [AbsoluteRouteOnlyPath.RESOURCES_SERVERS]: {
        documentationTitle: Translation.SERVERS_DOCUMENTATION_TITLE,
        documentationBody: Translation.SERVERS_DOCUMENTATION_BODY,
    },
    [AbsoluteRouteOnlyPath.RESOURCES_CONTAINERS]: {
        documentationTitle: Translation.CONTAINERS_DOCUMENTATION_TITLE,
        documentationBody: Translation.CONTAINERS_DOCUMENTATION_BODY,
    },
    [AbsoluteRouteOnlyPath.RESOURCES_VOLUMES]: {
        documentationTitle: Translation.VOLUMES_DOCUMENTATION_TITLE,
        documentationBody: Translation.VOLUMES_DOCUMENTATION_BODY,
    },
    [AbsoluteRouteOnlyPath.RESOURCES_CONNECTIONS]: {
        documentationTitle: Translation.CONNECTIONS_DOCUMENTATION_TITLE,
        documentationBody: Translation.CONNECTIONS_DOCUMENTATION_BODY,
    },
    [AbsoluteRouteOnlyPath.RESOURCES_ENDPOINTS]: {
        documentationTitle: Translation.ENDPOINTS_DOCUMENTATION_TITLE,
        documentationBody: Translation.ENDPOINTS_DOCUMENTATION_BODY,
    },
    [AbsoluteRouteOnlyPath.RESOURCES_MAPPINGS]: {
        documentationTitle: Translation.MAPPINGS_DOCUMENTATION_TITLE,
        documentationBody: Translation.MAPPINGS_DOCUMENTATION_BODY,
    },
} as const

export const ResourcesTabs: FC = () => <RouteTabs title={Translation.RESOURCE} tabs={tabs} />
