import { colors, createTheme, type SimplePaletteColorOptions } from '@mui/material'

import { backgrounds, text } from '../config/alster/colors.json'

/**
 * Colors from Alster
 */
const primary: SimplePaletteColorOptions = { main: '#15415E', dark: '#0e507a', contrastText: '#FFFFFF' }

export const cybusTheme = createTheme({
    palette: {
        ...colors,
        primary,
        secondary: { main: '#f5a119', contrastText: '#FFFFFF' },
        background: { default: colors.grey[50], ...backgrounds },
        info: { main: '#268BD2' },
        text,
    },
    components: {
        MuiDivider: {
            variants: [
                {
                    props: { variant: 'white' },
                    style: {
                        borderColor: 'rgba(255, 255, 255, 0.12)',
                    },
                },
            ],
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            /** Original value was 1200 */
            lg: 1280,
            xl: 1536,
        },
    },
})
