import React, { type FC } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import { selectLicense, Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'
import { useAppState, useAppUsecases } from '../../State'

import { Title } from './Title'
import { Content } from './Content'

export const Modal: FC = () => {
    const license = useAppState(selectLicense)
    const { logoutUsecase } = useAppUsecases()

    return (
        <Dialog id="license-issues-modal" disableEscapeKeyDown open fullWidth maxWidth="md">
            <DialogTitle>
                <Title loading={!license} />
            </DialogTitle>
            <DialogContent dividers>
                <Content license={license} />
            </DialogContent>
            <DialogActions>
                <Button id="license-check-modal-logout" color="primary" size="small" onClick={() => logoutUsecase.logout()}>
                    <FormattedTranslation id={Translation.LOGOUT} />
                </Button>
            </DialogActions>
        </Dialog>
    )
}
