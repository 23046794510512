import React, { type FC } from 'react'
import { type SxProps, type Theme, Typography } from '@mui/material'

import { type SystemCssProperties } from '@mui/system'

import { type BaseCommonProps } from '../Base'

const enabledStyle: SxProps<Theme> = { cursor: 'pointer' }
const disabledStyle: SxProps<Theme> = { color: 'grey.500' }
const labelStyle: SxProps<Theme> = { border: 1, borderColor: 'gray', borderRadius: 1, userSelect: 'none', verticalAlign: 'middle' }
const sizeStyles: Record<Props['type'], SystemCssProperties<Theme>> = {
    tiniest: { fontSize: '0.625rem', py: 0.125, px: 0.5 },
    small: { fontSize: '0.75rem', py: 0.25, px: 0.5 },
}

type Props = Readonly<{ type: 'tiniest' | 'small', disabled?: boolean, onClick?: VoidFunction }> & BaseCommonProps
type ExternalProps = Omit<Props, 'type'>

/**
 * A easy to use set of small buttons
 */
const Button: FC<Props> = ({ disabled = false, type, className, onClick, sx, ...props }) => (
    <Typography
        {...props}
        sx={{ ...sizeStyles[type], ...(disabled ? disabledStyle : enabledStyle), ...labelStyle, ...sx }}
        className={className}
        variant="caption"
        onClick={!disabled ? onClick : undefined}
    />
)

export const SmallButton: FC<ExternalProps> = (props) => <Button type="small" {...props} />
export const TiniestButton: FC<ExternalProps> = (props) => <Button type="tiniest" {...props} />
