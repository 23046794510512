import type { ValuesType } from 'utility-types'
import React, { type FC, type PropsWithChildren, type ReactNode, useMemo, useRef } from 'react'
import MUIDataTable, {
    type MUIDataTableColumn,
    type MUIDataTableOptions,
    type MUIDataTableState,
    type MUIDataTableTextLabelsPagination,
    TableBody,
} from 'mui-datatables'
import { Box, createTheme, ThemeProvider } from '@mui/material'

import { entries, isEnumOf, type NonEmptyArray, normalize } from '../../../../utils'

import { Translation } from '../../../../domain'

import { useTranslator } from '../../Internationalization'

import type { Selection, Translations } from './Domain'
import { getDefaultSearcher, type Searcher, SearchStrategy } from './Search'
import { createThemeOptions } from './Theme'
import { initialTableState, TableStateProvider, useTableState } from './Context'
import { TableToolbar, TableToolbarSelect } from './Toolbar'
import { Footer } from './Footer'
import { EmptyTableBody } from './Empty'
import { useSearchDispatcher } from './Hooks'
import { LoadingTableBody } from './Loading'

type CSVRenderer<V,> = (value: V) => string
type SortOrder<L,> = MUIDataTableOptions['sortOrder'] & Readonly<{ name: keyof L }>

/**
 * Escapes CSV data
 * Should be called for any string
 *
 * Adds quotes to the begining and end of the inputted string
 * Places single quotes before the appearance of dangerous characters
 * Trims extra spaces of the given string
 */
const escapeDangerousCSVCharactersAndQuotes = (str: string): string =>
    `"${str
        .replace(/"/g, '""')
        .replace(/^\+|^-|^=|^@/g, "'$&")
        .trim()}"`

export type Column<Value, Line,> = Readonly<{
    /**
     * The label of the column, can be the enum of `Translation`
     */
    label?: Exclude<MUIDataTableColumn['label'], undefined> | Translation
    /**
     * Defines if sorting is enabled for this column
     * If it is a function, a custom order can be calculated
     * @default true
     */
    sort?: true | ((value: Value) => number | string)
    /**
     * If the column should be displayed
     * It will still be rendered in the CSV
     * @default true
     */
    display?: boolean
    /**
     * How the field will be searched
     * @default {SearchStrategy.LOWER_CASE_DIACRITICLESS}
     */
    searchStrategy?: SearchStrategy | Searcher<Value>
    /**
     * If you want a custom rendering of the cell
     */
    customCellRender?: (value: Value, line: Line, index: number) => ReactNode
    /**
     * If you want a custom render for the CSV being outputted
     */
    customCSVRender?: CSVRenderer<Value> | false
}>

export type Columns<L,> = Partial<Readonly<{ [P in keyof L]: Column<L[P], L> }>>

export type Line = Record<string, unknown>

export type Props<L extends Line,> = Readonly<{
    title?: string
    data: L[]
    columns: Columns<L>

    /**
     * If there is any custom initial sorting order
     */
    initialSortOrder?: SortOrder<L>

    /**
     * Setup pagination configuration
     */
    initialPagination?: Readonly<{ selected?: number, options?: NonEmptyArray<number>, page?: number }> | false
    /**
     * The custom total count of rows (if pagination is external)
     */
    totalCount?: number

    /**
     * @default false
     */
    initialSearch?: string | boolean

    /**
     * Handler of what to do when the row is clicked
     */
    onRowClick?: ((line: L) => void) | void

    /**
     * The configuration if selection ought to be enabled
     */
    selection?: Selection<L>

    /**
     * Called when table is re-sorted, has its view, rows per page or page altered
     */
    onCustomized?: (state: MUIDataTableState) => void | Partial<Readonly<{ page: number }>>

    /**
     * For extra toolbar commands
     */
    extendedToolbar?: ReactNode

    download?: boolean | Readonly<{ /** Generated file will have a timestamp suffix */ suffix?: string }>

    /** The height of the table */
    height?: number | string

    translations?: Translations

    /** Show loading progress */
    isLoading?: boolean
}>

type MUIDataTableToolbarSelect = Readonly<{
    selectedRows: { data: Array<{ index: number, dataIndex: number }>, lookup: Record<number, boolean> }
}>

const TABLE_WRAPPER_CLASS = 'tableWrapperClass'

const InnerTable = <L extends Line,>({
    title,
    data,
    columns,
    initialSortOrder,
    initialPagination = false,
    totalCount = undefined,
    initialSearch = false,
    onRowClick,
    selection,
    onCustomized,
    extendedToolbar,
    translations,
    download = false,
    height = undefined,
    isLoading,
    children,
    ...propsWithData
}: PropsWithChildren<Props<L>>): ReturnType<FC> => {
    const translator = useTranslator()

    /**
     * Map columns config
     */
    const internalColumns: MUIDataTableColumn[] = []
    const searchers: Searcher<unknown>[] = []
    const internalCSV: Readonly<{ name: keyof L, label: string, renderer: CSVRenderer<unknown> }>[] = []

    const clearSelection = useTableState((s) => s.clearSelection)
    const dispatchSearch = useSearchDispatcher()

    const customizedProperties = useRef({
        searchText: typeof initialSearch === 'string' ? initialSearch : '',
        search: Boolean(initialSearch),

        page: (initialPagination && initialPagination.page) || undefined,
        pagination: Boolean(initialPagination),
        rowsPerPage: (initialPagination && initialPagination.selected) || undefined,
        rowsPerPageOptions: (initialPagination && initialPagination.options) || [],

        sortOrder: initialSortOrder,
    })

    entries(columns as Record<string, Column<unknown, unknown>>).forEach(
        ([
            name,
            {
                label,
                searchStrategy = SearchStrategy.LOWER_CASE_DIACRITICLESS,
                customCellRender = undefined,
                customCSVRender = String,
                sort = false,
                display = true,
            },
        ]) => {
            const translatedLabel = isEnumOf(Translation, label) ? translator.formatTranslation(label) : label || ' '

            searchers.push(typeof searchStrategy === 'function' ? searchStrategy : getDefaultSearcher(searchStrategy))
            if (customCSVRender) {
                internalCSV.push({ name: name as keyof L, renderer: customCSVRender, label: translatedLabel })
            }

            internalColumns.push({
                name,
                label: translatedLabel,
                options: {
                    /**
                     * If set to now displayed, will only be shown on the CSV
                     */
                    ...(display ? {} : { display: 'excluded' }),

                    sort: Boolean(sort),

                    filter: false,

                    customBodyRender: (value, { rowIndex }) =>
                        /**
                         * If a customCellRender function is provided, call it to render the cell content
                         * else, render the cell content with '-' if value is null
                         */
                        customCellRender ? customCellRender(value, data[rowIndex], rowIndex) : value ?? '-',

                    sortCompare:
                        typeof sort === 'function'
                            ? (sortOrder) =>
                                  ({ data: a }, { data: b }) =>
                                      (sort(a) > sort(b) ? 1 : -1) * (sortOrder === 'asc' ? 1 : -1)
                            : undefined,
                },
            })
        }
    )

    const theme = useMemo(() => createTheme(createThemeOptions({ cursorHover: Boolean(onRowClick) })), [Boolean(onRowClick), height])

    /** Offset filler for external pagination */
    const filler =
        typeof totalCount === 'number' &&
        typeof customizedProperties.current.rowsPerPage === 'number' &&
        typeof customizedProperties.current.page === 'number' &&
        data.length
            ? Array<L>(customizedProperties.current.page * customizedProperties.current.rowsPerPage).fill((data as NonEmptyArray<L>)[0])
            : []

    return (
        <ThemeProvider theme={theme}>
            {/*
                This extra box servers a dual purpose:
                - to make sure that the toolbar is properly not box shadowed
                - to set the max height on the internal body when necessary
                once these pre conditions are no longer needed, this can be removed
            */}
            <Box sx={{ [`.${TABLE_WRAPPER_CLASS}`]: { minHeight: height, maxHeight: height } }}>
                <MUIDataTable
                    title={title}
                    columns={internalColumns}
                    classes={{ responsiveBase: TABLE_WRAPPER_CLASS }}
                    options={{
                        elevation: 1,
                        responsive: 'standard',
                        print: false,
                        filter: false,
                        viewColumns: false,

                        /**
                         * Switching back and forth between undefined and an empty array forces the table to drop their current selection
                         */
                        rowsSelected: clearSelection ? [] : undefined,
                        selectableRows: selection ? 'multiple' : 'none',

                        /**
                         * Download configuration
                         */
                        download: Boolean(download),
                        downloadOptions: {
                            filename:
                                download && typeof download === 'object' && download.suffix
                                    ? `${new Date()
                                          .toISOString()
                                          .split(/\D+/g)
                                          .filter((v) => v)
                                          .join('-')}-${download.suffix}.csv`
                                    : undefined,
                        },
                        onDownload: () => {
                            const SEPARATOR = ','

                            const head = internalCSV.reduce((output, { label }, k) => {
                                if (k > 0) {
                                    /**
                                     * Add separator after each header if there is info there
                                     */
                                    output += SEPARATOR
                                }

                                output += escapeDangerousCSVCharactersAndQuotes(label)
                                return output
                            }, '')

                            const lines = data.reduce((output, l: L) => {
                                /**
                                 * Add line breaks
                                 */
                                output += '\r\n'

                                output += internalCSV.reduce((output, { name, renderer }, k) => {
                                    if (k > 0) {
                                        /**
                                         * Add separator after each cell
                                         */
                                        output += SEPARATOR
                                    }

                                    output += escapeDangerousCSVCharactersAndQuotes(renderer(l[name]))

                                    return output
                                }, '')

                                return output
                            }, '')

                            return head + lines
                        },

                        /**
                         * Search configuraton
                         */
                        searchText: customizedProperties.current.searchText,
                        search: customizedProperties.current.search,
                        customSearch: (raw: string, rowValues: ValuesType<L>[]): boolean => {
                            const search = { normalizedTerms: normalize(raw).trim().split(/\s+/), raw }
                            return rowValues.some((value, k) => {
                                const searcher = searchers[k]
                                return searcher && searcher(value, search)
                            })
                        },

                        /**
                         * User interaction
                         */
                        customToolbar: extendedToolbar ? () => extendedToolbar : undefined,
                        onRowClick: onRowClick
                            ? (_, { dataIndex }) => {
                                  const line = data[dataIndex - filler.length]
                                  if (line) {
                                      onRowClick(line)
                                  }
                              }
                            : undefined,
                        onTableChange: (action, state) => {
                            if (action !== 'changePage' && action !== 'changeRowsPerPage' && action !== 'search' && action !== 'sort') {
                                return
                            }

                            /**
                             * Set the reference valeue, so if there is a re-render (due to data change)
                             * The current customized values will be passed
                             */
                            customizedProperties.current = {
                                searchText: (typeof state.searchText === 'string' && state.searchText) || '',
                                search: customizedProperties.current.search,

                                page: state.page,
                                pagination: customizedProperties.current.pagination,
                                rowsPerPage: state.rowsPerPage,
                                rowsPerPageOptions: state.rowsPerPageOptions as NonEmptyArray<number>,

                                sortOrder: state.sortOrder,
                            }

                            dispatchSearch(state.searchText || '')

                            const update = onCustomized?.(state)

                            if (update) {
                                customizedProperties.current = {
                                    ...customizedProperties.current,
                                    ...update,
                                }
                            }
                        },

                        /**
                         * Pagination configuration
                         */
                        page: customizedProperties.current.page,
                        count: totalCount,

                        pagination: customizedProperties.current.pagination,
                        rowsPerPage: customizedProperties.current.rowsPerPage,
                        rowsPerPageOptions: customizedProperties.current.rowsPerPageOptions,

                        /** Data manipulation */
                        sortOrder: customizedProperties.current.sortOrder,

                        tableId: String((propsWithData as Record<string, unknown>)['data-testid'] || ''),

                        customFooter:
                            customizedProperties.current.pagination && !isLoading
                                ? (rowCount, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => (
                                      <Footer
                                          totalPages={Math.ceil(rowCount / rowsPerPage)}
                                          page={page}
                                          rowsPerPage={rowsPerPage}
                                          rowsPerPageOptions={customizedProperties.current.rowsPerPageOptions}
                                          changeRowsPerPage={changeRowsPerPage}
                                          changePage={changePage}
                                          textLabels={textLabels as MUIDataTableTextLabelsPagination}
                                      />
                                  )
                                : () => null,
                    }}
                    data={[...filler, ...data]}
                    components={{
                        TableToolbar,
                        TableToolbarSelect: selection
                            ? ({ selectedRows: { data: selected } }: MUIDataTableToolbarSelect) => (
                                  <TableToolbarSelect
                                      {...selection}
                                      lines={selected.reduce<L[]>((r, { dataIndex }) => {
                                          const line = data[dataIndex - filler.length]
                                          if (line) {
                                              r.push(line)
                                          }
                                          return r
                                      }, [])}
                                  />
                              )
                            : undefined,
                        TableBody: (props) =>
                            isLoading ? (
                                <LoadingTableBody columns={internalColumns} selectable={props.options.selectableRows !== 'none'} />
                            ) : props.data.length ? (
                                <TableBody {...props} />
                            ) : (
                                <EmptyTableBody
                                    translations={translations || {}}
                                    columns={internalColumns}
                                    selectable={props.options.selectableRows !== 'none'}
                                />
                            ),
                    }}
                />
                {children}
            </Box>
        </ThemeProvider>
    )
}

/**
 * This is the Table component to be used with-in the Connectware application
 *
 * It is based on mui-datatable's MUIDataTable
 *
 * @todo changes in data size cause the whole table to be re-rendered
 * This needs to be investigated
 * @see https://cybusio.atlassian.net/browse/CYB-3564
 */
export const Table = <L extends Line,>(props: PropsWithChildren<Props<L>>): ReturnType<FC> => (
    <TableStateProvider value={typeof props.initialSearch === 'string' ? { ...initialTableState, search: props.initialSearch } : initialTableState}>
        <InnerTable {...props} />
    </TableStateProvider>
)
