import React, { type FC, type MouseEvent } from 'react'
import { Box, IconButton, type SxProps, type Theme, Tooltip } from '@mui/material'

import { type Translation } from '../../../../../domain'

import { FormattedTranslation } from '../../../Internationalization'

import { useDisabled } from './State'

const buttonStyle: SxProps<Theme> = { p: 0, cursor: 'pointer' }

export const ActionButton: FC<{ disabled?: boolean, tooltip: Translation, onClick?: (e: MouseEvent<Element>) => void }> = ({
    disabled: customDisabled,
    tooltip,
    children,
    ...props
}) => {
    const disabled = useDisabled()

    return (
        <Tooltip title={<FormattedTranslation id={tooltip} />} placement="bottom">
            <Box component={'span'}>
                <IconButton {...props} disabled={customDisabled || disabled} sx={buttonStyle}>
                    {children}
                </IconButton>
            </Box>
        </Tooltip>
    )
}
