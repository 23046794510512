import { createNamedEqualityChecker } from '../../utils'

import type { CybusServer, CybusService, StatusType } from '..'

export type CybusNode = Readonly<{
    id: string
    service: CybusService['id'] | null
    name: string
    status: StatusType
    parent: CybusServer['id']
    protocol: string
    operation: string | null
}>

export const areCybusNodesEquals = createNamedEqualityChecker<CybusNode>(
    { id: null, service: null, name: null, status: null, parent: null, protocol: null, operation: null },
    'areCybusNodesEquals'
)
