import { Grid, type SxProps, type Theme, Typography } from '@mui/material'
import React, { type FC } from 'react'

import { type MfaSettings, type MfaSettingsState, Translation } from '../../../domain'

import { FormattedTranslation } from '../Internationalization'
import { useAppUsecase } from '../State'
import { TiniestCopy } from '../Copy'
import { SmallParagraph, Title } from '../common'
import { Block, Link } from './Common'

const gridWrapperStyle: SxProps<Theme> = { bgcolor: 'blue.50' }
const copyButtonWrapperStyle: SxProps<Theme> = { textAlign: 'right', my: 2, mx: 3 }
const backupCodeStyle: SxProps<Theme> = { textAlign: 'center', mb: 4 }

export const MfaBackupCodes: FC<Pick<MfaSettings<MfaSettingsState.ENABLED>, 'backupCodes'>> = ({ backupCodes }) => {
    const usecase = useAppUsecase('regenerateMfaBackupCodesUsecase')

    const link = (
        <Link data-testid="mfa-regenerate-backup-codes" onClick={() => usecase.start()}>
            <FormattedTranslation id={Translation.MFA_ENABLED_ACTION_CONFIRMATION} values={{ action: 'regenerateBackupCodes' }} />
        </Link>
    )

    if (backupCodes) {
        return (
            <>
                <Title id={Translation.MFA_BACKUP_CODES_PRESENTATION_TITLE} />
                <SmallParagraph id={Translation.MFA_BACKUP_CODES_PRESENTATION_SUBTITLE} />
                <Block data-testid="mfa-backupcodes">
                    <Grid container sx={gridWrapperStyle}>
                        <Grid item xs={12} sx={copyButtonWrapperStyle}>
                            <TiniestCopy>{backupCodes.join('\n')}</TiniestCopy>
                        </Grid>
                        {backupCodes.map((code, k) => (
                            <Grid key={k} item xs={4} sx={backupCodeStyle}>
                                <Typography variant="body2">{code}</Typography>
                            </Grid>
                        ))}
                    </Grid>
                    {link}
                </Block>
            </>
        )
    }

    return link
}
