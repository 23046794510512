import { type EffectCallback, useEffect, useLayoutEffect, useState } from 'react'

import { createInterval } from '../../../utils'

/**
 * Sets up a useEffect that is also hooked to a timed (by setInterval rules) re-render
 *
 * @param interval
 *  In milliseconds, needs to be less than 32-bit integer
 *  @see https://developer.mozilla.org/en-US/docs/Web/API/setInterval#sect1
 */
export const useIntervalEffect = (callback: EffectCallback, interval: number, deps?: unknown[]): void => {
    const [count, setCount] = useState(0)

    /**
     * Actually callback
     */
    useEffect(callback, deps ? [...deps, count] : [count])

    /**
     * Sets up timed-updater
     */
    useLayoutEffect(
        () =>
            /**
             * Force change after the timer
             */
            createInterval(() => setCount((c) => c + 1), interval),
        [interval]
    )
}
