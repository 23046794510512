import type { CybusLinkedService } from '../../../../../../domain'

import type { SubscriptionsTypes } from '../../../../../../application'

import type { ServiceProxy } from '../../../../proxies'

import { ServiceMapper } from './Service'
import { BaseServiceMapper, BaseVrpcServiceProxyInstanceHandler } from './Base'

class LinkedServiceMapper extends BaseServiceMapper<CybusLinkedService> {
    private readonly serviceMapper = new ServiceMapper()

    async mapToDomain (proxy: ServiceProxy): Promise<Map<string, CybusLinkedService>> {
        const { id, name, links } = await this.serviceMapper.mapToDomain(proxy)

        const serviceLinks = new Map<string, CybusLinkedService>()

        if (links.length) {
            serviceLinks.set(id, { id, name, links })
        }

        return serviceLinks
    }
}

export class VrpcLinkedServiceProxyInstanceHandler extends BaseVrpcServiceProxyInstanceHandler<SubscriptionsTypes['servicesLinks']> {
    instanceMapper = new LinkedServiceMapper()
}
