import { useEffect, useState } from 'react'

import { type RuleEngineSandbox, selectSandbox } from '../../../../domain'

import { useAppState } from '../../State'

const useRuleEngine = <P extends keyof RuleEngineSandbox>(prop: P): RuleEngineSandbox[P] => useAppState((s) => selectSandbox(s)[prop])

export const useSelectedType: () => RuleEngineSandbox['type'] = () => useRuleEngine('type')

export const useEndpoints: () => RuleEngineSandbox['endpoints'] = () => useRuleEngine('endpoints')

export const useInput: () => RuleEngineSandbox['input'] = () => useRuleEngine('input')

export const useSelectedTopics: () => RuleEngineSandbox['selectedTopics'] = () => useRuleEngine('selectedTopics')

export const useTransformation: () => RuleEngineSandbox['transformation'] = () => useRuleEngine('transformation')

/**
 * This caches the output from the state of the application
 * and maximizes the chance of it being loaded
 *
 * Even if it is outdated
 */
export const useOutput: () => RuleEngineSandbox['output'] = () => {
    const output = useRuleEngine('output')
    const [loaded, setLoaded] = useState(() => output)

    useEffect(() => {
        if (output.loaded) {
            setLoaded(output)
        }
    }, [output.loaded])

    return loaded
}
