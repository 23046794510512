import { type Certificate, getCertificateTitle } from '../../../domain'

import { CertificatesUsecase } from './Base'

export class DownloadCertificateUsecase extends CertificatesUsecase {
    async download (certificate: Certificate): Promise<void> {
        const content = await this.certificatesService.fetchCertificateFileContent(certificate)
        const [extension] = this.configurationService.getCertificateFileTypes()

        this.fileService.download(new File([content], `${getCertificateTitle(certificate)}.${extension}`))
    }
}
