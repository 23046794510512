import React, { type FC, useState } from 'react'

import { Box, Button, Collapse, Divider, Grid, type SvgIconProps, type SxProps, type Theme, Typography } from '@mui/material'
import { Delete, Download, ExpandLess, ExpandMore } from '@mui/icons-material'

import { type Certificate, getCertificateTitle, sortCertificates, Translation } from '../../../domain'

import { AnchorProvider } from '../common'
import { CertificateDetails } from './Details'
import { DeleteCertificateDialog } from './Delete'

import { useIfHookedCallback } from '../Droppable'
import { useAppUsecases } from '../State'
import { FormattedTranslation } from '../Internationalization'

const Arrow: FC<{ open: boolean } & SvgIconProps> = ({ open, ...props }) => (open ? <ExpandLess {...props} /> : <ExpandMore {...props} />)

const certificateDetailsStyle: SxProps<Theme> = { userSelect: 'none', py: 1, px: 2, '& > *': { mb: 2 } }
const certificateItemStyle: SxProps<Theme> = { p: 2, userSelect: 'none' }
const arrowStyle: SxProps<Theme> = { color: 'grey.700', ml: 1, cursor: 'pointer' }
const buttonStyle: SxProps<Theme> = { display: 'flex', alignItems: 'center', '& > button': { mx: 1, p: 0.5 } }

const headerStyle: SxProps<Theme> = {
    fontSize: 'body2.fontSize',
    transition: ({ transitions }) => transitions.create('font-size', { duration: transitions.duration.standard }),
}
const expandHeaderStyle: SxProps<Theme> = { fontSize: 'h5.fontSize' }

const CertificateItem: FC<Readonly<{ certificate: Certificate, divider: boolean }>> = ({ divider, certificate }) => {
    const title = getCertificateTitle(certificate)
    const [isExpanded, setExpanded] = useState(false)
    const [modal, setModal] = useState<'delete' | null>(null)
    const { downloadCertificateUsecase } = useAppUsecases()

    const ifHooked = useIfHookedCallback()
    const closeModal: VoidFunction = () => ifHooked(() => setModal(null))

    return (
        <>
            <Grid sx={certificateItemStyle} container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography data-testid="certificate-name" sx={[headerStyle, isExpanded && expandHeaderStyle]} title={title}>
                        {title}
                    </Typography>
                </Grid>

                <Grid item>
                    <Box sx={buttonStyle}>
                        {isExpanded && (
                            <>
                                <Button
                                    data-testid="download-btn"
                                    variant="text"
                                    startIcon={<Download />}
                                    onClick={() => void downloadCertificateUsecase.download(certificate)}
                                >
                                    <FormattedTranslation id={Translation.DOWNLOAD} values={{ count: 1 }} />
                                </Button>

                                {certificate.removable && (
                                    <Button data-testid="delete-btn" variant="text" startIcon={<Delete />} onClick={() => setModal('delete')}>
                                        <FormattedTranslation id={Translation.DELETE} />
                                    </Button>
                                )}
                            </>
                        )}

                        <Arrow fontSize="small" open={isExpanded} sx={arrowStyle} data-testid="certs-arrow" onClick={() => setExpanded(!isExpanded)} />
                    </Box>
                </Grid>
            </Grid>

            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <Box sx={certificateDetailsStyle} data-testid="certificate-details">
                    <CertificateDetails certificate={certificate} />
                </Box>
            </Collapse>

            {divider && <Divider />}
            {modal === 'delete' && <DeleteCertificateDialog certificate={certificate} onClose={closeModal} />}
        </>
    )
}

type GroupProps = Readonly<{ certificates: Certificate[], divider: boolean }>

export const CertificatesGroup: FC<GroupProps> = ({ certificates, divider }) => {
    return (
        <Box data-testid="organizations-certs">
            {divider && <Divider />}

            <AnchorProvider>
                {sortCertificates(certificates).map((cert, k) => (
                    <CertificateItem key={k} certificate={cert} divider={k !== certificates.length - 1} />
                ))}
            </AnchorProvider>
        </Box>
    )
}
