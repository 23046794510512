import type { CybusVolume, SortableColumn } from '../../../domain'

import { ResourcePageSubscriptionUsecase, ResourceSingleSubscriptionUsecase } from '../Resources'

export class ManageVolumesUsecase extends ResourcePageSubscriptionUsecase<'volumes'> {
    protected readonly pageName = 'volumes'

    protected readonly pageAddress = 'volumesPage'

    protected readonly initialSortColumn: SortableColumn<CybusVolume> = 'service'
}

export class ManageVolumeUsecase extends ResourceSingleSubscriptionUsecase<'volume'> {
    protected readonly singularName = 'volume'

    protected readonly singleAddress = 'volume'
}
