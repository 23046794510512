import React, { type FC } from 'react'

import { type CybusDetailedVolume, selectDetailedVolume, selectLoadedDetailedVolume, Translation } from '../../../../domain'

import { useRouting } from '../../routing'

import { ResourceDetails } from '../../Resources'
import { Content } from './Content'
import { DeleteModal } from '../Bulk'
import { HeaderActions } from './HeaderActions'
import { HeaderDetails } from './HeaderDetails'

const Modals: FC = () => {
    const routing = useRouting()

    return <DeleteModal volumeSelector={selectLoadedDetailedVolume} onConfirmed={() => routing.redirectToParent()} />
}

export const Details: FC = () => (
    <ResourceDetails<CybusDetailedVolume>
        subscriptionUsecase="manageVolumeUsecase"
        resourceSelector={selectDetailedVolume}
        notFoundTitle={Translation.VOLUME_NOT_FOUND}
        modals={Modals}
        content={Content}
        headerResourceName={Translation.VOLUME}
        headerName="name"
        headerActions={HeaderActions}
        infoTitle={Translation.VOLUME_DETAILS_DOCUMENTATION_TITLE}
        infoBody={Translation.VOLUME_DETAILS_DOCUMENTATION_BODY}
        headerDetails={HeaderDetails}
    />
)
