import React, { type FC, type ReactNode } from 'react'
import { List } from '@mui/material'

import { type CybusDetailedService, Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'

import { ResourcesList } from './ResourcesList'
import { ItemText } from './Commons'

const Routes: FC<Readonly<{ title: Translation, subtitle: Translation, children: ReactNode[] }>> = ({ title, subtitle, children }) => (
    <ResourcesList title={title} subtitle={subtitle}>
        <List disablePadding data-testid={title}>
            {children}
        </List>
    </ResourcesList>
)

export const HttpIngressRoutes: FC<Pick<CybusDetailedService['configuredResources'], 'httpRoutes'>> = ({ httpRoutes }) => (
    <Routes title={Translation.AUTHORIZE_HTTP_INGRESS_ROUTES} subtitle={Translation.AUTHORIZE_HTTP_INGRESS_ROUTES_SUBTITLE}>
        {httpRoutes.map(({ slug, container }, k) => (
            <ItemText
                key={`httpRoute-${k}`}
                primary={<FormattedTranslation id={Translation.AUTHORIZE_HTTP_INGRESS_ROUTE_GENERATED_URL} values={{ slug }} />}
                secondary={<FormattedTranslation id={Translation.AUTHORIZE_HTTP_INGRESS_ROUTE_CONTAINER} values={{ container }} />}
            />
        ))}
    </Routes>
)

export const TcpIngressRoutes: FC<Pick<CybusDetailedService['configuredResources'], 'tcpRoutes'>> = ({ tcpRoutes }) => (
    <Routes title={Translation.AUTHORIZE_TCP_INGRESS_ROUTES} subtitle={Translation.AUTHORIZE_TCP_INGRESS_ROUTES_SUBTITLE}>
        {tcpRoutes.map(({ container, connectwarePort, containerPort }, k) => (
            <ItemText
                key={`tcpRoute-${k}`}
                primary={<FormattedTranslation id={Translation.AUTHORIZE_TCP_INGRESS_ROUTE_PORT} values={{ connectwarePort }} />}
                secondary={<FormattedTranslation id={Translation.AUTHORIZE_TCP_INGRESS_ROUTE_DESTINATION} values={{ container, containerPort }} />}
            />
        ))}
    </Routes>
)
