import { selectUsersManagement, selectUsersManagementSelectedTab, type UsersManagement } from '../../../domain'
import { BaseUsecase } from './Base'

type TabInfo = UsersManagement[UsersManagement['selected']]

export class FilterUserManagementUsecase extends BaseUsecase {
    private setTab (tab: UsersManagement['selected'] | null, generator: ((current: TabInfo) => Partial<TabInfo>) | Partial<TabInfo> = {}): void {
        const state = this.getState()

        /** Define tab that will be updated */
        const selected = tab || selectUsersManagementSelectedTab(state)

        /** Retrive information an generate update */
        const { [selected]: tabInfo } = selectUsersManagement(state)
        const update = typeof generator === 'function' ? generator(tabInfo) : generator

        /** Always set the data */
        this.setUsersManagement({ selected, [selected]: { ...tabInfo, ...update, data: null } })
    }

    search (search: string): void {
        this.setTab(null, { search, page: 1 })
    }

    toggleShowInternal (): void {
        this.setTab(null, ({ showInternal }) => ({ showInternal: !showInternal, page: 1 }))
    }

    setPage (page: number): void {
        this.setTab(null, { page })
    }

    selectTab (selected: UsersManagement['selected']): void {
        this.setTab(selected)
    }
}
