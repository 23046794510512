import { capture, Droppable, Throttler } from '../../../../../utils'
import { ConnectwareError, ConnectwareErrorType } from '../../../../../domain'
import type { SubscriptionData, SubscriptionsTypes } from '../../../../../application'

type Value<D> = D | Map<string, D> | ConnectwareError

export abstract class EntityManager<T extends keyof SubscriptionsTypes, A extends unknown[]> {
    private dropped = false

    private value: Value<SubscriptionData<T>> | null = null

    private unsub: ((...args: A) => Promise<unknown> | unknown) | null = null

    protected abstract internallyDroppedArgs: A

    protected abstract readonly errorMessage: string

    constructor (private readonly handler: VoidFunction) {}

    get values (): (SubscriptionData<T> | ConnectwareError)[] {
        if (this.value === null) {
            /** Nothing loaded */
            return []
        }

        if (this.value instanceof Map) {
            /** Multiple values are generated by this mapper */
            return Array.from(this.value.values())
        }

        /** Only one value or error is retrieved out of this */
        return [this.value]
    }

    private mapError (e: unknown): ConnectwareError {
        if (ConnectwareError.is(e)) {
            return e
        }

        if (e instanceof Error) {
            return new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, this.errorMessage, {
                ...this.getErrorExtras(),
                message: e.message,
                name: e.name,
                stack: e.stack,
            })
        }

        return new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Thrown error is not an error object', { ...this.getErrorExtras(), error: e })
    }

    private setValue (value: Value<SubscriptionData<T>>): void {
        this.value = value
        this.handler()
    }

    private async safelyUnsub (...args: A): Promise<void> {
        await this.runSafely(async () => {
            const unsub = this.unsub

            /** Protected later calls */
            this.unsub = null

            await unsub?.(...args)
        })
    }

    private async runSafely<V> (run: () => Promise<V>): Promise<void> {
        try {
            await run()
        } catch (e: unknown) {
            if (e instanceof Error) {
                capture(e, run)
            }

            this.setValue(this.mapError(e))
        }
    }

    /**
     * Converts managed entity into a domain value
     *
     * @throws ConnectwareError
     */
    protected abstract generateValue (): Promise<SubscriptionData<T> | Map<string, SubscriptionData<T>>>

    /**
     * Add listeners to the managed entity
     *
     * @throws ConnectwareError
     */
    protected abstract addListener (): Promise<((...args: A) => Promise<unknown> | unknown) | void> | void

    protected getErrorExtras (): Record<string, unknown> {
        return {}
    }

    protected async safelySetValue (): Promise<void> {
        await this.runSafely(async () => this.setValue(await this.generateValue()))
    }

    protected async safelyAddListener (): Promise<void> {
        await this.runSafely(async () => {
            this.unsub = (await this.addListener()) || null
        })

        if (this.dropped) {
            await this.safelyUnsub(...this.internallyDroppedArgs)
        }
    }

    async drop (...args: A): Promise<void> {
        this.dropped = true

        await this.safelyUnsub(...args)
    }
}

export abstract class EntitiesManager<T extends keyof SubscriptionsTypes, K, A extends unknown[]> {
    private readonly entities = new Map<K, EntityManager<T, A> | ConnectwareError>()

    private readonly droppable = new Droppable()

    private readonly throttler = new Throttler(50)

    protected abstract internallyDroppedArgs: A

    constructor (private readonly handler: (data: SubscriptionData<T>[]) => void) {}

    protected async add (entityKey: K, entityGenerator: () => EntityManager<T, A> | ConnectwareError): Promise<void> {
        /** Check if entity has already been added, excluding exception where it was not found */
        if (this.entities.has(entityKey) && !ConnectwareError.isOfTypes(this.entities.get(entityKey), ConnectwareErrorType.NOT_FOUND)) {
            /** Drop previous entity silently */
            const removalPromise = this.remove(entityKey, ...this.internallyDroppedArgs)

            /** Replace previous value with this state error so it can be flagged down */
            this.entities.set(entityKey, new ConnectwareError(ConnectwareErrorType.STATE, 'Was already added', { entityKey }))

            /** Wait for full removal */
            await removalPromise

            /** Emit change */
            this.emitChange()

            return
        }

        const entity = entityGenerator()

        /** Check now if entity loaded as expected */
        if (ConnectwareError.is(entity)) {
            /** It did not, so set error */
            this.entities.set(entityKey, entity)

            /** Propagate error */
            this.emitChange()
        } else {
            /** Create internal manager */
            this.entities.set(entityKey, entity)
        }
    }

    protected async remove (entityKey: K, ...args: A): Promise<void> {
        /** Get entity */
        const entity = this.entities.get(entityKey)

        /** Remove entities reference */
        this.entities.delete(entityKey)

        if (entity instanceof EntityManager) {
            /** Drop it */
            await entity.drop(...args)
        }
    }

    protected emitChange (): void {
        this.throttler.run(() =>
            this.droppable.ifNotDropped(() =>
                this.handler(
                    Array.from(this.entities).reduce<SubscriptionData<T>[]>((r, [, entityManagerOrError]) => {
                        if (ConnectwareError.is(entityManagerOrError)) {
                            /**
                             * Entity is an error
                             */
                            r.push(entityManagerOrError)
                        } else {
                            /**
                             * Entity has at least loaded as expected
                             */
                            r.push(...entityManagerOrError.values)
                        }

                        return r
                    }, [])
                )
            )
        )
    }

    /**
     * To **quietly** drop all entities
     */
    async drop (): Promise<void> {
        this.droppable.drop()

        /** Drop all internal managers */
        await Promise.all(Array.from(this.entities.keys()).map((hash) => this.remove(hash, ...this.internallyDroppedArgs)))
    }
}
