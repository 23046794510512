import React, { type FC, type MouseEvent, useState } from 'react'
import { ArrowDropDown, Download, Replay } from '@mui/icons-material'

import { Menu, MenuItem, Chip as MuiChip, type SxProps, type Theme } from '@mui/material'

import { type NonEmptyArray } from '../../../utils'
import { ConnectwareError, type CybusLog, CybusLogLevel, type CybusLogSource, Translation } from '../../../domain'

import { type LogOptions } from '../../../application'
import { FormattedDateTime, FormattedTranslation, useTranslator } from '../Internationalization'
import { useAppUsecase } from '../State'

import { Table as BaseTable, ButtonDatePicker, Chip } from '../common'
import { ErrorMessage } from '../ErrorMessage'
import { Modal } from './Modal'
import { LogLevel } from './LogLevel'
import { useDates, useLogs } from './Hooks'

type WithIdProps = Readonly<{ id: string }>
type WithoutIdProps = Readonly<{ downloadSuffix: string }>
type Props = (WithIdProps | WithoutIdProps) &
    Readonly<{ type: CybusLogSource, lines?: LogOptions['lines'], showDownloadRawLogs?: boolean, showServiceId?: boolean }>

const filterStyle: SxProps<Theme> = { backgroundColor: 'grey.200', '&:hover': { backgroundColor: 'grey.200' } }
const activeFilterStyle: SxProps<Theme> = { backgroundColor: 'grey.400', '&:hover': { backgroundColor: 'grey.400' } }

export const Table: FC<Props> = ({ lines = 'limited', type, showDownloadRawLogs = false, showServiceId = false, ...props }) => {
    const [selectedLog, selectLog] = useState<CybusLog | null>(null)
    const containerLogs = useAppUsecase('containerLogsUsecase')
    const translation = useTranslator()

    const [levelFilters, setLevelFilters] = useState<CybusLogLevel[]>([])
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [[startDate, endDate], setStartDate, setEndDate] = useDates(null, null)

    const [logs, refreshLogs] = useLogs(type, {
        resource: 'id' in props ? props.id : null,
        lines,
        start: startDate?.toDate() ?? null,
        end: endDate?.toDate() ?? null,
        levels: levelFilters.length > 0 ? (levelFilters as NonEmptyArray<CybusLogLevel>) : null,
    })

    const onMenuClose = (): void => {
        setAnchorEl(null)
    }

    const onMenuOptionClick = (option: CybusLogLevel): void => {
        if (!levelFilters.includes(option)) {
            setLevelFilters((prevLevels) => [...prevLevels, option])
        }
        onMenuClose()
    }

    if (ConnectwareError.is(logs)) {
        return <ErrorMessage error={logs} stack extras="section" />
    }

    return (
        <BaseTable
            data-testid="service-log-table"
            isLoading={logs === null}
            data={(logs ?? []).map((l) => ({ ...l, date: l.timestamp }))}
            columns={{
                level: { label: Translation.LEVEL, customCellRender: (level) => <LogLevel level={level} />, sort: true },
                timestamp: {
                    label: Translation.TIME,
                    customCellRender: (date) => <FormattedDateTime date={date} format="datetime" />,
                    customCSVRender: (timestamp) => timestamp.toISOString(),
                    sort: (t) => t.getTime(),
                },
                ...(showServiceId ? { serviceId: { label: translation.formatTranslation(Translation.SERVICE, { count: 1 }), sort: true } } : {}),
                message: { label: Translation.MESSAGE, sort: true },
            }}
            initialPagination={{ selected: 50 }}
            initialSortOrder={{ name: 'timestamp', direction: 'desc' }}
            extendedToolbar={
                <>
                    {showDownloadRawLogs && (
                        <Chip
                            label={Translation.DOWNLOAD_RAW_LOGS}
                            avatar={Download}
                            onClick={() => void containerLogs.downloadRawlogs(type, 'id' in props ? props.id : null)}
                        />
                    )}
                    <Chip label={Translation.REFRESH_LOGS} avatar={Replay} onClick={refreshLogs} />

                    <MuiChip
                        data-testid="filter-button"
                        label={<FormattedTranslation id={Translation.FILTER} />}
                        icon={<ArrowDropDown />}
                        sx={levelFilters.length > 0 ? activeFilterStyle : filterStyle}
                        onClick={(e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget)}
                    />

                    <Menu data-testid="filter-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onMenuClose}>
                        {Object.values(CybusLogLevel).map((level) => (
                            <MenuItem data-testid={`log-level-item-${level}`} key={level} onClick={() => onMenuOptionClick(level)}>
                                {<FormattedTranslation id={Translation.LOG_LEVEL} values={{ level }} />}
                            </MenuItem>
                        ))}
                    </Menu>

                    {levelFilters.map((level) => (
                        <MuiChip
                            data-testid={`log-level-chip-${level}`}
                            key={level}
                            label={<FormattedTranslation id={Translation.LOG_LEVEL} values={{ level }} />}
                            onDelete={() => setLevelFilters((prevLevels) => prevLevels.filter((item) => item !== level))}
                        />
                    ))}

                    <ButtonDatePicker emptyLabel={Translation.START_TIME} value={startDate} onAccept={setStartDate} disableFuture />
                    <ButtonDatePicker
                        emptyLabel={Translation.END_TIME}
                        value={endDate}
                        onAccept={setEndDate}
                        disableFuture
                        minDateTime={startDate ?? undefined}
                    />
                </>
            }
            onRowClick={selectLog}
            initialSearch
            download={{ suffix: 'downloadSuffix' in props ? props.downloadSuffix : props.id }}
            translations={{ noResultsOrEmptyTable: Translation.NO_LOGS_FOUND }}
        >
            {selectedLog && <Modal log={selectedLog} onClose={() => selectLog(null)} />}
        </BaseTable>
    )
}
