import React, { type FC } from 'react'
import { Box, type SxProps, type Theme, Typography } from '@mui/material'
import { LockOutlined } from '@mui/icons-material'

import { Translation } from '../../../domain'
import { FormattedTranslation } from '../Internationalization'

const wrapperStyle: SxProps<Theme> = { display: 'flex', flexDirection: 'column', p: 2 }
const iconMessageStyle: SxProps<Theme> = { display: 'flex', alignItems: 'center' }
const iconStyle: SxProps<Theme> = { color: 'orange.400', fontSize: 20 }
const titleStyle: SxProps<Theme> = { color: 'primary.dark', ml: 1 }
const subTitleStyle: SxProps<Theme> = { ml: 3.5 }

export const NoPermission: FC = () => {
    return (
        <Box sx={wrapperStyle} data-testid="no-permission-message">
            <Box sx={iconMessageStyle}>
                <LockOutlined sx={iconStyle} />
                <Typography sx={titleStyle} variant="h6">
                    <FormattedTranslation id={Translation.YOU_NEED_PERMISSION_TO_VIEW_THIS_DATA} />
                </Typography>
            </Box>
            <Typography sx={subTitleStyle}>
                <FormattedTranslation id={Translation.ASK_YOUR_ADMIN_TO_GAIN_ACCESS} />
            </Typography>
        </Box>
    )
}
