import { type Certificate, ConnectwareError, ConnectwareErrorType, selectCertificates } from '../../../domain'

import { Usecase } from '../Usecase'

export abstract class CertificatesUsecase extends Usecase {
    protected updateCertificates (remapper: (certs: Certificate[]) => Certificate[]): void {
        const certificates = selectCertificates(this.getState())
        if (Array.isArray(certificates)) {
            this.setState({ certificates: remapper(certificates) })
        } else {
            throw new ConnectwareError(ConnectwareErrorType.STATE, 'Application state is unexpected. Update not possible')
        }
    }
}
