import type { Intersection } from 'utility-types'

import { type CybusDetailedVolume, type CybusVolume, StatusType } from '../../../domain'

import type { SubscriptionFilterArgs } from '../../../application'
import type { DockerDetailedVolumeResponse, DockerVolume } from '../proxies'
import { mapResourceNames } from './Resource'

const mapBaseVolume = ({ CreatedAt, Name }: DockerVolume): Intersection<CybusVolume, CybusDetailedVolume> => {
    const [service, name] = mapResourceNames(Name)
    return { name, id: Name, service, created: new Date(CreatedAt), status: StatusType.ONLINE }
}

export const mapVolumes = (Volumes: DockerVolume[], { service }: SubscriptionFilterArgs): CybusVolume[] =>
    Volumes.reduce<CybusVolume[]>((r, volume) => {
        const cybusVolume = mapBaseVolume(volume)
        return service && service !== cybusVolume.service ? r : [...r, cybusVolume]
    }, [])

export const mapDetailedVolume = (volume: DockerDetailedVolumeResponse): CybusDetailedVolume => ({ ...mapBaseVolume(volume), labels: volume.Labels })
