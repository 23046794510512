import React, { type FC, useEffect } from 'react'
import { CircularProgress, Grid, type SxProps, type Theme } from '@mui/material'

import { ConnectwareError, CybusRuleEngineExecutionError, RuleEngineType, Translation } from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'
import { useAppUsecase } from '../../State'
import { NoReRenderOnHover } from '../../common'
import { ErrorMessage } from '../../ErrorMessage'

import { useInput, useOutput, useSelectedTopics, useSelectedType, useTransformation } from './Hooks'
import { Code } from './Code'
import { RuleEngineErrorMessage, RuleEngineErrorPayload, RuleEngineErrorRule, RuleEngineErrorTitle } from '..'

const messageStyle: SxProps<Theme> = { mb: 2 }

export const TransformationOutput: FC = () => {
    const transform = useAppUsecase('transformSandboxUsecase')

    const type = useSelectedType()
    const selectedTopics = useSelectedTopics()
    const input = useInput()
    const transformation = useTransformation()
    const output = useOutput()

    useEffect(() => transform.invoke(), [transform, input, transformation])

    if (type !== RuleEngineType.JSON && !selectedTopics?.length) {
        /** Not a custom json, so topics are necessary */
        return (
            <Code id="rule-engine-output">
                <FormattedTranslation id={type === RuleEngineType.ENDPOINT ? Translation.SELECT_ENDPOINT : Translation.INPUT_MQTT_TOPIC} />
            </Code>
        )
    }

    if (!input.loaded) {
        /** Everything is properly set, but input has yet to be loaded */
        return (
            <Code id="rule-engine-output">
                <FormattedTranslation id={Translation.NO_MESSAGES} />
            </Code>
        )
    }

    if (!transformation) {
        /** Transformation was not inputted */
        return (
            <Code id="rule-engine-output">
                <FormattedTranslation id={Translation.INPUT_TRANSFORMATION_RULE} />
            </Code>
        )
    }

    if (!output.loaded) {
        /** Output is now loading */
        return (
            <Code id="rule-engine-output">
                <CircularProgress data-testid="loading-output" />
            </Code>
        )
    }

    if (CybusRuleEngineExecutionError.is(output.value)) {
        /** Issue with transformation */
        return (
            <Code id="rule-engine-output">
                <RuleEngineErrorTitle id="rule-engine-output-title" executionError={output.value} />
                <RuleEngineErrorMessage id="rule-engine-output-message" sx={messageStyle} executionError={output.value} />
                <Grid container spacing={2}>
                    <Grid id="rule-engine-output-payload" item xs={6}>
                        <NoReRenderOnHover>
                            <RuleEngineErrorPayload executionError={output.value} />
                        </NoReRenderOnHover>
                    </Grid>
                    <Grid id="rule-engine-output-rule" item xs={6}>
                        <NoReRenderOnHover>
                            <RuleEngineErrorRule executionError={output.value} />
                        </NoReRenderOnHover>
                    </Grid>
                </Grid>
            </Code>
        )
    }

    if (ConnectwareError.is(output.value)) {
        /** Other issues */
        return (
            <Code id="rule-engine-output">
                <ErrorMessage titleVariant="subtitle1" error={output.value} extras="section" />
            </Code>
        )
    }

    return <Code id="rule-engine-output" json={output.value} />
}
