import { areCybusNodesEquals, Capability, type CybusNode } from '../../../../../../domain'
import type { SubscriptionFilterArgs } from '../../../../../../application'

import { mapCybusNodes, type NodeProxyParams } from '../../../../../Connectware'

import { BaseSubscriptionStrategy } from '../Base'

export class NodesStrategy extends BaseSubscriptionStrategy<'nodes'> {
    readonly requiredPermissions = [Capability.NODES_READ]

    protected readonly supportedFilters: (keyof SubscriptionFilterArgs)[] = ['server']

    protected readonly areEquals = areCybusNodesEquals

    retrieveAll (filter: SubscriptionFilterArgs): Promise<CybusNode[]> {
        return this.retrieve({
            capability: Capability.NODES_READ,
            method: 'GET',
            path: '/api/nodes',
            mapper: (data: NodeProxyParams[]) => mapCybusNodes(data, filter),
        })
    }
}
