import React, { type FC, Fragment } from 'react'
import { List, Tooltip } from '@mui/material'
import { Warning } from '@mui/icons-material'

import { type CybusDetailedService, Translation } from '../../../../domain'

import { Table } from '../../common'
import { FormattedList, FormattedTranslation } from '../../Internationalization'

import { ItemText, NestedItemText, TextBlock } from './Commons'
import { ResourcesList } from './ResourcesList'

export const Containers: FC<Pick<CybusDetailedService['configuredResources'], 'containers'>> = ({ containers }) => {
    return (
        <ResourcesList title={Translation.AUTHORIZE_CONTAINERS_START}>
            <List data-testid="service-containers" dense>
                {containers.map(({ image, name, privileged, ports, capabilities, devices }, k) => (
                    <Fragment key={`container-${k}`}>
                        <ItemText primary={name} secondary={image} />
                        {privileged && (
                            <NestedItemText
                                icon={
                                    <Tooltip title={<FormattedTranslation id={Translation.AUTHORIZE_PRIVILEGED_CONTAINER_WARNING} />} placement="left-end">
                                        <Warning />
                                    </Tooltip>
                                }
                                isNormalFontWeight
                                primary={<FormattedTranslation id={Translation.PRIVILEGED} />}
                            />
                        )}
                        {ports.length > 0 && (
                            <>
                                <NestedItemText isNormalFontWeight primary={<FormattedTranslation id={Translation.AUTHORIZE_CONTAINER_PORTS_USAGE} />} />
                                <NestedItemText
                                    primary={<FormattedTranslation id={Translation.PORT} values={{ count: 2 }} />}
                                    secondary={
                                        <>
                                            {ports.map((port, k) => (
                                                <TextBlock key={`port-${k}`}>{port}</TextBlock>
                                            ))}
                                        </>
                                    }
                                />
                            </>
                        )}
                        {capabilities.length > 0 && (
                            <NestedItemText
                                primary={<FormattedTranslation id={Translation.CAPABILITY} values={{ count: 2 }} />}
                                secondary={<FormattedList value={capabilities} />}
                            />
                        )}
                        {devices.length > 0 && (
                            <>
                                <NestedItemText primary={<FormattedTranslation id={Translation.AUTHORIZE_CONTAINER_DEVICES} />} />
                                <NestedItemText
                                    disableTypography
                                    primary={
                                        <Table
                                            data={devices}
                                            columns={{
                                                pathOnHost: { label: Translation.PATH_ON_HOST },
                                                pathInContainer: { label: Translation.PATH_IN_CONTAINER },
                                                cGroupPermissions: { label: Translation.CGROUP_PERMISSIONS },
                                            }}
                                        />
                                    }
                                />
                            </>
                        )}
                    </Fragment>
                ))}
            </List>
        </ResourcesList>
    )
}
