import type { CybusServer } from '../../../../../../domain'

import { mapCybusServer } from '../../../../../Connectware'
import { OPCUA_SERVER_CLASSNAME_FILTER } from '../../../../constants'
import type { ServerProxy } from '../../../../proxies'
import {
    excludeInstanceByServiceName,
    listenToProxy,
    type UnsubFromInstanceFunction,
    type VrcpEntityHandler,
    type VrpcHandlerConfiguration,
    type VrpcInstanceMapper,
} from '../Handler'

class VrpcServerProxyToCybusServerMapper implements VrpcInstanceMapper<ServerProxy, CybusServer> {
    mapToDomain (proxy: ServerProxy): Promise<CybusServer> {
        return proxy.getParams().then(mapCybusServer)
    }

    onChange (proxy: ServerProxy, listener: () => void): Promise<UnsubFromInstanceFunction> {
        return listenToProxy(proxy, ['state'], listener)
    }
}

abstract class VrpcBaseServerProxyToCybusServerHandler implements VrcpEntityHandler<ServerProxy, CybusServer> {
    readonly instanceMapper = new VrpcServerProxyToCybusServerMapper()

    readonly remoteMapper = null

    constructor (readonly configuration: VrpcHandlerConfiguration) {}
}

export class VrpcServerProxyToCybusServersHandler extends VrpcBaseServerProxyToCybusServerHandler {
    constructor () {
        super({ classNameFilter: OPCUA_SERVER_CLASSNAME_FILTER, supportedFilters: ['service'], excludeByInstanceName: excludeInstanceByServiceName })
    }
}

export class VrpcServerProxyToCybusServerHandler extends VrpcBaseServerProxyToCybusServerHandler {
    constructor () {
        super({ classNameFilter: OPCUA_SERVER_CLASSNAME_FILTER, supportedFilters: [] })
    }
}
