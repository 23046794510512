import { canServerBeDeleted, canServerBeDisabled, canServerBeEnabled, type CybusNode, type CybusServer } from '.'
import { createLoadedSelector, type Loadable } from '../Loadable'
import { createPageSelector, type Page } from '..'

export type ServersAppState = Readonly<{ serversPage: Page<CybusServer>, server: Loadable<CybusServer>, nodesPage: Page<CybusNode> }>

export const selectServer = (s: ServersAppState): ServersAppState['server'] => s.server
export const selectServersPage = (s: ServersAppState): ServersAppState['serversPage'] => s.serversPage
export const selectNodesPage = (s: ServersAppState): ServersAppState['nodesPage'] => s.nodesPage

export const selectDisablableLoadedServers = createPageSelector(selectServersPage, 'id', 'selectDisablableLoadedServers', canServerBeDisabled)
export const selectEnabableLoadedServers = createPageSelector(selectServersPage, 'id', 'selectEnabableLoadedServers', canServerBeEnabled)
export const selectDeletableLoadedServers = createPageSelector(selectServersPage, 'id', 'selectDeletableLoadedServers', canServerBeDeleted)

export const selectDisablableLoadedServer = createLoadedSelector(selectServer, 'id', canServerBeDisabled)
export const selectEnabableLoadedServer = createLoadedSelector(selectServer, 'id', canServerBeEnabled)
export const selectDeletableLoadedServer = createLoadedSelector(selectServer, 'id', canServerBeDeleted)
