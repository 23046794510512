import type { PickByValue, PickByValueExact } from 'utility-types'

import type { SvgIconComponent } from '@mui/icons-material'

import { entries, type PartialReadonlyRecord } from '../../../../utils'

import type { AppState, Translation } from '../../../../domain'

import type { Usecases } from '../../../../application'

import { useAppState, useAppUsecases } from '../../State'
import type { TableActionIcons, TableActionNames, TableActions, TableActionTranslations, TableLine, TableSelection } from '../../common'

export type UseSelectionProps<TableResource extends TableLine, UsecaseSelectNames extends keyof Usecases> = Readonly<{
    /**
     * Maps if the current user is capable to manage the listed resource
     */
    hasManagementCapabilitiesSelector?: (s: AppState) => boolean

    /**
     * The actions that are available on selection
     * Define the usecase, when its enabled and custom icon
     */
    onSelect?: PartialReadonlyRecord<
        TableActionNames,
        Readonly<{ usecase: UsecaseSelectNames, filter?(r: TableResource): boolean, icon?: SvgIconComponent, label?: Translation }>
    > &
        Readonly<{
            /** The name of the prop that will be passed to usecase */
            resource: keyof PickByValueExact<TableResource, string> & string
            /** The usecase method to be called */
            usecaseMethod: keyof PickByValue<Usecases[UsecaseSelectNames], (id: string[] | null) => void>
        }>
}>

export const useSelection = <TableResource extends TableLine, UsecaseSelectNames extends keyof Usecases>({
    hasManagementCapabilitiesSelector = () => true,
    onSelect,
}: UseSelectionProps<TableResource, UsecaseSelectNames>): TableSelection<TableResource> | undefined => {
    const usecases = useAppUsecases()

    const canManage = useAppState(hasManagementCapabilitiesSelector)

    const icons: TableActionIcons = {}
    const labels: TableActionTranslations = {}

    if (!onSelect) {
        return
    }

    return {
        onSelect: (lines) => {
            const out: TableActions<TableResource> = {}

            if (!canManage) {
                return out
            }

            const { resource, usecaseMethod: selectionMethod, ...actions } = onSelect

            entries(actions).forEach(([actionName, config]) => {
                if (!config || (config.filter && !lines.every(config.filter))) {
                    return
                }

                const { [config.usecase]: usecase } = usecases

                out[actionName] = (resources) => (usecase[selectionMethod] as (id: string[] | null) => void)(resources.map((r) => String(r[resource])))

                if (config.icon) {
                    icons[actionName] = config.icon
                }

                if (config.label) {
                    labels[actionName] = config.label
                }
            })

            return out
        },
        icons,
        labels,
    }
}
