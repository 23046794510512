import { type CybusMapping, ResourceType } from '../../../domain'

import { BulkDeleteResourceUsecase, BulkDisableResourceUsecase, BulkEnableResourceUsecase } from '../Resources'

export class BulkDeleteMappingsUsecase extends BulkDeleteResourceUsecase {
    protected readonly type = ResourceType.MAPPING

    selectMappings (ids: CybusMapping['id'][] | null): void {
        this.select(ids)
    }

    delete (): Promise<void> {
        return super.delete()
    }
}

export class BulkEnableMappingsUsecase extends BulkEnableResourceUsecase {
    protected readonly type = ResourceType.MAPPING

    selectMappings (ids: CybusMapping['id'][] | null): void {
        this.select(ids)
    }

    enable (): Promise<void> {
        return super.enable()
    }
}

export class BulkDisableMappingsUsecase extends BulkDisableResourceUsecase {
    protected readonly type = ResourceType.MAPPING

    selectMappings (ids: CybusMapping['id'][] | null): void {
        this.select(ids)
    }

    disable (): Promise<void> {
        return super.disable()
    }
}
