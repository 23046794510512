import React, { type FC } from 'react'

import { canMappingBeDeleted, canMappingBeDisabled, canMappingBeEnabled, Capability, type CybusDetailedMapping, Translation } from '../../../../domain'

import { useAppUsecases } from '../../State'

import { DetailsActionButton } from '../../Details'

export const HeaderActions: FC<{ resource: CybusDetailedMapping }> = ({ resource: { id, status } }) => {
    const { bulkDeleteMappingsUsecase, bulkEnableMappingsUsecase, bulkDisableMappingsUsecase } = useAppUsecases()

    return (
        <>
            <DetailsActionButton
                id={canMappingBeDisabled({ status }) ? 'disable-mapping-button' : 'enable-mapping-button'}
                disabled={!canMappingBeDisabled({ status }) && !canMappingBeEnabled({ status })}
                translation={canMappingBeDisabled({ status }) ? Translation.DISABLE : Translation.ENABLE}
                capabilities={[Capability.MAPPINGS_MANAGE]}
                onClick={
                    canMappingBeDisabled({ status })
                        ? () => bulkDisableMappingsUsecase.selectMappings([id])
                        : () => bulkEnableMappingsUsecase.selectMappings([id])
                }
            />

            <DetailsActionButton
                disabled={!canMappingBeDeleted({ status })}
                id="delete-mapping-button"
                translation={Translation.DELETE}
                capabilities={[Capability.MAPPINGS_MANAGE]}
                onClick={() => bulkDeleteMappingsUsecase.selectMappings([id])}
            />
        </>
    )
}
