import React, { type FC } from 'react'

import { type TopicTree } from '../../../../domain'

import { useAppUsecase } from '../../State'
import { TopicsSubscriptionErrorsPopover } from '../../common'

type Props = Readonly<{ tree: TopicTree }>

const mapTopics = ({ path, children }: TopicTree): string[] => {
    const label = path.join('/')
    return [label, ...children.flatMap(mapTopics)]
}

export const ErrorInfoPopover: FC<Props> = ({ tree }) => {
    const manage = useAppUsecase('explorerTopicManagementUsecase')

    const testId = tree.path.join('/')
    const topics = mapTopics(tree)

    return (
        <TopicsSubscriptionErrorsPopover
            data-testid={testId}
            topics={topics}
            errors={tree.subscriptionErrors}
            onRetry={() => manage.retryTopic(tree.path)}
            onUnsub={() => manage.toggleTopic(tree.path)}
        />
    )
}
