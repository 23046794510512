import React, { type FC, useEffect } from 'react'
import { Box } from '@mui/material'

import { useAppUsecase } from '../State'

import { MfaContent, MfaDescription, MfaToggle } from '../MFA'
import { Section } from './Section'

export const MfaSettings: FC = () => {
    const usecase = useAppUsecase('loadMfaSettingsUsecase')
    useEffect(() => usecase.load(), [usecase])

    return (
        <Section width={600}>
            <MfaDescription required={false} />
            <Box data-testid="mfa-settings">
                <MfaToggle />
                <MfaContent />
            </Box>
        </Section>
    )
}
