import { Grid, List, ListItem, type SxProps, type Theme } from '@mui/material'
import React, { type FC } from 'react'

import { type CybusDetailedEndpoint, Translation } from '../../../../domain'
import { Status } from '../../common'
import { TiniestCopy } from '../../Copy'
import { DetailsEntry } from '../../Details'
import { useTranslator } from '../../Internationalization'
import { ServiceLinkEntry } from '../../Services'

const copyButtonStyle: SxProps<Theme> = { ml: 1 }
const topicsStyle: SxProps<Theme> = { mb: 0.5, p: 0 }
const topicsEntryStyle: SxProps<Theme> = { alignItems: 'baseline' }
const topicsListStyle: SxProps<Theme> = { p: 0 }

export const HeaderDetails: FC<{ resource: CybusDetailedEndpoint }> = ({ resource: { id, service, status, protocol, operation, topics } }) => {
    const translation = useTranslator()

    return (
        <Grid container alignItems="center">
            <Grid id="endpoint-header-description" item xs={7}>
                <DetailsEntry title={Translation.NAME} type="text">
                    {id}
                </DetailsEntry>
                <ServiceLinkEntry service={service} />
                <DetailsEntry title={translation.formatTranslation(Translation.PROTOCOL, { count: 1 })} type="text">
                    {protocol}
                </DetailsEntry>
                <DetailsEntry title={translation.formatTranslation(Translation.OPERATION, { count: 1 })} type="text">
                    {operation}
                </DetailsEntry>
                <DetailsEntry title={Translation.STATUS}>
                    <Status status={status} />
                </DetailsEntry>
                <DetailsEntry sx={topicsEntryStyle} title={translation.formatTranslation(Translation.TOPIC, { count: 2 })}>
                    <List sx={topicsListStyle}>
                        {topics.map((topic) => (
                            <ListItem key={topic} sx={topicsStyle}>
                                {topic}
                                <TiniestCopy sx={copyButtonStyle}>{topic}</TiniestCopy>
                            </ListItem>
                        ))}
                    </List>
                </DetailsEntry>
            </Grid>
        </Grid>
    )
}
