import React, { type FC, useMemo } from 'react'
import { Checkbox, FormControlLabel, type SxProps, type Theme } from '@mui/material'

import { type CybusPermissionOperations } from '../../../../domain'

import { useTranslatedOperationPermission } from '../Hooks'

const labelStyle: SxProps<Theme> = { typography: 'body2' }
const checkboxStyle: SxProps<Theme> = { py: 0 }

const OperationCheckbox: FC<{ title?: string, disabled?: boolean, checked: boolean, label: string, onChange: VoidFunction }> = ({
    disabled,
    checked,
    onChange,
    ...props
}) => (
    <FormControlLabel
        {...props}
        sx={labelStyle}
        control={<Checkbox sx={checkboxStyle} color="primary" size="small" disabled={disabled} checked={checked} onClick={onChange} />}
    />
)

export const OperationsList: FC<
    CybusPermissionOperations & {
        disabled?: boolean
        disabledRead?: boolean
        disabledWrite?: boolean
        readTitle?: string
        writeTitle?: string
        onChange: (operations: CybusPermissionOperations) => void
    }
> = ({ readTitle, writeTitle, disabled, disabledRead, disabledWrite, read, write, onChange }) => {
    const translate = useTranslatedOperationPermission()
    const [readLabel, writeLabel] = useMemo(() => [translate({ read: true, write: false }), translate({ read: false, write: true })], [translate])

    return (
        <>
            <OperationCheckbox
                data-testid="read-input"
                title={readTitle}
                disabled={disabled || disabledRead}
                checked={read}
                label={readLabel}
                onChange={() => onChange({ read: !read, write })}
            />
            <OperationCheckbox
                data-testid="write-input"
                title={writeTitle}
                disabled={disabled || disabledWrite}
                checked={write}
                label={writeLabel}
                onChange={() => onChange({ read, write: !write })}
            />
        </>
    )
}
