export type CapturePoint = Parameters<ErrorConstructor['captureStackTrace']>[1]

export const capture = (error: Error, func: CapturePoint): void => Error.captureStackTrace && Error.captureStackTrace(error, func)

/**
 * Captures error so it appears to be yielded from the given function
 *
 * @param error Error to be thrown
 * @param func artificial error origin
 */
export const throwCaptured = (error: Error, func: CapturePoint): never => {
    try {
        throw error
    } catch (e: unknown) {
        capture(e as Error, func)
        throw e
    }
}
