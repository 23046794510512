import { type CybusDetailedService, ResourceType } from '../../../domain'

import { BulkDeleteResourceUsecase, BulkDisableResourceUsecase, BulkEnableResourceUsecase } from '../Resources'

export class BulkEnableServiceUsecase extends BulkEnableResourceUsecase {
    protected readonly type = ResourceType.SERVICE

    selectServices (ids: CybusDetailedService['id'][] | null): void {
        this.select(ids)
    }

    enable (): Promise<void> {
        return super.enable()
    }
}

export class BulkDeleteServicesUsecase extends BulkDeleteResourceUsecase {
    protected readonly type = ResourceType.SERVICE

    selectServices (ids: CybusDetailedService['id'][] | null): void {
        this.select(ids)
    }

    delete (): Promise<void> {
        return super.delete()
    }
}

export class BulkDisableServicesUsecase extends BulkDisableResourceUsecase {
    protected readonly type = ResourceType.SERVICE

    selectServices (ids: CybusDetailedService['id'][] | null): void {
        this.select(ids)
    }

    disable (): Promise<void> {
        return super.disable()
    }
}
