import React, { type FC } from 'react'

import { Box, type SxProps, type Theme } from '@mui/material'

import { type CybusDetailedService, Translation } from '../../../../domain'

import { FormattedTranslation, useTranslator } from '../../Internationalization'
import { Json, Table } from '../../common'

import { ResourcesList } from './ResourcesList'

const tableWrapperStyle: SxProps<Theme> = { pt: 1 }

export const UserRolesPermissions: FC<Pick<CybusDetailedService['configuredResources'], 'roles' | 'users'>> = ({ users, roles }) => {
    const translator = useTranslator()
    return (
        <ResourcesList title={Translation.AUTHORIZE_ADDITIONAL_USERS_ROLES_PERMISSIONS}>
            <Box data-testid="user-roles-permissions" sx={tableWrapperStyle}>
                <Table
                    columns={{
                        type: { label: Translation.TYPE, sort: true },
                        name: { label: Translation.NAME, sort: true },
                        permissions: {
                            label: translator.formatTranslation(Translation.PERMISSION, { count: 0 }),
                            customCellRender: (permissions) => <Json src={permissions} collapsed />,
                        },
                    }}
                    data={[
                        ...users.map((user) => ({ ...user, type: <FormattedTranslation id={Translation.USER} values={{ count: 1 }} /> })),
                        ...roles.map((user) => ({ ...user, type: <FormattedTranslation id={Translation.ROLE} values={{ count: 1 }} /> })),
                    ]}
                />
            </Box>
        </ResourcesList>
    )
}
