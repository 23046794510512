import React, { type FC } from 'react'
import { Stack, type SxProps, type Theme, Typography } from '@mui/material'

import { ConnectwareError, type ConnectwareLicense, Translation } from '../../../../domain'

import { FormattedDateTime, FormattedTranslation } from '../../Internationalization'
import { CircularLoader } from '../../common'
import { ErrorMessage } from '../../ErrorMessage'
import { LicenseInfo } from './LicenseInfo'
import { NoLicense } from './NoLicense'
import StatusIcon from '../StatusIcon'

const errorStyle: SxProps<Theme> = { py: 2 }
const licenseStatusText: SxProps<Theme> = { ml: 1, color: 'grey.600', lineHeight: 'inherit' }

type Props = Readonly<{ noLicenseId?: string, license: ConnectwareLicense | ConnectwareError | null }>

const Content: FC<Props> = ({ noLicenseId, license }) => {
    if (!license) {
        return <CircularLoader />
    }

    if (ConnectwareError.is(license)) {
        return <ErrorMessage sx={errorStyle} error={license} extras="popover" />
    }

    if (!license.activated) {
        return <NoLicense id={noLicenseId} />
    }

    return (
        <>
            <LicenseInfo title={Translation.LICENSE_NAME}>{license.name}</LicenseInfo>
            <LicenseInfo title={Translation.LICENSE_ACCOUNT}>{license.account}</LicenseInfo>
            <LicenseInfo title={Translation.EXPIRATION_DATE}>
                {license.expiration && <FormattedDateTime date={license.expiration} format="dateOnly" />}
            </LicenseInfo>
            <LicenseInfo
                title={Translation.LICENSE_ID}
                additionalInfo={
                    <Stack direction="row" alignItems="center" className="license-status">
                        <StatusIcon status={license.valid ? 'GREEN' : 'RED'} />
                        <Typography variant="caption" sx={licenseStatusText}>
                            <FormattedTranslation id={license.valid ? Translation.VALID : Translation.INVALID} />
                        </Typography>
                    </Stack>
                }
            >
                {license.id}
            </LicenseInfo>
            <LicenseInfo title={Translation.LICENSE_CLASS}>{license.class}</LicenseInfo>
        </>
    )
}

export const Details: FC<Props & { className?: string }> = ({ className, ...props }) => (
    <div className={className}>
        <Content {...props} />
    </div>
)
