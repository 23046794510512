import React, { type FC } from 'react'

import { Box } from '@mui/material'

import { BufferType, Translation } from '../../../../domain'

import { type BaseCommonProps, Json, NoReRenderOnHover } from '..'
import { FormattedTranslation } from '../../Internationalization'

interface PayloadFormatsMap extends Record<BufferType, unknown> {
    [BufferType.OBJECT]: Record<string, unknown>
    [BufferType.HEX]: string
    [BufferType.STRING]: string
    [BufferType.LARGE_BUFFER]: string
    [BufferType.NUMBER]: number
}

const formatPayload = <T extends BufferType,>(payload: Buffer, type: T): PayloadFormatsMap[T] => {
    switch (type) {
        case BufferType.OBJECT:
            return JSON.parse(payload.toString()) as PayloadFormatsMap[T]
        case BufferType.HEX:
            return payload.toString('hex') as PayloadFormatsMap[T]
        case BufferType.NUMBER:
            return Number(payload.toString()) as PayloadFormatsMap[T]
        case BufferType.LARGE_BUFFER:
        default:
            return payload.toString() as PayloadFormatsMap[T]
    }
}

type Props = Readonly<{ buffer: Buffer, type: BufferType, minimized?: boolean }> & BaseCommonProps

export const FormattedBuffer: FC<Props> = ({ className, buffer, type, minimized = false, sx }) => {
    if (type === BufferType.OBJECT) {
        return (
            <NoReRenderOnHover>
                <Json src={formatPayload(buffer, type)} displayObjectSize={false} collapsed={minimized ? true : 1} />
            </NoReRenderOnHover>
        )
    }

    const value = String(formatPayload(buffer, type))

    return (
        <Box sx={sx} className={className} title={type === BufferType.LARGE_BUFFER ? undefined : value}>
            {value}
            {type === BufferType.LARGE_BUFFER && (
                <b>
                    <>{' ...'}</>
                    <FormattedTranslation id={Translation.BUFFER_TOO_LARGE_TO_BE_DISPLAYED} />
                </b>
            )}
        </Box>
    )
}
