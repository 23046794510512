import React, { type FC, useMemo } from 'react'
import { DataObject, ScatterPlot, Topic } from '@mui/icons-material'

import { RuleEngineType, Translation } from '../../../../domain'

import { SplitButton } from '../../common'
import { useAppUsecase } from '../../State'
import { useTranslator } from '../../Internationalization'
import { useSelectedType } from './Hooks'

export const TypeDropdown: FC = () => {
    const filter = useAppUsecase('filterSandboxUsecase')
    const type = useSelectedType()
    const translator = useTranslator()

    const options = useMemo(
        () => [
            { label: translator.formatTranslation(Translation.JSON), value: RuleEngineType.JSON, icon: DataObject },
            { label: translator.formatTranslation(Translation.ENDPOINT, { count: 1 }), value: RuleEngineType.ENDPOINT, icon: ScatterPlot },
            { label: translator.formatTranslation(Translation.MQTT_TOPIC), value: RuleEngineType.MQTT_TOPIC, icon: Topic },
        ],
        [translator]
    )

    return (
        <SplitButton
            id="rule-engine-type"
            options={options}
            selected={options.find((o) => o.value === type)}
            onChange={({ value }) => filter.selectType(value)}
        />
    )
}
