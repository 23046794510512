import React, { type FC } from 'react'

import { SvgIcon, type SvgIconProps } from '@mui/material'

export const Cybus: FC<SvgIconProps> = (props) => (
    <SvgIcon viewBox="0 0 137 170" {...props}>
        <path fill="#fbb03b" d="m 30.9,64 40.7,63.6 -23.6,0 -47.1,-62.1 0,-3 L 48,0.4 71.6,0.4 30.9,64 l 0,0 z" />
        <path fill="#fbb03b" d="M 106.8,106.8 66,43.3 l 23.6,0 47.1,62.1 0,3 -47.1,62.1 -23.6,0 40.8,-63.7 0,0 z" />
    </SvgIcon>
)
