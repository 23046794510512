import { ConnectwareError, ConnectwareErrorType, type CybusDetailedEndpoint, type CybusEndpoint } from '.'
import type { Loadable } from './Loadable'

type RuleEngineExtras = Readonly<{ messagePrefix: string | null, messageDetails: string, ruleName: string, rule: Record<string, unknown>, payload: unknown }>

export class CybusRuleEngineExecutionError extends ConnectwareError<ConnectwareErrorType.SERVER_ERROR, RuleEngineExtras> {
    static is (e: unknown): e is CybusRuleEngineExecutionError {
        return e instanceof CybusRuleEngineExecutionError
    }

    constructor (extras: RuleEngineExtras) {
        super(ConnectwareErrorType.SERVER_ERROR, 'There was an issue with executing the rule engine', extras)
    }

    get messagePrefix (): string | null {
        return this.extras.messagePrefix
    }

    get messageDetails (): string {
        return this.extras.messageDetails
    }

    get ruleName (): string {
        return this.extras.ruleName
    }

    get rule (): Record<string, unknown> {
        return this.extras.rule
    }

    get payload (): unknown {
        return this.extras.payload
    }
}

export enum RuleEngineType {
    ENDPOINT = 'ENDPOINT',
    JSON = 'JSON',
    MQTT_TOPIC = 'MQTT_TOPIC',
}

export type RuleEngineEndpoint = Pick<CybusEndpoint & CybusDetailedEndpoint, 'id' | 'name' | 'connection' | 'service' | 'topics'>

export type RuleEngineSandbox = Readonly<{
    /** The selected type of the whole rule engine sandbox */
    type: RuleEngineType

    /** Endpoints to be loaded/selected */
    endpoints: Loadable<RuleEngineEndpoint[]>
    selectedEndpoints: RuleEngineEndpoint['id'][] | null

    /** The topics being selected */
    selectedTopics: string[] | null

    /** The inputted json */
    input: Readonly<{ loaded: boolean, topic: string | null, value: unknown | null }>

    /** The transformation the user inputted */
    transformation: string

    /** The outputted json */
    output: Readonly<{ loaded: boolean, id: number, value: unknown | ConnectwareError | CybusRuleEngineExecutionError | null }>
}>

export type RuleEngineAppState = Readonly<{ ruleEngineSandbox: RuleEngineSandbox }>

export const selectSandbox = ({ ruleEngineSandbox }: RuleEngineAppState): RuleEngineSandbox => ruleEngineSandbox
