import { areArrayEquals, areRecordsEquals, type Comparators, createArrayComparator, createNamedEqualityChecker } from '../../utils'
import { StatusType } from '../Status'

type BaseCybusEndpoint = Readonly<{ id: string, name: string, service: string | null, protocol: string, operation: string, status: StatusType }>

export type CybusEndpoint = Readonly<{ connection: string | null }> & BaseCybusEndpoint

export type CybusDetailedEndpoint = Readonly<{ address: Record<string, number>, rules: unknown[] | null, topics: string[] }> & BaseCybusEndpoint

export const canEndpointBeEnabled = (endpoint: Pick<CybusEndpoint, 'status'>): boolean => endpoint.status === StatusType.DISABLED
export const canEndpointBeDisabled = (endpoint: Pick<CybusEndpoint, 'status'>): boolean => endpoint.status === StatusType.ENABLED

const baseEndpointComparators: Comparators<BaseCybusEndpoint> = { id: null, name: null, service: null, protocol: null, operation: null, status: null }

export const areCybusEndpointEquals = createNamedEqualityChecker<CybusEndpoint>({ ...baseEndpointComparators, connection: null }, 'areCybusEndpointEquals')

export const areDetailedCybusEndpointEquals = createNamedEqualityChecker<CybusDetailedEndpoint>(
    {
        ...baseEndpointComparators,
        address: areRecordsEquals,
        rules: (a, b) => Array.isArray(a) && Array.isArray(b) && areArrayEquals(a, b, { sort: false }),
        topics: createArrayComparator(),
    },
    'areDetailedCybusEndpointEquals'
)
