import { createArrayComparator, createEqualityChecker, createNamedEqualityChecker, createPrimitiveValueComparator } from '../../utils'

import {
    arePermissionArrayEquals,
    type CybusPersistedPermission,
    type CybusUser,
    type CybusUserAuthenticationMethod,
    isPermissionValid,
    type UserForm,
} from '..'

import type { Loadable } from '../Loadable'

export type ClientRegistryRequest = Pick<CybusUser, 'username' | 'roles'> &
    Readonly<{
        description: string
        timestamp: Date
        authenticationMethod: CybusUserAuthenticationMethod
        permissions: CybusPersistedPermission[]
    }>

export type ClientRegistryAuthorizationRequest = Pick<CybusUser, 'username' | 'mqttPublishPrefix' | 'authenticationMethods'> &
    Pick<UserForm, 'roles' | 'permissions'>

export type ClientRegistryRequestForm = Pick<CybusUser, 'username' | 'mqttPublishPrefix' | 'authenticationMethods'> &
    UserForm &
    Readonly<{ request: ClientRegistryRequest }>

export type EditableClientRegistryRequestForm = Pick<ClientRegistryRequestForm, 'mqttPublishPrefix' | 'authenticationMethods' | 'roles' | 'permissions'>

export type ClientRegistryData = Readonly<{ unlockedUntil: Date | null, requests: ClientRegistryRequest[] }>

export type ClientRegistry = Readonly<{ locked: boolean | null, data: Loadable<ClientRegistryData> }>

export const areClientRegistryRequestFormsEqual = createNamedEqualityChecker<EditableClientRegistryRequestForm>(
    { mqttPublishPrefix: null, roles: createArrayComparator(), permissions: arePermissionArrayEquals, authenticationMethods: createArrayComparator() },
    'areClientRegistryRequestFormsEqual'
)

export const isClientRegistryRequestCreatable = ({ permissions, authenticationMethods }: ClientRegistryRequestForm): boolean =>
    authenticationMethods.length > 0 && permissions.every(isPermissionValid)

const areClientRegistryRequestEquals = createEqualityChecker<ClientRegistryRequest>({
    username: null,
    description: null,
    timestamp: createPrimitiveValueComparator((t) => t.getTime()),
    authenticationMethod: null,
    roles: createArrayComparator(),
    permissions: arePermissionArrayEquals,
})

export const areClientRegistryDataEquals = createNamedEqualityChecker<ClientRegistryData>(
    { unlockedUntil: createPrimitiveValueComparator((t) => t?.getTime()), requests: createArrayComparator(areClientRegistryRequestEquals) },
    'areClientRegistryDataEquals'
)
