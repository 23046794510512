import { createTimeout } from '.'

abstract class BaseThrottler<Cancellable> {
    /**
     * @param interval in milliseconds
     */
    constructor (protected readonly interval: number) {}

    /**
     * Schedule call to the given callback
     *
     * It will call the method after the given interval
     *
     * Repeated calls will be ignored
     */
    abstract run (callback: VoidFunction): Cancellable
}

/**
 * Utility class to throttle repeated calls
 */
export class Throttler extends BaseThrottler<VoidFunction | null> {
    private cancel = false

    run (callback: VoidFunction): VoidFunction | null {
        if (this.cancel) {
            /**
             * Timer was already set, ignore next ones until is clears
             */
            return null
        }

        this.cancel = true

        return createTimeout(() => {
            try {
                callback()
            } finally {
                this.cancel = false
            }
        }, this.interval)
    }
}

/**
 * Utility class to throttle calls after they are no longer being made
 */
export class BelatedThrottler extends BaseThrottler<VoidFunction> {
    private cancel: VoidFunction | null = null

    run (callback: VoidFunction): VoidFunction {
        this.cancel?.()
        this.cancel = createTimeout(callback, this.interval)
        return this.cancel
    }
}
