import {
    Box,
    Divider,
    InputAdornment,
    inputBaseClasses,
    Stack,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    svgIconClasses,
    type SxProps,
    TextField,
    type Theme,
    Typography,
} from '@mui/material'
import React, { type FC, type ReactNode } from 'react'

import { ConnectwareError, isOtpValid, type MfaSettings, type MfaSettingsState, Translation } from '../../../domain'

import { FormattedTranslation } from '../Internationalization'
import { ErrorMessage } from '../ErrorMessage'
import { TiniestCopy } from '../Copy'
import { useAppUsecase } from '../State'
import { CircularLoader, QRCode } from '../common'
import { ActionButton, OtpField } from './Common'

const dividerStyle: SxProps<Theme> = { my: 3 }

const Steps: FC<{ title: Translation, subTitle: Translation, children: ReactNode[] }> = ({ title, subTitle, children }) => (
    <Stepper orientation="vertical" connector={null}>
        {children.map((child, k) => (
            <Step key={k} active last>
                {k > 0 && <Divider variant="fullWidth" sx={dividerStyle} />}
                <StepLabel
                    optional={
                        <Typography variant="body2">
                            <FormattedTranslation id={subTitle} values={{ step: k + 1 }} />
                        </Typography>
                    }
                >
                    <FormattedTranslation id={title} values={{ step: k + 1 }} />
                </StepLabel>
                <StepContent>{child}</StepContent>
            </Step>
        ))}
    </Stepper>
)

const secondStepTextTitleStyle: SxProps<Theme> = { mb: 1 }
const copyFieldStyle: SxProps<Theme> = {
    [`& .${svgIconClasses.root}`]: { verticalAlign: 'middle' },
    [`& .${inputBaseClasses.input}`]: { textOverflow: 'ellipsis' },
}

const FirstEnablingStep: FC<Pick<MfaSettings<MfaSettingsState.ENABLING>, 'activation'>> = ({ activation }) => {
    if (activation === null) {
        return <CircularLoader data-testid="mfa-activation-metadata-loader" />
    }

    if (ConnectwareError.is(activation)) {
        return <ErrorMessage data-testid="mfa-activation-metadata-error" error={activation} extras="section" />
    }

    return (
        <Stack direction="row" spacing={3}>
            <QRCode data-testid="mfa-activation-metadata-qrcode" value={activation.uri.toString()} />
            <Divider orientation="vertical" flexItem />
            <Box>
                <Typography sx={secondStepTextTitleStyle}>
                    <FormattedTranslation id={Translation.MFA_MFA_SECRET_KEY_LABEL} />
                </Typography>
                <TextField
                    data-testid="mfa-activation-metadata-secret-input"
                    sx={copyFieldStyle}
                    variant="outlined"
                    size="small"
                    defaultValue={activation.secret}
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <TiniestCopy>{activation.secret}</TiniestCopy>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
        </Stack>
    )
}

const EnablingOtpField: FC<Pick<MfaSettings<MfaSettingsState.ENABLING>, 'activation' | 'otp'>> = ({ activation, otp }) => {
    const usecase = useAppUsecase('enableMfaUsecase')
    return (
        <OtpField
            id="mfa-enabling-otp-input"
            disabled={activation === null || ConnectwareError.is(activation)}
            value={otp}
            onChange={(value) => usecase.setOtp(value)}
        />
    )
}

export const Enabling: FC<MfaSettings<MfaSettingsState.ENABLING>> = ({ activation, otp, error }) => (
    <>
        <Steps title={Translation.MFA_ENABLING_STEPS_TITLE} subTitle={Translation.MFA_ENABLING_STEPS_SUBTITLE}>
            <FirstEnablingStep activation={activation} />
            <EnablingOtpField activation={activation} otp={otp} />
        </Steps>
        <ActionButton
            data-testid="mfa-enabling-save-configuration"
            usecase="enableMfaUsecase"
            method="confirm"
            error={error}
            label={Translation.MFA_SAVE_CONFIGURATION}
            disabled={!isOtpValid(otp)}
        />
    </>
)
