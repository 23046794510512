import { createIntl, type IntlShape, type OnErrorFn } from '@formatjs/intl'

import { type ReadonlyRecord } from '../../utils'
import { ConnectwareError, ConnectwareErrorType, type Language, type Translation } from '../../domain'

import type { LoggerService, TranslationService } from '../../application'

const locale = 'en-de'

export type Internationalizations = ReadonlyRecord<Language, () => IntlShape<string>>

export const createTranslationInternationalization = (translation: Record<Translation, string>, logger: LoggerService): IntlShape<string> => {
    const onError: OnErrorFn = (e) =>
        logger.error(
            new ConnectwareError(ConnectwareErrorType.TRANSLATIONS, e.message.trim(), {
                code: e.code,
                descriptor: ('descriptor' in e && e.descriptor) || null,
            })
        )

    return createIntl({ messages: translation, locale, onError })
}

/**
 * Translation patterns should be as seen here `https://formatjs.io/docs/intl-messageformat`
 */
export class FormatJSTranslationService implements TranslationService {
    constructor (private readonly intls: IntlShape<string>) {}

    private get intl (): IntlShape<string> {
        return this.intls
    }

    list (values: string[]): string {
        return this.intl.formatList(values)
    }

    translate (id: Translation, values?: Record<string, unknown>): string {
        return this.intl.formatMessage({ id }, values as Record<string, string>)
    }
}
