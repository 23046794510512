import type { PickByValue, Primitive } from 'utility-types'

import { createEqualityChecker } from '../utils'

export type SortableColumn<T> = keyof PickByValue<T, Primitive | Date> & string

export type SortConfiguration<T> = Readonly<{ asc: boolean, column: SortableColumn<T> }>

export const areSortConfigurationEquals: <T>(a: SortConfiguration<T>, b: SortConfiguration<T>) => boolean = createEqualityChecker<SortConfiguration<unknown>>({
    asc: null,
    column: null,
}) as <T>(a: SortConfiguration<T>, b: SortConfiguration<T>) => boolean
