import React, { type FC, useMemo } from 'react'
import { Box, Stack, type SxProps, type Theme, Typography } from '@mui/material'

import { Translation } from '../../../domain'

import { useAppUsecase } from '../State'
import { FormattedTranslation } from '../Internationalization'
import { LeftContent, leftContentWidths } from '../LeftContent'
import { Background } from '../common'

import { LoginForm } from './LoginForm'

const backgroundStyle: SxProps<Theme> = { height: '100vh', width: `calc(100vw - ${leftContentWidths.regular}px)` }
const wrapperStyle: SxProps<Theme> = { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', px: 5, pb: 5, height: '100%' }

const Version: FC = () => {
    const usecase = useAppUsecase('versionUsecase')
    const version = useMemo(() => usecase.getUIVersion(), [usecase])

    return (
        <Typography id="connectware-version" variant="body2" color="white">
            <FormattedTranslation id={Translation.VERSION} /> {version}
        </Typography>
    )
}

export const Wrapper: FC = () => (
    <Stack direction="row">
        <LeftContent mode="regular">
            <Box sx={wrapperStyle}>
                <Typography id="connectware-slogan" variant="h3" color="white">
                    <FormattedTranslation id={Translation.CONNECTWARE_SLOGAN} />
                </Typography>
                <LoginForm />
                <Version />
            </Box>
        </LeftContent>
        <Box sx={backgroundStyle}>
            <Background />
        </Box>
    </Stack>
)
