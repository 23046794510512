import type { CybusAgent, SortableColumn } from '../../../domain'

import { ResourcePageSubscriptionUsecase } from '../Resources'

export class ManageAgentsUsecase extends ResourcePageSubscriptionUsecase<'agents'> {
    protected readonly pageName = 'agents'

    protected readonly pageAddress = 'agentsPage'

    protected readonly initialSortColumn: SortableColumn<CybusAgent> = 'name'
}
