import React, { type FC, useEffect } from 'react'
import { Box } from '@mui/material'

import { ConnectwareError, selectBackupMaintenance, Translation } from '../../../domain'

import { useAppState, useAppUsecase } from '../State'
import { FormattedTranslation } from '../Internationalization'
import { CircularLoader, DetailsHeader } from '../common'
import { ErrorMessage } from '../ErrorMessage'

import { Backup } from './Backup'
import { Restore } from './Restore'

export const MaintenanceStatusPage: FC = () => {
    const usecase = useAppUsecase('systemMaintenanceUsecase')
    // eslint-disable-next-line no-void
    useEffect(() => void usecase.loadMaintenanceStatus(), [usecase])

    const maintenance = useAppState(selectBackupMaintenance)

    if (!maintenance) {
        return <CircularLoader />
    }

    if (ConnectwareError.is(maintenance)) {
        return <ErrorMessage error={maintenance} stack extras="section" />
    }

    return (
        <Box id="backup-restore-page">
            <DetailsHeader
                title={{ title: <FormattedTranslation id={Translation.BACKUP_AND_RESTORE_TITLE} /> }}
                section={{ title: Translation.BACKUP_AND_RESTORE_DOCUMENTATION_TITLE, body: Translation.BACKUP_AND_RESTORE_DOCUMENTATION_BODY }}
            />
            <Backup backup={maintenance.backup} />
            <Restore restore={maintenance.restore} />
        </Box>
    )
}
