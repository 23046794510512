import React, { type FC, useEffect, useMemo } from 'react'
import { ifUnresolved, usePromise } from 'react-sync-promise'

import { type NullableValues } from '../../../../utils'
import { type EndpointConnectivity, selectLicense, Translation } from '../../../../domain'

import { useAppState, useAppUsecase } from '../../State'
import { FormattedTranslation } from '../../Internationalization'
import { DetailsHeader } from '../../common'

import { LicenseDetails } from '..'
import { useCanUpdateLicense } from '../Hooks'
import { RefreshLicenseButton, UploadLicenseFileButton } from '../Buttons'
import { PortalReachabilityError } from './Connectivity'
import { Services } from './Services'
import { InternetConnectivity } from './InternetConnectivity'

const useIsPortalReachable = (): NullableValues<EndpointConnectivity> | null => {
    const licenseView = useAppUsecase('licenseViewUsecase')

    const promise = useMemo(() => licenseView.fetchPortalConnectivity(), [licenseView])
    return ifUnresolved(usePromise(promise), null)
}

export const InfoPage: FC = () => {
    const canUpdateLicense = useCanUpdateLicense()
    const reachable = useIsPortalReachable()
    const license = useAppState(selectLicense)

    const infoUsecase = useAppUsecase('informationUsecase')
    useEffect(() => infoUsecase.loadInformation(), [infoUsecase])

    return (
        <>
            <DetailsHeader
                title={{
                    title: <FormattedTranslation id={Translation.INFO_VIEW_TITLE} />,
                    actions: (
                        <>
                            <RefreshLicenseButton disabled={!canUpdateLicense || reachable === null || !reachable.reachable} />
                            <UploadLicenseFileButton disabled={!canUpdateLicense} />
                        </>
                    ),
                }}
                section={{ title: Translation.INFO_PAGE_DOCUMENTATION_TITLE, body: Translation.INFO_PAGE_DOCUMENTATION_BODY }}
            />

            <PortalReachabilityError connectivity={reachable} />

            <LicenseDetails className="license-page" noLicenseId="info-license-activation" license={license} />

            <Services />

            <InternetConnectivity />
        </>
    )
}
