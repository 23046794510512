import type { CybusService } from '../../../../../../domain'

import { Capability } from '../../../../../../domain'
import { type CybusServiceProxyInfo, mapDeviatedServiceIds } from '../../../../../Connectware'

import { BaseSubscriptionStrategy } from '../Base'

export class DeviationsStrategy extends BaseSubscriptionStrategy<'deviations'> {
    readonly requiredPermissions = [Capability.DEVIATIONS_READ]

    retrieveAll (): Promise<CybusService['id'][]> {
        return this.retrieve({
            capability: Capability.DEVIATIONS_READ,
            method: 'GET',
            path: '/api/services/info',
            mapper: (data: CybusServiceProxyInfo[]) => mapDeviatedServiceIds(data),
        })
    }
}
