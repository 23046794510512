import React, { type FC } from 'react'
import { Box, Container, type SxProps, type Theme } from '@mui/material'

import { Breadcrumbs } from './Breadcrumbs'

const wrapperStyle: SxProps<Theme> = { width: '100%', background: 'white' }
const containertyle: SxProps<Theme> = { p: 4, mx: 'initial' }

export const RouteNavigation: FC = ({ children }) => (
    <Box sx={wrapperStyle}>
        <Container maxWidth="lg" disableGutters sx={containertyle}>
            <Breadcrumbs />
            {children}
        </Container>
    </Box>
)
