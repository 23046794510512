import React, { type FC, type HTMLAttributes, useEffect, useState } from 'react'
import { Box } from '@mui/material'

import { type BaseCommonProps } from '../Base'

type RendererProps = Pick<HTMLAttributes<HTMLDivElement>, 'className' | 'style' | 'onMouseEnter' | 'onMouseLeave' | 'children'> & Pick<BaseCommonProps, 'sx'>
type Props = Readonly<{ renderer?: FC<RendererProps> }> & Pick<RendererProps, 'className' | 'style' | 'sx'>

const defaultRenderer: FC<RendererProps> = (props) => <Box {...props} />

/**
 * This prevents re-render if there the user is hovering this component
 */
export const NoReRenderOnHover: FC<Props> = ({ renderer: Renderer = defaultRenderer, children, ...props }) => {
    const [hovering, setHovering] = useState(false)
    const [updatedChildren, updateChildren] = useState(() => children)

    useEffect(() => {
        if (!hovering) {
            updateChildren(children)
        }
    }, [children, hovering])

    return (
        <Renderer {...props} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
            {updatedChildren}
        </Renderer>
    )
}
