import type { SubscriptionFilterArgs } from '../../../../../../application'
import type { ContainerManagerOrchestratorProxy } from '../../../../proxies'
import { listenToProxy, type UnsubFromInstanceFunction, type VrpcInstanceMapper } from '..'
import { fetchImplementationResolver } from './Implementation'

export abstract class VrpcContainerManagerOrchestratorProxyBaseMapper<T> implements VrpcInstanceMapper<ContainerManagerOrchestratorProxy, T> {
    abstract mapToDomain (instance: ContainerManagerOrchestratorProxy, instanceName: string, filter: SubscriptionFilterArgs): Promise<T | Map<string, T>>

    /**
     * Deals with the setup of the container manager listeners
     */
    async onChange (proxy: ContainerManagerOrchestratorProxy, listener: () => void): Promise<UnsubFromInstanceFunction | void> {
        const resolver = await fetchImplementationResolver(proxy)

        if (resolver.isDocker(proxy)) {
            /** Docker has events to listen */
            return listenToProxy(proxy, ['dockerEvent'], listener)
        }
    }
}
