import React, { type FC, type ReactNode } from 'react'
import { type MUIDataTableColumn } from 'mui-datatables'
import { TableBody, TableCell, TableRow, Typography } from '@mui/material'

import { isEnumOf } from '../../../../utils'

import { Translation } from '../../../../domain'
import { useTranslator } from '../../Internationalization'

import { useTableState } from './Context'
import { type Translations } from './Domain'

export const EmptyTableBody: FC<Readonly<{ selectable: boolean, columns: MUIDataTableColumn[], translations: Translations }>> = ({
    selectable,
    columns,
    translations: { noResultsOrEmptyTable = Translation.NO_RESULTS_FOUND, emptyTable = noResultsOrEmptyTable, noResults = noResultsOrEmptyTable },
}) => {
    const translator = useTranslator()
    const isSearching = useTableState((s) => Boolean(s.search))
    const columnsCount = columns.filter((c) => c.options && c.options.display !== 'false').length

    let target: ReactNode = isSearching ? noResults : emptyTable
    target = isEnumOf(Translation, target) ? translator.formatTranslation(target) : target

    return (
        <TableBody data-testid="empty-table-body">
            <TableRow>
                <TableCell colSpan={selectable ? columnsCount + 1 : columnsCount}>
                    <Typography variant="body1" textAlign="center">
                        {target}
                    </Typography>
                </TableCell>
            </TableRow>
        </TableBody>
    )
}
