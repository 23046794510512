import React, { type FC, type ReactNode, useState } from 'react'
import { Box, Collapse, Divider, type SxProps, type Theme, Typography } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'

import { type Translation } from '../../../../domain'
import { boldFormatter, FormattedTranslation } from '../../Internationalization'

const headerStyle: SxProps<Theme> = { display: 'flex', justifyContent: 'space-between', my: 2 }
const actionStyle: SxProps<Theme> = { display: 'flex', alignItems: 'center', cursor: 'pointer' }

type TitleProps = Readonly<{ title: ReactNode, actions?: ReactNode }>

const Title: FC<TitleProps> = ({ title, actions }) => (
    <Box sx={headerStyle}>
        <Typography variant="h4" component="h4">
            {title}
        </Typography>
        {actions && <Box sx={actionStyle}>{actions}</Box>}
    </Box>
)

const infoWrapperStyle: SxProps<Theme> = { my: 2 }
const infoTitleStyle: SxProps<Theme> = { display: 'flex', mb: 2, color: 'grey.600', cursor: 'pointer' }
const infoIconStyle: SxProps<Theme> = { mr: 1, width: 20, height: 20, mt: 0.2 }
const infoBodyStyle: SxProps<Theme> = { color: 'grey.600', maxWidth: 600, fontSize: 'body2.fontSize', my: 2 }

const formatting = { b: boldFormatter, br: <br /> }

type SectionProps = Readonly<{ title: Translation, body: Translation }>

const Section: FC<SectionProps> = ({ title, body }) => {
    const [opened, setOpen] = useState(false)

    return (
        <Box sx={infoWrapperStyle}>
            <Box data-testid="info-title" sx={infoTitleStyle} onClick={() => setOpen(!opened)}>
                <InfoOutlined sx={infoIconStyle} />
                <Typography>
                    <FormattedTranslation id={title} />
                </Typography>
            </Box>

            <Collapse in={opened} timeout="auto" unmountOnExit>
                <Box data-testid="info-body">
                    <Typography sx={infoBodyStyle}>
                        <FormattedTranslation id={body} values={formatting} />
                    </Typography>

                    <Divider />
                </Box>
            </Collapse>
        </Box>
    )
}

export const DetailsHeader: FC<{ title?: TitleProps, section?: SectionProps }> = ({ title, section }) => (
    <>
        {title && <Title {...title} />}
        {section && <Section {...section} />}
    </>
)
