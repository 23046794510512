import type { CybusHTTPIngressRouteConfiguration, CybusTCPIngressRouteConfiguration } from '../../../../domain'

import { type CommissioningDataResourceProperties, extractReferenceName } from '../..'

export class RoutesMapper {
    readonly tcpRoutes: CybusTCPIngressRouteConfiguration[] = []

    readonly httpRoutes: CybusHTTPIngressRouteConfiguration[] = []

    map (properties: CommissioningDataResourceProperties<'Cybus::IngressRoute'>): void {
        const container = extractReferenceName(properties.container)

        if (properties.type === 'tcp') {
            const { connectwarePort, containerPort } = properties
            this.tcpRoutes.push({ container, connectwarePort, containerPort })
        } else {
            const { slug } = properties
            this.httpRoutes.push({ container, slug })
        }
    }
}
