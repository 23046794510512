import type { PickByValue } from 'utility-types'
import React, { type ConsumerProps, type FC, useCallback, useMemo, useState } from 'react'
import { Button, FormControlLabel, FormGroup, Switch } from '@mui/material'

import {
    selectUserManagementCurrentForm,
    selectUserManagementInitialForm,
    selectUsersManagementTransfering,
    Translation,
    type UsersManagementAppState,
} from '../../../../domain'

import type { Usecases } from '../../../../application'

import { FormattedTranslation } from '../../Internationalization'
import { useAppState, useAppUsecase } from '../../State'
import { useAsyncCallback } from '../../Callback'

import { AnchorProvider } from '../../common'

import { Dialog, type DialogProps } from './Base'
import { DeleteButton, DeletePopover } from './Delete'

type BaseForm = Readonly<{ id: string } | Record<never, never>>

type UsecaseNames = keyof PickByValue<Usecases, BaseUsecase>

type BaseUsecase = Readonly<{
    /**
     * Called when user wishes to close the form
     */
    close(): void

    /**
     * When user submits the form
     *
     * Should handle the closing of the form
     */
    confirm(): Promise<void>

    /**
     * When the user chooses to delete the entity associated with the form
     */
    delete?(id: string): Promise<void>

    /**
     * Simple form update with new data
     */
    update(update: Partial<BaseForm>): void
}>

type InternalProps<Form extends BaseForm, UsecaseName extends UsecaseNames,> = Readonly<{
    title: Translation
    confirmation?: Translation
    deleteConfirmation?: Translation

    /**
     * Form being manipulated
     */
    forms: [initial: Form, current: Form]
    formSelector: (s: UsersManagementAppState) => [Form, Form] | null

    /**
     * Boolean values display the advanced controls
     */
    advanced?: boolean | null

    /**
     * If the form can be reset
     */
    resetable?: boolean

    /**
     * The usecase that will manipulate the form
     */
    usecase: UsecaseName

    /**
     * If the entity associated with the form can be removed
     * No eval function yields a false
     */
    isRemovable?: (form: Form) => boolean

    /**
     * Function to compare forms to check for equality
     */
    areEquals: (a: Form, b: Form) => boolean

    /**
     * If current action can be confirmed
     */
    isConfirmable: (form: Form) => boolean

    /**
     * Action to be taken after confirmation
     */
    onConfirmed?: (form: Form) => void
}> &
    Pick<DialogProps, 'maxWidth'>

export type ManagedDialogContentProps<Form, UsecaseName extends UsecaseNames,> = Readonly<{
    /**
     * If the form as a whole is loading something
     */
    loading: boolean

    /**
     * If it should show advanced data
     */
    advanced: boolean

    /**
     * If form is not customized
     */
    equals: boolean

    /**
     * The form being edited
     */
    form: Form

    /**
     * The usecase that manages the form
     */
    usecase: Usecases[UsecaseName]
}>

const InternalManagedDialog = <Form extends BaseForm, UsecaseName extends UsecaseNames,>({
    title,
    confirmation,
    deleteConfirmation,
    usecase: usecaseName,
    forms,
    advanced: initiallyAdvanced = null,
    resetable = 'id' in selectUserManagementCurrentForm(forms),
    isRemovable,
    areEquals,
    isConfirmable,
    children,
    onConfirmed,
    ...props
}: ConsumerProps<ManagedDialogContentProps<Form, UsecaseName>> & Omit<InternalProps<Form, UsecaseName>, 'formSelector' | 'form'>): ReturnType<FC> => {
    const form = selectUserManagementCurrentForm(forms)
    const initialForm = selectUserManagementInitialForm(forms)

    const usecase: Usecases[UsecaseName] = useAppUsecase(usecaseName)

    const isTransfering = useAppState(selectUsersManagementTransfering)

    const id = 'id' in form ? form.id : null
    const removable = useMemo(() => Boolean(id && isRemovable && isRemovable(form)), [id, form, isRemovable])

    const onClose = useCallback(() => usecase.close(), [usecase])
    const [confirm, confirming, confirmationResult] = useAsyncCallback(async () => {
        await usecase.confirm()
        onConfirmed?.(form)
    }, [usecase, onConfirmed])
    const [remove, removing, removalResult] = useAsyncCallback(async () => {
        if ('delete' in usecase && id) {
            await usecase.delete(id)
        }
    }, [usecase, id])
    const reset = useCallback(() => usecase.update(initialForm), [usecase, initialForm])
    const [advanced, setAdvanced] = useState(initiallyAdvanced)

    const equals = useMemo(() => areEquals(initialForm, form), [areEquals, initialForm, form])

    const loading = confirming || removing || isTransfering

    /**
     * Modal cannot confirm action if
     *
     * - It is already updating
     * - Form has id and current and initial form are the same
     * - Custom confirmation cannot be achieved
     */
    const confirmable = useMemo(() => !(loading || (id && equals)) && isConfirmable(form), [form, isConfirmable, id, equals, loading])

    return (
        <Dialog
            {...props}
            title={<FormattedTranslation id={title} />}
            loading={loading}
            error={(!confirming && confirmationResult) || null}
            easyClose={equals}
            headerSuffix={
                <>
                    {advanced !== null && (
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        data-testid="advanced-mode-switch"
                                        size="small"
                                        checked={advanced}
                                        onClick={() => setAdvanced(!advanced)}
                                        color="primary"
                                    />
                                }
                                label={<FormattedTranslation id={Translation.ADVANCED_MODE} />}
                            />
                        </FormGroup>
                    )}
                    {resetable && (
                        <Button data-testid="reset" color="primary" variant="outlined" size="small" disabled={equals || loading} onClick={reset}>
                            <FormattedTranslation id={Translation.RESET} />
                        </Button>
                    )}
                    {deleteConfirmation && id && (
                        <AnchorProvider>
                            <DeleteButton data-testid="delete" loading={removing} error={removalResult || null} disabled={loading || !removable} />
                            <DeletePopover onConfirm={remove}>
                                <FormattedTranslation id={deleteConfirmation} values={form} />
                            </DeletePopover>
                        </AnchorProvider>
                    )}
                </>
            }
            actionsSuffix={
                <Button data-testid="confirm" color="primary" variant="outlined" disabled={!confirmable || loading} onClick={confirm}>
                    <FormattedTranslation id={confirmation || (id ? Translation.UPDATE : Translation.CREATE)} />
                </Button>
            }
            onClose={onClose}
        >
            {children({ loading, advanced: advanced === true, equals, form, usecase })}
        </Dialog>
    )
}

export const ManagedDialog = <Form extends BaseForm, UsecaseName extends UsecaseNames,>({
    formSelector,
    ...props
}: ConsumerProps<ManagedDialogContentProps<Form, UsecaseName>> & Omit<InternalProps<Form, UsecaseName>, 'forms'>): ReturnType<FC> => {
    const forms = useAppState(formSelector, [formSelector])
    return forms && <InternalManagedDialog forms={forms} {...props} />
}
