import {
    Capability,
    ConnectwareError,
    ConnectwareErrorType,
    createIsAuthenticatedWithCapabilitiesSelector,
    selectIsAuthenticated,
    selectLicense,
    Translation,
} from '../../../domain'

import { Usecase } from '../Usecase'

const canReadSystemInfo = createIsAuthenticatedWithCapabilitiesSelector(Capability.MINIMUM)

export class ReloadLicenseUsecase extends Usecase {
    private mapLicenseChangeError (e: ConnectwareError): never | void {
        if (!ConnectwareError.isOfTypes(e, ConnectwareErrorType.CONFIGURATION_ERROR)) {
            /**
             * If error is not of configuration type
             * it means some other issue occurred
             * while changing the license
             * so just pass it on
             */
            throw e
        }

        /**
         * We then check if the license was invalid before
         */
        const license = selectLicense(this.getState())
        if (!ConnectwareError.is(license) && license?.valid) {
            /**
             * The license was valid and it is now invalid
             * So we let the update (from `reloadLicense`) follow through
             */
            return
        }

        /**
         * The license was invalid before this update
         * So we through another error stating so
         */
        throw new ConnectwareError(e, this.translationService.translate(Translation.LICENSE_STILL_INVALID))
    }

    protected async reloadLicense (): Promise<void> {
        const state = this.getState()

        if (!selectIsAuthenticated(state)) {
            return
        }

        if (!canReadSystemInfo(state)) {
            this.setState({ license: new ConnectwareError(ConnectwareErrorType.AUTHENTICATION) })
            return
        }

        try {
            this.setState({ license: await this.systemService.fetchLicense() })
        } catch (e: unknown) {
            this.setState({ license: e as ConnectwareError })
        }
    }

    protected async changeLicense (action: () => Promise<void>): Promise<void> {
        try {
            await action()
        } catch (e: unknown) {
            this.mapLicenseChangeError(e as ConnectwareError)
        }
        await this.reloadLicense()
    }
}
