import type { VrpcProxy } from 'vrpc'

import { ConnectwareError, ConnectwareErrorType } from '../../../domain'
import { isInstanceRetrievalError, isTimeoutError, type ManagedVrpcRemote } from '.'

/**
 * Gets referenced instance
 *
 * @throws `ConnectwareError` with the types `NOT_FOUND`, `SERVER_ERROR`, `UNEXPECTED`
 */
export const getInstance = async <P extends VrpcProxy>(remote: ManagedVrpcRemote, ...args: Parameters<ManagedVrpcRemote['getInstance']>): Promise<P> => {
    try {
        return await remote.getInstance<P>(...args)
    } catch (e: unknown) {
        if (isInstanceRetrievalError(e)) {
            throw new ConnectwareError(ConnectwareErrorType.NOT_FOUND, 'Could not retrieve instance', { args })
        }

        if (isTimeoutError(e)) {
            throw new ConnectwareError(ConnectwareErrorType.SERVER_TIMEOUT, 'There was a time-out while retrieving the instance', { args })
        }

        throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Could not retrieve instance because of an unknown reason', {
            args,
            original: (e as Error).message,
        })
    }
}
