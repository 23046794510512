import React, { type FC } from 'react'
import { FormattedDate } from 'react-intl'
import { Box, Button, type SxProps, type Theme, Typography } from '@mui/material'
import { AccessTime } from '@mui/icons-material'

import { type BackupMaintenanceStatus, ConnectwareError, MissingBackupFileError, Translation } from '../../../domain'
import { ErrorMessage } from '../ErrorMessage'
import { FormattedDateTime, FormattedTranslation, useTranslator } from '../Internationalization'
import { useAppUsecase } from '../State'
import { useAsyncCallback } from '../Callback'
import { MaintenanceSection } from './Section'

const downloadButtonStyle: SxProps<Theme> = { ml: 2, p: 0 }
const backupCreatedStyle: SxProps<Theme> = { display: 'flex', alignItems: 'center', mt: 1 }

export const Backup: FC<Pick<BackupMaintenanceStatus, 'backup'>> = ({ backup }) => {
    const translator = useTranslator()

    const systemMaintenance = useAppUsecase('systemMaintenanceUsecase')

    const [createBackup, loadingCreate, valueCreate] = useAsyncCallback(() => systemMaintenance.createBackup(), [systemMaintenance])
    const [downloadBackup, loadingDownload, valueDownload] = useAsyncCallback(() => systemMaintenance.downloadBackup(), [systemMaintenance])

    return (
        <MaintenanceSection
            title={Translation.BACKUP_TITLE}
            description={Translation.BACKUP_DESCRIPTION}
            buttonProps={{
                className: 'createButton',
                icon: AccessTime,
                label: backup ? Translation.RENEW_BACKUP : Translation.CREATE_BACKUP_BUTTON,
                onClick: createBackup,
            }}
            loading={loadingCreate || loadingDownload}
        >
            {!loadingCreate &&
                !loadingDownload &&
                (backup instanceof Date ? (
                    <Box sx={backupCreatedStyle}>
                        <Typography data-testid="backup-created">
                            <FormattedTranslation
                                id={Translation.BACKUP_CREATED}
                                values={{
                                    date: <FormattedDate value={backup} />,
                                    time: <FormattedDateTime date={backup} format="timeOnly" />,
                                }}
                            />
                        </Typography>
                        <Button size="small" variant="text" sx={downloadButtonStyle} onClick={downloadBackup} data-testid="download-button">
                            <FormattedTranslation id={Translation.DOWNLOAD} values={{ count: 1 }} />
                        </Button>
                    </Box>
                ) : (
                    <Typography data-testid={backup ? 'backup-file-missing' : 'backup-not-created'}>
                        <FormattedTranslation
                            id={backup ? Translation.BACKUP_FILE_IS_MISSING : Translation.BACKUP_NOT_CREATED}
                            values={{ path: backup?.path }}
                        />
                    </Typography>
                ))}

            {!loadingCreate && ConnectwareError.is(valueCreate) && <ErrorMessage id="backup-create-error" titleVariant="body2" error={valueCreate} />}
            {!loadingDownload && ConnectwareError.is(valueDownload) && (
                <ErrorMessage
                    id="backup-download-error"
                    titleVariant="body2"
                    error={valueDownload}
                    customTitle={(e) =>
                        MissingBackupFileError.is(e) ? translator.formatTranslation(Translation.BACKUP_FILE_IS_MISSING, { path: e.path }) : null
                    }
                />
            )}
        </MaintenanceSection>
    )
}
