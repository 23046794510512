import React, { type FC } from 'react'

import { entries } from '../../../../utils'

import { Capability, type CybusDetailedCoreContainer, type CybusDetailedServiceContainer, type CybusLogSource, Translation } from '../../../../domain'

import { Table } from '../../common'
import Logs, { useAreLogsAvailable } from '../../Logs'
import { Tab, Tabs } from '../../Tabs'

export const Content: FC<Readonly<{ logType: CybusLogSource, resource: CybusDetailedServiceContainer | CybusDetailedCoreContainer }>> = ({
    logType,
    resource: { id, variables, config },
}) => {
    const available = useAreLogsAvailable(logType, id)

    return (
        <Tabs>
            <Tab title={Translation.CONTAINER_LOGS} disabled={!available} requiredCapabilities={[Capability.LOGS_READ]}>
                <Logs id={id} type={logType} showDownloadRawLogs />
            </Tab>
            <Tab title={Translation.CONFIGURATION}>
                <Table
                    data={entries(config).map(([name, value]) => ({ name, value }))}
                    columns={{ name: { label: Translation.NAME, sort: true }, value: { label: Translation.VALUE } }}
                />
            </Tab>
            <Tab title={Translation.ENVIRONMENT_VARIABLE}>
                <Table
                    data={entries(variables).map(([name, value]) => ({ name, value }))}
                    columns={{ name: { label: Translation.NAME, sort: true }, value: { label: Translation.VALUE } }}
                />
            </Tab>
        </Tabs>
    )
}
