import { ConnectwareError, ConnectwareErrorType, type CybusCatalogApplication, selectServiceCatalogApplication } from '../../../../domain'

import { AddServiceUsecase } from '../AddOrUpdate'
import { BaseCatalogUsecase } from './Base'

export class InstallServiceCatalogApplicationUsecase extends BaseCatalogUsecase {
    selectApplication (application: CybusCatalogApplication): void {
        this.setCatalog({ application })
    }

    close (): void {
        this.setCatalog({ application: null })
    }

    start (): Promise<void> {
        const application = selectServiceCatalogApplication(this.getState())

        if (!application) {
            return Promise.reject(new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'User should not be able to attempt to start installation'))
        }

        return this.getUsecase(AddServiceUsecase).startCreationFromCatalog(application)
    }
}
