import { ConnectwareError, ConnectwareErrorType, type CybusDetailedCoreContainer, type CybusDetailedServiceContainer } from '../../../../../../../domain'

import { mapDetailedCoreContainer, mapDetailedServiceContainer } from '../../../../../../Connectware'
import type { ContainerManagerOrchestratorProxy } from '../../../../../proxies'

import type { VrcpEntityHandler, VrpcInstanceMapper } from '../..'
import { VrpcContainerManagerOrchestratorProxyBaseMapper } from '../Base'

import { type ContainerInspectionResponse, coreConfiguration, serviceConfiguration } from './Base'

const mapValidContainer = <C>(
    id: string,
    container: ContainerInspectionResponse,
    mapper: (id: string, container: Exclude<ContainerInspectionResponse, void>) => C
): C => {
    if (!container) {
        throw new ConnectwareError(ConnectwareErrorType.NOT_FOUND, 'Container not found', { id })
    }

    return mapper(id, container)
}

class VrpcContainerManagerOrchestratorProxyToDetailedServiceContainerMapper
    extends VrpcContainerManagerOrchestratorProxyBaseMapper<CybusDetailedServiceContainer>
    implements VrpcInstanceMapper<ContainerManagerOrchestratorProxy, CybusDetailedServiceContainer> {
    async mapToDomain (proxy: ContainerManagerOrchestratorProxy, id: string): Promise<CybusDetailedServiceContainer> {
        return proxy.inspectContainer(id).then((container) => mapValidContainer(id, container, mapDetailedServiceContainer))
    }
}

class VrpcContainerManagerOrchestratorProxyToDetailedCoreContainerMapper
    extends VrpcContainerManagerOrchestratorProxyBaseMapper<CybusDetailedCoreContainer>
    implements VrpcInstanceMapper<ContainerManagerOrchestratorProxy, CybusDetailedCoreContainer> {
    async mapToDomain (proxy: ContainerManagerOrchestratorProxy, id: string): Promise<CybusDetailedCoreContainer> {
        return proxy.inspectContainer(id).then((container) => mapValidContainer(id, container, (i, c) => mapDetailedCoreContainer(i, i, c)))
    }
}

export class VrpcContainerManagerOrchestratorProxyToDetailedServiceContainerHandler
    implements VrcpEntityHandler<ContainerManagerOrchestratorProxy, CybusDetailedServiceContainer> {
    readonly configuration = serviceConfiguration

    readonly remoteMapper = null

    readonly instanceMapper = new VrpcContainerManagerOrchestratorProxyToDetailedServiceContainerMapper()
}

export class VrpcContainerManagerOrchestratorProxyToDetailedCoreContainerHandler
    implements VrcpEntityHandler<ContainerManagerOrchestratorProxy, CybusDetailedCoreContainer> {
    readonly configuration = coreConfiguration

    readonly remoteMapper = null

    readonly instanceMapper = new VrpcContainerManagerOrchestratorProxyToDetailedCoreContainerMapper()
}
