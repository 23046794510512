import type { CybusMappingConfiguration, CybusMappingPubSub, CybusService } from '../../../../domain'

import {
    type CommissioningDataResourceProperties,
    type CybusPubSub,
    extractReferenceName,
    isReferencingResourceFromAnotherService,
    replaceInterServicesReferenceName,
    replaceReferences,
} from '../..'

const mapPubSub = (data: CybusPubSub, serviceId: string): CybusMappingPubSub => ({
    topic: ('topic' in data && replaceReferences(data.topic, { serviceId })) || null,
    externalBroker: data.connection ? extractReferenceName(data.connection) : null,
    endpoint:
        ('endpoint' in data &&
            (!isReferencingResourceFromAnotherService(data.endpoint)
                ? `${serviceId}-${extractReferenceName(data.endpoint)}`
                : replaceInterServicesReferenceName(data.endpoint))) ||
        null,
})

export const mapMappingsConfiguration = (
    { mappings }: CommissioningDataResourceProperties<'Cybus::Mapping'>,
    serviceId: CybusService['id']
): CybusMappingConfiguration[] =>
    mappings.reduce<CybusMappingConfiguration[]>(
        (reduced, { subscribe, publish }) => [
            ...reduced,
            {
                subscribe: (Array.isArray(subscribe) ? subscribe : [subscribe]).map((subscribe) => mapPubSub(subscribe, serviceId)),
                publish: mapPubSub(publish, serviceId),
            },
        ],
        []
    )
