import React, { type FC } from 'react'

import { type ReadonlyRecord } from '../../../utils'
import {
    canConnectionBeConnected,
    canConnectionBeDisconnected,
    Capability,
    createIsAuthenticatedWithCapabilitiesSelector,
    type CybusConnection,
    selectConnectableLoadedDetailedConnections,
    selectConnectionsPage,
    selectDisconnectableLoadedDetailedConnections,
    selectLoadedConnections,
    type StatusType,
    Translation,
} from '../../../domain'
import { RelativeRoutePathWithId } from '../routing'
import { useTranslator } from '../Internationalization'
import { Status, useTableClearSelection } from '../common'
import { DeleteModal, DisableModal, EnableModal } from './Bulk'
import { RedirectingResourcesTable } from '../Resources'

const Modals: FC = () => {
    const clearSelection = useTableClearSelection()

    return (
        <>
            <DeleteModal connectionsSelector={selectLoadedConnections} onConfirmed={clearSelection} />
            <EnableModal connectionsSelector={selectConnectableLoadedDetailedConnections} />
            <DisableModal connectionsSelector={selectDisconnectableLoadedDetailedConnections} />
        </>
    )
}

const selectCanManageConnections = createIsAuthenticatedWithCapabilitiesSelector(Capability.CONNECTIONS_MANAGE)

export const Table: FC<Readonly<{ serviceId?: string }>> = ({ serviceId = null }) => {
    const translator = useTranslator()

    const customCellRender = (status: StatusType): JSX.Element => <Status status={status} />

    return (
        <RedirectingResourcesTable<
            CybusConnection,
            Pick<CybusConnection, 'id' | 'service' | 'name' | 'protocol' | 'status'> & ReadonlyRecord<'hostLabel' | 'portLabel', string | null>,
            'bulkDeleteConnectionsUsecase' | 'bulkDisconnectConnectionsUsecase' | 'bulkConnectConnectionsUsecase'
        >
            subscriptionUsecase="manageConnectionsUsecase"
            filter={serviceId ? { service: serviceId } : undefined}
            data={selectConnectionsPage}
            hasManagementCapabilitiesSelector={selectCanManageConnections}
            dataTableMapper={(connections) =>
                connections.map((c) => ({
                    id: c.id,
                    service: c.service,
                    name: c.name,
                    protocol: c.protocol,
                    hostLabel: c.deviceAddress || c.ipAddress || c.agent || c.localInterface || c.brokers?.join(', ') || c.host,
                    portLabel: c.deviceInstance || c.cncType || c.device || c.port,
                    status: c.status,
                }))
            }
            columns={{
                service: { label: Translation.SERVICE_ID, display: !serviceId, sort: true },
                name: { label: Translation.CONNECTION_ID, sort: true },
                protocol: { label: translator.formatTranslation(Translation.PROTOCOL, { count: 1 }), sort: true },
                hostLabel: { label: translator.formatTranslation(Translation.HOST, { count: 1 }) },
                portLabel: { label: translator.formatTranslation(Translation.PORT, { count: 1 }) },
                status: { label: Translation.STATUS, customCellRender, sort: true },
            }}
            short={Boolean(serviceId)}
            redirectOnRowclick={RelativeRoutePathWithId.CONNECTION}
            onSelect={{
                DELETE: { usecase: 'bulkDeleteConnectionsUsecase' },
                DISABLE: { usecase: 'bulkDisconnectConnectionsUsecase', filter: canConnectionBeDisconnected, label: Translation.DISCONNECT },
                ENABLE: { usecase: 'bulkConnectConnectionsUsecase', filter: canConnectionBeConnected, label: Translation.CONNECT },
                resource: 'id',
                usecaseMethod: 'selectConnections',
            }}
            translations={{ resourceName: Translation.CONNECTION, noResultsOrEmptyTable: Translation.NO_CONNECTIONS_AVAILABLE }}
        >
            <Modals />
        </RedirectingResourcesTable>
    )
}
