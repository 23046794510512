import { areDetailedCybusEndpointEquals, Capability, type CybusDetailedEndpoint } from '../../../../../../domain'
import { type EndpointProxyParams, mapDetailedEndpoint } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

export class DetailedEndpointStrategy extends BaseSubscriptionStrategy<'endpoint'> {
    readonly requiredPermissions = [Capability.ENDPOINT_READ]

    protected readonly areEquals = areDetailedCybusEndpointEquals

    async retrieveOne (id: string): Promise<CybusDetailedEndpoint> {
        return Promise.all<[Promise<EndpointProxyParams>, Promise<string[]>]>([
            this.retrieve({
                capability: Capability.ENDPOINT_READ,
                method: 'GET',
                path: '/api/endpoints/+',
                pathParams: [id],
                mapper: (data: EndpointProxyParams) => data,
                handleNotFound: true,
            }),
            this.retrieve({
                capability: Capability.ENDPOINT_READ,
                method: 'GET',
                path: '/api/endpoints/+/topics',
                pathParams: [id],
                mapper: (data: string[]) => data,
                handleNotFound: true,
            }),
        ]).then(([param, topics]) => mapDetailedEndpoint(param, topics))
    }
}
