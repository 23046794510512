"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createExtendedState = void 0;
var react_1 = __importStar(require("react"));
var ExtendedStateManager_1 = require("../ExtendedStateManager");
var utils_1 = require("../utils");
var normalizeArgs = function (_a) {
    var selector = _a[0], rest = _a.slice(1);
    return ({
        selector: selector,
        filter: rest.find(function (a) { return a instanceof Function; }) || null,
        dependencies: rest.find(function (a) { return Array.isArray(a); }) || [],
    });
};
/**
 * For your sanity
 * Call this outside of react rendering cycles ;)
 */
var createExtendedState = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.ignorePropsChanges, ignorePropsChanges = _c === void 0 ? false : _c;
    var Context = (0, react_1.createContext)(null);
    var getManager = function (caller) {
        var state = (0, react_1.useContext)(Context);
        if (state) {
            return state;
        }
        return (0, utils_1.throwCaptured)(new Error('Must be used with-in a Provider'), caller);
    };
    var Provider = function (_a) {
        var children = _a.children, props = __rest(_a, ["children"]);
        /**
         * The manager is created at the beginning
         * All actions will be applied on to it
         */
        var manager = (0, react_1.useMemo)(function () { return ('value' in props ? new ExtendedStateManager_1.ExtendedStateManager(props.value) : props.manager); }, []);
        if (!ignorePropsChanges) {
            /**
             * If changes to the arguments are made
             * The state is updated
             */
            (0, react_1.useLayoutEffect)(function () {
                var state = 'value' in props ? props.value : props.manager.getState();
                if (state !== manager.getState()) {
                    manager.setState(state);
                }
            }, ['value' in props ? props.value : props.manager]);
        }
        return react_1.default.createElement(Context.Provider, { value: manager }, children);
    };
    var useExtendedState = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        /** Make sure args are properly assigned */
        var _a = normalizeArgs(args), selector = _a.selector, filter = _a.filter, dependencies = _a.dependencies;
        /** First retrieve the context */
        var manager = getManager(useExtendedState);
        /** Aux ref to cheaply carry out the internal behaviours of the hook */
        var firstRender = (0, react_1.useRef)(manager.getState());
        /** Calulate initial value and set it to the result */
        var _b = (0, react_1.useState)(function () { return selector(manager.getState()); }), currentResult = _b[0], setResult = _b[1];
        /** Memoized updater of values, as the dependencies given from above change, so will the setter */
        var updateValue = (0, react_1.useCallback)(function () {
            var newValue = selector(manager.getState());
            /**
             * If the the value being returned is a function
             * Then it needs to be wrapped in another function in order to avoid its execution
             *
             * The reason?
             * https://reactjs.org/docs/hooks-reference.html#functional-updates
             */
            setResult(typeof newValue === 'function' ? function () { return newValue; } : newValue);
        }, __spreadArray([manager], dependencies, true));
        /** Subscribes to further value changes at the first possible moment */
        (0, react_1.useLayoutEffect)(function () { return manager.subscribe(function (previousState, currentState) {
            var previous = selector(previousState);
            var current = selector(currentState);
            if (previous === current) {
                /** Primitive match, no need to check anything else */
                return true;
            }
            /** Custom expensive filter */
            return Boolean(filter && (0, ExtendedStateManager_1.areResultsEqual)(current, previous, filter));
        }, updateValue); }, [manager, updateValue]);
        /** Updates the value if a change in the value occured between the firstRender and subscription */
        (0, react_1.useLayoutEffect)(function () {
            if (firstRender.current !== null && firstRender.current !== manager.getState()) {
                /**
                 * The state was changed between the hook and the layout effect execution
                 * Therefore this needs to be updated
                 */
                updateValue();
            }
        }, [manager, firstRender, updateValue]);
        /** Updates the value if there has been a change to the scope of the value updater */
        (0, react_1.useLayoutEffect)(function () {
            if (firstRender.current === null) {
                /** It is not on the first render, and there are dependencies (which caused later triggers) */
                updateValue();
            }
        }, [manager, updateValue]);
        (0, react_1.useLayoutEffect)(function () {
            /** Mark first render as done */
            firstRender.current = null;
        }, []);
        return currentResult;
    };
    var useExtendedStateDispatcher = function () {
        /**
         * First retrieve the context
         */
        var manager = getManager(useExtendedStateDispatcher);
        return function (newStateOrGenerator) {
            var state = newStateOrGenerator instanceof Function ? newStateOrGenerator(manager.getState()) : newStateOrGenerator;
            manager.setState(state);
        };
    };
    return { Provider: Provider, useExtendedState: useExtendedState, useExtendedStateDispatcher: useExtendedStateDispatcher };
};
exports.createExtendedState = createExtendedState;
