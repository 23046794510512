import type { UsersManagement, UsersManagementAppState } from '..'

export const selectUsersManagement = (s: UsersManagementAppState): UsersManagement => s.usersManagement

const createUsersManagementSelector =
    <P extends keyof UsersManagement>(propName: P): ((s: UsersManagementAppState) => UsersManagement[P]) =>
    (s) =>
        selectUsersManagement(s)[propName]

export const selectUsersManagementRoleCreation = createUsersManagementSelector('roleCreation')

export const selectUsersManagementRoleUpdating = createUsersManagementSelector('roleUpdating')

export const selectUsersManagementRegistryRequest = createUsersManagementSelector('registryRequest')

export const selectUsersManagementUserCreation = createUsersManagementSelector('userCreation')

export const selectUsersManagementUserUpdating = createUsersManagementSelector('userUpdating')

export const selectUsersManagementViewPermission = createUsersManagementSelector('viewPermission')

export const selectUsersManagementSelectedTab = createUsersManagementSelector('selected')

export const selectUsersManagementTransfering = createUsersManagementSelector('transfering')

export const selectUsersManagementRegistry = createUsersManagementSelector('registry')

export const selectUserManagementInitialForm = <T>([initial]: [initial: T, current: T]): T => initial

export const selectUserManagementCurrentForm = <T>([, current]: [initial: T, current: T]): T => current
