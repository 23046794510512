import { entries, type NonEmptyArray, type ReadonlyRecord } from '../../../../utils'
import { Capability, ConnectwareError, ConnectwareErrorType, Translation } from '../../../../domain'

import {
    AbsoluteRouteOnlyPath,
    type AbsoluteRoutePath,
    AbsoluteRoutePathWithId,
    AbsoluteRoutePathWithServiceAndResourceId,
    AbsoluteRoutePathWithServiceId,
    RelativeRoutePathWithId,
} from '../Domain'

type RelativeContext = 'service' | 'resource'

type PathParentConfig<Path> = Readonly<{
    /**
     * The parent of the given route
     */
    path: Path
    /**
     * The translation ready breadcrumb title of the route
     */
    breadcrumbTitle?: Translation
}>

type OnlyPathParentConfig = PathParentConfig<AbsoluteRouteOnlyPath>
type ServicePathParentConfig = PathParentConfig<AbsoluteRoutePathWithServiceId>

type TitleParentConfig = Readonly<{
    /**
     * The parent of the given route
     * Can be nothing or just a stand-in translation
     */
    title: Translation

    /**
     * @description If isTitleSingular is `true`, will pass count of 1, otherwise 0
     * @default false
     */
    isTitleSingular?: true
}>

type BaseRouteConfig<P extends string, E> = Readonly<
    {
        /**
         * The path to be used with-in react-router-dom
         */
        path: `/${P}`

        /**
         * The translation ready name of the page (will recieve a count value when being translated)
         */
        title: Translation

        /**
         * The capabilities needed in order to access the route
         */
        capabilities: NonEmptyArray<ReadonlyRecord<'optional' | 'required', Capability[]>>

        /**
         * When redirecting the user using a relative route
         * This conext needs to be configured, so the user may be taken to the correct place
         */
        relativeContext?: RelativeContext
    } & E
>

type OnlyPathRouteConfig = BaseRouteConfig<
    string,
    {
        /**
         * The translation ready description of the route
         */
        description?: Translation

        /**
         * The possible parent of said route
         */
        parent?: OnlyPathParentConfig | TitleParentConfig
    }
>

type PathWithIdConfig<F extends string, Parent, Context extends RelativeContext> = BaseRouteConfig<
    F,
    {
        /**
         * @description If isTitleSingular is `true`, will pass count of 1, otherwise 0
         * @default false
         */
        isTitleSingular: true

        /**
         * The possible parent of said route
         */
        parent: Parent

        /**
         * Now the context is required to be given
         */
        relativeContext: Context
    }
>

type PathWithResourceIdConfig = PathWithIdConfig<`${string}/:resourceId`, OnlyPathParentConfig, 'resource'>
type PathWithServiceIdConfig = PathWithIdConfig<`${string}/:serviceId`, OnlyPathParentConfig, 'service'>
type PathWithResourceAndServiceIdConfig = PathWithIdConfig<`services/:serviceId/${string}/:resourceId`, ServicePathParentConfig, 'service'>

type Routes = ReadonlyRecord<AbsoluteRouteOnlyPath, OnlyPathRouteConfig> &
    ReadonlyRecord<AbsoluteRoutePathWithId, PathWithResourceIdConfig> &
    ReadonlyRecord<AbsoluteRoutePathWithServiceAndResourceId, PathWithResourceAndServiceIdConfig> &
    ReadonlyRecord<AbsoluteRoutePathWithServiceId, PathWithServiceIdConfig>

/**
 * A lot of configurations duplicate a lot of things
 * This helps keeping them less duplicated
 */
const createDerivedConfigs = <K extends keyof Routes, Base extends keyof Routes[K]>(
    base: Pick<Routes[K], Base>,
    derivations: Record<K, Omit<Routes[K], Base>>
): Pick<Routes, K> => entries(derivations).reduce((r, [route, derivation]) => ({ ...r, [route]: { ...base, ...derivation } }), {} as Pick<Routes, K>)

/**
 * Use this object to define the routes of the application
 * Its a map to be used by 'react-router-dom' and the Connectware react components
 */
const routes: Routes = {
    [AbsoluteRouteOnlyPath.SERVICES]: {
        path: '/services',
        title: Translation.SERVICE,
        description: Translation.NAVIGATION_SERVICES_DESCRIPTION,
        capabilities: [
            {
                required: [Capability.SERVICES_READ],
                optional: [Capability.SERVICE_READ, Capability.SERVICES_MANAGE, Capability.SERVICES_CREATE_OR_UPDATE],
            },
        ],
        relativeContext: 'service',
    },
    ...createDerivedConfigs(
        {
            title: Translation.SERVICE,
            isTitleSingular: true,
            capabilities: [
                {
                    required: [Capability.SERVICE_READ],
                    optional: [
                        Capability.SERVICES_MANAGE,
                        Capability.SERVICES_CREATE_OR_UPDATE,
                        Capability.LOGS_READ,
                        Capability.CONNECTIONS_READ,
                        Capability.SERVICE_CONTAINERS_READ,
                        Capability.ENDPOINTS_READ,
                        Capability.MAPPINGS_READ,
                        Capability.SERVERS_READ,
                        Capability.VOLUMES_READ,
                    ],
                },
            ],
        },
        {
            [AbsoluteRoutePathWithServiceId.SERVICE]: {
                path: '/services/:serviceId',
                parent: { path: AbsoluteRouteOnlyPath.SERVICES, breadcrumbTitle: Translation.SERVICES_OVERVIEW },
                relativeContext: 'service',
            },
            [AbsoluteRoutePathWithId.RESOURCES_SERVICE]: {
                path: '/resources/services/:resourceId',
                parent: { path: AbsoluteRouteOnlyPath.SERVICES_LINKS, breadcrumbTitle: Translation.RESOURCES_OVERVIEW },
                relativeContext: 'resource',
            },
        }
    ),
    [AbsoluteRouteOnlyPath.SERVICE_LOGS]: {
        path: '/service-logs',
        title: Translation.SERVICE_LOGS,
        parent: { path: AbsoluteRouteOnlyPath.SERVICES, breadcrumbTitle: Translation.SERVICES_OVERVIEW },
        capabilities: [{ required: [Capability.LOGS_READ], optional: [] }],
        relativeContext: 'service',
    },
    [AbsoluteRouteOnlyPath.RESOURCES]: {
        path: '/resources',
        title: Translation.RESOURCE,
        description: Translation.NAVIGATION_MAPPINGS_DESCRIPTION,
        capabilities: [
            { required: [Capability.SERVICES_READ], optional: [Capability.SERVICE_READ] },
            { required: [Capability.SERVERS_READ], optional: [Capability.SERVER_READ, Capability.SERVERS_MANAGE] },
            { required: [Capability.SERVICE_CONTAINERS_READ], optional: [Capability.SERVICE_CONTAINER_READ, Capability.SERVICE_CONTAINERS_MANAGE] },
            { required: [Capability.VOLUMES_READ], optional: [Capability.VOLUME_READ, Capability.VOLUMES_MANAGE] },
            { required: [Capability.CONNECTIONS_READ], optional: [Capability.CONNECTION_READ, Capability.CONNECTIONS_MANAGE] },
            { required: [Capability.ENDPOINTS_READ], optional: [Capability.ENDPOINT_READ, Capability.ENDPOINTS_MANAGE] },
            { required: [Capability.MAPPINGS_READ], optional: [Capability.MAPPING_READ, Capability.MAPPINGS_MANAGE] },
        ],
        relativeContext: 'resource',
    },
    [AbsoluteRouteOnlyPath.SERVICES_LINKS]: {
        path: '/resources/services-links',
        title: Translation.SERVICE_LINK,
        description: Translation.NAVIGATION_SERVICES_LINKS_DESCRIPTION,
        parent: { path: AbsoluteRouteOnlyPath.RESOURCES },
        capabilities: [{ required: [Capability.SERVICES_READ], optional: [Capability.SERVICE_READ] }],
        relativeContext: 'resource',
    },
    [AbsoluteRouteOnlyPath.RESOURCES_SERVERS]: {
        path: '/resources/servers',
        title: Translation.SERVER,
        description: Translation.NAVIGATION_SERVERS_DESCRIPTION,
        parent: { path: AbsoluteRouteOnlyPath.RESOURCES },
        capabilities: [{ required: [Capability.SERVERS_READ], optional: [Capability.SERVER_READ, Capability.SERVERS_MANAGE] }],
        relativeContext: 'resource',
    },
    ...createDerivedConfigs(
        {
            title: Translation.SERVER,
            isTitleSingular: true,
            capabilities: [{ required: [Capability.SERVER_READ], optional: [Capability.SERVERS_MANAGE, Capability.NODES_READ, Capability.LOGS_READ] }],
        },
        {
            [AbsoluteRoutePathWithServiceAndResourceId.SERVICE_SERVER]: {
                path: '/services/:serviceId/servers/:resourceId',
                parent: { path: AbsoluteRoutePathWithServiceId.SERVICE, breadcrumbTitle: Translation.SERVICE_DETAILS },
                relativeContext: 'service',
            },
            [AbsoluteRoutePathWithId.RESOURCES_SERVER]: {
                path: '/servers/:resourceId',
                parent: { path: AbsoluteRouteOnlyPath.RESOURCES_SERVERS, breadcrumbTitle: Translation.RESOURCES_OVERVIEW },
                relativeContext: 'resource',
            },
        }
    ),
    [AbsoluteRouteOnlyPath.RESOURCES_CONTAINERS]: {
        path: '/resources/containers',
        title: Translation.CONTAINER,
        description: Translation.NAVIGATION_CONTAINERS_DESCRIPTION,
        parent: { path: AbsoluteRouteOnlyPath.RESOURCES },
        capabilities: [{ required: [Capability.SERVICE_CONTAINERS_READ], optional: [Capability.SERVICE_CONTAINER_READ, Capability.SERVICE_CONTAINERS_MANAGE] }],
        relativeContext: 'resource',
    },
    ...createDerivedConfigs(
        {
            title: Translation.CONTAINER,
            isTitleSingular: true,
            capabilities: [{ required: [Capability.SERVICE_CONTAINER_READ], optional: [Capability.SERVICE_CONTAINERS_MANAGE, Capability.LOGS_READ] }],
        },
        {
            [AbsoluteRoutePathWithServiceAndResourceId.SERVICE_CONTAINER]: {
                path: '/services/:serviceId/containers/:resourceId',
                parent: { path: AbsoluteRoutePathWithServiceId.SERVICE, breadcrumbTitle: Translation.SERVICE_DETAILS },
                relativeContext: 'service',
            },
            [AbsoluteRoutePathWithId.RESOURCES_CONTAINER]: {
                path: '/containers/:resourceId',
                parent: { path: AbsoluteRouteOnlyPath.RESOURCES_CONTAINERS, breadcrumbTitle: Translation.RESOURCES_OVERVIEW },
                relativeContext: 'resource',
            },
        }
    ),
    [AbsoluteRouteOnlyPath.RESOURCES_VOLUMES]: {
        path: '/resources/volumes',
        title: Translation.VOLUME,
        description: Translation.NAVIGATION_VOLUMES_DESCRIPTION,
        parent: { path: AbsoluteRouteOnlyPath.RESOURCES },
        capabilities: [{ required: [Capability.VOLUMES_READ], optional: [Capability.VOLUME_READ, Capability.VOLUMES_MANAGE] }],
        relativeContext: 'resource',
    },
    ...createDerivedConfigs(
        {
            title: Translation.VOLUME,
            isTitleSingular: true,
            capabilities: [{ required: [Capability.VOLUME_READ], optional: [Capability.VOLUMES_MANAGE, Capability.LOGS_READ] }],
        },
        {
            [AbsoluteRoutePathWithServiceAndResourceId.SERVICE_VOLUME]: {
                path: '/services/:serviceId/volumes/:resourceId',
                parent: { path: AbsoluteRoutePathWithServiceId.SERVICE, breadcrumbTitle: Translation.SERVICE_DETAILS },
                relativeContext: 'service',
            },
            [AbsoluteRoutePathWithId.RESOURCES_VOLUME]: {
                path: '/volumes/:resourceId',
                parent: { path: AbsoluteRouteOnlyPath.RESOURCES_VOLUMES, breadcrumbTitle: Translation.RESOURCES_OVERVIEW },
                relativeContext: 'resource',
            },
        }
    ),
    [AbsoluteRouteOnlyPath.RESOURCES_CONNECTIONS]: {
        path: '/resources/connections',
        title: Translation.CONNECTION,
        description: Translation.NAVIGATION_CONNECTIONS_DESCRIPTION,
        parent: { path: AbsoluteRouteOnlyPath.RESOURCES },
        capabilities: [{ required: [Capability.CONNECTIONS_READ], optional: [Capability.CONNECTION_READ, Capability.CONNECTIONS_MANAGE] }],
        relativeContext: 'resource',
    },
    ...createDerivedConfigs(
        {
            title: Translation.CONNECTION,
            isTitleSingular: true,
            capabilities: [{ required: [Capability.CONNECTION_READ], optional: [Capability.CONNECTIONS_MANAGE, Capability.LOGS_READ] }],
        },
        {
            [AbsoluteRoutePathWithServiceAndResourceId.SERVICE_CONNECTION]: {
                path: '/services/:serviceId/connections/:resourceId',
                parent: { path: AbsoluteRoutePathWithServiceId.SERVICE, breadcrumbTitle: Translation.SERVICE_DETAILS },
                relativeContext: 'service',
            },
            [AbsoluteRoutePathWithId.RESOURCES_CONNECTION]: {
                path: '/connections/:resourceId',
                parent: { path: AbsoluteRouteOnlyPath.RESOURCES_CONNECTIONS, breadcrumbTitle: Translation.RESOURCES_OVERVIEW },
                relativeContext: 'resource',
            },
        }
    ),
    [AbsoluteRouteOnlyPath.RESOURCES_ENDPOINTS]: {
        path: '/resources/endpoints',
        title: Translation.ENDPOINT,
        description: Translation.NAVIGATION_ENDPOINTS_DESCRIPTION,
        parent: { path: AbsoluteRouteOnlyPath.RESOURCES },
        capabilities: [{ required: [Capability.ENDPOINTS_READ], optional: [Capability.ENDPOINT_READ, Capability.ENDPOINTS_MANAGE] }],
        relativeContext: 'resource',
    },
    ...createDerivedConfigs(
        {
            title: Translation.ENDPOINT,
            isTitleSingular: true,
            capabilities: [{ required: [Capability.ENDPOINT_READ], optional: [Capability.ENDPOINTS_MANAGE, Capability.LOGS_READ] }],
        },
        {
            [AbsoluteRoutePathWithServiceAndResourceId.SERVICE_ENDPOINT]: {
                path: '/services/:serviceId/endpoints/:resourceId',
                parent: { path: AbsoluteRoutePathWithServiceId.SERVICE, breadcrumbTitle: Translation.SERVICE_DETAILS },
                relativeContext: 'service',
            },
            [AbsoluteRoutePathWithId.RESOURCES_ENDPOINT]: {
                path: '/endpoints/:resourceId',
                parent: { path: AbsoluteRouteOnlyPath.RESOURCES_ENDPOINTS, breadcrumbTitle: Translation.RESOURCES_OVERVIEW },
                relativeContext: 'resource',
            },
        }
    ),
    [AbsoluteRouteOnlyPath.RESOURCES_MAPPINGS]: {
        path: '/resources/mappings',
        title: Translation.MAPPING,
        description: Translation.NAVIGATION_MAPPINGS_DESCRIPTION,
        parent: { path: AbsoluteRouteOnlyPath.RESOURCES },
        capabilities: [{ required: [Capability.MAPPINGS_READ], optional: [Capability.MAPPING_READ, Capability.MAPPINGS_MANAGE] }],
        relativeContext: 'resource',
    },
    ...createDerivedConfigs(
        {
            title: Translation.MAPPING,
            isTitleSingular: true,
            capabilities: [
                { required: [Capability.MAPPING_READ], optional: [Capability.MAPPINGS_MANAGE, Capability.LOGS_READ, Capability.TOPICS_SUBSCRIPTION] },
            ],
        },
        {
            [AbsoluteRoutePathWithServiceAndResourceId.SERVICE_MAPPING]: {
                path: '/services/:serviceId/mappings/:resourceId',
                parent: { path: AbsoluteRoutePathWithServiceId.SERVICE, breadcrumbTitle: Translation.SERVICE_DETAILS },
                relativeContext: 'service',
            },
            [AbsoluteRoutePathWithId.RESOURCES_MAPPING]: {
                path: '/mappings/:resourceId',
                parent: { path: AbsoluteRouteOnlyPath.RESOURCES_MAPPINGS, breadcrumbTitle: Translation.RESOURCES_OVERVIEW },
                relativeContext: 'resource',
            },
        }
    ),
    [AbsoluteRouteOnlyPath.CLIENT_REGISTRY]: {
        path: '/client-registry',
        title: Translation.CLIENT_REGISTRY,
        description: Translation.NAVIGATION_CLIENT_REGISTRY_DESCRIPTION,
        parent: { title: Translation.USER_MANAGEMENT, isTitleSingular: true },
        capabilities: [{ required: [Capability.CLIENT_REGISTRY_MANAGE], optional: [] }],
    },
    [AbsoluteRouteOnlyPath.USER_MANAGEMENT]: {
        path: '/user-and-roles',
        title: Translation.USERS_AND_ROLES,
        parent: { title: Translation.USER_MANAGEMENT, isTitleSingular: true },
        capabilities: [{ required: [Capability.USERS_AND_ROLES_MANAGE], optional: [] }],
    },
    [AbsoluteRouteOnlyPath.CERTIFICATES]: {
        path: '/certificates',
        title: Translation.CERTIFICATES,
        description: Translation.NAVIGATION_CERTIFICATES_DESCRIPTION,
        parent: { title: Translation.USER_MANAGEMENT, isTitleSingular: true },
        capabilities: [{ required: [Capability.CERTIFICATES_MANAGE], optional: [] }],
    },
    [AbsoluteRouteOnlyPath.CATALOG]: {
        path: '/catalog',
        title: Translation.SERVICES_CATALOG,
        description: Translation.NAVIGATION_SERVICES_CATALOG_DESCRIPTION,
        capabilities: [{ required: [Capability.SERVICES_CATALOG_READ, Capability.SERVICES_CREATE_OR_UPDATE], optional: [] }],
    },
    [AbsoluteRouteOnlyPath.EXPLORER]: {
        path: '/explorer',
        title: Translation.DATA_EXPLORER,
        description: Translation.NAVIGATION_EXPLORER_DESCRIPTION,
        parent: { title: Translation.TOOL },
        capabilities: [
            {
                required: [
                    Capability.TOPICS_SUBSCRIPTION,
                    Capability.TOPICS_SUBSCRIPTION_METADATA,
                    Capability.MAPPING_STATE_READ,
                    Capability.ENDPOINT_STATE_READ,
                    Capability.NODE_STATE_READ,
                ],
                optional: [],
            },
        ],
    },
    [AbsoluteRouteOnlyPath.METRICS]: {
        path: '/metrics',
        title: Translation.METRICS,
        description: Translation.NAVIGATION_SYSTEM_METRICS_DESCRIPTION,
        parent: { title: Translation.SYSTEM, isTitleSingular: true },
        capabilities: [{ required: [Capability.SYSTEM_METRICS_READ], optional: [Capability.SYSTEM_METRICS_MANAGE] }],
    },
    [AbsoluteRouteOnlyPath.STATUSES]: {
        path: '/status',
        title: Translation.STATUS,
        description: Translation.NAVIGATION_SYSTEM_STATUSES_DESCRIPTION,
        parent: { title: Translation.SYSTEM, isTitleSingular: true },
        capabilities: [{ required: [Capability.CORE_CONTAINERS_READ], optional: [Capability.CORE_CONTAINER_READ, Capability.CORE_CONTAINERS_MANAGE] }],
    },
    [AbsoluteRoutePathWithId.RESOURCES_STATUS]: {
        path: '/status/:resourceId',
        title: Translation.STATUS,
        isTitleSingular: true,
        parent: { path: AbsoluteRouteOnlyPath.STATUSES, breadcrumbTitle: Translation.STATUES_PAGE_TITLE },
        capabilities: [{ required: [Capability.CORE_CONTAINER_READ], optional: [Capability.CORE_CONTAINERS_MANAGE, Capability.LOGS_READ] }],
        relativeContext: 'resource',
    },
    [AbsoluteRouteOnlyPath.AGENTS]: {
        path: '/agents',
        title: Translation.AGENT,
        description: Translation.NAVIGATION_SYSTEM_AGENTS_DESCRIPTION,
        parent: { title: Translation.SYSTEM, isTitleSingular: true },
        capabilities: [{ required: [Capability.AGENTS_READ], optional: [Capability.AGENTS_MANAGE] }],
    },
    [AbsoluteRouteOnlyPath.INFO]: {
        path: '/info',
        title: Translation.INFO,
        description: Translation.NAVIGATION_SYSTEM_LICENSE_DESCRIPTION,
        parent: { title: Translation.SYSTEM, isTitleSingular: true },
        capabilities: [{ required: [Capability.MINIMUM], optional: [Capability.SERVICES_READ, Capability.SYSTEM_LICENSE_MANAGE] }],
    },
    [AbsoluteRouteOnlyPath.RULE_ENGINE_SANDBOX]: {
        path: '/rule-engine-sandbox',
        title: Translation.RULE_ENGINE_SANDBOX,
        description: Translation.NAVIGATION_RULE_ENGINE_SANDBOX_DESCRIPTION,
        parent: { title: Translation.TOOL },
        capabilities: [{ required: [Capability.TOPICS_SUBSCRIPTION, Capability.RULE_ENGINE_SANDBOX_USE], optional: [] }],
    },
    [AbsoluteRouteOnlyPath.MAINTENANCE]: {
        path: '/backup-and-restore',
        title: Translation.MAINTENANCE,
        description: Translation.MAINTENANCE_DESCRIPTION,
        parent: { title: Translation.SYSTEM, isTitleSingular: true },
        capabilities: [{ required: [Capability.SYSTEM_BACKUP_MANAGE], optional: [] }],
    },
    [AbsoluteRouteOnlyPath.SETTINGS]: {
        path: '/settings',
        title: Translation.SETTINGS,
        capabilities: [
            { required: [], optional: [] },
            { required: [Capability.MINIMUM], optional: [] },
            { required: [Capability.USE_MFA], optional: [] },
        ],
    },
    [AbsoluteRouteOnlyPath.LOGIN_SETTINGS]: {
        path: '/settings/login',
        title: Translation.LOGIN_SETTINGS,
        parent: { path: AbsoluteRouteOnlyPath.SETTINGS },
        capabilities: [
            { required: [], optional: [] },
            { required: [Capability.MINIMUM], optional: [] },
            { required: [Capability.USE_MFA], optional: [] },
        ],
    },
}

const relativeRoutes: ReadonlyRecord<
    RelativeContext,
    ReadonlyRecord<RelativeRoutePathWithId, AbsoluteRoutePathWithServiceId | AbsoluteRoutePathWithId | AbsoluteRoutePathWithServiceAndResourceId>
> = {
    resource: {
        [RelativeRoutePathWithId.SERVER]: AbsoluteRoutePathWithId.RESOURCES_SERVER,
        [RelativeRoutePathWithId.CONTAINER]: AbsoluteRoutePathWithId.RESOURCES_CONTAINER,
        [RelativeRoutePathWithId.VOLUME]: AbsoluteRoutePathWithId.RESOURCES_VOLUME,
        [RelativeRoutePathWithId.CONNECTION]: AbsoluteRoutePathWithId.RESOURCES_CONNECTION,
        [RelativeRoutePathWithId.ENDPOINT]: AbsoluteRoutePathWithId.RESOURCES_ENDPOINT,
        [RelativeRoutePathWithId.MAPPING]: AbsoluteRoutePathWithId.RESOURCES_MAPPING,
        [RelativeRoutePathWithId.SERVICE]: AbsoluteRoutePathWithId.RESOURCES_SERVICE,
    },
    service: {
        [RelativeRoutePathWithId.SERVER]: AbsoluteRoutePathWithServiceAndResourceId.SERVICE_SERVER,
        [RelativeRoutePathWithId.CONTAINER]: AbsoluteRoutePathWithServiceAndResourceId.SERVICE_CONTAINER,
        [RelativeRoutePathWithId.VOLUME]: AbsoluteRoutePathWithServiceAndResourceId.SERVICE_VOLUME,
        [RelativeRoutePathWithId.CONNECTION]: AbsoluteRoutePathWithServiceAndResourceId.SERVICE_CONNECTION,
        [RelativeRoutePathWithId.ENDPOINT]: AbsoluteRoutePathWithServiceAndResourceId.SERVICE_ENDPOINT,
        [RelativeRoutePathWithId.MAPPING]: AbsoluteRoutePathWithServiceAndResourceId.SERVICE_MAPPING,
        [RelativeRoutePathWithId.SERVICE]: AbsoluteRoutePathWithServiceId.SERVICE,
    },
}

/**
 * Retrieves routing configuration by the given route
 */
export const getRouteConfig = <P extends AbsoluteRoutePath>(path: P): Routes[P] => routes[path]

export const getRouteInternalPath = (path: AbsoluteRoutePath): string => getRouteConfig(path).path

export const getRouteCapabilities = (path: AbsoluteRoutePath): Routes[AbsoluteRoutePath]['capabilities'] => getRouteConfig(path).capabilities

export const getContext = (route: AbsoluteRoutePath): RelativeContext => {
    const { relativeContext } = getRouteConfig(route)

    if (!relativeContext) {
        throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Could not find relative context', { route })
    }

    return relativeContext
}

export const getAbsoluteRoute = (path: RelativeRoutePathWithId, context: RelativeContext): (typeof relativeRoutes)[typeof context][typeof path] =>
    relativeRoutes[context][path]
