import { isPrimitive } from 'utility-types'

import { entries } from '../../../utils'

const passwordKeyPattern = /pass/im

/**
 * This function intakes properties, as complex as they may be and converts them to strings
 *
 * If there is a pass in the props name, converts them to an array of asterisks
 */
export const safelyMapPlainProperties = (props: Record<string, unknown>): Record<string, string> =>
    entries(props).reduce<Record<string, string>>((r, [name, value]) => {
        if (!isPrimitive(value)) {
            return r
        }

        const stringValue = String(value)
        return { ...r, [name]: passwordKeyPattern.test(name) ? Array(stringValue.length).fill('*').join('') : stringValue }
    }, {})
